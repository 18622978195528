import { FunctionComponent, useState, useEffect } from 'react'
import { useAppSelector, useModal } from 'app/hooks'
import { Modal } from 'components/modules/modal/Modal'
import OIIIModal from 'components/modules/modal/Modals'
import SideMenu from 'components/modules/menu/SideMenu'
import FakturacniUdajeSettings from 'components/settings/FakturacniUdajeSettings'
import icons from '@styles/dist/system/icons.svg'
import AddressesSettings from './AddressesSettings'
import SettingsKontaktniOsoby from './SettingsKontaktniOsoby'
import Smlouvy from 'components/onboarding/Smlouvy'
import Tip from './Tip'
import { isInternal } from 'util/authOps'
import JstePrihlaseniZa from 'components/internal/JstePrihlaseniZa'
import SettingsSluzby from './SettingsSluzby'
import SettingsOstatni from './SettingsOstatni'
import { lg } from 'app/facade'
import {
  ADDRESS_MANAGEMENT,
  BILLING_INFORMATION,
  CLOSE,
  CONTACT_PERSONS_MANAGEMENT,
  FIRM_ID_HELP,
  SAVE_CHANGES,
  SETTINGS,
} from 'app/constDictionary'
import { selectLanguage } from './userSettingsSlice'
import { selectCisloKontraktu } from 'components/onboarding/features/firemniUdajeSlice'
import { APP_VERSION } from 'App'

const Settings: FunctionComponent = () => {
  // useBodyClass('u-bg-light-grey')

  const lang = useAppSelector(selectLanguage) || 'cz'
  const { isShown, toggle } = useModal()
  const onConfirm = () => toggle()
  const onCancel = () => toggle()

  const [activeTab, setActiveTab] = useState('nastaveni')
  const cisloKontraktu = useAppSelector(selectCisloKontraktu)

  return (
    <div>
      <div className='u-ml-6 u-px-2 u-pt-6 u-pb-4'>
        {isInternal() && (
          <div className='grid-x u-mb-3' style={{ marginTop: '-40px' }}>
            <div className='cell medium-auto'>
              <div className='u-flex u-flex-row u-justify-between u-items-center u-ml-5'>
                <div className='tabs u-mb-4'>
                  <div className='tabs__links'>
                    <button
                      onClick={() => setActiveTab('nastaveni')}
                      className={
                        'button-as-anchor tabs__link u-mr-3' +
                        (activeTab === 'nastaveni' ? ' tabs__link--active' : '')
                      }
                    >
                      {lg(lang, SETTINGS)}
                    </button>
                    <div>
                      <button
                        onClick={() => {
                          setActiveTab('sluzby')
                        }}
                        className={
                          'button-as-anchor tabs__link u-mr-2' +
                          (activeTab === 'sluzby' ? ' tabs__link--active' : '')
                        }
                      >
                        Služby klienta
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => setActiveTab('ostatni')}
                        className={
                          'button-as-anchor tabs__link' +
                          (activeTab === 'ostatni' ? ' tabs__link--active' : '')
                        }
                      >
                        Ostatní služby
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <JstePrihlaseniZa />
          </div>
        )}
        {activeTab === 'sluzby' && <SettingsSluzby />}
        {activeTab === 'ostatni' && <SettingsOstatni />}
        {activeTab === 'nastaveni' && (
          <div>
            <h1 className='u-size-l28 u-mb-3 u-ml-5'>Nastavení</h1>
            <div className='container container--wide u-mx-0'>
              <div className='grid-x'>
                <div className='cell large-8 u-pr-large-3 u-mb-6 u-mb-large-0'>
                  {/* Přihlašovací údaje - not used for now, will be re-enabled when we switch to the new login system */}
                  {/* <PrihlasovaciUdaje /> */}
                  {/* Fakturační údaje */}
                  <div className='highlight u-py-3 u-px-4 u-mb-3'>
                    <div className='highlight__content'>
                      <h3 className='u-size-l20 u-mb-4'>
                        {lg(lang, BILLING_INFORMATION)}
                      </h3>
                      <FakturacniUdajeSettings
                        submitIcon={
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-check'}></use>
                          </svg>
                        }
                        submitText={lg(lang, SAVE_CHANGES)}
                        tipComponent={
                          <Tip
                            scrollId={'nastaveni-adresy'}
                            id={'fakturacni-udaje-tip'}
                            content={lg(lang, FIRM_ID_HELP)}
                          />
                        }
                      />
                    </div>
                  </div>
                  {/* Správa adres */}
                  <div
                    id={'adresy'}
                    style={{ marginTop: '-80px', visibility: 'hidden' }}
                  ></div>
                  <div style={{ marginTop: '106px' }}></div>
                  <div className='highlight u-py-3 u-px-4 u-mb-3'>
                    <div className='highlight__content'>
                      <h3 className='u-size-l20 u-mb-4'>
                        {lg(lang, ADDRESS_MANAGEMENT)}
                      </h3>
                      <AddressesSettings />
                    </div>
                  </div>
                  <div className='highlight u-py-3 u-px-4 u-mb-5'>
                    <div className='highlight__content'>
                      <h3 className='u-size-l20 u-mb-4'>
                        {lg(lang, CONTACT_PERSONS_MANAGEMENT)}
                      </h3>
                      <SettingsKontaktniOsoby />
                    </div>
                  </div>
                  {cisloKontraktu && (
                    <Smlouvy settings={true} label={'Smlouvy k nahlédnutí'} />
                  )}
                </div>
              </div>
              <small className='cell auto u-pb-0'>
                <span className='small-text u-color-c1-60'>
                 Pluxee E-commerce {APP_VERSION} | © 2023
                </span>
              </small>
            </div>
          </div>
        )}
      </div>

      <Modal
        isShown={isShown}
        hide={onCancel}
        headerText={lg(lang, CLOSE)}
        subheaderText='Firemní údaje'
        modalContent={<OIIIModal onSubmit={onConfirm} />} // change to OSModalItem2
      />
      <SideMenu />
    </div>
  )
}

export default Settings
