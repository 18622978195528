import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class PersonIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'person-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21 8.441C21 10.976 18.968 13 16.5 13S12 10.976 12 8.441V6.56C12 4.024 14.032 2 16.5 2S21 4.024 21 6.559V8.44zm2 0C23 12.064 20.09 15 16.5 15S10 12.064 10 8.441V6.56C10 2.936 12.91 0 16.5 0S23 2.936 23 6.559V8.44zM20.818 19c3.966 0 7.55 3.36 8.107 8.078l-1.563.67c-7.024 3.011-14.788 3.002-21.803-.025l-1.484-.642C4.63 22.36 8.214 19 12.182 19h8.636zM31 28.364l-2.85 1.223c-7.528 3.227-15.864 3.217-23.384-.028L2 28.364C2 22.087 6.558 17 12.182 17h8.636C26.442 17 31 22.091 31 28.364z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default PersonIcon
