import WorkerBuilder from './WorkerBuilder'

// unsafe shit. Use WorkerFactory instead
export const SingletonFactory = (function () {
  let instance: WorkerBuilder | null = null

  return {
    getInstance: function () {
      if (!instance) {
        instance = new WorkerBuilder(Worker)
      }
      return instance
    },
  }
})()
