import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class SortingDownIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'sorting-down-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          d='M22.7 12.625l-5.92 7.399a1 1 0 01-1.56 0l-5.92-7.4A1 1 0 0110.08 11h11.84a1 1 0 01.78 1.625z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default SortingDownIcon
