import React from 'react'
import Color from 'app/colors'

const SettingsOstatni = () => {
  return (
    <div className='settings-ostatni'>
      <div
        className='container container--wide u-mx-0'
        style={{
          // background: '#2A295B',
          maxWidth: '100vw',
          borderRadius: 24,
        }}
      >
        <div className='grid-x'>
          <div className='cell large-8 u-pr-large-3 u-mb-6 u-mb-large-0'>
            {/* Přihlašovací údaje - not used for now, will be re-enabled when we switch to the new login system */}
            {/* <PrihlasovaciUdaje /> */}
            {/* Fakturační údaje */}
            <div
              className='highlight u-py-3 u-px-4 u-mb-3'
              style={{ width: 700 }}
            >
              <div className='highlight__content'>
                <h2 className='u-size-l20 u-mb-4'>Ostatní služby</h2>
                <div className='grid-x'>
                  <div className='cell'>
                    <div
                      className={'u-mt-1 u-pt-4 u-pb-5'}
                      style={{ maxWidth: '600px' }}
                    >
                      <div
                        style={{
                          border: `2px solid ${Color.BLUESODEXO}`,
                          marginBottom: '0px',
                        }}
                        className='highlight__title grid-x color-sodexo-blue u-py-2 u-px-2'
                      >
                        <span>Služba</span>
                        <small className='u-mr-4 xsmall-2'></small>
                        <small className='xsmall-3 u-pr-2'>Hodnota</small>
                      </div>
                      <div className='border-full-no-top u-px-2 u-pt-1 u-pb-2'>
                        <p className='grid-x color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>Dlužník</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ne
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>Maximální kredit</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            0 Kč
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>Platba po dodání</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ne
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>Logo na kartě</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ne
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>ID loga</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Žádné
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>
                            Tisk distribučních listů SPO
                          </span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ano
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>MultiPassCard</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ano
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell auto'>Personalizace</span>
                          <span className='cell shrink small-2 u-text-center u-mt-2'>
                            Ne
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsOstatni
