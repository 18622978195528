import { useEffect, useRef, useState } from 'react'
import SideMenu from 'components/modules/menu/SideMenu'
import TableThree from './TableThree'

import icons from '@styles/dist/system/icons.svg'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import SortingDownIcon from '@styles/icon/sortingDownIcon'

import {
  useAppSelector,
  useBodyClass,
  useOnClickOutsideAndEscapeForMultipleModals,
  useOnEscapeClick,
} from 'app/hooks'
import { useDispatch } from 'react-redux'
import {
  tableActions,
  selectTable,
  selectTableTotalPrice,
  selectTotalSelectedActive,
  selectTotalSelectedDeactivated,
  selectPocetNovychKaretKObjednani,
  selectTableTotalVolnocasovy,
  selectTableTotalStravenkovy,
  selectSelectedRowIds,
  selectEmployeesWithAnOrderItem,
  selectEmployeesWithoutAnOrderItem,
  selectPocetNahradnichKaretKObjednani,
  shouldOrderEmployeeACard,
  TableRow,
} from './tableSlice'
import {
  reducerAddDenniKreditZamestnance,
  reducerDenniKredit,
  reducerEmpDetailRollover,
  reducerRemoveDenniKreditZamestnance,
  reducerZadaniStravKredituCastkaKreditu,
  selectCerstveDokoncilMigraci,
  selectCerstveProselOnboardingem,
  selectDenniKredit,
  selectDenniKredityZamestnancu,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import DeleteIcon from '@styles/icon/deleteIcon'
import OrderSummary from 'components/selfcare/OrderSummary'
import {
  minimumXIfNotZero as minimumXIfNotZero,
  numberWithSpaces,
  timeout,
  validateNumericKeyWithWhites,
} from 'app/util'
import {
  selectKontaktniEmail,
  selectPoznamkaNaFakture,
  reducerPoznamkaNaFakture,
  reducerKontaktniEmail,
  selectPracovniEmail,
} from 'components/onboarding/features/objednavatelSlice'
import { selectUserSettings } from 'components/settings/userSettingsSlice'
import {
  govActions,
  selectAddresses,
  selectGroups,
  selectLogin,
  selectUsers,
} from 'components/settings/governanceSlice'
import { overviewActions } from './overviewSlice'
import {
  useDorucovaciAdresyMap,
  useTotalMonthsToPay,
  useTotalPriceWithFeesAndDelivery,
  selectorBeginningOfTime,
  monthsElapsedBeginningInclusive,
  ONE,
} from 'components/selfcare/selfcareHooks'
import {
  DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU,
  INTERNAL_ID,
  INTERNAL_IDX,
  KOREKCE_DAT,
  MIGRACE_DAT,
} from 'app/constants'
import {
  beAfterLogin,
  urlOrdersOverview,
  urlSelfcareTable,
  urlNastaveni,
  beMakeAdresa,
} from 'App'
import { Navigate, useNavigate } from 'react-router-dom'
import { kohoCehoZamestnancuSwitch } from 'util/LangOps'
import { selectDenniPrispevekNaStravuOnboarding } from 'components/onboarding/features/mesicniNakladySlice'
import {
  equalsBezRozdiluVCisluOrientacnim,
  equalsNormalize,
  findMatchingAddress,
  formatPsc,
  isValidAddress,
  parseUliceCisloPopisne,
  splitUliceCisloPopisne,
} from 'util/ValidFormatOps'
import {
  reducerCenik,
  reducerCisloKontraktu,
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerOpravneneOsoby,
  reducerPsc,
  reducerUliceCisloPopisne,
  selectCenik,
} from 'components/onboarding/features/firemniUdajeSlice'

import Help from 'components/modules/help/Help'
import { isDeactivatable, isDeletable, isNew } from 'util/TableOps'
import EraseIcon from '@styles/icon/eraseIcon'
import Color from 'app/colors'
import { defaultHeaders } from 'app/back'
import {
  Address,
  AdresaBackend,
  AdresaBackendUUID,
  Cenik,
  ConsignmentState,
  ExcelFile,
  KontaktniOsobaBackend,
  Login,
  NovaObjednavkaBackend,
  ObjednavkaBackend,
  Order,
  OrderState,
  Role,
  Spolecnost,
  TSMenu,
  User,
  ZamestnanecBackend,
  ZamestnanecBackendEditace,
  Zasilka,
} from 'app/type'
import {
  assignSkupina,
  sendObjednavkaToBackendAndPrettifyId,
  createSkupina,
  mutateAktivovanyBackend,
  mutateKontaktniOsobaBackend,
  mutatePocatekCasu,
  mutateZamestnanecBackend,
  purgeAndLog,
  createOddeleniBackend,
  createAdresaBackend,
} from 'app/req'
import {
  addressToAdresaBackendKlic,
  adresaBackendToAddress,
  adresaBackendToBrokenAddress,
  employeeToZamestnanecBackendEditace,
  kontaktniOsobaBackendToUser,
  objednavkaBackendToOrder,
  zamestnanecBackendToEmployee,
} from 'util/transformer'
import { resetVisitedLocation } from 'components/onboarding/features/stepperSlice'
import { sinking, verne } from 'app/julesverne'
import { dateFromBackendString, newDate } from 'util/DateOps'
import { reducerUploadedFiles } from 'components/dataimportcentre/importSettingsSlice'
import {
  lg,
  changeZamestnanecBackend,
  changeZamestnanecAddress,
} from 'app/facade'
import JstePrihlaseniZa from 'components/internal/JstePrihlaseniZa'
import { getCurrentUser, isInternal } from 'util/authOps'
import {
  CARDS,
  CLOSE,
  CREDIT,
  CREDIT_AMOUNT,
  DAYS_WORKED,
  NEW_ORDER,
  ORDER_SUMMARY,
  SELECTED,
  SELECT_EMPLOYEES_BULK_ACTION,
  SEND_THE_ORDER,
} from 'app/constDictionary'
import { MultiPassCardState } from 'util/CardOps'
import {
  bitMaskOfTheCurrentMonth,
  padWithZerosFromTheLeftTo32Bits,
} from 'util/binaryOps'
import { nanoid } from '@reduxjs/toolkit'
import { roundToTwo } from 'app/numberOps'
import { randomFirstName, randomLastName, randomCardStatus } from 'util/Faker'

const TableContainer = () => {
  // console.log('TableContainer - loaded Component')
  verne('TableContainer#126')

  const navigate = useNavigate()

  useBodyClass('u-bg-light-grey')

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState<string | null>(null)

  const monthsToPay = useTotalMonthsToPay(1) // change request from business to only bill this and the last month (Petra Putyrova, 22.02.2022)
  const beginningOfTime = useAppSelector((state) =>
    selectorBeginningOfTime(state)
  )

  const dispatch = useDispatch()

  const lang = useAppSelector(selectLanguage) || 'cz'

  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()
  // Call hook passing in the ref and a function to call on outside click
  const [popover, setPopover] = useState('')
  useOnClickOutsideAndEscapeForMultipleModals(
    ref,
    () => setPopover(''),
    popover,
    setPopover
  )
  const cerstveProselOnboardingem = useAppSelector(
    selectCerstveProselOnboardingem
  )
  const cerstveDokoncilMigraci = useAppSelector(selectCerstveDokoncilMigraci)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        popover === 'volnocas-trash' &&
          dispatch(tableActions.setVolnocasovyToAll({ amount: 0 }))
        popover === 'stravenkovy-trash' &&
          dispatch(
            tableActions.setStravenkovyToAll({
              amount: 0,
            })
          )
        setPopover('')
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [popover, setPopover])

  const table = useAppSelector(selectTable) as TableRow[]
  const selectedEmployeeIds = useAppSelector(selectSelectedRowIds)
  const selectedEmployees = table.filter((row) => row.selected)

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)
  const addresses = useAppSelector(selectAddresses) as Address[]
  const pracovniEmail = useAppSelector(selectPracovniEmail)

  const onboardingDenniKreditNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const [pocetDnu, setPocetDnu] = useState('')
  const denniKredit = useAppSelector(selectDenniKredit)
  const [stateDenniKredit, setStateDenniKredit] = useState('')
  const denniKredityZamestnancu = useAppSelector(selectDenniKredityZamestnancu)
  useState(denniKredityZamestnancu)

  const login = useAppSelector(selectLogin) as Login
  const users = useAppSelector(selectUsers) as User[]
  const currentUser = getCurrentUser(users, login)

  // We cannot use hook useRestartBeginningOfTime() here, because it is called in the render() function and we get a React error.
  // reusing the code here inside our redundant restartBeginningOfTime function
  const restartBeginningOfTime = () => {
    dispatch(tableActions.clearPaidMonths())
    dispatch(tableActions.clearDeactivatedMonths())
    // set beginning of time to the second day of the current month (to account for timezones)
    const secondDayOfCurrentMonth = newDate()
    secondDayOfCurrentMonth.setDate(2)
    secondDayOfCurrentMonth.setHours(0, 0, 0, 0)
    console.log(`renewing the beginning of time to ${secondDayOfCurrentMonth}`)
    dispatch(govActions.reducerBeginningOfTime(secondDayOfCurrentMonth))
  }

  const [redirectToOrdersOverview, setRedirectToOrdersOverview] =
    useState(false)
  const [redirectToSelfcare, setRedirectToSelfcare] = useState(false)
  const [redirectToSettings, setRedirectToSettings] = useState('')

  function evacuateEmployeesFromIncompleteAddresses(
    data: any,
    completeAddresses: AdresaBackendUUID[],
    incompleteAddresses: AdresaBackendUUID[]
  ) {
    incompleteAddresses.forEach((address: AdresaBackendUUID) => {
      const zamestnanciBackend = data.zamestnanci as ZamestnanecBackend[]
      for (let i = 0; i < zamestnanciBackend.length; i++) {
        if (
          zamestnanciBackend[i].adresa.ulice === address.ulice ||
          // zamestnanciBackend[i].adresa.cisloPopisne === address.cisloPopisne || // this is not unique
          zamestnanciBackend[i].adresa.psc === address.psc
        ) {
          const zamestnanecBackendEditace = zamestnanecBackendToEmployee(
            zamestnanciBackend[i]
          ) as TableRow
          let matchFound = false
          completeAddresses.forEach((element) => {
            if (
              // safe equality check
              equalsBezRozdiluVCisluOrientacnim(
                `${zamestnanciBackend[i].adresa.ulice}, ${zamestnanciBackend[i].adresa.cisloPopisne}`,
                `${element.ulice}, ${element.cisloPopisne}`
              ) ||
              equalsNormalize(
                zamestnanciBackend[i].adresa.ulice,
                element.ulice
              ) ||
              // zamestnanciBackend[i].adresa.cisloPopisne === element.cisloPopisne && // this is not unique
              equalsNormalize(zamestnanciBackend[i].adresa.psc, element.psc)
            ) {
              zamestnanciBackend[i].adresa = element

              const zamestnanecBackendEditaceSimilarAddress =
                employeeToZamestnanecBackendEditace(zamestnanecBackendEditace, {
                  ulice: element.ulice,
                  cisloPopisne: element.cisloPopisne,
                  psc: element.psc,
                  oddeleni: element.oddeleni ? element.oddeleni[0] : 'Žádné', // this should not happen, but what if some of the addresses are without oddeleni and we still have not 'fixed' them?
                }) as ZamestnanecBackendEditace
              mutateZamestnanecBackend(zamestnanecBackendEditaceSimilarAddress)
              console.log(
                'TableContainer#468 - callBackendAfterLogin() - similar address found for employee %O, replacing with: %O',
                zamestnanciBackend[i],
                element
              )
              matchFound = true
              return
            }
          })
          if (!matchFound) {
            zamestnanciBackend[i].adresa = completeAddresses[0]
            // replace the employee's address with the first valid address in the list
            const zamestnanecBackendEditaceFirstAddress =
              employeeToZamestnanecBackendEditace(zamestnanecBackendEditace, {
                ulice: completeAddresses[0].ulice,
                cisloPopisne: completeAddresses[0].cisloPopisne,
                psc: completeAddresses[0].psc,
                oddeleni: completeAddresses[0].oddeleni
                  ? completeAddresses[0].oddeleni[0]
                  : 'Žádné', // this could happen
              }) as ZamestnanecBackendEditace
            mutateZamestnanecBackend(zamestnanecBackendEditaceFirstAddress)
            console.log(
              'TableContainer#468 - callBackendAfterLogin() - no matching address found for employee: %O, assigning to first valid address: %O',
              zamestnanciBackend[i],
              completeAddresses[0]
            )
            alert(
              `Nepodařilo se najít odpovídající adresu pro zaměstnance ${zamestnanciBackend[i].jmeno} ${zamestnanciBackend[i].prijmeni}. Byl přiřazen na první validní adresu v seznamu: ${completeAddresses[0].ulice}, ${completeAddresses[0].cisloPopisne}, ${completeAddresses[0].psc}. V případě potřeby kontaktujte prosím podporu.`
            )
            return
          }
        }
      }
      purgeAndLog(`${beMakeAdresa}/${address.uuid}`)
      console.log(
        `TableContainer#468 - callBackendAfterLogin() - Eliminated address ${address.uuid} because it was incomplete. Moved employees to the most similar address or first valid address.`
      )
      data.adresy = data.adresy.filter(
        (a: AdresaBackendUUID) => a.uuid !== address.uuid
      )
    })
  }

  verne('TableContainer#167')
  async function callBackendAfterLogin() {
    verne('TableContainer#169 - callBackendAfterLogin()')
    let dev = false
    let noSpoSync = dev ? true : false

    const url = beAfterLogin + (noSpoSync === true ? '?no-sync' : '')

    console.log(
      `
      ##################################
      ########## AFTER-LOGIN ###########
      ##################################
      GET ${url} 
        with options.headers:
        Accept = ${defaultHeaders().headers.Accept}
        Content-Type = ${defaultHeaders().headers['Content-Type']}      
      `
      // Authorization = ${defaultHeaders().headers.Authorization} // do not log the token
    )

    setIsLoading(true)

    // Execute rm -rf / before the await fetch, to assure a predictable state of application -> either data loads or empty screen. No old data.
    // step 0.8 - rm -rf /
    clearTables('/')

    let containsIllFormattedAddresses = false
    let cerstveZmigrovana = false

    // here we cannot use typical getAndLog() because we execute our own logic after the backend is ready... we could leverage a callback though...
    await fetch(url, defaultHeaders())
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then(async (data) => {
        console.log('data: ', data)
        // step -1. - if backend failed, return
        if (!data) {
          console.log(
            'failed to retrieve a response body on /after-login request'
          )
          return
        } else {
          verne('TableContainer#209 - callBackendAfterLogin() - data: %O', data)
        }

        // step 0.9 - spolecnosti
        data.spolecnosti
          ? dispatch(govActions.reducerSpolecnosti(data.spolecnosti))
          : console.log(
              'TableContainer#213 - callBackendAfterLogin() - data does not contain spolecnosti: %O',
              data
            )

        const aktualniSpolecnost: Spolecnost = data.spolecnosti.find(
          (spolecnost) => spolecnost.aktivni === true
        )
        console.log('aktualniSpolecnost: ', aktualniSpolecnost)

        cerstveZmigrovana = aktualniSpolecnost.cerstveZmigrovana
        dispatch(reducerCisloKontraktu(aktualniSpolecnost.kontrakt))

        // console.log(aktualniSpolecnost)
        // console.log(aktualniSpolecnost.pocatekCasu === null) // true
        // console.log(aktualniSpolecnost.pocatekCasu === undefined) // false
        // console.log(!aktualniSpolecnost.pocatekCasu) // true

        let pocatekCasu: string | null = aktualniSpolecnost.pocatekCasu
        if (aktualniSpolecnost.pocatekCasu === null) {
          console.log(
            'TableContainer#217 - callBackendAfterLogin() - data.spolecnosti.pocatekCasu is null'
          )
          console.log('Creating new pocatekCasu')
          // new date DD.MM.YYYY format
          const date = new Date()
          const dateString = `${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()}`
          pocatekCasu = dateString
          mutatePocatekCasu(dateString)
        }
        dispatch(
          govActions.reducerBeginningOfTime(dateFromBackendString(pocatekCasu))
        )

        // step 0.91 - load menu
        const menuBar: TSMenu[] = data.menu
        dispatch(govActions.reducerMenuBar(menuBar))

        // step 1 - replace the redux storage with data from backend
        // step 1.1 - ceník
        const beCenik: Cenik = {
          ...data.cenik,
          minStravenkyFee: data.cenik.minStravenky,
          minVolnocasFee: data.cenik.minVolnocas,
        }
        verne(
          'TableContainer#224 - callBackendAfterLogin() - beCenik: %O',
          beCenik
        )
        dispatch(reducerCenik(beCenik))

        // step 1.2 - fakturacniUdaje
        const beFakturacniUdaje = data.fakturacniUdaje
        verne(
          'TableContainer#232 - callBackendAfterLogin() - beFakturacniUdaje: %O',
          beFakturacniUdaje
        )
        // no need to remove nothing here, we replace the whole state.
        dispatch(reducerIcFirmy(beFakturacniUdaje.ico))
        dispatch(reducerNazevSpolecnosti(beFakturacniUdaje.nazevSpolecnosti))
        dispatch(
          reducerUliceCisloPopisne(
            parseUliceCisloPopisne(
              beFakturacniUdaje.ulice,
              beFakturacniUdaje.cisloPopisne
            )
          )
        )
        dispatch(reducerMesto(beFakturacniUdaje.mesto))
        dispatch(reducerPsc(beFakturacniUdaje.psc))
        dispatch(reducerOpravneneOsoby(beFakturacniUdaje.opravneneOsoby))

        // step 1.3 - kontaktni osoby
        data.kontaktniOsoby.forEach((kontaktniOsoba: KontaktniOsobaBackend) => {
          dispatch(
            govActions.addUser(kontaktniOsobaBackendToUser(kontaktniOsoba))
          )
        })

        // step 1.4 - adresy
        dispatch(govActions.clearBrokenAddresses()) // first, make sure we don't reuse broken addresses from previous login

        // step 1.4.0 - add department 'Žádné' to addresses that do not have department
        data.adresy.forEach((address: AdresaBackendUUID) => {
          if (address.oddeleni === undefined || address.oddeleni.length === 0) {
            console.log(
              `Address ${address} has no departments. Assigning department 'Žádné'.`
            )
            // no need to change it on frontend, at this point, they do not "exist" yet
            // const uliceCisloPopisne =
            //   address.ulice + ', ' + address.cisloPopisne
            // dispatch(
            //   govActions.addOddeleni({
            //     uliceCisloPopisne: uliceCisloPopisne,
            //     oddeleni: 'Žádné',
            //   })
            // )
            createOddeleniBackend({
              address: adresaBackendToAddress(address),
              oddeleni: 'Žádné',
            })
            address = {
              ...address,
              oddeleni: ['Žádné'],
            }
          }
        })

        // step 1.4.1 - remove incomplete addresses that do not have ulice, cisloPopisne, or psc
        const incompleteAddresses = data.adresy.filter(
          (address: AdresaBackendUUID) => {
            return (
              address.ulice === null ||
              address.cisloPopisne === null ||
              address.psc === null ||
              address.ulice === undefined ||
              address.cisloPopisne === undefined ||
              address.psc === undefined
            )
          }
        ) as AdresaBackendUUID[]
        const completeAddresses = data.adresy.filter(
          (address: AdresaBackendUUID) => {
            return (
              address.ulice !== null &&
              address.cisloPopisne !== null &&
              address.psc !== null &&
              address.ulice !== undefined &&
              address.cisloPopisne !== undefined &&
              address.psc !== undefined
            )
          }
        ) as AdresaBackendUUID[]
        if (incompleteAddresses.length > 0) {
          console.log(
            'TableContainer#428 - callBackendAfterLogin() - incompleteAddresses: %O',
            incompleteAddresses
          )
          if (incompleteAddresses.length === data.adresy.length) {
            console.log(
              'TableContainer#433 - callBackendAfterLogin() - all addresses are incomplete, we cannot automatically move existing employees anywhere. Please contact support.'
            )
            alert('Všechny adresy jsou neúplné. Kontaktujte prosím podporu.')
            return
          }
        }
        // step 1.4.2 - Evacuate employees from incomplete addresses
        // ORD-2160 - pause evacuation
        evacuateEmployeesFromIncompleteAddresses(
          data,
          completeAddresses,
          incompleteAddresses
        )

        const addresses = [] as Address[]

        function isDuplicateAddress(address: Address) {
          // safe equality check
          return addresses.find((a) => {
            equalsBezRozdiluVCisluOrientacnim(
              a.uliceCisloPopisne,
              address.uliceCisloPopisne
            ) && a.psc === address.psc
          })
        }

        data.adresy.forEach((addressBackend: AdresaBackendUUID) => {
          // console.log('addressBackend ulice: ', addressBackend.ulice)
          // console.log(
          //   'addressBackend cisloPopisne: ',
          //   addressBackend.cisloPopisne
          // )
          // !! IMPORTANT !!
          // PARSING IS HAPPENING IN THE TRANSFORMATIONS BELOW!
          const transformedAddress = adresaBackendToAddress(
            addressBackend
          ) as Address
          // console.log(
          //   'transformedAddress uliceCisloPopisne: ',
          //   transformedAddress.uliceCisloPopisne
          // )
          dispatch(govActions.addAddress(transformedAddress))
          if (isDuplicateAddress(transformedAddress)) {
            console.error('Duplicate address found', transformedAddress)
          }
          addresses.push(transformedAddress)
          if (!addressBackend.ulice || !addressBackend.cisloPopisne) {
            const brokenAddress = adresaBackendToBrokenAddress(addressBackend)
            dispatch(govActions.addBrokenAddress(brokenAddress))
          }
        })
        // console.log('addresses from backend', addresses)

        // step 1.4.val - validace adres
        if (cerstveZmigrovana) {
          // if the user has just migrated, we want to flag all addresses as ill-formatted, to make sure he checks all of them.
          data.adresy.forEach((a: AdresaBackendUUID) => {
            dispatch(
              govActions.addBrokenAddress(adresaBackendToBrokenAddress(a))
            )
          })
        } else {
          // else add only the ill-formatted addresses
          for (let i = 0; i < addresses.length; i++) {
            if (!isValidAddress(addresses[i])) {
              console.log(
                'TableContainer#408 - callBackendAfterLogin() - address is not valid',
                addresses[i]
              )
              const brokenAddress = adresaBackendToBrokenAddress(data.adresy[i])
              dispatch(govActions.addBrokenAddress(brokenAddress))
              containsIllFormattedAddresses = true
            }
          }
        }

        // step 1.5 - zaměstnanci
        const beZamestnanci = data.zamestnanci as ZamestnanecBackend[]
        const feZamestnanci = beZamestnanci.map((zamestnanec) => {
          return zamestnanecBackendToEmployee(zamestnanec)
        }) as TableRow[]
        const peopleFiltered = feZamestnanci.filter(
          (zamestnanec) =>
            zamestnanec.cardStatus !== MultiPassCardState.Žádná // ORDTEX-xxx - Nezobrazování zaměstnanců co mají stav karty/účtů "Žádná karta" u zmigrovaných účtů
        ) as TableRow[]
        dispatch(tableActions.addRowsToTheTable({ rows: peopleFiltered }))

        // 2023-02-16 : validate that all people have a valid address
        function doesEmployeeAddressExist(uliceCisloPopisne: string): boolean {
          return findMatchingAddress(uliceCisloPopisne, addresses) !== undefined
          // the above is better
          // return addresses.some(
          //   // use the identity, not equality check here because were are fixing addresses from backend = sensitive to typos
          //   // (a) => a.uliceCisloPopisne === uliceCisloPopisne
          //   // 30.3.2023 - we have some trouble, giving it the equality with some degrees of freedom instead of identity check
          //   (a) =>
          //     equalsBezRozdiluVCisluOrientacnim(
          //       a.uliceCisloPopisne,
          //       uliceCisloPopisne
          //     )
          // )
        }

        function giveEmployeeFirstAddress(employee: TableRow) {
          let firstAddress = addresses[0]
          console.log(
            'Giving employee {} first address {}',
            employee,
            firstAddress
          )

          changeZamestnanecAddress(employee, firstAddress)
          dispatch(
            tableActions.setUliceCisloPopisne({
              id: employee.id,
              uliceCisloPopisne: firstAddress.uliceCisloPopisne,
              oddeleni: firstAddress.oddeleni[0],
            })
          )
        }

        function giveEmployeeFirstDepartmentOfHisAddress(employee: TableRow) {
          const matchingAddress = findMatchingAddress(
            employee.uliceCisloPopisne,
            addresses
          ) as Address
          const firstOddeleni = matchingAddress.oddeleni[0]
          console.log(
            'Giving employee {} first department {} of address {}',
            employee,
            firstOddeleni,
            matchingAddress
          )

          changeZamestnanecAddress(
            employee,
            addresses.find(
              // use the identity, not equality check here because were are fixing addresses from backend = sensitive to typos
              (a) => a.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
            ) as Address
          )
          dispatch(
            tableActions.setUliceCisloPopisne({
              id: employee.id,
              uliceCisloPopisne: matchingAddress.uliceCisloPopisne,
              oddeleni: matchingAddress.oddeleni[0],
            })
          )
        }

        peopleFiltered.forEach((employee: TableRow) => {
          if (!employee.uliceCisloPopisne) {
            console.warn(
              'TableContainer#465 - callBackendAfterLogin() - employee has no address, no uliceCisloPopisne',
              employee
            )
            console.log(
              `TableContainer would love to give the employee ${employee} a valid address, because his address ${employee.uliceCisloPopisne} is not valid.`
            )
            // giveEmployeeFirstAddress(employee)
          } else {
            if (!doesEmployeeAddressExist(employee.uliceCisloPopisne)) {
              console.warn(
                `TableContainer#471 - callBackendAfterLogin() - employee ${employee} has an invalid address - uliceCisloPopisne ${employee.uliceCisloPopisne} is not found in existing addresses`
              )
            }
            console.log(
              `TableContainer would love to give the employee ${employee} a valid address, because his address ${employee.uliceCisloPopisne} is not valid.`
            )
            // giveEmployeeFirstAddress(employee) // todo give him the most familiar address to his current address
          }
          if (!employee.oddeleni) {
            console.warn(
              'TableContainer#479 - callBackendAfterLogin() - employee has no department',
              employee
            )
            giveEmployeeFirstDepartmentOfHisAddress(employee)
          }
        })

        // step 1.6 - skupiny
        data.skupiny.forEach((skupina: string) =>
          dispatch(govActions.addGroup(skupina))
        )

        // step 1.7 - objednávky
        // The variant below triggers refresh on every addOrder call.
        // data.objednavky.forEach((objednavka: ObjednavkaBackend) => {
        //   const order: Order = objednavkaBackendToOrder(objednavka)
        //   // console.log('Adding order from BE: ', order)
        //   dispatch(overviewActions.addOrder(order))
        // })
        const beObjednavky = data.objednavky as ObjednavkaBackend[]
        const feObjednavky = beObjednavky.map((objednavka) => {
          return objednavkaBackendToOrder(objednavka)
        }) as Order[]
        dispatch(overviewActions.addOrders({ orders: feObjednavky }))

        // step 1.7.1 for internal users
        const lastOrderEmail = feObjednavky[0]?.contactEmail
        dispatch(reducerKontaktniEmail(lastOrderEmail))

        // step 1.8 - data import centre file names
        const beDataImportCentreFiles =
          data.dataImportCentrum.nahraneSoubory.slice(-3)
        console.log('beDataImportCentreFiles: ', beDataImportCentreFiles)
        const feDataImportCentreFiles: ExcelFile[] =
          beDataImportCentreFiles.map((file: any) => {
            return {
              uuid: file.id,
              date: dateFromBackendString(file.datum),
            } as ExcelFile
          })
        // order by date descending
        feDataImportCentreFiles.sort((a, b) => {
          return b.date.getTime() - a.date.getTime()
        })
        dispatch(reducerUploadedFiles([...feDataImportCentreFiles]))

        // step 2 - set the state of the app and computed properties to be ready
        // step 2.1 - set the currently logged in user
        let editableUsers = [] as String[]
        let usersChecked = 0
        let adminEmail = ''
        console.log('isInternalUser: ', isInternal())
        if (!isInternal()) {
          // const filteredUsers = data.kontaktniOsoby.filter(
          for (const kontaktniOsoba of data.kontaktniOsoby) {
            console.log('Found kontaktniOsoba: ', kontaktniOsoba)
            // async (kontaktniOsoba: KontaktniOsobaBackend) => {
            let editable: boolean = false
            const result = mutateKontaktniOsobaBackend({
              email: kontaktniOsoba.email,
              name: kontaktniOsoba.jmeno,
              surname: kontaktniOsoba.prijmeni,
              countryCode:
                kontaktniOsoba.telefon === '' ||
                kontaktniOsoba.telefon === undefined
                  ? undefined
                  : kontaktniOsoba.predvolba,
              phoneNumber: kontaktniOsoba.telefon,
            })
            result.then((result) => {
              if (!result.zprava.includes('Musíte být admin')) {
                console.log(
                  `Found an editable user (might be the logged in user) in the backend: ${kontaktniOsoba.email}.`
                )
                editable = true
                if (equalsNormalize(kontaktniOsoba.status, Role.Hlavní)) {
                  adminEmail = kontaktniOsoba.email
                }
              }
              console.log('Found return: ', editable)
              if (editable) editableUsers.push(kontaktniOsoba.email)
              usersChecked++
              return editable
            })
          }
          // )
          // !isInternal() &&
          //   data.kontaktniOsoby
          //     // .filter((o: KontaktniOsobaBackend) => o.status != 'vytvorena') // re-enable this filter when backend is ready to return the actual state of the users and when we are not testing with users who are not yet created
          //     .forEach((kontaktniOsoba: KontaktniOsobaBackend) => {
          //       if (!adminEmail)
          //         setTimeout(() => {
          //           const result = mutateKontaktniOsobaBackend({
          //             email: kontaktniOsoba.email,
          //             name: kontaktniOsoba.jmeno,
          //             surname: kontaktniOsoba.prijmeni,
          //           })
          //           result.then((result) => {
          //             if (!result.zprava.includes('Musíte být admin')) {
          //               console.log(
          //                 `Found an editable user (might be the logged in user) in the backend: ${kontaktniOsoba.email}. Setting as the logged in user.`
          //               )
          //               numberOfEditableUsers++
          //               if (equalsNormalize(kontaktniOsoba.status, Role.Hlavní)) {
          //                 adminEmail = kontaktniOsoba.email
          //                 console.log(
          //                   `Admin email: ${adminEmail}, breaking the loop`
          //                 )
          //                 dispatch(
          //                   govActions.reducerLogin({
          //                     email: kontaktniOsoba.email,
          //                   })
          //                 )
          //               } else {
          //                 dispatch(
          //                   govActions.reducerLogin({
          //                     email: kontaktniOsoba.email,
          //                   })
          //                 )
          //               }
          //             }
          //           })
          //         }, 50)
          //     })
          console.log('Found editable users', editableUsers)
          const intervalId = setInterval(() => {
            if (usersChecked === data.kontaktniOsoby.length) {
              clearInterval(intervalId)
              console.log('Found editable users after timeout', editableUsers)
              if (editableUsers.length > 1) {
                console.log(
                  'Found more than one editable user in the backend, this means the admin has logged in.'
                )
                dispatch(govActions.reducerLogin({ email: adminEmail }))
                console.log(
                  `Found Successfully set the admin as the logged in user, with email ${adminEmail}`
                )
              } else if (editableUsers.length === 1) {
                console.log(
                  'Found exactly one editable user in the backend, this means the user has logged in.'
                )
                dispatch(govActions.reducerLogin({ email: editableUsers[0] }))
                console.log(
                  `Found Successfully set the user as the logged in user, with email ${editableUsers[0]}`
                )
              } else {
                console.log(
                  'Found no editable users in the backend, this means the user has not logged in yet.'
                )
              }
            }
          }, 100)
        }

        return data
      })
      .then(() => {
        console.log('Successfully loaded data from the backend.')
        setIsLoading(false)
        console.log('Validating data')
        // console.log('denniKredityZamestnancu: ', denniKredityZamestnancu)

        if (
          (cerstveZmigrovana && !cerstveDokoncilMigraci) ||
          containsIllFormattedAddresses
        ) {
          console.log(
            `Launching the migration tool and redirecting to Settings, because ${
              cerstveZmigrovana
                ? 'the company has migrated and has not finished the migrating tool process on frontend yet'
                : ''
            }${
              containsIllFormattedAddresses
                ? 'ill-formatted addresses were received in /after-login'
                : ''
            }`
          )
          setTimeout(
            () =>
              setRedirectToSettings(
                cerstveZmigrovana ? MIGRACE_DAT : KOREKCE_DAT
              ),
            1000
          )
        }
      })
      .catch((error) => {
        setIsError(error)
        setIsLoading(false)
        console.log(error)
      })
  }

  const skupiny = useAppSelector(selectGroups) as string[]
  const [selectedSkupina, setSelectedSkupina] = useState('')
  const [newSkupinaName, setNewSkupinaName] = useState('')

  const haveCalledAfterLogin = useAppSelector(
    (state) => state.gov
  ).haveCalledAfterLogin

  const [bigWindow, setBigWindow] = useState(
    window.matchMedia('(min-width: 1100px)').matches
  )

  // MONADS
  const writer = (value: any) => [value, []]
  const unit = (value: any) => [value, []]

  const squared = (x: number) => [x * x, [`${x} was squared.`]]
  const halved = (x: number) => [x / 2, [`${x} was halved.`]]

  // const bind = (m: any) => (f: any) => f(m[0]) // from Copilot
  const bind = (writer: any, transform: any) => {
    const [value, log] = writer
    const [result, updates] = transform(value)
    return [result, log.concat(updates)]
  }
  // const map = (m: any, f: any) => bind(m, (value) => writer(f(value))) // from Copilot
  const pipelog = (writer: any, ...transforms: any[]) =>
    transforms.reduce(bind, writer)

  useEffect(() => {
    console.log('TableContainer - useEffect')
    logEmployees()
    dispatch(resetVisitedLocation())

    // const firstCompany = table[0]!.cardStatus -> this is for testing exception throwing

    if (!haveCalledAfterLogin) {
      if (!cerstveProselOnboardingem && !cerstveDokoncilMigraci) {
        callBackendAfterLogin()
        dispatch(govActions.reducerHaveCalledAfterLogin(true))
      }
    }

    window
      .matchMedia('(min-width: 1100px)')
      .addEventListener('change', (e) => setBigWindow(e.matches))

    // perform before render so that the user doesn't see wrong price
    dispatch(tableActions.markDeactivatedMonthsUpUntilNow(beginningOfTime))

    // DENNI KREDIT
    // if denni kredit was not set yet, set it to the value from onboarding or the default (fail-safe)
    let newDenniKredit = denniKredit
    if (newDenniKredit < 1) {
      newDenniKredit =
        onboardingDenniKreditNaStravu > 1
          ? onboardingDenniKreditNaStravu
          : DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU
      dispatch(reducerDenniKredit(onboardingDenniKreditNaStravu))
    }
    setStateDenniKredit(newDenniKredit.toString())

    // DENNI KREDITY ZAMESTNANCU
    let newDenniKredity: { id: string; kredit: number }[] = []
    newDenniKredity = [...denniKredityZamestnancu]
    table.forEach((e, i) => {
      const denniKreditZamestnance = newDenniKredity.find((d) => d.id === e.id)
      if (!denniKreditZamestnance || denniKreditZamestnance.kredit < 1) {
        newDenniKredity.splice(i, 1, {
          id: e.id,
          kredit: newDenniKredit,
        })
      }
    })
    // dispatch(eraseDenniKredityZamestnancu())
    newDenniKredity.forEach((e) => {
      dispatch(reducerRemoveDenniKreditZamestnance({ id: e.id }))
      dispatch(
        reducerAddDenniKreditZamestnance({
          id: e.id,
          kredit: e.kredit,
        })
      )
    })
    dispatch(reducerEmpDetailRollover(false))

    if (!beginningOfTime) {
      restartBeginningOfTime()
    }

    // set up dummy data
    if (process.env.REACT_APP_CLEAR_SELFCARE_DATA === 'on_refresh') {
      clearTables('/')
    }
    if (
      process.env.REACT_APP_INIT_DUMMY_DATA === 'on_refresh' &&
      !table.length
    ) {
      initDummyData()
    }
    verne('TableContainer#435 - end of useEffect()')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denniKredit])

  function clearTables(pojistka: string) {
    if (pojistka !== '/') return
    dispatch(govActions.removeAllGroups())
    dispatch(govActions.removeAllAddresses())
    dispatch(govActions.removeAllUsers())
    // dispatch(govActions.reducerSpolecnosti([])) // this one causes flickering of nazev spolecnosti in TopNavBar
    dispatch(tableActions.removeAllRowsFromTheTable())
    // this one isn't even doing anything, I guess, as all the rows get removed in the line above
    dispatch(tableActions.removeAllHistoricalOrders())
    dispatch(overviewActions.removeAllRowsFromTheTable())
  }

  function initDummyData() {
    dispatch(govActions.removeAllGroups())

    let tempSkupiny = [...skupiny] || ['Nezařazení']
    tempSkupiny.push('Hrad', 'Zámek', 'Obchod', 'Výroba')
    tempSkupiny.forEach((e) => dispatch(govActions.addGroup(e)))

    let tempAdresy: Address[] = [...addresses]
    tempAdresy.push({
      uliceCisloPopisne: 'Libušská 319/126',
      mesto: 'Praha',
      psc: 14200,
      nazevSpolecnosti: 'SAPA',
      telefon: 724724724,
      predvolba: 420,
      kontaktniOsoba: 'Jiří Šimáček',
      oddeleni: ['Žádné'],
    })
    tempAdresy.forEach((e) => {
      dispatch(govActions.addAddress(e))
      createAdresaBackend(e)
    })

    for (let i = 1; i <= 3; i++) {
      dispatch(
        tableActions.addRowToTheTable({
          id: i,
          name: randomFirstName(),
          surname: randomLastName(),
          group: tempSkupiny[i % tempSkupiny.length] ?? '',
          cardStatus: randomCardStatus(),
          uliceCisloPopisne:
            tempAdresy[i % tempAdresy.length].uliceCisloPopisne,
          historicalOrders: [],
        })
      )
    }
    for (let i = 3; i <= 5; i++) {
      const surname = randomLastName()
      dispatch(
        tableActions.addRowToTheTable({
          id: i,
          name: randomFirstName(),
          surname: surname,
          group: tempSkupiny[i % tempSkupiny.length],
          cardStatus: randomCardStatus(),
          uliceCisloPopisne:
            tempAdresy[i % tempAdresy.length].uliceCisloPopisne,
          historicalOrders: [
            {
              id: `00${i}`,
              date: new Date(),
              leisure: `${((i + 300) * (i + i + i) + i) % 10000}`,
              gastro: 25,
            },
          ],
        })
      )
    }
  }

  function someNonDeactivableEmployeeSelected(): boolean {
    return selectedEmployees.some((e) => !isDeactivatable(e))
  }

  function someDeactivableEmployeeSelected(): boolean {
    return selectedEmployees.some((e) => isDeactivatable(e))
  }

  function someNonDeletableEmployeeSelected(): boolean {
    return selectedEmployees.some((e) => !isDeletable(e))
  }

  function someDeletableEmployeeSelected(): boolean {
    return selectedEmployees.some((e) => isDeletable(e))
  }

  function canOrder() {
    return (
      totalPrice > 0 ||
      pocetNovychKaretKObjednani + pocetNahradnichKaretKObjednani > 0
    )
  }

  function zmenitAdresuOznacenym() {
    const uliceCisloPop = addresses[selectedAddressIndex].uliceCisloPopisne
    const oddeleni = addresses[selectedAddressIndex].oddeleni[0]
    dispatch(
      tableActions.setUliceCisloPopisneOznacenym({
        uliceCisloPopisne: uliceCisloPop,
        oddeleni: oddeleni,
      })
    )
    dispatch(tableActions.deselectAll())
    selectedEmployees.forEach((e) => {
      changeZamestnanecBackend(
        e.id,
        e.name,
        e.surname,
        uliceCisloPop,
        addresses[selectedAddressIndex].psc,
        oddeleni,
        e.paidMonths,
        e.deactivatedMonths
      )
    })
  }

  const [isDeaktivniPohledActive, setIsDeaktivniPohledActive] = useState(false)
  const totalPrice = useAppSelector(selectTableTotalPrice) || 0
  const totalVolnocasovy = useAppSelector(selectTableTotalVolnocasovy) || 0
  const totalStravenkovy = useAppSelector(selectTableTotalStravenkovy) || 0
  const totalSelectActive = useAppSelector(selectTotalSelectedActive)
  const totalSelectDeactivated = useAppSelector(selectTotalSelectedDeactivated)
  let totalSelected = isDeaktivniPohledActive
    ? totalSelectDeactivated
    : totalSelectActive

  const [hromadnyVolnocasKredit, setHromadnyVolnocasKredit] = useState(0)
  const [hromadnyStravKredit, setHromadnyStravKredit] = useState(0)

  const [objednatRollover, setObjednatRollover] = useState(false)
  useOnEscapeClick(objednatRollover, setObjednatRollover)

  const [recapRollover, setRecapRollover] = useState(false)

  function closeRollovers() {
    setObjednatRollover(false)
    setRecapRollover(false)
  }

  // Bottom buttons
  const [popoverSkupinySelect, setPopoverSkupinySelect] = useState(false)
  const [popoverHromadneZadaniSelect, setPopoverHromadneZadaniSelect] =
    useState(false)

  const zadaniStravKredituCastkaKreditu =
    useAppSelector(selectUserSettings).zadaniStravKredituCastkaKreditu

  function handleVlozitHromadnouObjednavkuDoKosiku() {
    setPopover('')
    if (hromadnyVolnocasKredit > 0) {
      dispatch(
        tableActions.setVolnocasovyToAllSelected({
          amount: hromadnyVolnocasKredit,
        })
      )
    }
    if (zadaniStravKredituCastkaKreditu) {
      if (hromadnyStravKredit > 0) {
        dispatch(
          tableActions.setStravenkovyToAllSelected({
            amount: hromadnyStravKredit,
          })
        )
      }
    } else {
      if (+pocetDnu > 0) {
        dispatch(
          tableActions.setStravenkovyToAllSelected({
            amount: +pocetDnu * +stateDenniKredit,
          })
        )
        let newDenniKredityZamestnancu = [...denniKredityZamestnancu]
        selectedEmployeeIds.forEach((id) => {
          console.log('newDenniKredityZamestnance', id, stateDenniKredit)
          const index = newDenniKredityZamestnancu.findIndex((e) => e.id === id)
          if (index > -1) {
            newDenniKredityZamestnancu.splice(index, 1, {
              id: id,
              kredit: +stateDenniKredit,
            })
          }
          dispatch(reducerRemoveDenniKreditZamestnance({ id: id }))
          dispatch(
            reducerAddDenniKreditZamestnance({
              id: id,
              kredit: newDenniKredityZamestnancu[index].kredit,
            })
          )
        })
        console.log('newDenniKredityZamestnance', newDenniKredityZamestnancu)
      }
    }
    dispatch(tableActions.deselectAll())
    setStateDenniKredit(denniKredit.toString()) // reset again to the default value from the settings (gear icon)
  }

  const dorucovaciAdresyMap = useDorucovaciAdresyMap()

  function deleteSelected() {
    dispatch(tableActions.deleteEligibleSelected())
    dispatch(tableActions.deselectAll())
  }

  function deactivateSelected() {
    const deactivatableEmployees = selectedEmployees.filter((e) =>
      isDeactivatable(e)
    )
    deactivatableEmployees.map((e) => {
      mutateAktivovanyBackend(e.id, false)
    })
    dispatch(tableActions.deactivateEligibleSelected())
    dispatch(reducerEmpDetailRollover(false))
    dispatch(tableActions.deselectAll())
  }
  function reactivateSelected() {
    // 1. Reactivate the employees in our redux
    // 2. Reactivate the employees in the backend:
    //   - clear the current month's bit of deactivatedMonths
    //   - reset the deactivatedFrom to null by calling the fancy endpoint mutateAktivovanyBackend
    dispatch(
      tableActions.reactivateEmployees({
        employeeIds: selectedEmployees.flatMap((e) => e.id),
        beginningOfTime: beginningOfTime,
      })
    )

    selectedEmployees.forEach((e) => {
      const address = findMatchingAddress(
        e.uliceCisloPopisne,
        addresses
      ) as Address
      const zamestnanecBackend: ZamestnanecBackendEditace = {
        id: +e.id,
        jmeno: e.name,
        prijmeni: e.surname,
        adresa: addressToAdresaBackendKlic(address, e.oddeleni),
        // clear the current month's bit of deactivatedMonths
        deaktivovaneMesice:
          e.deactivatedMonths & ~bitMaskOfTheCurrentMonth(beginningOfTime),
        zaplaceneMesice: e.paidMonths,
        // deaktivovanoOd: null, // this gets done by backend on their own in the mutateAktivovanyBackend call below. It's just for the sake of clarity
      }
      mutateZamestnanecBackend(zamestnanecBackend)
      /* Backend manages setting the deactivatedFrom to new Date() on deactivation and null on reactivation 
      (confirmed in Teams channel with Patrik on 25.4.) */
      mutateAktivovanyBackend(e.id, true)
    })
  }
  // function eraseAllOrders() {
  //   dispatch(tableActions.eraseAllOrderValues())
  // }
  function getTotalPriceWithSpace(): string {
    return numberWithSpaces(totalPrice.toString())
  }
  const pocetNovychKaretKObjednani = useAppSelector(
    selectPocetNovychKaretKObjednani
  )
  const pocetNahradnichKaretKObjednani = useAppSelector(
    selectPocetNahradnichKaretKObjednani
  )

  function getZasilkyForNewOrder(): Zasilka[] {
    const zasilky: Zasilka[] = []

    dorucovaciAdresyMap.forEach((adresa, i) => {
      const matchingAddress = findMatchingAddress(
        adresa.key.uliceCisloPopisne,
        addresses
      ) as Address
      const zasilka: Zasilka = {
        number: i + 1,
        dorucovaciAdresa: matchingAddress,
        pocetNovychKaret: adresa.pocetNovychKaret,
        pocetNahradnichKaret: adresa.pocetNahradnichKaret,
        stavZasilky:
          adresa.pocetNahradnichKaret + adresa.pocetNovychKaret > 0
            ? ConsignmentState.ČekáNaZpracování
            : ConsignmentState.ŽádnéKarty,
      }
      zasilky.push(zasilka)
    })

    return zasilky
  }

  const kontaktniEmail = useAppSelector(selectKontaktniEmail)

  const totalPriceWithFeesAndDelivery = useTotalPriceWithFeesAndDelivery()
  const poznamkaNaFakture = useAppSelector(selectPoznamkaNaFakture)
  const ordersOverview = useAppSelector((state) => state.overview)

  const cenik = useAppSelector(selectCenik) as Cenik
  // for backend
  const employeesWithAnOrder = useAppSelector(
    selectEmployeesWithAnOrderItem
  ) as TableRow[]
  const employeesWithoutAnOrder = useAppSelector(
    selectEmployeesWithoutAnOrderItem
  ) as TableRow[]

  function pripravObjednavkuProFrontend(
    orderId: number,
    email: string,
    consignments: Zasilka[]
  ): Order {
    console.log('pripravObjednavkuProFrontend', orderId, email, consignments)
    // ALWAYS USE TYPES, we were fixing this error for so long! instead of field "orderId", we provided field "id"
    const order: Order = {
      orderId: orderId,
      owner: currentUser
        ? currentUser.jmeno + ' ' + currentUser.prijmeni
        : 'no current user',
      date: new Date(),
      price: totalPriceWithFeesAndDelivery,
      note: poznamkaNaFakture || '',
      contactEmail: email,
      orderState: OrderState.ČekáNaZaplacení,
      // delivery on backend means consignment on frontend
      deliveryState:
        pocetNovychKaretKObjednani + pocetNahradnichKaretKObjednani > 0
          ? ConsignmentState.ČekáNaZpracování
          : ConsignmentState.ŽádnéKarty, // if zero cards, then no delivery
      zasilky: consignments,
      leisureCredits: totalVolnocasovy,
      gastroCredits: totalStravenkovy,
      spravaUctu: monthsToPay * cenik.spravaJednohoUctu, // todo remove this field
      vydaniPrvniKartyPrice: cenik.vydaniPrvniKarty,
      vydaniNahradniKartyPrice: cenik.vydaniNahradniKarty,
      uskutecnenyPoplatekDoruceni: cenik.doruceni * consignments.length,
      feeLeisure: cenik.volnocas,
      feeGastro: cenik.stravenky,
      uskutecnenyPoplatekGastro: 0, // here it doesn't matter, it matters when we load it back from backend
      uskutecnenyPoplatekLeisure: 0, // here it doesn't matter, it matters when we load it back from backend
      uskutecnenyPoplatekSpravaUctu: monthsToPay * cenik.spravaJednohoUctu,
    }
    return order
  }

  function getInternalId(): number {
    const unresolvedOrders = ordersOverview.filter(
      (o) => +o.orderId >= INTERNAL_IDX
    )
    // get internal order id and replace its last 3 digits with last 3 digits of the last unresolved order id
    const lastOrderId =
      unresolvedOrders.length > 0
        ? INTERNAL_ID.substring(0, -3) +
          unresolvedOrders[unresolvedOrders.length - 1].orderId
            .toString()
            .substring(-3) // just for certainty, someone could have put a number there
        : INTERNAL_ID
    // replace last digit with last digit + 1, if last digit is nine, replace with zero and add one to the next digit
    let lastDigit = parseInt(lastOrderId.slice(lastOrderId.length - 1))
    lastDigit = lastDigit === 9 ? 0 : lastDigit + 1 // well, we do not add 1 to the next digit, but let's hope backend will not be delayed by more than 10 orderIds...
    let internalId = (+lastOrderId.slice(0, -1) +
      lastDigit.toString()) as unknown as number
    // console.log('odeslat objednavku,', internalId)
    return internalId
  }

  function odeslatObjednavku() {
    const internalId: number = getInternalId()
    const kontaktniEmailProBackend: string = getKontaktniEmailProBackend()
    const consignments: Zasilka[] = getZasilkyForNewOrder()

    // ORD-2103 - Nesprávná aplikace měsíčního poplatku v případě spadlé objednávky/neodeslané: we do some pre-processing here that we do not remove if the order is not sent
    const state: TableRow[] = objednavkaPreProcessing()

    function getPreprocessedEmployee(id: string): TableRow {
      return state.find((e) => e.id === id)!
    }

    // send order to backend
    const orderForBackend: NovaObjednavkaBackend = {
      noviZamestnanci: employeesWithAnOrder
        .filter((e) => isNew(e))
        .map((employee) => {
          if (!employee.uliceCisloPopisne) {
            employee.uliceCisloPopisne = addresses[0].uliceCisloPopisne
          }

          const { ulice, cisloPopisne } = splitUliceCisloPopisne(
            employee.uliceCisloPopisne
          )
          const matchingAddress =
            (findMatchingAddress(
              employee.uliceCisloPopisne,
              addresses
            ) as Address) || addresses[0] // if not found (because of migration or because someone creates employees without selecting an address), use first address
          console.log(
            `new employee, id ${employee.id} that is being sent to backend with paid and deactivated months`,
            padWithZerosFromTheLeftTo32Bits(
              getPreprocessedEmployee(employee.id).paidMonths
            ),
            padWithZerosFromTheLeftTo32Bits(
              getPreprocessedEmployee(employee.id).deactivatedMonths
            )
          )
          return {
            jmeno: employee.name,
            prijmeni: employee.surname,
            cislo: +employee.id,
            adresa: {
              psc: matchingAddress.psc.toString(),
              ulice: ulice,
              cisloPopisne: cisloPopisne, // keep as string because of possible letters in cp
              // POTENTIAL SOURCE OF BUGS AHEAD : '' not translating to 'žádné' but to INTERNAL_ID
              oddeleni:
                employee.oddeleni === '' ? INTERNAL_ID : employee.oddeleni, // because backend doesn't accept empty string as oddeleni, we need to replace it with INTERNAL_ID,
            },
            zaplaceneMesice:
              getPreprocessedEmployee(employee.id).paidMonths || 0,
            deaktivovaneMesice:
              getPreprocessedEmployee(employee.id).deactivatedMonths || 0,
          }
        }),
      zamestnanciBezObjednavky: employeesWithoutAnOrder.map(
        (employee: TableRow) => {
          return {
            cislo: +employee.id,
            zaplaceneMesice: getPreprocessedEmployee(employee.id).paidMonths,
            deaktivovaneMesice: getPreprocessedEmployee(employee.id)
              .deactivatedMonths,
          }
        }
      ),
      objednavka: {
        polozky: {
          volnocasovyKredit: {
            cena: totalVolnocasovy,
            ks: 1,
            dph: 0.0,
            total: totalVolnocasovy * 1,
          },
          stravenkovyKredit: {
            cena: totalStravenkovy,
            ks: 1,
            dph: 0.0,
            total: totalStravenkovy * 1,
          },
          poplatekVolnocasovyKredit: {
            cena: roundToTwo(
              minimumXIfNotZero(
                cenik.minVolnocasFee,
                cenik.volnocas,
                totalVolnocasovy
              )
            ),
            ks: 1,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) *
                minimumXIfNotZero(
                  cenik.minVolnocasFee,
                  cenik.volnocas,
                  totalVolnocasovy
                )
            ),
          },
          poplatekStravenkovyKredit: {
            cena: roundToTwo(
              minimumXIfNotZero(
                cenik.minStravenkyFee,
                cenik.stravenky,
                totalStravenkovy
              )
            ),
            ks: 1,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) *
                minimumXIfNotZero(
                  cenik.minStravenkyFee,
                  cenik.stravenky,
                  totalStravenkovy
                )
            ),
          },
          vydaniPrvniMPC: {
            cena: cenik.vydaniPrvniKarty,
            ks: pocetNovychKaretKObjednani,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) * cenik.vydaniPrvniKarty * pocetNovychKaretKObjednani
            ),
          },
          vydaniNahradniMPC: {
            cena: cenik.vydaniNahradniKarty,
            ks: pocetNahradnichKaretKObjednani,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) *
                cenik.vydaniNahradniKarty *
                pocetNahradnichKaretKObjednani
            ),
          },
          doruceniMPC: {
            cena: cenik.doruceni,
            ks: consignments.length,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) * cenik.doruceni * consignments.length
            ),
          },
          spravaJednohoUctu: {
            cena: cenik.spravaJednohoUctu,
            ks: monthsToPay,
            dph: 0.21,
            total: roundToTwo(
              (1 + 0.21) * cenik.spravaJednohoUctu * monthsToPay
            ),
          },
        },
        zamestnanci: employeesWithAnOrder.map((employee: TableRow) => {
          const karta = shouldOrderEmployeeACard(employee)
          console.log(
            `existing employee with an order, id ${employee.id} is being sent to backend with paid and deactivated months`,
            padWithZerosFromTheLeftTo32Bits(
              getPreprocessedEmployee(employee.id).paidMonths
            ),
            padWithZerosFromTheLeftTo32Bits(
              getPreprocessedEmployee(employee.id).deactivatedMonths
            )
          )
          return {
            cislo: +employee.id,
            volnocasovyKredit: +employee.leisureCredits || null, // Patrik wants null instead of 0
            stravenkovyKredit: +employee.gastroCredits || null, // same as above
            karta: karta,
            zaplaceneMesice: getPreprocessedEmployee(employee.id).paidMonths,
            deaktivovaneMesice: getPreprocessedEmployee(employee.id)
              .deactivatedMonths,
            // todo 22.11.22: uncomment when implemented on backend
            // isLoggedInUser: employee.isLoggedInUser || false, // doNotCreateNewSpoAccount
          }
        }),
        cena: totalPriceWithFeesAndDelivery,
        kontaktniEmail: kontaktniEmailProBackend,
        poznamka: poznamkaNaFakture || '',
      },
    }
    console.log('orderForBackend', orderForBackend)

    // mutate all employees without an order in order to propagate their paid and deactivated months to backend
    console.log(
      '### TODO Move "zamestnanci" to first level on /objednavka request body on backend and Purge the following sacrilege after backend implements processing of "zamestnanciBezObjednavky" attribute in /objednavka. Mutating state for all employeesWithoutAnOrder',
      employeesWithoutAnOrder
    )
    employeesWithoutAnOrder.forEach((e: TableRow) => {
      // console.log('employeesWithoutAnOrder, mutating employee', e)
      const address = findMatchingAddress(
        e.uliceCisloPopisne,
        addresses
      ) as Address
      const zamestnanecBackend: ZamestnanecBackendEditace = {
        id: +e.id,
        jmeno: e.name,
        prijmeni: e.surname,
        adresa: addressToAdresaBackendKlic(address, e.oddeleni),
        zaplaceneMesice: getPreprocessedEmployee(e.id).paidMonths,
        deaktivovaneMesice: getPreprocessedEmployee(e.id).deactivatedMonths,
      }
      mutateZamestnanecBackend(zamestnanecBackend)
    })

    // console.log('Months To Pay', monthsToPay)
    const orderForFrontend: Order = pripravObjednavkuProFrontend(
      internalId,
      kontaktniEmailProBackend,
      consignments
    )
    dispatch(overviewActions.addOrder(orderForFrontend))

    const requestTimeoutMs = 30 * 1000 // 30 seconds

    Promise.race([
      sendObjednavkaToBackendAndPrettifyId(orderForBackend),
      timeout(requestTimeoutMs),
    ])
      .then((data) => {
        let backendId = data
        console.log(
          'objednavka post processing, frontendId, backendId',
          internalId,
          backendId
        )
        objednavkaPostProcessing(internalId, backendId)
        console.log(
          'objednavkaPostProcessing done. Redirecting to overview to show order summary + hotjar'
        )
        navigate(`${urlOrdersOverview}/~${backendId}`) // to launch Petra's hotjar
      })
      .catch((error) => {
        // Handle the error or timeout case
        navigate(`${urlOrdersOverview}/~${internalId}-!`) // to launch Petra's hotjar
        console.error('An error occurred or the request timed out:', error)
      })

    maintainEndOfTimeTemporalSensitive()

    // reset the input field poznamka na fakture!
    dispatch(reducerPoznamkaNaFakture(''))

    setRedirectToOrdersOverview(true)
  }

  function objednavkaPreProcessing(): TableRow[] {
    // the following two functions are also replicated for local state below, which is
    // then read for sending the updated values to backend
    const monthsElapsed: number =
      monthsElapsedBeginningInclusive(beginningOfTime)

    dispatch(
      tableActions.changeNewFlagToDeactivatedMonthsUpUntilLastMonth(
        monthsElapsed
      )
    )
    dispatch(tableActions.markPaidMonthsUpUntilLastMonth(monthsElapsed))

    const state = table.map((row) => ({
      ...row,
      deactivatedMonths: getDeactivatedMonthsForNewEmployees(row),
      paidMonths: getPaidMonths(row),
    }))

    function getDeactivatedMonthsForNewEmployees(row: TableRow): number {
      let deactivatedMonths = row.deactivatedMonths
      if (row.flag === 'new' && monthsElapsed !== 0) {
        for (let i = 0; i < monthsElapsed; i++) {
          const monthBit = ONE >> i
          deactivatedMonths |= monthBit
        }
      }
      return deactivatedMonths
    }

    function getPaidMonths(row: TableRow): number {
      let paidMonths = row.paidMonths
      if (row.flag === 'new' && monthsElapsed !== 0) {
        // pay only for the current month, do not pay for the months that get deactivated
        paidMonths |= ONE >> bitMaskOfTheCurrentMonth(beginningOfTime)
      } else {
        for (let i = 0; i < monthsElapsed; i++) {
          const monthBit = ONE >> i
          if (!(monthBit & row.deactivatedMonths)) {
            paidMonths |= monthBit
          }
        }
      }
      return paidMonths
    }

    const paddedMaskOfTheCurrentMonth = padWithZerosFromTheLeftTo32Bits(
      bitMaskOfTheCurrentMonth(beginningOfTime)
    )
    console.log(
      'paddedMaskOfTheCurrentMonth, months elapsed',
      paddedMaskOfTheCurrentMonth,
      monthsElapsed
    )

    return state
  }

  function objednavkaPostProcessing(frontendId: number, backendId: number) {
    // console.log('Promise.all, ids: ', frontendId, backendId)
    if (typeof backendId !== 'number') {
      console.error('backendId is not a number')
      throw new Error('backendId is not a number, it is: ' + backendId) // let's fail-fast instead of fail-safe
      // backendId = +lastOrderId + 1 // if backendId is not a number, they failed to provide it. We add 1 to the last orderId.
    }

    dispatch(
      overviewActions.reducerReplaceCisloObjednavky({
        callerId: frontendId,
        newId: backendId,
      })
    )

    table.forEach((row) => {
      // reinstate "objednat náhradní kartu" checkbox to false
      if (row.objednatNahradniKartu) {
        dispatch(
          tableActions.checkBoxObjednatNahradniKartu({
            id: row.id,
            checkBoxObjednatNahradniKartu: false,
          })
        )
      }
      // put orders of credits into historic orders by scanning the actual values on rows at the time of order
      if (row.leisureCredits > 0 || row.gastroCredits > 0) {
        dispatch(
          tableActions.addHistoricOrder({
            employeeId: row.id,
            orderId: backendId,
            leisure: row.leisureCredits,
            gastro: row.gastroCredits,
          })
        )
      }
    })

    // Cards are created with orderId, employees cards go from state MultiPassCardStatus.VKošíku to MultiPassCardStatus.VeVýrobě, credits v košíku reset to zero
    dispatch(tableActions.orderSentTablePostProcessing({ orderId: backendId }))
    // todo these seem not to work, try
    // dispatch(tableActions.eraseAllOrderValues())
  }

  function getKontaktniEmailProBackend(): string {
    const kontaktniEmailProBackend =
      kontaktniEmail || login.email || pracovniEmail
    dispatch(reducerKontaktniEmail(kontaktniEmailProBackend))
    console.log('kontaktni email pro backend,', kontaktniEmailProBackend)
    return kontaktniEmailProBackend
  }

  function maintainEndOfTimeTemporalSensitive() {
    // ONLY CALL AFTER AN ORDER HAS BEEN SENT TO THE BACKEND AND ALL TEMPORAL DATA CAN BE RESET.

    // if the application is reaching its time limit and all management fees have been paid,
    // set the beginning of time to the first day of the current month
    // Česky (pro snazší vysvětlování projekťákům):
    // ,,Reset času se provede pouze při zaplacení objednávky, když uběhlo více jak 24 měsíců od počátku... a
    // všem se začne zapisovat zase od prvního bitu z leva / nultého měsíce od nového počátku času."
    if (monthsElapsedBeginningInclusive(beginningOfTime) > 24) {
      // useRestartBeginningOfTime() // we can use the hook here, because it is not part of an inside function. But to be sure to not cause bugs, let's use our redundant function here
      restartBeginningOfTime()
    }
  }

  function potvrditVytvoritSkupinu() {
    let skupinaProUmistovani: string = ''
    if (newSkupinaName !== '') {
      dispatch(govActions.addGroup(newSkupinaName))
      createSkupina(newSkupinaName)
      skupinaProUmistovani = newSkupinaName
    } else {
      skupinaProUmistovani = selectedSkupina
    }
    dispatch(
      tableActions.setGroupToSelectedEmployees({
        group: skupinaProUmistovani,
      })
    )
    assignSkupina(
      selectedEmployees.map((e) => +e.id),
      skupinaProUmistovani
    )
    dispatch(tableActions.deselectAll())
  }

  function logEmployees() {
    console.groupCollapsed('Employees')
    const rowsForLog = table.map((e) => {
      return {
        id: e.id,
        name: e.name,
        surname: e.surname,
        uliceCisloPopisne: e.uliceCisloPopisne,
        oddeleni: e.oddeleni,
        volnocasovyKredit: e.leisureCredits,
        stravovaciKredit: e.gastroCredits,
        deactivatedMonths: e.deactivatedMonths,
        paidMonths: e.paidMonths,
        deactivatedFrom: e.deactivatedFrom,
      }
    })
    // for (var r in table) {
    //   var row = table[r]
    // }

    // console.groupCollapsed(
    // '%c  %c' + (row.deactivatedFrom ? 'deactivated' : 'active'),
    // 'background-color: ' +
    //   (row.deactivatedFrom ? 'red' : 'green') +
    //   '; margin-right: 10px',
    // 'background-color: transparent'
    //   'table'
    // )
    sinking() &&
      console.table(
        rowsForLog.map((r) => {
          return {
            Id: r.id,
            PaidMonths: r.paidMonths.toString(2),
            DeactivatedMonths: r.deactivatedMonths.toString(2),
          }
        })
      )

    // console.table([
    //   {Id: row.id,},
    //   { PaidMonths: row.paidMonths.toString(2) },
    //   { DeactivatedMonths: row.deactivatedMonths.toString(2) },
    // ])
    // console.groupEnd()
    // console.table(table, [
    //   'id',
    //   'paidMonths',
    //   'deactivatedMonths',
    //   'uliceCisloPopisne',
    // ])
    // console.log('Paid & deactivated months in table')
    // table
    //   // .filter((e) => [4, 5, 6, 7, 8, 9].includes(+e.id))
    //   .forEach(
    //     (e) =>
    //       console.log(
    //         // can use console.count() but not in a place which gets refreshed too often
    //         `${e.id}, ${e.paidMonths.toString(2)}, ${e.deactivatedMonths.toString(
    //           2
    //         )}`
    //       )
    //       // {} // do nothing for now
    //   )
    console.groupEnd()
    verne('TableContainer#775')
  }

  // const orderId = useAppSelector(selectLastOrderId) // this is the id of the order that was just created, its temporary internal representation

  return (
    <div className='table-container-mv'>
      {verne('TableContainer#980')}
      {redirectToOrdersOverview && <Navigate to={`${urlOrdersOverview}/~`} />}
      {redirectToSettings && (
        <Navigate to={`${urlNastaveni}/${redirectToSettings}`} />
      )}
      <div className='u-ml-6 u-px-6 u-pb-4 u-pt-6'>
        {verne('TableContainer#983')}
        <div className='grid-x u-mb-6 u-pb-1'>
          <div className='cell medium-auto'>
            <span className='grid-x table-container__header'>
              <h1 className='u-size-l28 u-ml-1'>{lg(lang, NEW_ORDER)}</h1>
              <Help />
            </span>
          </div>
          <JstePrihlaseniZa />
        </div>

        {/* Objednávací Panel - Karty, Kredit, Košík */}
        {verne('TableContainer#991')}
        <span
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: canOrder() ? 'pointer' : 'default',
          }}
          onClick={() => {
            if (canOrder()) setObjednatRollover(!objednatRollover)
          }}
        >
          <span
            className='u-mr-4 grid-x'
            style={{ marginBottom: bigWindow ? '-32px' : '20px', zIndex: 3 }}
          >
            <span className='u-text-center u-size-base14'>
              {lg(lang, CARDS)} (
              <b>
                {pocetNovychKaretKObjednani + pocetNahradnichKaretKObjednani}
              </b>
              ), {lg(lang, CREDIT)} (<b>{getTotalPriceWithSpace()} Kč</b>)
            </span>
            <svg
              className='modal__trigger icon icon--30'
              style={{
                marginLeft: '6px',
                marginTop: '-12px',
                opacity: canOrder() ? 1 : 0.5,
              }}
            >
              <use
                className='modal__trigger'
                href={icons + '#sprite-shoppingcart'}
              />
            </svg>
            <div
              className='number-circle'
              style={{
                cursor: canOrder() ? 'pointer' : 'default',
                margin: '-20px 0px 0px -16px',
                // opacity: canOrder() ? 1 : 0.7,
                zIndex: 4,
              }}
              onClick={() => {
                if (canOrder()) setObjednatRollover(!objednatRollover)
              }}
            >
              {employeesWithAnOrder.filter((e) => !e.deactivatedFrom).length}
            </div>
          </span>
        </span>
        {/* Částky (Karty, Kredit) + Objednat */}
        {verne('TableContainer#1038')}
        {isLoading || isError ? (
          <span
            style={{ fontSize: '150px', position: 'fixed', color: Color.RED }}
            className='loader centered'
          ></span>
        ) : (
          // ) : isError ? (
          //   <span
          //     className='input__error'
          //     style={{ marginTop: 40, fontSize: '20px', whiteSpace: 'pre-line' }}
          //   >
          //     {`Nahrávání Vašich dat z databáze nedopadlo dobře. Kontaktujte podporu.
          //     \n
          //     Chybová hláška: ${isError}`}
          //   </span>
          <TableThree
            stateChanger={setIsDeaktivniPohledActive}
            closeRollovers={closeRollovers}
            popover={popover}
            setPopover={setPopover}
          />
        )}
        {/* SPODNI PANEL */}
        {verne('TableContainer#1053')}
        <div className='u-fixed-bottom u-desktop-only u-mb-2 u-pl-4 u-pr-4'>
          {!isDeaktivniPohledActive ? (
            <div className='user-bar'>
              <div className='box box--small-radius u-ml-6'>
                <div className='box__content u-overflow-visible u-px-2 u-py-1'>
                  <div className='grid-x align-middle u-size-s'>
                    {/* Badge - Kolik Vybráno */}
                    <div className='cell medium-shrink u-pr-4'>
                      <div className='badge u-my-05 u-mr-1 badge--40 badge--blue'>
                        <div className='badge__content'>{totalSelected}</div>
                      </div>{' '}
                      {totalSelected < 1 ? (
                        `${lg(lang, SELECT_EMPLOYEES_BULK_ACTION)}`
                      ) : (
                        <span>
                          {lg(lang, SELECTED)}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      )}
                    </div>
                    {totalSelected > 0 && (
                      <div
                        data-bar='menu'
                        className='cell medium-auto grid-x u-my-1'
                      >
                        {/* Hromadné zadání kreditu */}
                        <div className='popover__wrapper'>
                          <button
                            onClick={() => {
                              setPopover(
                                popover === 'hromadneZadani'
                                  ? ''
                                  : 'hromadneZadani'
                              )
                              // setModalOpen(!isModalOpen)
                            }}
                            className='button-as-anchor modal__trigger cell shrink u-my-05 u-mr-3'
                          >
                            <svg className='modal__trigger icon icon--16'>
                              <use
                                className='modal__trigger'
                                href={icons + '#sprite-bulk'}
                              ></use>
                            </svg>{' '}
                            Zadání kreditu
                          </button>
                          {popover === 'hromadneZadani' && (
                            <div
                              className='modal__trigger popover popover--open'
                              // @ts-ignore
                              ref={ref}
                            >
                              <div className='modal__trigger popover__body'>
                                <h3 className='modal__trigger u-size-l20 u-mb-1'>
                                  Zadání kreditu
                                </h3>
                                <p className='modal__trigger u-size-s u-mb-3'>
                                  Zadejte výši kreditu pro vybrané uživatele.
                                </p>
                                <p className='modal__trigger u-size-base14 u-mb-1'>
                                  Volnočasový kredit
                                </p>
                                <div className='modal__trigger input u-mb-2 input--small input--small-placeholder'>
                                  <div className='modal__trigger input__wrapper'>
                                    <input
                                      className='modal__trigger'
                                      placeholder='Zadejte částku'
                                      type='number'
                                      value={
                                        hromadnyVolnocasKredit === 0
                                          ? ''
                                          : hromadnyVolnocasKredit
                                      }
                                      onChange={(e) =>
                                        setHromadnyVolnocasKredit(
                                          e.currentTarget.valueAsNumber
                                        )
                                      }
                                      onKeyPress={validateNumericKeyWithWhites}
                                      onWheel={(e) => e.currentTarget.blur()}
                                    />
                                  </div>
                                </div>
                                <div className='modal__trigger grid-x align-justify u-mb-1'>
                                  <div className='modal__trigger cell shrink'>
                                    <p className='modal__trigger u-size-base14'>
                                      Stravenkový kredit
                                    </p>
                                  </div>
                                  <div className='modal__trigger cell shrink'>
                                    <div
                                      className={
                                        'modal__trigger select u-color-violet select--transparent' +
                                        (popoverHromadneZadaniSelect
                                          ? ' select--active'
                                          : '')
                                      }
                                    >
                                      <div
                                        className='modal__trigger select__option select__option--selected'
                                        onClick={() =>
                                          setPopoverHromadneZadaniSelect(
                                            !popoverHromadneZadaniSelect
                                          )
                                        }
                                      >
                                        <span className='modal__trigger'>
                                          {zadaniStravKredituCastkaKreditu
                                            ? lg(lang, CREDIT_AMOUNT)
                                            : lg(lang, DAYS_WORKED)}
                                        </span>
                                        <svg className='modal__trigger icon icon--24'>
                                          <SortingDownIcon className='modal__trigger' />
                                        </svg>
                                      </div>
                                      <div className='modal__trigger select__options'>
                                        <div
                                          className='modal__trigger select__option'
                                          onClick={() => {
                                            setPopoverHromadneZadaniSelect(
                                              !popoverHromadneZadaniSelect
                                            )
                                            dispatch(
                                              reducerZadaniStravKredituCastkaKreditu(
                                                !zadaniStravKredituCastkaKreditu
                                              )
                                            )
                                          }}
                                        >
                                          {zadaniStravKredituCastkaKreditu
                                            ? lg(lang, DAYS_WORKED)
                                            : lg(lang, CREDIT_AMOUNT)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='modal__trigger u-mb-4'>
                                  <div className='modal__trigger'>
                                    {zadaniStravKredituCastkaKreditu ? (
                                      <div className='modal__trigger input input--small input--small-placeholder'>
                                        <div className='modal__trigger input__wrapper'>
                                          <input
                                            className='modal__trigger'
                                            placeholder='Zadejte částku'
                                            type='number'
                                            value={
                                              hromadnyStravKredit === 0
                                                ? ''
                                                : hromadnyStravKredit
                                            }
                                            onKeyPress={
                                              validateNumericKeyWithWhites
                                            }
                                            onWheel={(e) =>
                                              e.currentTarget.blur()
                                            }
                                            onChange={(e) =>
                                              setHromadnyStravKredit(
                                                e.currentTarget.valueAsNumber
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className='modal__trigger grid-x align-middle'>
                                        <div className='modal__trigger cell auto'>
                                          <div className='modal__trigger input input--small input--small-placeholder'>
                                            <div className='modal__trigger input__wrapper'>
                                              <input
                                                className='modal__trigger'
                                                placeholder='Počet dnů'
                                                value={pocetDnu}
                                                onChange={(e) => {
                                                  // actually someone might order credits for more than one month
                                                  if (+e.target.value < 999) {
                                                    setPocetDnu(e.target.value)
                                                  } else {
                                                    setPocetDnu('999')
                                                  }
                                                }}
                                                type='tel'
                                                onKeyPress={
                                                  validateNumericKeyWithWhites
                                                }
                                                onWheel={(e) =>
                                                  e.currentTarget.blur()
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className='modal__trigger cell align-center shrink'>
                                          <svg className='modal__trigger icon icon--16 u-mx-1'>
                                            <DeleteThinIcon className='modal__trigger' />
                                          </svg>
                                        </div>
                                        <div className='modal__trigger cell auto'>
                                          <div className='modal__trigger input input--small input--small-placeholder'>
                                            <div className='modal__trigger input__wrapper'>
                                              <input
                                                className='modal__trigger'
                                                placeholder='Denní výše kreditu'
                                                type='tel'
                                                onKeyPress={
                                                  validateNumericKeyWithWhites
                                                }
                                                value={stateDenniKredit}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value.length < 5
                                                  ) {
                                                    setStateDenniKredit(
                                                      e.target.value
                                                    )
                                                  } else {
                                                    setStateDenniKredit('9999')
                                                  }
                                                }}
                                                // disabled
                                              />
                                              {stateDenniKredit.length > 0 && (
                                                <span
                                                  className='modal__trigger input__suffix u-color-dark-purple-blue-alt'
                                                  style={{
                                                    right: '3.5rem',
                                                    fontSize: '1.2rem',
                                                  }}
                                                >
                                                  Kč
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='modal__trigger'>
                                  <a
                                    onClick={
                                      handleVlozitHromadnouObjednavkuDoKosiku
                                    }
                                    className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                  >
                                    <svg className='modal__trigger icon icon--16'>
                                      <use
                                        className='modal__trigger'
                                        href={icons + '#sprite-shoppingcart'}
                                      />
                                    </svg>
                                    Nastavit
                                  </a>
                                </div>
                                <div className='modal__trigger'>
                                  <a
                                    onClick={() =>
                                      setPopover(
                                        popover === 'hromadneZadani'
                                          ? ''
                                          : 'hromadneZadani'
                                      )
                                    }
                                    className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                  >
                                    <svg className='modal__trigger icon icon--16'>
                                      <use
                                        className='modal__trigger'
                                        href={icons + '#sprite-delete-thin'}
                                      />
                                    </svg>
                                    Zrušit
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Vytvořit skupinu */}
                        <button
                          onClick={() => {
                            setPopover(
                              popover === 'vytvoritSkupinu'
                                ? ''
                                : 'vytvoritSkupinu'
                            )
                            setSelectedSkupina('')
                            setNewSkupinaName('')
                          }}
                          className='button-as-anchor modal__trigger cell shrink u-my-05 u-mr-3'
                        >
                          <svg className='modal__trigger icon icon--16'>
                            <use
                              className='modal__trigger'
                              href={icons + '#sprite-employee'}
                            />
                          </svg>{' '}
                          Skupiny
                        </button>
                        {popover === 'vytvoritSkupinu' && (
                          <div
                            // @ts-ignore
                            ref={ref}
                            className='modal__trigger popover popover--open'
                            style={{
                              transform: 'translate(-33rem, -6.5%)',
                            }}
                          >
                            <div className='modal__trigger popover__body'>
                              <h3 className='modal__trigger u-size-l20 u-mb-3'>
                                Vytvořit skupinu
                              </h3>{' '}
                              <p className='modal__trigger u-size-base14 u-mb-2'>
                                Vybrali jste <strong>{totalSelected}</strong>{' '}
                                {kohoCehoZamestnancuSwitch(totalSelected, lang)}
                              </p>{' '}
                              <div
                                className='modal__trigger highlight u-bg-lightest-blue u-px-2 u-py-2 u-mb-1'
                                style={{ position: 'relative', zIndex: 10 }}
                              >
                                <div className='modal__trigger highlight__content'>
                                  <p className='modal__trigger u-size-base14 u-mb-1'>
                                    Přesunout do již existující skupiny
                                  </p>{' '}
                                  <div
                                    onClick={() =>
                                      setPopoverSkupinySelect(
                                        !popoverSkupinySelect
                                      )
                                    }
                                    className={
                                      'modal__trigger select select--wide select--big' +
                                      (popoverSkupinySelect
                                        ? ' select--active'
                                        : '')
                                    }
                                  >
                                    <div className='modal__trigger select__option select__option--selected'>
                                      <span className='modal__trigger'>
                                        {selectedSkupina === ''
                                          ? 'Výběr ze seznamu'
                                          : selectedSkupina}
                                      </span>{' '}
                                      <svg className='modal__trigger icon icon--24'>
                                        <use
                                          className='modal__trigger'
                                          href={
                                            icons +
                                            (popoverSkupinySelect
                                              ? '#sprite-sorting-up'
                                              : '#sprite-sorting-down')
                                          }
                                        ></use>
                                      </svg>
                                    </div>{' '}
                                    <div className='modal__trigger select__options'>
                                      {skupiny &&
                                        skupiny.map((skupina) => (
                                          <div
                                            key={nanoid(5)}
                                            className='modal__trigger select__option'
                                            onClick={() =>
                                              setSelectedSkupina(skupina)
                                            }
                                          >
                                            {skupina}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>{' '}
                              <div className='modal__trigger divider u-mb-1'>
                                <div className='modal__trigger divider__text'>
                                  nebo
                                </div>
                              </div>{' '}
                              <div className='modal__trigger highlight u-bg-light-violet u-px-2 u-py-2 u-mb-2'>
                                <div className='modal__triggerhighlight__content'>
                                  <p className='modal__trigger u-size-base14 u-mb-1'>
                                    Vytvořit novou skupinu
                                  </p>{' '}
                                  <div className='modal__trigger input input--small input--small-placeholder'>
                                    <div className='modal__trigger input__wrapper'>
                                      <input
                                        className='modal__trigger'
                                        placeholder='Např.: Marketing'
                                        type='text'
                                        value={newSkupinaName}
                                        onChange={(e) =>
                                          setNewSkupinaName(e.target.value)
                                        }
                                      />{' '}
                                    </div>{' '}
                                  </div>
                                </div>
                              </div>{' '}
                              <div className='modal__trigger'>
                                <a
                                  onClick={() => {
                                    potvrditVytvoritSkupinu()
                                    setPopover('')
                                  }}
                                  className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                >
                                  <svg className='modal__trigger icon icon--16'>
                                    <use
                                      className='modal__trigger'
                                      href={icons + '#sprite-check'}
                                    ></use>
                                  </svg>
                                  Potvrdit
                                </a>
                              </div>{' '}
                              <div className='modal__trigger'>
                                <a
                                  onClick={() => setPopover('')}
                                  className='btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                >
                                  <svg className='modal__trigger icon icon--16'>
                                    <use
                                      className='modal__trigger'
                                      href={icons + '#sprite-delete-thin'}
                                    ></use>
                                  </svg>
                                  Zrušit
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='modal__trigger popover__wrapper'>
                          <button
                            onClick={() =>
                              setPopover(
                                popover === 'zmenitAdresu' ? '' : 'zmenitAdresu'
                              )
                            }
                            className='button-as-anchor modal__trigger cell shrink u-my-05 u-mr-3'
                          >
                            <svg className='modal__trigger icon icon--16'>
                              <use
                                className='modal__trigger'
                                href={icons + '#sprite-geopin-outline'}
                              />
                            </svg>{' '}
                            Změnit adresu
                          </button>
                          {popover === 'zmenitAdresu' && (
                            <div
                              // @ts-ignore
                              ref={ref}
                              className='modal__trigger popover popover--big popover--open'
                            >
                              <div className='modal__trigger popover__body'>
                                <div className='modal__trigger grid-x align-bottom u-mb-3'>
                                  <div className='modal__trigger cell auto'>
                                    <h3 className='modal__trigger u-size-l20'>
                                      Změna adresy
                                    </h3>
                                  </div>
                                  <div className='modal__trigger cell shrink'>
                                    <a
                                      href={urlNastaveni + '/#nastaveni-adresy'}
                                      className='modal__trigger u-size-s'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-pen'}
                                        />
                                      </svg>
                                      Nastavení firemních adres
                                    </a>
                                  </div>
                                </div>
                                {addresses.map((address, i) => (
                                  <div
                                    onClick={() => setSelectedAddressIndex(i)}
                                    className='modal__trigger u-mb-2'
                                  >
                                    <div
                                      className={
                                        'modal__trigger input input--small input--clickable' +
                                        (i === selectedAddressIndex
                                          ? ' input--selected'
                                          : '')
                                      }
                                    >
                                      <div className='modal__trigger input__wrapper'>
                                        <input
                                          className='modal__trigger'
                                          value={
                                            (address.nazevSpolecnosti || '') +
                                            ' ' +
                                            (address.uliceCisloPopisne || '') +
                                            ' ' +
                                            (address.mesto || '') +
                                            ' ' +
                                            formatPsc(address.psc)
                                          }
                                          placeholder='Adresa'
                                          disabled={true}
                                          type='text'
                                        />
                                      </div>
                                      {i === selectedAddressIndex && (
                                        <div className='modal__trigger input__icon'>
                                          <svg className='modal__trigger icon icon--24'>
                                            <use
                                              className='modal__trigger'
                                              href={
                                                icons + '#sprite-check-circle'
                                              }
                                            />
                                          </svg>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                <div className='modal__trigger grid-x'>
                                  <div className='modal__trigger cell auto u-pr-1'>
                                    <a
                                      onClick={() => {
                                        zmenitAdresuOznacenym()
                                        setPopover('')
                                      }}
                                      className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-check'}
                                        />
                                      </svg>
                                      Změnit
                                    </a>
                                  </div>
                                  <div className='modal__trigger cell auto u-pl-1'>
                                    <a
                                      onClick={() => setPopover('')}
                                      className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-delete-thin'}
                                        />
                                      </svg>
                                      Zrušit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {someDeactivableEmployeeSelected() && (
                          <div className='modal__trigger popover__wrapper u-mr-3'>
                            <button
                              onClick={() =>
                                setPopover(
                                  popover === 'deaktivovat' ? '' : 'deaktivovat'
                                )
                              }
                              className='button-as-anchor modal__trigger cell shrink u-my-05'
                              style={{
                                color: someNonDeactivableEmployeeSelected()
                                  ? 'rgba(234, 68, 78, 0.8)'
                                  : 'rgba(234, 68, 78, 1.0)',
                              }}
                            >
                              <svg
                                className='modal__trigger icon icon--16'
                                style={{ color: Color.RED_PRIMARY }}
                              >
                                <use
                                  className='modal__trigger'
                                  href={icons + '#sprite-trash'}
                                />
                              </svg>{' '}
                              Deaktivovat
                            </button>
                            {popover === 'deaktivovat' && (
                              <div
                                // @ts-ignore
                                ref={ref}
                                className='modal__trigger popover popover--open'
                              >
                                <div className='modal__trigger popover__body'>
                                  <h3 className='modal__trigger u-size-l20 u-mb-2'>
                                    Deaktivovat zaměstnance
                                  </h3>
                                  <p className='modal__trigger u-size-s u-mb-2'>
                                    Vybraní zaměstnanci budou přesunuti do
                                    tabulky deaktivovaných zaměstnanců a nebude
                                    možné jim nadále objednávat kredity.
                                    <br />
                                    <br />
                                    Zaměstnanci budou deaktivováni první den
                                    následujícího měsíce.
                                    <br />
                                    <br />
                                    Svoje rozhodnutí můžete kdykoliv změnit a
                                    zaměstnance znovu aktivovat.
                                  </p>
                                  {someNonDeactivableEmployeeSelected() && (
                                    <p className='modal__trigger u-size-s u-mb-3 input__error'>
                                      Vybraní zaměstnanci následujících čísel
                                      nebudou deaktivováni, protože se pro ně v
                                      košíku nachází nová nebo náhradní karta:{' '}
                                      <br />
                                      &nbsp;&nbsp;&nbsp;
                                      <b className='modal__trigger'>
                                        #
                                        {selectedEmployees
                                          .filter((e) => !isDeactivatable(e))
                                          .map((e) => e.id)
                                          .join(', #')}
                                        .
                                      </b>
                                    </p>
                                  )}
                                  <div className='modal__trigger'>
                                    <a
                                      onClick={() => {
                                        deactivateSelected()
                                        setPopover('')
                                      }}
                                      className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-trash'}
                                        />
                                      </svg>
                                      Deaktivovat
                                    </a>
                                  </div>
                                  <div className='modal__trigger'>
                                    <a
                                      onClick={() => setPopover('')}
                                      className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-delete-thin'}
                                        />
                                      </svg>
                                      Zrušit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {someDeletableEmployeeSelected() && (
                          <div className='modal__trigger popover__wrapper'>
                            <button
                              onClick={() =>
                                setPopover(
                                  popover === 'odstranit' ? '' : 'odstranit'
                                )
                              }
                              className='button-as-anchor modal__trigger cell shrink u-my-05'
                              style={{
                                color: someNonDeletableEmployeeSelected()
                                  ? 'rgba(234, 68, 78, 0.8)'
                                  : 'rgba(234, 68, 78, 1.0)',
                              }}
                            >
                              <EraseIcon
                                width={16}
                                height={16}
                                style={{ marginRight: '8px' }}
                              />{' '}
                              Smazat
                            </button>
                            {popover === 'odstranit' && (
                              <div
                                // @ts-ignore
                                ref={ref}
                                className='modal__trigger popover popover--open'
                              >
                                <div className='modal__trigger popover__body'>
                                  <h3 className='modal__trigger u-size-l20 u-mb-2'>
                                    Smazat zaměstnance
                                  </h3>
                                  <p className='modal__trigger u-size-s u-mb-2'>
                                    Vybraní zaměstnanci budou smazáni.
                                    <br />
                                    <br />
                                    Zaměstnanci budou smazáni z&nbsp;tabulky
                                    zaměstnanců a&nbsp;nebude možné je obnovit.
                                  </p>
                                  {someNonDeletableEmployeeSelected() && (
                                    <p className='modal__trigger u-size-s u-mb-3 input__error'>
                                      Vybraní zaměstnanci následujících čísel
                                      nebudou odstraněni, protože již mají v
                                      systému dokončené objednávky: <br />
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <b className='modal__trigger'>
                                        #
                                        {selectedEmployees
                                          .filter((e) => !isDeletable(e))
                                          .map((e) => e.id)
                                          .join(', #')}
                                        .
                                      </b>
                                    </p>
                                  )}
                                  <div className='modal__trigger'>
                                    <a
                                      onClick={() => {
                                        deleteSelected()
                                        setPopover('')
                                      }}
                                      className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <EraseIcon
                                          width={16}
                                          height={16}
                                          fill={Color.WHITE}
                                        />
                                      </svg>
                                      Smazat
                                    </a>
                                  </div>
                                  <div className='modal__trigger'>
                                    <a
                                      onClick={() => setPopover('')}
                                      className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                    >
                                      <svg className='modal__trigger icon icon--16'>
                                        <use
                                          className='modal__trigger'
                                          href={icons + '#sprite-delete-thin'}
                                        />
                                      </svg>
                                      Zrušit
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // DISABLED SECTION
            <div className='user-bar'>
              <div className='box box--small-radius u-ml-6'>
                <div className='box__content u-overflow-visible u-px-2 u-py-1'>
                  <div className='grid-x align-middle u-size-s'>
                    <div className='cell medium-shrink u-pr-4'>
                      <div className='badge u-my-05 u-mr-1 badge--40 badge--blue'>
                        <div className='badge__content'>{totalSelected}</div>
                      </div>
                      Vybráno{' '}
                    </div>
                    {totalSelected > 0 && (
                      <div
                        data-bar='menu'
                        className='cell medium-auto grid-x u-my-1'
                      >
                        <div className='modal__trigger popover__wrapper'>
                          <button
                            onClick={() =>
                              setPopover(popover === 'obnovit' ? '' : 'obnovit')
                            }
                            className='button-as-anchor modal__trigger cell shrink u-my-05 u-mr-3'
                          >
                            <svg className='modal__trigger icon icon--16'>
                              <use
                                className='modal__trigger'
                                href={icons + '#sprite-refresh'}
                              ></use>
                            </svg>
                            Obnovit zaměstnance
                          </button>
                          <div
                            className={
                              'modal__trigger popover popover--small' +
                              (popover === 'obnovit' ? ' popover--open' : '')
                            }
                          >
                            <div className='modal__trigger popover__body'>
                              <p className='modal__trigger u-size-base14 u-mb-2'>
                                <b className='modal__trigger'>
                                  Obnovit zaměstnance
                                </b>
                              </p>
                              <p className='modal__trigger u-size-s u-mb-2'>
                                Vybraní zaměstnanci budou přesunuti do tabulky
                                aktivních zaměstnanců a bude možné jim znovu
                                objednávat kredity.
                              </p>
                              <div className='modal__trigger'>
                                <a
                                  onClick={() => {
                                    setPopover('')
                                    reactivateSelected()
                                  }}
                                  className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                >
                                  <svg className='modal__trigger icon icon--16'>
                                    <use
                                      className='modal__trigger'
                                      href={icons + '#sprite-check'}
                                    ></use>
                                  </svg>
                                  Reaktivovat
                                </a>
                              </div>
                              <div className='modal__trigger'>
                                <a
                                  onClick={() => setPopover('')}
                                  className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                >
                                  <svg className='modal__trigger icon icon--16'>
                                    <use
                                      className='modal__trigger'
                                      href={icons + '#sprite-delete-thin'}
                                    ></use>
                                  </svg>
                                  Zrušit
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      data-bar='end'
                      className='cell large-shrink grid-x align-middle u-my-1 u-my-large-0'
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {verne('TableContainer#1928')}
      </div>
      <SideMenu isActivated={!isLoading} />
      <div className='panel__wrapper'>
        <div
          className={
            'panel__button' + (objednatRollover ? ' panel__button--open' : '')
          }
        >
          <div className='u-text-center'>
            <a
              // style={{ marginLeft: '-30px' }}
              onClick={() => {
                if (kontaktniEmail === '') {
                  // do nothing -> the field above is not filled yet, throw warning on the field!
                  // setShowKontaktniEmailWarning(true)
                  // better just use Formix to validate the field
                } else {
                  if (!kontaktniEmail) {
                    login.email
                      ? dispatch(reducerKontaktniEmail(login.email))
                      : pracovniEmail &&
                        dispatch(reducerKontaktniEmail(pracovniEmail))
                  }
                  odeslatObjednavku()
                  setObjednatRollover(!objednatRollover) // will these even execute after the redirect from odeslatObjednavku?
                  setRecapRollover(!recapRollover)
                }
              }}
              className='btn btn--primary'
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-direction'} />
              </svg>
              {lg(lang, SEND_THE_ORDER)}
            </a>
          </div>
        </div>
        {/* Odeslat objednávku Rollover */}
        <div className={'panel' + (objednatRollover ? ' panel--open' : '')}>
          <div className='panel__body'>
            <div
              className='box box--narrow'
              style={{
                maxWidth: '55rem',
              }}
            >
              <div className='box__content'>
                <div className='u-text-right u-mb-2'>
                  <a
                    className='underline u-size-base14 u-color-dark-purple-blue'
                    onClick={() => setObjednatRollover(false)}
                  >
                    {lg(lang, CLOSE)}
                    <svg className='icon icon--24 u-ml-1'>
                      <DeleteIcon width={24} height={24} />
                    </svg>
                  </a>
                </div>
                <OrderSummary />
              </div>
            </div>
          </div>
        </div>
        {redirectToSelfcare && <Navigate to={urlSelfcareTable} />}
        <div
          className={
            'panel__button' + (recapRollover ? ' panel__button--open' : '')
          }
        >
          <div className='u-text-center'>
            <a
              onClick={() => {
                setRedirectToSelfcare(true)
                setRecapRollover(!recapRollover)
              }}
              className='btn btn--primary'
              style={{ textAlign: 'center' }}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-delete-thin'} />
              </svg>
              {lg(lang, CLOSE)}&nbsp;{lg(lang, ORDER_SUMMARY)}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableContainer
