import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class WalletIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'wallet-icon',
    width: 24,
    height: 24,
    fill: 'currentColor',
  }

  render() {
    const { className, width, height, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.978 3.236a1 1 0 00-1.42.132L9.6 7H6.857A3.857 3.857 0 003 10.857v14.286A2.857 2.857 0 005.857 28H24a3 3 0 003-3v-1.333h2a1 1 0 001-1V18a1 1 0 00-1-1h-2v-1.059c0-1.273-.81-2.357-1.941-2.767V10a3 3 0 00-3-3h-3.527a.85.85 0 00-.088.004l-4.466-3.768zM20.81 9l2.249 1.898V10a1 1 0 00-1-1H20.81zm1.64 4l-8.984-7.58L7.29 13h15.16zm-17.031-.87L7.969 9H6.857A1.857 1.857 0 005 10.857c0 .477.156.917.419 1.273zM25 15.941V17h-.667a3.333 3.333 0 000 6.667H25V25a1 1 0 01-1 1H5.857A.857.857 0 015 25.143v-10.74A4.124 4.124 0 007.143 15h16.916a.94.94 0 01.941.941zm-.667 5.726H28V19h-3.667a1.333 1.333 0 000 2.667z'
          fill={fill}
        ></path>
      </svg>
    )
  }
}

export default WalletIcon
