import { TableRow } from 'components/table/tableSlice'
import { MultiPassCardState } from './CardOps'

export function isDeactivatable(employee: TableRow): boolean {
  return employee && employee.cardStatus !== MultiPassCardState.VKošíku
}

export function isDeletable(employee: TableRow): boolean {
  return (
    employee &&
    employee.cardStatus === MultiPassCardState.VKošíku &&
    !employee.objednatNahradniKartu
  )
}

export function isNew(employee: TableRow): boolean {
  return isDeletable(employee)
}

let idCounter: number = 1

// todo the parameter should be just id strings, not the whole rows
export function getPersonId(state: TableRow[]): string {
  // in case someone deletes a row, we need to find the next available id, starting from previous id
  const previousIdEmployee = state.find(
    (person) => person.id === (idCounter - 1).toString()
  )
  console.log('previousIdEmployee, id = ', idCounter - 1, previousIdEmployee)
  if (!previousIdEmployee) {
    console.log(
      'employee with previous id not found, running iteratively getPersonId'
    )
    if (idCounter > 1) {
      idCounter--
      return getPersonId(state)
    } else {
      idCounter = 1
    }
  }
  // 1999 is our limit of idCounter
  if (idCounter > 1999) idCounter = 1
  // let personId = '' + (idCounter++).toString().padStart(4, '0')
  let personId = '' + (idCounter++).toString()
  if (state.find((person) => person.id === personId)) {
    return getPersonId(state)
  }
  return personId
}
