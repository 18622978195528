import React, {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import NoResult from '@styles/image/no-result.png'
import NoResult2x from '@styles/image/no-result2x.png'
import icons from '@styles/dist/system/icons.svg'
import PlusIcon from '@styles/icon/plusIcon'
import MagnifyingGlassIcon from '@styles/icon/magnifyingGlassIcon'
import CalendarIcon from '@styles/icon/calendarIcon'
import CheckIcon from '@styles/icon/checkIcon'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import CloseIcon from '@styles/icon/closeIcon'
import DeleteIcon from '@styles/icon/deleteIcon'
import {
  DefaultColumnFilter,
  GlobalFilter,
  renderHeaderAndSortIndicators,
  SelectColumnFilter,
} from './Table'
import {
  HeaderGroup,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { useDispatch } from 'react-redux'
import { useAppSelector, useOnClickOutside, useOnEscapeClick } from 'app/hooks'
import {
  tableActions,
  selectTable,
  selectSelectedRowIds,
  selectVolnocasovyKreditById,
  selectStravenkovyKreditById,
  TableRow,
} from './tableSlice'
import {
  reducerDenniKredit,
  reducerEmpDetailRollover,
  reducerZadaniStravKredituCastkaKreditu,
  selectDenniKredit,
  selectDenniKredityZamestnancu,
  selectUserSettings,
  reducerDeaktivovaniActive,
  reducerAddDenniKreditZamestnance,
  reducerRemoveDenniKreditZamestnance,
} from 'components/settings/userSettingsSlice'
import EmployeeAdd from 'components/selfcare/EmployeeAdd'
import EmployeeDetail from 'components/selfcare/EmployeeDetail'
import Color from '../../app/colors'
import { urlDataImportOne } from 'App'
import LeisureHistoryPopoverInsides from './LeisureHistoryPopoverInsides'
import { DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU } from 'app/constants'
import {
  govActions,
  selectAddresses,
  selectGroups,
} from 'components/settings/governanceSlice'
import { selectDenniPrispevekNaStravuOnboarding } from 'components/onboarding/features/mesicniNakladySlice'
import {
  findMatchingAddress,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from 'util/ValidFormatOps'
import { selectCardTagClass } from 'util/colorOps'
import {
  assignSkupina,
  createOddeleniBackend,
  mutateSkupina,
  mutateZamestnanecBackend,
  purgeSkupina,
} from 'app/req'
import { MultiPassCardState } from 'util/CardOps'
import { validateNumericKeyWithWhites } from 'app/util'
import { lg } from 'app/facade'
import {
  ADD_NEW_EMPLOYEE,
  CANCEL,
  FILTER,
  TO_SEARCH_IN,
  DATA_IMPORT,
  DEACTIVATED,
  NAME,
  SURNAME,
  EMPL_ID,
  CARD,
  LEISURE_CREDIT,
  MEAL_VOUCHER_CREDIT,
  CREDIT_AMOUNT,
  DAYS_WORKED,
  NO_RECORD_FOUND,
  ENTER_AMOUNT,
  ENTER_NUMBER_OF_DAYS,
  CLOSE,
  CONFIRM_DELETING_WHOLE_COLUMN,
  TO_EMPTY,
  DAILY_CREDIT_SETTING,
  DAILY_CREDIT_SETTING_INFO,
  SET,
  ACTIVE,
} from 'app/constDictionary'
import CloseCircleFilledIcon from '@styles/icon/closeCircleFilledIcon'
import { nanoid } from '@reduxjs/toolkit'
import { Address, ZamestnanecBackendEditace } from 'app/type'

interface TableThreeProps {
  stateChanger: (setIsDeaktivniPohledActive: boolean) => void
  closeRollovers: () => void
  popover: string
  setPopover: (popover: string) => void
}

const TableThree: FunctionComponent<TableThreeProps> = ({
  stateChanger,
  closeRollovers: closeContainerRollovers,
  popover,
  setPopover,
}) => {
  const dispatch = useDispatch()
  const reduxTable = useAppSelector(selectTable) as TableRow[]
  const selectedRowIds = useAppSelector(selectSelectedRowIds)
  const lang = useAppSelector(selectLanguage) || 'cz'

  // try not to use this! only for when employee is added, to refresh the table
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref: MutableRefObject<HTMLDivElement> =
    useRef() as MutableRefObject<HTMLDivElement>
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setPopover(''))

  // Skupiny
  const groups = (useAppSelector(selectGroups) as string[]) || ['Nezařazení']
  const [prejmenovatSkupinuNazev, setPrejmenovatSkupinuNazev] = useState('')
  const [hiddenGroups, setHiddenGroups] = useState([''])
  function isGroupEmpty(group: string) {
    return reduxTable
      .filter((row) =>
        isDeaktivovaniActive ? row.deactivatedFrom : !row.deactivatedFrom
      )
      .every((row) => row.group !== group)
  }
  const [eyeOpen, setEyeOpen] = useState('none')
  function hideGroup(group: string) {
    setHiddenGroups((prev) => [...prev, group])
  }
  function unhideGroup(group: string) {
    setHiddenGroups((prev) => prev.filter((g) => g !== group))
  }
  function removeGroup(group: string) {
    const employeesToBeMoved = reduxTable.filter((row) => row.group === group)
    employeesToBeMoved.forEach((employee) => {
      dispatch(tableActions.setGroup({ id: employee.id, group: 'Nezařazení' }))
    })
    assignSkupina(
      employeesToBeMoved.flatMap((e) => +e.id),
      'Nezařazení'
    )

    dispatch(govActions.removeGroup(group))
    purgeSkupina(group)
  }
  function renameGroup(group: string, newName: string) {
    if (groups.indexOf(newName) === -1) {
      dispatch(govActions.renameGroup({ group, newName }))
      mutateSkupina(group, newName)
      const employeesToBeMoved = reduxTable.filter((row) => row.group === group)
      employeesToBeMoved.forEach((employee) => {
        dispatch(tableActions.setGroup({ id: employee.id, group: newName }))
      })
      assignSkupina(
        employeesToBeMoved.flatMap((e) => +e.id),
        newName
      )
    } else {
      alert('Skupina s tímto názvem již existuje.')
    }
  }

  const denniKredit = useAppSelector(selectDenniKredit)
  // console.log('DenniKredit', denniKredit)
  const onboardingDenniKreditNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const denniKredityZamestnancu = useAppSelector(selectDenniKredityZamestnancu)
  const addresses = useAppSelector(selectAddresses)
  useEffect(() => {
    // console.log('useEffect - TableThree')
    // DENNI KREDIT
    // if denni kredit was not set yet, set it to the value from onboarding or the default (fail-safe)
    let newDenniKredit = denniKredit
    if (newDenniKredit < 1) {
      newDenniKredit =
        onboardingDenniKreditNaStravu > 1
          ? onboardingDenniKreditNaStravu
          : DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU
      dispatch(reducerDenniKredit(onboardingDenniKreditNaStravu))
    }
    setSettingsDenniKredit(newDenniKredit.toString())
    // put all employees without a group or in a non-existent group into group 'Nezařazení'
    for (const employee of reduxTable) {
      if (employee.group === '' || groups.indexOf(employee.group) === -1) {
        dispatch(
          tableActions.setGroup({ id: employee.id, group: 'Nezařazení' })
        )
      }
    }
    // DENNI KREDITY ZAMESTNANCU
    let newDenniKredity: { id: string; kredit: number }[] = []
    newDenniKredity = [...denniKredityZamestnancu]
    reduxTable.forEach((e, i) => {
      const denniKreditZamestnance = newDenniKredity.find((d) => d.id === e.id)
      if (!denniKreditZamestnance || denniKreditZamestnance.kredit < 1) {
        newDenniKredity.splice(i, 1, {
          id: e.id,
          kredit: newDenniKredit,
        })
      }
    })
    newDenniKredity.forEach((e) => {
      dispatch(reducerRemoveDenniKreditZamestnance({ id: e.id }))
      dispatch(
        reducerAddDenniKreditZamestnance({
          id: e.id,
          kredit: e.kredit,
        })
      )
    })

    // put all groups that are not assigned to any employee to hiddenGroups
    for (const group of groups) {
      if (isGroupEmpty(group)) {
        hideGroup(group)
      }
    }
    // sort the groups order (and put 'Nezařazení' to the end)
    dispatch(govActions.sortGroups())
    // put all employees without an oddeleni into the first oddeleni on their uliceCisloPopisne
    for (const employee of reduxTable) {
      const matchingAddress = findMatchingAddress(
        employee.uliceCisloPopisne,
        addresses
      ) as Address
      if (!matchingAddress) {
        // this is too much to fix, should not get to this state, other components should fix it.
        console.warn(
          'TableThree, while trying to fix oddeleni, No matching address found for employee',
          employee
        )
        return
      }
      if (!matchingAddress.oddeleni) {
        dispatch(
          govActions.addOddeleni({
            uliceCisloPopisne: matchingAddress.uliceCisloPopisne,
            oddeleni: 'Žádné',
          })
        )
        createOddeleniBackend({
          address: matchingAddress,
          oddeleni: 'Žádné',
        }).then(() =>
          persistEmployeeDepartment(employee, matchingAddress, 'Žádné')
        )
      } else if (
        employee.oddeleni === '' ||
        matchingAddress.oddeleni.indexOf(employee.oddeleni) === -1
      ) {
        const zeroIndexOddeleniNaAdrese = matchingAddress.oddeleni[0]
        dispatch(
          tableActions.setOddeleni({
            id: employee.id,
            oddeleni: zeroIndexOddeleniNaAdrese,
          })
        )
        persistEmployeeDepartment(
          employee,
          matchingAddress,
          zeroIndexOddeleniNaAdrese
        )
      }
    }
  }, [groups])

  function persistEmployeeDepartment(
    employee: TableRow,
    matchingAddress: Address,
    nazevOddeleni: string
  ) {
    const { ulice, cisloPopisne } =
      splitUliceCisloPopisneZachovatCisloOrientacni(employee.uliceCisloPopisne)
    const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
      id: +id,
      jmeno: employee.name,
      prijmeni: employee.surname,
      adresa: {
        ulice: ulice,
        cisloPopisne: cisloPopisne,
        psc: matchingAddress.psc.toString(),
        oddeleni: nazevOddeleni,
      },
      zaplaceneMesice: employee.paidMonths,
      deaktivovaneMesice: employee.deactivatedMonths,
    }
    mutateZamestnanecBackend(zamestnanecBackendEditace)
  }

  useEffect(() => {
    // console.log('useEffect - TableThree, getDenniKredity')
  }, [denniKredityZamestnancu])

  // MEMOIZE CALLBACK
  // we might need the fast-memoize lib
  // https://stackoverflow.com/questions/61255053/react-usecallback-with-parameter
  // const memoizedValue = useMemo(() => getEmployeeDailyCredit(id), [stateDenniKredityZamestnancu]);
  // const getEmployeeDailyCredit = useCallback(
  const getEmployeeDailyCredit = (id: string) => {
    const employee = denniKredityZamestnancu.find((e) => e.id === id)
    // console.log('getEmployeeDailyCredit', id, employee)
    if (employee) {
      const denniKredit = +employee.kredit
      // console.log('getEmployeeDailyCredit', id, denniKredit)
      return denniKredit
    } else {
      // todo remove this lol
      reducerAddDenniKreditZamestnance({ id: id, kredit: denniKredit })
      return denniKredit
    }
    // getDenniKreditZamestnance(id) ?? +denniKredit
  }
  //   [denniKredityZamestnancu]
  // )

  const zadaniStravKredituCastkaKreditu =
    useAppSelector(selectUserSettings).zadaniStravKredituCastkaKreditu
  const [settingsDenniKredit, setSettingsDenniKredit] = useState('')
  const empDetailRollover = useAppSelector(selectUserSettings).empDetailRollover
  // Can't use typical useOnEscapeClick since it's using reducer and not useState
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dispatch(reducerEmpDetailRollover(false))
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [empDetailRollover])

  const [isDeaktivovaniActive, setIsDeaktivovaniActive] = useState(false)
  const [empAddRollover, setEmpAddRollover] = useState(false)
  useOnEscapeClick(empAddRollover, setEmpAddRollover)

  const [currentEmpDetailId, setCurrentEmpDetailId] = useState('')

  function setSelected(rowId: any, value: any) {
    dispatch(tableActions.setSelected({ id: rowId, value: value }))
  }
  function isSelected(rowId: any): boolean {
    return selectedRowIds.indexOf(rowId) !== -1
  }
  function getFilteredRowIds() {
    const filteredActivDeactivRows = isDeaktivovaniActive
      ? globalFilteredRows.filter((row) => row.original.deactivatedFrom)
      : globalFilteredRows.filter((row) => !row.original.deactivatedFrom)
    // filter with global filter
    return filteredActivDeactivRows.flatMap((row) => row.original.id)
  }

  function selectAllFiltered(checked: boolean): void {
    const currentSelectionIds = getFilteredRowIds()
    dispatch(
      tableActions.setSelectedSet({
        set: currentSelectionIds,
        value: checked,
      })
    )
  }

  function selectAllFilteredInGroup(checked: boolean, group: string): void {
    const currentSelectionIds = getFilteredRowIds()
    dispatch(
      tableActions.setSelectedSetInGroup({
        set: currentSelectionIds,
        group: group,
        value: checked,
      })
    )
  }

  function isCheckedAllFiltered(): boolean {
    for (const rowId of getFilteredRowIds()) {
      if (!isSelected(rowId)) {
        return false
      }
    }
    return true
  }

  function isCheckedAllFilteredInGroup(group: string): boolean {
    let count = 0
    for (const rowId of getFilteredRowIds()) {
      if (reduxTable.find((row) => row.id === rowId)!.group === group) {
        count++
        if (!isSelected(rowId)) {
          return false
        }
      }
    }
    return count > 0
  }

  function doesEmployeeHaveHistoricalLeisureOrders(id: string): boolean {
    return (
      reduxTable
        .find((row) => row.id === id)
        ?.historicalOrders?.some((order) => order.leisure > 0) || false
    )
  }
  const name = lg(lang, NAME)
  const surname = lg(lang, SURNAME)
  const id = lg(lang, EMPL_ID)
  const card = lg(lang, CARD)
  const leisureCredit = lg(lang, LEISURE_CREDIT)
  const gastroCredit = lg(lang, MEAL_VOUCHER_CREDIT)

  function prepareColumn(
    headerGroup: HeaderGroup<TableRow>,
    index: number,
    customClassNames?: string
  ) {
    const columnHeader = headerGroup.headers[index]
    return (
      <div
        {...columnHeader.getHeaderProps(columnHeader.getSortByToggleProps())}
        className={`table__cell table__cell--sortable ${
          columnHeader.isSorted ? 'is-active ' : ''
        }${customClassNames ? customClassNames : 'u-w-6'}
        `}
      >
        {renderHeaderAndSortIndicators(columnHeader)}
      </div>
    )
  }

  const [isFilteringVisible, setFilteringVisible] = React.useState(false)
  const columns = React.useMemo(
    () => [
      {
        Header: () => null, // No header
        id: 'checkbox',
        Cell: () => <input type='checkbox' />,
      },
      {
        Header: name,
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: surname,
        accessor: 'surname',
      },
      {
        Header: id, // langCisloZam()
        accessor: 'id',
      },
      {
        Header: card,
        accessor: 'cardStatus',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: leisureCredit,
        accessor: 'leisureCredits',
        // Filter: NumberRangeColumnFilter,
        // filter: 'greater-than',
      },
      {
        Header: gastroCredit,
        accessor: 'gastroCredits',
        // Filter: SliderColumnFilter,
        // filter: 'equals',
      },
    ],
    []
  )
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const filterTypes = React.useMemo(
    () => ({
      text: (rows: any, filter: any) => {
        const regex = new RegExp(`^${filter}`, 'i')
        return rows.filter((r: any) => regex.test(r.name))
      },
    }),
    []
  )

  // 1. Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      // @ts-ignore: mv code error
      columns,
      data: reduxTable,
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetSelectedRows: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  const onFilterButtonClick = () => {
    if (isFilteringVisible) {
      stateChanger(false)
      setAllFilters([])
    }
    setFilteringVisible(!isFilteringVisible)
  }

  function setDeaktivovani(value: boolean) {
    setIsDeaktivovaniActive(value)
    stateChanger(value)
    dispatch(reducerDeaktivovaniActive(value))
    setAllFilters([])
  }

  return (
    <div className='table-mv' style={{ marginTop: '-10px' }}>
      {/* tlačítka Přidat nového zaměstnance, Vyhledávat, ... */}
      <div className='grid-x u-mb-3'>
        <div className='cell medium-auto'>
          <button
            onClick={() => {
              dispatch(reducerEmpDetailRollover(false))
              setEmpAddRollover(!empAddRollover)
              closeContainerRollovers()
            }}
            className='btn u-mr-1 u-mb-1 btn--secondary btn--tiny'
          >
            <svg className='icon icon--16'>
              <PlusIcon />
            </svg>
            {lg(lang, ADD_NEW_EMPLOYEE)}
          </button>
          {isFilteringVisible ? (
            <a
              onClick={onFilterButtonClick}
              className='btn u-mr-1 u-mb-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
              style={{ width: '145px' }}
            >
              <svg className='icon icon--16'>
                <use href={icons + '#sprite-delete-thin'}></use>
              </svg>
              <span>
                {lg(lang, CANCEL)}&nbsp;{lg(lang, FILTER)}
              </span>
            </a>
          ) : (
            <a
              onClick={onFilterButtonClick}
              className='btn u-mr-1 u-mb-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
              style={{ width: '145px' }}
            >
              <svg className='icon icon--16'>
                <use href={icons + '#sprite-funnel'}></use>
              </svg>
              <span>{lg(lang, FILTER)}</span>
            </a>
          )}
          <div
            style={{
              cursor: 'default',
              paddingLeft: '18px',
            }}
            className='input-parent btn u-mr-1 u-mb-1 btn--secondary btn--outlined btn--tiny'
          >
            <svg className='icon icon--16'>
              <MagnifyingGlassIcon width={16} height={16} />
            </svg>
            {lg(lang, TO_SEARCH_IN)}
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDeaktivovaniActive={isDeaktivovaniActive}
            />
          </div>
          <a
            href={urlDataImportOne}
            className='btn u-mr-1 btn--violet btn--tiny'
          >
            <svg className='icon icon--16'>
              <CalendarIcon />
            </svg>
            {lg(lang, DATA_IMPORT)}
          </a>
        </div>
      </div>
      {/* Deaktivovaní Switch */}
      <div
        className={'switch__wrapper ' + (isFilteringVisible ? '' : 'u-mb-2_5')}
      >
        <span className='switch-reverse__text switch-reverse__text--on'>
          {lg(lang, ACTIVE)}
        </span>
        <label className='switch-reverse switch-reverse--text'>
          <input
            type='checkbox'
            checked={isDeaktivovaniActive}
            onChange={() => {
              setDeaktivovani(!isDeaktivovaniActive)
            }}
          />
          <span className='switch-reverse__slider'></span>
          <svg className='switch-reverse__icon switch-reverse__icon--off icon icon--16'>
            <CheckIcon height={16} width={16} />
          </svg>
          <svg className='switch-reverse__icon switch-reverse__icon--on icon icon--16'>
            <DeleteIcon height={16} width={16} />
          </svg>
        </label>
        <span className='switch-reverse__text switch-reverse__text--off'>
          {lg(lang, DEACTIVATED)}
        </span>
      </div>
      {/* Tabulka - Header */}
      <div
        {...getTableProps()}
        role='table'
        aria-label='Zaměstnanci'
        className='table table--compact table--no-border'
      >
        {/*  */}
        {/* Table Header - Filters (invisible by default) */}
        {/*  */}
        {headerGroups.map((headerGroup) => (
          <div
            role='row'
            className='table__row table__row--clickable table__row--header u-hide-large-down'
            {...headerGroup.getHeaderGroupProps()} // also adds key (and ref?)
          >
            {/* Hidden checkbox - to maintain indentation styles for Filters */}
            <div className='table__cell table__cell--valign u-py-1 u-desktop-only'>
              <label className='checkbox' style={{ visibility: 'hidden' }}>
                <input type='checkbox' />
                <span className='checkbox__box'></span>
              </label>
            </div>
            <div className='table__group table__group--large u-w-6 u-w-medium-3'>
              <div role='cell' className='table__cell u-w-6'>
                <div className='input input--tiny input--small-placeholder'>
                  <div className='input__wrapper'>
                    {/* {headerGroup.headers.slice(1, 2).map((column) => (
                      <div key={column.id}>
                    {/* we are trying to figure out whether it's better to use index i or column.id */}
                    {/* Seems that if we sort or filter the data in the table, React will have to re-render the items
                        when incremental keys are used, but not when natural keys are used. */}
                    {/* Lists and Keys - React https://reactjs.org › docs › lists-and-keys: */}
                    {/* "We don't recommend using indexes for keys if the order of items may change. This can negatively impact performance and may cause issues with component state." */}
                    {/* {console.log('Index is ', column.id)} */}
                    {isFilteringVisible && headerGroup.headers[1].canFilter
                      ? headerGroup.headers[1].render('Filter')
                      : null}
                    {/* </div> */}
                    {/* ))} */}
                  </div>
                </div>
              </div>
              <div role='cell' className='table__cell u-w-6'>
                <div className='input input--tiny input--small-placeholder'>
                  <div className='input__wrapper'>
                    {isFilteringVisible && headerGroup.headers[2].canFilter
                      ? headerGroup.headers[2].render('Filter')
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='table__group u-w-auto'>
              <div className='table__group table__group--medium u-w-12 u-w-medium-5'>
                <div role='cell' className='table__cell u-w-6'>
                  <div className='input input--tiny input--small-placeholder'>
                    <div className='input__wrapper'>
                      {isFilteringVisible && headerGroup.headers[2].canFilter
                        ? headerGroup.headers[3].render('Filter')
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  role='cell'
                  className='table__cell table__cell--overflow u-w-6'
                >
                  {isFilteringVisible && headerGroup.headers[4].canFilter
                    ? headerGroup.headers[4].render('Filter')
                    : null}
                </div>
              </div>
            </div>
          </div>
        ))}
        {/*  */}
        {/* Table Header - The Header */}
        {/*  */}
        {headerGroups.map((headerGroup) => (
          <div
            // key={headerGroup.id} is already specified in getHeaderGroupProps()
            role='row'
            className='table__row table__row--clickable table__row--header'
            {...headerGroup.getHeaderGroupProps()}
          >
            {/* Select All checkbox */}
            <div
              className='table__cell table__cell--valign u-py-1 u-desktop-only'
              style={{
                visibility:
                  getFilteredRowIds().length === 0 ? 'hidden' : 'visible',
              }}
            >
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={isCheckedAllFiltered()}
                  onChange={(e) => selectAllFiltered(e.currentTarget.checked)}
                />
                <span className='checkbox__box'></span>
              </label>
            </div>
            {/* Jméno, Příjmení */}
            <div className='table__group table__group--large u-w-6 u-w-medium-3'>
              {prepareColumn(headerGroup, 1)}
              {prepareColumn(headerGroup, 2)}
            </div>
            {/* číslo zam., karta, volnočasový, stravenkový, gearbox, odprac.dny */}
            <div className='table__group u-w-auto'>
              {/* číslo zam., karta */}
              <div className='table__group table__group--medium u-w-12 u-w-medium-5'>
                {prepareColumn(headerGroup, 3)}
                {prepareColumn(headerGroup, 4)}
              </div>
              {/* volnočasový, stravenkový */}
              <div className='table__group table__group--large u-w-7 u-hide-small-down'>
                {/* Volnočasový */}
                {
                  <div
                    // Volnocasovy kredit is not sortable
                    {...headerGroup.headers[5].getHeaderProps()} // Do not add the sorting props to the header props
                    className={
                      'table__cell table__cell--sortable table__cell--overflow u-w-5'
                    }
                  >
                    <b className='u-mr-2'>
                      {headerGroup.headers[5].Header !== undefined &&
                        headerGroup.headers[5].render('Header')}
                    </b>
                    {/*  */}
                    {/* Volnocas - Clear all column data Popover */}
                    {/*  */}
                    <div className='modal__trigger popover__wrapper'>
                      <div
                        className={
                          'modal__trigger settings-button' +
                          (popover === 'volnocas-trash'
                            ? ' settings-button--active'
                            : '')
                        }
                        onClick={() =>
                          setPopover(
                            popover === 'volnocas-trash' ? '' : 'volnocas-trash'
                          )
                        }
                      >
                        <svg className='modal__trigger icon icon--16'>
                          <use
                            className='modal__trigger'
                            href={icons + '#sprite-trash'}
                          />
                        </svg>
                      </div>
                      <div
                        // @ts-ignore
                        ref={ref}
                        className={
                          'modal__trigger popover popover--small popover--bottom' +
                          (popover === 'volnocas-trash' ? ' popover--open' : '')
                        }
                      >
                        <div className='modal__trigger popover__body'>
                          <p className='modal__trigger u-color-dark-purple-blue u-size-base14 u-mb-1'>
                            <b className='modal__trigger'>
                              {lg(lang, CONFIRM_DELETING_WHOLE_COLUMN)}
                            </b>
                          </p>
                          <div className='modal__trigger'>
                            <a
                              className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                              onClick={() => {
                                dispatch(
                                  tableActions.setVolnocasovyToAll({
                                    amount: 0,
                                  })
                                )
                                setPopover('')
                              }}
                            >
                              <svg className='modal__trigger icon icon--16'>
                                <use
                                  className='modal__trigger'
                                  href={icons + '#sprite-trash'}
                                />
                              </svg>
                              {lg(lang, TO_EMPTY)}
                            </a>
                          </div>
                          <div className='modal__trigger'>
                            <a
                              className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                              onClick={() => setPopover('')}
                            >
                              <svg className='modal__trigger icon icon--16'>
                                <use
                                  className='modal__trigger'
                                  href={icons + '#sprite-delete-thin'}
                                />
                              </svg>
                              {lg(lang, CANCEL)}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {/* Stravenkový */}
                {
                  <div
                    // Stravenkovy kredit is not sortable
                    {...headerGroup.headers[6].getHeaderProps()} // Do not add the sorting props to the header props
                    className={
                      'table__cell table__cell--sortable table__cell--overflow u-w-7'
                    }
                  >
                    <b className='u-mr-2'>
                      {headerGroup.headers[6].Header !== undefined &&
                        headerGroup.headers[6].render('Header')}
                    </b>
                    {/* Nastavení denního kreditu and Clear All Popover Wrapper */}
                    <div className='modal__trigger popover__wrapper'>
                      {/*  */}
                      {/* Stravenkovy - Clear All Column Popover Content */}
                      {/*  */}
                      <div
                        className={
                          'modal__trigger settings-button u-mr-1' +
                          (popover === 'stravenkovy-trash'
                            ? ' settings-button--active'
                            : '')
                        }
                        onClick={() =>
                          setPopover(
                            popover === 'stravenkovy-trash'
                              ? ''
                              : 'stravenkovy-trash'
                          )
                        }
                      >
                        <svg className='modal__trigger icon icon--16'>
                          <use
                            className='modal__trigger'
                            href={icons + '#sprite-trash'}
                          />
                        </svg>
                      </div>
                      <div
                        className={
                          'modal__trigger popover popover--small popover--bottom' +
                          (popover === 'stravenkovy-trash'
                            ? ' popover--open'
                            : '')
                        }
                      >
                        <div className='modal__trigger popover__body'>
                          <p className='modal__trigger u-color-dark-purple-blue u-size-base14 u-mb-1'>
                            <b className='modal__trigger'>
                              {lg(lang, CONFIRM_DELETING_WHOLE_COLUMN)}
                            </b>
                          </p>
                          <div className='modal__trigger'>
                            <a
                              className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                              onClick={() => {
                                dispatch(
                                  tableActions.setStravenkovyToAll({
                                    amount: 0,
                                  })
                                )
                                setPopover('')
                              }}
                            >
                              <svg className='modal__trigger icon icon--16'>
                                <use
                                  className='modal__trigger'
                                  href={icons + '#sprite-trash'}
                                />
                              </svg>
                              {lg(lang, TO_EMPTY)}
                            </a>
                          </div>
                          <div className='modal__trigger'>
                            <a
                              className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                              onClick={() => setPopover('')}
                            >
                              <svg className='modal__trigger icon icon--16'>
                                <use
                                  className='modal__trigger'
                                  href={icons + '#sprite-delete-thin'}
                                />
                              </svg>
                              {lg(lang, CANCEL)}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Nastavení denního kreditu Popover content */}
                      <div
                        className={
                          'modal__trigger settings-button u-mr-1' +
                          (popover === 'nastaveni-denniho-kreditu'
                            ? ' settings-button--active'
                            : '')
                        }
                        onClick={() =>
                          setPopover(
                            popover === 'nastaveni-denniho-kreditu'
                              ? ''
                              : 'nastaveni-denniho-kreditu'
                          )
                        }
                      >
                        <svg className='modal__trigger icon icon--16'>
                          <use
                            className='modal__trigger'
                            href={icons + '#sprite-wheel'}
                          ></use>
                        </svg>
                      </div>
                      {/* Nastavení denního kreditu - hidden by default */}
                      {popover === 'nastaveni-denniho-kreditu' && (
                        <div
                          style={{ cursor: 'default' }}
                          className='popover popover--open popover--small popover--bottom'
                        >
                          <div className='modal__trigger popover__body'>
                            <p className='modal__trigger u-color-dark-purple-blue u-size-base14 u-mb-1'>
                              <b className='modal__trigger'>
                                {lg(lang, DAILY_CREDIT_SETTING)}
                              </b>
                            </p>
                            <div className='modal__trigger input u-mb-2 input--tiny'>
                              <div className='modal__trigger input__wrapper'>
                                <input
                                  className='modal__trigger'
                                  placeholder={lg(lang, CREDIT_AMOUNT)}
                                  value={settingsDenniKredit}
                                  onChange={(
                                    e // do not call reducer here, we are just changing the field, not submitting yet
                                  ) => {
                                    if (e.target.value.length < 5) {
                                      setSettingsDenniKredit(e.target.value)
                                    } else {
                                      setSettingsDenniKredit('9999')
                                    }
                                  }}
                                  type='tel'
                                  onKeyPress={validateNumericKeyWithWhites}
                                  onWheel={(e) => e.currentTarget.blur()}
                                />
                              </div>
                            </div>
                            <p className='modal__trigger u-color-dark-purple-blue u-size-s u-mb-2'>
                              {lg(lang, DAILY_CREDIT_SETTING_INFO)}{' '}
                              {DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU} Kč.
                            </p>
                            <div className='modal__trigger'>
                              <a
                                onClick={() => {
                                  if (+settingsDenniKredit > 0) {
                                    // no need to change useState as it's been already changed while changing the input
                                    dispatch(
                                      reducerDenniKredit(settingsDenniKredit)
                                    )
                                    dispatch(
                                      tableActions.changeDailyCreditDenominatorToAllAndUpdateGastro(
                                        {
                                          denniKredityZamestnancu:
                                            denniKredityZamestnancu,
                                          newDenominator: settingsDenniKredit,
                                        }
                                      )
                                    )
                                    // dispatch(eraseDenniKredityZamestnancu())
                                    denniKredityZamestnancu.forEach((row) => {
                                      dispatch(
                                        reducerRemoveDenniKreditZamestnance({
                                          id: row.id,
                                        })
                                      )
                                      dispatch(
                                        reducerAddDenniKreditZamestnance({
                                          id: row.id,
                                          kredit: settingsDenniKredit,
                                        })
                                      )
                                    })
                                  }
                                  setPopover('')
                                }}
                                className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                              >
                                <svg className='icon icon--16'>
                                  <CheckIcon width={16} height={16} />
                                </svg>
                                {lg(lang, SET)}
                              </a>
                            </div>
                            <div>
                              <a
                                onClick={() => setPopover('')}
                                className='btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                              >
                                <svg className='icon icon--16'>
                                  <DeleteThinIcon width={16} height={16} />
                                </svg>
                                {lg(lang, CANCEL)}
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        'modal__trigger select' +
                        (popover === 'odpracovane-dny' ? ' select--active' : '')
                      }
                      onClick={() =>
                        setPopover(
                          popover === 'odpracovane-dny' ? '' : 'odpracovane-dny'
                        )
                      }
                    >
                      <div className='modal__trigger select__option select__option--selected'>
                        <span className='modal__trigger'>
                          {zadaniStravKredituCastkaKreditu
                            ? lg(lang, CREDIT_AMOUNT)
                            : lg(lang, DAYS_WORKED)}
                        </span>
                        <svg className='modal__trigger icon icon--24'>
                          <use
                            className='modal__trigger'
                            href={
                              icons +
                              (popover === 'odpracovane-dny'
                                ? '#sprite-sorting-up'
                                : '#sprite-sorting-down')
                            }
                          />
                        </svg>
                      </div>
                      <div className='modal__trigger select__options'>
                        <div
                          className='modal__trigger select__option'
                          onClick={() =>
                            dispatch(
                              reducerZadaniStravKredituCastkaKreditu(
                                !zadaniStravKredituCastkaKreditu
                              )
                            )
                          }
                        >
                          {zadaniStravKredituCastkaKreditu
                            ? lg(lang, DAYS_WORKED)
                            : lg(lang, CREDIT_AMOUNT)}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
      {/*  */}
      {/*  */}
      {/* Table Body */}
      {/*  */}
      {/*  */}
      {getFilteredRowIds().length === 0 ? (
        <div
          className='u-text-center u-mt-5'
          style={{
            backgroundColor: Color.GREYLIGHT,
          }}
        >
          <p className='u-size-l20 u-mb-2'>{lg(lang, NO_RECORD_FOUND)}</p>{' '}
          <img
            src={NoResult}
            srcSet={NoResult2x + ' 2x'}
            width='376'
            height='auto'
            alt='Nebyl nalezen žádný záznam'
          />
        </div>
      ) : (
        <div className='box box--small-radius box--no-padding u-mb-9'>
          <div className='box__content u-overflow-visible'>
            <div
              {...getTableBodyProps()}
              role='table'
              aria-label='Zaměstnanci'
              className='table table--compact'
            >
              {/* ####################### */}
              {/* ######## GROUPS ####### */}
              {/* ####################### */}
              {groups.map((group) => {
                return (
                  <div key={group}>
                    {!isGroupEmpty(group) && (
                      <div>
                        {/* To restart coloring of data from zero - must be applied to every start of a new group */}
                        {/* <div role='row' className='table__row' />{' '} */}
                        <div role='row' className='table__row'>
                          <div
                            className='table__cell table__cell--valign u-py-1 u-desktop-only'
                            style={{
                              border: 'none',
                              // borderBottom: '2px solid #ea444e',
                            }}
                          >
                            <label className='checkbox'>
                              <input
                                type='checkbox'
                                checked={isCheckedAllFilteredInGroup(group)}
                                onChange={(e) =>
                                  selectAllFilteredInGroup(
                                    e.currentTarget.checked,
                                    group
                                  )
                                }
                              />
                              <span className='checkbox__box'></span>
                            </label>
                          </div>
                          <div
                            className='table__cell table__cell--valign table__cell--overflow'
                            style={{
                              border: 'none',
                            }}
                          >
                            <p className='u-size-l20 u-color-dark-purple-blue-alt u-mr-1'>
                              <strong>{group}</strong>
                            </p>
                            {/* Nabídka voleb pro skupiny (... a očíčko) */}
                            {group !== 'Nezařazení' &&
                              (hiddenGroups.indexOf(group) === -1 ? (
                                // nabídka skupiny
                                <div className='modal__trigger popover__wrapper'>
                                  <button
                                    className='button-as-anchor modal__trigger'
                                    onClick={() => {
                                      if (popover !== 'skupina#' + group) {
                                        setPopover('skupina#' + group)
                                      } else {
                                        setPopover('')
                                      }
                                    }}
                                  >
                                    <svg className='modal__trigger icon icon--24'>
                                      <use
                                        className='modal__trigger'
                                        href={
                                          icons + '#sprite-3dots-horizontal'
                                        }
                                      ></use>
                                    </svg>
                                  </button>
                                  {popover === 'skupina#' + group && (
                                    <div
                                      // @ts-ignore
                                      ref={ref}
                                      className={
                                        'modal__trigger popover popover--bottom-right popover--blue popover--open'
                                      }
                                    >
                                      <div className='modal__trigger popover__body'>
                                        <button
                                          onClick={() => {
                                            setPopover(
                                              'prejmenovat-skupinu#' + group
                                            )
                                          }}
                                          className='button-as-anchor modal__trigger u-size-s u-color-white u-mb-2'
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={icons + '#sprite-pen'}
                                            ></use>
                                          </svg>
                                          Přejmenovat
                                        </button>
                                        <button
                                          onClick={() => {
                                            hideGroup(group)
                                            setPopover('')
                                            setEyeOpen('none')
                                          }}
                                          className='button-as-anchor modal__trigger u-size-s u-color-white u-mb-2'
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              href={
                                                icons +
                                                '#sprite-eye-close-outline'
                                              }
                                            ></use>
                                          </svg>
                                          Skrýt
                                        </button>
                                        <button
                                          onClick={() => {
                                            setPopover(
                                              'zrusit-skupinu#' + group
                                            )
                                          }}
                                          className='button-as-anchor modal__trigger u-size-s u-color-sodexo-red'
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={
                                                icons + '#sprite-delete-thin'
                                              }
                                            ></use>
                                          </svg>
                                          Zrušit skupinu
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {/* Přejmenovat skupinu */}
                                  <div
                                    className={
                                      'modal__trigger popover popover--bottom-right' +
                                      (popover ===
                                      'prejmenovat-skupinu#' + group
                                        ? ' popover--open'
                                        : '')
                                    }
                                  >
                                    <div className='modal__trigger popover__body'>
                                      <h3 className='modal__trigger u-size-l20 u-mb-3'>
                                        Přejmenovat skupinu
                                      </h3>
                                      <p className='modal__trigger u-size-base14 u-mb-2'>
                                        Název skupiny
                                      </p>
                                      <div className='modal__trigger input u-mb-3 input--small input--small-placeholder'>
                                        <div className='modal__trigger input__wrapper'>
                                          <input
                                            className='modal__trigger'
                                            placeholder='Název skupiny'
                                            type='text'
                                            value={prejmenovatSkupinuNazev}
                                            onChange={(e) =>
                                              setPrejmenovatSkupinuNazev(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className='modal__trigger'>
                                        <a
                                          className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                          onClick={() => {
                                            renameGroup(
                                              group,
                                              prejmenovatSkupinuNazev
                                            )
                                            setPopover('')
                                          }}
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={icons + '#sprite-pen'}
                                            ></use>
                                          </svg>
                                          Přejmenovat
                                        </a>
                                      </div>{' '}
                                      <div className='modal__trigger'>
                                        <a
                                          onClick={() => {
                                            setPopover('')
                                          }}
                                          className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={
                                                icons + '#sprite-delete-thin'
                                              }
                                            ></use>
                                          </svg>
                                          Zrušit
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      'modal__trigger popover popover--small popover--bottom-right' +
                                      (popover === 'zrusit-skupinu#' + group
                                        ? ' popover--open'
                                        : '')
                                    }
                                  >
                                    <div className='modal__trigger popover__body'>
                                      <p className='modal__trigger u-color-dark-purple-blue u-size-base14 u-mb-1'>
                                        <b>Opravdu chcete zrušit skupinu?</b>
                                      </p>
                                      <div className='modal__trigger'>
                                        <a
                                          className='modal__trigger btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                                          onClick={() => {
                                            removeGroup(group)
                                            setPopover('')
                                          }}
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={icons + '#sprite-trash'}
                                            ></use>
                                          </svg>
                                          Zrušit skupinu
                                        </a>
                                      </div>
                                      <div>
                                        <a
                                          onClick={() => setPopover('')}
                                          className='modal__trigger btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                                        >
                                          <svg className='modal__trigger icon icon--16'>
                                            <use
                                              className='modal__trigger'
                                              href={
                                                icons + '#sprite-delete-thin'
                                              }
                                            ></use>
                                          </svg>
                                          Zrušit
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='input__wrapper u-ml-5'>
                                  <svg
                                    style={{ width: '20px', height: '20px' }}
                                    onMouseEnter={() => setEyeOpen(group)}
                                    onMouseLeave={() => setEyeOpen('none')}
                                    className='input__eye icon icon--24'
                                    onClick={() => unhideGroup(group)}
                                  >
                                    <use
                                      href={`${icons}#sprite-eye-${
                                        eyeOpen === group ? 'open' : 'close'
                                      }-filled`}
                                    />
                                    {/* <EyeOpenFilledIcon width={24} height={24} /> */}
                                  </svg>
                                </div>
                              ))}
                          </div>
                        </div>
                        {/* To restart coloring of data from zero - must be applied to every start of a new group */}
                        <div role='row' className='table__row' />
                        {/* KONEC HEADERU SKUPINY */}
                        {/* START TABLE DATA */}
                        {(!hiddenGroups.includes(group) ||
                          // bugfix - when a new employee was added and it was the very first employee, he was not displayed until refresh
                          group === 'Nezařazení') &&
                          (isDeaktivovaniActive
                            ? // 1. deactivated employees
                              rows
                                .filter(
                                  (row) =>
                                    row.original.deactivatedFrom &&
                                    row.original.group === group
                                )
                                .map((row) => {
                                  prepareRow(row)
                                  return (
                                    <div
                                      key={row.id}
                                      role='row'
                                      className='table__row'
                                    >
                                      <div className='modal__trigger table__cell table__cell--valign table__cell--inactive u-py-1 u-desktop-only'>
                                        <label className='checkbox'>
                                          <input
                                            type='checkbox'
                                            checked={isSelected(
                                              row.cells[3].value
                                            )}
                                            onChange={() =>
                                              setSelected(
                                                row.cells[3].value,
                                                !isSelected(row.cells[3].value)
                                              )
                                            }
                                          />
                                          <span className='checkbox__box'></span>
                                        </label>
                                      </div>
                                      <div className='table__group table__group--large u-w-6 u-w-medium-3'>
                                        <div
                                          role='cell'
                                          className='table__cell table__cell--valign u-text-ellipsis u-w-6 u-faded-50'
                                        >
                                          {row.original.name}
                                        </div>
                                        <div
                                          role='cell'
                                          className='table__cell table__cell--valign u-text-ellipsis u-w-6 u-faded-50'
                                        >
                                          {row.original.surname}
                                        </div>
                                      </div>
                                      <div className='table__group u-w-auto'>
                                        <div className='table__group table__group--medium u-w-12 u-w-medium-5'>
                                          <div
                                            role='cell'
                                            className='table__cell table__cell--valign u-w-6 u-faded-50'
                                          >
                                            {row.original.id}
                                          </div>
                                          <div
                                            role='cell'
                                            className='table__cell table__cell--valign table__cell--valign u-py-medium-1 u-w-6 u-faded-50'
                                          >
                                            {
                                              <div
                                                className={
                                                  'tag u-w-12 ' +
                                                  selectCardTagClass(
                                                    row.cells[4].value
                                                  )
                                                }
                                                {...row.cells[4].getCellProps()}
                                              >
                                                {row.cells[4].render('Cell')}
                                              </div>
                                            }
                                          </div>
                                        </div>
                                        <div className='table__group table__group--large u-w-7 u-hide-small-down'>
                                          <div
                                            role='cell'
                                            className='table__cell table__cell--valign u-text-ellipsis u-w-5 u-faded-50'
                                          >
                                            <i>Nelze objednat kredit</i>
                                          </div>{' '}
                                          <div
                                            role='cell'
                                            className='table__cell table__cell--valign u-text-ellipsis u-w-7 u-faded-50'
                                          >
                                            <i>Nelze objednat kredit</i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                            : // 2. active employees
                              rows
                                .filter(
                                  (row) =>
                                    !row.original.deactivatedFrom &&
                                    row.original.group === group
                                )
                                .map((row) => {
                                  prepareRow(row)
                                  return (
                                    <div
                                      key={row.id}
                                      role='row'
                                      className='table__row table__row--clickable'
                                    >
                                      <div className='modal__trigger table__cell table__cell--valign u-py-1 u-desktop-only'>
                                        <label
                                          className='checkbox'
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <input
                                            type='checkbox'
                                            checked={isSelected(
                                              row.original.id
                                            )}
                                            onChange={() => {
                                              setSelected(
                                                row.original.id,
                                                !isSelected(row.original.id)
                                              )
                                            }}
                                          />
                                          <span className='checkbox__box'></span>
                                        </label>
                                      </div>
                                      {/* jméno příjmení  */}
                                      <div
                                        className='table__group table__group--large u-w-6 u-w-medium-3'
                                        onClick={() => {
                                          setEmpAddRollover(false)
                                          closeContainerRollovers()
                                          // if not already open or open on the same user, toggle rollover, otherwise keep rollover open, but change Employee id
                                          if (
                                            currentEmpDetailId ===
                                              row.original.id ||
                                            currentEmpDetailId === '' ||
                                            empDetailRollover === false
                                          ) {
                                            dispatch(
                                              reducerEmpDetailRollover(
                                                !empDetailRollover
                                              )
                                            )
                                          }
                                          setCurrentEmpDetailId(row.original.id)
                                        }}
                                      >
                                        <div
                                          className='table__cell table__cell--valign u-text-ellipsis u-w-6'
                                          {...row.cells[1].getCellProps()}
                                        >
                                          {row.cells[1].render('Cell')}
                                        </div>
                                        <div
                                          className='table__cell table__cell--valign u-text-ellipsis u-w-6'
                                          {...row.cells[2].getCellProps()}
                                        >
                                          {row.cells[2].render('Cell')}
                                        </div>
                                      </div>
                                      {/* číslo zam., karta */}
                                      <div className='table__group u-w-auto'>
                                        {/* číslo zam. */}
                                        <div className='table__group table__group--medium u-w-12 u-w-medium-5'>
                                          <div
                                            onClick={() => {
                                              setEmpAddRollover(false)
                                              closeContainerRollovers()
                                              // if not already open or open on the same user, toggle rollover, otherwise keep rollover open, but change Employee id
                                              if (
                                                currentEmpDetailId ===
                                                  row.original.id ||
                                                currentEmpDetailId === '' ||
                                                empDetailRollover === false
                                              ) {
                                                dispatch(
                                                  reducerEmpDetailRollover(
                                                    !empDetailRollover
                                                  )
                                                )
                                              }
                                              setCurrentEmpDetailId(
                                                row.original.id
                                              )
                                            }}
                                            className={`table__cell table__cell--valign u-w-6 ${
                                              row.original.cardStatus ===
                                              MultiPassCardState.VKošíku
                                                ? 'table__cell--dot-blue-light'
                                                : row.original.cardStatus ===
                                                  MultiPassCardState.NáhradníKartaVKošíku
                                                ? 'table__cell--dot-aquamarine'
                                                : ''
                                            }`}
                                            {...row.cells[3].getCellProps()}
                                          >
                                            {row.cells[3].render('Cell')}
                                          </div>
                                          {/* karta */}
                                          <div
                                            // this should stop the click event from bubbling up to the parent... should be fixed in next version of react-table
                                            onMouseUp={(e) => {
                                              e.stopPropagation()
                                              e.preventDefault()
                                            }}
                                            role='cell'
                                            className='table__cell table__cell--valign u-py-medium-1 u-w-6'
                                            style={{ cursor: 'default' }}
                                          >
                                            <div
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onClick={() => {
                                                setEmpAddRollover(false)
                                                closeContainerRollovers()
                                                // if not already open or open on the same user, toggle rollover, otherwise keep rollover open, but change Employee id
                                                if (
                                                  currentEmpDetailId ===
                                                    row.original.id ||
                                                  currentEmpDetailId === '' ||
                                                  empDetailRollover === false
                                                ) {
                                                  dispatch(
                                                    reducerEmpDetailRollover(
                                                      !empDetailRollover
                                                    )
                                                  )
                                                }
                                                setCurrentEmpDetailId(
                                                  row.original.id
                                                )
                                              }}
                                              className={
                                                'tag u-w-12 ' +
                                                selectCardTagClass(
                                                  row.cells[4].value
                                                )
                                              }
                                              {...row.cells[4].getCellProps()}
                                            >
                                              {row.cells[4].render('Cell')}
                                              {row.cells[4].value ===
                                              MultiPassCardState.NáhradníKartaVKošíku ? (
                                                <svg
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    dispatch(
                                                      tableActions.zrusitNahradniKartu(
                                                        row.original.id
                                                      )
                                                    )
                                                    // vymazatOddeleni(
                                                    //   address,
                                                    //   department
                                                    // )
                                                  }}
                                                  className='input__remove icon icon--16'
                                                  style={{
                                                    right: '0.5rem',
                                                  }}
                                                >
                                                  <CloseCircleFilledIcon
                                                    fill={Color.RED}
                                                    width={16}
                                                    height={16}
                                                  />
                                                </svg>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {/* volnočasový, stravenkový kredit,  */}
                                        <div
                                          className='table__group table__group--large u-w-7 u-hide-small-down'
                                          style={{ cursor: 'default' }}
                                        >
                                          <div
                                            className='table__cell table__cell--overflow table__cell--valign u-w-5'
                                            {...row.cells[5].getCellProps()}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <div className='input u-fullWidth input--tiny'>
                                              <div className='input__wrapper'>
                                                <input
                                                  placeholder={lg(
                                                    lang,
                                                    ENTER_AMOUNT
                                                  )}
                                                  type='tel'
                                                  // value={selectVolnocasovyKreditById(row.id)}
                                                  onKeyPress={
                                                    validateNumericKeyWithWhites
                                                  }
                                                  onWheel={(e) =>
                                                    e.currentTarget.blur()
                                                  }
                                                  value={
                                                    row.cells[5].value == 0
                                                      ? ''
                                                      : row.cells[5].value
                                                  }
                                                  // value={cell.value}
                                                  onChange={(e) => {
                                                    dispatch(
                                                      tableActions.setLeisureCredit(
                                                        {
                                                          id: row.cells[3]
                                                            .value, // cells[3] = id, based on the order of headers, not the order of cells data
                                                          amount:
                                                            e.target.value,
                                                        }
                                                      )
                                                    )
                                                  }}
                                                  style={{
                                                    border: `1px solid ${
                                                      row.cells[5].value > 0
                                                        ? '#3cdcc8'
                                                        : '#2a295c'
                                                    }`,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/*  */}
                                            {/* HISTORY POPOVER */}
                                            {/*  */}
                                            <div
                                              className='modal__trigger popover__wrapper'
                                              style={{
                                                cursor:
                                                  doesEmployeeHaveHistoricalLeisureOrders(
                                                    row.original.id
                                                  )
                                                    ? 'pointer'
                                                    : 'default',
                                              }}
                                            >
                                              <svg
                                                className='modal__trigger icon icon--16 u-ml-1'
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  if (
                                                    doesEmployeeHaveHistoricalLeisureOrders(
                                                      row.original.id
                                                    )
                                                  ) {
                                                    setPopover(
                                                      popover ===
                                                        'history#' +
                                                          row.original.id
                                                        ? ''
                                                        : 'history#' +
                                                            row.original.id
                                                    )
                                                  }
                                                }}
                                                style={{
                                                  opacity:
                                                    doesEmployeeHaveHistoricalLeisureOrders(
                                                      row.original.id
                                                    )
                                                      ? '1.0'
                                                      : '0.5',
                                                  cursor:
                                                    doesEmployeeHaveHistoricalLeisureOrders(
                                                      row.original.id
                                                    )
                                                      ? 'pointer'
                                                      : 'default',
                                                }}
                                              >
                                                <use
                                                  href={
                                                    icons + '#sprite-history'
                                                  }
                                                  className='modal__trigger'
                                                  style={{
                                                    opacity:
                                                      doesEmployeeHaveHistoricalLeisureOrders(
                                                        row.original.id
                                                      )
                                                        ? '1.0'
                                                        : '0.5',
                                                    cursor:
                                                      doesEmployeeHaveHistoricalLeisureOrders(
                                                        row.original.id
                                                      )
                                                        ? 'pointer'
                                                        : 'default',
                                                  }}
                                                />
                                              </svg>
                                              <div
                                                className={
                                                  'modal__trigger popover popover--bottom-right popover--blue' +
                                                  (popover ===
                                                  'history#' + row.original.id
                                                    ? ' popover--open'
                                                    : '')
                                                }
                                              >
                                                <div className='modal__trigger popover__body'>
                                                  <div className='modal__trigger popover__close u-text-right u-mb-1'>
                                                    <svg
                                                      className='modal__trigger icon icon--16'
                                                      onClick={(e) => {
                                                        e.stopPropagation()
                                                        setPopover('')
                                                      }}
                                                    >
                                                      <CloseIcon
                                                        height={16}
                                                        width={16}
                                                        className='modal__trigger'
                                                      />
                                                    </svg>
                                                  </div>
                                                  <LeisureHistoryPopoverInsides
                                                    innerRef={ref}
                                                    id={row.original.id}
                                                    closePopover={() => {
                                                      setPopover('')
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className='table__cell table__cell--no-border u-w-7'
                                            {...row.cells[6].getCellProps()}
                                          >
                                            <div className='input input--tiny'>
                                              <div className='input__wrapper'>
                                                <input
                                                  placeholder={
                                                    zadaniStravKredituCastkaKreditu
                                                      ? lg(lang, ENTER_AMOUNT)
                                                      : lg(
                                                          lang,
                                                          ENTER_NUMBER_OF_DAYS
                                                        )
                                                  }
                                                  // value={
                                                  //   zadaniStravKredituCastkaKreditu
                                                  //     ? cell.value
                                                  //     : cell.value === 0
                                                  //     ? ''
                                                  //     : Math.ceil(
                                                  //         cell.value /
                                                  //           getEmployeeDailyCredit(
                                                  //             row.original
                                                  //               .id
                                                  //           )
                                                  //       )
                                                  // }
                                                  value={
                                                    zadaniStravKredituCastkaKreditu
                                                      ? row.cells[6].value == 0
                                                        ? ''
                                                        : row.cells[6].value
                                                      : row.cells[6].value == 0
                                                      ? ''
                                                      : Math.ceil(
                                                          row.cells[6].value /
                                                            getEmployeeDailyCredit(
                                                              row.original.id
                                                            )
                                                        )
                                                  }
                                                  onChange={(e) => {
                                                    dispatch(
                                                      tableActions.setGastroCredit(
                                                        {
                                                          id: row.cells[3]
                                                            .value, // cells[3] = id, based on the order of headers, not the order of cells data
                                                          amount:
                                                            zadaniStravKredituCastkaKreditu
                                                              ? e.target.value
                                                              : +e.target
                                                                  .value *
                                                                getEmployeeDailyCredit(
                                                                  row.original
                                                                    .id
                                                                ),
                                                        }
                                                      )
                                                    )
                                                  }}
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                  type='tel'
                                                  onKeyPress={
                                                    validateNumericKeyWithWhites
                                                  }
                                                  onWheel={(e) =>
                                                    e.currentTarget.blur()
                                                  }
                                                  style={{
                                                    border: `1px solid ${
                                                      row.cells[6].value > 0
                                                        ? '#3cdcc8'
                                                        : '#2a295c'
                                                    }`,
                                                  }}
                                                />
                                                {!zadaniStravKredituCastkaKreditu && (
                                                  <span className='input__suffix u-color-dark-purple-blue-alt'>
                                                    x{' '}
                                                    {getEmployeeDailyCredit(
                                                      row.original['id']
                                                    )}{' '}
                                                    Kč
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }))}{' '}
                      </div>
                    )}
                  </div> //DIV PRO SKUPINY
                )
              })}
            </div>
          </div>
        </div>
      )}
      <div className='panel__wrapper'>
        <div className={'panel' + (empAddRollover ? ' panel--open' : '')}>
          <div className='panel__body'>
            <div className='box box--narrow'>
              <div className='box__content'>
                <div className='u-text-right u-mb-2'>
                  <button
                    className='button-as-anchor underline u-size-base14 u-color-dark-purple-blue'
                    onClick={() => setEmpAddRollover(false)}
                  >
                    {lg(lang, CLOSE)}
                    <svg className='icon icon--24 u-ml-1'>
                      <DeleteIcon width={24} height={24} />
                    </svg>
                  </button>
                </div>
                <EmployeeAdd
                  onSubmit={() => {
                    setEmpAddRollover(false)
                    forceUpdate()
                    console.log('forcingUpdate') // keep it here for now, without this it was not updating
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='panel__wrapper'>
        <div className={'panel' + (empDetailRollover ? ' panel--open' : '')}>
          <div className='panel__body'>
            <div className='box box--user'>
              <div className='box__content'>
                <div className='u-text-right u-mb-2'>
                  <button
                    className='button-as-anchor underline u-size-base14 u-color-dark-purple-blue'
                    onClick={() => {
                      setEmpAddRollover(false)
                      dispatch(reducerEmpDetailRollover(!empDetailRollover))
                    }}
                  >
                    {lg(lang, CLOSE)}
                    <svg className='icon icon--24 u-ml-1'>
                      <DeleteIcon width={24} height={24} />
                    </svg>
                  </button>
                </div>
                <EmployeeDetail // FORCE REFRESH EVERY TIME IT LOADS
                  id={currentEmpDetailId}
                  volnocasovyKreditZTabulky={useAppSelector(
                    selectVolnocasovyKreditById(currentEmpDetailId)
                  )}
                  stravenkovyKreditZTabulky={useAppSelector(
                    selectStravenkovyKreditById(currentEmpDetailId)
                  )}
                  pristiVolnocasovy={true}
                  pristiStravenkovy={true}
                  onSubmit={() => dispatch(reducerEmpDetailRollover(false))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableThree
