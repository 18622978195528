import { MultiPassCardState } from './CardOps'

const faker = {
  name: {
    firstName: () => {
      return [
        'Nam',
        'Trần',
        'Nguyễn',
        'Phạm',
        'Trịnh',
        'Đặng',
        'Lê',
        'Hoàng',
        'Phan',
        'Vũ',
      ][Math.floor(Math.random() * 10)]
    },
    lastName: () => {
      return [
        'Hải',
        'Văn',
        'Thịnh',
        'Thanh',
        'Thủy',
        'Tuấn',
        'Hạnh',
        'Phúc',
        'Quang',
        'Thắng',
      ][Math.floor(Math.random() * 10)]
    },
  },
  card: {
    cardStatus: () => {
      return [
        MultiPassCardState.VeVýrobě,
        MultiPassCardState.Aktivní,
        MultiPassCardState.Blokovaná,
        MultiPassCardState.Expirovaná,
      ][Math.floor(Math.random() * 4)]
    },
  },
}

export function randomFirstName(): string {
  return faker.name.firstName()
}

export function randomLastName(): string {
  return faker.name.lastName()
}

export function randomCardStatus(): string {
  return faker.card.cardStatus()
}
