import CheckIcon from '@styles/icon/checkIcon'
import EraseIcon from '@styles/icon/eraseIcon'
import PenIcon from '@styles/icon/penIcon'
import PlusIcon from '@styles/icon/plusIcon'
import Color from 'app/colors'
import icons from '@styles/dist/system/icons.svg'
import { COUNTRY, SAVE } from 'app/constDictionary'
import { changeZamestnanecAddress, lg } from 'app/facade'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  createAdresaBackend,
  createKontaktniOsobaBackend,
  logout,
  mutateAdresaBackend,
  mutateKontaktniOsobaBackend,
  mutateZamestnanecBackend,
  purgeAdresaBackend,
  purgeKontaktniOsobaBackend,
} from 'app/req'
import {
  Address,
  BrokenAddress,
  KontaktniOsobaBackendRequest,
  Role,
  User,
  ZamestnanecBackendEditace,
} from 'app/type'
import {
  govActions,
  selectAddresses,
  selectAddressByUliceCisloPopisne,
  selectBrokenAddresses,
} from 'components/settings/governanceSlice'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import {
  selectTable,
  tableActions,
  TableRow,
} from 'components/table/tableSlice'
import { Formik, Form, Field } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { isMigrating, isCorrecting } from 'util/migrationOps'
import {
  validateJmeno,
  validatePrijmeni,
  validateEmail,
  equalsBezRozdiluVCisluOrientacnim,
  splitUliceCisloPopisne,
  validateNepovinnyTelefon,
  findMatchingAddress,
  equalsNormalize,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from 'util/ValidFormatOps'
import FiremniUdaje from '../../onboarding/FiremniUdaje'
import SettingsAddressChangeForm from './SettingsAddressChangeForm'

interface ModalProps {
  onSubmit: () => void
  onCancel?: () => void
  uliceCisloPopisne?: string // for SmazatAdresuModal
  psc?: string
  callerUser?: User
  loggedInUserEmail?: string
  setSelectedAddress?: (address: Address) => void
}

export function doesZakaznikUseOddeleni(addresses: Address[]): boolean {
  return addresses.some((address) => address.oddeleni[0] !== 'Žádné')
}

export const SmazatAdresuModal: React.FC<ModalProps> = ({
  onSubmit,
  onCancel,
  uliceCisloPopisne,
}) => {
  const dispatch = useAppDispatch()
  const table = useAppSelector(selectTable) as TableRow[]

  console.log('SmazatAdresuModal', { uliceCisloPopisne })

  const callerAddress = useAppSelector(
    selectAddressByUliceCisloPopisne(uliceCisloPopisne!)
  ) as Address
  const addresses = useAppSelector(selectAddresses) as Address[]
  const otherAddresses =
    addresses.length > 1
      ? addresses.filter((address) => address !== callerAddress)
      : []

  const [checkedDepartment, setCheckedDepartment] = React.useState(
    otherAddresses[0].oddeleni[0]
  )

  const [checkedUliceCisloPopisne, setCheckedUliceCisloPopisne] =
    React.useState('')

  function employeesCountOnAddress() {
    let count = 0
    for (const employee of table) {
      if (
        // todo: non-safe check -> will count more. Should be fixed (add psc and mesto checks too)
        employee.uliceCisloPopisne === uliceCisloPopisne
      ) {
        count++
      }
    }
    return count
  }

  async function prestehovatZamestnance() {
    //
    //   .filter(
    //   (employee) => !employee.deactivatedFrom
    // )
    for (const employee of table) {
      if (employee.uliceCisloPopisne === uliceCisloPopisne) {
        dispatch(
          tableActions.setUliceCisloPopisne({
            id: employee.id,
            uliceCisloPopisne: checkedUliceCisloPopisne,
            oddeleni: checkedDepartment,
          })
        )

        const newEmployeeAddress = findMatchingAddress(
          checkedUliceCisloPopisne,
          addresses
        ) as Address

        const { ulice, cisloPopisne } =
          splitUliceCisloPopisneZachovatCisloOrientacni(
            newEmployeeAddress.uliceCisloPopisne
          )
        const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
          id: +employee.id,
          jmeno: employee.name,
          prijmeni: employee.surname,
          adresa: {
            ulice: ulice,
            cisloPopisne: cisloPopisne,
            psc: newEmployeeAddress.psc.toString(),
            oddeleni: checkedDepartment,
          },
          zaplaceneMesice: employee.paidMonths,
          deaktivovaneMesice: employee.deactivatedMonths,
        }
        mutateZamestnanecBackend(zamestnanecBackendEditace)
      }
    }
  }

  function odstranitAdresu() {
    dispatch(govActions.removeAddressByUliceCisloPopisne(uliceCisloPopisne))
    purgeAdresaBackend(callerAddress)
  }

  return (
    <div className='modal__content'>
      <div>
        <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
          <div className='highlight__content'>
            <p className='u-mb-2'>
              <strong>{employeesCountOnAddress() > 0 && 'Pozor!'}</strong> Touto
              akcí odstraníte adresu, ke které patří{' '}
              <strong>{employeesCountOnAddress()}</strong> zaměstnanců.
            </p>
            {employeesCountOnAddress() > 0 && (
              <div>
                <p className='u-mb-2'>
                  {doesZakaznikUseOddeleni(otherAddresses)
                    ? 'Zvolte oddělení na jiné adrese, kam chcete tyto zaměstnance převést.'
                    : 'Zvolte jinou adresu, kam chcete tyto zaměstnance převést.'}
                </p>
                <p className='u-mb-2 u-size-base14 u-color-c1-40'>
                  {doesZakaznikUseOddeleni(otherAddresses)
                    ? 'Vyberte jedno oddělení'
                    : 'Vyberte jednu adresu'}
                </p>
                {otherAddresses.map((address) =>
                  address.oddeleni.map((department) => (
                    <div
                      key={address + department}
                      className='highlight u-mb-2 u-px-3 u-py-3 u-bg-white'
                    >
                      <div className='highlight__content'>
                        <label className='checkbox'>
                          {doesZakaznikUseOddeleni(otherAddresses) &&
                            department}{' '}
                          - {address.uliceCisloPopisne},{' '}
                          {address.nazevSpolecnosti}{' '}
                          <input
                            type='radio'
                            value={department}
                            checked={
                              checkedDepartment === department &&
                              equalsBezRozdiluVCisluOrientacnim(
                                // safe equality check
                                checkedUliceCisloPopisne,
                                address.uliceCisloPopisne
                              )
                            }
                            onChange={() => {
                              setCheckedDepartment(department)
                              setCheckedUliceCisloPopisne(
                                address.uliceCisloPopisne
                              )
                            }}
                          />
                          <span className='checkbox__box'></span>
                        </label>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        <div className='grid-x u-justify-center'>
          <a
            onClick={() => {
              onSubmit()
              prestehovatZamestnance()
              odstranitAdresu()
            }}
            className='btn u-mr-2 btn--primary'
          >
            <svg className='icon icon--24'>
              <EraseIcon width={24} height={24} fill={Color.WHITE} />
            </svg>
            Smazat adresu{' '}
            {employeesCountOnAddress() > 0 && 'a převést zaměstnance'}
          </a>
          <a onClick={onCancel} className='btn btn--primary btn--outlined'>
            Storno
          </a>
        </div>
      </div>
    </div>
  )
}

const OIIIModal: React.FC<ModalProps> = (props) => {
  const submitIcon = <CheckIcon />
  const lang = useAppSelector(selectLanguage) || 'cz'
  const submitText = lg(lang, SAVE)

  return (
    <FiremniUdaje
      submitIcon={submitIcon}
      submitText={submitText}
      nextRoute={''}
      onSubmit={props.onSubmit}
    />
  )
}

export const UpravitAdresuModal: React.FC<ModalProps> = ({
  onSubmit,
  uliceCisloPopisne: callerUliceCisloPopisne,
  psc: callerPsc,
}) => {
  const formRef = useRef(null)

  useEffect(() => {
    if (formRef.current) {
      // @ts-ignore
      formRef.current.validateForm()
    }
  }, [])

  const dispatch = useAppDispatch()
  const addresses = useAppSelector(selectAddresses) as Address[]
  const brokenAddresses = useAppSelector(
    selectBrokenAddresses
  ) as BrokenAddress[]
  const table = useAppSelector(selectTable) as TableRow[] // !!! If uliceCisloPopisne gets changed, we must update table of employees too, as it is the PK

  const isBrokenAddress = brokenAddresses.length > 0
  // we have to load this useAppSelector value ahead of time, even if it is NOT a broken address, otherwise we get a "Change in the order of the Hooks" error!
  //  Previous render            Next render
  //  ------------------------------------------------------
  //  1. useRef                     useRef
  //  2. useEffect                  useEffect
  //  3. useContext                 useContext
  //  4. useContext                 useContext
  //  5. useReducer                 useReducer
  //  6. useMemo                    useMemo
  //  7. useRef                     useRef
  //  8. useRef                     useRef
  //  9. useRef                     useRef
  //  10. useRef                    useRef
  //  11. useLayoutEffect           useLayoutEffect
  //  12. useLayoutEffect           useLayoutEffect
  //  13. useDebugValue             useDebugValue
  //  14. useContext                useContext
  //  15. useReducer                useReducer
  //  16. useMemo                   useMemo
  //  17. useRef                    useRef
  //  18. useRef                    useRef
  //  19. useRef                    useRef
  //  20. useRef                    useRef
  //  21. useLayoutEffect           useLayoutEffect
  //  22. useLayoutEffect           useLayoutEffect
  //  23. useDebugValue             useDebugValue
  //  24. useContext                useContext
  //  25. useReducer                useReducer
  //  26. useMemo                   useMemo
  //  27. useRef                    useRef
  //  28. useRef                    useRef
  //  29. useRef                    useRef
  //  30. useRef                    useRef
  //  31. useLayoutEffect           useLayoutEffect
  //  32. useLayoutEffect           useLayoutEffect
  //  33. useDebugValue             useDebugValue
  //  34. undefined                 useContext
  // const okayCallerAddress = useAppSelector(
  //   selectAddressByUliceCisloPopisne(callerUliceCisloPopisne as string)
  // ) as Address // ORDTEX-168 In UpravitAdresuModal, the field callerAddress can attain 'undefined' value: if we edit uliceCisloPopisne, the form gets saved properly, but before it gets saved the state here gets refreshed and Formik tries to populate the values from now non-existent address before the window gets closed.

  const callerAddress = isBrokenAddress
    ? (brokenAddresses.find(
        (address) =>
          equalsNormalize(
            address.street + ', ' + address.cisloPopisne,
            callerUliceCisloPopisne
          ) && equalsNormalize(address.psc, callerPsc)
      ) as BrokenAddress)
    : (findMatchingAddress(
        callerUliceCisloPopisne as string,
        addresses
      ) as Address)

  // we do not want to use this one if it is a broken address... as it can fail splitting it because the format may not be according to our standards
  const { ulice: nonBrokenUlice, cisloPopisne: nonBrokenCisloPopisne } =
    splitUliceCisloPopisne(callerUliceCisloPopisne)
  const callerCisloPopisne = isBrokenAddress
    ? (callerAddress as BrokenAddress)?.cisloPopisne || '' // prevents "Cannot read properties of undefined (reading 'cisloPopisne')" why it happens? something is happening when the modal is successfully sent to backend, the values are reset and then read again and on the reset values there is no such field (that's my theory, anyways)
    : nonBrokenCisloPopisne
  const callerUlice = isBrokenAddress
    ? subtractStringUnsafe(
        callerUliceCisloPopisne as string,
        callerCisloPopisne
      )
    : nonBrokenUlice

  const displayedCallerUliceCisloPopisne =
    callerUlice + ', ' + callerCisloPopisne

  // the caller should BE SURE that s2 is inside s1
  function subtractStringUnsafe(s1: string, s2: string): string {
    const start = s1.indexOf(s2)
    // const end = start + s2.length
    const result = s1.slice(0, start)
    console.log(`Subtracting ${s2} from ${s1}, result:`, result)
    return result
  }

  console.log(
    `UpravitAdresuModal, callerUliceCisloPopisne, callerAddress, addresses, brokenAddresses, evaluated: callerUlice ${callerUlice}, callerCisloPopisne ${callerCisloPopisne}`,
    callerUliceCisloPopisne,
    callerAddress,
    addresses,
    brokenAddresses
  )

  return (
    <div className='modal__content'>
      {isBrokenAddress && (
        <div>
          <div
            className='highlight u-mb-4 u-py-4 highlight--narrow'
            style={{ textAlign: 'center' }}
          >
            <span>
              {isMigrating() &&
                'Prošli jste migrací dat. Zkontrolujte správnost údajů v následující adrese.'}
              {isCorrecting() &&
                'Tato adresa je neúplná či v nesprávném formátu. Zkontrolujte správnost údajů.'}
            </span>
          </div>
        </div>
      )}
      <Formik
        innerRef={formRef}
        // enableReinitialize
        initialValues={{
          uliceCisloPopisne: displayedCallerUliceCisloPopisne || '',
          kontaktniOsoba: callerAddress?.kontaktniOsoba || '',
          mesto: callerAddress?.mesto || '',
          psc: callerAddress?.psc || '',
          telefon: callerAddress?.telefon.toString() || '',
          nazevSpolecnosti: callerAddress?.nazevSpolecnosti || '',
          predvolba: callerAddress?.predvolba || 420,
        }}
        initialTouched={{
          uliceCisloPopisne: true,
          kontaktniOsoba: true,
          mesto: true,
          psc: true,
          telefon: true,
          nazevSpolecnosti: true,
          predvolba: true,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          const updatedAddress: Address = {
            uuid: callerAddress.uuid,
            nazevSpolecnosti: values.nazevSpolecnosti,
            uliceCisloPopisne: values.uliceCisloPopisne,
            kontaktniOsoba: values.kontaktniOsoba,
            mesto: values.mesto,
            psc: +values.psc.toString().replace(/\s/g, ''),
            telefon: +values.telefon.replace(/\s/g, ''),
            predvolba: values.predvolba,
            oddeleni: callerAddress.oddeleni,
          }
          const uuid = callerAddress.uuid
          if (uuid && uuid.length > 0) {
            dispatch(
              govActions.updateAddressByUUID({
                uuid: uuid,
                newAddress: updatedAddress,
              })
            )
            if (isBrokenAddress) {
              dispatch(
                govActions.removeAddressFromListOfBrokenByUUID({
                  uuid: uuid,
                })
              )
            }
          } else {
            dispatch(
              govActions.updateAddress({
                uliceCisloPopisne: callerUlice + ', ' + callerCisloPopisne,
                newAddress: updatedAddress,
              })
            )
            if (isBrokenAddress) {
              dispatch(
                govActions.removeAddressFromListOfBrokenByUliceCisloPopisnePsc({
                  ulice: callerUlice,
                  cisloPopisne: callerCisloPopisne,
                  psc: callerPsc,
                })
              )
            }
          }

          console.log(
            'Upravit adresu Modal onSubmit, callerAddress, updateAddress, callerUuid (optional)',
            callerAddress,
            updatedAddress,
            callerAddress.uuid
          )
          mutateAdresaBackend({
            callerAddress: {
              ulice: callerUlice,
              cisloPopisne: callerCisloPopisne,
              psc: callerPsc as string,
            },
            updatedAddress,
            callerUuid: callerAddress.uuid,
          }).then((data) => {
            console.log(
              `UpravitAdresuModal, mutateAdresaBackend, response data, assuming backend processed the mutation of address correctly, proceeding with migrating employees that were located there: ${data}`
            )
            for (const employee of table) {
              if (
                // safe check
                equalsBezRozdiluVCisluOrientacnim(
                  employee.uliceCisloPopisne,
                  callerUliceCisloPopisne
                )
              ) {
                dispatch(
                  tableActions.setUliceCisloPopisne({
                    id: employee.id,
                    uliceCisloPopisne: values.uliceCisloPopisne,
                  })
                )
                changeZamestnanecAddress(employee, updatedAddress)
              }
            }
          })
          onSubmit()
        }}
      >
        {({ errors, touched, values, handleChange, submitForm }) => (
          <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
            <div className='highlight__content'>
              <SettingsAddressChangeForm
                errors={errors}
                touched={touched}
                values={values}
              />
            </div>
            <div className='u-text-center'>
              <a onClick={submitForm} className='btn btn--primary'>
                <svg className='icon icon--24'>
                  <PenIcon />
                </svg>
                Uložit změny
              </a>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export const PridatAdresuModal: React.FC<ModalProps> = ({
  onSubmit,
  setSelectedAddress,
}) => {
  const dispatch = useAppDispatch()

  return (
    <div className='modal__content'>
      <Formik
        initialValues={{
          uliceCisloPopisne: '',
          kontaktniOsoba: '',
          mesto: '',
          psc: '',
          telefon: '',
          nazevSpolecnosti: '',
          predvolba: 420,
        }}
        onSubmit={(values) => {
          const newAddress = {
            nazevSpolecnosti: values.nazevSpolecnosti,
            kontaktniOsoba: values.kontaktniOsoba,
            uliceCisloPopisne: values.uliceCisloPopisne,
            mesto: values.mesto,
            psc: +values.psc.replace(/\s/g, ''),
            telefon: +values.telefon.replace(/\s/g, ''),
            oddeleni: ['Žádné'],
            predvolba: values.predvolba,
          } as Address
          dispatch(govActions.addAddress(newAddress))
          createAdresaBackend(newAddress)
          setSelectedAddress && setSelectedAddress(newAddress) // only for DIC-6, not for Settings
          onSubmit()
        }}
      >
        {({ errors, touched, submitForm, values }) => (
          <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
            <div className='highlight__content'>
              <SettingsAddressChangeForm
                errors={errors}
                touched={touched}
                values={values}
              />
            </div>
            <div className='u-text-center'>
              <a onClick={submitForm} className='btn btn--primary'>
                <svg className='icon icon--24'>
                  <PenIcon />
                </svg>
                Uložit změny
              </a>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export const CreateNewUserModal: React.FC<ModalProps> = ({ onSubmit }) => {
  const dispatch = useAppDispatch()
  const [predvolbaSelectionShown, setPredvolbaSelectionShown] = useState(false)
  const [predvolba, setPredvolba] = useState(420)
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div className='modal__content'>
      <Formik
        initialValues={{
          jmeno: '',
          prijmeni: '',
          email: '',
          telefon: '',
          role: Role.Vytvořená,
        }}
        onSubmit={(values) => {
          const newUserBackend: KontaktniOsobaBackendRequest = {
            email: values.email.trim(),
            name: values.jmeno.trim(),
            surname: values.prijmeni.trim(),
            countryCode:
              values.telefon === '' ? undefined : (predvolba as number),
            phoneNumber: values.telefon === '' ? undefined : +values.telefon,
          }
          createKontaktniOsobaBackend(newUserBackend)
            .then((data) => {
              console.log('createKontaktniOsobaBackend data', data)
              if (data.zprava === 'Email already registered') {
                // code 409 (Conflict)
                alert('Uživatel s tímto emailem již existuje')
              } else if (
                data.zprava === 'Musíte být admin k provedení této akce'
              ) {
                // code 403 (Forbidden)
                alert('Nemáte oprávnění k vytvoření nového uživatele')
              } else {
                // code 200
                console.log('data', data)
                const newUserFrontend: User = {
                  jmeno: values.jmeno,
                  prijmeni: values.prijmeni,
                  email: values.email,
                  predvolba:
                    values.telefon === '' ? undefined : (predvolba as number),
                  telefon: values.telefon === '' ? undefined : +values.telefon,
                  role: values.role,
                }
                dispatch(govActions.addUser(newUserFrontend))
              }
              onSubmit()
            })
            .catch((error) => {
              if (error.message === '409') {
                alert('Uživatel s tímto emailem již existuje')
              } else if (error.message === '403') {
                alert('Nemáte oprávnění k vytvoření nového uživatele')
              } else {
                alert(
                  `Nastala neznámá chyba, zkuste to prosím později nebo kontaktujte podporu. Chybový kód: ${error.message}`
                )
              }
              onSubmit()
            })
        }}
      >
        {({ errors, touched, submitForm }) => (
          <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
            <div className='highlight__content'>
              <Form id='settings-address-change-form'>
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>Jméno</label>
                  <Field
                    validate={validateJmeno}
                    type='text'
                    placeholder='Např. Karel'
                    name='jmeno'
                  />
                  {errors.jmeno && touched.jmeno && (
                    <span className='input__error'>{errors.jmeno}</span>
                  )}
                </div>
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>Příjmení</label>
                  <Field
                    validate={validatePrijmeni}
                    type='text'
                    placeholder='Např. Havlíček'
                    name='prijmeni'
                  />
                  {errors.prijmeni && touched.prijmeni && (
                    <span className='input__error'>{errors.prijmeni}</span>
                  )}
                </div>
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>E-mail</label>
                  <Field
                    validate={validateEmail}
                    type='text'
                    placeholder='Např. havlicek@pluxee.cz'
                    name='email'
                  />
                  {errors.email && touched.email && (
                    <span className='input__error'>{errors.email}</span>
                  )}
                </div>
                <div className='grid-x'>
                  <div className='cell medium-5 u-pr-medium-2 input u-mb-4'>
                    <div
                      onClick={() =>
                        setPredvolbaSelectionShown(!predvolbaSelectionShown)
                      }
                      id='country-code'
                      className={
                        'cphone-code' +
                        ' ' +
                        (predvolbaSelectionShown ? 'cphone-code--active' : '')
                      }
                    >
                      <label
                        htmlFor='country-code'
                        className='country-code__label'
                      >
                        {lg(lang, COUNTRY)}
                      </label>{' '}
                      <div className='cphone-code__option cphone-code__option--selected'>
                        <svg className='icon icon--24'>
                          <use
                            href={
                              icons +
                              (predvolba === 420
                                ? '#sprite-flag-cz'
                                : '#sprite-flag-sk')
                            }
                          />
                        </svg>{' '}
                        <span>
                          <svg className='icon icon--16 u-mx-1'>
                            <use
                              href={
                                icons +
                                (predvolbaSelectionShown
                                  ? '#sprite-arrow-thin-up'
                                  : '#sprite-arrow-thin-down')
                              }
                            />
                          </svg>
                        </span>{' '}
                        <span>+{predvolba}</span>
                      </div>{' '}
                      {predvolbaSelectionShown && (
                        <div
                          onClick={() => {
                            setPredvolbaSelectionShown(false)
                            setPredvolba(predvolba === 420 ? 421 : 420)
                          }}
                          className='cphone-code__options'
                        >
                          <div className='cphone-code__option'>
                            <svg className='icon icon--24 u-mr-1'>
                              <use
                                href={
                                  icons +
                                  (predvolba === 420
                                    ? '#sprite-flag-sk'
                                    : '#sprite-flag-cz')
                                }
                              />
                            </svg>{' '}
                            <span>+{predvolba === 420 ? 421 : 420}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='cell medium-7 input u-mb-4'>
                    <label className='input__label'>Telefon</label>
                    <Field
                      validate={validateNepovinnyTelefon}
                      type='tel'
                      placeholder={`${lg(lang, 'FOR_EXAMPLE')} 723 723 723`}
                      name='telefon'
                      // the formatting stuff does not work properly inside Formik
                      // value={(e) => formatTelefon(e.target.value)}
                      // onKeyPress={validateNumericKeyWithWhites}
                      // onChange={(e) =>
                      //   setTelefon(e.target.value.replace(/[^0-9]/g, ''))
                      // }
                    />
                    {errors.telefon && touched.telefon && (
                      <span className='input__error'>{errors.telefon}</span>
                    )}
                  </div>
                </div>
              </Form>
            </div>
            <div className='u-text-center'>
              <a onClick={submitForm} className='btn btn--primary'>
                <svg className='icon icon--24'>
                  <PenIcon />
                </svg>
                Vložit osobu
              </a>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export const EditUserModal: React.FC<ModalProps> = ({
  onSubmit,
  callerUser: user,
}) => {
  const dispatch = useAppDispatch()
  const [predvolbaSelectionShown, setPredvolbaSelectionShown] = useState(false)
  const [predvolba, setPredvolba] = useState(user?.predvolba || 420)
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div className='modal__content'>
      <Formik
        initialValues={{
          jmeno: user?.jmeno,
          prijmeni: user?.prijmeni,
          email: user?.email,
          predvolba: user?.predvolba || 420,
          telefon: user?.telefon as unknown as string,
        }}
        onSubmit={(values) => {
          // trim whitespaces at the end
          const jmeno: string = values.jmeno?.trim() || ''
          const prijmeni: string = values.prijmeni?.trim() || ''
          const email: string = values.email?.trim() || ''
          // do not include predvolba here, otherwise it does not load it from useState
          const telefon: string = values.telefon?.trim() || ''

          const editedUserBackend: KontaktniOsobaBackendRequest = {
            name: jmeno,
            surname: prijmeni,
            email: email,
            countryCode:
              values.telefon === '' ? undefined : (predvolba as number),
            phoneNumber: values.telefon === '' ? undefined : +telefon,
          }
          mutateKontaktniOsobaBackend(editedUserBackend)
            .then((data) => {
              console.log('mutateKontaktniOsobaBackend data', data)
              if (data.zprava === 'Email already registered') {
                // code 409 (Conflict)
                alert('Uživatel s tímto emailem již existuje')
              } else if (
                data.zprava === 'Musíte být admin k provedení této akce'
              ) {
                // code 403 (Forbidden)
                alert('Nemáte oprávnění k vytvoření nového uživatele')
              } else {
                // code 200
                const editedUserFrontend: User = {
                  // jmeno: jmeno || '', // this one throws cannot read properties of undefined (reading 'jmeno')
                  // ORD-2130 - cannot read properties of undefined when referencing context from before async call after the async call because the context by then is gone and did not get copied as a closure
                  jmeno: values.jmeno?.trim() || '',
                  prijmeni: values.prijmeni?.trim() || '',
                  email: values.email || '',
                  predvolba:
                    values.telefon === '' ? undefined : (predvolba as number),
                  telefon: values.telefon === '' ? undefined : +values.telefon,
                  role: user?.role || Role.Aktivní,
                }
                dispatch(govActions.editUser(editedUserFrontend))
              }
              onSubmit()
            })
            .catch((error) => {
              if (error.message === '409') {
                alert('Uživatel s tímto emailem již existuje')
              } else if (error.message === '403') {
                alert('Nemáte oprávnění k vytvoření nového uživatele')
              }
              // else {
              //   alert(
              //     `Nastala neznámá chyba, zkuste to prosím později nebo kontaktujte podporu. Chybový kód: ${error.message}`
              //   )
              // }
              onSubmit()
            })
        }}
      >
        {({ errors, touched, submitForm }) => (
          <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
            <div className='highlight__content'>
              <Form id='settings-address-change-form'>
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>Jméno</label>
                  <Field
                    validate={validateJmeno}
                    type='text'
                    placeholder='Např. Karel'
                    name='jmeno'
                  />
                  {errors.jmeno && touched.jmeno && (
                    <span className='input__error'>{errors.jmeno}</span>
                  )}
                </div>
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>Příjmení</label>
                  <Field
                    validate={validatePrijmeni}
                    type='text'
                    placeholder='Např. Havlíček'
                    name='prijmeni'
                  />
                  {errors.prijmeni && touched.prijmeni && (
                    <span className='input__error'>{errors.prijmeni}</span>
                  )}
                </div>
                {/* cannot edit email. */}
                <div className='input u-mb-4 input--required'>
                  <label className='input__label'>E-mail</label>
                  <input
                    value={user?.email}
                    disabled={true}
                    type='text'
                    readOnly
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.03)',
                      opacity: 0.8,
                    }}
                  />
                  {/* <Field
                    validate={validateEmail}
                    type='text'
                    placeholder='Např. havlicek@pluxee.cz'
                    name='email'
                  />
                  {errors.email && touched.email && (
                    <span className='input__error'>{errors.email}</span>
                  )} */}
                </div>
                <div className='grid-x'>
                  <div className='cell medium-5 u-pr-medium-2 input u-mb-4'>
                    <div
                      onClick={() =>
                        setPredvolbaSelectionShown(!predvolbaSelectionShown)
                      }
                      id='country-code'
                      className={
                        'cphone-code' +
                        ' ' +
                        (predvolbaSelectionShown ? 'cphone-code--active' : '')
                      }
                    >
                      <label
                        htmlFor='country-code'
                        className='country-code__label'
                      >
                        {lg(lang, COUNTRY)}
                      </label>{' '}
                      <div className='cphone-code__option cphone-code__option--selected'>
                        <svg className='icon icon--24'>
                          <use
                            href={
                              icons +
                              (predvolba === 420
                                ? '#sprite-flag-cz'
                                : '#sprite-flag-sk')
                            }
                          />
                        </svg>{' '}
                        <span>
                          <svg className='icon icon--16 u-mx-1'>
                            <use
                              href={
                                icons +
                                (predvolbaSelectionShown
                                  ? '#sprite-arrow-thin-up'
                                  : '#sprite-arrow-thin-down')
                              }
                            />
                          </svg>
                        </span>{' '}
                        <span>+{predvolba}</span>
                      </div>{' '}
                      {predvolbaSelectionShown && (
                        <div
                          onClick={() => {
                            setPredvolbaSelectionShown(false)
                            setPredvolba(predvolba === 420 ? 421 : 420)
                          }}
                          className='cphone-code__options'
                        >
                          <div className='cphone-code__option'>
                            <svg className='icon icon--24 u-mr-1'>
                              <use
                                href={
                                  icons +
                                  (predvolba === 420
                                    ? '#sprite-flag-sk'
                                    : '#sprite-flag-cz')
                                }
                              />
                            </svg>{' '}
                            <span>+{predvolba === 420 ? 421 : 420}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='cell medium-7 input u-mb-4'>
                    <label className='input__label'>Telefon</label>
                    <Field
                      validate={validateNepovinnyTelefon}
                      type='tel'
                      placeholder={`${lg(lang, 'FOR_EXAMPLE')} 723 723 723`}
                      name='telefon'
                      // the formatting stuff does not work properly inside Formik
                      // value={(e) => formatTelefon(e.target.value)}
                      // onKeyPress={validateNumericKeyWithWhites}
                      // onChange={(e) =>
                      //   setTelefon(e.target.value.replace(/[^0-9]/g, ''))
                      // }
                    />
                    {errors.telefon && touched.telefon && (
                      <span className='input__error'>{errors.telefon}</span>
                    )}
                  </div>
                </div>
              </Form>
            </div>
            <div className='u-text-center'>
              <a onClick={submitForm} className='btn btn--primary'>
                <svg className='icon icon--24'>
                  <PenIcon />
                </svg>
                Uložit změny
              </a>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export const RemoveUserModal: React.FC<ModalProps> = ({
  onSubmit,
  onCancel,
  callerUser,
  loggedInUserEmail,
}) => {
  const dispatch = useAppDispatch()
  return (
    <div className='modal__content'>
      <div>
        <div className='highlight u-mb-4 u-py-4 highlight--narrow'>
          <div className='highlight__content'>
            <p className='u-mb-2'>
              <strong>Pozor!</strong> Touto akcí odstraníte kontaktní osobu:{' '}
              <br />
              <strong>{`${callerUser?.jmeno} ${callerUser?.prijmeni}`}</strong>
            </p>
          </div>
        </div>
        <div className='grid-x u-justify-center'>
          <a
            onClick={() => {
              onSubmit()
              dispatch(govActions.removeUser(callerUser!.email))
              purgeKontaktniOsobaBackend(callerUser!.email)
              if (loggedInUserEmail === callerUser!.email) {
                logout()
              }
            }}
            className='btn u-mr-2 btn--primary'
          >
            <svg className='icon icon--24'>
              <PlusIcon width={24} height={24} />
            </svg>
            Odstranit osobu
          </a>
          <a onClick={onCancel} className='btn btn--primary btn--outlined'>
            Storno
          </a>
        </div>
      </div>
    </div>
  )
}

export default OIIIModal
