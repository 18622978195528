import React, { useState, useMemo, useEffect } from 'react'
import NoResult from '@styles/image/no-result.png'
import NoResult2x from '@styles/image/no-result2x.png'
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { useAppSelector, useBodyClass } from 'app/hooks'
import SideMenu from 'components/modules/menu/SideMenu'
import {
  DefaultColumnFilter,
  GlobalFilter,
  renderHeaderAndSortIndicators,
} from './Table'
import Color from '../../app/colors'
import { beInterniSpolecnosti, beInterniSprava, urlSelfcareTable } from 'App'
import { InternalCompany } from 'app/type'

import { defaultHeaders } from 'app/back'
import { ErrorModal } from 'components/modules/errorhandler/ErrorModal'
import MagnifyingGlassIcon from '@styles/icon/magnifyingGlassIcon'
import { govActions } from 'components/settings/governanceSlice'
import { useDispatch } from 'react-redux'
import { internalActions, selectInternalCompanies } from './internalSlice'
import PersonIcon from '@styles/icon/personIcon'
import { sendAndLog } from 'app/req'
import JstePrihlaseniZa from 'components/internal/JstePrihlaseniZa'
import { clearCache, clearFlags } from 'app/facade'
import { useNavigate } from 'react-router-dom'
import { nanoid } from '@reduxjs/toolkit'

const InternalWeb: React.FC = () => {
  useBodyClass('u-bg-light-grey')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const internalCompanies: InternalCompany[] = useAppSelector(
    selectInternalCompanies
  )

  const [isFilteringVisible, setFilteringVisible] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<InternalCompany>()

  async function callBackendAfterInternal() {
    console.log(
      `
      #####################################
      ########## AFTER-INTERNAL ###########
      #####################################
      GET ${beInterniSpolecnosti} 
        with options.headers:
        Accept = ${defaultHeaders().headers.Accept}
        Content-Type = ${defaultHeaders().headers['Content-Type']}
        Authorization = ${defaultHeaders().headers.Authorization}
      `
    )

    await fetch(beInterniSpolecnosti, defaultHeaders())
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        if (!data) {
          console.log(
            'failed to retrieve a response body on /interni/spolecnosti request'
          )
          return
        } else {
          console.log('callBackendAfterInternal() - data: %O', data)
          dispatch(internalActions.replaceCompanies(data))
        }
      })
  }

  useEffect(() => {
    callBackendAfterInternal()
    dispatch(govActions.reducerHaveCalledAfterInternal(true))
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: () => null, // No header
        id: 'checkbox',
        Cell: () => <input type='checkbox' />,
      },
      {
        Header: 'Číslo smlouvy',
        accessor: (row: InternalCompany) => row.cisloSmlouvy,
      },
      {
        Header: 'Název společnosti',
        accessor: 'nazev', // accessor is the "key" in the data
      },
      {
        Header: 'IČ',
        accessor: 'ico',
      },
    ],
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const filterTypes = useMemo(
    () => ({
      text: (rows: any, filter: any) => {
        const regex = new RegExp(`^${filter}`, 'i')
        return rows.filter((r: any) => regex.test(r.name))
      },
    }),
    []
  )

  // 1. Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    globalFilteredRows,
    filteredRows,
    preFilteredRows,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      // @ts-ignore: mv code error
      columns,
      data: internalCompanies,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: useMemo(
          () => [
            {
              id: 'nazev', // default sort column, funny that this works the way we want it to, the id is the name of the field in the data
              desc: false,
            },
          ],
          []
        ),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  const onFilterButtonClick = () => {
    if (isFilteringVisible) {
      setAllFilters([])
    }
    setFilteringVisible(!isFilteringVisible)
  }

  function getFilteredRows() {
    // return globalFilteredRows.map((row) => row.original.ico)
    return globalFilteredRows
  }

  function getPrefilteredRows() {
    // return globalFilteredRows.map((row) => row.original.ico)
    return preGlobalFilteredRows
  }

  function isPristine() {
    return (
      globalFilteredRows === preGlobalFilteredRows &&
      filteredRows === preFilteredRows
    )
  }

  function markAsSelected(original: InternalCompany) {
    if (selectedCompany?.ico === original.ico) {
      setSelectedCompany(undefined)
    } else {
      setSelectedCompany(original)
    }
  }

  const [showErrorModal, setShowErrorModal] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [rowSelected, setRowSelected] = React.useState(0)

  function manageErrors(response: Response) {
    if (!response.ok) {
      setShowErrorModal(true)
      setIsLoading(false)
      throw new Error(response.statusText)
    }
    return response
  }

  // Acquires a JWT from backend and saves it in our Redux store.
  async function loginAsUser(selectedCompany: InternalCompany) {
    setIsLoading(true)
    const contractNumber = selectedCompany.cisloSmlouvy
    console.log(contractNumber)

    await initializeFreshLogin()

    // ORD-2064, this endpoint does not get called
    const response = sendAndLog(
      beInterniSprava,
      contractNumber
        ? {
            contract: selectedCompany.cisloSmlouvy,
          }
        : { ico: selectedCompany.ico }
    )
    response
      .then((data) => {
        console.log('data: ', data)
        setIsLoading(false)
      })
      .then(() => {
        navigate(urlSelfcareTable)
      })
      .catch((error) => {
        console.log('error: ', error)
        setIsLoading(false)
      })
  }

  async function initializeFreshLogin() {
    clearCache()
    clearFlags()
    dispatch(govActions.reducerInternalCompany(selectedCompany))
    dispatch(
      govActions.reducerSpolecnosti([
        {
          ico: selectedCompany!.ico,
          nazev: selectedCompany!.nazev,
          aktivni: true,
        },
      ])
    )
  }

  return (
    <div>
      <div className='internal-web-mv'>
        <div className='u-ml-6 u-px-6 u-pt-6 u-pb-4'>
          {/* tlačítka Filtrovat, Vyhledávat, Máte záznamy, kt. vyžadují vaši pozornost, Data import center ... */}
          <div className='grid-x u-mb-3'>
            <div className='cell medium-auto'>
              <h1 className='u-size-l28 u-mb-3 u-ml-1'>
                Přihlášení za klienta
              </h1>
              <div style={{ marginTop: '-60px' }}>
                <JstePrihlaseniZa />
              </div>
              {/* {isFilteringVisible ? (
                <button
                  onClick={onFilterButtonClick}
                  className='btn u-mr-1 u-mb-1 btn--secondary btn--outlined btn--tiny'
                  style={{ width: '145px' }}
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-delete-thin'}></use>
                  </svg>
                  <span>Zrušit filtr</span>
                </button>
              ) : (
                <button
                  onClick={() => {
                    !isPristine() ? onFilterButtonClick() : null
                  }}
                  className={
                    'btn u-mr-1 u-mb-1 btn--secondary btn--outlined btn--tiny' +
                    (isPristine() ? ' btn--disabled not-allowed' : '')
                  }
                  style={{ width: '145px' }}
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-funnel'}></use>
                  </svg>
                  <span>Filtrovat</span>
                </button>
              )} */}
              <div
                style={{
                  cursor: 'default',
                  paddingLeft: '24px',
                  // width: '400px',
                }}
                className='input-parent btn u-mr-1 u-mb-1 btn--secondary btn--outlined'
              >
                <svg className='icon icon--24'>
                  <MagnifyingGlassIcon width={24} height={24} />
                </svg>
                Vyhledávat v
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isDeaktivovaniActive={false}
                  isDominantElement={true}
                />
              </div>
            </div>
          </div>
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-70px',
            }}
          >
            <button
              onClick={() => loginAsUser(selectedCompany!)}
              className={
                'btn u-mr-1 btn--primary ' +
                (!selectedCompany ? 'btn--disabled' : '')
              }
              style={{ visibility: !selectedCompany ? 'hidden' : 'visible' }}
              disabled={!selectedCompany}
            >
              <svg className='icon icon--16'>
                <PersonIcon />
              </svg>
              Přihlásit se za klienta
            </button>
          </span>
          {/* Tabulka - Header */}
          <div
            {...getTableProps()}
            role='table'
            aria-label='Objednávky'
            className='table table--compact table--no-border'
          >
            {/*  */}
            {/* Table Header - Filters (invisible by default) */}
            {/*  */}
            {!isPristine() &&
              headerGroups.map((headerGroup) => (
                <div
                  role='row'
                  className='table__row table__row--clickable table__row--header u-hide-large-down'
                  {...headerGroup.getHeaderGroupProps()} // also adds key (and ref?)
                >
                  <div role='cell' className='table__cell u-w-4'>
                    <div className='input input--tiny input--small-placeholder u-w-8'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(1, 2).map((column) => (
                          <div key={column.id}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div role='cell' className='table__cell u-w-4'>
                    <div className='input input--tiny input--small-placeholder u-w-8'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(2, 3).map((column) => (
                          <div key={nanoid(5)}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='table__group u-w-auto'>
                    <div className='table__group table__group--medium u-w-4 u-w-medium-12'>
                      <div role='cell' className='table__cell u-w-8'>
                        <div className='input input--tiny input--small-placeholder'>
                          <div className='input__wrapper'>
                            {headerGroup.headers.slice(3, 4).map((column) => (
                              <div key={nanoid(5)}>
                                {isFilteringVisible && column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/*  */}
            {/* Table Header - The Header */}
            {/*  */}
            {!isPristine() &&
              headerGroups.map((headerGroup) => (
                <div
                  role='row'
                  className='table__row table__row--clickable table__row--header'
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {/* Datum obj., Objednal(a) */}
                  <div className='table__group table__group--large u-w-medium-4'>
                    {headerGroup.headers.slice(1, 2).map((column) => (
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          'table__cell table__cell--sortable u-w-6 ' +
                          (column.isSorted ? 'is-active' : '')
                        }
                      >
                        {renderHeaderAndSortIndicators(column)}
                      </div>
                    ))}
                  </div>
                  <div className='table__group table__group--large u-w-medium-4'>
                    {headerGroup.headers.slice(2, 3).map((column) => (
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          'table__cell table__cell--sortable u-w-6 ' +
                          (column.isSorted ? 'is-active' : '')
                        }
                      >
                        {renderHeaderAndSortIndicators(column)}
                      </div>
                    ))}
                  </div>
                  {/* Číslo obj., Částka, Poznámka */}
                  <div className='table__group table__group--large u-w-medium-4'>
                    {headerGroup.headers.slice(3, 4).map((column) => (
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={
                          'table__cell table__cell--sortable u-w-6 ' +
                          (column.isSorted ? 'is-active' : '')
                        }
                      >
                        {renderHeaderAndSortIndicators(column)}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          {/*  */}
          {/*  */}
          {/* Tabulka - data */}
          {/*  */}
          {/*  */}
          {isPristine() ? (
            <div
              className='u-text-center u-mt-5'
              style={{
                backgroundColor: Color.GREYLIGHT,
              }}
            >
              <p className='u-size-l20 u-mb-2 color-sodexo-grey'>
                Zadejte IČ, číslo kontraktu nebo název společnosti do
                vyhledávacího pole
              </p>{' '}
              <img
                src={NoResult}
                srcSet={NoResult2x + ' 2x'}
                width='376'
                height='auto'
                alt='Nebyl nalezen žádný záznam'
              />
            </div>
          ) : getFilteredRows().length === 0 ? (
            <div
              className='u-text-center u-mt-5'
              style={{
                backgroundColor: Color.GREYLIGHT,
              }}
            >
              <p className='u-size-l20 u-mb-2'>Nebyl nalezen žádný záznam</p>{' '}
              <img
                src={NoResult}
                srcSet={NoResult2x + ' 2x'}
                width='376'
                height='auto'
                alt='Nebyl nalezen žádný záznam'
              />
            </div>
          ) : (
            <div className='box box--small-radius box--no-padding u-mb-2'>
              <div className='box__content'>
                <div
                  {...getTableBodyProps()}
                  role='overview-table'
                  aria-label='Přehled objednávek'
                  className='table table--compact'
                >
                  {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                      // Order detail Rollover
                      <div
                        key={nanoid()}
                        role='row'
                        className='table__row table__row--clickable'
                        style={
                          row.original.ico === selectedCompany?.ico
                            ? {
                                backgroundColor: Color.REDLIGHT,
                              }
                            : {}
                        }
                      >
                        {/* Číslo smlouvy */}
                        <div
                          onClick={() => markAsSelected(row.original)}
                          className='table__group table__group--large u-w-medium-4'
                        >
                          {row.cells.slice(1, 2).map((cell, j) => {
                            return (
                              <div
                                className='table__cell table__cell--valign u-text-ellipsis u-w-12'
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                        {/* Název společnosti */}
                        <div
                          onClick={() => markAsSelected(row.original)}
                          className='table__group table__group--large u-w-medium-4'
                        >
                          {row.cells.slice(2, 3).map((cell, j) => {
                            return (
                              <div
                                className='table__cell table__cell--valign u-text-ellipsis u-w-12'
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                        {/* IČ */}
                        <div
                          onClick={() => markAsSelected(row.original)}
                          className='table__group table__group--large u-w-medium-4'
                        >
                          {row.cells.slice(3, 4).map((cell, j) => {
                            return (
                              <div
                                className='table__cell table__cell--valign u-text-ellipsis u-w-12'
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {/* Zkušební loader*/}
          {isLoading && (
            <div
              className='error-modal_div'
              style={{
                marginTop: `-${56 + 60 * rowSelected}px`,
                marginRight: '70px',
              }}
            >
              <span style={{ fontSize: '20px' }} className='loader'></span>
            </div>
          )}

          <div
            className='error-modal_div'
            style={{ marginTop: `-${92 + 60 * rowSelected}px` }} // 92 je výška do tabulky, 60 je výška jednoho řádku
          >
            <ErrorModal
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              text={'Přihlášení za uživatele společnosti se nepovedlo.'}
            />
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}

export default InternalWeb
