import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import mesicniNakladyReducer from '../components/onboarding/features/mesicniNakladySlice'
import firemniUdajeReducer from '../components/onboarding/features/firemniUdajeSlice'
import objednavatelReducer from '../components/onboarding/features/objednavatelSlice'
import stepperReducer from '../components/onboarding/features/stepperSlice'
import tableReducer from '../components/table/tableSlice'
import overviewReducer from '../components/table/overviewSlice'
import governanceReducer from '../components/settings/governanceSlice'
import internalReducer from '../components/table/internalSlice'
import userSettingsReducer from '../components/settings/userSettingsSlice'
import faqReducer from './../components/faq/faqSlice'
import dataImportReducer from './../components/dataimportcentre/dataImportSlice'
import importSettingsReducer from './../components/dataimportcentre/importSettingsSlice'

import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
// import thunk from 'redux-thunk'

const reducers = combineReducers({
  mesicniNaklady: mesicniNakladyReducer,
  firemniUdaje: firemniUdajeReducer,
  objednavatel: objednavatelReducer,
  stepper: stepperReducer,
  table: tableReducer,
  overview: overviewReducer,
  gov: governanceReducer,
  internal: internalReducer,
  userSettings: userSettingsReducer,
  faq: faqReducer,
  dataImport: dataImportReducer,
  importSettings: importSettingsReducer,
})

const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key', // todo: fix security issue, possibly move to env and use library secure-env or implement custom encryption for env vars https://stackoverflow.com/a/74311125
      onError: function (error) {
        console.error('Error while encrypting data', error)
      },
    }),
  ],
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== 'production',
//   // middleware: [thunk],
// })

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'test',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
})
// export const store = createStore(
//   persistedReducer,
//   {},
//   compose(applyMiddleware(thunk))
// )

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
// this one is implemented in counter example and demonstrates the ability to use thunk, mixing sync and async actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
