import React, { PureComponent } from 'react';

import { IconProps } from '../../app/type'

class ChevronIcon extends PureComponent<IconProps> {
    static defaultProps: IconProps = {
        className: 'chevron-down-icon',
        width: 24,
        height: 24,
    };

    render() {
        const { className, width, height } = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 24 24" className={className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.29289 6.79289C2.68342 6.40237 3.31658 6.40237 3.70711 6.79289L12 15.0858L20.2929 6.79289C20.6834 6.40237 21.3166 6.40237 21.7071 6.79289C22.0976 7.18342 22.0976 7.81658 21.7071 8.20711L12.7071 17.2071C12.3166 17.5976 11.6834 17.5976 11.2929 17.2071L2.29289 8.20711C1.90237 7.81658 1.90237 7.18342 2.29289 6.79289Z"
                    fill="#283897"
                ></path>
            </svg>
        );
    }
}

export default ChevronIcon;
