import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'
import {
  getDisplayCardStatusForEmployee,
  MultiPassCard,
  MultiPassCardState,
  newCard,
} from 'util/CardOps'
import { WritableDraft } from 'immer/dist/types/types-external'
import {
  ONE,
  monthsElapsedBeginningInclusive,
} from 'components/selfcare/selfcareHooks'
import { equalsBezRozdiluVCisluOrientacnim } from 'util/ValidFormatOps'
import { isDeactivatable, isDeletable } from 'util/TableOps'
import { newDate } from 'util/DateOps'
import {
  bitMaskOfTheCurrentMonth,
  padWithZerosFromTheLeftTo32Bits,
} from 'util/binaryOps'

export interface TableRow {
  id: string
  name: string
  surname: string
  cardStatus: MultiPassCardState
  leisureCredits: number
  gastroCredits: number
  selected: boolean
  oddeleni: string
  uliceCisloPopisne: string
  cards: MultiPassCard[]
  objednatNahradniKartu: boolean
  checkBoxObjednatNahradniKartu: boolean
  historicalOrders: HistoricalOrder[]
  group: string
  paidMonths: number // bitshift for months
  deactivatedMonths: number // bitshift for months
  deactivatedFrom: Date | null
  flag?: string // transient field, used to mark deactivated months only!
  isLoggedInUser?: boolean
}

// algo for calculating paid months
// everything remains as it was
// but we don't bill the current month
// changes:
// 1. if there is one unpaid month, do not bill it
// 2. do not mark the current month as paid when sending orders

export type HistoricalOrder = {
  id: number
  date: Date
  leisure: number
  gastro: number
}

const initialState: TableRow[] = []

let idCounter: number = 1

function getPersonId(state: WritableDraft<TableRow>[]): string {
  // in case someone deletes a row, we need to find the next available id, starting from previous id
  const previousIdEmployee = state.find(
    (person) => person.id === (idCounter - 1).toString()
  )
  console.log('previousIdEmployee, id = ', idCounter - 1, previousIdEmployee)
  if (!previousIdEmployee) {
    console.log(
      'employee with previous id not found, running iteratively getPersonId'
    )
    if (idCounter > 1) {
      idCounter--
      return getPersonId(state)
    } else {
      idCounter = 1
    }
  }
  // 9999 is our limit of idCounter
  if (idCounter > 9999) idCounter = 1
  // let personId = '' + (idCounter++).toString().padStart(4, '0')
  let personId = '' + (idCounter++).toString()
  if (state.find((person) => person.id === personId)) {
    return getPersonId(state)
  }
  return personId
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    reactivateEmployees(state, action) {
      const { employeeIds, beginningOfTime } = action.payload
      for (const id of employeeIds) {
        const row = state.find((row) => row.id === id) as TableRow

        row.selected = false

        // clear the current month's bit of deactivatedMonths
        row.deactivatedMonths &= ~bitMaskOfTheCurrentMonth(beginningOfTime)
        row.deactivatedFrom = null
      }
    },
    // markPaidMonthsUpUntilNowOriginal(state, action) {
    //   const beginningOfTime = action.payload
    //   const now = new Date()
    //   const monthsElapsed: number = monthsElapsedX(beginningOfTime)

    //   state.forEach((row) => {
    //     for (let i = 0; i <= monthsElapsed; i++) {
    //       const monthBit = ONE >> i
    //       if (!(monthBit & (row.paidMonths | row.deactivatedMonths))) {
    //         row.paidMonths |= monthBit
    //       }
    //       // paying for employee which is deactivated but not since the beginning of this month
    //       if (
    //         (ONE >> monthsElapsed) & row.deactivatedMonths &&
    //         row.deactivatedFrom &&
    //         new Date(row.deactivatedFrom).getMonth() === now.getMonth() &&
    //         ((ONE >> monthsElapsed) & row.paidMonths) === 0
    //       ) {
    //         row.paidMonths |= monthBit
    //       }
    //     }
    //   })
    // },
    markPaidMonthsUpUntilLastMonth(state, action) {
      const monthsElapsed = action.payload

      // beginning : 02/02/22, now: 05/04/22
      // elapsed: 2 months (first one is taken even if partial, the next ones only full)

      state
        // .filter((e) => [4, 5, 6, 7, 8, 9].includes(+e.id))
        .forEach((row) => {
          markPaidMonths(monthsElapsed, row)
          console.log(
            `paidMonths ${padWithZerosFromTheLeftTo32Bits(row.paidMonths)} ${
              row.id
            } : ${row.name} ${row.surname}`
          )
        })
    },
    // This one has to be called from useEffect() of TableContainer to always reflect the latest state
    // as the employees can be activated/deactivated even before an order is sent.
    // An alternative approach would be to call this when reactivating employees and to check in the calculation
    // whether the employee has deactivatedFrom active and if so, skip him. BUT! That would not account for employees
    // which were activated during the last month and had only been deactivated now.
    // So, the current approach is the correct one.
    markDeactivatedMonthsUpUntilNow(state, action) {
      const beginningOfTime = action.payload
      const now = newDate()
      // beginning 02/02/22, now: 05/04/22
      const monthsElapsed: number =
        monthsElapsedBeginningInclusive(beginningOfTime) // = 2

      state
        // .filter((e) => [4, 5, 6, 7, 8, 9].includes(+e.id))
        .forEach((row) => {
          if (row.deactivatedFrom) {
            // deactivatedFrom 03/02/22, now: 05/04/22
            // it doesn't count the current month
            const monthsSinceDeactivation = monthsElapsedBeginningInclusive(
              row.deactivatedFrom,
              now
            ) // = 2
            const monthOfDeactivation = monthsElapsed - monthsSinceDeactivation // = 0

            for (let i = monthOfDeactivation; i < monthsElapsed; i++) {
              const monthBit = ONE >> i
              row.deactivatedMonths |= monthBit
            }
            console.debug(
              `markDeactivatedMonthsUpUntilNow:
            deactivatedFrom: ${row.deactivatedFrom}, now: ${now}, 
            monthsSinceDeactivation: ${monthsSinceDeactivation}, monthOfDeactivation: ${monthOfDeactivation}
            row.deactivatedMonths: ${row.deactivatedMonths.toString(2)}`
            )
          }
        })
    },
    changeNewFlagToDeactivatedMonthsUpUntilLastMonth(state, action) {
      const monthsElapsed: number = action.payload

      state
        .filter((row) => row.flag === 'new')
        .forEach((row) => {
          row.flag = undefined
          if (monthsElapsed === 0) return
          for (let i = 0; i < monthsElapsed; i++) {
            const monthBit = ONE >> i
            row.deactivatedMonths |= monthBit
          }
        })
    },
    removeAllHistoricalOrders(state) {
      state.forEach((row) => {
        row.historicalOrders = []
      })
    },
    clearPaidMonths(state) {
      state.forEach((row) => {
        row.paidMonths = 0
      })
    },
    clearDeactivatedMonths(state) {
      state.forEach((row) => {
        row.deactivatedMonths = 0
      })
    },
    addRowsToTheTable(state, action) {
      const { rows } = action.payload
      console.log(`addRowsToTheTable: ${rows.length} rows, %O`, rows)
      state.push(...rows)
    },
    addRowToTheTable(state, action) {
      const newRow: TableRow = action.payload
      const existingRow = state.find((row) => row.id === newRow.id)

      const evaluatedId = newRow.id
      if (!existingRow) {
        state.push({
          // id: newRow.id || `${Date.newDate()}`,
          id: evaluatedId,
          name: newRow.name || '',
          surname: newRow.surname || '',
          cardStatus: newRow.cardStatus || '',
          leisureCredits: +(newRow.leisureCredits || ''),
          gastroCredits: +(newRow.gastroCredits || ''),
          selected: false,
          oddeleni: newRow.oddeleni || '',
          uliceCisloPopisne: newRow.uliceCisloPopisne || '',
          cards: newRow.cards || [],
          objednatNahradniKartu: newRow.objednatNahradniKartu || false,
          checkBoxObjednatNahradniKartu:
            newRow.checkBoxObjednatNahradniKartu || false,
          historicalOrders: newRow.historicalOrders || [],
          group: newRow.group || '',
          paidMonths: newRow.paidMonths || 0,
          deactivatedMonths: newRow.deactivatedMonths || 0,
          // || getDeactivatedMonthsMaskUpUntilAMonthAgo(state),
          deactivatedFrom: newRow.deactivatedFrom || null,
          flag:
            newRow.flag ||
            (!newRow.deactivatedMonths && !newRow.paidMonths
              ? 'new'
              : undefined),
          isLoggedInUser: newRow.isLoggedInUser || false,
        })
        console.log(`added new row: ${evaluatedId}, group: ${newRow.group}`)
      } else {
        console.log('Tento záznam již existuje', newRow.id, newRow, existingRow)
        // existingRow.cardStatus = newRow.cardStatus
        // existingRow.leisureCredits = newRow.leisureCredits
        // existingRow.gastroCredits = newRow.gastroCredits
      }
    },
    importRowsToTheTable(state, action) {
      const rows = action.payload
      for (const row of rows) {
        const existingRow = state.find(
          (oldRow) => oldRow.id === row.cisloZamestnance
        )
        if (!existingRow) {
          state.push({
            name: row.jmeno,
            surname: row.prijmeni,
            id: row.cisloZamestnance || getPersonId(state),
            uliceCisloPopisne: row.uliceCisloPopisne,
            leisureCredits: +row.volnocasovyKredit,
            gastroCredits: +row.stravenkovyKredit,
            selected: false,
            cardStatus: row.cardStatus || MultiPassCardState.VKošíku,
            oddeleni: row.oddeleni || 'Žádné', // keep Žádné here when it comes from DIC.
            cards: [],
            objednatNahradniKartu: false,
            checkBoxObjednatNahradniKartu: false,
            historicalOrders: [],
            group: 'Nezařazení',
            paidMonths: 0,
            deactivatedMonths: 0, //getDeactivatedMonthsMaskUpUntilAMonthAgo(),
            deactivatedFrom: row.deactivatedFrom || null,
            flag: row.flag || 'new',
          })
        } else {
          existingRow.name = row.jmeno
          existingRow.surname = row.prijmeni
          existingRow.uliceCisloPopisne = row.uliceCisloPopisne
          existingRow.leisureCredits = +row.volnocasovyKredit
          existingRow.gastroCredits = +row.stravenkovyKredit
          existingRow.cardStatus =
            row.cardStatus ||
            existingRow.cardStatus ||
            MultiPassCardState.VKošíku
          // safe check
          existingRow.oddeleni = equalsBezRozdiluVCisluOrientacnim(
            existingRow.uliceCisloPopisne,
            row.uliceCisloPopisne
          )
            ? existingRow.oddeleni
            : row.oddeleni
        }
      }
    },
    removeRowFromTheTable(state, action) {
      const { id } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state.splice(index, 1)
      }
    },
    removeAllRowsFromTheTable(state) {
      state.splice(0, state.length)
    },
    setName(state, action) {
      const { id, name } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].name = name
      }
    },
    setSurname(state, action) {
      const { id, surname } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].surname = surname
      }
    },
    setLeisureCredit(state, action) {
      const { id, amount } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].leisureCredits = amount
      }
    },
    setGastroCredit(state, action) {
      const { id, amount } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].gastroCredits = amount
      }
    },
    setSelected(state, action) {
      const { id, value } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].selected = value
      }
    },
    setSelectedSet(state, action) {
      const { set, value } = action.payload
      for (const id of set) {
        const index = state.findIndex((row) => row.id === id)
        if (index > -1) {
          const row = state[index]
          row.selected = value
        }
      }
    },
    setSelectedSetInGroup(state, action) {
      const { set, group, value } = action.payload
      for (const id of set) {
        const index = state.findIndex((row) => row.id === id)
        if (index > -1) {
          const row = state[index]
          if (row.group === group) {
            row.selected = value
          }
        }
      }
    },
    deselectAll(state) {
      for (const row of state) {
        row.selected = false
      }
    },
    deactivateEligibleSelected(state) {
      for (const row of state) {
        if (row.selected && isDeactivatable(row)) {
          row.selected = false
          row.deactivatedFrom = newDate()
        }
      }
    },
    deleteEligibleSelected(state) {
      // delete all selected and deletable rows
      for (let i = state.length - 1; i >= 0; i--) {
        const row = state[i]
        if (row.selected && isDeletable(row)) {
          state.splice(i, 1)
        }
      }
    },
    setVolnocasovyToAllSelected(state, action) {
      const { amount } = action.payload
      for (const row of state) {
        if (row.selected) {
          row.leisureCredits = amount
        }
      }
    },
    setStravenkovyToAllSelected(state, action) {
      const { amount } = action.payload
      for (const row of state) {
        if (row.selected) {
          row.gastroCredits = amount
        }
      }
    },
    changeDailyCreditDenominatorToAllAndUpdateGastro(state, action) {
      // { id: string; kredit: number }[]
      const { denniKredityZamestnancu, newDenominator } = action.payload
      // console.log(
      //   'changeDailyCreditDenominatorToAll',
      //   denniKredityZamestnancu,
      //   newDenominator
      // )
      for (const row of state) {
        const oldDenominator = denniKredityZamestnancu.find(
          (denniKredit) => denniKredit.id === row.id
        )?.kredit
        // console.log(
        //   'Row.id, oldDenominator, gastroCredits',
        //   row.id,
        //   oldDenominator,
        //   row.gastroCredits
        // )
        row.gastroCredits =
          (row.gastroCredits * newDenominator) / oldDenominator
        // console.log(
        //   'Row.id, oldDenominator, gastroCredits',
        //   row.id,
        //   oldDenominator,
        //   row.gastroCredits
        // )
      }
    },
    setVolnocasovyToAll(state, action) {
      const { amount } = action.payload
      for (const row of state) {
        row.leisureCredits = amount
      }
    },
    setStravenkovyToAll(state, action) {
      const { amount } = action.payload
      for (const row of state) {
        row.gastroCredits = amount
      }
    },
    eraseAllOrderValues(state) {
      for (const row of state) {
        row.leisureCredits = 0
        row.gastroCredits = 0
        row.objednatNahradniKartu = false
      }
    },
    orderSentTablePostProcessing(state, action) {
      const { orderId } = action.payload
      console.log('orderSentTablePostProcessing', orderId)
      for (const row of state) {
        row.leisureCredits = 0
        row.gastroCredits = 0
        if (shouldOrderEmployeeACard(row)) {
          if (!row.cards) {
            row.cards = []
          }
          row.cards.push(newCard(orderId))
          row.cardStatus = MultiPassCardState.VeVýrobě
          row.objednatNahradniKartu = false
          console.log(
            `${row.name} ${row.surname} has been ordered a ${
              row.objednatNahradniKartu ? 'replacement' : 'new'
            } card and now has the following cards: ${row.cards.flatMap(
              (card) => card.id
            )}`
          )
          // safety check - the program should not branch here under normal circumstances (but e.g. data import functionality could lead us here)
          for (const card of row.cards) {
            if (card.status === MultiPassCardState.VKošíku) {
              card.status = MultiPassCardState.VeVýrobě
            }
          }
        }
      }
    },
    setUliceCisloPopisne(state, action) {
      const { id, uliceCisloPopisne, oddeleni } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].uliceCisloPopisne = uliceCisloPopisne
        if (oddeleni) {
          console.log(
            'Redux - setUliceCisloPopisne, setting oddeleni: ',
            oddeleni
          )
          state[index].oddeleni = oddeleni
        }
      }
    },
    setUliceCisloPopisneOznacenym(state, action) {
      const { uliceCisloPopisne, oddeleni } = action.payload
      for (const row of state) {
        if (row.selected) {
          row.uliceCisloPopisne = uliceCisloPopisne
          row.oddeleni = oddeleni
        }
      }
    },
    setOddeleni(state, action) {
      const { id, oddeleni } = action.payload

      const index = state.findIndex((row) => row.id === id)
      // console.log(
      //   'OddeleniRadioSelector: (before) setOddeleni, action.payload, index, state[index].oddeleni: ',
      //   action.payload,
      //   index,
      //   state[index].oddeleni
      // )
      if (index > -1) {
        state[index].oddeleni = oddeleni
      }
      // console.log(
      //   'OddeleniRadioSelector: (after) setOddeleni, action.payload, state[index].oddeleni: ',
      //   action.payload,
      //   state[index].oddeleni
      // )
    },
    deactivateById(state, action) {
      const { id } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].deactivatedFrom = newDate()
        state[index].selected = false
      }
    },
    blokovatKartu(state, action) {
      const { id, cardIndex } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].cardStatus = MultiPassCardState.Blokovaná
        state[index].cards[cardIndex].status = MultiPassCardState.Blokovaná
      }
    },
    zrusitNahradniKartu(state, action) {
      console.log('zrusitNahradniKartu, id: ', action.payload)
      const id = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].checkBoxObjednatNahradniKartu = false
        state[index].objednatNahradniKartu = false
        const actualCardStatus = getDisplayCardStatusForEmployee(
          state[index].cards
        )
        state[index].cardStatus = actualCardStatus
      }
    },
    objednatNahradniKartu(state, action) {
      const { id } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].objednatNahradniKartu = true
      }
    },
    checkBoxObjednatNahradniKartu(state, action) {
      const { id, checkBoxObjednatNahradniKartu } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].checkBoxObjednatNahradniKartu =
          checkBoxObjednatNahradniKartu
      }
    },
    setCardStatus(state, action) {
      const { id, cardStatus } = action.payload
      const index = state.findIndex((row) => row.id === id)
      console.log('setCardStatus', id, cardStatus, index)
      if (index > -1) {
        state[index].cardStatus = cardStatus
      }
    },
    addHistoricOrder(state, action) {
      console.log('addHistoricOrder', action.payload)
      const { employeeId, orderId, leisure, gastro } = action.payload
      const order: HistoricalOrder = {
        id: orderId,
        date: new Date(),
        leisure,
        gastro,
      }
      const index = state.findIndex((row) => row.id === employeeId)
      if (index > -1) {
        // (better safe than sorry) can be deleted later when all the data is migrated to the new format
        if (!state[index].historicalOrders) {
          state[index].historicalOrders = []
        }
        state[index].historicalOrders = [
          order,
          ...state[index].historicalOrders, // keep the full history of historical orders here, slice it when displaying
        ]
      }
    },
    setGroupToSelectedEmployees(state, action) {
      const { group } = action.payload
      for (const row of state) {
        if (row.selected) {
          row.group = group
        }
      }
    },
    setGroup(state, action) {
      const { id, group } = action.payload
      const index = state.findIndex((row) => row.id === id)
      if (index > -1) {
        state[index].group = group
      }
    },
  },
})

export const tableActions = tableSlice.actions

export const selectHistoricalOrders = (id: string) => (state: RootState) => {
  const index = state.table.findIndex((row) => row.id === id)
  if (index > -1) {
    return state.table[index].historicalOrders
  }
  return []
}

export const selectTable = (state: RootState) => state.table

export const selectEmployeesWithAnOrderItem = (state: RootState) =>
  state.table.filter(
    (row) =>
      (!row.deactivatedFrom || row.deactivatedFrom == null) &&
      (row.leisureCredits > 0 ||
        row.gastroCredits > 0 ||
        shouldOrderEmployeeACard(row))
  )

export const selectEmployeesWithoutAnOrderItem = (state: RootState) =>
  state.table.filter(
    (row) =>
      !(
        (!row.deactivatedFrom || row.deactivatedFrom == null) &&
        (row.leisureCredits > 0 ||
          row.gastroCredits > 0 ||
          shouldOrderEmployeeACard(row))
      )
  )

export const selectIsTableEmpty = (state: RootState) => state.table.length === 0
export const selectTotalSelectedActive = (state: RootState) =>
  state.table.filter((row) => row.selected && !row.deactivatedFrom).length
export const selectTotalSelectedDeactivated = (state: RootState) =>
  state.table.filter((row) => row.selected && row.deactivatedFrom).length
export const selectTableTotalPrice = (state: RootState) => {
  let totalPrice: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.leisureCredits > 0) {
        totalPrice += +row.leisureCredits
      }
      if (row.gastroCredits > 0) {
        totalPrice += +row.gastroCredits
      }
    }
  }
  return totalPrice
}
export const selectTableTotalVolnocasovy = (state: RootState) => {
  let totalPrice: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.leisureCredits > 0) {
        totalPrice += +row.leisureCredits
      }
    }
  }
  return totalPrice
}
export const selectTableTotalStravenkovy = (state: RootState) => {
  let totalPrice: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.gastroCredits > 0) {
        totalPrice += +row.gastroCredits
      }
    }
  }
  return totalPrice
}
export const selectSelectedRowIds = (state: RootState) => {
  return state.table.filter((row) => row.selected).map((row) => row.id)
}

export const selectVolnocasovyKreditById =
  (_id: string) => (state: RootState) => {
    const row = state.table.filter(({ id }) => id === _id)[0]
    return row ? row.leisureCredits : 0
  }
export const selectStravenkovyKreditById =
  (_id: string) => (state: RootState) => {
    const row = state.table.filter(({ id }) => id === _id)[0]
    return row ? row.gastroCredits : 0
  }

export const selectUliceCisloPopisneProDoruceni =
  (_id: string) => (state: RootState) => {
    const row = state.table.filter(({ id }) => id === _id)[0]
    return row ? row.uliceCisloPopisne : ''
  }
export const selectZamestnancovoOddeleni =
  (_id: string) => (state: RootState) => {
    const row = state.table.filter(({ id }) => id === _id)[0]
    return row ? row.oddeleni : ''
  }

export const selectTotalVolnocasove = (state: RootState) => {
  let totalPrice: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.leisureCredits) totalPrice += +row.leisureCredits
    }
  }
  return totalPrice ? totalPrice : 0
}

export const selectTotalStravenkovy = (state: RootState) => {
  let totalPrice: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.gastroCredits) totalPrice += +row.gastroCredits
    }
  }
  return totalPrice ? totalPrice : 0
}

// export const selectPocetZamestnancuBezKarty = (state: RootState) => {
//   let totalObjednatKaret: number = 0
//   for (const row of state.table) {
//     if (!row.deactivated) {
//       // Do not uncomment! We order the cards for everyone in the table, regardless of whether they have credits set.
//       // if (row.leisureCredits === 0 && row.gastroCredits === 0) {
//       //   continue
//       // }
//       if (isEmployeeWithoutACard(row)) {
//         totalObjednatKaret++
//       }
//     }
//   }
//   return totalObjednatKaret
// }

// export const selectPocetNahradnichKaret = (state: RootState) => {
//   let totalNahradnichKaret: number = 0
//   for (const row of state.table) {
//     if (!row.deactivated) {
//       if (row.objednatNahradniKartu) {
//         totalNahradnichKaret++
//       }
//     }
//   }
//   return totalNahradnichKaret
// }

export const selectPocetNovychKaretKObjednani = (state: RootState) => {
  let totalObjednatKaret: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (isEmployeeWithoutACard(row)) {
        totalObjednatKaret++
      }
    }
  }
  return totalObjednatKaret
}
export const selectPocetNahradnichKaretKObjednani = (state: RootState) => {
  let totalObjednatKaret: number = 0
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.objednatNahradniKartu) {
        totalObjednatKaret++
      }
    }
  }
  return totalObjednatKaret
}

export const selectZamestnanciSDorucovanimNoveKarty = (state: RootState) => {
  let zamestnanci: TableRow[] = []
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (isEmployeeWithoutACard(row)) {
        zamestnanci.push(row)
      }
    }
  }
  return zamestnanci
}
export const selectZamestnanciSDorucovanimNahradniKarty = (
  state: RootState
) => {
  let zamestnanci: TableRow[] = []
  for (const row of state.table) {
    if (!row.deactivatedFrom) {
      if (row.objednatNahradniKartu) {
        zamestnanci.push(row)
      }
    }
  }
  return zamestnanci
}

export const selectCardStatusById = (_id: string) => (state: RootState) => {
  const row = state.table.filter(({ id }) => id === _id)[0]
  return row ? row.cardStatus : ''
}

export const selectCardsById = (_id: string) => (state: RootState) => {
  const row = state.table.filter(({ id }) => id === _id)[0]
  return row ? row.cards : []
}

export const selectAllActiveEmployees = (state: RootState) => {
  return state.table.filter((row) => !row.deactivatedFrom)
}

export default tableSlice.reducer

export function markPaidMonths(
  monthsElapsed: number,
  row: WritableDraft<TableRow>
) {
  for (let i = 0; i < monthsElapsed; i++) {
    const monthBit = ONE >> i
    if (!(monthBit & row.deactivatedMonths)) {
      row.paidMonths |= monthBit
    }
    // how about employees which were deactivated only for a part of the last month?
    // let's try not to increase data model
    // // paying for employee which is deactivated but not since the beginning of this month
    // if (
    //   (ONE >> monthsElapsed) & row.deactivatedMonths &&
    //   row.deactivatedFrom &&
    //   new Date(row.deactivatedFrom).getMonth() === now.getMonth() &&
    //   ((ONE >> monthsElapsed) & row.paidMonths) === 0
    // ) {
    //   row.paidMonths |= monthBit
    // }
  }
}

function isEmployeeWithoutACard(row: TableRow): boolean {
  return (
    !row.cards ||
    row.cards.length === 0 ||
    row.cards[0].status === MultiPassCardState.VKošíku
  )
}

export function shouldOrderEmployeeACard(row: TableRow): boolean {
  return isEmployeeWithoutACard(row) || row.objednatNahradniKartu
}

export const selectRowById = (id: string) => (state: RootState) =>
  state.table.find((row) => row.id === id)
export const selectEmployeeById = (id: string) => (state: RootState) =>
  state.table.find((row) => row.id === id)

export const selectAllEmployeeIds = (state: RootState) =>
  state.table.map((row) => row.id)

export const selectIsNahradniKartaVKosiku =
  (id: string) => (state: RootState) => {
    const index = state.table.findIndex((row) => row.id === id)
    if (index > -1) {
      return state.table[index].objednatNahradniKartu
    }
    return false
  }
export const selectCheckBoxObjednatNahradniKartu =
  (id: string) => (state: RootState) => {
    const index = state.table.findIndex((row) => row.id === id)
    if (index > -1) {
      return state.table[index].checkBoxObjednatNahradniKartu
    }
    return false
  }
