import React, { useEffect, useState } from 'react'
import { NEPLATNA_ULICE_CISLO_POPISNE, NEPLATNE_PSC } from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { pscRegex, validateNumericKeyWithWhites } from 'app/util'
import ConfirmSection from 'components/onboarding/ConfirmSection'
import {
  selectIcoFirmy,
  selectNazevSpolecnosti,
  selectUliceCisloPopisne,
  selectMesto,
  selectOpravneneOsoby,
  selectPsc,
  reducerIcFirmy,
  reducerNazevSpolecnosti,
  reducerUliceCisloPopisne,
  reducerMesto,
  reducerPsc,
  reducerRemoveEmptyOpravneneOsoby,
  reducerRestorePreviousFiremniUdaje,
  reducerCopyActualStateToThePreviousState,
} from '../onboarding/features/firemniUdajeSlice'
import OpravneneOsoby from 'components/modules/OpravneneOsoby'
import {
  isValidUliceCisloPopisne,
  splitUliceCisloPopisne,
} from 'util/ValidFormatOps'
import { FakturacniUdajeBackend } from 'app/type'
import { sendAndLog } from 'app/req'
import { beMakeFakturacniUdaje, urlMultiPassCard, urlRecontracting } from 'App'
import { SETTINGS_NOTICE_DISAPPEAR_TIMEOUT } from 'app/tuning'
import { lg } from 'app/facade'
import {
  CITY,
  FIRM_ID,
  FIRM_NAME,
  FOR_EXAMPLE,
  REQUIRED_FIELD,
  STREET_HOUSE_NUMBER,
  ZIP,
} from 'app/constDictionary'
import { selectLanguage } from './userSettingsSlice'
import { useLocation } from 'react-router-dom'

interface FakturacniUdajeProps {
  submitIcon: JSX.Element
  submitText: string
  tipComponent?: JSX.Element
}

// located only in Settings
const FakturacniUdajeSettings: React.FC<FakturacniUdajeProps> = ({
  submitIcon,
  submitText,
  tipComponent,
}) => {
  const dispatch = useAppDispatch()

  const location = useLocation()

  const isReconOrOnboarding =
    location.pathname.includes(urlRecontracting) ||
    location.pathname.includes(urlMultiPassCard)

  useEffect(() => {
    window.addEventListener('beforeunload', restorePreviousState)
    // window.addEventListener('unload', restorePreviousState)
    window.addEventListener('load', handlePageEnter)
    return () => {
      window.removeEventListener('beforeunload', restorePreviousState)
      // window.removeEventListener('unload', restorePreviousState)
      window.removeEventListener('load', handlePageEnter)
    }
  })

  const handlePageEnter = () => {
    console.log('handlePageEnter')
    // BugFix: put less stress on the server and do not execute the other event listeners too, if not in recon or onboarding
    if (isReconOrOnboarding) {
      dispatch(reducerCopyActualStateToThePreviousState())
    }
  }

  function restorePreviousState() {
    console.log('restorePreviousState')
    // BugFix: "In the browser, the event listeners sometimes do not get cleared and then on events 'beforeunload' a 'load' the restoration of the previous state of Firemni Udaje secretly happens."
    if (isReconOrOnboarding) {
      dispatch(reducerRestorePreviousFiremniUdaje())
    }
  }

  function copyActualStateToThePreviousState() {
    console.log('copyActualStateToThePreviousState')
    dispatch(reducerCopyActualStateToThePreviousState())
  }

  const [hasValidated, setHasValidated] = useState(false)

  const icFirmy = useAppSelector(selectIcoFirmy)
  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne)
  const mesto = useAppSelector(selectMesto) as string
  const psc = useAppSelector(selectPsc) as string
  const opravneneOsoby = useAppSelector(selectOpravneneOsoby)

  const { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne!)

  async function callBackendFakturacniUdaje() {
    console.log('submit - fakturacni udaje - callbackend fakturacni udaje')
    const noveFakturacniUdaje: FakturacniUdajeBackend = {
      nazevSpolecnosti: nazevSpolecnosti!,
      ulice: ulice,
      cisloPopisne: cisloPopisne,
      mesto: mesto,
      psc: psc.replaceAll(' ', ''),
      opravneneOsoby: opravneneOsoby,
    }
    await sendAndLog(beMakeFakturacniUdaje, noveFakturacniUdaje, 'PUT')
  }

  const [opravneneOsobyChanged, setOpravneneOsobyChanged] = useState([false])
  const [opravneneOsobyChangedAndSaved, setOpravneneOsobyChangedAndSaved] =
    useState([false])

  const [initialOpravneneOsoby, setInitialOpravneneOsoby] =
    useState(opravneneOsoby)

  useEffect(() => {
    const osobyChanged: boolean[] = []
    for (let i = 0; i < opravneneOsoby.length; i++) {
      osobyChanged.push(opravneneOsoby[i] !== initialOpravneneOsoby[i])
    }
    setOpravneneOsobyChanged(osobyChanged)
  }, [opravneneOsoby, initialOpravneneOsoby])

  // first one is state that changes to 'true' at the moment when user changes something at the input field
  // second one is state that changes to 'true' at the moment when user clicks on submit button
  const [nazevSpolecnostiChanged, setNazevSpolecnostiChanged] = useState(false)
  const [nazevSpolecnostiChangedAndSaved, setNazevSpolecnostiChangedAndSaved] =
    useState(false)

  const [uliceCisloPopisneChanged, setUliceCisloPopisneChanged] =
    useState(false)
  const [
    uliceCisloPopisneChangedAndSaved,
    setUliceCisloPopisneChangedAndSaved,
  ] = useState(false)

  const [mestoChanged, setMestoChanged] = useState(false)
  const [mestoChangedAndSaved, setMestoChangedAndSaved] = useState(false)

  const [pscChanged, setPscChanged] = useState(false)
  const [pscChangedAndSaved, setPscChangedAndSaved] = useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'
  const handleInput = (e: any) => {
    let value = e.target.value
    let id = e.target.id
    value = value === undefined ? (typeof value === 'number' ? 0 : '') : value
    switch (id) {
      case 'icFirmy':
        dispatch(reducerIcFirmy(value))
        break
      case 'nazevSpolecnosti':
        dispatch(reducerNazevSpolecnosti(value))
        setNazevSpolecnostiChanged(true)
        setNazevSpolecnostiChangedAndSaved(false)
        break
      case 'uliceCisloPopisne':
        dispatch(reducerUliceCisloPopisne(value))
        setUliceCisloPopisneChanged(true)
        setUliceCisloPopisneChangedAndSaved(false)
        break
      case 'mesto':
        dispatch(reducerMesto(value))
        setMestoChanged(true)
        setMestoChangedAndSaved(false)
        break
      case 'psc':
        dispatch(reducerPsc(value))
        setPscChanged(true)
        setPscChangedAndSaved(false)
        break
      default:
        console.log(
          'WARNING: handling input from a field with unrecognized id',
          id,
          value
        )
    }
  }

  function isValid() {
    return (
      nazevSpolecnosti &&
      uliceCisloPopisne &&
      mesto &&
      psc &&
      pscRegex.test(psc) &&
      isValidUliceCisloPopisne(uliceCisloPopisne) &&
      opravneneOsoby[0]
    )
  }

  return (
    <div>
      <div className='input u-mb-4'>
        <label>{lg(lang, FIRM_ID)}</label>
        <input
          id='icFirmy'
          type='text'
          placeholder='${lg(lang, FOR_EXAMPLE)} 1231231'
          value={icFirmy}
          onChange={handleInput}
          readOnly
          style={{
            backgroundColor: 'rgba(0,0,0,0.03)',
            opacity: 0.8,
          }}
        />
      </div>
      <div className='input u-mb-4 input--required'>
        <label>{lg(lang, FIRM_NAME)}</label>
        <input
          id='nazevSpolecnosti'
          type='text'
          placeholder={`${lg(lang, FOR_EXAMPLE)} Pluxee s.r.o`}
          value={nazevSpolecnosti}
          onChange={handleInput}
          className={
            nazevSpolecnostiChangedAndSaved ? 'changed-input bg-97' : ''
          } // if changes were made and saved, then the approval mark is shown
        />
        {hasValidated && !nazevSpolecnosti && (
          <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
        )}
      </div>
      <div className='input u-mb-4 input--required'>
        <label>{lg(lang, STREET_HOUSE_NUMBER)}</label>
        <input
          id='uliceCisloPopisne'
          type='text'
          placeholder={`${lg(lang, FOR_EXAMPLE)} Moskevská 33`}
          value={uliceCisloPopisne}
          onChange={handleInput}
          className={
            uliceCisloPopisneChangedAndSaved ? 'changed-input bg-97' : ''
          }
        />
        {hasValidated &&
          (uliceCisloPopisne ? (
            !isValidUliceCisloPopisne(uliceCisloPopisne) && (
              <span className='input__error'>
                {NEPLATNA_ULICE_CISLO_POPISNE}
              </span>
            )
          ) : (
            <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
          ))}
      </div>
      <div className='grid-x u-mb-4'>
        <div className='cell small-7 u-pr-small-2'>
          <div className='input input--required'>
            <label>{lg(lang, CITY)}</label>
            <input
              id='mesto'
              type='text'
              placeholder={`${lg(lang, FOR_EXAMPLE)} Praha 10`}
              value={mesto}
              onChange={handleInput}
              className={mestoChangedAndSaved ? 'changed-input bg-95' : ''}
            />
            {hasValidated && !mesto && (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            )}
          </div>
        </div>
        <div className='cell small-5'>
          <div className='input input--required'>
            <label>{lg(lang, ZIP)}</label>
            <input
              id='psc'
              type='tel'
              placeholder={`${lg(lang, FOR_EXAMPLE)} 101 00`}
              value={psc}
              onChange={handleInput}
              className={pscChangedAndSaved ? 'changed-input bg-92' : ''}
              onKeyPress={validateNumericKeyWithWhites}
            />
            {hasValidated &&
              (psc ? (
                !pscRegex.test(psc) && (
                  <span className='input__error'>{NEPLATNE_PSC}</span>
                )
              ) : (
                <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
              ))}
          </div>
        </div>
      </div>
      <OpravneneOsoby
        opravneneOsoby={opravneneOsoby}
        hasValidated={hasValidated}
        // wasChanged={opravnenaOsobaChangedAndSaved}
        wereChanged={opravneneOsobyChangedAndSaved}
      />
      {tipComponent ? tipComponent : null}
      <div className='u-my-1'>
        {
          <ConfirmSection
            alignClassName='u-text-left'
            submitIcon={submitIcon}
            submitText={submitText}
            nextRoute={''}
            onSubmit={
              isValid()
                ? () => {
                    console.log('submit - fakturacni udaje')

                    if (nazevSpolecnostiChanged) {
                      setNazevSpolecnostiChangedAndSaved(true)
                      setNazevSpolecnostiChanged(false)
                    }

                    if (uliceCisloPopisneChanged) {
                      setUliceCisloPopisneChangedAndSaved(true)
                      setUliceCisloPopisneChanged(false)
                    }

                    if (mestoChanged) {
                      setMestoChangedAndSaved(true)
                      setMestoChanged(false)
                    }

                    if (pscChanged) {
                      setPscChangedAndSaved(true)
                      setPscChanged(false)
                    }

                    setOpravneneOsobyChangedAndSaved([...opravneneOsobyChanged])
                    setOpravneneOsobyChanged([])
                    setInitialOpravneneOsoby([...opravneneOsoby])

                    setTimeout(() => {
                      setNazevSpolecnostiChangedAndSaved(false)
                      setUliceCisloPopisneChangedAndSaved(false)
                      setMestoChangedAndSaved(false)
                      setPscChangedAndSaved(false)
                      setOpravneneOsobyChangedAndSaved(
                        opravneneOsobyChangedAndSaved.fill(false)
                      )
                    }, SETTINGS_NOTICE_DISAPPEAR_TIMEOUT)

                    dispatch(reducerRemoveEmptyOpravneneOsoby())
                    copyActualStateToThePreviousState()
                    callBackendFakturacniUdaje()
                  }
                : () => setHasValidated(true)
            }
          />
        }
      </div>
    </div>
  )
}

export default FakturacniUdajeSettings
