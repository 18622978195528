import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class PlusIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'plus-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 28.962a1 1 0 102 0V17h11.962a1 1 0 100-2H17V3.039a1 1 0 00-2 0v11.96H3.037a1 1 0 100 2h11.964v11.963z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default PlusIcon
