import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class mailIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'mail-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2 2.75C1.0335 2.75 0.25 3.5335 0.25 4.5V11C0.25 11.9665 1.0335 12.75 2 12.75H14C14.9665 12.75 15.75 11.9665 15.75 11V4.5C15.75 3.5335 14.9665 2.75 14 2.75H2ZM1.75 4.76781V11C1.75 11.1381 1.86193 11.25 2 11.25H14C14.1381 11.25 14.25 11.1381 14.25 11V4.76826L9.76065 8.50924C8.74082 9.35906 7.25949 9.35905 6.23968 8.50921L1.75 4.76781ZM12.5288 4.25H3.4717L7.19995 7.35688C7.6635 7.74317 8.33684 7.74317 8.80039 7.35689L12.5288 4.25Z'
          fill='#2E3897'
        ></path>
      </svg>
    )
  }
}

export default mailIcon
