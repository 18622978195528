import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class clockIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'mail-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM7.5 5.25C7.91421 5.25 8.25 5.58579 8.25 6V7.68934L10.0303 9.46967C10.3232 9.76256 10.3232 10.2374 10.0303 10.5303C9.73744 10.8232 9.26256 10.8232 8.96967 10.5303L6.96967 8.53033C6.89776 8.45842 6.84351 8.37555 6.80691 8.28709C6.77024 8.19866 6.75 8.10169 6.75 8V6C6.75 5.58579 7.08579 5.25 7.5 5.25Z'
          fill='#2E3897'
        ></path>
      </svg>
    )
  }
}

export default clockIcon
