export function callInstinkt(nextStep: number) {
  console.log(
    'callInstinctAgency, clicking on button Pokračovat leading to onboarding step ' +
      nextStep
  )
  // @ts-ignore
  dataLayer.push({
    event: 'formSubmission',
    step: nextStep,
  })
}
