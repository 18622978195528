import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class SdxCardIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'sdx-card-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24 9H8a3 3 0 00-3 3h16a3 3 0 01-3 3H5v5a3 3 0 003 3h16a3 3 0 003-3v-8a3 3 0 00-3-3zM8 7a5 5 0 00-5 5v8a5 5 0 005 5h16a5 5 0 005-5v-8a5 5 0 00-5-5H8zm15 14a2 2 0 002-2h-8l-1 2h7z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default SdxCardIcon
