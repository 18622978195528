import React, { useState, useMemo, useEffect } from 'react'
import NoResult from '@styles/image/no-result.png'
import NoResult2x from '@styles/image/no-result2x.png'
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table'
import { overviewActions, selectOverview } from './overviewSlice'
import {
  useAppDispatch,
  useAppSelector,
  useBodyClass,
  useOnEscapeClick,
} from 'app/hooks'
import { formatDate } from 'util/DateOps'
import SideMenu from 'components/modules/menu/SideMenu'
import icons from '@styles/dist/system/icons.svg'
import {
  DefaultColumnFilter,
  renderHeaderAndSortIndicators,
  SelectColumnFilter,
} from './Table'
import Color from '../../app/colors'
import OrderDetail from 'components/selfcare/OrderDetail'
import { colorize } from 'util/colorOps'
import { generateInfoMessage, numberWithSpacesMax2 } from 'app/util'
import { beObjednavky, urlDataImportOne, urlOrdersOverview } from 'App'
import DeleteIcon from '@styles/icon/deleteIcon'
import OrderEmailRecap from 'components/selfcare/OrderEmailRecap'
import { INTERNAL_IDX } from 'app/constants'
import { Order, OrderState } from 'app/type'
import { getPrecious } from 'app/back'
import { ErrorModal } from 'components/modules/errorhandler/ErrorModal'
import { orderIdToCisloObjednavkyBackend } from 'util/transformer'
import { sinking } from 'app/julesverne'
import JstePrihlaseniZa from 'components/internal/JstePrihlaseniZa'
import { isInternal } from 'util/authOps'
import { lg } from 'app/facade'
import {
  AMOUNT_NO_VAT,
  CANCEL,
  CARD_SHIPMENT,
  CLOSE,
  FILTER,
  FOR_DOWNLOAD,
  INVOICE,
  NOTE,
  NO_RECORD_FOUND,
  ORDERED_BY,
  ORDERS,
  ORDER_DATE,
  ORDER_NUMBER,
  ORDER_STATUS,
  PROFORMA,
  TO_FILTER,
  YOU_HAVE,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { nanoid } from '@reduxjs/toolkit'
import { useLocation } from 'react-router-dom'

const OrdersOverview: React.FC = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()

  const reduxOverview: Order[] = useAppSelector(selectOverview)
  sinking() &&
    console.table(reduxOverview, ['orderId', 'owner', 'date', 'price'])

  const [isFilteringVisible, setFilteringVisible] = useState(false)
  const [currentOrderDetailId, setCurrentOrderDetailId] = useState<number>(0)

  const [isOrderDetailRollover, setOrderDetailRollover] = useState(false)
  useOnEscapeClick(isOrderDetailRollover, setOrderDetailRollover)

  const [selectedOrderState, setSelectedOrderState] = useState('')

  const [isRecapRollover, setRecapRollover] = useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const location = useLocation()
  // const navigate = useNavigate()

  useEffect(() => {
    console.log('URL changed:', location.pathname)
    if (window.location.href.indexOf('~') > -1) {
      const afterHyphen = window.location.href.split('~')[1]
      if (afterHyphen.length === 0) {
        console.log(
          'Přehled objednávek, waiting for response from backend, Hotjar tracking.'
        )
        setRecapRollover(false)
      } else if (afterHyphen.length > 0 && afterHyphen.includes('-!')) {
        console.error(
          'Přehled objednávek, timed out on response from backend, compensating previous transactions and alerting the user.'
        )
        window.location.href = urlOrdersOverview
        dispatch(
          overviewActions.removeRowFromTheTable({
            id: afterHyphen.slice(0, -2),
          })
        )
        setRecapRollover(false)
        alert(
          'Omlouváme, něco se nepovedlo, odeslání objednávky jsme proto raději zrušili. Kontaktujte prosím podporu s žádostí o pomoc a uveďte id požadavku ' +
            afterHyphen.slice(0, -2)
        )
      } else {
        console.log(
          'Přehled objednávek, received response from backend with orderId, Hotjar tracking.'
        )
        setTimeout(() => setRecapRollover(true), 200)
      }
    }
    // we don't really use this one
    // if (window.location.href.indexOf('#') > -1) {
    //   setOrderDetailRollover(true)
    //   setRecapRollover(false)
    //   setCurrentOrderDetailId(+window.location.href.split('#')[1])
    // }
  }, [location])

  // console.log('Přehled objednávek, performing side effects.', reduxOverview)

  const columns = useMemo(
    () => [
      {
        Header: () => null, // No header
        id: 'checkbox',
        Cell: () => <input type='checkbox' />,
      },
      {
        Header: lg(lang, ORDER_DATE),
        accessor: 'date',
        // @ts-ignore
        Cell: ({ value }) => {
          return formatDate(value)
        },
      },
      {
        Header: lg(lang, ORDERED_BY),
        accessor: 'owner', // accessor is the "key" in the data
      },
      {
        Header: lg(lang, ORDER_NUMBER),
        accessor: (row: Order) =>
          +row.orderId >= INTERNAL_IDX && +row.orderId < INTERNAL_IDX * 2
            ? 'načítám...'
            : row.orderId,
        sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
          const a = parseInt(rowA.original.orderId)
          const b = parseInt(rowB.original.orderId)
          return a > b ? 1 : a < b ? -1 : 0
        },
      },
      {
        Header: lg(lang, AMOUNT_NO_VAT),
        accessor: 'price',
        // @ts-ignore
        Cell: ({ value }) => {
          return numberWithSpacesMax2(value) + ' Kč'
        },
      },
      {
        Header: lg(lang, NOTE),
        accessor: 'note',
      },
      {
        Header: lg(lang, ORDER_STATUS),
        accessor: 'orderState', // must match the name of the field in the data (redux overview slice)
        Filter: SelectColumnFilter,
      },
      {
        Header: lg(lang, CARD_SHIPMENT),
        accessor: 'deliveryState',
        Filter: SelectColumnFilter,
      },
      {
        Header: lg(lang, FOR_DOWNLOAD),
        accessor: 'order_download',
      },
    ],
    [lang]
  )

  // The default column object for every column passed to React Table.
  // Column-specific properties will override the properties in this object, eg. { ...defaultColumn, ...userColumn }
  // This is particularly useful for adding global column properties. For instance, when using the useFilters plugin hook, add a default Filter renderer for every column, eg.{ Filter: MyDefaultFilterComponent }
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const filterTypes = useMemo(
    () => ({
      text: (rows: any, filter: any) => {
        const regex = new RegExp(`^${filter}`, 'i')
        return rows.filter((r: any) => regex.test(r.name))
      },
    }),
    []
  )

  const orderIdSortees = React.useMemo(
    () => [
      {
        id: 'orderId',
        desc: false,
      },
    ],
    []
  )

  // 1. Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    globalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore: mv code error
      columns,
      data: reduxOverview,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: useMemo(
          () => [
            {
              id: 'orderId',
              desc: true,
            },
            {
              id: 'date',
              desc: true,
            },
          ],
          []
        ),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  const zaznamyVyzadujiciPozornost = preGlobalFilteredRows.filter(
    (row: any) => row.values.orderState === OrderState.ČekáNaZaplacení
  )

  const onFilterButtonClick = () => {
    setFilteringVisible(!isFilteringVisible)
  }

  function getFilteredRowIds() {
    return globalFilteredRows.map((row) => row.original.orderId)
  }

  function selectCurrentOrder(original: Order) {
    setRecapRollover(false)
    if (currentOrderDetailId === original.orderId) {
      setOrderDetailRollover(false)
      setCurrentOrderDetailId(0)
    } else {
      setCurrentOrderDetailId(original.orderId)
      rolloverPanel()
    }
  }

  function rolloverPanel() {
    setOrderDetailRollover(true)
  }

  const [showErrorModal, setShowErrorModal] = React.useState(false)
  const [isFaktura, setIsFaktura] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [rowSelected, setRowSelected] = React.useState(0)

  function manageErrors(response: Response) {
    if (!response.ok) {
      setShowErrorModal(true)
      setIsLoading(false)
      throw new Error(response.statusText)
    }
    return response
  }

  function getDataFromBackend(dataType: string, curOrderId: number): any {
    setIsLoading(true)
    const orderId = curOrderId
    console.log(orderId)
    const zasilkaBackendNumber = orderIdToCisloObjednavkyBackend(orderId)
    console.log('zasilkaBackendNumber', zasilkaBackendNumber)
    let token = getPrecious()
    if (dataType === 'faktura') {
      setIsFaktura(true)
    } else {
      setIsFaktura(false)
    }

    fetch(`${beObjednavky}/${zasilkaBackendNumber}/soubory/${dataType}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(manageErrors)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const fileURL = URL.createObjectURL(myBlob)
        window.open(fileURL) as Window
      })
      .then(() => setIsLoading(false))
      .catch((error) => console.log(error))
  }

  const getDataType = () => {
    if (isFaktura) {
      return 'Faktura'
    } else {
      return 'Proforma'
    }
  }

  return (
    <div>
      <div className='orders-overview-mv'>
        <div className='u-ml-6 u-px-6 u-pt-6 u-pb-4'>
          {/* tlačítka Filtrovat, Vyhledávat, Máte záznamy, kt. vyžadují vaši pozornost, Data import center ... */}
          <div className='grid-x align-top u-mb-5'>
            <div className='cell medium-auto'>
              <h1 className='u-size-l28 u-mb-3 u-ml-1'>{lg(lang, ORDERS)}</h1>
              {/* <button onClick={addFakeOrders}>Přidat fejkové objednávky</button> */}
              {/* FILTERING OF DATES - postponed for now */}
              {/* {isFilteringVisible ? (
                <a
                  onClick={onFilterButtonClick}
                  className='btn u-mr-1 u-mb-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-delete-thin'}></use>
                  </svg>
                  <span>Zrušit filtr</span>
                </a>
              ) : (
                <a
                  onClick={onFilterButtonClick}
                  className='btn u-mr-1 u-mb-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-funnel'}></use>
                  </svg>
                  <span>Filtrovat</span>
                </a>
              )} */}
            </div>
            <div className='cell medium-shrink u-text-right'>
              <div className='u-mb-2'>
                {zaznamyVyzadujiciPozornost.length > 0 && (
                  <div className='alert alert--warning alert--visible'>
                    <div className='alert__inner'>
                      <div
                        className='grid-x align-middle'
                        style={{ height: '14px' }}
                      >
                        <svg className='alert__icon icon icon--16'>
                          <use href={icons + '#sprite-bell'}></use>
                        </svg>
                        <span className='alert__text'>
                          {/* todo: pozornost vyžadují pouze určité záznamy - které? */}
                          {lg(lang, YOU_HAVE)}{' '}
                          {`${zaznamyVyzadujiciPozornost.length} ${
                            zaznamyVyzadujiciPozornost.length === 1 &&
                            lang === 'en'
                              ? 'record'
                              : zaznamyVyzadujiciPozornost.length === 1 &&
                                lang === 'cz'
                              ? 'záznam'
                              : zaznamyVyzadujiciPozornost.length === 1 &&
                                lang === 'bl'
                              ? 'запис'
                              : zaznamyVyzadujiciPozornost.length < 5 &&
                                lang === 'cz'
                              ? 'záznamy'
                              : lang === 'en'
                              ? 'records'
                              : lang === 'bl'
                              ? 'записи'
                              : 'záznamů'
                          }`}
                          {lang !== 'en' && ','}
                          {lang === 'cz' &&
                          zaznamyVyzadujiciPozornost.length === 1
                            ? ' který vyžaduje vaši pozornost'
                            : lang === 'cz' &&
                              zaznamyVyzadujiciPozornost.length !== 1
                            ? ' které vyžadují vaši pozornost'
                            : lang === 'en'
                            ? ' that require your attention'
                            : lang === 'bl' &&
                              zaznamyVyzadujiciPozornost.length === 1
                            ? ' което изисква вашето внимание'
                            : ' които изискват вашето внимание'}
                        </span>
                        {/* ORDTEX-164 Není možné zrušit žlutý banner v přehledu objednávek i když obsahuje ikonku pro zrušení
                        -> křížek odstraněn */}
                        {/* <svg className='alert__close icon icon--16'>
                          <use href={icons + '#sprite-delete-thin'}></use>
                        </svg> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {!isInternal() && (
                <a
                  href={urlDataImportOne}
                  className='btn u-mr-1 btn--violet btn--tiny'
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-calendar'}></use>
                  </svg>
                  Import dat
                </a>
              )}
            </div>
            <JstePrihlaseniZa />
          </div>
          {/* Tabulka - Header */}
          {/* <DatePicker /> */}
          <div
            {...getTableProps()}
            role='table'
            aria-label={lg(lang, ORDERS)}
            className='table table--compact table--no-border'
          >
            {/*  */}
            {/* Table Header - Filters (invisible by default) */}
            {/*  */}
            {headerGroups.map((headerGroup, i) => (
              <div
                key={nanoid(5)}
                role='row'
                className='table__row table__row--clickable table__row--header u-hide-large-down'
              >
                <div className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-4'>
                  <div role='cell' className='table__cell u-w-6'>
                    <div className='cell medium-auto'>
                      {isFilteringVisible ? (
                        <a
                          onClick={onFilterButtonClick}
                          className='btn u-mr-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
                        >
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-delete-thin'}></use>
                          </svg>
                          <span>
                            {lg(lang, CANCEL)} {lg(lang, FILTER)}
                          </span>
                        </a>
                      ) : (
                        <a
                          onClick={onFilterButtonClick}
                          className='btn u-mr-1 u-hide-large-down btn--secondary btn--outlined btn--tiny'
                        >
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-funnel'}></use>
                          </svg>
                          <span>{lg(lang, TO_FILTER)}</span>
                        </a>
                      )}
                    </div>
                    <div className=''>
                      {/* <DatePicker
                        field={undefined}
                        form={undefined}
                        label={undefined}
                        disabled={undefined}
                        onChange={undefined}
                        onBlur={undefined}
                      /> */}
                      {/* <div className='mx-datepicker mx-datepicker-range'>
                        <div className='mx-input-wrapper'>
                          <input
                            name='date'
                            type='text'
                            autoComplete='off'
                            placeholder='Kalendář'
                            className='mx-input'
                          />
                          <i className='mx-icon-calendar'>
                            <i>
                              <svg
                                className='icon icon--16'
                                style={{ color: 'rgb(40, 56, 151)' }}
                              >
                                <use href={icons + '#sprite-calendar'}></use>
                              </svg>
                            </i>
                          </i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div role='cell' className='table__cell u-w-6'>
                    <div className='input input--tiny input--small-placeholder'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(2, 3).map((column) => (
                          <div key={nanoid(5)}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-3'>
                  <div role='cell' className='table__cell u-w-4'>
                    <div className='input input--tiny input--small-placeholder'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(3, 4).map((column, j) => (
                          <div key={nanoid(5)}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div role='cell' className='table__cell u-w-4'>
                    <div className='input input--tiny input--small-placeholder'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(4, 5).map((column, j) => (
                          <div key={nanoid(5)}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    role='cell'
                    className='table__cell table__cell--overflow u-w-4'
                  >
                    <div className='input input--tiny input--small-placeholder'>
                      <div className='input__wrapper'>
                        {headerGroup.headers.slice(5, 6).map((column, j) => (
                          <div key={nanoid(5)}>
                            {isFilteringVisible && column.canFilter
                              ? column.render('Filter')
                              : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='table__group table__group--large u-w-6 u-hide-xsmall-down u-w-medium-4 u-w-xlarge-3'>
                  <div
                    role='cell'
                    className='table__cell table__cell--overflow u-w-6'
                  >
                    {headerGroup.headers.slice(6, 7).map((column, j) => (
                      <div key={nanoid(5)}>
                        {isFilteringVisible && column.canFilter
                          ? column.render('Filter')
                          : null}
                      </div>
                    ))}
                  </div>
                  <div
                    role='cell'
                    className='table__cell table__cell--overflow u-w-6'
                  >
                    {headerGroup.headers.slice(7, 8).map((column, j) => (
                      <div key={nanoid(5)}>
                        {isFilteringVisible && column.canFilter
                          ? column.render('Filter')
                          : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='table__group u-w-medium-4 u-w-large-3 u-hide-small-down'></div>
              </div>
            ))}
            {/*  */}
            {/* Table Header - The Header */}
            {/*  */}
            {headerGroups.map((headerGroup) => (
              <div
                role='row'
                className='table__row table__row--clickable table__row--header'
                {...headerGroup.getHeaderGroupProps()}
              >
                {/* Datum obj., Objednal(a) */}
                <div className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-4'>
                  {headerGroup.headers.slice(1, 3).map((column) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        'table__cell table__cell--sortable u-w-6 ' +
                        (column.isSorted ? 'is-active' : '')
                      }
                    >
                      {renderHeaderAndSortIndicators(column)}
                    </div>
                  ))}
                </div>
                {/* Číslo obj., Částka, Poznámka */}
                <div className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-4'>
                  {headerGroup.headers.slice(3, 6).map((column) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        'table__cell table__cell--sortable u-w-4 ' +
                        (column.isSorted ? 'is-active' : '')
                      }
                    >
                      {renderHeaderAndSortIndicators(column)}
                    </div>
                  ))}
                </div>
                {/* Stav obj., Karetní zásilka */}
                {/* todo: fix the size of the columns, it's broken because of long text Čeká na zpracování */}
                <div className='table__group table__group--large u-w-6 u-hide-xsmall-down u-w-medium-4 u-w-xlarge-3'>
                  {headerGroup.headers.slice(6, 8).map((column) => (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        'table__cell table__cell--sortable u-w-6 ' +
                        (column.isSorted ? 'is-active' : '')
                      }
                    >
                      {renderHeaderAndSortIndicators(column)}
                    </div>
                  ))}
                </div>
                <div className='table__group u-w-medium-4 u-w-large-3 u-hide-small-down'>
                  <div role='cell' className='table__cell u-w-12'>
                    <b>{lg(lang, FOR_DOWNLOAD)}</b>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*  */}
          {/*  */}
          {/* Tabulka - data */}
          {/*  */}
          {/*  */}
          {getFilteredRowIds().length === 0 ? (
            <div
              className='u-text-center u-mt-5'
              style={{
                backgroundColor: Color.GREYLIGHT,
              }}
            >
              <p className='u-size-l20 u-mb-2'>{lg(lang, NO_RECORD_FOUND)}</p>{' '}
              <img
                src={NoResult}
                srcSet={NoResult2x + ' 2x'}
                width='376'
                height='auto'
                alt='Nebyl nalezen žádný záznam'
              />
            </div>
          ) : (
            <div className='box box--small-radius box--no-padding u-mb-2'>
              <div className='box__content'>
                <div
                  {...getTableBodyProps()}
                  role='overview-table'
                  aria-label='Přehled objednávek'
                  className='table table--compact'
                >
                  {rows.map((row) => {
                    prepareRow(row)
                    return (
                      // Order detail Rollover
                      <div
                        key={nanoid(5)}
                        role='row'
                        className='table__row table__row--clickable'
                      >
                        {/* Datum objednání, Objednal(a) */}
                        <div
                          onClick={() => selectCurrentOrder(row.original)}
                          className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-4'
                        >
                          {row.cells.slice(1, 3).map((cell, j) => {
                            return (
                              <div
                                className='table__cell table__cell--valign u-text-ellipsis u-w-6'
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                        {/* Číslo objednávky, částka, poznámka */}
                        <div
                          onClick={() => selectCurrentOrder(row.original)}
                          className='table__group table__group--large u-w-6 u-w-medium-4 u-w-xlarge-4'
                        >
                          {row.cells.slice(3, 6).map((cell) => {
                            return (
                              <div
                                className='table__cell table__cell--valign u-text-ellipsis u-w-4'
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </div>
                            )
                          })}
                        </div>
                        {/* Stav objednávky, karetní zásilka */}
                        <div
                          onClick={() => selectCurrentOrder(row.original)}
                          className='table__group table__group--large u-w-6 u-hide-xsmall-down u-w-medium-4 u-w-xlarge-3'
                        >
                          <div
                            role='cell'
                            className={`table__cell table__cell--valign u-text-ellipsis u-w-6 ${colorize(
                              row.original.orderState
                            )} ${
                              row.original.orderState ===
                              OrderState.ČekáNaZaplacení
                                ? 'table__cell--dot'
                                : ''
                            }`}
                          >
                            {row.cells[6].render('Cell')}{' '}
                          </div>
                          <div
                            role='cell'
                            className={
                              'table__cell table__cell--valign u-text-ellipsis u-w-6 ' +
                              colorize(row.original.deliveryState)
                            }
                          >
                            {row.cells[7].render('Cell')}
                          </div>
                        </div>
                        <div className='table__group u-w-medium-4 u-w-large-3 u-hide-small-down'>
                          <div role='cell' className='table__cell u-w-12'>
                            <a
                              onClick={() => {
                                getDataFromBackend(
                                  'proforma',
                                  row.original.orderId
                                )
                                console.log('Row id is' + row.id)
                                setRowSelected(parseInt(row.id))
                                setSelectedOrderState(row.original.orderState)
                              }}
                              className='btn u-px-2 u-mb-1 u-mb-xlarge-0 btn--violet btn--tiny'
                            >
                              <svg className='icon icon--16'>
                                <use href={icons + '#sprite-download'}></use>
                              </svg>
                              {lg(lang, PROFORMA)}
                            </a>{' '}
                            <a
                              onClick={() => {
                                getDataFromBackend(
                                  'faktura',
                                  row.original.orderId
                                )
                                setRowSelected(parseInt(row.id))
                                setSelectedOrderState(row.original.orderState)
                              }}
                              className='btn u-px-2 btn--violet btn--tiny'
                            >
                              <svg className='icon icon--16'>
                                <use href={icons + '#sprite-download'}></use>
                              </svg>
                              {lg(lang, INVOICE)}
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {/* Zkušební loader*/}
          {isLoading && (
            <div
              className='error-modal_div'
              style={{
                marginTop: `-${56 + 60 * rowSelected}px`,
                marginRight: '70px',
              }}
            >
              <span style={{ fontSize: '20px' }} className='loader'></span>
            </div>
          )}

          <div
            className='error-modal_div'
            style={{ marginTop: `-${92 + 60 * rowSelected}px` }} // 92 je výška do tabulky, 60 je výška jednoho řádku
          >
            <ErrorModal
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              text={generateInfoMessage(getDataType(), selectedOrderState)}
            />
          </div>
        </div>
      </div>
      <SideMenu />
      {/* PANEL - Detail objednávky */}
      <div className='detail-objednavky-panel-mv panel__wrapper'>
        <div
          className={'panel' + (isOrderDetailRollover ? ' panel--open' : '')}
        >
          <div className='panel__body'>
            <div className='box box--wider'>
              <div className='box__content'>
                <div className='u-text-right u-mb-2'>
                  <button
                    onClick={() => {
                      setOrderDetailRollover(false)
                      setCurrentOrderDetailId(0)
                      window.history.replaceState(
                        null,
                        'Přehled objednávek',
                        urlOrdersOverview
                      )
                    }}
                    className='button-as-anchor underline u-size-base14 u-color-dark-purple-blue hover-pointer'
                  >
                    {lg(lang, CLOSE)}
                    <svg className='icon icon--24 u-ml-1'>
                      <use href={icons + '#sprite-delete'}></use>
                    </svg>
                  </button>
                </div>
                <OrderDetail orderId={currentOrderDetailId} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Rekapitulace objednávky Rollover */}
      <div className={'panel' + (isRecapRollover ? ' panel--open' : '')}>
        <div className='panel__body'>
          <div className='box box--narrow'>
            <div className='box__content'>
              <div className='u-text-right u-mb-2'>
                <a
                  className='underline u-size-base14 u-color-dark-purple-blue'
                  onClick={() => {
                    setRecapRollover(false)
                    window.history.replaceState(
                      null,
                      'Přehled objednávek',
                      urlOrdersOverview
                    )
                    // setRedirectSelfcare(true)
                  }}
                >
                  {lg(lang, CLOSE)}

                  <svg className='icon icon--24 u-ml-1'>
                    <DeleteIcon width={24} height={24} />
                  </svg>
                </a>
              </div>
              <OrderEmailRecap />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrdersOverview

{
  /* <div className='select select--wide select--checkboxes'>
                      <div className='select__option select__option--selected'>
                        <span>Stav obj.</span>
                        <svg className='icon icon--24'>
                          <use href={icons + '#sprite-sorting-down'}></use>
                        </svg>
                      </div>
                      <div className='select__options'>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Čeka na vyřizeni <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Pripraveno k odeslani/odberu{' '}
                            <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Odeslana/odebrana <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Stornovana <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Všechny záslky doručeny <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Blokováno <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Dobropis <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Vrubopis <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Objednáno <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Sloučeno <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                        <div className='select__option'>
                          <label className='checkbox u-size-s'>
                            Čeká na schválení <input type='checkbox' />
                            <span className='checkbox__box'></span>
                          </label>
                        </div>
                      </div>
                    </div> */
}
