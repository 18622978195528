import { getCenikFromRedux } from 'app/facade'
import { sinking, verne } from 'app/julesverne'
import {
  AdresaBackend,
  ZamestnancovaHistorickaObjednavkaBackend,
  KartaBackend,
  KontaktniOsobaBackend,
  ZamestnanecBackend,
  ObjednavkaBackend,
  ZasilkaBackend,
  AdresaBackendKlic,
  Cenik,
  OrderState,
  Role,
  equalsBackend,
  ConsignmentState,
  Address,
  Order,
  User,
  Zasilka,
  BrokenAddress,
  ZamestnanecBackendEditace,
  AdresaBackendUUID,
} from 'app/type'
import { HistoricalOrder, TableRow } from 'components/table/tableSlice'
import {
  getDisplayCardStatusForEmployee,
  MultiPassCard,
  MultiPassCardState,
} from './CardOps'
import {
  addWorkingDays,
  dateFromBackendString,
  dateTimeFromBackendString,
  newDate,
} from './DateOps'
import {
  normalize,
  splitPredvolbaTelefon,
  parsePsc,
  parseUliceCisloPopisne,
  splitUliceCisloPopisne,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from './ValidFormatOps'

export function zasilkaBackendToZasilka(
  zasilka: ZasilkaBackend,
  orderDate: Date,
  orderState: OrderState
): Zasilka {
  const { cislo, adresa, dorucena, pocetPrvnichKaret, pocetNahradnichKaret } =
    zasilka

  const orderDatePlusThreeWorkingDays = new Date(addWorkingDays(orderDate, 3))
  const haveThreeWorkingDaysElapsed = orderDatePlusThreeWorkingDays < newDate()

  const zasilkaFrontend: Zasilka = {
    number: cislo,
    dorucovaciAdresa: adresaBackendToAddress(adresa),
    // rewrite some day to a switch statement for brevity
    stavZasilky: dorucena
      ? ConsignmentState.Převzatá
      : orderState === OrderState.Přijatá
      ? ConsignmentState.ČekáNaZpracování
      : orderState === OrderState.ČekáNaZaplacení
      ? ConsignmentState.ČekáNaZpracování
      : orderState === OrderState.Zaplacená
      ? haveThreeWorkingDaysElapsed
        ? ConsignmentState.ČekáNaPřevzetí
        : ConsignmentState.VeVýrobě
      : orderState === OrderState.Doručená
      ? ConsignmentState.Převzatá
      : orderState === OrderState.Stornovaná
      ? ConsignmentState.ČekáNaPřevzetí
      : orderState === OrderState.Blokovaná
      ? ConsignmentState.ČekáNaZpracování
      : orderState === OrderState.Dobropis
      ? ConsignmentState.ČekáNaPřevzetí
      : orderState === OrderState.Vrubopis
      ? ConsignmentState.ČekáNaPřevzetí
      : ConsignmentState.Neznámý,
    pocetNovychKaret: pocetPrvnichKaret,
    pocetNahradnichKaret: pocetNahradnichKaret,
  }
  return zasilkaFrontend
}

export function orderIdToCisloObjednavkyBackend(orderId: number): string {
  // 40E00000987
  const isOrderIdMoreThan8Digits = orderId.toString().length > 8
  const zasilkaBackendNumber = isOrderIdMoreThan8Digits
    ? orderId.toString()
    : '40E' + orderId.toString().padStart(8, '0')

  return zasilkaBackendNumber
}

export function cisloObjednavkyBackendToOrderId(
  cisloObjednavky: string
): number {
  // When returned in /after-login, it is '40E00000414'
  const substring =
    // cisloObjednavky begins with a code containing letters (e.g. '40E')
    cisloObjednavky.slice(0, 3).match(/[a-zA-Z]/g) !== null
      ? // ORD-1893 - Nezobrazeni cisla objednavky u starsicho objednavek -> Applying a clever, yet potentially unsafe fix. Upon editing the order, like accepting a delivery, the order id will be changed to '40E' + orderId. Meaning, backend will give us a 404 response. However, nobody is going to edit these old orders, which came from migration.
        // .substring(cisloObjednavky.indexOf('40E') + 3)
        cisloObjednavky.substring(3).split(' ')[0]
      : cisloObjednavky
  // console.log('cisloObjednavkyBackendToOrderId, substring:', substring)
  return parseInt(substring)
}

export function stavObjednavkyToOrderState(stavObjednavky: string) {
  switch (normalize(stavObjednavky)) {
    case 'created':
    case 'pending':
      return OrderState.Přijatá
    case 'waiting':
    case 'cafprepared':
      return OrderState.ČekáNaZaplacení
    case 'sent':
      return OrderState.Zaplacená
    case 'delivered':
      return OrderState.Doručená
    case 'cancel': // špatně pojmenované
      return OrderState.Stornovaná
    case 'blocked':
      return OrderState.Blokovaná
    case 'creditadvice':
      return OrderState.Dobropis
    case 'creditadvicecancel':
      return OrderState.Vrubopis
    case 'creditadvicenot':
      return OrderState.RozpracovanýDobropis
    default:
      return OrderState.Neznámý
  }
}

export function computeDeliveryStateFromConsignments(consignments: Zasilka[]) {
  if (consignments.length === 0) {
    return ConsignmentState.ŽádnéKarty
  }
  const consignmentStates = consignments.map((zasilka) => zasilka.stavZasilky)
  // we have to bubble up the state of the consignment, from Převzatá to ČekáNaZpracování
  const deliveryState = consignmentStates.reduce((acc, curr) => {
    switch (curr) {
      case ConsignmentState.ČekáNaZpracování:
        if (
          acc === ConsignmentState.ČekáNaPřevzetí ||
          acc === ConsignmentState.VeVýrobě
        )
          return acc
        else return ConsignmentState.ČekáNaZpracování
      case ConsignmentState.VeVýrobě:
        if (acc === ConsignmentState.ČekáNaPřevzetí) return acc
        else return ConsignmentState.VeVýrobě
      case ConsignmentState.ČekáNaPřevzetí:
        // always display ČekáNaPřevzetí if one of the consignments is ČekáNaPřevzetí
        return ConsignmentState.ČekáNaPřevzetí
      case ConsignmentState.Převzatá:
        if (
          acc === ConsignmentState.ČekáNaPřevzetí ||
          acc === ConsignmentState.VeVýrobě ||
          acc === ConsignmentState.ČekáNaZpracování
        )
          return acc
        else return ConsignmentState.Převzatá
      case ConsignmentState.Neznámý:
        return ConsignmentState.Neznámý
      default:
        return ConsignmentState.Neznámý
    }
  }, ConsignmentState.Převzatá)
  return deliveryState
}

export function objednavkaBackendToOrder(objednavka: ObjednavkaBackend): Order {
  const cenik: Cenik = getCenikFromRedux()

  const zadavatel = objednavka.zadavatel
  const email = objednavka.email

  const orderDate = dateTimeFromBackendString(objednavka.datum)
  const orderState = stavObjednavkyToOrderState(objednavka.status)

  const consignments = objednavka.zasilky
    ? objednavka.zasilky.map((zasilka) =>
        zasilkaBackendToZasilka(zasilka, orderDate, orderState)
      )
    : ([] as Zasilka[])

  const order: Order = {
    orderId: cisloObjednavkyBackendToOrderId(objednavka.cislo),
    owner: zadavatel,
    date: orderDate,
    price: objednavka.cena.spolu,
    note: objednavka.poznamka || '',
    contactEmail: email,
    orderState: orderState,
    deliveryState: computeDeliveryStateFromConsignments(consignments),
    zasilky: consignments,
    gastroCredits: objednavka.stravenkovyKredit || 0,
    leisureCredits: objednavka.volnocasovyKredit || 0,
    feeGastro: cenik.stravenky,
    feeLeisure: cenik.volnocas,
    spravaUctu: cenik.spravaJednohoUctu,
    vydaniPrvniKartyPrice: cenik.vydaniPrvniKarty,
    vydaniNahradniKartyPrice: cenik.vydaniNahradniKarty,
    uskutecnenyPoplatekGastro: objednavka.poplatekStravenkovyKredit || 0,
    uskutecnenyPoplatekLeisure: objednavka.poplatekVolnocasovyKredit || 0,
    uskutecnenyPoplatekDoruceni: objednavka.doruceniMPC || 0,
    uskutecnenyPoplatekSpravaUctu: objednavka.spravaJednohoUctu || 0,
  }

  return order
}

export function adresaBackendToAddress(
  adresaBackend: AdresaBackendUUID
): Address {
  const { predvolba, telefon } = splitPredvolbaTelefon(adresaBackend.telefon)
  const address: Address = {
    uuid: adresaBackend.uuid,
    nazevSpolecnosti: adresaBackend.nazevSpolecnosti,
    kontaktniOsoba: adresaBackend.kontaktniOsoba,
    uliceCisloPopisne: parseUliceCisloPopisne(
      adresaBackend.ulice,
      adresaBackend.cisloPopisne
    ),
    mesto: adresaBackend.mesto,
    psc: parsePsc(adresaBackend.psc),
    predvolba: predvolba,
    telefon: telefon,
    oddeleni: (adresaBackend.oddeleni as unknown as string[]) || ['Žádné'], // backend uses different datatypes for address.oddeleni based on the specific request
  }

  return address
}

export function adresaBackendToBrokenAddress(
  adresaBackend: AdresaBackendUUID
): BrokenAddress {
  const { predvolba, telefon } = splitPredvolbaTelefon(adresaBackend.telefon)
  const address: BrokenAddress = {
    uuid: adresaBackend.uuid,
    nazevSpolecnosti: adresaBackend.nazevSpolecnosti,
    kontaktniOsoba: adresaBackend.kontaktniOsoba,
    street: adresaBackend.ulice,
    cisloPopisne: adresaBackend.cisloPopisne,
    mesto: adresaBackend.mesto,
    psc: adresaBackend.psc,
    predvolba: predvolba,
    telefon: telefon,
    oddeleni: (adresaBackend.oddeleni as unknown as string[]) || ['Žádné'], // backend uses different datatypes for address.oddeleni based on the specific request
  }

  return address
}

export function kontaktniOsobaBackendToUser(
  kontaktniOsobaBackend: KontaktniOsobaBackend
): User {
  // !!!
  // breaks if equalsBackend doesn't include NFD normalization
  // backend currently stores it as 'hlavni' and 'aktivni' values in db
  const role = equalsBackend(kontaktniOsobaBackend.status, Role.Aktivní)
    ? Role.Aktivní
    : equalsBackend(kontaktniOsobaBackend.status, Role.Hlavní)
    ? Role.Hlavní
    : Role.Vytvořená
  const user: User = {
    jmeno: kontaktniOsobaBackend.jmeno,
    prijmeni: kontaktniOsobaBackend.prijmeni,
    email: kontaktniOsobaBackend.email,
    predvolba: +kontaktniOsobaBackend.predvolba,
    telefon: +kontaktniOsobaBackend.telefon,
    role: role,
  }

  return user
}

// status: 4 | 5 | 7 | 1 | 3 | 8 | 2 | 6 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 99 | -2
// G95_CARD_STATUS	G95_STATUS_CODE	G95_STATUS_NAME	G95_FOLLOWING_STATUS_NAME
// 4	CRD_ACT	Aktivní	K aktivaci
// 5	CRD_EXP	Expirovaná	K expiraci
// 7	CRD_CANC	Zrušená	Ke zrušení
// 1	CRD_IN_PRD	Přijata do výroby	K přijetí do výroby
// 3	CRD_NOT_ACT	K aktivaci	Na „k aktivaci“
// 8	CRD_NOT_MNF	Zrušena ve výrobě	Ke zrušení ve výrobě
// 2	CRD_MNF	Vyrobená	K vyrobení
// 6	CRD_BLK_TMP	Blokovaná	K blokaci
// 9	BLK_CNF_LST	Ztracená	Ke ztracení
// 10	BLK_REP_LST	Nahlášena ztráta	K nahlášení ztráty
// 11	BLK_SPC_FRD	Podezřelé chování	K podezřelému chování
// 12	BLK_CNF_STLN	Zcizená	Ke zcizení
// 13	BLK_REP_STLN	Nahlášeno zcizení	K nahlášení zcizení
// 14	BLK_LT_PMNT	Blokována pro pozdní platby	K blokaci pro pozdní platby
// 15	BLK_PIN_CTR	Blokována pro chybně zadaný PIN	K blokaci pro chybně zadaný PIN
// 16	BLK_HLDR_RQ	Blokovaná na žádost držitele	K blokaci na žádost držitele
// 17	APP_DECL	Zamítnutá	K zamítnutí
// 18	APP_APPR	Schválená	Ke schválení
// 19	APP_APPR_PEND	Čeká na schválení	K čekání na schválení
// 99	PIN_RESET_REQUEST	Žádost o reset PINu	K žádosti o reset PINu
// -2	CRD_CART	V košíku
export function statusSpoKartyToMultiPassCardStatus(
  statusSpo: number
): MultiPassCardState {
  switch (statusSpo) {
    case 4:
      return MultiPassCardState.Aktivní
    case 5:
      return MultiPassCardState.Expirovaná
    case 7:
      return MultiPassCardState.Zrušená
    case 1:
      return MultiPassCardState.PřijataDoVýroby
    case 3:
      return MultiPassCardState.KAktivaci
    case 8:
      return MultiPassCardState.ZrušenaVeVýrobě
    case 2:
      return MultiPassCardState.Vyrobená
    case 6:
      return MultiPassCardState.Blokovaná
    case 9:
      return MultiPassCardState.Ztracená
    case 10:
      return MultiPassCardState.NahlášenaZtráta
    case 11:
      return MultiPassCardState.PodezřeléChování
    case 12:
      return MultiPassCardState.Zcizená
    case 13:
      return MultiPassCardState.NahlášenoZcizení
    case 14:
      return MultiPassCardState.BlokovánaProPozdníPlatby
    case 15:
      return MultiPassCardState.BlokovánaProChybněZadanýPIN
    case 16:
      return MultiPassCardState.BlokovánaNaŽádostDržitele
    case 17:
      return MultiPassCardState.Zamítnutá
    case 18:
      return MultiPassCardState.Schválená
    case 19:
      return MultiPassCardState.ČekáNaSchválení
    case 99:
      return MultiPassCardState.ŽádostOResetPINu
    case -2:
      return MultiPassCardState.VKošíku
    default:
      return MultiPassCardState.Neznámý
  }
}

export function kartaBackendToMultiPassCard(
  kartaBackend: KartaBackend
): MultiPassCard {
  const cisloKarty =
    kartaBackend.cisloKarty === null ? '' : kartaBackend.cisloKarty

  const datumObjednaniKarty = dateTimeFromBackendString(
    kartaBackend.datumObjednavky
  )

  const cardType = kartaBackend.typ || ''

  let datumExpiraceKarty
  if (kartaBackend.datumExpirace === null) {
    datumExpiraceKarty = new Date(datumObjednaniKarty)
    datumExpiraceKarty.setFullYear(datumExpiraceKarty.getFullYear() + 2)
  } else {
    datumExpiraceKarty = dateFromBackendString(kartaBackend.datumExpirace)
  }

  const multiPassCard: MultiPassCard = {
    id: cisloKarty,
    datumObjednani: datumObjednaniKarty,
    datumExpirace: datumExpiraceKarty,
    status: statusSpoKartyToMultiPassCardStatus(kartaBackend.status),
    cardNumber: cisloKarty,
    type: cardType,
    orderId: cisloObjednavkyBackendToOrderId(kartaBackend.cisloObjednavky),
  }
  return multiPassCard
}

export function objednavkaZamestnanceBackendToHistoricalOrder(
  objednavkaZamestnanceBackend: ZamestnancovaHistorickaObjednavkaBackend
): HistoricalOrder {
  const historicalOrder: HistoricalOrder = {
    id: cisloObjednavkyBackendToOrderId(
      objednavkaZamestnanceBackend.cisloObjednavky
    ),
    date: dateTimeFromBackendString(
      objednavkaZamestnanceBackend.datumObjednavky
    ),
    gastro: objednavkaZamestnanceBackend.stravenkovyKredit || 0,
    leisure: objednavkaZamestnanceBackend.volnocasovyKredit || 0,
  }
  return historicalOrder
}

export function addressToAdresaBackendKlic(
  address: Address,
  oddeleni: string
): AdresaBackendKlic {
  const { ulice, cisloPopisne } = splitUliceCisloPopisneZachovatCisloOrientacni(
    address.uliceCisloPopisne
  )
  return {
    ulice: ulice,
    cisloPopisne: cisloPopisne,
    psc: address.psc.toString(),
    oddeleni: oddeleni,
  }
}

export function zamestnanecBackendToEmployee(
  zamestnanecBackend: ZamestnanecBackend
): TableRow {
  const multiPassCards = zamestnanecBackend.karty.map(
    kartaBackendToMultiPassCard
  )

  const uliceCisloPopisne = parseUliceCisloPopisne(
    zamestnanecBackend.adresa.ulice,
    zamestnanecBackend.adresa.cisloPopisne
  )

  const tableRow: TableRow = {
    id: zamestnanecBackend.cislo.toString(), // zákazník chtěl string jako user id, ale backend používá number, prý kvůli SPO
    name: zamestnanecBackend.jmeno,
    surname: zamestnanecBackend.prijmeni,
    cardStatus: getDisplayCardStatusForEmployee(multiPassCards),
    leisureCredits: 0,
    gastroCredits: 0,
    selected: false,
    oddeleni: zamestnanecBackend.oddeleni,
    uliceCisloPopisne: uliceCisloPopisne,
    cards: multiPassCards,
    objednatNahradniKartu: false,
    checkBoxObjednatNahradniKartu: false,
    historicalOrders: zamestnanecBackend.objednavky.map(
      objednavkaZamestnanceBackendToHistoricalOrder
    ),
    group: zamestnanecBackend.skupina || 'Nezařazení',
    paidMonths: zamestnanecBackend.zaplaceneMesice,
    deactivatedMonths: zamestnanecBackend.deaktivovaneMesice,
    deactivatedFrom: zamestnanecBackend.datumDeaktivace
      ? dateTimeFromBackendString(zamestnanecBackend.datumDeaktivace)
      : null,
  }

  return tableRow
}

export function employeeToZamestnanecBackendEditace(
  employee: TableRow,
  address: AdresaBackendKlic
): ZamestnanecBackendEditace {
  const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
    id: +employee.id,
    jmeno: employee.name,
    prijmeni: employee.surname,
    adresa: address,
    zaplaceneMesice: employee.paidMonths,
    deaktivovaneMesice: employee.deactivatedMonths,
  }
  return zamestnanecBackendEditace
}

export function createBackendTelefon(predvolba: number, telefon: number) {
  return '+' + predvolba + telefon
}
