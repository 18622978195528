import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class PenIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'pen-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26.07 5.927l-.047-.047c-1.138-1.143-3.264-1.212-4.602.025L6.04 21.345l4.474 4.717.553-.555.006-.006 14.994-15.182.053-.046c.53-.465.88-1.309.88-2.151 0-.944-.332-1.67-.88-2.151l-.05-.044zM8.647 27L5 23.155V27h3.647zm3.843-.088L10.41 29H3v-7.438L20.03 4.468c2.08-1.957 5.46-1.957 7.41 0C28.48 5.381 29 6.686 29 8.122c0 1.305-.52 2.74-1.56 3.653L12.49 26.912zM25.04 8.374L11.208 22.207l-1.414-1.414L23.626 6.96a1 1 0 111.415 1.414z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default PenIcon
