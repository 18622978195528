import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class ShoppingCartIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'shopping-cart-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 5.667a1 1 0 000 2c.765 0 1.326.348 1.793.976.492.661.837 1.592 1.075 2.589l.01.04 2.826 10a.997.997 0 00.155.317A2.48 2.48 0 008 23.5c0 1.417 1.083 2.5 2.5 2.5s2.5-1.083 2.5-2.5c0-.57-.176-1.087-.478-1.5h7.956c-.302.413-.478.93-.478 1.5 0 1.417 1.083 2.5 2.5 2.5s2.5-1.083 2.5-2.5c0-.736-.292-1.381-.771-1.832 1.477-.535 2.685-1.692 3.07-3.283l1.793-5.794A2 2 0 0027.182 10H7.608c-.266-.884-.647-1.794-1.21-2.55C5.644 6.434 4.535 5.666 3 5.666zM9.01 15l-.848-3h19.02l-.947 3.059A.497.497 0 0026 15H9.01zm.283 1l1.13 4h11.91c1.532 0 2.752-.927 3.026-2.105a.976.976 0 01.02-.07L25.942 16H9.293zm2.04 7.5c0-.5-.333-.833-.833-.833a.835.835 0 00-.833.833c0 .5.333.833.833.833.5 0 .833-.333.833-.833zm12 0c0-.5-.333-.833-.833-.833a.835.835 0 00-.833.833c0 .5.333.833.833.833.5 0 .833-.333.833-.833z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default ShoppingCartIcon
