import React, { useEffect, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import {
  reducerOdebratPosledniOpravnenouOsobu,
  reducerOpravnenaOsoba,
  reducerPridatPrazdnouOpravnenouOsobu,
} from 'components/onboarding/features/firemniUdajeSlice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { lg } from 'app/facade'
import {
  ADD_ANOTHER_PERSON,
  AUTHORIZED_PERSON,
  FOR_EXAMPLE,
  REQUIRED_FIELD,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface Props {
  opravneneOsoby: string[]
  hasValidated: boolean
  wereChanged: boolean[]
}

const OpravneneOsoby: React.FC<Props> = ({
  opravneneOsoby,
  hasValidated,
  wereChanged,
}) => {
  const dispatch = useAppDispatch()

  const [mouseOver, setMouseOver] = useState(false)

  const lang = useAppSelector(selectLanguage) || 'cz'

  const handleInput = (e: any) => {
    // let value = e.target.value.trim() // ORD-1851
    let value = e.target.value // ORD-1855 - Uvedeni opravnene osoby -> do not trim multi-word user inputs... he is unable to enter spaces between the words without hacking
    let index = e.target.id
    dispatch(reducerOpravnenaOsoba({ index, value }))
    console.log('opravneneOsoby', opravneneOsoby)
  }

  return (
    <>
      <div className='input u-mb-3 input--required'>
        <label className='input__label input__label--info'>
          {lg(lang, AUTHORIZED_PERSON)}
          <div
            className='input__info'
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
          >
            {/* <QuestionCircleOutlineIcon width={16} height={16} /> */}
          </div>
          {/* {mouseOver && (
            <div className='input__hover'>
              <p className='u-size-s'>
                {lg(lang, AUTHORIZED_PERSON_ADDITIONAL_INFO)}
              </p>
            </div>
          )} */}
        </label>
        <div className='input__wrapper'>
          <input
            id='0'
            type='text'
            placeholder={`${lg(lang, FOR_EXAMPLE)} Karel Havlíček`}
            value={opravneneOsoby[0]}
            onChange={handleInput}
            className={wereChanged[0] ? 'changed-input bg-97' : ''}
          />
          {hasValidated && !opravneneOsoby[0] && (
            <span className='input input__error'>
              {lg(lang, REQUIRED_FIELD)}
            </span>
          )}
        </div>
      </div>
      {console.log('opravneneOsoby', opravneneOsoby)}
      {opravneneOsoby.slice(1).map((opravnenaOsoba: string, i: number) => (
        <div key={i} className='input u-my-3 input'>
          <label>{lg(lang, AUTHORIZED_PERSON)}</label>
          <input
            id={(i + 1).toFixed(0)}
            type='text'
            placeholder={`${lg(lang, FOR_EXAMPLE)} Karel Havlíček`}
            value={opravnenaOsoba}
            onChange={handleInput}
            className={
              // todo dmitry why is -2 here?
              wereChanged[i + 1] && i === opravneneOsoby.length - 2
                ? 'changed-input bg-92'
                : wereChanged[i + 1]
                ? 'changed-input bg-97'
                : ''
            }
          />
          {i === opravneneOsoby.length - 2 && (
            <svg
              onClick={() => dispatch(reducerOdebratPosledniOpravnenouOsobu())}
              className='input__remove icon icon--24'
              style={{ color: 'rgb(234, 68, 78)' }}
            >
              <use href={icons + '#sprite-close-circle-filled'}></use>
            </svg>
          )}
        </div>
      ))}
      {opravneneOsoby.length < 5 && (
        <button
          onClick={() => {
            if (opravneneOsoby.length < 5)
              dispatch(reducerPridatPrazdnouOpravnenouOsobu())
          }}
          className={`button-as-anchor ${
            opravneneOsoby.length > 4 ? 'disabled' : ''
          }`}
        >
          <small>{lg(lang, ADD_ANOTHER_PERSON)}</small>
        </button>
      )}
    </>
  )
}

export default OpravneneOsoby
