import { useEffect, useRef, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// Modals
// Generic Modal Hook
export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const toggle = () => setIsShown(!isShown)
  return {
    isShown,
    toggle,
  }
}

// Create an array of modal hooks
export const modals = [useModal, useModal, useModal, useModal]

const addBodyClass = (className: string) =>
  document.body.classList.add(className)
const removeBodyClass = (className: string) =>
  document.body.classList.remove(className)

export function useBodyClass(className: string | string[]) {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className)

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className)
    }
  }, [className])
}

// auto closes modal on click outside or inside - maybe get rid of this one.
export function useComponentVisible(
  initialIsVisible: boolean,
  onOutside: () => void
) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef()

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsComponentVisible(false)
        onOutside()
        // @ts-ignore
        // haha, throws TypeError: ref.current is not a function
        // ref.current(e) // this line added from the creator of react-table
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ref, isComponentVisible, setIsComponentVisible }
}

// auto closes modal on click outside
export function useOnClickOutside(elRef: any, callbackHandler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements or the modal trigger - we want to allow the user to click the trigger to close/open the modal
        if (
          !elRef.current ||
          elRef.current.contains(event.target) ||
          (event.target.className.baseVal &&
            event.target.className.baseVal.includes('modal__trigger')) ||
          (typeof event.target.className === 'string' &&
            event.target.className.includes('checkbox')) ||
          (typeof event.target.className === 'string' &&
            event.target.className.includes('modal__trigger'))
        ) {
          // this line added from the creator of react-table - not sure it's needed
          // this line destroys everything! :)
          // @ts-ignore
          // callbackRef.current(event)
          return
        }
        callbackHandler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [elRef, callbackHandler]
  )
}

export function useOnEscapeClick(
  isModalShown: boolean,
  setModalShown: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setModalShown(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isModalShown, setModalShown])
}

export function useOnClickOutsideAndEscape(
  elRef: any,
  callbackHandler: any,
  isModalShown: boolean,
  setModalShown: React.Dispatch<React.SetStateAction<boolean>>
) {
  useOnClickOutside(elRef, callbackHandler)
  useOnEscapeClick(isModalShown, setModalShown)
}

export function useOnClickOutsideAndEscapeForMultipleModals(
  elRef: any,
  callbackHandler: any,
  modalShown: string,
  setModalShown: React.Dispatch<React.SetStateAction<string>>
) {
  useOnClickOutside(elRef, callbackHandler)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setModalShown('')
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [modalShown, setModalShown])
}
