import React, { FunctionComponent, useState } from 'react'
import GeoPinOutlineIcon from '@styles/icon/geoPinOutlineIcon'
import PlusIcon from '@styles/icon/plusIcon'
import { UDAJ_JE_POVINNY } from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { numberWithSpacesX } from 'app/util'
import { govActions, selectAddresses } from '../settings/governanceSlice'
import { tableActions, selectRowById, TableRow } from '../table/tableSlice'
import OddeleniRadioSelector from './OddeleniRadioSelector'
import {
  equalsBezRozdiluVCisluOrientacnim,
  findMatchingAddress,
  splitUliceCisloPopisne,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from 'util/ValidFormatOps'
import { createOddeleniBackend, mutateZamestnanecBackend } from 'app/req'
import { Address, ZamestnanecBackendEditace } from 'app/type'
import { changeZamestnanecBackend, lg } from 'app/facade'
import {
  ADD,
  ADDRESS_FOR_CARDS_DELIVERY,
  DEPARTMENT_NAME,
  NEW_DELIVERY_ADDRESS,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import EmployeeAddressesInline from './EmployeeAddressesInLine'
import { nanoid } from '@reduxjs/toolkit'

interface Props {
  id: string
  hasValidated?: boolean
}

const EmployeeDetailAddresses: FunctionComponent<Props> = ({
  hasValidated = false,
  id,
}) => {
  const dispatch = useAppDispatch()

  const [show, setShow] = useState('none')
  const addresses = useAppSelector(selectAddresses) as Address[]
  const employee = useAppSelector(selectRowById(id)) as TableRow
  const [nazevOddeleni, setNazevOddeleni] = React.useState('')
  const lang = useAppSelector(selectLanguage) || 'cz'

  // PERSISTENT DATA
  // called only when new department is created
  // this one is reused in persistEmployeeUliceCisloPopisne
  function persistEmployeeDepartment(
    oddeleni: string,
    prestehovanaUliceCisloPopisne?: string
  ) {
    const kamSeStehujeNeboZustava = prestehovanaUliceCisloPopisne
      ? prestehovanaUliceCisloPopisne
      : employee.uliceCisloPopisne // stale value from employee row in case we are calling this from persistEmployeeUliceCisloPopisne
    console.log('persistEmployeeDepartment', oddeleni)

    const kamSeStehujeNeboZustavaAddress = findMatchingAddress(
      kamSeStehujeNeboZustava,
      addresses
    ) as Address

    // if we are selecting a different address (we do not know the list of departments from the caller)
    if (!oddeleni) {
      // console.log(
      //   'persistEmployeeDepartment: oddeleni is undefined',
      //   oddeleni,
      //   kamSeStehujeNeboZustavaAddress
      // )
      oddeleni = kamSeStehujeNeboZustavaAddress.oddeleni[0]
      // console.log('persistEmployeeDepartment: oddeleni set to', oddeleni)
    }

    dispatch(
      tableActions.setOddeleni({
        id,
        oddeleni,
      })
    )

    const { ulice, cisloPopisne } =
      splitUliceCisloPopisneZachovatCisloOrientacni(kamSeStehujeNeboZustava)
    const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
      id: +id,
      jmeno: employee.name,
      prijmeni: employee.surname,
      adresa: {
        ulice: ulice,
        cisloPopisne: cisloPopisne,
        psc: kamSeStehujeNeboZustavaAddress.psc.toString(),
        oddeleni: oddeleni,
      },
      zaplaceneMesice: employee.paidMonths,
      deaktivovaneMesice: employee.deactivatedMonths,
    }
    mutateZamestnanecBackend(zamestnanecBackendEditace)
  }

  // called when a different address radio button is selected
  function persistEmployeeUliceCisloPopisne(uliceCisloPopisne: string) {
    dispatch(
      tableActions.setUliceCisloPopisne({
        id: id,
        uliceCisloPopisne: uliceCisloPopisne,
      })
    )

    // we do not know the list of departments on the caller side
    persistEmployeeDepartment('', uliceCisloPopisne) // mutate is called in this function

    console.log('AddressesUserDetail: setEmployeeAddress', uliceCisloPopisne)
  }

  function getEmployeeAddress(): string {
    // console.log('finding employee uliceCisloPopisne', employee)
    return employee ? employee.uliceCisloPopisne : ''
  }

  return (
    <div key={id} className='highlight u-mb-3 u-py-3 u-px-2'>
      <div className='highlight__content'>
        <div className='grid-x align-bottom u-mb-3'>
          <div className='cell auto'>
            <h4>{lg(lang, ADDRESS_FOR_CARDS_DELIVERY)}</h4>
          </div>
          {/* ORDTEX-161 - hide Správa adres button */}
          {/* <div className='cell shrink'>
            <a href={urlNastaveni + '/#nastaveni-adresy'} className='u-size-s'>
              <svg className='icon icon--16'>
                <PenIcon width={16} height={16} />
              </svg>
              Správa adres
            </a>
          </div> */}
        </div>
        {hasValidated && (!addresses || addresses.length === 0) && (
          <span className='input__error'>{UDAJ_JE_POVINNY}</span>
        )}
        {addresses.map((address, i) => (
          <div key={nanoid(5)}>
            <label className='radio u-mb-3 radio--align-top'>
              <input
                type='radio'
                // name='address-group' THIS WAS THE CAUSE OF THE PROBLEM
                checked={Boolean(
                  equalsBezRozdiluVCisluOrientacnim(
                    address.uliceCisloPopisne,
                    employee && employee.uliceCisloPopisne // Keep the "employee &&" check for when the employee is deleted, this component is still shown and throws Uncaught TypeError: Cannot read properties of undefined (reading 'uliceCisloPopisne')
                  )
                )}
                onChange={(e) => {
                  // https://stackoverflow.com/questions/49092857/react-16-radio-button-onchange-not-working
                  if (e.currentTarget.checked) {
                    console.log(
                      'AddressesUserDetail: checked address, address.uliceCisloPopisne, address.oddeleni',
                      address.uliceCisloPopisne,
                      address.oddeleni
                    )
                    persistEmployeeUliceCisloPopisne(address.uliceCisloPopisne)
                  } else {
                    console.log(
                      'AddressesUserDetail: unchecked address, address.uliceCisloPopisne, address.oddeleni',
                      address.uliceCisloPopisne,
                      address.oddeleni
                    )
                  }
                }}
              />
              <span className='radio__control'></span>
              <span className='radio__label'>
                <p className='u-size-base14'>{address.nazevSpolecnosti}</p>
                <p className='u-size-base14 u-mt-1'>{address.kontaktniOsoba}</p>
                <p className='u-size-base14 u-mt-1'>
                  {address.uliceCisloPopisne}, {address.mesto}, {address.psc}
                </p>
                <p className='u-size-base14 u-mt-1 u-mb-3'>
                  tel.: +{address.predvolba}{' '}
                  {numberWithSpacesX(address.telefon)}
                </p>

                {equalsBezRozdiluVCisluOrientacnim(
                  address.uliceCisloPopisne,
                  getEmployeeAddress()
                ) && (
                  <OddeleniRadioSelector
                    employeeId={employee.id}
                    selectedAddress={
                      findMatchingAddress(
                        employee.uliceCisloPopisne,
                        addresses
                      ) as Address
                    }
                    selectedDepartment={employee.oddeleni}
                    onPriraditOddeleni={() => {
                      show === 'priradit_oddeleni#' + i
                        ? setShow('none')
                        : setShow('priradit_oddeleni#' + i)
                    }}
                    onChange={persistEmployeeDepartment}
                  />
                )}
              </span>
            </label>
            {show === 'priradit_oddeleni#' + i && (
              <div>
                <div className='input u-mb-2 input--required'>
                  <label className='input__label'>
                    {lg(lang, DEPARTMENT_NAME)}
                  </label>
                  <div className='input__wrapper'>
                    <input
                      value={nazevOddeleni}
                      onChange={(e) => setNazevOddeleni(e.target.value)}
                      placeholder='Např.: Marketing'
                      type='text'
                    />
                  </div>
                </div>
                <a
                  onClick={() => {
                    if (nazevOddeleni && nazevOddeleni.length > 0) {
                      dispatch(
                        govActions.addOddeleni({
                          uliceCisloPopisne: address.uliceCisloPopisne,
                          oddeleni: nazevOddeleni,
                        })
                      )
                      createOddeleniBackend({
                        address: address,
                        oddeleni: nazevOddeleni,
                      }).then(() => persistEmployeeDepartment(nazevOddeleni))
                    }
                    setShow('none')
                    setNazevOddeleni('')
                  }}
                  className='btn u-pl-2 u-size-base14 btn--secondary btn--small u-mb-2'
                >
                  <svg className='icon icon--24'>
                    <PlusIcon width={24} height={24} />
                  </svg>
                  {lg(lang, ADD)}
                </a>
              </div>
            )}
          </div>
        ))}
        <a
          onClick={() => {
            show === 'pridat_adresu'
              ? setShow('none')
              : setShow('pridat_adresu')
          }}
          className='btn u-pl-2 u-mb-4 u-size-base14 btn--primary btn--outlined btn--small'
        >
          <svg className='icon icon--24'>
            <GeoPinOutlineIcon width={24} height={24} />
          </svg>
          {lg(lang, NEW_DELIVERY_ADDRESS)}
        </a>
        {/* Přidat adresu - InLine - this one we see in EmployeeDetail */}
        {show === 'pridat_adresu' && (
          // <EmployeeDetailAddressesInLine id={id} setShow={setShow} />
          <EmployeeAddressesInline
            setShow={setShow}
            afterCreateAdresaBackend={(
              uliceCisloPopisneNaNoveAdrese,
              oddeleniNaNoveAdrese,
              pscNaNoveAdrese,
              employee
            ) => {
              changeZamestnanecBackend(
                id,
                employee!.name,
                employee!.surname,
                uliceCisloPopisneNaNoveAdrese,
                pscNaNoveAdrese!,
                oddeleniNaNoveAdrese || 'Žádné',
                employee!.paidMonths,
                employee!.deactivatedMonths
              )
              dispatch(
                tableActions.setUliceCisloPopisne({
                  id: id,
                  uliceCisloPopisne: uliceCisloPopisneNaNoveAdrese,
                })
              )
              dispatch(
                tableActions.setOddeleni({
                  id: id,
                  oddeleni: oddeleniNaNoveAdrese || 'Žádné',
                })
              )
            }}
          />
        )}
      </div>
    </div>
  )
}

export default EmployeeDetailAddresses
