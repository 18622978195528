import React from 'react'
import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'
import {
  equalsBezRozdiluVCisluOrientacnim,
  findMatchingAddress,
} from 'util/ValidFormatOps'
import {
  Address,
  BrokenAddress,
  InternalCompany,
  Login,
  Role,
  Spolecnost,
  TSMenu,
  User,
} from 'app/type'
import { sinking } from 'app/julesverne'

const initialState: {
  login: Login
  menuBar: TSMenu[]
  addresses: Address[]
  brokenAddresses: BrokenAddress[]
  users: User[]
  spolecnosti: Spolecnost[]
  groups: string[]
  beginningOfTime: Date // must be shared across users
  precious: string // One Ring to rule them all, One Ring to find them, One Ring to bring them all, and in the darkness bind them
  logoutUrl: string
  haveCalledAfterLogin: boolean
  haveCalledAfterInternal: boolean
  isCustomUser: boolean
  internalCompany: InternalCompany
} = {
  login: {
    email: '',
  },
  menuBar: [],
  addresses: [],
  brokenAddresses: [],
  users: [],
  spolecnosti: [],
  groups: [],
  beginningOfTime: new Date(),
  precious: '',
  logoutUrl: '',
  haveCalledAfterLogin: false,
  haveCalledAfterInternal: false,
  isCustomUser: false,
  internalCompany: {} as InternalCompany,
}

const governanceSlice = createSlice({
  name: 'gov',
  initialState,
  reducers: {
    reducerInternalCompany(state, action) {
      console.log('reducerInternalCompany', action.payload)
      const internalCompany = action.payload
      state.internalCompany = internalCompany
      console.log(
        'reducerInternalCompany state.internalCompany',
        state.internalCompany
      )
    },
    reducerPrecious: (state, action) => {
      sinking() && console.log('set precious reducer called', action.payload)
      state.precious = action.payload
    },
    reducerLogoutUrl: (state, action) => {
      sinking() && console.log('set logoutUrl reducer called', action.payload)
      state.logoutUrl = action.payload
    },
    reducerBeginningOfTime: (state, action) => {
      state.beginningOfTime = action.payload
    },
    reducerLogin(state, action) {
      state.login = action.payload
    },
    reducerMenuBar(state, action) {
      state.menuBar = action.payload
    },
    reducerIsCustomUser: (state, action) => {
      state.isCustomUser = action.payload
    },
    reducerHaveCalledAfterLogin: (state, action) => {
      state.haveCalledAfterLogin = action.payload
    },
    reducerHaveCalledAfterInternal: (state, action) => {
      state.haveCalledAfterInternal = action.payload
    },
    addAddress(state, action) {
      // add if doesn't exist, otherwise replace
      const addressOriginal: Address = action.payload
      const addressCopy = { ...addressOriginal }
      addressCopy.psc = +addressCopy.psc.toString().replace(/\s/g, '')
      const index = state.addresses.findIndex((a) =>
        // safe check
        equalsBezRozdiluVCisluOrientacnim(
          a.uliceCisloPopisne,
          addressCopy.uliceCisloPopisne
        )
      )
      if (index === -1) {
        state.addresses.push(addressCopy)
      } else {
        state.addresses[index] = addressCopy
      }
    },
    removeAddressByUliceCisloPopisne(state, action) {
      const uliceCisloPopisne = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      state.addresses.splice(index, 1)
    },
    addBrokenAddress(state, action) {
      if (!state.brokenAddresses) {
        state.brokenAddresses = []
      }
      // add if doesn't exist, otherwise replace
      const address: BrokenAddress = action.payload
      const index = state.brokenAddresses.findIndex(
        (a) =>
          a.street === address.street &&
          a.cisloPopisne === address.cisloPopisne &&
          a.psc === address.psc
      )
      if (index === -1) {
        state.brokenAddresses.push(address)
      } else {
        state.brokenAddresses[index] = address
      }
    },
    removeAddressFromListOfBrokenByUliceCisloPopisnePsc(state, action) {
      const { ulice, cisloPopisne, psc } = action.payload
      const index = state.brokenAddresses.findIndex(
        (a) =>
          a.street === ulice && a.cisloPopisne === cisloPopisne && a.psc === psc
      )
      console.log(
        'govActions.removeAddressFromListOfBrokenByUliceCisloPopisnePsc, ulice, cisloPopisne, psc, index',
        ulice,
        cisloPopisne,
        psc,
        index
      )
      state.brokenAddresses.splice(index, 1)
    },
    removeAddressFromListOfBrokenByUUID(state, action) {
      const { uuid } = action.payload
      const index = state.brokenAddresses.findIndex((a) => a.uuid === uuid)
      console.log(
        'govActions.removeAddressFromListOfBrokenByUUID, uuid, index',
        uuid,
        index
      )
      state.brokenAddresses.splice(index, 1)
    },
    clearBrokenAddresses(state) {
      state.brokenAddresses = []
    },
    removeAllAddresses(state) {
      state.addresses = []
    },
    removeAllUsers(state) {
      state.users = []
    },
    addOddeleni(state, action) {
      const { uliceCisloPopisne, oddeleni } = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      state.addresses[index].oddeleni.push(oddeleni)
    },
    removeOddeleni(state, action) {
      const { uliceCisloPopisne, oddeleni } = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      const oddeleniIndex = state.addresses[index].oddeleni.indexOf(oddeleni)
      state.addresses[index].oddeleni.splice(oddeleniIndex, 1)
    },
    renameOddeleni(state, action) {
      const { uliceCisloPopisne, indexOddeleni, newOddeleni } = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      state.addresses[index].oddeleni[indexOddeleni] = newOddeleni
    },
    removeEmptyOddeleni(state) {
      state.addresses.forEach((row) => {
        row.oddeleni = row.oddeleni.filter((oddeleni) => oddeleni !== '')
      })
    },
    updateAddress(state, action) {
      const { uliceCisloPopisne, newAddress } = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      console.log('updateAddress,', uliceCisloPopisne, newAddress, index)
      // state.addresses[index] = {
      //   nazevSpolecnosti: newAddress.nazevSpolecnosti,
      //   kontaktniOsoba: newAddress.kontaktniOsoba,
      //   uliceCisloPopisne: newAddress.uliceCisloPopisne,
      //   mesto: newAddress.mesto,
      //   psc: newAddress.psc,
      //   telefon: newAddress.telefon,
      //   oddeleni: state.addresses[index]?.oddeleni || ['Žádné'],
      //   predvolba: newAddress.predvolba,
      // }
      state.addresses[index] = newAddress
    },
    updateAddressByUUID(state, action) {
      const { uuid, newAddress } = action.payload
      const index = state.addresses.findIndex((row) => row.uuid === uuid)
      console.log('updateAddressUuid,', uuid, newAddress, index)
      state.addresses[index] = newAddress
    },
    replaceOddeleniOnAddress(state, action) {
      const { uliceCisloPopisne, oddeleni } = action.payload
      const matchingAddress = findMatchingAddress(
        uliceCisloPopisne,
        state.addresses
      ) as Address
      const index = state.addresses.findIndex(
        (row) => row.uliceCisloPopisne === matchingAddress.uliceCisloPopisne
      )
      state.addresses[index].oddeleni = oddeleni
    },
    addUser(state, action) {
      state.users.push(action.payload)
    },
    addUserField(state) {
      if (!state.users) {
        state.users = []
      }
    },
    removeUser(state, action) {
      const index = state.users.findIndex((row) => row.email === action.payload)
      state.users.splice(index, 1)
    },
    editUser(state, action) {
      const { email } = action.payload
      const index = state.users.findIndex((row) => row.email === email)
      state.users[index] = action.payload
    },
    addGroup(state, action) {
      if (!state.groups) {
        state.groups = []
      }
      if (!state.groups.includes(action.payload)) {
        state.groups.push(action.payload)
      } else {
        if (!['Nezařazení'].includes(action.payload)) {
          alert('Skupina již existuje')
        }
      }
    },
    removeGroup(state, action) {
      const index = state.groups.findIndex((row) => row === action.payload)
      state.groups.splice(index, 1)
    },
    renameGroup(state, action) {
      const { group, newName } = action.payload
      const index = state.groups.findIndex((row) => row === group)
      state.groups[index] = newName
    },
    removeAllGroups(state) {
      state.groups = ['Nezařazení']
    },
    moveGroup(state, action) {
      const { index, newIndex } = action.payload
      const temp = state.groups[index]
      state.groups[index] = state.groups[newIndex]
      state.groups[newIndex] = temp
    },
    sortGroups(state) {
      if (!state.groups || state.groups.length === 0) {
        state.groups = ['Nezařazení']
      }
      // sort groups and put 'Nezařazení' to the end
      state.groups.sort((a, b) => {
        if (a === 'Nezařazení') {
          return 1
        } else if (b === 'Nezařazení') {
          return -1
        } else {
          return a.localeCompare(b) // sort alphabetically
        }
      })
      // if 'Nezařazení' was not present, put it to the end
      if (state.groups.indexOf('Nezařazení') === -1) {
        state.groups.push('Nezařazení')
      }
    },
    setHlavniKontaktniOsoba(state, action) {
      for (const user of state.users) {
        if (user.email === action.payload) {
          user.role = Role.Hlavní
        } else if (user.role === Role.Hlavní) {
          user.role = Role.Aktivní
        }
      }
    },
    setUsers(state, action) {
      state.users = action.payload
    },
    reducerSpolecnosti(state, action) {
      state.spolecnosti = action.payload
    },
  },
})

export const govActions = governanceSlice.actions

export const selectInternalCompany = (state: RootState) =>
  state.gov.internalCompany
export const selectLogin = (state: RootState) => state.gov.login
export const selectJwtToken = (state: RootState) => state.gov.precious
export const selectIsCustomUser = (state: RootState) => state.gov.isCustomUser
export const selectIsAdmin = (state: RootState) => {
  const loggedInUser = state.gov.users.filter(
    (user) => user?.email === state.gov.login?.email
  )[0]
  return loggedInUser && loggedInUser.role === Role.Hlavní
}

export const selectUsers = (state: RootState) => state.gov.users
export const selectSpolecnosti = (state: RootState) => state.gov.spolecnosti

export const selectGroups = (state: RootState) => state.gov.groups
export const selectAddresses = (state: RootState) => state.gov.addresses
export const selectBrokenAddresses = (state: RootState) =>
  (state.gov.brokenAddresses || []) as BrokenAddress[]
export const selectAddressByUliceCisloPopisne =
  (uliceCisloPopisne: string) => (state: RootState) => {
    const address = findMatchingAddress(uliceCisloPopisne, state.gov.addresses)
    return address
  }

export default governanceSlice.reducer
