import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class CalendarIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'calendar-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 1a1 1 0 011 1v2h16V2a1 1 0 112 0v2h3a1 1 0 011 1v23a2 2 0 01-2 2H4a2 2 0 01-2-2V5a1 1 0 011-1h3V2a1 1 0 011-1zm-3 9v18h24V10H4zm2.5 6.5A1.5 1.5 0 018 15h4a1.5 1.5 0 010 3H8a1.5 1.5 0 01-1.5-1.5zM8 22a1.5 1.5 0 000 3h4a1.5 1.5 0 000-3H8zm10.5-5.5A1.5 1.5 0 0120 15h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5zM20 22a1.5 1.5 0 000 3h4a1.5 1.5 0 000-3h-4z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default CalendarIcon
