import { MIN_POCET_ZNAKU_HESLA } from './tuning'

export const MIGRACE_DAT = 'migrace-dat'
export const KOREKCE_DAT = 'korekce-dat'

export const RECON_EMAIL_COM = 'recon@email.com'
export const SODEXO_INFO_KLIENT_EMAIL = 'info.cz@pluxeegroup.com'

export const INTERNAL_ID = '9876543210' // used when waiting for an answer from the backend or as a proxy object when backend doesn't support our naming conventions
export const INTERNAL_IDX = 9876543210 // used when waiting for an answer from the backend or as a proxy object when backend doesn't support our naming conventions

export const MIN_POCET_SLOUPCU_DIC = 3 // Jméno, Příjmení, Ulice číslo popisné
export const UDAJ_JE_POVINNY = 'Údaj je povinný'
export const ZADEJTE_HODNOTU = 'Zadejte hodnotu'
export const NIZKY_POCET_ZAMESTNANCU =
  'Počet zaměstnanců nemůže být menší než 1'

export const NEPLATNE_JMENO = 'Neplatné jméno, nepoužívejte číslice'
export const NEPLATNE_PRIJMENI = 'Neplatné příjmení, nepoužívejte číslice'
export const NEPLATNY_EMAIL =
  'E-mail není ve správném formátu, použijte formát: karel@email.com'
export const NEPLATNE_TELEFONNI_CISLO =
  'Neplatný formát telefonního čísla, použijte formát +420 123 456 789'
export const NEPLATNE_ICO = 'Neplatný formát IČO, použijte 8 číslic'
export const NEPLATNE_PSC = 'Neplatný formát PSČ, použijte 5 číslic'
export const NEPLATNE_MESTO = 'Neplatný formát města, vzor Praha 2'
export const NEPLATNA_ADRESA = 'Neplatná adresa, vzor V Třehavě 123'
export const NEPLATNA_KONTAKTNI_OSOBA =
  'Neplatná kontaktní osoba, nepoužívejte číslice'
export const NEPLATNY_NAZEV_SPOLECNOSTI =
  'Neplatný název společnosti, minimální délka 2 znaky'
export const NEPLATNA_ULICE_CISLO_POPISNE =
  'Neplatná ulice, číslo popisné, vzor V Třehavě 123'
export const KRATKE_HESLO = `Heslo musí mít alespoň ${MIN_POCET_ZNAKU_HESLA} znaků`
export const ZADANE_EMAILOVE_ADRESY_SE_NESHODUJI =
  'Zadané e-mailové adresy se neshodují.'

export const SOUHLAS_SE_ZASILANIM_INFORMACI = `Souhlasím s použitím poskytnutých údajů za účelem zasílání
novinek a marketingových sdělení společnosti Pluxee Česká republika a.s. Svůj souhlas můžete odvolat kliknutím na
„odhlásit z odběru“.`
export const ZASADY_ZPRACOVANI_OSOBNICH_UDAJU = `Po odeslání formuláře zpracujeme Vaše osobní informace za
 účelem poskytování služeb a plnění smlouvy se společností Pluxee Česká republika a.s. Více informací
  o zpracování osobních údajů naleznete `

export const MAXIMALNI_POCET_ZAMESTNANCU = 100000
export const DEFAULT_ODPRACOVANYCH_DNU_V_MESICI = 20
export const DEFAULT_VOLNOCASOVY_KREDIT_NA_MESIC = 1000
export const DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU = 194
export const MIN_VOLNOCASOVY_KREDIT_NA_MESIC = 0

export const MEGABYTE = 1024 * 1024

export const SEGMENT_B_THRESHOLD = 80

export const GHOST_INDEX = -99
export const EMPTY_PSC = 10000 // shouldn't be used, but case it is, use this value ubiquitously

export const DIC_COLUMNS = [
  'Jméno',
  'Příjmení',
  'Číslo zaměstnance',
  'Volnočasový kredit',
  'Stravenkový kredit',
  'Název společnosti',
  'Ulice, č. popisné',
  'Město',
  'PSČ',
  'Kontaktní osoba',
  'Telefon',
]
export const DIC_COLUMNS_EN = [
  'First name',
  'Last name',
  'ID',
  'Leisure credit',
  'Gastro credit',
  'Company name',
  'Street, house number',
  'City',
  'ZIP',
  'Contact person',
  'Phone',
]
export const DIC_COLUMNS_SK = [
  'Meno',
  'Priezvisko',
  'Číslo zaměstnance',
  'Voľnočasový kredit',
  'Stravenkový kredit',
  'Názov spoločnosti',
  'Ulica, č. popisné',
  'Mesto',
  'PSČ',
  'Kontaktná osoba',
  'Telefón',
]

export const DOCUMENT_IN_PREPARATION = 'se připravuje. Zkuste znovu za chvíli.'
