import { FunctionComponent, useCallback, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import { urlDataImportTwo } from 'App'
import { useDropzone } from 'react-dropzone'
import SideMenu from 'components/modules/menu/SideMenu'
import { useAppDispatch, useAppSelector, useBodyClass } from 'app/hooks'
import { dataImportActions } from './dataImportSlice'
import { Navigate } from 'react-router'
import {
  uploadedFileName,
  reducerUploadedFileName,
  initUploadSettingsKeepColumns,
  selectUploadedFiles,
  reducerUploadedFiles,
} from './importSettingsSlice'
import { MEGABYTE as MB_to_Bytes } from 'app/constants'
import Help from 'components/modules/help/Help'
// @ts-ignore
import XlsxVzorovaSablonaTabulky from '@resources/dataImportVzor.xlsx'
import { ExcelFile } from 'app/type'
import { uploadExcel } from 'app/req'
import UploadedFilesHistory from './UploadedFilesHistory'
import { verne } from 'app/julesverne'

const DataImportOne: FunctionComponent = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()

  const userDataUploadedFileName = useAppSelector(uploadedFileName)
  const [fileName, setFileName] = useState(userDataUploadedFileName)
  // todo only save the uploaded file after the user has clicked the continue button or after he finished the import?
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)

  const userDataUploadedFiles =
    useAppSelector(selectUploadedFiles) || ([] as ExcelFile[])

  const [uploadedFiles, setUploadedFiles] = useState<ExcelFile[]>(
    userDataUploadedFiles
  )

  // lazy loading
  let read: any = null
  let utils: any = null
  import('xlsx').then((xlsx) => {
    read = xlsx.read
    utils = xlsx.utils
  })

  function s2ab(s: string) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const persistAndSaveFile = useCallback(
    (fileName: string, bytesFromStream: string) => {
      // first persist the file, get the db id and save the file in redux
      var ab = s2ab(bytesFromStream)
      var blob = new Blob([ab], {
        type: `${
          fileName.charAt(fileName.length - 1) === 'x'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'application/vnd.ms-excel'
        }`,
      })

      uploadExcel(fileName, blob).then((data) => {
        verne('persisted file to db, got back id: ', data.id)
        const newExcelFile: ExcelFile = {
          uuid: data.id,
          // name: fileName.replace(/\.[^/.]+$/, ''), // remove extension
          date: new Date(),
        }

        const newUploadedFiles = [newExcelFile, ...uploadedFiles].slice(
          0,
          3
        ) as ExcelFile[] // be careful to always have the files ordered by date descending
        setUploadedFiles([...newUploadedFiles])
        dispatch(reducerUploadedFiles([...newUploadedFiles]))
        verne('New Uploaded Files %O', newUploadedFiles)
      })
    },
    [dispatch]
  )

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.slice(0, 1).forEach((file: File) => {
      // we are losing the file reference here, to enable juvenile garbage collection.
      // the only reference remains to the bytesFromStream
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString // !! converts object to boolean
      // console.log(
      //   `Data from Excel is loaded as ${rABS ? 'binary string' : 'array'}`
      // )
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = (e) => {
        var bytesFromStream = e.target!.result as string
        // console.log('Byte stream', bytesFromStream) // this corresponds to the binary data of the file
        var workbook = read(bytesFromStream, {
          type: rABS ? 'binary' : 'array',
        })
        // console.log('Workbook', workbook)
        setUploadingState('success')
        setSheets(workbook.SheetNames)
        if (workbook.SheetNames.length === 1) {
          setActiveSheet(workbook.SheetNames[0])
        }
        setUploadedWorkBook(workbook)
        setFileName(file.name)
        setUploadedFile(file)
        dispatch(initUploadSettingsKeepColumns())
        // console.log('File', file)

        // todo delay the persistence of the file - it is not necessary to persist the file immediately
        // setUploadedFile(file)
        persistAndSaveFile(file.name, bytesFromStream)
      }
      if (rABS) reader.readAsBinaryString(file)
      else reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: `application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
      maxSize: 5 * MB_to_Bytes, // 5 MB
    })

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div>
      <span className='input__error' style={{ fontSize: '1rem' }}>
        <br />
        {/* @ts-ignore */}
        Vložili jste soubor {file.path}.
        <br />
        {/* what if file >5MB is uploaded? */}
        {errors[0].message.includes('File is larger than')
          ? `Velikost souboru je ${(file.size / MB_to_Bytes).toFixed(
              2
            )} MB a je příliš velká. Vložte soubor do 5MB.`
          : `Typ souboru není podporován. Vložte prosím soubor s příponou *.xls/.xlsx`}
      </span>
    </div>
  ))

  const [uploadingState, setUploadingState] = useState('idle')
  const [selectSheetActive, setSelectSheetActive] = useState(false)
  const [activeSheet, setActiveSheet] = useState('')
  const [sheets, setSheets] = useState([''])
  const [redirect, setRedirect] = useState(false)
  let uploadedWorkbook: any /* xlsx.WorkBook - lazy loading */ = {
    Sheets: {},
    SheetNames: [],
  }
  const [uploadedWorkBook, setUploadedWorkBook] = useState(uploadedWorkbook)

  function onProceed() {
    dispatch(
      reducerUploadedFileName(fileName + ' - ' + activeSheet.toLowerCase())
    )
    var jsonFromExcel = utils.sheet_to_json(
      uploadedWorkBook.Sheets[activeSheet],
      {
        raw: false,
        dateNF: 'DD.MM.YYYY',
        header: 1,
        defval: '',
      }
    )
    dispatch(dataImportActions.arrayOfArraysFromExcel(jsonFromExcel))
    dispatch(initUploadSettingsKeepColumns())
  }

  return (
    <div className='u-ml-6 u-px-2 u-pt-6 u-pb-4'>
      <div className='container container--wide u-mx-0'>
        <div className='💪'>
          <h1 className='u-size-l28 u-mb-2'>Import dat z Excelu</h1>
          <Help dataImport />
        </div>
        <div className='u-mb-4'>
          <p className='u-size-l20'>Vložení souboru s daty.</p>
        </div>
        <div className='grid-x'>
          <div className='cell large-8 u-mb-6 u-mb-large-0'>
            <div
              {...getRootProps({
                onClick: (event) => console.log(event),
              })}
              className='file-upload u-mb-3'
              style={{ overflow: 'visible' }}
            >
              <input
                {...getInputProps()}
                className='dropzone'
                accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              />
              <div id='dropzone' className='dropzone'>
                {/* dz-message has no effect, it's in system.js but not in system.css */}
                {uploadingState === 'idle' && (
                  <div className='dz-message'>
                    <div className='file-upload__body'>
                      <div>
                        <svg className='icon u-mb-1'>
                          <use href={icons + '#sprite-excel'}></use>
                        </svg>
                        {isDragActive ? (
                          <p className='u-size-l20'>Položte soubor zde ...</p>
                        ) : (
                          <p className='u-size-l20'>
                            Přetáhněte nebo{' '}
                            <u className='u-color-dark-purple-blue-alt'>
                              vyberte soubor z počítače.
                            </u>
                            <br />
                            Podporované typy souborů: .xls/.xlsx
                          </p>
                        )}
                      </div>
                    </div>
                    {fileRejectionItems}
                  </div>
                )}
                {uploadingState === 'success' && (
                  <div className='dz-message'>
                    <div className='file-upload__body'>
                      <div>
                        <p className='u-size-l20 u-mb-2'>
                          Soubor byl úspěšně nahrán.
                          <br />
                          <u className='u-color-dark-purple-blue-alt'>
                            {fileName}
                          </u>
                        </p>
                        <div className='u-mb-2'>
                          <div
                            onClick={(e) => {
                              if (sheets.length === 1 && activeSheet) {
                                e.stopPropagation()
                                return // do nothing
                              } else {
                                setSelectSheetActive(!selectSheetActive)
                                e.stopPropagation()
                              }
                            }}
                            className={
                              'select' +
                              (selectSheetActive ? ' select--active' : '')
                            }
                            style={{
                              minWidth: '187px',
                              zIndex: 100,
                            }}
                          >
                            <div className='select__option select__option--selected'>
                              <span>
                                {activeSheet
                                  ? activeSheet
                                  : 'Vyberte název listu'}
                              </span>
                              {sheets.length > 1 && (
                                <svg className='icon icon--24'>
                                  <use
                                    href={
                                      icons +
                                      (selectSheetActive
                                        ? '#sprite-sorting-up'
                                        : '#sprite-sorting-down')
                                    }
                                  ></use>
                                </svg>
                              )}
                            </div>
                            <div className='select__options'>
                              {sheets
                                .filter((sheet) => sheet !== activeSheet)
                                .map((sheet, index) => (
                                  <div
                                    key={index}
                                    className='select__option'
                                    onClick={(e) => {
                                      // dispatch(
                                      //   dataImportActions.setSheet(sheet)
                                      // )
                                      setActiveSheet(sheet)
                                      setSelectSheetActive(false)
                                      e.stopPropagation()
                                    }}
                                  >
                                    <span>{sheet}</span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setUploadingState('idle')
                          }}
                          className='button-as-anchor u-size-s u-color-red'
                        >
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-trash'}></use>
                          </svg>
                          Smazat soubor
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='file-upload__alert'></div>
            </div>
            {redirect && <Navigate to={urlDataImportTwo} />}
            <button
              className={
                'button-as-anchor btn u-mb-5 btn--primary btn--small' +
                (uploadingState === 'success' && activeSheet
                  ? ''
                  : ' btn--disabled')
              }
              onClick={() => {
                if (uploadingState === 'success' && activeSheet) {
                  onProceed()
                  setRedirect(true)
                }
              }}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-download'}></use>
              </svg>
              Uložit
            </button>
          </div>
          <div className='cell large-4 u-pl-large-3'>
            <div className='box u-mb-2 box--no-radius box--negative-margin'>
              <div className='box__content'>
                <p className='u-mb-2'>
                  <strong>Stáhněte si vzorový soubor pro import dat</strong>
                </p>
                <a href={XlsxVzorovaSablonaTabulky}>
                  <svg className='icon icon--24'>
                    <use href={icons + '#sprite-excel'}></use>
                  </svg>
                  Vzorová šablona tabulky
                </a>
              </div>
            </div>
            <UploadedFilesHistory uploadedFiles={uploadedFiles} />
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}
export default DataImportOne
