import React from 'react'
import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'

export interface Faq {
  title: string
  content: string
}

const initialState: Faq[] = [
  {
    title: 'Jak se zaregistrovat?',
    content:
      'Registrace je *jednoduchá*. Vyplňte registrační formulář a klikněte na tlačítko „Registrovat“. Poté se vám zobrazí potvrzovací email. Potvrďte registraci kliknutím na odkaz v emailu. Poté se můžete přihlásit.',
  },
  {
    title: 'Jak mohu změnit své heslo?',
    content:
      'Klikněte na „Můj účet“ v pravém horním rohu. V nabídce „Můj účet“ naleznete možnost „Změnit heslo“. V nabídce „Změnit heslo“ můžete změnit své heslo.',
  },
  {
    title: 'Jak mohu změnit své údaje?',
    content:
      'Klikněte na „Můj účet“ v pravém horním rohu. V nabídce „Můj účet“ naleznete možnost „Změnit údaje“. V nabídce „Změnit údaje“ můžete změnit své údaje.',
  },
  {
    title: 'Jak mohu změnit své fakturační údaje?',
    content:
      'Klikněte na „Můj účet“ v pravém horním rohu. V nabídce „Můj účet“ naleznete možnost „Změnit fakturační údaje“. V nabídce „Změnit fakturační údaje“ můžete změnit své fakturační údaje.',
  },
  {
    title: 'Jak mohu změnit své doručovací údaje?',
    content:
      'Klikněte na „Můj účet“ v pravém horním rohu. V nabídce „Můj účet“ naleznete možnost „Změnit doručovací údaje“. V nabídce „Změnit doručovací údaje“ můžete změnit své doručovací údaje.',
  },
]

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    addFaq(state, action) {
      const { title, content } = action.payload
      // push if not already in state
      if (!state.find((faq) => faq.title === title)) {
        state.push({ title, content })
        console.log(
          'tried to add faq, but already in state',
          state,
          title,
          content
        )
      } else {
        console.log('added a new faq', state, title, content)
      }
    },
    clearFaqs(state) {
      state.splice(0, state.length)
    },
    resetInitialState(state) {
      state.splice(0, state.length)
      state.push(...initialState)
    },
  },
})

export const faqActions = faqSlice.actions

export const selectFaqs = (state: RootState) => state.faq

export default faqSlice.reducer
