import React from 'react'
import icons from '@styles/dist/system/icons.svg'
import { urlInternalWeb } from 'App'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  govActions,
  selectInternalCompany,
} from 'components/settings/governanceSlice'
import { InternalCompany } from 'app/type'
import { isInternal } from 'util/authOps'
import { overviewActions } from 'components/table/overviewSlice'
import { tableActions } from 'components/table/tableSlice'
import { Navigate } from 'react-router-dom'

const JstePrihlaseniZa: React.FC = () => {
  const dispatch = useAppDispatch()

  const internalCompany: InternalCompany = useAppSelector(selectInternalCompany)

  const [redirect, setRedirect] = React.useState('')

  async function rmrfAndRedirectToInternalWeb() {
    await rmrf()
    // window.location.href = urlInternalWeb
    setRedirect('internalWeb')
  }

  async function rmrf() {
    dispatch(govActions.reducerInternalCompany(null))
    dispatch(govActions.removeAllGroups())
    dispatch(govActions.removeAllAddresses())
    dispatch(govActions.removeAllUsers())
    dispatch(govActions.reducerSpolecnosti([]))
    dispatch(tableActions.removeAllRowsFromTheTable())
    dispatch(tableActions.removeAllHistoricalOrders())
    dispatch(overviewActions.removeAllRowsFromTheTable())
  }

  return (
    <div
      className='jste-prihlaseni-za'
      style={{ visibility: internalCompany ? 'visible' : 'hidden' }}
    >
      {redirect === 'internalWeb' && <Navigate to={urlInternalWeb} />}
      {isInternal() && (
        <div className='cell medium-shrink u-text-right'>
          <div className='u-mb-2'>
            <div className='alert alert--info-light alert--visible'>
              <div className='alert__inner'>
                <div className='grid-x align-middle'>
                  <svg className='alert__icon icon icon--24'>
                    <use href={icons + '#sprite-info-circle-fill'}></use>
                  </svg>
                  <span
                    className='alert__text'
                    style={{
                      whiteSpace: 'pre-line',
                      textAlign: 'left',
                      // lineHeight: '1.4',
                    }}
                  >
                    <strong>Jste přihlášeni za {internalCompany?.nazev}</strong>
                    <br />
                    Č. smlouvy: {internalCompany?.cisloSmlouvy}, IČ:{' '}
                    {internalCompany?.ico}
                  </span>
                  {internalCompany && (
                    <button
                      onClick={rmrfAndRedirectToInternalWeb}
                      className='u-ml-2 button-as-anchor alert__text'
                    >
                      Odhlásit
                    </button>
                  )}
                  {/* <svg
                    onClick={rmrfAndRedirectToInternalWeb}
                    className='u-ml-2 icon icon--16'
                    cursor={'pointer'}
                  >
                    <use href={icons + '#sprite-close-circle-outline'}></use>
                  </svg> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default JstePrihlaseniZa
