export function kohoCehoZamestnancuSwitch(
  totalSelected: number,
  lang: string
): string {
  if (lang === 'en') {
    return totalSelected === 1 ? 'employee' : 'employees'
  } else if (lang === 'bl') {
    return totalSelected === 1 ? 'служител' : 'служители'
  } else {
    switch (totalSelected) {
      case 0:
        return 'zaměstnanců'
      case 1:
      case 2:
      case 3:
      case 4:
        return 'zaměstnance'
      default:
        return 'zaměstnanců'
    }
  }
}
export function kohoCehoKusuSwitch(totalSelected: number): string {
  switch (totalSelected) {
    case 0:
      return 'kusů'
    case 1:
      return 'kus'
    case 2:
    case 3:
    case 4:
      return 'kusy'
    default:
      return 'kusů'
  }
}

export function kohoCoKartuSwitch(totalSelected: number, lang: string): string {
  if (lang === 'en') {
    return totalSelected === 1 ? 'card' : 'cards'
  } else if (lang === 'bl') {
    return totalSelected === 1 ? 'карта' : 'карты'
  } else {
    switch (totalSelected) {
      case 0:
        return 'karet'
      case 1:
        return 'kartu'
      case 2:
      case 3:
      case 4:
        return 'karty'
      default:
        return 'karet'
    }
  }
}
