import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class CheckCircleIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'check-circle-icon',
    width: 24,
    height: 24,
    fill: 'currentColor',
  }

  render() {
    const { className, width, height, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zm-10.548-5.558c.59-.612 1.518-.566 2.117 0 .595.566.554 1.62 0 2.194l-1.055 1.093-4.64 4.807-2.914 3.02a1.483 1.483 0 01-2.117 0c-.09-.096-.181-.19-.272-.284-.09-.094-.181-.188-.27-.283l-3.873-4.023c-.591-.617-.55-1.574 0-2.194.546-.617 1.564-.574 2.118 0l.27.281.272.285 2.815 2.925 4.634-4.801 2.915-3.02z'
          fill={fill}
        />
      </svg>
    )
  }
}

export default CheckCircleIcon
