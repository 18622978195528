import React, { FunctionComponent, useEffect, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import Logo from '@styles/image/data-import-4-logo.svg'
import SideMenu from 'components/modules/menu/SideMenu'
import { useAppDispatch, useAppSelector, useBodyClass } from 'app/hooks'
import { urlDataImportFive, urlDataImportThree } from 'App'
import { ExcelRow } from './dataImportSlice'
import ImportHeader from './ImportHeader'
import { Navigate } from 'react-router-dom'
import {
  activeImportRow,
  activeImportColumn,
  uploadedFileName,
  resetColumns,
  reducerAssignColumn,
} from './importSettingsSlice'
import {
  DIC_COLUMNS,
  DIC_COLUMNS_EN,
  DIC_COLUMNS_SK,
  MIN_POCET_SLOUPCU_DIC,
} from 'app/constants'
import { equalsBezDiakritiky } from 'util/ValidFormatOps'

const DataImportFour: FunctionComponent = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()
  const redux = useAppSelector((state) => state.dataImport)
  const importSettings = useAppSelector((state) => state.importSettings)
  const activeRow = useAppSelector(activeImportRow)
  const activeColumn = useAppSelector(activeImportColumn)
  const fileName = useAppSelector(uploadedFileName)
  const sloupceDat = redux[0] as unknown as string[]
  const pocetSloupcuDat = sloupceDat.length - activeColumn

  const [popover, setPopover] = useState(-1)
  interface Tuple {
    index: number
    column: string
  }
  const [selectedColumns, setSelectedColumns] = useState<Array<Tuple>>([])

  function onSelect(index: number, column: string) {
    // remove entries containing the same index or same column
    let newArray = selectedColumns.filter((item) => {
      return item.index !== index && item.column !== column
    })
    // add new entry
    newArray.push({ index, column })
    setSelectedColumns(newArray)
    setPopover(-1) // close the select
  }

  const [redirect, setRedirect] = React.useState(false)

  useEffect(() => {
    // hm? probably has its justification... -> caused us infinite loop when kredit columns were missing
    // if (pocetSloupcuDat < DIC_COLUMNS.length) {
    //   dispatch(resetColumns())
    // }

    // this variant is to remove all settings on page reload or zpět button
    // not wanted by user
    // dispatch(reducerJmenoColumn(-1))
    // dispatch(reducerPrijmeniColumn(-1))
    // dispatch(reducerCisloZamestnanceColumn(-1))
    // dispatch(reducerVolnocasovyKreditColumn(-1))
    // dispatch(reducerstravenkovyKreditColumn(-1))
    // dispatch(reducerNazevSpolecnostiColumn(-1))
    // dispatch(reducerKontaktniOsobaColumn(-1))
    // dispatch(reducerTelefonColumn(-1))
    // dispatch(reducerUliceCisloPopisneColumn(-1))
    // dispatch(reducerMestoColumn(-1))
    // dispatch(reducerPscColumn(-1))

    // the following variant is to keep all settings through reloads and on zpět button
    // wanted by user
    // 1. if there are previous settings in redux, load and put them into state
    const array: Tuple[] = []
    if (importSettings.jmenoColumn !== -1) {
      array.push({
        index: importSettings.jmenoColumn,
        column: DIC_COLUMNS[0],
      })
    }
    if (importSettings.prijmeniColumn !== -1) {
      array.push({
        index: importSettings.prijmeniColumn,
        column: DIC_COLUMNS[1],
      })
    }
    if (importSettings.cisloZamestnanceColumn !== -1) {
      array.push({
        index: importSettings.cisloZamestnanceColumn,
        column: DIC_COLUMNS[2],
      })
    }
    if (importSettings.volnocasovyKreditColumn !== -1) {
      array.push({
        index: importSettings.volnocasovyKreditColumn,
        column: DIC_COLUMNS[3],
      })
    }
    if (importSettings.stravenkovyKreditColumn !== -1) {
      array.push({
        index: importSettings.stravenkovyKreditColumn,
        column: DIC_COLUMNS[4],
      })
    }
    if (importSettings.nazevSpolecnostiColumn !== -1) {
      array.push({
        index: importSettings.nazevSpolecnostiColumn,
        column: DIC_COLUMNS[5],
      })
    }
    if (importSettings.uliceCisloPopisneColumn !== -1) {
      array.push({
        index: importSettings.uliceCisloPopisneColumn,
        column: DIC_COLUMNS[6],
      })
    }
    if (importSettings.mestoColumn !== -1) {
      array.push({
        index: importSettings.mestoColumn,
        column: DIC_COLUMNS[7],
      })
    }
    if (importSettings.pscColumn !== -1) {
      array.push({
        index: importSettings.pscColumn,
        column: DIC_COLUMNS[8],
      })
    }
    if (importSettings.kontaktniOsobaColumn !== -1) {
      array.push({
        index: importSettings.kontaktniOsobaColumn,
        column: DIC_COLUMNS[9],
      })
    }
    if (importSettings.telefonColumn !== -1) {
      array.push({
        index: importSettings.telefonColumn,
        column: DIC_COLUMNS[10],
      })
    }
    // Artificial Intelligence matching.
    // The order is safe:
    // - the previous code fills array for indexes that aren't -1;
    // - here we fill -1 indexes, followingly:
    // 0. If NO column has been set yet,
    // 1. If the column was not set yet,
    // 2. add to the array which will have effect on UI/useState
    // 3. add the actual column index to the redux store
    let noColumnSetYet = true
    // We actually support less columns than DIC_COLUMNS and this approach works!
    for (let i = 0; i < DIC_COLUMNS.length; i++) {
      if (array.find((item) => item.column === DIC_COLUMNS[i])) {
        noColumnSetYet = false
      }
    }
    if (noColumnSetYet) {
      for (let i = 0; i < sloupceDat.length; i++) {
        // if a column with this index is already set, skip it
        if (!array.find((e) => e.index === i)) {
          // try matching the column name from file with DIC
          for (let j = 0; j < DIC_COLUMNS.length; j++) {
            if (
              equalsBezDiakritiky(sloupceDat[i], DIC_COLUMNS[j]) ||
              equalsBezDiakritiky(sloupceDat[i], DIC_COLUMNS_SK[j]) ||
              equalsBezDiakritiky(sloupceDat[i], DIC_COLUMNS_EN[j])
            ) {
              array.push({
                index: i,
                column: DIC_COLUMNS[j],
              })
              dispatch(
                reducerAssignColumn({ index: i, column: DIC_COLUMNS[j] })
              )
              break
            }
          }
        }
      }
    }

    setSelectedColumns(array)
  }, [
    importSettings.cisloZamestnanceColumn,
    importSettings.jmenoColumn,
    importSettings.prijmeniColumn,
    importSettings.volnocasovyKreditColumn,
    importSettings.stravenkovyKreditColumn,
    importSettings.nazevSpolecnostiColumn,
    importSettings.kontaktniOsobaColumn,
    importSettings.telefonColumn,
    importSettings.uliceCisloPopisneColumn,
    importSettings.mestoColumn,
    importSettings.pscColumn,
    pocetSloupcuDat,
    dispatch,
  ])

  return (
    <div>
      {redirect && <Navigate to={urlDataImportFive} />}
      <div className='table__import'>
        <div className='grid-x align-justify align-bottom u-mb-3'>
          <div className='grid-x align-middle u-mb-1'>
            <div>
              <img src={Logo} alt='logo' width='auto' className='u-mr-3' />
            </div>
            <div>
              <h1 className='u-size-l28 u-mb-2'>Přiřazení dat</h1>
              {/* We actually made it smart so that it supports less columns (3). */}
              {pocetSloupcuDat >= MIN_POCET_SLOUPCU_DIC ? (
                <p className='u-size-l20'>
                  Vyberte hodnotu z nabídky odpovídající datům v tabulce.
                  {/* <ul>
                    <li>
                      Není-li ve vaší tabulce dostatečný počet sloupců:
                      <ul>
                        <li>
                          Vyberte všechny sloupce, které chcete importovat.
                        </li>
                        <li>
                          Sloupce Jméno, Příjmení, Ulice, č.p. jsou nezbytné.
                        </li>
                      </ul>
                    </li>
                    
                    <li>
                      Každý zaměstnanec musí mít v pořádku údaj 'Ulice, č.p.'. V
                      opačném případě Vám nabídneme doporučenou korekci adresy,
                      existuje-li již zamestnanec v naší db. S nesprávnou
                      adresou nebudete moci dokončit import dat.
                    </li>
                    <li>
                      Pokud má v pořádku údaj 'Ulice, č.p.', ale chybí správně
                      vyplněné údaje 'Název společnosti, Město, PSČ, Kontaktní
                      osoba, Telefon,' budete vyzváni v posledním kroku k jejich
                      doplnění.
                    </li>
                    <li>
                      U zaměstnanců, kteří neexistují v naší db a zároveň nemají
                      Ulici, č.p. ve správném formátu (nebo vůbec), dostanete
                      možnost vytvoření nové adresy. Tu můžete aplikovat
                      jednotně na všechny takové zaměstnance.
                    </li>
                  </ul> */}
                  {/* <li>
                      Údaje Ulice, č.p., Název společnosti, Město, PSČ,
                      Kontaktní osoba a Telefon můžete nastavit v dalším kroku.
                    </li> */}
                  {/* <li>
                      Neúplná adresa (např. chybějící PSČ) není podporována.
                      Bude učiněn pokus dohledat adresu podle Ulice, č.p. dle
                      Vámi dříve zadaných údajů. V případě selhání budete
                      vyzváni k vytvoření nové adresy v dalším kroku.
                    </li> */}
                </p>
              ) : (
                <p className='u-size-l20 input__error'>
                  {`Vaši tabulku není možné naimportovat.
                  Počet sloupců s daty ve vaší tabulce musí být alespoň 3.
                  Sloupce musí obsahovat:`}
                  <br />
                  {`jméno, příjmení, ulice, č. popisné.`}
                </p>
              )}
            </div>
          </div>
          <div className='u-mb-1'>
            <div className='alert alert--warning alert--visible'>
              <div className='alert__inner'>
                <div className='grid-x align-middle'>
                  <span className='alert__text'>
                    Prázdné sloupce nebudou importovány
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='table__overflow'
          style={{ overflowY: 'visible', overflowX: 'scroll' }}
        >
          <div className='box box--small-radius box--no-padding box--transparent u-mb-9'>
            <div
              className='box__content'
              style={{
                overflow: 'visible',
                borderTop: '0px',
              }}
            >
              <div
                role='table'
                aria-label='Spárování sloupců'
                className='table table--compact'
              >
                {/* HEADER */}
                <div role='row' className='table__row'>
                  <div
                    className='table__cell table__cell--small u-bg-white'
                    style={{
                      borderTop: '1px solid rgba(212, 212, 222, 0.75)',
                      borderTopLeftRadius: '16px',
                    }}
                  >
                    &nbsp;
                  </div>
                  {Array(...Array(pocetSloupcuDat)).map((_, i) => (
                    <ImportHeader
                      key={i}
                      index={i}
                      popover={popover}
                      setPopover={setPopover}
                      onSelect={onSelect}
                      selectedColumns={selectedColumns}
                    />
                  ))}
                </div>
                {/* BODY */}
                {redux.slice(activeRow).map((row: ExcelRow, i) => (
                  <div key={i} role='row' className='table__row'>
                    <div
                      className={'table__cell table__cell--small u-bg-white'}
                      style={{
                        borderBottomLeftRadius:
                          i === redux.length - 1 ? '16px' : '0px',
                      }}
                    >
                      <span>{i + 1}</span>
                    </div>
                    {/* @ts-ignore */}
                    {row.slice(activeColumn).map((cell, j) => (
                      <div
                        key={j}
                        className={
                          'table__cell table__cell--fixed u-text-ellipsis' +
                          (selectedColumns.filter((tuple) => tuple.index === j)
                            .length > 0
                            ? i === 0
                              ? ' u-bg-new-yellow-35'
                              : ' u-bg-aquamarine-20'
                            : '')
                        }
                      >
                        <span>{cell}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='u-fixed-bottom u-mb-4 u-px-3'>
        <div className='grid-x align-justify u-ml-5 u-mr-3'>
          <div className='grid-x align-middle'>
            <svg className='icon u-mr-2'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>
            <i>{fileName}</i>
          </div>
          <div className='grid-x align-middle'>
            <a href={urlDataImportThree} className='u-mr-3'>
              Zpět
            </a>
            <button
              className={
                'btn u-px-2 btn--primary btn--small' +
                (selectedColumns.length < MIN_POCET_SLOUPCU_DIC
                  ? ' btn--disabled'
                  : '')
              }
              disabled={selectedColumns.length < MIN_POCET_SLOUPCU_DIC}
              onClick={() => setRedirect(true)}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-check'}></use>
              </svg>
              Pokračovat
            </button>
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}
export default DataImportFour
