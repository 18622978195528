import React, { useEffect, useRef } from 'react'
import { useAppSelector, useOnClickOutside } from 'app/hooks'
import { useDispatch } from 'react-redux'
import Logo from '@styles/image/pluxee-logo.svg'
import CzechFlag from '@styles/image/czech-flag.svg'
import EnglishFlag from '@styles/image/english-flag.svg'
import BulgarianFlag from '@styles/image/bulgarian-flag.svg'
import ProfilePerson from '@styles/image/profile-person.svg'
import houseImage from '@styles/image/sodexo-house.svg'
import {
  selectIsAdmin,
  govActions,
  selectSpolecnosti,
  selectLogin,
  selectIsCustomUser,
  selectInternalCompany,
  selectUsers,
} from 'components/settings/governanceSlice'
import {
  urlKatalogProvozoven,
  urlLogoutNeprihlaseny,
  urlNastaveni,
  urlInternalLogin,
  urlSelfcareTable,
  beLogoutInternal,
} from 'App'
import { getPrecious, loggedIn, setLogoutUrl, setPrecious } from 'app/back'
import Color from 'app/colors'
import {
  selectIcoFirmy,
  selectNazevSpolecnosti,
} from 'components/onboarding/features/firemniUdajeSlice'
import {
  getAndLog,
  logout,
  placeNewJwtInUrl,
  sendAndLog,
  switchSpolecnost,
} from 'app/req'

import { tableActions } from 'components/table/tableSlice'
import { overviewActions } from 'components/table/overviewSlice'
import { resetVisitedLocation } from 'components/onboarding/features/stepperSlice'
import { onClickUrl } from 'util/OpenInNewTab'
import { InternalCompany, Login, TSMenu, User } from 'app/type'
import { verne } from 'app/julesverne'
import { clearFlags, lg } from 'app/facade'
import { CATALOG_OF_ESTABLISHMENTS, WHERE_TO_SHOP } from 'app/constDictionary'
import { getCurrentUser, isInternal } from 'util/authOps'
import {
  reducerSetLanguage,
  selectCerstveProselOnboardingem,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import { useNavigate } from 'react-router-dom'
import { JwtUserPayload, isJwtExpired, parseJwtUser } from 'app/cryptoOps'
import MultiLevelMenu from "./MultiLevelMenu";

const TopNavBar: React.FC = () => {
  verne('TopNavBar#39')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const cerstveProselOnboardingem = useAppSelector(
    selectCerstveProselOnboardingem
  )

  const isMultiLanguageEnabled = false

  // todo: might replace this with facade.getCurrentUserUnsafe()
  const login = useAppSelector(selectLogin) as Login
  verne('TopNavBar, login', login)
  const users = useAppSelector(selectUsers) as User[]
  verne('TopNavBar#44', users)
  const user = getCurrentUser(users, login)
  verne('user', user)

  const isLoggedIn = loggedIn()
  const isAdmin = useAppSelector(selectIsAdmin) // todo this returns undefined -> probably bugged
  const isCustomUser = useAppSelector(selectIsCustomUser)
  // verne('TopNavBar#41', isLoggedIn, isAdmin, isCustomUser)

  const menuBar: TSMenu[] = useAppSelector((state) => state.gov.menuBar)
  // toto menu není pro custom usery, viz dřívější check "isCustomUser ? 'LOGIN' : 'USER'"
  const menuUser: TSMenu | null = menuBar
    ? (menuBar.find((item) => item.code === 'USER') as TSMenu)
    : null
  const menuPrihlaseniProCustomTokeny: TSMenu | null = menuBar
    ? (menuBar.find((item) => item.code === 'LOGIN') as TSMenu)
    : null
  const menuItemLogout: TSMenu | null = menuUser
    ? (menuUser.items!.find((item) => item.code === 'LOGOUT') as TSMenu)
    : menuPrihlaseniProCustomTokeny || null

  if (menuItemLogout) {
    setLogoutUrl(menuItemLogout.url || '')
  } else {
    setLogoutUrl(urlLogoutNeprihlaseny)
  }

  // verne('TopNavBar#43 %O', menuBar)

  const menuSodexo: TSMenu | null = menuBar
      ? (menuBar.find((item) => item.code === 'SODEXO') as TSMenu)
      : null
  const menuMain: TSMenu | null = menuBar
    ? (menuBar.find((item) => item.code === 'MAIN') as TSMenu)
    : null
  const menuItemMujPrehled: TSMenu | null = menuMain
    ? (menuMain.items!.find((item) => item.code === 'EBP') as TSMenu)
    : null
  const menuItemMujPass: TSMenu | null = menuMain
    ? (menuMain.items!.find((item) => item.code === 'MUJPASS') as TSMenu)
    : null
  const menuItemOthers: TSMenu | null = menuMain
    ? (menuMain.items!.find((item) => item.code === 'OTHERS') as TSMenu)
    : null

  // verne('TopNavBar#43 %O', menuItemMujPrehled)
  // verne('TopNavBar#43 %O', menuItemMujPass)

  const menuItemNastaveniUzivateleUrl =
    menuBar && menuUser
      ? isCustomUser
        ? menuUser.url // plochá struktura pro custom uživatele
        : ((menuUser.items as TSMenu[])[0] as TSMenu).url // produkční verze
      : ''

  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const icoFirmy = useAppSelector(selectIcoFirmy)
  const supplementarySpolecnost = {
    ico: icoFirmy,
    nazev: nazevSpolecnosti,
    aktivni: true,
  }

  const spolecnosti = useAppSelector(selectSpolecnosti)
  if (!spolecnosti || spolecnosti.length === 0) {
    dispatch(govActions.reducerSpolecnosti([supplementarySpolecnost]))
  }
  const aktivniSpolecnost = spolecnosti
    ? spolecnosti.filter((s) => s.aktivni)[0]
    : supplementarySpolecnost

  const internalCompany: InternalCompany = useAppSelector(selectInternalCompany)
  verne('TopNavBar Internal Company', internalCompany)

  async function rmrf(pojistka: string) {
    if (pojistka !== '/') return
    setPrecious('')
    dispatch(govActions.removeAllGroups())
    dispatch(govActions.removeAllAddresses())
    dispatch(govActions.removeAllUsers())
    dispatch(govActions.reducerSpolecnosti([]))
    dispatch(govActions.reducerLogin(null))
    dispatch(tableActions.removeAllRowsFromTheTable())
    dispatch(tableActions.removeAllHistoricalOrders())
    dispatch(overviewActions.removeAllRowsFromTheTable())
  }

  function logoutAndTerminateProlonging() {
    rmrf('/')
    dispatch(resetVisitedLocation())
    console.log('logoutAndKillWorker - reseting visited location')
    logout()
  }

  async function logoutInternal() {
    await sendAndLog(beLogoutInternal, '')
      .then(() => rmrf('/'))
      .then(() => {
        dispatch(govActions.reducerInternalCompany(null))
        console.log('logoutInternal - token from redux:', getPrecious())
        navigate(urlInternalLogin)
      })
  }

  const [othersDropdown, setOthersDropDown] = React.useState(false)
  const [profileDropdown, setProfileDropdown] = React.useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const profileRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useOnClickOutside(profileRef, () => {
    setProfileDropdown(false)
    setOthersDropDown(false)
  })

  const [redirectMujUcet, setRedirectMujPrehled] = React.useState(false)

  let urlBackend = '' // receives it later from TopNavBar
  const prolong = '/jwt/prolong'

  async function sendProlongPrecious(precious: string): Promise<string> {
    const jwt = await fetch(urlBackend + prolong, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${precious}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        console.log('JwtProlong, response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log(
          data.jwt
            ? `JwtProlong, data: jwt token received, not logging the contents for security reasons`
            : `JwtProlong, data - logging the contents because no jwt was received: ${data}`
        )
        return data.jwt
      })
      .catch((error) => console.log(error))

    if (jwt) {
      console.log(
        'JwtProlong, jwt received, setting it to precious directly from JwtProlong, with an indisputable context access.'
      )
      setPrecious(jwt) // not sure it will work from worker, probably not... now, from TopNavBar, yes.
    }
    return jwt
  }

  async function prolongPrecious(
    precious: string,
    jwtDurationInMinutes: number,
    jwtDurationSafetyMarginInMinutes: number
  ) {
    try {
      console.log(
        'JwtProlong, jwtDurationInMinutes - jwtDurationSafetyMarginInMinutes ' +
          jwtDurationInMinutes +
          ' - ' +
          jwtDurationSafetyMarginInMinutes +
          ' interval elapsed, JwtProlong is prolonging our precious'
      )
      return sendProlongPrecious(precious)
    } catch (error) {
      console.error(error)
    }
  }

  async function jwtValidityCheckAndProlong(precious: string): Promise<string> {
    try {
      const parsedJwt = parseJwtUser(precious) as JwtUserPayload
      const expiration = parsedJwt.exp
      const now = new Date().getTime()
      const difference = expiration * 1000 - now

      console.log(
        `JwtProlong, expiration in: ${Math.floor(difference / 60000)} minutes.`
      )

      if (isJwtExpired(precious)) {
        console.warn(
          `JwtProlong, precious has expired, you will be logged out briefly...`
        )
        logoutAndTerminateProlonging()
        return ''
      } else {
        return sendProlongPrecious(precious)
      }
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  let oneDegreeOfFreedomFromStoppingTheJwtChecks = true
  // todo rewrite to measure the actual time of expiration of the token against the world time. the user could have closed the laptop lid and come back after 60 mins with the token expired.
  function setupProlonging(jwt: string) {
    // compute the duration of the jwt token

    // setInterval(async function () {
    //   const jwtParts = jwt.split('.')
    //   const jwtPayload = JSON.parse(atob(jwtParts[1]))
    //   const jwtDuration = jwtPayload.exp - jwtPayload.iat
    //   const jwtDurationInMinutes = Math.floor(jwtDuration / 60)
    //   const jwtDurationSafetyMarginInMinutes = 3
    //   console.log('JwtProlong, jwtDuration: ', jwtDurationInMinutes, ' minutes')

    //   let prolonged = new Date()

    //   prolongPrecious(
    //     jwt,
    //     jwtDurationInMinutes,
    //     jwtDurationSafetyMarginInMinutes
    //   ).then((jwt) => {
    //     if (jwt) {
    //       console.debug(
    //         'JwtProlong, jwt received and prolonged, updating the "prolonged" variable'
    //       )
    //       prolonged = new Date()
    //     }
    //   })
    // }, Math.max(jwtDurationInMinutes - jwtDurationSafetyMarginInMinutes, 1) *
    //   60 *
    //   1000)
    setTimeout(async function () {
      if (jwt)
        jwtValidityCheckAndProlong(jwt).then((newJwt) => {
          setupProlonging(newJwt)
        })
      else if (oneDegreeOfFreedomFromStoppingTheJwtChecks) {
        jwtValidityCheckAndProlong(jwt).then((newJwt) => {
          setupProlonging(newJwt)
        })
        oneDegreeOfFreedomFromStoppingTheJwtChecks = false
      }
    }, 60 * 1000)
  }

  useEffect(() => {
    if (isLoggedIn) {
      const precious = getPrecious()
      setupProlonging(precious)
      console.log(
        'JwtProlong, setupProlonging for a logged in user, precious: ',
        precious
      )
    } else {
      setTimeout(() => {
        const precious = getPrecious()
        setupProlonging(precious)
        console.log(
          'JwtProlong, setupProlonging for a non-logged in user (this is a bug branch), precious: ',
          precious
        )
      }, 2000)
    }
  }, [isLoggedIn])

  return (
    <div className='topbar' id='topbar'>
      <a
        className='topbar__logo'
        href={menuSodexo ? menuSodexo.url : '/'}
      >
        <img src={Logo} alt='Logo' />
      </a>

      {isLoggedIn ? (
        <nav className='topbar__nav has-smooth-scroll' id='topbarNav'>
          {menuMain?.items?.map(({url, name, code, items}) => (
              <div className='topbar__nav-item' key={code}>
                {!items && url && (
                    <a
                      href={url}
                      className='topbar__nav-link button-as-anchor'
                    >
                      {code === 'EBP' && (
                          <img src={houseImage} alt='house' className='topbar__nav-icon u-mr-1' width={15}/>
                      )}
                     {name}
                   </a>
                )}
                {items && name && (
                    <MultiLevelMenu name={name} items={items} />
                )}
              </div>
          ))}
          {/* todo: the code for menu 'others' is below this component's source code. */}
        </nav>
      ) : (
        <nav className='topbar__nav has-smooth-scroll' id='topbarNav'>
          <div className='topbar__nav-item' id='div-header-MUJPASS'>
            <button
              onClick={onClickUrl(urlKatalogProvozoven)}
              id='header-MUJPASS'
              className='topbar__nav-link button-as-anchor'
            >
              {lg(lang, WHERE_TO_SHOP)}
            </button>
          </div>
        </nav>
      )}
      {isMultiLanguageEnabled && (
        <div className='language-selector'>
          <img
            src={CzechFlag}
            onClick={() => dispatch(reducerSetLanguage('cz'))}
          />
          <img
            src={EnglishFlag}
            onClick={() => dispatch(reducerSetLanguage('en'))}
          />
          <img
            src={BulgarianFlag}
            onClick={() => dispatch(reducerSetLanguage('bl'))}
          />
        </div>
      )}
      {isLoggedIn ? (
        <div className='topbar__profile'>
          <div
            className={
              'topbar__profile-picture' +
              (isAdmin ? ' topbar__profile-picture--admin' : '')
            }
            style={{
              backgroundImage: `url(${ProfilePerson})`,
              backgroundColor: Color.GREYOLD,
            }}
          >
            <button
              onClick={() => {
                setProfileDropdown(!profileDropdown)
                setOthersDropDown(false)
              }}
              className='modal__trigger topbar__profile-picture-link button-as-anchor'
            ></button>
            {profileDropdown && (
              <nav className='menu menu--profile is-hidden' ref={profileRef}>
                {menuUser && menuUser.items ? (
                  <>
                    {menuUser.items.map(({url, name, code}) => (
                        <a href={url} className={`menu__item ${code === 'LOGOUT' ? 'menu__item--logout' : ''}`} key={code}>
                            {name}
                        </a>
                    ))}
                  </>
                ) : (
                    <button
                      onClick={() =>
                      isInternal()
                      ? logoutInternal()
                      : logoutAndTerminateProlonging()
                      }
                      className='menu__item menu__item--logout button-as-anchor u-mb-1'
                    style={{
                      marginLeft: isLoggedIn ? '' : '46px',
                    }}
                    >
                      Odhlásit
                    </button>
                )}

                {/* {loggedInThroughTS && (
                  <button
                    onClick={() => {
                      instance.postMessage({ precious: getPrecious() })
                      console.log('Budiž Ti prodloužen token.')
                      setProfileDropdown(!profileDropdown)
                    }}
                    className='button-as-anchor menu__item'
                  >
                    Prodloužit token!
                  </button>
                )} */}
                {/* todo: re-enable přepnout firmu. */}
                <div
                  className='menu__secondary-container'
                  // style={{
                  //   marginLeft: loggedInThroughTS ? '10px' : '50px',
                  //   paddingLeft: 20,
                  // }}
                >
                  {!isInternal() && !cerstveProselOnboardingem && (
                    <>
                      <button className='button-as-anchor menu__item menu__item--has-secondary'>
                        Přepnout firmu
                      </button>
                      {spolecnosti.map((spolecnost) => (
                        <a
                          onClick={() => {
                            switchSpolecnost(spolecnost.ico)
                            clearFlags()
                            // without the timeout it doesn't work properly.
                            setTimeout(
                              // do not use navigate() here, it will cause a bug. It will not trigger /afterLogin.
                              () => (window.location.href = urlSelfcareTable),
                              500
                            )
                          }}
                          className={`menu__item menu__item--secondary ${
                            spolecnost.aktivni && 'u-bg-aquamarine'
                          }`}
                          style={{ cursor: 'pointer' }}
                        >
                          {spolecnost.nazev}
                        </a>
                      ))}
                    </>
                  )}
                </div>
              </nav>
            )}
          </div>
          <div className='topbar__profile-meta'>
            <div className='topbar__profile-name'>
              {user.jmeno} {user.prijmeni}
            </div>
            {!(isInternal() && !internalCompany) && (
              <div className='topbar__profile-company'>
                {aktivniSpolecnost
                  ? aktivniSpolecnost.nazev
                  : 'Společnosti uživatele nenačteny'}
              </div>
            )}
          </div>
        </div>
      ) : (
        window.location.href.includes('/objednavka') && (
          <div className='topbar__profile topbar__profile--link js-topbar-login-link'>
            <div className='topbar__profile-login'></div>
            <div className='topbar__profile-name' id='header-login-right'>
              <button
                onClick={() => navigate(urlLogoutNeprihlaseny)}
                id='login-link-right'
                className='topbar__nav-link button-as-anchor'
              >
                Přihlášení
              </button>
            </div>
          </div>
        )
      )}
      {verne('TopNavBar#330')}
    </div>
  )
}

export default TopNavBar

// // {menuOthers && (
//   <div className='topbar__nav-item'>
//   <button
//     onClick={() => {
//       setOthersDropDown(!othersDropdown)
//       setProfileDropdown(false)
//     }}
//     className='modal__trigger button-as-anchor topbar__nav-link topbar__nav-link--dropdown js-menu-toggle'
//   >
//     {menuOthers?.name}
//   </button>
//   {othersDropdown && (
//     <nav className='modal__trigger menu' ref={profileRef}>
//       {menuOthers?.items?.map((item) => (
//         <div className='menu__item' key={item.code}>
//           <a
//             href={item.url}
//             className='menu__link'
//             onClick={() => {
//               setOthersDropDown(false)
//             }}
//           >
//             {item.name}
//           </a>
//         </div>
//       ))}
//       {/* <button className='button-as-anchor menu__item is-active'>
//       Volný čas
//     </button> */}
//     </nav>
//   )}
// </div>
// )}
