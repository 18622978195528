export const dictionary = {
  REQUIRED_FIELD: {
    cz: 'Údaj je povinný',
    en: 'Required field',
    bl: 'Задължително поле',
  },
  WORK_EMAIL: {
    cz: 'Pracovní e-mail',
    en: 'Work email',
    bl: 'Служебен имейл',
  },
  WORK_EMAIL_PLACEHOLDER: {
    cz: 'Např.: novak@pluxee.cz',
    en: 'For example: novak@pluxee.cz',
    bl: 'Например: novak@pluxee.cz',
  },
  EMAIL_INVALID: {
    cz: 'E-mail není ve správném formátu',
    en: 'Email is invalid',
    bl: 'Имейл не е валиден',
  },
  COUNTRY: {
    cz: 'Země',
    en: 'Country',
    bl: 'Държава',
  },
  PHONE: {
    cz: 'Telefon',
    en: 'Phone',
    bl: 'Телефон',
  },
  PHONE_PLACEHOLDER: {
    cz: 'Např.: 721 123 456',
    en: 'For example: 721 123 456',
    bl: 'Например: 721 123 456',
  },
  PHONE_INVALID: {
    cz: 'Neplatný formát telefonního čísla',
    en: 'Invalid phone number',
    bl: 'Невалиден телефонен номер',
  },
  FIRM_ID: {
    cz: 'IČ firmy',
    en: 'Firm ID',
    bl: 'ИД на фирмата',
  },
  FIRM_ID_PLACEHOLDER: {
    cz: 'Např.: 1231231',
    en: 'For example: 1231231',
    bl: 'Например: 1231231',
  },
  FIRM_ID_INVALID: {
    cz: 'Neplatný formát IČO',
    en: 'Invalid firm ID',
    bl: 'Невалиден ИД на фирмата',
  },
  FIRM_ID_IS_BEING_VALIDATED: {
    cz: 'Vaše IČO je právě validováno...',
    en: 'Your firm ID is being validated...',
    bl: 'Вашият ИД на фирмата се валидира...',
  },
  FIRM_ID_IS_BEING_VALIDATED_SUCCESS: {
    cz: 'Vaše IČO je validní.',
    en: 'Your firm ID is valid.',
    bl: 'Вашият ИД на фирмата е валиден.',
  },
  FIRM_ID_IS_BEING_VALIDATED_FAILURE: {
    cz: 'Společnost s tímto IČO nebyla nalezena.',
    en: 'Ares MFČR service did not find the given firm ID.',
    bl: 'Службата на Ares MFČR не намери тази фирма.',
  },
  NUMBER_OF_EMPLOYEES: {
    cz: 'Počet zaměstnanců',
    en: 'Number of employees',
    bl: 'Брой служители',
  },
  NUMBER_OF_EMPLOYEES_PLACEHOLDER: {
    cz: 'Např.: 50',
    en: 'For example: 50',
    bl: 'Например: 50',
  },
  NUMBER_OF_EMLOYEES_TOO_LOW: {
    cz: 'Počet zaměstnanců nemůže být menší než 1',
    en: 'Number of employees cannot be less than 1',
    bl: 'Брой служителите не може да бъде по-малко от 1',
  },
  NUMBER_OF_EMLOYEES_TOO_HIGH: {
    cz: 'Počet zaměstnanců nemůže být vyšší než 100.000',
    en: 'Number of employees cannot be more than 100.000',
    bl: 'Брой служителите не може да бъде повече от 100.000',
  },
  RECIEVING_EMAILS_CONSENT: {
    cz: `Souhlasím s použitím poskytnutých údajů za účelem zasílání
    novinek a marketingových sdělení společnosti Pluxee Česká
    republika a.s. Svůj souhlas můžete odvolat kliknutím na
    „odhlásit z odběru“.`,
    en: `I agree to the use of the provided data for the purpose of sending
    news and marketing communications from Pluxee Česká
    republic a.s. You can withdraw your consent by clicking on
    "unsubscribe".`,
    bl: `Съгласявам със използването на предоставените данни за целта на изпращане
    на новини и маркетингови комуникации от Pluxee Česká
    republic a.s.`,
  },
  SENDING_PERSONAL_DATA_CONSENT: {
    cz: `Po odeslání formuláře zpracujeme Vaše osobní informace za
    účelem poskytování služeb a plnění smlouvy se společností Pluxee Česká republika a.s. Více informací
     o zpracování osobních údajů naleznete `,
    en: `After submitting the form, we will process your personal information for 
     the purpose of providing services and fulfilling the contract with Pluxee Česká republika a.s. More information
    about the processing of personal data can be found `,
    bl: `След като изпратите формуляра, ние ще обработим вашата лична информация с цел предоставяне на услуги и изпълнение на договора 
    със Pluxee Česká republika a.s. Повече информация за обработката на лични данни можете да намерите `,
  },
  HERE: {
    cz: 'zde',
    en: 'here',
    bl: 'тук',
  },
  GO_TO_PRICE_OFFER: {
    cz: 'Přejít na cenovou nabídku',
    en: 'Go to price offer',
    bl: 'Отиди на ценова предложение',
  },

  WHERE_TO_SHOP: {
    cz: 'Kde nakupovat',
    en: 'Where to shop',
    bl: 'Куди купівляти',
  },

  OFFER_LEAD_HEADER: {
    cz: 'Objednejte kartu Multibenefit Vašim zaměstnancům',
    en: 'Offer Multibenefit card to your employees',
    bl: 'Предложете Multibenefit card на вашите служители',
  },
  OFFER_LEAD_SUBHEADER: {
    cz: 'Pluxee Vám pomůže vyřešit benefity pro stravování i volný čas Vašich zaměstnanců.',
    en: 'Pluxee will help you to solve benefits for dining and free time of your employees.',
    bl: 'Pluxee ви поможе да решите предизвикателствата за питане и отсъствието на вашите служители.',
  },
  OFFER_LEAD_STEP_1: {
    cz: 'Začněte vyplněním údajů o Vaší společnosti',
    en: 'Start with filling in your company details',
    bl: 'Започнете с попълването на данните за вашата фирма',
  },
  OFFER_LEAD_STEP_2: {
    cz: 'Připravíme Vám nabídku na míru',
    en: 'We will prepare you an offer on a per-employee basis',
    bl: 'Подготвим вам предложение за всеки служител',
  },
  OFFER_LEAD_STEP_3: {
    cz: 'Podpis smlouvy vyřešíme on-line bez zbytečného papírování',
    en: 'We will sign the contract on-line without unnecessary paperwork',
    bl: 'Подпишем договора онлайн без ненужни документи',
  },
  OFFER_LEAD_STEP_4: {
    cz: 'Můžete začít objednávat!',
    en: 'You can start ordering!',
    bl: 'Можете да започнете покупките!',
  },

  CONTINUE_NEXT_STEP: {
    cz: 'Pokračovat na další krok',
    en: 'Continue to next step',
    bl: 'Продължете към следващият шаг',
  },

  TERMS_AND_CONDITIONS: {
    cz: 'Smluvní podmínky',
    en: 'Terms and conditions',
    bl: 'Условия за ползване',
  },

  OB1_HEADER: {
    cz: 'Cenová kalkulace a smluvní podmínky',
    en: 'Price calculation and contract terms',
    bl: 'Калкулация на цени и условия за ползване',
  },
  OB1_SUBHEADER_1: {
    cz: 'Spočítejte si cenovou nabídku benefitů na kartu Multibenefit dle',
    en: 'Calculate your price offer benefit on Multibenefit card according to',
    bl: 'Калкулирайте ценово предложение за предизвикателства на Multibenefit card въз основа на',
  },
  OB1_SUBHEADER_2: {
    cz: 'Vašich individuálních potřeb.',
    en: 'your individual needs.',
    bl: 'Вашите индивидуални потреби.',
  },
  CALCULATION: {
    cz: 'Kalkulace',
    en: 'Calculation',
    bl: 'Калкулация',
  },
  OUR_OFFER: {
    cz: 'Naše nabídka',
    en: 'Our offer',
    bl: 'Нашата предложение',
  },
  FIRM_INFORMATION: {
    cz: 'Firemní údaje',
    en: 'Firm information',
    bl: 'Информация за фирмата',
  },
  CONTRACT: {
    cz: 'Smlouva',
    en: 'Contract',
    bl: 'Договор',
  },
  NUMBER_OF_WORKED_DAYS: {
    cz: 'Počet odpracovaných dnů v měsíci',
    en: 'Number of worked days per month',
    bl: 'Брой дни отработени в месеца',
  },
  DAILY_FOOD_DOTATION: {
    cz: 'Denní příspěvek na stravu',
    en: 'Daily food dotation',
    bl: 'Дневна пищева нужда',
  },
  MONTHLY_LEASURE_CREDIT: {
    cz: 'Volnočasový kredit na měsíc',
    en: 'Monthly leisure credit',
    bl: 'Месечен кредит за отпуск',
  },
  VALUES_CAN_BE_CHANGED: {
    cz: 'Hodnoty jsou orientační a lze je kdykoliv změnit. Nejedná se o závaznou objednávku.',
    en: 'The values are indicative and can be changed at any time. It is not a binding order.',
    bl: 'Стойностите са индивидуални и могат да се променят в любо време. Не се отнася за заключени договори.',
  },
  MONTHLY_FEES: {
    cz: 'Měsíční poplatky',
    en: 'Monthly fees',
    bl: 'Месечни такси',
  },
  YOUR_PRICE: {
    cz: 'Vaše cena',
    en: 'Your price',
    bl: 'Вашата цена',
  },
  MEAL_VOUCHER_CREDIT: {
    cz: 'Stravenkový kredit',
    en: 'Gastro credit',
    bl: 'Кредит за пищева нужда',
  },
  LEISURE_CREDIT: {
    cz: 'Volnočasový kredit',
    en: 'Leisure credit',
    bl: 'Кредит за отпуск',
  },
  CREDIT_ORDER_FEE: {
    cz: 'Poplatek za objednávku kreditu',
    en: 'Credit order fee',
    bl: 'Такса за покупка на кредит',
  },
  ORDER_FEE: {
    cz: 'Poplatek za objednávku',
    en: 'Order fee',
    bl: 'Такса за покупка',
  },
  ACCOUNT_MANAGEMENT_FEE: {
    cz: 'Správa účtů',
    en: 'Accounts management fee',
    bl: 'Такса за управление на сметка',
  },
  TOTAL_PRICE: {
    cz: 'Celková cena',
    en: 'Total price',
    bl: 'Обща цена',
  },
  PRICE_WITHOUT_VAT: {
    cz: 'Uvedené ceny jsou bez DPH',
    en: 'The prices do not include VAT',
    bl: 'Цените не са включени в ДДС',
  },
  ONE_TIME_FEES: {
    cz: 'Jednorázové poplatky',
    en: 'One-time fees',
    bl: 'Еднократни такси',
  },
  CARDS_ISSUANCE: {
    cz: 'Vydání karet',
    en: 'Cards issuance',
    bl: 'Издаване на карти',
  },
  CARDS_DELIVERY: {
    cz: 'Doručení karet',
    en: 'Cards delivery',
    bl: 'Доставка на карти',
  },
  TO_THE_COMPANY_ADDRESS: {
    cz: 'na firemní adresu',
    en: 'to the company address',
    bl: 'на фирмената адреса',
  },
  PCS: {
    cz: 'ks',
    en: 'pcs',
    bl: 'бр',
  },
  GO_TO_TERMS_AND_CONDITIONS: {
    cz: 'Přejít na smluvní podmínky',
    en: 'Go to terms and conditions',
    bl: 'Отиди на условията за ползване',
  },

  LENGTH_OF_CONTRACT_PERIOD: {
    cz: 'Délka doby zasmluvnění',
    en: 'Length of contract period',
    bl: 'Период на договора',
  },
  INDEFINITE: {
    cz: 'Na dobu neurčitou',
    en: 'Indefinite',
    bl: 'На време неопределено',
  },
  NOTICE_PERIOD: {
    cz: 'Výpovědní lhůta',
    en: 'Notice period',
    bl: 'Времеви отстъпка',
  },
  MONTHS_TWO: {
    cz: 'měsíce',
    en: 'months',
    bl: 'месеца',
  },
  MONTHS_FIVE: {
    cz: 'měsíců',
    en: 'months',
    bl: 'месеца',
  },
  MIN_CREDITS_ORDER_PER_MONTH: {
    cz: 'Min. objednávka kreditů na měsíc',
    en: 'Min. credits order per month',
    bl: 'Мин. покупка на кредит в месец',
  },
  NONE: {
    cz: 'Žádná',
    en: 'None',
    bl: 'Няма',
  },
  MATURITY: {
    cz: 'Splatnost',
    en: 'Maturity',
    bl: 'Валидност',
  },
  BEFORE_DELIVERY: {
    cz: 'Před dodáním',
    en: 'Before delivery',
    bl: 'Преди доставка',
  },
  COMMON_PRICE: {
    cz: 'Běžná cena',
    en: 'Common price',
    bl: 'Обща цена',
  },
  DELIVERY_TO_THE_COMPANY_ADDRESS: {
    cz: 'Doručení na firemní adresu',
    en: 'Delivery to the company address',
    bl: 'Доставка до адрес на фирмата',
  },
  MONTHLY_USER_ACCOUNT_MANAGEMENT: {
    cz: 'Měsíční správa uživatelského účtu',
    en: 'Monthly user account management',
    bl: 'Месечно управление на потребителски акаунти',
  },
  FEE_FOR_ACTIVE_USER_ACCOUNT: {
    cz: 'Poplatek za aktivní účet uživatele',
    en: 'Fee for active user account',
    bl: 'Такса за активен потребителски акаунт',
  },

  // Onboarding TWO
  OB2_SUBHEADER: {
    cz: 'Zadané údaje budou použity pro přípravu smluvní dokumentace.',
    en: 'The entered data will be used for the preparation of contract documentation.',
    bl: 'Въведените данни ще бъдат използвани за подготовка на документация за договора.',
  },

  // Firm info
  FIRM_NAME: {
    cz: 'Název společnosti',
    en: 'Firm name',
    bl: 'Име на фирмата',
  },
  FOR_EXAMPLE: {
    cz: 'Např.:',
    en: 'For example:',
    bl: 'Наример:',
  },
  STREET_HOUSE_NUMBER: {
    cz: 'Ulice, číslo popisné',
    en: 'Street, house number',
    bl: 'Улица, номер на дома',
  },
  INVALID_STREET_HOUSE_NUMBER: {
    cz: 'Neplatná ulice, číslo popisné',
    en: 'Invalid street, house number',
    bl: 'Невалидна улица, номер на дома',
  },
  CITY: {
    cz: 'Město',
    en: 'City',
    bl: 'Град',
  },
  ZIP: {
    cz: 'PSČ',
    en: 'ZIP',
    bl: 'ПКП',
  },
  INVALID_ZIP: {
    cz: 'Neplatný formát PSČ',
    en: 'Invalid ZIP format',
    bl: 'Невалиден формат на ПКП',
  },

  // Authorized representative
  AUTHORIZED_PERSON: {
    cz: 'Oprávněná osoba',
    en: 'Authorized person',
    bl: 'Упълномощено лице',
  },
  AUTHORIZED_PERSON_ADDITIONAL_INFO: {
    cz: 'Do smlouvy doplníme uvedené jméno osob/y oprávněné uzavřít smlouvy z titulu statutárního orgánu případně plné moci.',
    en: 'We will add to the contract the name of the person/s authorized to enter into contracts by virtue of a statutory body or power of attorney.',
    bl: 'В договора ще добавим името на лицето, отговарящо за въвеждане на договори по правото на организацията или по правото на лицето, което е посочено в договора.',
  },
  ADD_ANOTHER_PERSON: {
    cz: '+ přidat další osobu',
    en: '+ add another person',
    bl: '+ добави друго лице',
  },

  CLOSE: {
    cz: 'Zavřít',
    en: 'Close',
    bl: 'Затвори',
  },

  // Onboarding THREE
  AFFIDAVIT: {
    cz: 'ČESTNÉ PROHLÁŠENÍ POVĚŘENÉ OSOBY O OPRÁVNĚNÍ UZAVŘÍT SMLOUVU',
    en: 'AFFIDAVIT OF THE AUTHORIZED PERSON REGARDING THE AUTHORITY TO ENTER INTO A CONTRACT',
    bl: 'КЛЕТВЕНА ДЕКЛАРАЦИЯ НА УПЪЛНОМОЩЕНОТО ЛИЦЕ ОТНОСНО ПРАВОМОЩИЯ ЗА СКЛЮЧВАНЕ НА ДОГОВОР',
  },
  AFFIDAVIT_CONTENT: {
    cz: `Tímto čestně prohlašuji, že jsem osobou oprávněnou uzavřít v
    zastoupení Zákazníka tuto Smlouvu o zprostředkování benefitů se společností 
    Pluxee Česká republika a.s., IČ: 61860476, se sídlem Praha 5 – Smíchov, Plzeňská 3350/18, PSČ: 150 00.
    Na důkaz tohoto prohlášení jsem schopen/schopna doložit plnou moc, výpis z
    obchodního rejstříku či jiné pověření prokazující mé jednatelské
    oprávnění ke dni uzavření této smlouvy.`,
    en: `I hereby solemnly declare that I am the person authorized to conclude, 
    on behalf of the Customer, this Agreement on mediation of benefits
    with the company Pluxee Česká republika a.s., IČ: 61860476, with registered 
    office in Prague 5 – Smíchov, Plzeňská 3350/18, ZIP Code: 150 00. As proof of this statement, 
    I am able /able to document a power of attorney, an extract from the commercial register
    or other authorization proving my executive authority as of the date of conclusion of this contract.`,
    bl: `С настоящото тържествено декларирам, че съм лицето, упълномощено да сключи, от името на Клиента, 
    този Договор за посредничество при ползи с компанията Pluxee Česká republika a.s., IČ: 61860476, 
    със седалище в Прага 5 – Smíchov, Plzeňská 3350/18, Пощенски код: 150 00. 
    Като доказателство за това твърдение мога да представя пълномощно, извлечение от търговския регистър или друго пълномощно, 
    доказващо изпълнителната ми власт към датата на сключване на този договор.`,
  },
  SIGNING_CONTRACT: {
    cz: 'Podpis smlouvy',
    en: 'Signing the contract',
    bl: 'Подписване на договора',
  },
  OB3_SUBHEADER: {
    cz: 'Zkontrolujte prosím své firemní údaje a projděte si návrh smlouvy',
    en: 'Please check your company details and review the draft contract',
    bl: 'Моля проверете вашите компаниски данни и прегледайте нашия начертан договор',
  },
  YOUR_DATA: {
    cz: 'Vaše údaje',
    en: 'Your data',
    bl: 'Вашите данни',
  },
  EDIT: {
    cz: 'Upravit',
    en: 'Edit',
    bl: 'Редактирай',
  },
  DRAFT_CONTRACT: {
    cz: 'Návrh smlouvy',
    en: 'Draft contract',
    bl: 'Начертан договор',
  },
  STATUTORY_BODY_TEXT: {
    cz: 'Jsem statutární orgán nebo osoba pověřená na základě plné moci',
    en: 'I am a statutory body or a person authorized on the basis of a power of attorney',
    bl: 'Съм статутен орган или лице, упълномощено на основа на полна моженост',
  },
  SEE: {
    cz: 'viz',
    en: 'see',
    bl: 'виж',
  },
  AFFIDAVIT_1: {
    cz: 'čestné prohlášení',
    en: 'affidavit',
    bl: 'клетвена декларация',
  },
  ONLY_A_STATUTORY_BODY_CAN_CONFIRM_THE_CONTRACT: {
    cz: 'Pokračovat může pouze statutární orgán nebo pověřená osoba.',
    en: 'Only a statutory body or an authorized person can confirm the contract.',
    bl: 'Само статутен орган или лице, упълномощено на основа на полна моженост може да продължи договора.',
  },
  SIGNED_BY_SENDING: {
    cz: 'Odesláním dojde k elektronickému podpisu smlouvy. Smlouvu najdete ve formátu PDF v klientském portálu Pluxee po dokončení registrace.',
    en: 'By sending, the contract will be electronically signed. You can find the contract in PDF format in the Pluxee Portal after completing registration.',
    bl: 'С изпращането на договора се подписва електронно. Можете да намерите договора във формат PDF в Pluxee Portal след приключване на регистрацията.',
  },
  OB3_BUTTON: {
    cz: 'Podepsat a pokračovat',
    en: 'Sign and continue',
    bl: 'Podepsat a pokračovat',
  },

  // Onboarding FOUR
  OB4_SUBHEADER_1: {
    cz: 'Pro používání Pluxee Portálu je třeba zvolit osobu, která bude mít do portálu přístup.',
    en: 'To use the Pluxee Portal, you need to select a person who will have access to the portal.',
    bl: 'За да използвате Pluxee Portal, трябва да изберете човек, който ще има достъп до портала.',
  },
  OB4_SUBHEADER_2: {
    cz: 'Tato osoba musí mít v Pluxee vedený osobní účet.',
    en: 'This person must have a personal account in Pluxee.',
    bl: 'Този човек трябва да има личен профил в Pluxee.',
  },
  NAME: {
    cz: 'Jméno',
    en: 'Name',
    bl: 'Име',
  },
  SURNAME: {
    cz: 'Příjmení',
    en: 'Surname',
    bl: 'Фамилия',
  },
  EMAIL_FOR_LOGIN: {
    cz: 'E-mail pro přihlášení',
    en: 'Email for login',
    bl: 'E-mail поща за вход',
  },
  CONFIRM_EMAIL_FOR_LOGIN: {
    cz: 'Potvrdit e-mail pro přihlášení',
    en: 'Confirm email for login',
    bl: 'Потвърдете e-mail поща за вход',
  },
  CONFIRM_EMAIL_FOR_LOGIN_INFO: {
    cz: 'Na tuto e-mailovou adresu Vám pošleme informace pro přihlášení do Pluxee Portálu.',
    en: 'We will send you information for logging into the Pluxee Portal to this e-mail address.',
    bl: 'Ние ще Ви изпратим информация за вход в Pluxee Portal на този e-mail адрес.',
  },
  REGISTRATION_OR_LOGIN: {
    cz: 'Registrace',
    en: 'Registration/Login',
    bl: 'Регистрация/Вход',
  },
  LOGIN_CREDENTIALS_NEW_USER_INFO: {
    cz: 'Po vytvoření účtu můžete oprávnění ke správě v Pluxee Portálu přidat i dalším osobám.',
    en: 'After creating an account, you can add administration rights in the Pluxee Portal to other people.',
    bl: 'След като създадете сметка, можете да добавите права за администрация в Pluxee Portal на други лица.',
  },
  LOGIN_CREDENTIALS_REGISTERED_USER_HEADER: {
    cz: 'Přihlaste se, prosím, do svého Pluxee osobního účtu, abychom Vám mohli nastavit přístup do Pluxee Portálu.',
    en: 'Please log in to your Pluxee personal account so we can set up access to the Pluxee Portal for you.',
    bl: 'Моля, влезте във вашия личен профил Pluxee, за да можем да Ви настроим достъпа до Pluxee Portal.',
  },
  LOGIN_CREDENTIALS_REGISTERED_USER_INFO: {
    cz: 'Jako přihlášený uživatel můžete oprávnění ke správě v Pluxee Portálu přidávat i dalším osobám.',
    en: 'As a logged-in user, you can add administration rights in the Pluxee Portal to other people.',
    bl: 'Като влезли потребител можете да добавяте права за администрация в Pluxee Portal на други лица.',
  },
  COMPLETE: {
    cz: 'Dokončit',
    en: 'Complete',
    bl: 'Завърши',
  },
  COMPLETE_REGISTRATION: {
    cz: 'Dokončit registraci',
    en: 'Complete registration',
    bl: 'Завършете регистрацията',
  },
  COMPLETE_LOGIN: {
    cz: 'Přihlásit se',
    en: 'Log in',
    bl: 'Влез',
  },
  NEW_CUSTOMER: {
    cz: 'Jsem nový zákazník',
    en: 'New customer',
    bl: 'Нов клиент',
  },
  REGISTERED_CUSTOMER: {
    cz: 'Mám účet v Pluxee',
    en: 'I have an account in Pluxee',
    bl: 'Имам сметка в Pluxee',
  },

  // Onboarding SUCCESS
  OB_SUCCESS_HEADER: {
    cz: 'Úspěšně jsme Vám vytvořili klientský účet do Pluxee Portálu.',
    en: 'We have successfully created a client account for you in the Pluxee Portal.',
    bl: 'Успешно сметка за клиент в Pluxee Portal е създадена.',
  },
  OB_SUCCESS_SUBHEADER: {
    cz: 'Můžete začít objednávat!',
    en: 'You can start ordering!',
    bl: 'Можете да започнете покупките!',
  },
  OB_SUCCESS_TEXT: {
    cz: 'Všechny důležité informace jsme Vám poslali na e-mail ',
    en: 'We have sent you all the important information to the e-mail ',
    bl: 'Всички важни информации сме ви изпратили на e-mail ',
  },
  OB_SUCCESS_BUTTON: {
    cz: 'Pokračovat na první objednávku',
    en: 'Continue to the first order',
    bl: 'Продължете към първата покупка',
  },

  CATALOG_OF_ESTABLISHMENTS: {
    cz: 'Katalog provozoven',
    en: 'Catalog of establishments',
    bl: 'Каталог на установи',
  },

  // ORDER PAGE
  NEW_ORDER: {
    cz: 'Nová objednávka',
    en: 'New order',
    bl: 'Нова покупка',
  },
  DO_YOU_NEED_HELP: {
    cz: 'Nevíte si rady',
    en: 'Do you need help?',
    bl: 'Не знаете какво да правите?',
  },
  CREATING_A_NEW_ORDER: {
    cz: 'Vytvoření nové objednávky',
    en: 'Creating a new order',
    bl: 'Създаване на нова покупка',
  },
  MANUALLY: {
    cz: 'Manuálně',
    en: 'Manually',
    bl: 'Ръчно',
  },
  BY_IMPORTING_DATA: {
    cz: 'Importem dat',
    en: 'By importing data',
    bl: 'Импортиране на данни',
  },

  // HELP MODAL
  HM_STEP_1: {
    cz: 'Klikněte na tlačítko ',
    en: 'Click the button ',
    bl: 'Кликнете на бутон ',
  },
  HM_STEP_1_BOLD: {
    cz: 'Přidat zaměstnance',
    en: 'Add employee',
    bl: 'Добавяне на служител',
  },
  HM_STEP_2: {
    cz: 'Potvrďte kliknutím na tlačítko ',
    en: 'Confirm by clicking the button ',
    bl: 'Потвърдете кликането на бутон ',
  },
  ADD_TO_CART: {
    cz: 'Vložit do košíku',
    en: 'Add to cart',
    bl: 'Добавяне в кошницата',
  },
  HM_STEP_3: {
    cz: 'Můžete objednat jen kartu nebo rovnou dobít stravenkový a volnočasový kredit',
    en: 'You can order either only a card or immediately get a free time credit',
    bl: 'Можете да покупите само карта или да добавите безплатно временно кредит',
  },
  HM_STEP_4: {
    cz: 'Až bude vaše objednávka kompletní, klikněte na ikonu košíku',
    en: 'When your order is complete, click the cart icon',
    bl: 'Когато покупката е завършена, кликнете на иконата на кошницата',
  },
  HM_STEP_5: {
    cz: 'Ve shrnutí objednávky zkontrolujte prosím fakturační údaje, kontakt pro zaslání pokynů k platbě a adresu pro doručení karet',
    en: 'In the summary, please check the billing information, contact for payment instructions and address for delivery of cards',
    bl: 'В обобщението, моля проверете информацията за плащане, контакт за инструкции за плащане и адрес за доставка на карти',
  },
  HM_STEP_6: {
    cz: 'Pro potvrzení objednávky klikněte na tlačítko ',
    en: 'To confirm the order, click the button ',
    bl: 'За потвърждение на покупката, кликнете на бутон ',
  },
  HM_STEP_6_BOLD: {
    cz: 'Odeslat závazně objednávku',
    en: 'Send the order',
    bl: 'Изпратете покупката',
  },
  HM_IMPORT_STEP_1: {
    cz: 'Pro první objednávku doporučujeme stáhnout ',
    en: 'For the first order we recommend downloading ',
    bl: 'За първата покупка моля свалете ',
  },
  HM_IMPORT_STEP_1_BOLD: {
    cz: 'vzorový formulář pro import dat',
    en: 'a template for importing data',
    bl: 'шаблон за импортиране на данни',
  },
  HM_IMPORT_STEP_2: {
    cz: 'V případě, že chcete nahrát vlastní importní soubor, musí obsahovat tato ',
    en: 'If you want to upload your own import file, it must contain these ',
    bl: 'Ако искате да качите собствен файл за импорт, той трябва да съдържа следните ',
  },
  HM_IMPORT_STEP_2_BOLD: {
    cz: 'povinná pole:',
    en: 'required fields:',
    bl: 'задължителни полета:',
  },
  CONTACT_PERSON: {
    cz: 'Kontaktní osoba',
    en: 'Contact person',
    bl: 'Контактно лице',
  },
  NAME_SURNAME_FIRST_CELL: {
    cz: '(jméno a příjmení v 1. buňce)',
    en: '(name and surname in the first cell)',
    bl: '(име и фамилия в първата клетка)',
  },
  PHONE_NUMBER_OF_THE_CONTACT_PERSON: {
    cz: 'Telefonní číslo kontaktní osoby',
    en: 'Phone number of the contact person',
    bl: 'Телефонен номер на контактното лице',
  },
  HM_IMPORT_STEP_3: {
    cz: 'Připravený soubor nahrajte a klikněte na tlačítko ',
    en: 'Upload the prepared file and click the button ',
    bl: 'Качете го подготвения файл и кликнете на бутон ',
  },
  SAVE: {
    cz: 'Uložit',
    en: 'Save',
    bl: 'Запази',
  },
  HM_FOOTER: {
    cz: 'V případě dalších otázek nás prosím kontaktujte',
    en: 'If you have any questions, please contact us',
    bl: 'Моля, свържете се с нас, ако имате допълнителни въпроси',
  },
  MON_FRI: {
    cz: 'Po-Pá',
    en: 'Mon-Fri',
    bl: 'По-Пет',
  },
  NAME_AND_SURNAME: {
    cz: 'Jméno a příjmení',
    en: 'Name and surname',
    bl: 'Име и фамилия',
  },
  HM_AE_STEP_1: {
    cz: 'Vyplňte jméno a příjmení zaměstnance, které bude uvedeno na kartě.',
    en: 'Fill in the first and last name of the employee that will appear on the card.',
    bl: 'Попълнете името и фамилията на служителя, които ще се показват на картата.',
  },
  EMPLOYEE_ID: {
    cz: 'Číslo zaměstnance',
    en: 'Employee ID',
    bl: 'Номер на служителя',
  },
  HM_AE_STEP_2: {
    cz: 'Vyplňte identifikační číslo zaměstnance. Pokud jej nemáte, automaticky se vygeneruje. V dalších krocích již nebude možné číslo měnit, protože slouží pro identifikaci zaměstnance.',
    en: `Fill in the employee identification number. If you don't have one, it will be generated automatically. In the next steps, it will no longer be possible to change the number, as it serves to identify the employee.`,
    bl: 'Попълнете идентификационния номер на служителя. Ако нямате такъв, той ще се генерира автоматично. В следващите стъпки вече няма да е възможно да промените номера, тъй като той служи за идентифициране на служителя.',
  },
  ADDRESS_FOR_CARDS_DELIVERY: {
    cz: 'Adresa pro doručení karet',
    en: 'Address for cards delivery',
    bl: 'Адрес за доставка на карти',
  },
  NEW_DELIVERY_ADDRESS: {
    cz: 'Nová doručovací adresa',
    en: 'New delivery address',
    bl: 'Нов адрес за доставка',
  },
  HM_AE_STEP_3_1: {
    cz: 'Adresa je automaticky předvyplněna dle sídla vaší společnosti nebo poslední zadané adresy. V případě, že chcete kartu poslat na jinou adresu a nemáte ji ještě uloženou v seznamu adres, klikněte na tlačítko',
    en: 'The address is automatically pre-filled according to your company headquarters or the last address entered. If you want to send the card to another address and you do not have it saved in the address list, click the button',
    bl: 'Адресът се попълва автоматично предварително според седалището на вашата фирма или последния въведен адрес. Ако искате да изпратите картата на друг адрес и нямате записана в списъка с адреси, натиснете бутона',
  },
  HM_AE_STEP_3_2: {
    cz: 'Zde zadejte adresu, jméno, příjmení a tel. číslo kontaktní osoby pro převzetí zásilky karet a uložte.',
    en: 'Enter the address, name, surname and phone number of the contact person for the delivery of the cards and save.',
    bl: 'Въведете адрес, име, фамилия и телефонен номер на контактната лице за доставка на картите и ги запишете.',
  },

  // TableContainer
  CARDS: {
    cz: 'Karty',
    en: 'Cards',
    bl: 'Карти',
  },
  CREDIT: {
    cz: 'Kredit',
    en: 'Credit',
    bl: 'Кредит',
  },
  SELECTED: {
    cz: 'Vybráno',
    en: 'Selected',
    bl: 'Избрано',
  },

  // TableThree
  ADD_NEW_EMPLOYEE: {
    cz: 'Přidat nového zaměstnance',
    en: 'Add new employee',
    bl: 'Добави нов служител',
  },
  CANCEL: {
    cz: 'Zrušit',
    en: 'Cancel',
    bl: 'Отказ',
  },
  FILTER: {
    cz: 'Filtr',
    en: 'Filter',
    bl: 'Филтър',
  },
  TO_FILTER: {
    cz: 'Filtrovat',
    en: 'Filter',
    bl: 'Филтрирай',
  },
  TO_SEARCH_IN: {
    cz: 'Vyhledávat v',
    en: 'Search in',
    bl: 'Търси в',
  },
  DATA_IMPORT: {
    cz: 'Import dat',
    en: 'Data import',
    bl: 'Импорт на данни',
  },
  DEACTIVATED: {
    cz: 'Deaktivovaní',
    en: 'Deactivated',
    bl: 'Деактивирани',
  },
  // unused
  ACTIVATED: {
    cz: 'Aktivovaní',
    en: 'Activated',
    bl: 'Активирани',
  },
  ACTIVE: {
    cz: 'Aktivní',
    en: 'Active',
    bl: 'Активен',
  },
  EMPL_ID: {
    cz: 'Číslo zam.',
    en: 'Empl. ID',
    bl: 'Номер на служ.',
  },
  CARD: {
    cz: 'Karta',
    en: 'Card',
    bl: 'Карта',
  },
  REPLACEMENT_CARD: {
    cz: 'Náhradní karta',
    en: 'Replacement card',
    bl: 'Заместителна карта',
  },
  CREDIT_AMOUNT: {
    cz: 'Částka kreditu ',
    en: 'Credit amount ',
    bl: 'Кредитна сума ',
  },
  DAYS_WORKED: {
    cz: 'Odpracov. dny ',
    en: 'Days worked ',
    bl: 'Работни дни ',
  },
  NO_RECORD_FOUND: {
    cz: 'Nebyl nalezen žádný záznam',
    en: 'No record found',
    bl: 'Не беше намерен запис',
  },
  SELECT_EMPLOYEES_BULK_ACTION: {
    cz: 'Vyberte zaměstnance pro hromadné akce a úpravy',
    en: 'Select employees for bulk actions and adjustments',
    bl: 'Изберете служители за групови действия и настройки',
  },
  RECORD: {
    cz: 'záznamu...',
    en: 'record...',
    bl: 'запис...',
  },
  RECORDS: {
    cz: 'záznamech...',
    en: 'records...',
    bl: 'записа...',
  },
  ORDER_STATUS: {
    cz: 'Stav objednávky',
    en: 'Order status',
    bl: 'Статус на поръчката',
  },
  SHIPMENT_STATUS: {
    cz: 'Stav zásilky',
    en: 'Shipment status',
    bl: 'Статус на доставката',
  },
  CARD_STATUS: {
    cz: 'Stav karty',
    en: 'Card status',
    bl: 'Статус на картата',
  },
  ENTER_AMOUNT: {
    cz: 'Zadejte částku',
    en: 'Enter amount',
    bl: 'Въведете сума',
  },
  ENTER_NUMBER_OF_DAYS: {
    cz: 'Zadejte počet dnů',
    en: 'Enter number of days',
    bl: 'Въведете брой дни',
  },

  // Side menu
  ORDERS_OVERVIEW: {
    cz: 'Přehled objednávek',
    en: 'Orders overview',
    bl: 'Обзор на поръчките',
  },
  ADMINISTRATION: {
    cz: 'Správa',
    en: 'Administration',
    bl: 'Администрация',
  },
  SETTINGS: {
    cz: 'Nastavení',
    en: 'Settings',
    bl: 'Настройки',
  },

  // EmployeeAdd
  EMPLOYEE_ID_HELP: {
    cz: 'Zadejte identifikační číslo zaměstnance. Pokud jej nemáte, automaticky se vygeneruje. V dalších krocích již číslo nelze měnit, protože slouží pro identifikaci zaměstnance.',
    en: `Enter the employee identification number. If you don't have one, it will be generated automatically. In the next steps, the number cannot be changed because it is used to identify the employee.`,
    bl: 'Въведете идентификационния номер на служителя. Ако нямате такъв, той ще се генерира автоматично. В следващите стъпки номерът не може да бъде променен, защото се използва за идентифициране на служителя.',
  },
  EMPLOYEE_ADD_DETAIL_1: {
    cz: 'Přidáním zaměstnance automaticky dojde k vložení karty do košíku.',
    en: 'Adding an employee automatically adds the card to the basket.',
    bl: 'Добавянето на служител автоматично добавя картата в кошницата.',
  },
  EMPLOYEE_ADD_DETAIL_2: {
    cz: 'Je na Vás, zda nejprve objednáte jen kartu nebo rovnou dobijete stravenkový a volnočasový kredit.',
    en: 'It is up to you whether you first order just the card or top up the meal voucher and leisure credit straight away.',
    bl: 'Вие можете да изберете дали да поръчате карта или да добиете предварително кредит за питания и отпуск.',
  },
  DEPARTMENT: {
    cz: 'Oddělení',
    en: 'Department',
    bl: 'Отдел',
  },
  ASSIGN_DEPARTMENT: {
    cz: 'Přiřadit oddělení',
    en: 'Assign department',
    bl: 'Прибави отдел',
  },
  DEPARTMENT_NAME: {
    cz: 'Název oddělení',
    en: 'Department name',
    bl: 'Име на отдел',
  },
  ADD: {
    cz: 'Přidat',
    en: 'Add',
    bl: 'Добави',
  },
  SEND_INFO_TO_YOUR_PHONE: {
    cz: 'Na telefon vám budou zaslány informace o doručení smlouvy a karet pro zaměstnance',
    en: 'Information about the delivery of the contract and cards for employees will be sent to your phone',
    bl: 'Информация за доставка на договора и карти за служители ще бъдат изпратени на вашия телефон',
  },
  NOT_REQUIRED: {
    cz: 'nepovinné',
    en: 'not required',
    bl: 'не е необходимо',
  },
  SAVE_ADDRESS: {
    cz: 'Uložit adresu',
    en: 'Save address',
    bl: 'Запази адрес',
  },
  NONE_N: {
    cz: 'Žádné',
    en: 'None',
    bl: 'Никой',
  },

  // Contracts
  DOWNLOAD_ALL: {
    cz: 'Stáhnout vše',
    en: 'Download all',
    bl: 'Свали всички',
  },

  // OrderSummary
  ORDER_SUMMARY: {
    cz: 'Shrnutí objednávky',
    en: 'Order summary',
    bl: 'Обобщение на поръчката',
  },
  CHARGED: {
    cz: 'Účtováno',
    en: 'Charged',
    bl: 'Заредено',
  },
  FOR_EACH_EMPLOYEE_AND_MONTH: {
    cz: 'za každého zaměstnance a kalendářní měsíc',
    en: 'for each employee and calendar month',
    bl: 'за всеки служител и месец от календара',
  },
  TOTAL_AMOUNT: {
    cz: 'Celková částka',
    en: 'Total amount',
    bl: 'Обща сума',
  },
  NO_VAT: {
    cz: 'Uvedené ceny jsou bez DPH',
    en: 'These prices do not include VAT',
    bl: 'Тези цени не са с ДДС',
  },
  ADDRESSES_FOR_DELIVERY: {
    cz: 'Adresy pro doručení',
    en: 'Addresses for delivery',
    bl: 'Адреси за доставка',
  },
  DELIVERY_ADDRESSES: {
    cz: 'Doručovací adresy',
    en: 'Delivery addresses',
    bl: 'Адреси за доставка',
  },
  FOR: {
    cz: 'pro',
    en: 'for',
    bl: 'за',
  },
  BILLING_INFORMATION: {
    cz: 'Fakturační údaje',
    en: 'Billing information',
    bl: 'Информация за фактурата',
  },
  NOTE_ON_INVOICE: {
    cz: 'Poznámka na faktuře',
    en: 'Note on the invoice',
    bl: 'Бележка за фактурата',
  },
  CONTACT_FOR_INVOICE: {
    cz: 'Kontakt pro zaslání proforma faktury',
    en: 'Contact for sending a proforma invoice',
    bl: 'Контакт за изпращане на проформа фактура',
  },
  INVOICE_EMAIL_MISSING: {
    cz: 'Vyplňte prosím e-mail pro zaplacení objednávky',
    en: 'Please fill in the email to pay for the order',
    bl: 'Моля попълнете e-mail за плащане на поръчката',
  },
  CARDS_DELIVERY_DATE: {
    cz: 'Datum doručení karet',
    en: 'Cards delivery date',
    bl: 'Дата на доставка на карти',
  },
  CARDS_DELIVERY_INFO: {
    cz: 'Karty doručujeme 5 pracovních dní od připsání platby. Karty zašleme doporučeně Českou poštou.',
    en: 'We deliver the cards 5 working days after crediting the payment. We will send the cards by registered mail by Czech Post.',
    bl: 'Картите се доставят 5 работни дни след плащане. Картите се изпращат по пощата на Чешката поща.',
  },
  CREDIT_ASSIGNMENT: {
    cz: 'Připsání kreditu',
    en: 'Credit assignment',
    bl: 'Призаване на кредит',
  },
  CREDIT_ASSIGNMENT_INFO: {
    cz: 'Kredity budou přípsány po obdržení platby',
    en: 'Credits will be assigned after the payment',
    bl: 'Кредитите се призават след плащане',
  },
  SEND_THE_ORDER: {
    cz: 'Odeslat závaznou objednávku',
    en: 'Send the order',
    bl: 'Изпрати закупената поръчка',
  },
  AMOUNT_TO_BE_PAID: {
    cz: 'Částka k úhradě:',
    en: 'Amount to be paid:',
    bl: 'Сума за плащане:',
  },
  TO_EMAIL: {
    cz: 'Na e-mail',
    en: 'To email',
    bl: 'На e-mail',
  },
  THE_PAYMENT_DETAILS_WERE_SENT: {
    cz: 'jsme vám zaslali platební údaje spolu s proforma fakturou.',
    en: 'we have sent you the payment details together with the pro forma invoice.',
    bl: 'имаме изпратени ви данни за плащане според проформа фактурата.',
  },
  ORDERS: {
    cz: 'Objednávky',
    en: 'Orders',
    bl: 'Поръчки',
  },
  CONFIRM_DELETING_WHOLE_COLUMN: {
    cz: 'Opravdu chcete vyprázdnit data celého sloupce?',
    en: 'Do you really want to empty the whole column?',
    bl: 'Наистина ли искате да изпратите празна колона?',
  },
  TO_EMPTY: {
    cz: 'Vyprázdnit',
    en: 'Empty',
    bl: 'Изпразни',
  },
  DAILY_CREDIT_SETTING: {
    cz: 'Nastavení denního kreditu',
    en: 'Daily credit setting',
    bl: 'Настройка на дневен кредит',
  },
  DAILY_CREDIT_SETTING_INFO: {
    cz: 'Daňově nejvýhodnější je',
    en: 'The most tax-advantaged is',
    bl: 'Най-облагодетелстван от данъчна гледна точка',
  },
  SET: {
    cz: 'Nastavit',
    en: 'Set',
    bl: 'Задай',
  },
  ORDER_DATE: {
    cz: 'Datum obj.',
    en: 'Order date',
    bl: 'Дата на поръчка',
  },
  ORDERED_BY: {
    cz: 'Objednal(a)',
    en: 'Ordered by',
    bl: 'Поръчал(а)',
  },
  ORDER_NUMBER: {
    cz: 'Číslo obj.',
    en: 'Order number',
    bl: 'Номер на поръчка',
  },
  AMOUNT_NO_VAT: {
    cz: 'Částka (bez DPH)',
    en: 'Amount (no VAT)',
    bl: 'Сума (без ДДС)',
  },
  NOTE: {
    cz: 'Poznámka',
    en: 'Note',
    bl: 'Бележка',
  },
  CARD_SHIPMENT: {
    cz: 'Karetní zásilka',
    en: 'Card shipment',
    bl: 'Картова поставка',
  },
  FOR_DOWNLOAD: {
    cz: 'Ke stažení',
    en: 'Download',
    bl: 'За изтегляне',
  },
  YOU_HAVE: {
    cz: 'Máte',
    en: 'You have',
    bl: 'Имате',
  },
  INVOICE: {
    cz: 'Faktura',
    en: 'Invoice',
    bl: 'Фактура',
  },
  PROFORMA: {
    cz: 'Proforma',
    en: 'Proforma',
    bl: 'Проформа',
  },
  ACCOUNTS_AND_CARDS: {
    cz: 'Účty a karty',
    en: 'Accounts and cards',
    bl: 'Сметки и карти',
  },
  EMPLOYEE_DATA: {
    cz: 'Údaje o zaměstnanci',
    en: 'Employee data',
    bl: 'Данни за служители',
  },
  IN_TOTAL: {
    cz: 'Celkem',
    en: 'In total',
    bl: 'Общо',
  },
  ADD_ORDER_TO_CART: {
    cz: 'Vložit objednávku do košíku',
    en: 'Add order to cart',
    bl: 'Добави поръчка в кошницата',
  },
  ID_CAN_NO_LONGER_BE_CHANGED: {
    cz: 'Číslo již nelze měnit, protože slouží pro identifikaci zaměstnance.',
    en: 'The ID can no longer be changed because it is used for identification of the employee.',
    bl: 'Номера не може да се промени, защото се използва за идентификация на служителя.',
  },
  CARD_DETAILS: {
    cz: 'Údaje o kartě',
    en: 'Card details',
    bl: 'Данни за карта',
  },
  SUBSTITUTE: {
    cz: 'Nahradní',
    en: 'Substitute',
    bl: 'Заместител',
  },
  NEW_F: {
    cz: 'Nová',
    en: 'New',
    bl: 'Нова',
  },
  CARD_IN_CART: {
    cz: 'karta v košíku',
    en: 'card in the cart',
    bl: 'карта в кошницата',
  },
  DELETE_ADDRESS: {
    cz: 'Smazat adresu',
    en: 'Delete the address',
    bl: 'Изтрий адрес',
  },
  ADD_ANOTHER_ADDRESS: {
    cz: 'Přidat další adresu',
    en: 'Add another address',
    bl: 'Добави друг адрес',
  },
  FIRM_ID_HELP: {
    cz: 'IČ je identifikátorem vaší firmy, který je uvedený na smlouvě a nelze jej měnit. Oprávněná osoba je například jednatel firmy případně jiný statutární orgán.',
    en: 'The ID is the identifier of your company, which is given on the contract and cannot be changed. The authorized person is usually the company holder or another statutory organization.',
    bl: 'ИД е идентификатор на вашата фирма, който е посочен в договора и не може да се промени. Организиращата се особа е обикновено даваната фирма или друг законни орган.',
  },
  SAVE_CHANGES: {
    cz: 'Uložit změny',
    en: 'Save changes',
    bl: 'Запази промените',
  },
  ADDRESS_MANAGEMENT: {
    cz: 'Správa adres pro doručení karet',
    en: 'Address management for card delivery',
    bl: 'Управление на адреси за картова доставка',
  },
  CONTACT_PERSONS_MANAGEMENT: {
    cz: 'Správa kontaktních osob',
    en: 'Contact persons management',
    bl: 'Управление на контактни лица',
  },
  DELIVERY_ADDRESS_HELP: {
    cz: 'Součástí doručovací adresy musí být kontaktní osoba a její telefon. V případě potíží s doručením zásilky vás bude kurýr kontaktovat.',
    en: 'The delivery address must include the contact person and their phone number. In case of problems with the delivery of the package, the courier will contact you.',
    bl: 'Адреса за доставка трябва да съдържа контактна лиценз и телефона. В случай на проблеми с доставката на пакета ще ви контактираме.',
  },
  DELETE_CONTACT_PERSON: {
    cz: 'Odstranit kontaktní osobu',
    en: 'Delete the contact person',
    bl: 'Изтрий контактната лиценз',
  },

  ERROR_OCCURRED: {
    cz: 'Mrzí nás to, ale nastala chyba. Předejte prosím níže uvedené informace podpoře systému.',
    en: 'We are sorry, but an error has occurred. Please pass the information below to the system support.',
    bl: 'Съжаляваме, но възникна грешка. Моля, предайте по-долу посочената информация на поддръжката на системата.',
  },
}
