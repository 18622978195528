import { FunctionComponent } from 'react'
import { useAppSelector } from 'app/hooks'
import PenIcon from '@styles/icon/penIcon'
import SdxCardIcon from '@styles/icon/sdxCardIcon'
import {
  selectPocetNahradnichKaretKObjednani,
  selectPocetNovychKaretKObjednani,
  selectTotalStravenkovy,
  selectTotalVolnocasove,
} from 'components/table/tableSlice'
import { numberWithSpaces, numberWithSpacesX } from 'app/util'
import { selectCenik } from 'components/onboarding/features/firemniUdajeSlice'
import {
  useDorucovaciAdresyMap,
  useTotalMonthsToPay,
  useTotalPriceWithFeesAndDelivery,
} from './selfcareHooks'
import {
  getFormattedFee,
  getSpravaUctuPriceFormatted,
} from 'app/numberOps'
import { kohoCehoZamestnancuSwitch, kohoCoKartuSwitch } from 'util/LangOps'
import FakturacniUdajeOrderSummary from './FakturacniUdajeOrderSummary'
import { urlNastaveni } from 'App'
import { formatPsc } from 'util/ValidFormatOps'
import { lg } from 'app/facade'
import {
  ACCOUNT_MANAGEMENT_FEE,
  ADDRESSES_FOR_DELIVERY,
  CARDS,
  CARDS_DELIVERY,
  CHARGED,
  CREDIT_ORDER_FEE,
  DELIVERY_ADDRESSES,
  FOR,
  FOR_EACH_EMPLOYEE_AND_MONTH,
  LEISURE_CREDIT,
  MEAL_VOUCHER_CREDIT,
  NO_VAT,
  ORDER_SUMMARY,
  PCS,
  REPLACEMENT_CARD,
  TOTAL_AMOUNT,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { Cenik } from 'app/type'

const OrderSummary: FunctionComponent = () => {
  const monthsToPay = useTotalMonthsToPay(1) // change request from business to only bill this and the last month (Petra Putyrova, 22.02.2022)

  const pocetNovychKaretKObjednani = useAppSelector(
    selectPocetNovychKaretKObjednani
  )
  const pocetNahradnichKaretKObjednani = useAppSelector(
    selectPocetNahradnichKaretKObjednani
  )
  const pocetKaretKObjednani =
    pocetNovychKaretKObjednani + pocetNahradnichKaretKObjednani
  const totalVolnocasove = useAppSelector(selectTotalVolnocasove)
  const totalStravenkovy = useAppSelector(selectTotalStravenkovy)

  const dorucovaciAdresyMap = useDorucovaciAdresyMap()
  const totalPriceWithFeesAndDelivery = useTotalPriceWithFeesAndDelivery()

  const cenik = useAppSelector(selectCenik) as Cenik

  const lang = useAppSelector(selectLanguage) || 'cz'
  return (
    <div style={{ display: 'flex' }}>
      <div
        className='box box--narrow'
        style={{
          width: '25rem',
          marginRight: `${pocetKaretKObjednani > 0 ? '2rem' : '0rem'}`,
        }}
      >
        <h3 className='u-size-l20 u-mb-2 u-ml-2'>{lg(lang, ORDER_SUMMARY)}</h3>
        <div className='highlight u-mb-2 u-px-2 u-py-2'>
          <div className='highlight__content'>
            {pocetKaretKObjednani > 0 && (
              <>
                <p className='grid-x'>
                  <span className='cell auto'>
                    <strong>
                      {lg(lang, CARDS)} {pocetKaretKObjednani}
                      {lg(lang, PCS)}
                    </strong>
                  </span>
                  <span
                    className='cell shrink u-text-right u-ml-1'
                    style={{ marginTop: '2px' }}
                  >
                    {/* jednorázové náklady na vydání (bez) doručení karet */}
                    <strong>
                      {numberWithSpacesX(
                        cenik.vydaniPrvniKarty * pocetNovychKaretKObjednani +
                          cenik.vydaniNahradniKarty *
                            pocetNahradnichKaretKObjednani
                      )}{' '}
                      Kč
                    </strong>
                  </span>
                </p>
                <p className='grid-x u-mt-05'>
                  <small className='cell auto small-text'>
                    <span>{lg(lang, CARDS_DELIVERY)}</span>
                  </small>
                  <small className='cell shrink u-text-right u-ml-1'>
                    <span>
                      {cenik.doruceni * dorucovaciAdresyMap.length} Kč
                    </span>
                  </small>
                </p>
              </>
            )}
            {/* Volnočas - FOR LEFT AND SOLO COLUMN */}
            <>
              <p className='grid-x align-bottom'>
                <span className='cell auto'>
                  <strong>{lg(lang, LEISURE_CREDIT)}</strong>
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>{numberWithSpacesX(totalVolnocasove)} Kč</strong>
                </small>
              </p>
              <p className='grid-x u-mt-05'>
                <small className='cell auto small-text'>
                  <span>{lg(lang, CREDIT_ORDER_FEE)}</span>
                </small>
                <small className='cell shrink u-text-right u-ml-1'>
                  <span>
                    {getFormattedFee(
                        cenik.minVolnocasFee,
                        totalVolnocasove,
                        cenik.volnocas
                    )}{' '}
                    Kč
                  </span>
                </small>
              </p>
            </>
            {/* Stravenk - FOR LEFT AND SOLO COLUMN */}
            <>
              <p className='grid-x align-bottom'>
                <span className='cell auto'>
                  <strong>{lg(lang, MEAL_VOUCHER_CREDIT)}</strong>
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>{numberWithSpacesX(totalStravenkovy)} Kč</strong>
                </small>
              </p>
              <p className='grid-x u-mt-05'>
                <small className='cell auto small-text'>
                  <span>{lg(lang, CREDIT_ORDER_FEE)}</span>
                </small>
                <small className='cell shrink u-text-right u-ml-1'>
                  <span>
                    {getFormattedFee(
                        cenik.minStravenkyFee,
                        totalStravenkovy,
                        cenik.stravenky,
                    )}&nbsp;
                    Kč
                  </span>
                </small>
              </p>
            </>
            {/* Náhradní karta - FOR LEFT AND SOLO COLUMN */}
            {pocetNahradnichKaretKObjednani > 0 && (
              <>
                <p className='grid-x align-bottom'>
                  <span className='cell auto'>
                    <strong>{lg(lang, REPLACEMENT_CARD)}</strong>
                  </span>
                  <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                    <strong>
                      {numberWithSpacesX(
                        pocetNahradnichKaretKObjednani *
                          cenik.vydaniNahradniKarty
                      )}{' '}
                      Kč
                    </strong>
                  </small>
                </p>
                <p className='grid-x u-mt-05'>
                  <small className='cell auto small-text u-color-c1-40'>
                    <span>Karta Multibenefit</span>
                  </small>
                </p>
              </>
            )}
            {/* Sprava účtů - FOR LEFT AND SOLO COLUMN */}
            <p className='grid-x align-bottom'>
              <span className='cell auto'>
                {/* Správa účtů = 5 Kč měsíčně za účet */}
                <strong>{lg(lang, ACCOUNT_MANAGEMENT_FEE)}</strong>
              </span>
              <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                <strong>
                  {getSpravaUctuPriceFormatted(
                    monthsToPay,
                    cenik.spravaJednohoUctu
                  )}{' '}
                  Kč
                </strong>
              </small>
            </p>
            <p className='grid-x u-mt-05'>
              <small className='cell auto small-text u-color-c1-40'>
                <span>
                  {lg(lang, CHARGED)} {cenik.spravaJednohoUctu} Kč{' '}
                  {lg(lang, FOR_EACH_EMPLOYEE_AND_MONTH)}
                </span>
              </small>
            </p>
            {/* Celková částka */}
            <p
              className='grid-x align-bottom u-pt-2'
              style={{ borderTop: '1px solid #283897' }}
            >
              <span className='u-size-l18 cell auto'>
                <strong>{lg(lang, TOTAL_AMOUNT)}</strong>
              </span>
              <span className='cell shrink u-text-right u-ml-1 u-size-l16'>
                <strong>
                  {numberWithSpaces(totalPriceWithFeesAndDelivery.toFixed(2))}{' '}
                  Kč
                </strong>
              </span>
            </p>
            <p className='grid-x u-mt-05'>
              <small className='cell auto small-text u-color-c1-40'>
                <span>{lg(lang, NO_VAT)}</span>
              </small>
            </p>
          </div>
        </div>
        {/* Adresy pro doručení */}
        {pocetKaretKObjednani > 0 && (
          <div
            className='highlight u-ml-0 u-mt-3 u-pt-3 u-pb-1 u-px-2 box box--narrow'
            style={{ width: '25rem' }}
          >
            <div className='highlight__content'>
              <div className='grid-x align-bottom u-mb-2'>
                <div className='cell auto'>
                  <p>
                    <strong>{lg(lang, ADDRESSES_FOR_DELIVERY)}</strong>
                  </p>
                </div>
                <div className='cell shrink'>
                  <a
                    className='u-size-base14'
                    href={urlNastaveni + '#' + 'adresy'}
                  >
                    <svg className='icon icon--16'>
                      <PenIcon width={16} height={16} />
                    </svg>
                    {lg(lang, DELIVERY_ADDRESSES)}
                  </a>
                </div>
              </div>
              {dorucovaciAdresyMap.map((adresaPocet) => (
                // console.log('adresaPocet', adresaPocet),
                <div>
                  <p className='u-size-base14 u-mt-2'>
                    <b>{adresaPocet.key.nazevSpolecnosti}</b>
                  </p>
                  <p className='u-size-base14 u-mt-1'>
                    {adresaPocet.key.kontaktniOsoba}
                  </p>
                  <p className='u-size-base14 u-mt-1'>
                    {adresaPocet.key.uliceCisloPopisne}, {adresaPocet.key.mesto}
                    , {formatPsc(adresaPocet.key.psc)}
                  </p>
                  <p className='u-size-base14 u-mt-1 u-mb-2'>
                    tel.: {adresaPocet.key.predvolba}{' '}
                    {numberWithSpacesX(adresaPocet.key.telefon)}
                  </p>
                  <p className='grid-x align-middle u-size-base14 u-mb-3'>
                    <svg className='icon icon--24 u-mr-1'>
                      <SdxCardIcon />
                    </svg>
                    <span>
                      {adresaPocet.pocetNovychKaret +
                        adresaPocet.pocetNahradnichKaret}{' '}
                      {kohoCoKartuSwitch(
                        adresaPocet.pocetNovychKaret +
                          adresaPocet.pocetNahradnichKaret,
                        lang
                      )}{' '}
                      {lg(lang, FOR)}{' '}
                      {adresaPocet.pocetNovychKaret +
                        adresaPocet.pocetNahradnichKaret}{' '}
                      {kohoCehoZamestnancuSwitch(
                        adresaPocet.pocetNovychKaret +
                          adresaPocet.pocetNahradnichKaret,
                        lang
                      )}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* SOLO COLUMN */}
        {pocetKaretKObjednani === 0 && <FakturacniUdajeOrderSummary />}
      </div>
      {/* RIGHT COLUMN */}
      {pocetKaretKObjednani > 0 && <FakturacniUdajeOrderSummary />}
    </div>
  )
}

export default OrderSummary
