import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  DEFAULT_ODPRACOVANYCH_DNU_V_MESICI,
  DEFAULT_VOLNOCASOVY_KREDIT_NA_MESIC,
  DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU,
} from 'app/constants'
import { RootState } from '../../../app/store'

export interface MesicniNakladyState {
  // pocetZamestnancu has to be optional, otherwise the placeholder wouldn't appear before the customer first enters the number of pocetZamestnancu
  pocetZamestnancu?: number
  denniPrispevekNaStravuOnboarding: number
  volnocasovyKreditNaMesic: number
  odpracovanychDnuVMesici: number
  enabledDenniPrispevekNaStravu: boolean
  enabledVolnocasovyKreditNaMesic: boolean
}

const initialState: MesicniNakladyState = {
  denniPrispevekNaStravuOnboarding: DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU,
  volnocasovyKreditNaMesic: DEFAULT_VOLNOCASOVY_KREDIT_NA_MESIC,
  odpracovanychDnuVMesici: DEFAULT_ODPRACOVANYCH_DNU_V_MESICI,
  enabledDenniPrispevekNaStravu: true,
  enabledVolnocasovyKreditNaMesic: true,
}

export const mesicniNakladySlice = createSlice({
  name: 'mesicniNaklady',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    reducerPocetZamestnancu: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.pocetZamestnancu = action.payload
    },
    reducerDenniPrispevekNaStravu: (state, action: PayloadAction<number>) => {
      state.denniPrispevekNaStravuOnboarding = action.payload
    },
    reducerVolnocasovyKreditNaMesic: (state, action: PayloadAction<number>) => {
      state.volnocasovyKreditNaMesic = action.payload
    },
    reducerOdpracovanychDnuVMesici: (state, action: PayloadAction<number>) => {
      state.odpracovanychDnuVMesici = action.payload
    },
    reducerEnabledDenniPrispevekNaStravu: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.enabledDenniPrispevekNaStravu = action.payload
    },
    reducerEnabledVolnocasovyKreditNaMesic: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.enabledVolnocasovyKreditNaMesic = action.payload
    },
  },
})

export const {
  reducerPocetZamestnancu,
  reducerDenniPrispevekNaStravu,
  reducerVolnocasovyKreditNaMesic,
  reducerOdpracovanychDnuVMesici,
  reducerEnabledDenniPrispevekNaStravu,
  reducerEnabledVolnocasovyKreditNaMesic,
} = mesicniNakladySlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPocetZamestnancu = (state: RootState) =>
  state.mesicniNaklady.pocetZamestnancu
export const selectDenniPrispevekNaStravuOnboarding = (state: RootState) =>
  state.mesicniNaklady.denniPrispevekNaStravuOnboarding
export const selectVolnocasovyKreditNaMesic = (state: RootState) =>
  state.mesicniNaklady.volnocasovyKreditNaMesic
export const selectOdpracovanychDnuVMesici = (state: RootState) =>
  state.mesicniNaklady.odpracovanychDnuVMesici
export const selectEnabledDenniPrispevekNaStravu = (state: RootState) =>
  state.mesicniNaklady.enabledDenniPrispevekNaStravu
export const selectEnabledVolnocasovyKreditNaMesic = (state: RootState) =>
  state.mesicniNaklady.enabledVolnocasovyKreditNaMesic

export default mesicniNakladySlice.reducer
