import { FunctionComponent, useState, useEffect } from 'react'
import {
  urlRecon1,
  urlRecon2,
  urlRecon3,
  urlRecon4,
  urlMultiPassCard,
  urlOnboarding1,
  urlOnboarding2,
  urlOnboarding3,
  urlOnboarding4,
} from 'App'
import { useAppSelector } from 'app/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { selectFarthestVisitedLocation } from './features/stepperSlice'
import { lg } from 'app/facade'
import {
  CALCULATION,
  CONTRACT,
  FIRM_INFORMATION,
  LOGIN_CREDENTIALS,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

const Stepper: FunctionComponent = () => {
  const location = useLocation()
  const history = useNavigate()
  const farthestVisitedLocation = useAppSelector(selectFarthestVisitedLocation)
  const lang = useAppSelector(selectLanguage) || 'cz'

  function isUnlocked(stepperNumber: number) {
    return farthestVisitedLocation >= stepperNumber
  }

  // reads the last letter of the current Url and converts it into a number. NO MORE.
  const locationNumber =
    location.pathname.endsWith(urlOnboarding1) ||
    location.pathname.endsWith(urlRecon1)
      ? 1
      : location.pathname.endsWith(urlOnboarding2) ||
        location.pathname.endsWith(urlRecon2)
      ? 2
      : location.pathname.endsWith(urlOnboarding3) ||
        location.pathname.endsWith(urlRecon3)
      ? 3
      : location.pathname.endsWith(urlOnboarding4) ||
        location.pathname.endsWith(urlRecon4)
      ? 4
      : 0

  function handleNavigation(
    stepperNumber: number,
    stepperLocationUrlSuffix: string
  ): void {
    if (!isUnlocked(4) && isUnlocked(stepperNumber))
      // the above check !isUnlocked(4) is not necessary, but it is a safety check
      history(stepperLocationUrlSuffix)
  }

  return (
    <div className='grid-x u-justify-center'>
      <div className='cell xsmall-10 small-7 u-mb-9 u-pb-4'>
        <div className='stepper'>
          <div className='stepper__segments'>
            <div
              className={'stepper__segment ' + (locationNumber > 1 && 'filled')}
            ></div>
            <div
              className={'stepper__segment ' + (locationNumber > 2 && 'filled')}
            ></div>
            <div
              className={'stepper__segment ' + (locationNumber > 3 && 'filled')}
            ></div>
          </div>
          <span
            className={
              'stepper__step ' +
              (isUnlocked(4)
                ? ' stepper__locked done'
                : (locationNumber > 1 ? 'done' : 'active') +
                  (isUnlocked(1) ? ' stepper__unlocked' : ' stepper__locked'))
            }
            onClick={() =>
              handleNavigation(
                1,
                window.location.href.includes(urlMultiPassCard)
                  ? urlOnboarding1
                  : urlRecon1
              )
            }
          >
            1<span className='stepper__label'>{lg(lang, CALCULATION)}</span>
          </span>

          <span
            className={
              'stepper__step ' +
              (isUnlocked(4)
                ? ' stepper__locked done'
                : (locationNumber > 2
                    ? 'done'
                    : locationNumber === 2 && 'active') +
                  (isUnlocked(2) ? ' stepper__unlocked' : ' stepper__locked'))
            }
            onClick={() =>
              handleNavigation(
                2,
                window.location.href.includes(urlMultiPassCard)
                  ? urlOnboarding2
                  : urlRecon2
              )
            }
          >
            2
            <span className='stepper__label'>{lg(lang, FIRM_INFORMATION)}</span>
          </span>
          <span
            className={
              'stepper__step ' +
              (isUnlocked(4)
                ? ' stepper__locked done'
                : (locationNumber > 3
                    ? 'done'
                    : locationNumber === 3 && 'active') +
                  (isUnlocked(3) ? ' stepper__unlocked' : ' stepper__locked'))
            }
            onClick={() =>
              handleNavigation(
                3,
                window.location.href.includes(urlMultiPassCard)
                  ? urlOnboarding3
                  : urlRecon3
              )
            }
          >
            3<span className='stepper__label'>{lg(lang, CONTRACT)}</span>
          </span>
          <span
            className={
              'stepper__step ' +
              (locationNumber === 4 && 'active') +
              (isUnlocked(4) ? ' stepper__unlocked' : ' stepper__locked')
            }
            onClick={() =>
              handleNavigation(
                4,
                window.location.href.includes(urlMultiPassCard)
                  ? urlOnboarding4
                  : urlRecon4
              )
            }
          >
            4
            <span className='stepper__label'>
              {lg(lang, LOGIN_CREDENTIALS)}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Stepper
