import { GHOST_INDEX } from 'app/constants'
import { Address } from 'app/type'
import { numberWithSpacesX, numberWithSpaces } from 'app/util'
import { ImportRow } from 'components/dataimportcentre/dataImportSlice'
import { TableRow } from 'components/table/tableSlice'
import {
  formatTelefon,
  formatPsc,
  formatUliceCisloPopisne,
  equalsBezRozdiluVCisluOrientacnim,
} from './ValidFormatOps'

export function completeKontaktniOsoba(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses: Address[]
): string {
  return appliedAddresses[i]
    ? appliedAddresses[i]!.kontaktniOsoba
    : finalKontaktniOsoba(changesAcceptance, i, row, addresses, db, indexes)
}
export function completeTelefon(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses: Address[]
): string {
  return appliedAddresses[i]
    ? formatTelefon(appliedAddresses[i]!.telefon)
    : finalTelefon(changesAcceptance, i, row, addresses, db, indexes)
}
export function completePsc(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses: Address[]
): string {
  return appliedAddresses[i]
    ? formatPsc(appliedAddresses[i]!.psc)
    : finalPsc(changesAcceptance, i, row, addresses, db, indexes)
}
export function completeMesto(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses: Address[]
): string {
  return appliedAddresses[i]
    ? appliedAddresses[i]!.mesto
    : finalMesto(changesAcceptance, i, row, addresses, db, indexes)
}
export function completeUliceCisloPopisne(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses?: Address[]
): string {
  return appliedAddresses[i]
    ? formatUliceCisloPopisne(appliedAddresses[i]!.uliceCisloPopisne)
    : finalUliceCisloPopisne(changesAcceptance, i, row, db, indexes)
}
export function completeNazevSpolecnosti(
  row: ImportRow,
  i: number,
  changesAcceptance: string[],
  db: TableRow[],
  indexes: number[],
  appliedAddresses: (Address | undefined)[],
  addresses: Address[]
): string {
  return appliedAddresses[i]
    ? appliedAddresses[i]!.nazevSpolecnosti
    : finalNazevSpolecnosti(changesAcceptance, i, row, addresses, db, indexes)
}

// FINALS - called after DIC step 5
export function finalTelefon(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  addresses: Address[],
  db: TableRow[],
  indexes: number[]
) {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  return changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
    ? numberWithSpacesX(
        addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
          )
        )?.telefon || 0
      )
    : numberWithSpaces(row.telefon)
}

export function finalKontaktniOsoba(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  addresses: Address[],
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  return (
    (changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
      ? addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
          )
        )?.kontaktniOsoba
      : row.kontaktniOsoba) || ''
  )
}

export function finalPsc(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  addresses: Address[],
  db: TableRow[],
  indexes: number[]
) {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  return changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
    ? formatPsc(
        addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
          )
        )?.psc || ''
      )
    : formatPsc(row.psc)
}

export function finalMesto(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  addresses: Address[],
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  return (
    (changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
      ? addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
          )
        )?.mesto
      : row.mesto) || ''
  )
}

export function finalUliceCisloPopisne(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  return formatUliceCisloPopisne(
    (changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
      ? db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
      : row.uliceCisloPopisne) || ''
  )
}

export function finalNazevSpolecnosti(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  addresses: Address[],
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  return (
    (changesAcceptance[indexes.indexOf(i)] === 'rejected'
      ? addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            db.find((e) => e.id === row.cisloZamestnance)?.uliceCisloPopisne
          )
        )?.nazevSpolecnosti
      : row.nazevSpolecnosti) || ''
  )
}

// final = after changes acceptance aka after step 5
export function finalPrijmeni(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  // if (row.cisloZamestnance === '3')
  //   console.log(
  //     'finalPrijmeni',
  //     row.cisloZamestnance,
  //     row.prijmeni,
  //     indexes.indexOf(i),
  //     changesAcceptance,
  //     i,
  //     indexes,
  //     row.uliceCisloPopisne,
  //     changesAcceptance[indexes.indexOf(i)]
  //   )
  return (
    (changesAcceptance[indexes.indexOf(i)] === 'rejected'
      ? db.find((e) => e.id === row.cisloZamestnance)?.surname
      : row.prijmeni) || ''
  )
}

export function finalJmeno(
  changesAcceptance: string[],
  i: number,
  row: ImportRow,
  db: TableRow[],
  indexes: number[]
): string {
  indexes = indexes.filter((e) => e !== GHOST_INDEX)
  // if something goes undefined, we return empty string and validation will catch it
  return (
    (changesAcceptance[indexes.indexOf(i)] === 'rejected' &&
    row.cisloZamestnance
      ? db.find((e) => e.id === row.cisloZamestnance)?.name
      : row.jmeno) || ''
  )
}
