import CloseIcon from '@styles/icon/closeIcon'
import { useAppSelector } from 'app/hooks'
import { selectBrokenAddresses } from 'components/settings/governanceSlice'
import React, { FunctionComponent, useEffect } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock' /* From MDN Article about accessible dialogs:
 - The dialog must be properly labeled
 - Keyboard focus must be managed correctly
 => This one is about managing the focus. */

interface ModalProps {
  isShown: boolean
  hide: () => void
  modalContent: JSX.Element
  headerText: string
  subheaderText: string
  onUseEffect?: () => void
  prevState?: any
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
  subheaderText,
  onUseEffect,
  prevState,
}) => {
  const isBrokenAddress = useAppSelector(selectBrokenAddresses).length > 0
  const onKeyDown = (event: KeyboardEvent) => {
    // Close modal on Escape key
    if (event.keyCode === 27 && isShown) {
      hideInternal()
    }
  }

  const hideInternal = () => {
    hide()
    setPristine(true)
  }

  const [pristine, setPristine] = React.useState(true)

  useEffect(() => {
    pristine && onUseEffect && onUseEffect()
    setPristine(false)
    isShown
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset')
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [isShown, onKeyDown, prevState])

  const modal = (
    <React.Fragment>
      <div className='modal_backdrop' onClick={hideInternal} />
      <FocusLock>
        <div
          className='modal_backdrop'
          aria-modal
          aria-labelledby={headerText}
          tabIndex={-1}
          role='dialog'
        >
          <div className='modal modal--open'>
            <div className='modal__body'>
              <div className='modal__header'>
                {!isBrokenAddress && (
                  <div className='modal__close' onClick={hideInternal}>
                    <span>{headerText}</span>
                    <svg className='icon icon--24'>
                      <CloseIcon />
                    </svg>
                  </div>
                )}
              </div>
              <div className='modal__subheader'>
                <h2 className='heading heading--2 u-text-center u-mb-4'>
                  {subheaderText}
                </h2>
              </div>
              <div className='modal__content'>{modalContent}</div>
            </div>
          </div>
        </div>
      </FocusLock>
    </React.Fragment>
  )

  return isShown ? ReactDOM.createPortal(modal, document.body) : null
}
