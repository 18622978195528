import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class LockIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'lock-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 5c-1.656 0-3 1.344-3 3v3h6V8c0-1.656-1.344-3-3-3zm-5 3v3.027c-2.25.246-4 2.152-4 4.466v9.014A4.494 4.494 0 0011.493 29h9.014A4.494 4.494 0 0025 24.507v-9.014c0-2.314-1.75-4.22-4-4.466V8c0-2.76-2.24-5-5-5s-5 2.24-5 5zm.493 5h9.014A2.494 2.494 0 0123 15.493v9.014A2.494 2.494 0 0120.507 27h-9.014A2.494 2.494 0 019 24.507v-9.014A2.494 2.494 0 0111.493 13zm5.43 7.215l1.005 1.876c.224.416-.102.909-.604.909h-2.648c-.502 0-.828-.493-.604-.91l1.005-1.881a.463.463 0 00-.136-.588 1.442 1.442 0 01-.557-1.124c0-.928.917-1.66 1.962-1.465.598.114 1.093.568 1.229 1.118a1.433 1.433 0 01-.51 1.471c-.183.152-.25.392-.142.594z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default LockIcon
