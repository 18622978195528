import React, { useEffect, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import DirectionIcon from '@styles/icon/directionIcon'
import ConfirmSection from './ConfirmSection'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  reducerPocetZamestnancu,
  selectPocetZamestnancu,
} from './features/mesicniNakladySlice'
import {
  reducerCenik,
  reducerDicFirmy,
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerOpravneneOsoby,
  reducerPsc,
  reducerUliceCisloPopisne,
  selectDicFirmy,
  selectIcoFirmy,
} from 'components/onboarding/features/firemniUdajeSlice'
import {
  reducerPracovniEmail,
  reducerPredvolba,
  reducerTelefon,
  selectPracovniEmail,
  selectPredvolba,
  selectTelefon,
} from 'components/onboarding/features/objednavatelSlice'
import {
  emailRegex,
  icoRegex,
  telefonniCisloRegex,
  validateNumericKeyWithWhites,
} from 'app/util'
import {
  SOUHLAS_SE_ZASILANIM_INFORMACI,
  SEGMENT_B_THRESHOLD,
  MAXIMALNI_POCET_ZAMESTNANCU,
} from 'app/constants'
import {
  REQUIRED_FIELD,
  WORK_EMAIL,
  WORK_EMAIL_PLACEHOLDER,
  EMAIL_INVALID,
  OFFER_LEAD_HEADER,
  OFFER_LEAD_SUBHEADER,
  OFFER_LEAD_STEP_1,
  OFFER_LEAD_STEP_2,
  OFFER_LEAD_STEP_3,
  OFFER_LEAD_STEP_4,
  COUNTRY,
  PHONE,
  PHONE_PLACEHOLDER,
  PHONE_INVALID,
  FIRM_ID,
  FIRM_ID_PLACEHOLDER,
  FIRM_ID_INVALID,
  FIRM_ID_IS_BEING_VALIDATED,
  FIRM_ID_IS_BEING_VALIDATED_FAILURE,
  NUMBER_OF_EMPLOYEES,
  NUMBER_OF_EMPLOYEES_PLACEHOLDER,
  NUMBER_OF_EMLOYEES_TOO_LOW,
  RECIEVING_EMAILS_CONSENT,
  SENDING_PERSONAL_DATA_CONSENT,
  HERE,
  GO_TO_PRICE_OFFER,
  NUMBER_OF_EMLOYEES_TOO_HIGH,
} from 'app/constDictionary'
import { onClickUrl } from 'util/OpenInNewTab'
import {
  urlBackend,
  urlReconOfferAndLeadExit,
  urlRecon1,
  urlMultiPassCard,
  urlOchranaOsobnichUdaju,
  urlOfferAndLeadExit,
  urlOnboarding1,
} from 'App'
import { createMagicLink } from 'components/magiclink/MagicLinkTools'
import {
  addCommaInUliceCisloPopisne,
  formatTelefon,
  isValidTelefonX,
  validateCzechIcoControlNumber,
} from 'util/ValidFormatOps'
import CheckIcon from '@styles/icon/checkIcon'
import Logos from './Logos'
import { clearCache, clearFlags, lg } from 'app/facade'
import {
  reducerSetLanguage,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import { comesFromOurApplication } from 'util/authOps'
import { createBackendTelefon } from 'util/transformer'

// enum FindMeResponses {
//   validni = 'validni',
//   neexistujiciIco = 'neexistujici_ico',
//   jizZakaznikem = 'jiz_zakaznikem',

const OfferAndLead: React.FC = () => {
  const [marketingConsent, setMarketingConsent] = useState(false)
  const [responseFindMe, setResponseFindMe] = useState('')
  const [validniIcSalesForce, setValidniIcSalesForce] = useState(false)
  // by default only errors on IČ are shown
  const [displayAllValidationErrors, setDisplayAllValidationErrors] =
    useState(false)
  const [isIcoValid, setIsIcoValid] = useState(false);

  const lang = useAppSelector(selectLanguage) || 'cz'
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const telefon = useAppSelector(selectTelefon)
  const predvolba = useAppSelector(selectPredvolba)
  const icFirmy = useAppSelector(selectIcoFirmy)
  const dicFirmy = useAppSelector(selectDicFirmy)
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const dispatch = useAppDispatch()

  useEffect(() => {
    // todo later decide how / from where the language gets set to allow multi-language
    dispatch(reducerSetLanguage('cz'))
    setIsIcoValid(false);
    // if the user has just began the onboarding process by coming from outside the onboarding or our site or simply just now opening this tab, we reset the cached onboarding values.
    if (!comesFromOurApplication(document.referrer)) {
      clearCache()
      clearFlags()
    }
  }, []) // the empty dependency array makes sure the useEffect is only ran once.

  const handleChangePracovniEmail = (e: any) => {
    let value = e.target.value
    dispatch(reducerPracovniEmail(value))
  }
  const handleChangeTelefon = (e: any) => {
    let value = e.target.value
    // here we actually just need to replace whitespaces because of our pretty formatting
    value = value.replace(/[^0-9]/g, '')
    dispatch(reducerTelefon(value))
  }
  const handleChangeIcFirmy = (e: any) => {
    setIsIcoValid(false);
    let value = e.target.value
    console.log('setting IcFirmy: ', value)
    dispatch(reducerIcFirmy(value))
    icoRegex.test(value) && callBackendFindme(value)
  }
  const handleChangePocetZamestnancu = (e: any) => {
    let value = e.target.value
    dispatch(reducerPocetZamestnancu(value))
  }

  useEffect(() => {
    icFirmy && callBackendFindme(icFirmy)
  }, [])

  const [spolecnostNesplnujePodminky, setSpolecnostNesplnujePodminky] =
    useState(false)

  async function callBackendFindme(_icFirmy: any) {
    if (!_icFirmy) _icFirmy = icFirmy

    // reset leads to 'Vaše IČ je právě validováno help message'
    setResponseFindMe('') // reset
    setValidniIcSalesForce(false) // reset
    setIsIcoValid(false);

    const requestBody = JSON.stringify({
      ico: _icFirmy,
      DEBUG_KEY: 'wS5xyTmmJJU9qzJQezmG',
    })

    console.log(
      'Sending POST request to ' + urlBackend + '/findme, requestBody: ',
      requestBody
    )

    // await fetch(urlBackend + '/findme?DEBUG_KEY=wS5xyTmmJJU9qzJQezmG', {
    await fetch(urlBackend + '/findme', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        setResponseFindMe('validni') // clear help message 'Vaše IČ je právě validováno'
        setValidniIcSalesForce(true) // clear help message 'Vaše IČ je právě validováno'
        setSpolecnostNesplnujePodminky(false) // reset
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        if (data.code !== 200) {
          if (data.code === 101) {
            setResponseFindMe('neexistujici_ico')
            console.log('Neexistující IČO')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
          } else if (data.code === 102) {
            setResponseFindMe('jiz_zakaznikem')
            setValidniIcSalesForce(false)
            console.log('Společnost již zaregistrovaná')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
          } else if (data.code === 103) {
            setResponseFindMe('nesplnuje_podminky')
            // this check is also done on frontend, but it's not a problem if it's done on backend as well
            console.log('Společnost nesplňuje podmínky pro onboarding')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
            setSpolecnostNesplnujePodminky(true)
          } else if (data.code === 104) {
            // new check (4.4.2022)
            setResponseFindMe('nevhodna_pravni_forma')
            console.log('Společnost má validní IČO ale nevhodnou právní formu')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
            setSpolecnostNesplnujePodminky(true)
          }
        } else {
          setIsIcoValid(true);
          console.log(
              'No response data code was returned, good. Dispatching Reducers...'
          )
        }
      })
      .catch((error) => {
        console.log(error)
        setIsIcoValid(false)
      })
  }

  async function callBackendLead(_icFirmy: any) {
    if (!_icFirmy) _icFirmy = icFirmy

    // reset leads to 'Vaše IČ je právě validováno help message'
    setResponseFindMe('') // reset
    setValidniIcSalesForce(false) // reset

    let pocetZamestnancuNumber = parseInt((pocetZamestnancu || 0).toString())
    if (pocetZamestnancuNumber === 0) {
      pocetZamestnancuNumber = 20 // bypass backend validation
    }

    const magicLink = createMagicLink({
      location: urlOnboarding1,
      vars: {
        pracovniEmail: pracovniEmail,
        telefon: telefon,
        predvolba: predvolba,
        pocetZamestnancu: pocetZamestnancu,
        icFirmy: _icFirmy,
        dicFirmy: dicFirmy,
      },
    })
    console.log({ magicLink })

    const requestBody = JSON.stringify({
      email: pracovniEmail,
      phone: createBackendTelefon(predvolba, telefon),
      countryCode: predvolba, // countryCode goes without the leading '+', according to backend's request
      ico: _icFirmy,
      employees: pocetZamestnancuNumber,
      marketingConsent: marketingConsent
        ? SOUHLAS_SE_ZASILANIM_INFORMACI
        : 'false',
      magic_link: shallNotPass()
        ? // Ineligible clients do not receive a magic link for the onboarding process.
          ''
        : magicLink,
    })

    console.log(
      'Sending POST request to ' +
        urlBackend +
        '/onboarding/lead, requestBody: ',
      requestBody
    )

    await fetch(urlBackend + '/onboarding/lead', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        setResponseFindMe('validni') // clear 'Vaše IČ je právě validováno help message'
        setValidniIcSalesForce(true) // clear 'Vaše IČ je právě validováno help message'
        setSpolecnostNesplnujePodminky(false) // reset
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        if (data.code) {
          if (data.code === 101) {
            setResponseFindMe('validni')
            console.log('Neexistující IČO')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
          } else if (data.code === 102) {
            setResponseFindMe('jiz_zakaznikem')
            setValidniIcSalesForce(false)
            console.log('Společnost již zaregistrovaná')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
          } else if (data.code === 103) {
            setResponseFindMe('nesplnuje_podminky')
            // this check is done on frontend, backend is sending us this info too (which is redundant but good)
            console.log('Společnost nesplňuje podmínky pro onboarding')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
            setSpolecnostNesplnujePodminky(true)
          } else if (data.code === 104) {
            // new check (4.4.2022)
            setResponseFindMe('nevhodna_pravni_forma')
            console.log('Společnost má validní IČO ale nevhodnou právní formu')
            console.log('data.code: ', data.code)
            console.log('data.message: ', data.message)
            setSpolecnostNesplnujePodminky(true)
          }
        } else {
          // dobry pruchod, nevratil se response data code
          console.log(
            'No response data code was returned, good. Dispatching Reducers...'
          )
          data.icoFirmy
            ? dispatch(reducerIcFirmy(data.icoFirmy))
            : // if backend doesn't answer our call, we'll just proceed with the user's input
              dispatch(reducerIcFirmy(_icFirmy))
          data.dicFirmy
            ? dispatch(reducerDicFirmy(data.dicFirmy)) // added 12.4. - not sure if backend (Pepa) sends it yet.
            : dispatch(reducerDicFirmy(''))
          dispatch(reducerNazevSpolecnosti(data.nazevSpolecnosti))
          const uliceCisloPopisneWithComma = addCommaInUliceCisloPopisne(
            data.uliceCisloPopisne
          )
          dispatch(reducerUliceCisloPopisne(uliceCisloPopisneWithComma))
          // dispatch(reducerUliceCisloPopisne(data.uliceCisloPopisne)) // todo delete later
          dispatch(reducerMesto(data.mesto))
          dispatch(reducerPsc(data.psc))
          dispatch(
            reducerCenik({
              volnocas: data.cenik.poplatek_z_volnocasoveho_kreditu,
              stravenky: data.cenik.poplatek_ze_stravovaciho_kreditu,
              spravaJednohoUctu: data.cenik.mesicni_sprava_uzivatelskeho_uctu,
              vydaniPrvniKarty: data.cenik.cena_vydani_karty,
              vydaniNahradniKarty:
                data.cenik.poplatek_za_objednani_nahradni_karty,
              doruceni: data.cenik.cena_doruceni,
              minStravenkyFee: data.cenik.min_gastro_fee,
              minVolnocasFee: data.cenik.min_leisure_fee,
            })
          )
          dispatch(reducerOpravneneOsoby([])) // initialize opravneneOsoby
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      })
  }

  function isValid() {
    return (
      pracovniEmail &&
      telefon &&
      icFirmy &&
      pocetZamestnancu &&
      pocetZamestnancu > 0 &&
      pocetZamestnancu <= MAXIMALNI_POCET_ZAMESTNANCU &&
      emailRegex.test(pracovniEmail) &&
      icoRegex.test(icFirmy.toString()) &&
      telefonniCisloRegex.test(telefon.toString()) &&
      isIcoValid
      // validniIcAres
      // && validniIcSalesForce
    )
  }

  const [countryCodeSelectionShown, setCountryCodeSelectionShown] =
    useState(false)

  const submitIcon = <DirectionIcon />

  const submitText = lg(lang, GO_TO_PRICE_OFFER)
  const nextRoute = window.location.href.includes(urlMultiPassCard)
    ? urlOnboarding1
    : urlRecon1
  const exitRoute = window.location.href.includes(urlMultiPassCard)
    ? urlOfferAndLeadExit
    : urlReconOfferAndLeadExit

  function shallNotPass(): boolean {
    return (
      pocetZamestnancu! > SEGMENT_B_THRESHOLD ||
      pocetZamestnancu! < 1 ||
      spolecnostNesplnujePodminky ||
      !validniIcSalesForce
    )
  }
  function submitAction() {
    if (isIcoValid) {
      !isValid() &&
      setDisplayAllValidationErrors(true)
      callBackendLead(icFirmy)
    }
  }

  return (
    <div className='container container--wide u-mt-6'>
      <h2 className='u-text-center u-mb-5'>{lg(lang, OFFER_LEAD_HEADER)}</h2>
      <p className='u-text-center u-mb-6'>{lg(lang, OFFER_LEAD_SUBHEADER)}</p>
      <div className='grid-x'>
        <div
          className='highlight highlight--transparent'
          style={{
            maxWidth: '39rem',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className='highlight__content'>
            {/* <div className='cell xlarge-6 u-pr-xlarge-4 u-ml-8 u-mt-5'> */}
            {/* <h3 className='heading u-size-l20 u-mb-4'>Multi Pass CARD nabízí:</h3> */}
            <ol className='custom-counter-offer-lead'>
              <li>{lg(lang, OFFER_LEAD_STEP_1)}</li>
              <li>{lg(lang, OFFER_LEAD_STEP_2)}</li>
              <li>{lg(lang, OFFER_LEAD_STEP_3)}</li>
              <li>{lg(lang, OFFER_LEAD_STEP_4)}</li>
            </ol>
          </div>

          {/* Logos - to be displayed on big screen */}
          <div
            className='grid-x align-spaced align-middle u-mt-9 u-pt-9 u-mb-4 u-hide-large-down'
            style={{
              marginLeft: '-36px',
            }}
          >
            <Logos />
          </div>
        </div>
        <div className='cell xlarge-5'>
          <div className='highlight highlight--narrow'>
            <div className='highlight__content'>
              <div className='input u-mb-4 input--required'>
                <label>{lg(lang, WORK_EMAIL)}</label>
                <input
                  type='email'
                  placeholder={lg(lang, WORK_EMAIL_PLACEHOLDER)}
                  value={pracovniEmail}
                  onChange={handleChangePracovniEmail}
                />
                {displayAllValidationErrors &&
                  (pracovniEmail ? (
                    !emailRegex.test(pracovniEmail) && (
                      <span className='input__error'>
                        {lg(lang, EMAIL_INVALID)}
                      </span>
                    )
                  ) : (
                    <span className='input__error'>
                      {lg(lang, REQUIRED_FIELD)}
                    </span>
                  ))}
              </div>
              <div className='grid-x'>
                <div className='cell medium-4 u-pr-medium-2'>
                  <div
                    onClick={() =>
                      setCountryCodeSelectionShown(!countryCodeSelectionShown)
                    }
                    id='country-code'
                    className={
                      'cphone-code u-mb-4 cphone-code--required' +
                      ' ' +
                      (countryCodeSelectionShown ? 'cphone-code--active' : '')
                    }
                  >
                    <label
                      htmlFor='country-code'
                      className='country-code__label'
                    >
                      {lg(lang, COUNTRY)}
                    </label>{' '}
                    <div className='cphone-code__option cphone-code__option--selected'>
                      <svg className='icon icon--24'>
                        <use
                          href={
                            icons +
                            (predvolba === 420
                              ? '#sprite-flag-cz'
                              : '#sprite-flag-sk')
                          }
                        />
                      </svg>{' '}
                      <span>
                        <svg className='icon icon--16 u-mx-1'>
                          <use
                            href={
                              icons +
                              (countryCodeSelectionShown
                                ? '#sprite-arrow-thin-up'
                                : '#sprite-arrow-thin-down')
                            }
                          />
                        </svg>
                      </span>{' '}
                      <span>+{predvolba}</span>
                    </div>{' '}
                    {countryCodeSelectionShown && (
                      <div
                        onClick={() => {
                          setCountryCodeSelectionShown(false)
                          dispatch(
                            reducerPredvolba(predvolba === 420 ? 421 : 420)
                          )
                        }}
                        className='cphone-code__options'
                      >
                        <div className='cphone-code__option'>
                          <svg className='icon icon--24 u-mr-1'>
                            <use
                              href={
                                icons +
                                (predvolba === 420
                                  ? '#sprite-flag-sk'
                                  : '#sprite-flag-cz')
                              }
                            />
                          </svg>{' '}
                          <span>+{predvolba === 420 ? 421 : 420}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='cell medium-8'>
                  <div className='input u-mb-4 input--required'>
                    <label className='input__label'>{lg(lang, PHONE)}</label>{' '}
                    <div className='input__wrapper'>
                      <input
                        placeholder={lg(lang, PHONE_PLACEHOLDER)}
                        type='tel'
                        value={formatTelefon(telefon) || ''}
                        onKeyPress={validateNumericKeyWithWhites}
                        onChange={handleChangeTelefon}
                      />{' '}
                      {displayAllValidationErrors &&
                        (telefon ? (
                          !isValidTelefonX(telefon) && (
                            <span className='input__error'>
                              {lg(lang, PHONE_INVALID)}
                            </span>
                          )
                        ) : (
                          <span className='input__error'>
                            {lg(lang, REQUIRED_FIELD)}
                          </span>
                        ))}
                    </div>{' '}
                  </div>
                </div>
              </div>
              <div className='input u-mb-4 input--required'>
                <label>{lg(lang, FIRM_ID)}</label>
                <input
                  type='tel'
                  placeholder={lg(lang, FIRM_ID_PLACEHOLDER)}
                  value={icFirmy}
                  onChange={handleChangeIcFirmy}
                  onKeyPress={validateNumericKeyWithWhites}
                  onWheel={(e) => e.currentTarget.blur()}
                />
                {displayAllValidationErrors &&
                  (!icFirmy ? (
                    <span className='input__error'>
                      {lg(lang, REQUIRED_FIELD)}
                    </span>
                  ) : (
                    !icoRegex.test(icFirmy.toString()) && (
                      <span className='input__error'>
                        {lg(lang, FIRM_ID_INVALID)}
                      </span>
                    )
                  ))}
                {icFirmy &&
                  icoRegex.test(icFirmy.toString()) &&
                  !responseFindMe &&
                  !validniIcSalesForce && (
                    <span className='input__help'>
                      {lg(lang, FIRM_ID_IS_BEING_VALIDATED)}
                    </span>
                  )}
                {icFirmy &&
                  icoRegex.test(icFirmy.toString()) &&
                  responseFindMe && (
                    // ORDTEX-56
                    // validniIcSalesForce &&
                    <span className='input__help'>
                      {validateCzechIcoControlNumber(icFirmy)
                        ? responseFindMe === 'validni' ||
                          responseFindMe === 'nesplnuje_podminky' ||
                          responseFindMe === 'nevhodna_pravni_forma'
                          ? 'Vaše IČO je validní.'
                          : responseFindMe === 'jiz_zakaznikem'
                          ? 'Společnost s tímto IČO je již zákazníkem PLUXEE.'
                          : responseFindMe === 'neexistujici_ico'
                          ? 'Společnost s tímto IČO nebyla nalezena.'
                          : ''
                        : 'Společnost s tímto IČO nebyla v České republice nalezena.'}
                    </span>
                  )}
                {!responseFindMe &&
                  validniIcSalesForce &&
                  icFirmy && // check needed because we do not want to show this error if the user has not entered anything yet
                  icoRegex.test(icFirmy.toString()) && ( // check needed because we do not send request if ico is not valid
                    <span className='input__error'>
                      {lg(lang, FIRM_ID_IS_BEING_VALIDATED_FAILURE)}
                    </span>
                  )}
              </div>
              <div className='input input--required'>
                <label>{lg(lang, NUMBER_OF_EMPLOYEES)}</label>
                <input
                  value={pocetZamestnancu}
                  onChange={handleChangePocetZamestnancu}
                  placeholder={lg(lang, NUMBER_OF_EMPLOYEES_PLACEHOLDER)}
                  type='tel'
                  onKeyDown={(e) =>
                    (e.key === 'e' || e.key === '-' || e.key === '+') &&
                    e.preventDefault()
                  }
                  onWheel={(e) => e.currentTarget.blur()}
                />
                {displayAllValidationErrors && !pocetZamestnancu && (
                  <span className='input__error'>
                    {lg(lang, REQUIRED_FIELD)}
                  </span>
                )}
                {displayAllValidationErrors &&
                  pocetZamestnancu &&
                  pocetZamestnancu < 1 && (
                    <span className='input__error'>
                      {lg(lang, NUMBER_OF_EMLOYEES_TOO_LOW)}
                    </span>
                  )}
                {displayAllValidationErrors &&
                  pocetZamestnancu &&
                  pocetZamestnancu > MAXIMALNI_POCET_ZAMESTNANCU && (
                    <span className='input__error'>
                      {lg(lang, NUMBER_OF_EMLOYEES_TOO_HIGH)}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div
            className='highlight u-pr-1 u-pt-2 highlight--transparent'
            style={{
              maxWidth: '30rem',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div className='highlight__content'>
              <label className='checkbox u-mb-2 u-mt-1'>
                {lg(lang, RECIEVING_EMAILS_CONSENT)}
                <input
                  type='checkbox'
                  checked={marketingConsent}
                  onChange={() => setMarketingConsent(!marketingConsent)}
                />
                <span className='checkbox__box'></span>
              </label>
              <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: '-4.5px', visibility: 'hidden' }}>
                  <CheckIcon className='u-mr-1' width={20} height={20} />
                </div>
                <p className='u-size-base14 u-mb-4'>
                  {lg(lang, SENDING_PERSONAL_DATA_CONSENT)}
                  <button
                    className='button-as-anchor'
                    onClick={onClickUrl(urlOchranaOsobnichUdaju)}
                  >
                    {lg(lang, HERE)}
                  </button>
                  .
                </p>
              </div>
              <div className='u-text-center'>
                <ConfirmSection
                  submitIcon={submitIcon}
                  submitText={submitText}
                  nextRoute={
                    // nextRoute // debug - backend is broken.
                    isValid()
                      ? pocetZamestnancu
                        ? shallNotPass()
                          ? exitRoute
                          : nextRoute
                        : '' // pocetZamestnancu undefined
                      : '' // isValid() === false
                    // if nextRoute = '' or undefined, then it will not be redirected, only the () => setShowValidationErrors(true) will be called
                  }
                  onSubmit={submitAction}
                  nextLocationNumber={1}
                  formNotValid={!isValid()}
                />
              </div>
            </div>
          </div>
          {/* Logos - to be displayed on mobile */}
          <div className='grid-x align-spaced align-middle u-mt-4 u-mb-4 u-hide-xlarge-up'>
            <Logos />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferAndLead
