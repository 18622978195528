import { newDate } from './DateOps'

export type MultiPassCard = {
  id: string // '' means new card, have not received the number from SPO yet
  datumObjednani: Date
  datumExpirace: Date
  status: MultiPassCardState
  cardNumber: string
  type: string
  orderId: number
}

export enum MultiPassCardState {
  VKošíku = 'V košíku',
  NáhradníKartaVKošíku = 'Náhradní karta v košíku',
  VeVýrobě = 'Ve výrobě',
  Aktivní = 'Aktivní',
  Expirovaná = 'Expirovaná',
  Blokovaná = 'Blokovaná',
  Zrušená = 'Zrušená',
  PřijataDoVýroby = 'Ve výrobě', // our fix for SPO status 1
  KAktivaci = 'K aktivaci',
  ZrušenaVeVýrobě = 'Zrušena ve výrobě',
  Vyrobená = 'Vyrobená',
  Ztracená = 'Ztracená',
  NahlášenaZtráta = 'Nahlášena ztráta',
  PodezřeléChování = 'Podezřelé chování',
  Zcizená = 'Zcizená',
  NahlášenoZcizení = 'Nahlášeno zcizení',
  BlokovánaProPozdníPlatby = 'Blokována pro pozdní platby',
  BlokovánaProChybněZadanýPIN = 'Blokována pro chybně zadaný PIN',
  BlokovánaNaŽádostDržitele = 'Blokována na žádost držitele',
  Zamítnutá = 'Zamítnutá',
  Schválená = 'Schválená',
  ČekáNaSchválení = 'Čeká na schválení',
  ŽádostOResetPINu = 'Žádost o reset PINu',
  Neznámý = 'Neznámý stav karty',
  Žádná = 'Žádná karta',
}

export function getDisplayCardStatusForEmployee(
  cards: MultiPassCard[]
): MultiPassCardState {
  if (cards.length === 0) return MultiPassCardState.Žádná

  const existsCardVeVyrobe = cards.find(
    (card) => card.status === MultiPassCardState.VeVýrobě
  )
  const existsCardAktivni = cards.find(
    (card) => card.status === MultiPassCardState.Aktivní
  )
  const existsCardExpirovana = cards.find(
    (card) => card.status === MultiPassCardState.Expirovaná
  )
  const existsCardBlokovana = cards.find(
    (card) => card.status === MultiPassCardState.Blokovaná
  )
  const existsCardPrijataDoVyroby = cards.find(
    (card) => card.status === MultiPassCardState.PřijataDoVýroby
  )
  const existsCardVyrobena = cards.find(
    (card) => card.status === MultiPassCardState.Vyrobená
  )

  // Priority: Aktivní > Vyrobená > Ve výrobě > Blokovaná > Expirovaná
  const status = existsCardAktivni
    ? MultiPassCardState.Aktivní
    : existsCardVyrobena
    ? MultiPassCardState.Vyrobená
    : existsCardPrijataDoVyroby || existsCardVeVyrobe
    ? MultiPassCardState.VeVýrobě
    : existsCardBlokovana
    ? MultiPassCardState.Blokovaná
    : existsCardExpirovana
    ? MultiPassCardState.Expirovaná
    : // : MultiPassCardState.Neznámý // this should never happen
      cards[0].status // if none of the cards have a status of which we know the priority, we return the first card's status
  return status
}

export function newCard(orderId: number): MultiPassCard {
  const now = newDate()
  const expirationDate = new Date(now)
  var numberOfDaysToAdd = 365 * 2
  expirationDate.setDate(expirationDate.getDate() + numberOfDaysToAdd)
  return {
    id: uuidv4(),
    datumObjednani: now,
    datumExpirace: expirationDate,
    status: MultiPassCardState.VKošíku,
    cardNumber: '',
    type: '',
    orderId: orderId,
  }
}

function uuidv4() {
  return crypto.getRandomValues(new Uint32Array(4)).join('-')
}

export function newStravovaniCardNumber(): string {
  // random number from 1000 to 9999
  const randomNumber1 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
  // random number from 1000 to 9999
  const randomNumber2 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
  return `${randomNumber1} **** **** ${randomNumber2}`
}

export function newVolnocasCardNumber(): string {
  // random number from 1000 to 9999
  const randomNumber1 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
  // random number from 1000 to 9999
  const randomNumber2 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
  return `${randomNumber1} **** **** ${randomNumber2}`
}
