export const REQUIRED_FIELD = 'REQUIRED_FIELD'

export const WORK_EMAIL = 'WORK_EMAIL'
export const WORK_EMAIL_PLACEHOLDER = 'WORK_EMAIL_PLACEHOLDER'
export const EMAIL_INVALID = 'EMAIL_INVALID'
export const COUNTRY = 'COUNTRY'
export const PHONE = 'PHONE'
export const PHONE_PLACEHOLDER = 'PHONE_PLACEHOLDER'
export const PHONE_INVALID = 'PHONE_INVALID'
export const FIRM_ID = 'FIRM_ID'
export const FIRM_ID_PLACEHOLDER = 'FIRM_ID_PLACEHOLDER'
export const FIRM_ID_INVALID = 'FIRM_ID_INVALID'
export const FIRM_ID_IS_BEING_VALIDATED = 'FIRM_ID_IS_BEING_VALIDATED'
export const FIRM_ID_IS_BEING_VALIDATED_SUCCESS =
  'FIRM_ID_IS_BEING_VALIDATED_SUCCESS'
export const FIRM_ID_IS_BEING_VALIDATED_FAILURE =
  'FIRM_ID_IS_BEING_VALIDATED_FAILURE'
export const NUMBER_OF_EMPLOYEES = 'NUMBER_OF_EMPLOYEES'
export const NUMBER_OF_EMPLOYEES_PLACEHOLDER = 'NUMBER_OF_EMPLOYEES_PLACEHOLDER'
export const NUMBER_OF_EMLOYEES_TOO_LOW = 'NUMBER_OF_EMLOYEES_TOO_LOW'
export const NUMBER_OF_EMLOYEES_TOO_HIGH = 'NUMBER_OF_EMLOYEES_TOO_HIGH'
export const RECIEVING_EMAILS_CONSENT = 'RECIEVING_EMAILS_CONSENT'
export const SENDING_PERSONAL_DATA_CONSENT = 'SENDING_PERSONAL_DATA_CONSENT'
export const HERE = 'HERE'
export const GO_TO_PRICE_OFFER = 'GO_TO_PRICE_OFFER'

export const WHERE_TO_SHOP = 'WHERE_TO_SHOP'

export const OFFER_LEAD_HEADER = 'OFFER_LEAD_HEADER'
export const OFFER_LEAD_SUBHEADER = 'OFFER_LEAD_SUBHEADER'
export const OFFER_LEAD_STEP_1 = 'OFFER_LEAD_STEP_1'
export const OFFER_LEAD_STEP_2 = 'OFFER_LEAD_STEP_2'
export const OFFER_LEAD_STEP_3 = 'OFFER_LEAD_STEP_3'
export const OFFER_LEAD_STEP_4 = 'OFFER_LEAD_STEP_4'

export const CONTINUE_NEXT_STEP = 'CONTINUE_NEXT_STEP'
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS'
export const OB1_HEADER = 'OB1_HEADER'
export const OB1_SUBHEADER_1 = 'OB1_SUBHEADER_1'
export const OB1_SUBHEADER_2 = 'OB1_SUBHEADER_2'
export const CALCULATION = 'CALCULATION'
export const FIRM_INFORMATION = 'FIRM_INFORMATION'
export const CONTRACT = 'CONTRACT'
export const LOGIN_CREDENTIALS = 'REGISTRATION_OR_LOGIN'
export const OUR_OFFER = 'OUR_OFFER'
export const NUMBER_OF_WORKED_DAYS = 'NUMBER_OF_WORKED_DAYS'
export const DAILY_FOOD_DOTATION = 'DAILY_FOOD_DOTATION'
export const MONTHLY_LEASURE_CREDIT = 'MONTHLY_LEASURE_CREDIT'
export const VALUES_CAN_BE_CHANGED = 'VALUES_CAN_BE_CHANGED'
export const MONTHLY_FEES = 'MONTHLY_FEES'
export const YOUR_PRICE = 'YOUR_PRICE'
export const MEAL_VOUCHER_CREDIT = 'MEAL_VOUCHER_CREDIT'
export const LEISURE_CREDIT = 'LEISURE_CREDIT'
export const CREDIT_ORDER_FEE = 'CREDIT_ORDER_FEE'
export const ORDER_FEE = 'ORDER_FEE'
export const ACCOUNT_MANAGEMENT_FEE = 'ACCOUNT_MANAGEMENT_FEE'
export const TOTAL_PRICE = 'TOTAL_PRICE'
export const PRICE_WITHOUT_VAT = 'PRICE_WITHOUT_VAT'
export const ONE_TIME_FEES = 'ONE_TIME_FEES'
export const CARDS_ISSUANCE = 'CARDS_ISSUANCE'
export const CARDS_DELIVERY = 'CARDS_DELIVERY'
export const TO_THE_COMPANY_ADDRESS = 'TO_THE_COMPANY_ADDRESS'
export const PCS = 'PCS'
export const GO_TO_TERMS_AND_CONDITIONS = 'GO_TO_TERMS_AND_CONDITIONS'

export const LENGTH_OF_CONTRACT_PERIOD = 'LENGTH_OF_CONTRACT_PERIOD'
export const INDEFINITE = 'INDEFINITE'
export const NOTICE_PERIOD = 'NOTICE_PERIOD'
export const MONTHS_TWO = 'MONTHS_TWO'
export const MONTHS_FIVE = 'MONTHS_FIVE'
export const MIN_CREDITS_ORDER_PER_MONTH = 'MIN_CREDITS_ORDER_PER_MONTH'
export const NONE = 'NONE'
export const MATURITY = 'MATURITY'
export const BEFORE_DELIVERY = 'BEFORE_DELIVERY'
export const COMMON_PRICE = 'COMMON_PRICE'
export const DELIVERY_TO_THE_COMPANY_ADDRESS = 'DELIVERY_TO_THE_COMPANY_ADDRESS'
export const MONTHLY_USER_ACCOUNT_MANAGEMENT = 'MONTHLY_USER_ACCOUNT_MANAGEMENT'
export const FEE_FOR_ACTIVE_USER_ACCOUNT = 'FEE_FOR_ACTIVE_USER_ACCOUNT'

// Onboarding TWO
export const OB2_SUBHEADER = 'OB2_SUBHEADER'

// Firm info
export const FIRM_NAME = 'FIRM_NAME'
export const FOR_EXAMPLE = 'FOR_EXAMPLE'
export const STREET_HOUSE_NUMBER = 'STREET_HOUSE_NUMBER'
export const INVALID_STREET_HOUSE_NUMBER = 'INVALID_STREET_HOUSE_NUMBER'
export const CITY = 'CITY'
export const ZIP = 'ZIP'
export const INVALID_ZIP = 'INVALID_ZIP'

// Authorized representatives
export const AUTHORIZED_PERSON = 'AUTHORIZED_PERSON'
export const AUTHORIZED_PERSON_ADDITIONAL_INFO =
  'AUTHORIZED_PERSON_ADDITIONAL_INFO'
export const ADD_ANOTHER_PERSON = 'ADD_ANOTHER_PERSON'

export const CLOSE = 'CLOSE'

// Onboarding THREE
export const AFFIDAVIT = 'AFFIDAVIT'
export const AFFIDAVIT_CONTENT = 'AFFIDAVIT_CONTENT'
export const SIGNING_CONTRACT = 'SIGNING_CONTRACT'
export const OB3_SUBHEADER = 'OB3_SUBHEADER'
export const YOUR_DATA = 'YOUR_DATA'
export const EDIT = 'EDIT'
export const DRAFT_CONTRACT = 'DRAFT_CONTRACT'
export const STATUTORY_BODY_TEXT = 'STATUTORY_BODY_TEXT'
export const SEE = 'SEE'
export const AFFIDAVIT_1 = 'AFFIDAVIT_1'
export const ONLY_A_STATUTORY_BODY_CAN_CONFIRM_THE_CONTRACT =
  'ONLY_A_STATUTORY_BODY_CAN_CONFIRM_THE_CONTRACT'
export const SIGNED_BY_SENDING = 'SIGNED_BY_SENDING'
export const OB3_BUTTON = 'OB3_BUTTON'

// Onboarding FOUR
export const OB4_SUBHEADER_1 = 'OB4_SUBHEADER_1'
export const OB4_SUBHEADER_2 = 'OB4_SUBHEADER_2'
export const NAME = 'NAME'
export const SURNAME = 'SURNAME'
export const EMAIL_FOR_LOGIN = 'EMAIL_FOR_LOGIN'
export const CONFIRM_EMAIL_FOR_LOGIN = 'CONFIRM_EMAIL_FOR_LOGIN'
export const CONFIRM_EMAIL_FOR_LOGIN_INFO = 'CONFIRM_EMAIL_FOR_LOGIN_INFO'
export const LOGIN_CREDENTIALS_NEW_USER_INFO = 'LOGIN_CREDENTIALS_NEW_USER_INFO'
export const COMPLETE = 'COMPLETE'

// Onboarding SUCCESS
export const OB_SUCCESS_HEADER = 'OB_SUCCESS_HEADER'
export const OB_SUCCESS_SUBHEADER = 'OB_SUCCESS_SUBHEADER'
export const OB_SUCCESS_TEXT = 'OB_SUCCESS_TEXT'
export const OB_SUCCESS_BUTTON = 'OB_SUCCESS_BUTTON'

export const CATALOG_OF_ESTABLISHMENTS = 'CATALOG_OF_ESTABLISHMENTS'

// ORDER PAGE
export const NEW_ORDER = 'NEW_ORDER'
export const DO_YOU_NEED_HELP = 'DO_YOU_NEED_HELP'
export const CREATING_A_NEW_ORDER = 'CREATING_A_NEW_ORDER'
export const MANUALLY = 'MANUALLY'
export const BY_IMPORTING_DATA = 'BY_IMPORTING_DATA'

// HELP MODAL
export const HM_STEP_1 = 'HM_STEP_1'
export const HM_STEP_1_BOLD = 'HM_STEP_1_BOLD'
export const HM_STEP_2 = 'HM_STEP_2'
export const ADD_TO_CART = 'ADD_TO_CART'
export const HM_STEP_3 = 'HM_STEP_3'
export const HM_STEP_4 = 'HM_STEP_4'
export const HM_STEP_5 = 'HM_STEP_5'
export const HM_STEP_6 = 'HM_STEP_6'
export const HM_STEP_6_BOLD = 'HM_STEP_6_BOLD'
export const HM_QUESTIONS = 'HM_QUESTIONS'
export const HM_IMPORT_STEP_1 = 'HM_IMPORT_STEP_1'
export const HM_IMPORT_STEP_1_BOLD = 'HM_IMPORT_STEP_1_BOLD'
export const HM_IMPORT_STEP_2 = 'HM_IMPORT_STEP_2'
export const HM_IMPORT_STEP_2_BOLD = 'HM_IMPORT_STEP_2_BOLD'
export const CONTACT_PERSON = 'CONTACT_PERSON'
export const NAME_SURNAME_FIRST_CELL = 'NAME_SURNAME_FIRST_CELL'
export const PHONE_NUMBER_OF_THE_CONTACT_PERSON =
  'PHONE_NUMBER_OF_THE_CONTACT_PERSON'
export const HM_IMPORT_STEP_3 = 'HM_IMPORT_STEP_3'
export const SAVE = 'SAVE'
export const HM_FOOTER = 'HM_FOOTER'
export const MON_FRI = 'MON_FRI'
export const NAME_AND_SURNAME = 'NAME_AND_SURNAME'
export const HM_AE_STEP_1 = 'HM_AE_STEP_1'
export const EMPLOYEE_ID = 'EMPLOYEE_ID'
export const HM_AE_STEP_2 = 'HM_AE_STEP_2'
export const ADDRESS_FOR_CARDS_DELIVERY = 'ADDRESS_FOR_CARDS_DELIVERY'
export const NEW_DELIVERY_ADDRESS = 'NEW_DELIVERY_ADDRESS'
export const HM_AE_STEP_3_1 = 'HM_AE_STEP_3_1'
export const HM_AE_STEP_3_2 = 'HM_AE_STEP_3_2'

// TableContainer
export const CARDS = 'CARDS'
export const CREDIT = 'CREDIT'
export const SELECTED = 'SELECTED'

// TableThree
export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE'
export const CANCEL = 'CANCEL'
export const FILTER = 'FILTER'
export const TO_FILTER = 'TO_FILTER'
export const TO_SEARCH_IN = 'TO_SEARCH_IN'
export const DATA_IMPORT = 'DATA_IMPORT'
export const DEACTIVATED = 'DEACTIVATED'
export const ACTIVATED = 'ACTIVATED'
export const ACTIVE = 'ACTIVE'
export const EMPL_ID = 'EMPL_ID'
export const CARD = 'CARD'
export const REPLACEMENT_CARD = 'REPLACEMENT_CARD'
export const CREDIT_AMOUNT = 'CREDIT_AMOUNT'
export const DAYS_WORKED = 'DAYS_WORKED'
export const NO_RECORD_FOUND = 'NO_RECORD_FOUND'
export const SELECT_EMPLOYEES_BULK_ACTION = 'SELECT_EMPLOYEES_BULK_ACTION'
export const RECORD = 'RECORD'
export const RECORDS = 'RECORDS'
export const ORDER_STATUS = 'ORDER_STATUS'
export const SHIPMENT_STATUS = 'SHIPMENT_STATUS'
export const CARD_STATUS = 'CARD_STATUS'
export const ENTER_AMOUNT = 'ENTER_AMOUNT'
export const ENTER_NUMBER_OF_DAYS = 'ENTER_NUMBER_OF_DAYS'

// SideMenu
export const ORDERS_OVERVIEW = 'ORDERS_OVERVIEW'
export const ADMINISTRATION = 'ADMINISTRAION'
export const SETTINGS = 'SETTINGS'

// EmployeeAdd
export const EMPLOYEE_ID_HELP = 'EMPLOYEE_ID_HELP'
export const EMPLOYEE_ADD_DETAIL_1 = 'EMPLOYEE_ADD_DETAIL_1'
export const EMPLOYEE_ADD_DETAIL_2 = 'EMPLOYEE_ADD_DETAIL_2'
export const DEPARTMENT = 'DEPARTMENT'
export const ASSIGN_DEPARTMENT = 'ASSIGN_DEPARTMENT'
export const DEPARTMENT_NAME = 'DEPARTMENT_NAME'
export const ADD = 'ADD'
export const SEND_INFO_TO_YOUR_PHONE = 'SEND_INFO_TO_YOUR_PHONE'
export const NOT_REQUIRED = 'NOT_REQUIRED'
export const SAVE_ADDRESS = 'SAVE_ADDRESS'
export const NONE_N = 'NONE_N'

// Contracts
export const DOWNLOAD_ALL = 'DOWNLOAD_ALL'

// OrderSummary
export const ORDER_SUMMARY = 'ORDER_SUMMARY'
export const CHARGED = 'CHARGED'
export const FOR_EACH_EMPLOYEE_AND_MONTH = 'FOR_EACH_EMPLOYEE_AND_MONTH'
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT'
export const NO_VAT = 'NO_VAT'
export const ADDRESSES_FOR_DELIVERY = 'ADDRESSES_FOR_DELIVERY'
export const DELIVERY_ADDRESSES = 'DELIVERY_ADDRESSES'
export const FOR = 'FOR'
export const BILLING_INFORMATION = 'BILLING_INFORMATION'
export const NOTE_ON_INVOICE = 'NOTE_ON_INVOICE'
export const CONTACT_FOR_INVOICE = 'CONTACT_FOR_INVOICE'
export const INVOICE_EMAIL_MISSING = 'INVOICE_EMAIL_MISSING'
export const CARDS_DELIVERY_DATE = 'CARDS_DELIVERY_DATE'
export const CARDS_DELIVERY_INFO = 'CARDS_DELIVERY_INFO'
export const CREDIT_ASSIGNMENT = 'CREDIT_ASSIGNMENT'
export const CREDIT_ASSIGNMENT_INFO = 'CREDIT_ASSIGNMENT_INFO'
export const SEND_THE_ORDER = 'SEND_THE_ORDER'
export const AMOUNT_TO_BE_PAID = 'AMOUNT_TO_BE_PAID'
export const TO_EMAIL = 'TO_EMAIL'
export const THE_PAYMENT_DETAILS_WERE_SENT = 'THE_PAYMENT_DETAILS_WERE_SENT'
export const ORDERS = 'ORDERS'
export const CONFIRM_DELETING_WHOLE_COLUMN = 'CONFIRM_DELETING_WHOLE_COLUMN'
export const TO_EMPTY = 'TO_EMPTY'
export const DAILY_CREDIT_SETTING = 'DAILY_CREDIT_SETTING'
export const DAILY_CREDIT_SETTING_INFO = 'DAILY_CREDIT_SETTING_INFO'
export const SET = 'SET'
export const ORDER_DATE = 'ORDER_DATE'
export const ORDERED_BY = 'ORDERED_BY'
export const ORDER_NUMBER = 'ORDER_NUMBER'
export const AMOUNT_NO_VAT = 'AMOUNT_NO_VAT'
export const NOTE = 'NOTE'
export const CARD_SHIPMENT = 'CARD_SHIPMENT'
export const FOR_DOWNLOAD = 'FOR_DOWNLOAD'
export const YOU_HAVE = 'YOU_HAVE'
export const INVOICE = 'INVOICE'
export const PROFORMA = 'PROFORMA'
export const ACCOUNTS_AND_CARDS = 'ACCOUNTS_AND_CARDS'
export const EMPLOYEE_DATA = 'EMPLOYEE_DATA'
export const IN_TOTAL = 'IN_TOTAL'
export const ADD_ORDER_TO_CART = 'ADD_ORDER_TO_CART'
export const ID_CAN_NO_LONGER_BE_CHANGED = 'ID_CAN_NO_LONGER_BE_CHANGED'
export const CARD_DETAILS = 'CARD_DETAILS'
export const SUBSTITUTE = 'SUBSTITUTE'
export const NEW_F = 'NEW_F'
export const CARD_IN_CART = 'CARD_IN_CART'
export const DELETE_ADDRESS = 'DELETE_ADDRESS'
export const ADD_ANOTHER_ADDRESS = 'ADD_ANOTHER_ADDRESS'

// Settings
export const FIRM_ID_HELP = 'FIRM_ID_HELP'
export const SAVE_CHANGES = 'SAVE_CHANGES'
export const ADDRESS_MANAGEMENT = 'ADDRESS_MANAGEMENT'
export const CONTACT_PERSONS_MANAGEMENT = 'CONTACT_PERSONS_MANAGEMENT'
export const DELIVERY_ADDRESS_HELP = 'DELIVERY_ADDRESS_HELP'
export const DELETE_CONTACT_PERSON = 'DELETE_CONTACT_PERSON'

// Error
export const ERROR_OCCURRED = 'ERROR_OCCURRED'
