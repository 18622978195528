import React, { useState } from 'react'
import {
  urlBackend,
  urlMultiPassCard,
  urlOnboardingSuccess,
  urlReconSuccess,
} from 'App'
import {
  NAME,
  FOR_EXAMPLE,
  SURNAME,
  EMAIL_FOR_LOGIN,
  CONFIRM_EMAIL_FOR_LOGIN,
  CONFIRM_EMAIL_FOR_LOGIN_INFO,
} from 'app/constDictionary'
import { lg } from 'app/facade'
import { Role } from 'app/type'
import { govActions } from 'components/settings/governanceSlice'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { Formik, Form, Field } from 'formik'
import ConfirmSection from '../ConfirmSection'
import {
  reducerKontaktniEmail,
  selectPracovniEmail,
} from '../features/objednavatelSlice'
import { reducerCurrentLocation } from '../features/stepperSlice'
import DirectionIcon from '@styles/icon/directionIcon'
import { setPrecious } from 'app/back'
import {
  RECON_EMAIL_COM,
  SOUHLAS_SE_ZASILANIM_INFORMACI,
  ZADANE_EMAILOVE_ADRESY_SE_NESHODUJI,
} from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { validateEmail, validateJmeno } from 'util/ValidFormatOps'
import { selectIcoFirmy } from '../features/firemniUdajeSlice'
import { Navigate } from 'react-router-dom'
import Color from 'app/colors'

interface Props {
  onSubmit: () => void
}

const OnboardingFourRegistrovatSeDanyil: React.FC<Props> = ({ onSubmit }) => {
  const dispatch = useAppDispatch()
  const [redirect, setRedirect] = useState(false)

  const lang = useAppSelector(selectLanguage) || 'cz'

  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const icSpolecnosti = useAppSelector(selectIcoFirmy)

  const submitIcon = <DirectionIcon />
  const submitText = 'Registrovat se'
  const nextRoute = window.location.href.includes(urlMultiPassCard)
    ? urlOnboardingSuccess
    : urlReconSuccess
  const nextLocationNumber = 5

  async function callBackend(
    emailProPrihlaseni: string,
    jmeno: string,
    prijmeni: string
  ) {
    console.log('Submit - Onb4 - callingbackend')

    const requestBody = JSON.stringify({
      email: pracovniEmail || RECON_EMAIL_COM,
      userEmail: emailProPrihlaseni,
      name: jmeno,
      surname: prijmeni,
      ico: icSpolecnosti,
      marketingConsent: SOUHLAS_SE_ZASILANIM_INFORMACI,
    })

    console.log('Request Body, ', requestBody)

    await fetch(urlBackend + '/onboarding/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        data.jwt && setPrecious(data.jwt)

        setRedirect(true)
        return data
      })
      .catch((error) => console.log(error))
  }

  // Synchronous validation
  const validate = (values: {
    emailProPrihlaseni: string
    repeatedEmail: string
    jmeno: string
    prijmeni: string
  }) => {
    const errors = {
      emailProPrihlaseni: validateEmail(values.emailProPrihlaseni),
      repeatedEmail: validateEmail(values.repeatedEmail),
      jmeno: validateJmeno(values.jmeno),
      prijmeni: validateJmeno(values.prijmeni),
    }

    if (values.emailProPrihlaseni !== values.repeatedEmail) {
      errors.repeatedEmail = ZADANE_EMAILOVE_ADRESY_SE_NESHODUJI
    }

    let valid =
      errors.emailProPrihlaseni === '' &&
      errors.repeatedEmail === '' &&
      errors.jmeno === '' &&
      errors.prijmeni === ''
        ? {}
        : errors

    return valid
  }

  return (
    <div className='grid-y'>
      {redirect && <Navigate to={nextRoute} />}
      <Formik
        initialValues={{
          emailProPrihlaseni: pracovniEmail || '',
          repeatedEmail: '',
          jmeno: '',
          prijmeni: '',
        }}
        onSubmit={(values) => {
          dispatch(reducerKontaktniEmail(values.emailProPrihlaseni))
          dispatch(reducerCurrentLocation(nextLocationNumber))
          dispatch(
            govActions.addUser({
              jmeno: values.jmeno,
              prijmeni: values.prijmeni,
              email: values.emailProPrihlaseni,
              role: Role.Vytvořená,
            })
          )
          dispatch(
            govActions.reducerLogin({
              email: values.emailProPrihlaseni,
            })
          )
          callBackend(values.emailProPrihlaseni, values.jmeno, values.prijmeni)
          onSubmit()
        }}
        validate={validate}
      >
        {({ errors, touched, submitForm }) => (
          <>
            <div
              className='highlight highlight--wide'
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: `${Color.BLUE_DANYIL}`,
              }}
            >
              <div className='highlight__content u-mt-3'>
                <div>
                  <Form id='onboarding-4-form'>
                    <div className='grid-y'>
                      <div className='grid-x u-justify-center'>
                        <div className='input large-5 u-mb-4 input--required'>
                          <label>{lg(lang, NAME)}</label>
                          <Field
                            type='text'
                            placeholder={`${lg(lang, FOR_EXAMPLE)} Jan`}
                            name='jmeno'
                          />
                          {errors.jmeno && touched.jmeno && (
                            <span className='input__error'>{errors.jmeno}</span>
                          )}
                        </div>
                        <div className='input large-5 u-ml-8 u-mb-4 input--required'>
                          <label>{lg(lang, SURNAME)}</label>
                          <Field
                            type='text'
                            placeholder={`${lg(lang, FOR_EXAMPLE)} Novák`}
                            name='prijmeni'
                          />
                          {errors.prijmeni && touched.prijmeni && (
                            <span className='input__error'>
                              {errors.prijmeni}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='grid-x u-justify-center'>
                        <div className='input large-5 u-mb-4 input--required'>
                          <label>{lg(lang, EMAIL_FOR_LOGIN)}</label>
                          <Field
                            type='email'
                            placeholder={`${lg(
                              lang,
                              FOR_EXAMPLE
                            )} novak@pluxee.cz`}
                            name='emailProPrihlaseni'
                          />
                          {errors.emailProPrihlaseni &&
                            touched.emailProPrihlaseni && (
                              <span className='input__error'>
                                {errors.emailProPrihlaseni}
                              </span>
                            )}
                        </div>
                        <div className='input large-5 u-ml-8 u-mb-3 input--required'>
                          <label>{lg(lang, CONFIRM_EMAIL_FOR_LOGIN)}</label>
                          <Field
                            type='email'
                            placeholder={`${lg(
                              lang,
                              FOR_EXAMPLE
                            )} novak@pluxee.cz`}
                            name='repeatedEmail'
                          />
                          {errors.repeatedEmail && touched.repeatedEmail ? (
                            <span className='input__error'>
                              {errors.repeatedEmail}
                            </span>
                          ) : (
                            <span className='input__help'>
                              {lg(lang, CONFIRM_EMAIL_FOR_LOGIN_INFO)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className='u-text-center'>
                <ConfirmSection
                  submitText={submitText}
                  nextRoute={''}
                  onSubmit={() => {
                    submitForm()
                  }}
                  nextLocationNumber={nextLocationNumber}
                  disabled={Object.keys(errors).length > 0}
                  customClassName="btn--dark-red"
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
}

export default OnboardingFourRegistrovatSeDanyil
