import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class GeoPinOutlineIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'geo-pin-outline-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 1.334h.327C22.764 1.551 28 6.766 28 13.284c0 9.344-10.91 16.949-11.236 17.166-.218.109-.437.217-.655.217-.218 0-.436 0-.545-.108-.11 0-.219-.109-.328-.218C13.6 29.255 4 22.085 4 13.285 4 6.657 9.345 1.334 16 1.334zm-9.818 11.95c0 7.062 7.636 13.364 9.818 14.993 2.29-1.738 9.818-7.93 9.818-14.992 0-5.433-4.364-9.778-9.818-9.778h-.218c-5.346.108-9.6 4.454-9.6 9.778zm4.484.05c0-2.987 2.347-5.334 5.333-5.334 2.987 0 5.334 2.347 5.334 5.333 0 2.987-2.347 5.334-5.334 5.334-2.986 0-5.333-2.347-5.333-5.334zm2.133 0c0 1.813 1.387 3.2 3.2 3.2 1.814 0 3.2-1.387 3.2-3.2 0-1.814-1.386-3.2-3.2-3.2-1.813 0-3.2 1.386-3.2 3.2z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default GeoPinOutlineIcon
