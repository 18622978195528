import React, { FunctionComponent, useState, useEffect } from 'react'
import PdfComponent from './PdfComponent'

// The below URL gets opened on prod after clicking on this component.
// http://20.86.12.59/cz.sodexo.de/bundles/adastrafrontend/static/media/szb-sablona.ee1aa4c20ff7713d99db.pdf
// The below URL gets opened on localhost after clicking on this component.
// http://localhost:3000/static/media/szb-sablona.ee1aa4c20ff7713d99db.pdf
// The difference is in that the "homepage" is not the same on localhost and prod.
// Homepage gets auto-added to resources in prod.
// Also, notice how @resources translates to /static/media in both prod and localhost.
import PdfSablonaSmlouvaOZprostredkovaniBenefitu from '@resources/szb-sablona.pdf'
import PdfSablonaVop from '@resources/vop-sablona.pdf'
import PdfSablonaCenik from '@resources/cenik-sablona.pdf'
import PdfSablonaReklamacniRad from '@resources/reklamacni-rad-sablona.pdf'
import PdfSablonaIndividualniCenik from '@resources/ind-cenik-sablona.pdf'

import { useAppSelector } from 'app/hooks'
import {
  selectNazevSpolecnosti,
  selectUliceCisloPopisne,
  selectMesto,
  selectPsc,
  selectOpravneneOsoby,
  selectIcoFirmy,
  selectDicFirmy,
} from 'components/onboarding/features/firemniUdajeSlice'
import { selectPracovniEmail } from 'components/onboarding/features/objednavatelSlice'
import { selectPocetZamestnancu } from './features/mesicniNakladySlice'
import { splitUliceCisloPopisne } from 'util/ValidFormatOps'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import icons from '@styles/dist/system/icons.svg'
import Color from 'app/colors'
import NoHrefPdfComponent from './NoHrefPdfComponent'
import {
  beContract,
  beIndividualniCenik,
  uriStaticMedia,
  beSignedContract,
  beSignedIndividualniCenik,
  beIndividualniCenikWatermark,
  beContractWatermark,
} from 'App'
import { loadAndOpenFile, loadFile, loadResource } from 'app/req'
import { defaultOptionsGet } from 'app/back'
import { ErrorModal } from 'components/modules/errorhandler/ErrorModal'
import { generateInfoMessage } from 'app/util'
import { lg } from 'app/facade'
import { DOWNLOAD_ALL } from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { RECON_EMAIL_COM } from 'app/constants'

interface SmlouvyProps {
  label?: string
  onboardingOne?: boolean
  settings?: boolean
}

const Smlouvy: FunctionComponent<SmlouvyProps> = (props) => {
  const icSpolecnosti = useAppSelector(selectIcoFirmy)
  const dicSpolecnosti = useAppSelector(selectDicFirmy)
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne)
  const mesto = useAppSelector(selectMesto)
  const psc = useAppSelector(selectPsc)?.toString().replace(/\s/g, '') // remove whitespace for backend ORDTEX-193
  const opravneneOsoby = useAppSelector(selectOpravneneOsoby)
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne!)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const [
    loadingSmlouvaOZprostredkovaniBenefitu,
    setLoadingSmlouvaOZprostredkovaniBenefitu,
  ] = React.useState(false)
  const [loadingIndividualPricelist, setLoadingIndividualPricelist] =
    React.useState(false)
  const [loadingZip, setLoadingZip] = React.useState(false)

  const [noContractFound, setNoContractFound] = React.useState(false)
  const [noIndividualPricelistFound, setNoIndividualPricelistFound] =
    React.useState(false)

  const requestObjectContract = {
    email: pracovniEmail || RECON_EMAIL_COM,
    company_name: nazevSpolecnosti,
    ico: icSpolecnosti,
    dic: dicSpolecnosti,
    street_address: ulice,
    street_number: cisloPopisne,
    address_locality: mesto,
    address_postal_code: psc,
    persons: opravneneOsoby,
    employees: +(pocetZamestnancu as number),
    preview: props.onboardingOne,
  }

  const requestObjectIndividualPricelist = {
    ico: icSpolecnosti,
    size: +(pocetZamestnancu as number),
    preview: props.onboardingOne,
  }

  async function stahnoutVse() {
    setLoadingZip(true)

    if (props.settings) {
      const signedContractResponse = await fetch(
        beSignedContract,
        defaultOptionsGet()
      )
      if (signedContractResponse.status !== 200) {
        setNoContractFound(true)
        setLoadingZip(false)
        return
      }
    }
    const jzip = new JSZip()

    // todo In the future, the frontend must not depend on file names on the server. let's push be to implement the endpoints.
    const vop = loadResource(
      uriStaticMedia + '/vop-sablona.b737cc8b6d4fac4a5664.pdf' // todo we updated the file, update this hash
    )
    const cenik = loadResource(
      uriStaticMedia + '/cenik-sablona.7632e4d61e73e2d0a27d.pdf'
    )
    const reklamacniRad = loadResource(
      uriStaticMedia + '/reklamacni-rad-sablona.f1fba9bc73f5b1777747.pdf'
    )

    jzip.file('Reklamační řád.pdf', reklamacniRad)
    jzip.file('VOP-cenik.pdf', cenik)
    jzip.file('VOP.pdf', vop)

    if (props.onboardingOne) {
      // const smlouvaOZprostredkovaniBenefituSablona = loadResource(
      //   uriStaticMedia + '/szb-sablona.ee1aa4c20ff7713d99db.pdf'
      // )
      // const cenikSablona = loadResource(
      //   uriStaticMedia + '/ind-cenik-sablona.095555ce50e75b3e9a7d.pdf'
      // )
      // jzip.file(
      //   'Smlouva o zprostředkování benefitů.pdf',
      //   smlouvaOZprostredkovaniBenefituSablona
      // )
      // jzip.file('Individualní ceník.pdf', cenikSablona)
      jzip.file(
        'Individualní ceník.pdf',
        await loadFile(beIndividualniCenikWatermark, 'POST', {
          ico: icSpolecnosti,
          size: +(pocetZamestnancu as number),
        })
      )
      jzip.file(
        'Smlouva o zprostředkování benefitů.pdf',
        await loadFile(beContractWatermark, 'POST', requestObjectContract)
      )
    } else if (props.settings) {
      jzip.file(
        'Individualní ceník.pdf',
        await loadFile(beSignedIndividualniCenik)
      )
      jzip.file(
        'Smlouva o zprostředkování benefitů.pdf',
        await loadFile(beSignedContract)
      )
    } else {
      // onboarding three
      jzip.file(
        'Individualní ceník.pdf',
        await loadFile(beIndividualniCenik, 'POST', {
          ico: icSpolecnosti,
          size: +(pocetZamestnancu as number),
        })
      )
      jzip.file(
        'Smlouva o zprostředkování benefitů.pdf',
        await loadFile(beContract, 'POST', requestObjectContract)
      )
    }

    jzip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'Smlouvy.zip')
    })

    setLoadingZip(false)
  }

  return (
    <div>
      {props.label && <h3 className='u-mb-0'>{props.label}</h3>}
      <div className='u-pb-0 u-pt-2 u-pr-0'>
        {/* LEFT */}
        <div
          style={{
            marginBottom: props.onboardingOne ? '-4.5px' : '0px',
          }}
        >
          <div className='grid-x u-pb-05'>
            <div className='cell medium-7'></div>
            <div
              className='cell medium-5'
              style={{
                marginRight: props.onboardingOne ? '-32px !important' : '0px',
                marginLeft: props.onboardingOne ? '340px' : '0px',
                marginTop: props.onboardingOne ? '-16px' : '0px',
                paddingBottom: props.onboardingOne ? '16px' : '0px',
                zIndex: props.onboardingOne ? 3 : 1,
              }}
            >
              <button
                onClick={stahnoutVse}
                className='button-as-anchor'
                style={{
                  color: Color.VIOLET,
                }}
              >
                <div className='zip-download'>
                  <svg className='icon icon--24'>
                    <use href={icons + '#sprite-download'}></use>
                  </svg>
                  <span className='u-pl-2'>{lg(lang, DOWNLOAD_ALL)}</span>
                </div>
              </button>
              {loadingZip && (
                <span
                  style={{ fontSize: '20px' }}
                  className='u-ml-2 loader'
                ></span>
              )}
              <div style={{ marginLeft: '-70px' }}>
                <ErrorModal
                  showErrorModal={noContractFound}
                  setShowErrorModal={setNoContractFound}
                  text={generateInfoMessage('Smlouva')}
                />
              </div>
            </div>
          </div>
          <div className='grid-x'>
            <span
              className={`cell auto u-pb-1 ${
                props.onboardingOne ? 'u-pt-2' : 'u-pt-3'
              }`}
              style={{ marginTop: props.onboardingOne ? '-40px' : '0px' }}
            >
              {props.onboardingOne ? (
                // <PdfComponent
                //   label={'Smlouva o zprostředkování benefitů'}
                //   href={PdfSablonaSmlouvaOZprostredkovaniBenefitu}
                // ></PdfComponent>
                <div className='flex-dir-row'>
                  <NoHrefPdfComponent
                    label={'Smlouva o zprostředkování benefitů'}
                    onClick={() =>
                      loadAndOpenFile(
                        beContractWatermark,
                        setLoadingSmlouvaOZprostredkovaniBenefitu,
                        'POST',
                        requestObjectContract
                      )
                    }
                  />
                  {loadingSmlouvaOZprostredkovaniBenefitu && (
                    <span
                      style={{ fontSize: '20px' }}
                      className='u-ml-2 loader'
                    ></span>
                  )}
                </div>
              ) : props.settings ? (
                <div className='flex-dir-row'>
                  <NoHrefPdfComponent
                    label={'Smlouva o zprostředkování benefitů'}
                    onClick={() =>
                      loadAndOpenFile(
                        beSignedContract,
                        setLoadingSmlouvaOZprostredkovaniBenefitu,
                        'GET',
                        {},
                        true,
                        setNoContractFound
                      )
                    }
                  />
                  {loadingSmlouvaOZprostredkovaniBenefitu && (
                    <span
                      style={{ fontSize: '20px' }}
                      className='u-ml-2 loader'
                    ></span>
                  )}
                </div>
              ) : (
                // onboarding three
                <div className='flex-dir-row'>
                  <NoHrefPdfComponent
                    label={'Smlouva o zprostředkování benefitů'}
                    onClick={() =>
                      loadAndOpenFile(
                        beContract,
                        setLoadingSmlouvaOZprostredkovaniBenefitu,
                        'POST',
                        requestObjectContract
                      )
                    }
                  />
                  {loadingSmlouvaOZprostredkovaniBenefitu && (
                    <span
                      style={{ fontSize: '20px' }}
                      className='u-ml-2 loader'
                    ></span>
                  )}
                </div>
              )}
            </span>
          </div>
          {/* Smlouvy must be referenced in order to appear in build */}
          <div style={{ display: 'none' }}>
            <div className='u-pb-1 u-pt-05'>
              <PdfComponent
                label={'Hidden SZB'}
                href={PdfSablonaSmlouvaOZprostredkovaniBenefitu}
              />
            </div>
            <div className='u-pb-1 u-pt-05'>
              <PdfComponent
                label={'Hidden Ind. Ceník'}
                href={PdfSablonaIndividualniCenik}
              />
            </div>
          </div>
          <div className='u-pb-1 u-pt-05'>
            <PdfComponent label={'Příloha č. 1. VOP'} href={PdfSablonaVop} />
          </div>
          <div className='u-pb-1 u-pt-05'>
            <PdfComponent
              label={'Příloha č. 1.1 - VOP - Ceník'}
              href={PdfSablonaCenik}
            />
          </div>
          <div className='u-pb-1 u-pt-05'>
            <PdfComponent
              label={'Příloha č. 2 - Reklamační řád'}
              href={PdfSablonaReklamacniRad}
            />
          </div>
          <div className='u-pb-1 u-pt-05'>
            {props.onboardingOne ? (
              // <PdfComponent
              //   label={'Příloha č. 3 - Individuální ceník'}
              //   href={PdfSablonaIndividualniCenik}
              // />
              <div className='flex-dir-row'>
                <NoHrefPdfComponent
                  label={'Příloha č. 3 - Individuální ceník'}
                  onClick={() =>
                    loadAndOpenFile(
                      beIndividualniCenikWatermark,
                      setLoadingIndividualPricelist,
                      'POST',
                      requestObjectIndividualPricelist
                    )
                  }
                />
                {loadingIndividualPricelist && (
                  <span
                    style={{ fontSize: '20px' }}
                    className='u-ml-2 loader'
                  ></span>
                )}
              </div>
            ) : props.settings ? (
              <div className='flex-dir-row'>
                <NoHrefPdfComponent
                  label={'Příloha č. 3 - Individuální ceník'}
                  onClick={() =>
                    loadAndOpenFile(
                      beSignedIndividualniCenik,
                      setLoadingIndividualPricelist,
                      'GET',
                      {},
                      true,
                      setNoIndividualPricelistFound
                    )
                  }
                />
                {loadingIndividualPricelist && (
                  <span
                    style={{ fontSize: '20px' }}
                    className='u-ml-2 loader'
                  ></span>
                )}
                <div style={{ marginLeft: '70px', marginTop: '-32px' }}>
                  <ErrorModal
                    showErrorModal={noIndividualPricelistFound}
                    setShowErrorModal={setNoIndividualPricelistFound}
                    text={generateInfoMessage('Ceník')}
                  />
                </div>
              </div>
            ) : (
              // onboarding three
              <div className='flex-dir-row'>
                <NoHrefPdfComponent
                  label={'Příloha č. 3 - Individuální ceník'}
                  onClick={() =>
                    loadAndOpenFile(
                      beIndividualniCenik,
                      setLoadingIndividualPricelist,
                      'POST',
                      requestObjectIndividualPricelist
                    )
                  }
                />
                {loadingIndividualPricelist && (
                  <span
                    style={{ fontSize: '20px' }}
                    className='u-ml-2 loader'
                  ></span>
                )}
              </div>
            )}
          </div>
        </div>
        {/* RIGHT */}
        <div></div>
      </div>
    </div>
  )
}

export default Smlouvy
