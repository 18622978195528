import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class FileIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'file-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.667 0A2.666 2.666 0 004 2.666v26.667A2.667 2.667 0 006.667 32h18.667a2.667 2.667 0 002.667-2.667V6.396c0-.705-.28-1.381-.777-1.881L23.512.785A2.667 2.667 0 0021.622 0H6.667zm12 2.666h-12v26.667h18.667V8h-3.515c-1.047 0-1.93-.416-2.497-1.21-.514-.719-.655-1.593-.655-2.346V2.666zm5.609 2.667l-2.654-2.667h-.288v1.778c0 .474.098.712.158.797.022.029.037.04.057.05a.646.646 0 00.27.042h2.457z'
          fill='currentColor'
        ></path>
        <rect
          x='9.333'
          y='17.334'
          width='12'
          height='2'
          rx='.75'
          fill='currentColor'
        ></rect>
        <rect
          x='9.333'
          y='21.334'
          width='12'
          height='2'
          rx='.75'
          fill='currentColor'
        ></rect>
        <path
          d='M6.667 8h8A2.667 2.667 0 0112 10.667H6.667V8z'
          fill='currentColor'
        ></path>
        <rect
          x='9.333'
          y='13.344'
          width='12'
          height='2'
          rx='.75'
          fill='currentColor'
        ></rect>
      </svg>
    )
  }
}

export default FileIcon
