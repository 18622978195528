import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class EyeOpenFilledIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'eye-open-filled-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30 16s-6.268 8-14 8-14-8-14-8 6.268-8 14-8 14 8 14 8zm-8 0a6 6 0 11-12 0 6 6 0 0112 0zm-6 2a2 2 0 100-4 2 2 0 000 4z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default EyeOpenFilledIcon
