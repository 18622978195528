// datumObjednavky: '24.03.2022 20:03:54'
export function dateFromBackendString(dateString: string): Date {
  // const date = dateString.split(' ')[0]
  // const dateParts = date.split('.')
  // return new Date(
  //   Number(dateParts[2]),
  //   Number(dateParts[1]) - 1, // js Date month is 0-based
  //   Number(dateParts[0])
  // )
  if (!dateString.includes(' ')) {
    dateString = dateString + ' 13:19:06' // prostě random čas z ukázky, kterou mi ukázal Tomáš na čísle objednávky 40E00000651
  }
  const dateTime = dateTimeFromBackendString(dateString)
  return new Date(
    dateTime.getFullYear(),
    dateTime.getMonth(),
    dateTime.getDate()
  )
}

export function dateTimeFromBackendString(dateTimeString: string): Date {
  const dateTimeRegex = RegExp(
    '^(\\d{2}\\.\\d{2}\\.\\d{4})\\s(\\d{2}:\\d{2}:\\d{2})$'
  )
  if (!dateTimeRegex.test(dateTimeString)) {
    return new Date('2024-24-12:00:00:00')
  }
  const dateTime = dateTimeString.split(' ')
  const date = dateTime[0]
  const time = dateTime[1]
  const dateParts = date.split('.')
  const timeParts = time.split(':')

  const nonOffsetDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1, // js Date month is 0-based
    Number(dateParts[0]),
    Number(timeParts[0]),
    Number(timeParts[1]),
    Number(timeParts[2])
  )

  // because backend doesn't store timezone, and we are in CET+2 (Czech Republic)
  // do not make the mistake of doing new Date().getTimeZoneOffset -> that would lose the summer time
  let casovyPosunHodin = nonOffsetDate.getTimezoneOffset() / 60
  casovyPosunHodin = -casovyPosunHodin // for some reason timeZoneOffset is in negative
  let hour = Number(timeParts[0]) + casovyPosunHodin
  let day = Number(dateParts[0])
  if (hour > 23) {
    day++
    hour -= 24
  }

  const newDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1, // js Date month is 0-based
    Number(day),
    Number(hour),
    Number(timeParts[1]),
    Number(timeParts[2])
  )
  // console.log('dateTimeFromBackendString', dateTimeString, newDate)
  return newDate
}

// datumObjednavky: '24.03.2022 20:03:54'
// stringToDate('24.03.2022', 'dd.MM.yyyy', '.')
// stringToDate('17/9/2014', 'dd/MM/yyyy', '/')
// stringToDate('9/17/2014', 'mm/dd/yyyy', '/')
// stringToDate('9-17-2014', 'mm-dd-yyyy', '-')
export function dateFromString(
  _date: string,
  _format: string,
  _delimiter: string
) {
  var formatLowerCase = _format.toLowerCase()
  var formatItems = formatLowerCase.split(_delimiter)
  var dateItems = _date.split(_delimiter)
  var monthIndex = formatItems.indexOf('mm')
  var dayIndex = formatItems.indexOf('dd')
  var yearIndex = formatItems.indexOf('yyyy')
  var month = parseInt(dateItems[monthIndex])
  month -= 1
  var formatedDate = new Date(
    +dateItems[yearIndex],
    month,
    +dateItems[dayIndex]
  )
  return formatedDate
}

export function formatDate(date: Date): string {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as const

  return new Date(date).toLocaleDateString('cs-CZ', options)
}

export function formatDateTwoDigitYear(date: Date): string {
  const options = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  } as const

  return new Date(date).toLocaleDateString('cs-CZ', options)
}

export function formatDateAndTime(date: Date): string {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  } as const

  return new Date(date).toLocaleDateString('cs-CZ', options)
}

export function getFormattedDatumDoruceniKaret(lang): string {
  var dateOfDelivery = addWorkingDays(new Date(), 5)
  //Formatting to day dd. mm. yyyy :
  //Pondělí 30. 7. 2019
  var day = dateOfDelivery.getDay()
  var dd = dateOfDelivery.getDate()
  var mm = dateOfDelivery.getMonth() + 1
  var y = dateOfDelivery.getFullYear()

  var someFormattedDate =
    (lang === 'en'
      ? getEnglishDay(day)
      : lang === 'bl'
      ? getBulgarianDay(day)
      : getCzechDay(day)) +
    ' ' +
    dd +
    '. ' +
    mm +
    '. ' +
    y

  return someFormattedDate
}

export function getEnglishDay(day: number): string {
  switch (day) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    default:
      return 'Sunday'
  }
}

export function getBulgarianDay(day: number): string {
  switch (day) {
    case 0:
      return 'Неделя'
    case 1:
      return 'Понеделник'
    case 2:
      return 'Вторник'
    case 3:
      return 'Сряда'
    case 4:
      return 'Четвъртък'
    case 5:
      return 'Петък'
    case 6:
      return 'Събота'
    default:
      return 'Неделя'
  }
}

export function getCzechDay(day: number): string {
  switch (day) {
    case 0:
      return 'Neděle'
    case 1:
      return 'Pondělí'
    case 2:
      return 'Úterý'
    case 3:
      return 'Středa'
    case 4:
      return 'Čtvrtek'
    case 5:
      return 'Pátek'
    case 6:
      return 'Sobota'
    default:
      return 'Neděle'
  }
}

export function addWorkingDays(date: Date, daysToAdd: number): Date {
  const newDate = new Date(date) // Be careful, do not forget to wrap with new Date!!! Otherwise it will change the date of the referenced field.
  for (let i = 1; i <= daysToAdd; i++) {
    newDate.setDate(newDate.getDate() + 1)
    if (newDate.getDay() === 6) {
      newDate.setDate(newDate.getDate() + 2)
    } else if (newDate.getDay() === 0) {
      newDate.setDate(newDate.getDate() + 1)
    }
  }
  return newDate
}

export function getCzechMonth(month: number): string {
  switch (month) {
    case 0:
      return 'Leden'
    case 1:
      return 'Únor'
    case 2:
      return 'Březen'
    case 3:
      return 'Duben'
    case 4:
      return 'Květen'
    case 5:
      return 'Červen'
    case 6:
      return 'Červenec'
    case 7:
      return 'Srpen'
    case 8:
      return 'Září'
    case 9:
      return 'Říjen'
    case 10:
      return 'Listopad'
    case 11:
      return 'Prosinec'
    default:
      return 'Leden'
  }
}

export const newDate = (plus?: number): Date => {
  const now = new Date()
  if (plus) {
    now.setUTCDate(2)
    now.setUTCMonth(now.getUTCMonth() + (plus || 0)) // safer to use setUTCMonth than setMonth
  }
  // now.setMonth(now.getMonth())
  return now
}

export const getSecondDayOfCurrentMonth = (): Date => {
  const date = new Date()
  // Doesn't work. The date is set to the first day of the current month. 0utput: 2022-07-01T22:00:00.000Z
  // See the comment in the function above getFirstDayOfLastMonth()
  // date.setDate(2)
  // date.setHours(0, 0, 0, 0)
  date.setUTCDate(2)
  date.setUTCHours(0, 0, 0, 0)
  return date
}

// todo WORKING DAYS WITH HOLIDAYS
// https://stackoverflow.com/questions/37069186/calculate-working-days-between-two-dates-in-javascript-excepts-holidays
// let workingDaysBetweenDates = (d0, d1) => {
//   /* Two working days and an sunday (not working day) */
//   var holidays = ['2016-05-03', '2016-05-05', '2016-05-07'];
//   var startDate = parseDate(d0);
//   var endDate = parseDate(d1);

// // Validate input
//   if (endDate <= startDate) {
//     return 0;
//   }

// // Calculate days between dates
//   var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
//   startDate.setHours(0, 0, 0, 1);  // Start just after midnight
//   endDate.setHours(23, 59, 59, 999);  // End just before midnight
//   var diff = endDate - startDate;  // Milliseconds between datetime objects
//   var days = Math.ceil(diff / millisecondsPerDay);

//   // Subtract two weekend days for every week in between
//   var weeks = Math.floor(days / 7);
//   days -= weeks * 2;

//   // Handle special cases
//   var startDay = startDate.getDay();
//   var endDay = endDate.getDay();

//   // Remove weekend not previously removed.
//   if (startDay - endDay > 1) {
//     days -= 2;
//   }
//   // Remove start day if span starts on Sunday but ends before Saturday
//   if (startDay == 0 && endDay != 6) {
//     days--;
//   }
//   // Remove end day if span ends on Saturday but starts after Sunday
//   if (endDay == 6 && startDay != 0) {
//     days--;
//   }
//   /* Here is the code */
//   holidays.forEach(day => {
//     if ((day >= d0) && (day <= d1)) {
//       /* If it is not saturday (6) or sunday (0), substract it */
//       if ((parseDate(day).getDay() % 6) != 0) {
//         days--;
//       }
//     }
//   });
//   return days;
// }

// function parseDate(input) {
//     // Transform date from text to date
//   var parts = input.match(/(\d+)/g);
//   // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
//   return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
// }
