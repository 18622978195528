import { urlMagicLink } from 'App'
import { decrypt, decryptWithVector, encrypt } from 'app/cryptoOps'
import { verne } from 'app/julesverne'
import CryptoES from 'crypto-es'

const KEY_256_BIT = 'jXn2r5u7x!A%D*G-KaPdSgVkYp3s6v9y'

export type MagicLinkProps = {
  location: string
  vars: { [key: string]: any }
}

export function createMagicLink(props: MagicLinkProps): string {
  const magicLink = `${window.location.origin}${urlMagicLink}?`
  let vars = props.vars
  const query = Object.keys(vars)
    .map((key) => `${key}=${vars[key]}`)
    .join('&')
  const varsWithLocation = `location=${props.location}&${query}`
  const encryptedVarsWithLocation = encrypt(varsWithLocation, KEY_256_BIT)

  return `${magicLink}${encryptedVarsWithLocation}`
}

function decryptString(url: string, key?: string, iv?: string): string {
  const indexOfSearchSubstring = url.indexOf('?')
  if (indexOfSearchSubstring === -1) {
    return ''
  } else {
    let str = url.substring(indexOfSearchSubstring + 1)
    let decryptedString
    if (key && iv) {
      const decryptedWordArray = CryptoES.AES.decrypt(
        str,
        CryptoES.enc.Utf8.parse(key),
        {
          iv: CryptoES.enc.Utf8.parse(iv),
          mode: CryptoES.mode.CBC,
        }
      )
      decryptedString = decryptedWordArray.toString(CryptoES.enc.Utf8)
      console.log('decrypted url with Decryptor', decryptedString)
    } else {
      decryptedString =
        key && iv
          ? decryptWithVector(str, key, iv)
          : decrypt(str, key || KEY_256_BIT)
      console.log('decrypted url without decryptor', decryptedString)
    }
    return decryptedString
  }
}

export function unpackEncryptedVarsFromURL(
  url: string,
  key?: string,
  iv?: string
) {
  console.log('magicLink, unpacking vars from URL: ', url)

  const decryptedString = decryptString(url, key, iv)

  const params = new URLSearchParams(decryptedString)
  const objectFromEntries = Object.fromEntries(params.entries())

  // const vars: any = {}
  // decryptedString.split('&').forEach((pair: string) => {
  //   const [key, value] = pair.split('=')
  //   vars[key] = decodeURIComponent(value).replace(/\+/g, ' ')
  // })
  // console.log('Vars ', vars)
  console.log('Object from entries', objectFromEntries)
  return objectFromEntries
}

export function unpackURIEncodedVarsFromURL(url: string) {
  verne({ url })
  const indexOfSearchSubstring = url.indexOf('?')
  if (indexOfSearchSubstring === -1) {
    return {}
  } else {
    url = url.substring(indexOfSearchSubstring + 1)
    const vars: any = {}
    url.split('&').forEach((pair) => {
      const [key, value] = pair.split('=')
      vars[key] = decodeURIComponent(value)
    })
    verne({ vars })
    return vars
  }
}
