import { FunctionComponent, useState } from 'react'
import EyeOpenFilledIcon from '@styles/icon/eyeOpenFilledIcon'
import LockIcon from '@styles/icon/lockIcon'
import PenIcon from '@styles/icon/penIcon'
import { Formik, Form, Field } from 'formik'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { validateEmail, validatePassword } from 'util/ValidFormatOps'
import { govActions, selectLogin } from 'components/settings/governanceSlice'
import {
  createSalt32Char,
  hash,
  JwtInternalPayload,
  parseJwtUser,
  parseJwtInternal,
} from 'app/cryptoOps'
import { sendAndLog } from 'app/req'
import { beInterniLogin, urlInternalWeb } from 'App'
import { getPrecious, loggedIn, setPrecious } from 'app/back'
import { Role } from 'app/type'
import { clearFlags } from 'app/facade'
import { useNavigate } from 'react-router-dom'

const Login: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // const login = useAppSelector(selectLogin) // todo: remove, just for logging cause of ORD-2097

  console.log('Login, isLoggedIn, precious', loggedIn(), getPrecious())

  const [passwordShown, setPasswordShown] = useState(false)
  const [navigateFlag, setNavigateFlag] = useState(false)

  if (navigateFlag) {
    navigate(urlInternalWeb)
  }

  function loginUserBackend(values: { email: string; password: string }) {
    // create salt and hash password
    const salt = createSalt32Char(values.email)
    // hash the password
    const hashedPassword = hash(values.password, salt)
    // callbackend login user

    // START OF DEBUG SECTION
    // dispatch(
    //   govActions.reducerLogin({
    //     email: values.email,
    //     jmeno: `ota`,
    //     prijmeni: `braniborsky`,
    //     role: Role.Internal,
    //   })
    // )
    // console.log('precious after resolving internal login', getPrecious()) // this maybe returns old token because of how the global store access works
    // console.log(`login after resolving internal login`, login)
    // console.log(`isLoggedIn after resolving internal login`, loggedIn())
    // navigate(urlInternalWeb)
    // END OF DEBUG SECTION

    const response = sendAndLog(beInterniLogin, {
      email: values.email,
      heslo: hashedPassword,
    })
    response
      .then((data) => {
        const unparsedJwt = data.jwt
        console.log(
          'Logging data.jwt before sending to parseJwt function',
          unparsedJwt
        )
        setPrecious(unparsedJwt)
        const parsedJwtPayload = parseJwtInternal(
          unparsedJwt
        ) as JwtInternalPayload
        if (parsedJwtPayload.role !== Role.Internal) {
          throw new Error('Role is not Internal. Cannot proceed with login.')
        } else {
          dispatch(
            govActions.reducerLogin({
              email: values.email,
              /* "sub": [
              "cf2f27d774624218bc94339ac7fdbc48",
              "Dasha.",
              "Sodexo"
            ], */
              jmeno: parsedJwtPayload.sub[1],
              prijmeni: parsedJwtPayload.sub[2],
              role: Role.Internal,
            })
          )
          dispatch(govActions.reducerInternalCompany(null))
          clearFlags()
          setTimeout(() => {
            // console.log('Navigating to internal web after 2 seconds')
            // setNavigateFlag(true)
            window.location.href = urlInternalWeb
          }, 2000)
        }
      })
      // .then(() => {
      //   // console.log(
      //   //   'precious after resolving internal login - todo: delete this logging before going to prod',
      //   //   getPrecious()
      //   // ) // returns new token! the global store access works well.
      //   console.log(`login after resolving internal login`, login)
      //   // console.log(`isLoggedIn after resolving internal login`, loggedIn()) // this one causes crash -> cannot call hook from function that is not a react component
      // })
      .then(() => {
        // // wait until getPrecious() returns new token
        // setInterval(() => {
        //   // this variant is nicer, but we do not know the format of the jwt token
        //   // if (getPrecious().sub[0] === values.email) {
        //   console.log(
        //     'Iterating with 1 sec delay until isLoggedIn returns true after resolving internal login, currently the value is: ',
        //     loggedIn()
        //   )
        //   if (getPrecious() && getPrecious().toString().length > 0) {
        //     setNavigateFlag(true)
        //   }
        // }, 1000)
      })
  }

  return (
    <div className='container u-mx-0'>
      <h1 className='u-size-l28 u-mb-3 u-ml-5'>
        Přihlášení za interního zaměstnance
      </h1>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, { resetForm }) => {
          loginUserBackend(values)
          resetForm()
        }}
      >
        {({ errors, touched, submitForm }) => (
          <div className='highlight u-py-3 u-px-4 u-mb-3'>
            <div className='highlight__content'>
              <Form>
                <h3 className='u-size-l20 u-mb-4'>
                  Zadejte své přihlašovací údaje
                </h3>
                <div className='input u-mb-4'>
                  <label className='input__label'>Přihlašovací e-mail</label>
                  <div className='input__wrapper'>
                    <Field
                      placeholder='Např.: novak@pluxee.cz'
                      type='text'
                      name='email'
                      validate={validateEmail}
                    />
                  </div>
                  {errors.email && touched.email && (
                    <span className='input__error'>{errors.email}</span>
                  )}
                </div>
                <div className='input u-mb-3 input--with-icon input--password'>
                  <label className='input__label'>Heslo</label>
                  <div className='input__wrapper'>
                    <Field
                      placeholder='Heslo'
                      type={passwordShown ? 'text' : 'password'}
                      name='password'
                      validate={(value: string) => validatePassword(value)}
                    />
                    <svg
                      onMouseDown={() => setPasswordShown(true)}
                      onMouseUp={() => setPasswordShown(false)}
                      className='input__eye icon icon--24'
                    >
                      <EyeOpenFilledIcon width={24} height={24} />
                    </svg>
                  </div>
                  <div className='input__icon'>
                    <svg className='icon icon--24'>
                      <LockIcon width={24} height={24} />
                    </svg>
                  </div>
                  {errors.password && touched.password && (
                    <span className='input__error'>{errors.password}</span>
                  )}
                </div>
                <a onClick={submitForm} className='btn btn--primary btn--small'>
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Přihlásit se
                </a>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default Login
