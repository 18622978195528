import React, { PureComponent } from 'react'
import { IconProps } from 'app/type'

class MagnifyingGlassIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: '',
    iconColor: '#283897', // DARK_PURPLE_BLUE
    width: 24,
    height: 24,
  }

  render() {
    const { className, iconColor, width, height } = this.props
    return (
      <svg
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        width={width}
        height={height}
      >
        {
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            // tslint:disable-next-line:max-line-length
            d='M6.83524 12.9848C4.96941 11.119 4.96941 8.09388 6.83524 6.22805C8.70108 4.36221 11.7262 4.36221 13.592 6.22805C15.4579 8.09388 15.4579 11.119 13.592 12.9848C11.7262 14.8507 8.70108 14.8507 6.83524 12.9848ZM5.42103 4.81383C2.77414 7.46072 2.77414 11.7522 5.42103 14.3991C7.72117 16.6992 11.2632 17.0005 13.8887 15.303L19.2928 20.7071C19.6833 21.0976 20.3165 21.0976 20.707 20.7071C21.0975 20.3166 21.0975 19.6834 20.707 19.2929L15.393 13.9789C17.6462 11.3159 17.5173 7.32484 15.0063 4.81383C12.3594 2.16695 8.06792 2.16695 5.42103 4.81383Z'
            fill={iconColor}
          />
        }
      </svg>
    )
  }
}

export default MagnifyingGlassIcon
