import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class DeleteThinIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'delete-thin-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height, onClick } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
        onClick={onClick}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.008 14.288l7.841-7.845c.523-.524 1.276-.618 1.8-.094.522.523.424 1.271-.1 1.795l-7.84 7.846 7.852 7.856c.522.523.611 1.28.089 1.803-.523.523-1.279.435-1.801-.089l-7.853-7.857-7.846 7.85c-.523.524-1.27.622-1.793.099-.523-.524-.448-1.259.075-1.782l7.863-7.868-7.772-7.783c-.522-.524-.614-1.275-.092-1.799.523-.523 1.28-.435 1.803.089l7.774 7.779z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default DeleteThinIcon
