import { FunctionComponent, useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectPocetZamestnancu,
  selectDenniPrispevekNaStravuOnboarding,
  selectVolnocasovyKreditNaMesic,
  selectOdpracovanychDnuVMesici,
  reducerPocetZamestnancu,
  reducerDenniPrispevekNaStravu,
  reducerVolnocasovyKreditNaMesic,
  reducerOdpracovanychDnuVMesici as reducerOdpracovanychDnuVMesici,
  selectEnabledDenniPrispevekNaStravu,
  selectEnabledVolnocasovyKreditNaMesic,
  reducerEnabledDenniPrispevekNaStravu,
  reducerEnabledVolnocasovyKreditNaMesic,
} from 'components/onboarding/features/mesicniNakladySlice'
import { MIN_VOLNOCASOVY_KREDIT_NA_MESIC } from 'app/constants'
import CheckIcon from '@styles/icon/checkIcon'
import DeleteIcon from '@styles/icon/deleteIcon'
import QuestionCircleOutlineIcon from '@styles/icon/questionCircleOutlineIcon'
import { validateNumericKeyWithWhites } from 'app/util'
import { lg } from 'app/facade'
import {
  DAILY_FOOD_DOTATION,
  MONTHLY_LEASURE_CREDIT,
  NUMBER_OF_EMPLOYEES,
  NUMBER_OF_WORKED_DAYS,
  VALUES_CAN_BE_CHANGED,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface RangeSliderProps {
  label: string
  min: number
  max: number
  step: number
  nakladyType: string
  inputSuffix?: string
  checkbox?: boolean
}

const RangeSlider: FunctionComponent<RangeSliderProps> = ({
  label,
  min,
  max,
  step,
  nakladyType,
  inputSuffix,
  checkbox,
}) => {
  const enabledDenniPrispevekNaStravu = useAppSelector(
    selectEnabledDenniPrispevekNaStravu
  )
  const enabledVolnocasovyKreditNaMesic = useAppSelector(
    selectEnabledVolnocasovyKreditNaMesic
  )
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const odpracovanychDnuVMesici = useAppSelector(selectOdpracovanychDnuVMesici)
  const denniPrispevekNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )

  const dispatch = useAppDispatch()

  function handleChangeSlider(e: any) {
    let value = e.target.value
    value = value > max ? max : value
    // add async check to fix values lower than the minimum.
    value = value === undefined || value < 0 ? min : value
    switch (nakladyType) {
      case 'pocetZamestnancu':
        dispatch(reducerPocetZamestnancu(value))
        break
      case 'odpracovanychDnuVMesici':
        dispatch(reducerOdpracovanychDnuVMesici(value))
        break
      case 'denniPrispevekNaStravu':
        dispatch(reducerDenniPrispevekNaStravu(value))
        break
      case 'volnocasovyKreditNaMesic':
        dispatch(reducerVolnocasovyKreditNaMesic(value))
    }
  }

  function getSliderValue(): number {
    switch (nakladyType) {
      case 'pocetZamestnancu':
        return pocetZamestnancu === undefined ? 1 : pocetZamestnancu
      case 'odpracovanychDnuVMesici':
        return odpracovanychDnuVMesici
      case 'denniPrispevekNaStravu':
        return denniPrispevekNaStravu
      case 'volnocasovyKreditNaMesic':
        return volnocasovyKreditNaMesic
      default:
        return 100
    }
  }

  return (
    <div className='range u-px-medium-2 u-mb-4'>
      <div className='range__top grid-x'>
        <label
          htmlFor=''
          style={{
            marginLeft: `${
              nakladyType === 'pocetZamestnancu' ? '-12px' : '0px'
            }`,
          }}
        >
          {checkbox ? (
            <label className='checkbox u-mb-0' style={{ marginLeft: '-16px' }}>
              <div className='switch__wrapper'>
                <label className='switch switch--text'>
                  <input
                    type='checkbox'
                    checked={
                      nakladyType === 'denniPrispevekNaStravu'
                        ? enabledDenniPrispevekNaStravu
                        : enabledVolnocasovyKreditNaMesic
                    }
                    onChange={
                      nakladyType === 'denniPrispevekNaStravu'
                        ? (e) => {
                            dispatch(
                              reducerEnabledDenniPrispevekNaStravu(
                                e.currentTarget.checked
                              )
                            )
                          }
                        : (e) => {
                            dispatch(
                              reducerEnabledVolnocasovyKreditNaMesic(
                                e.currentTarget.checked
                              )
                            )
                          }
                    }
                  />
                  <span className='switch__slider'></span>
                  <svg className='switch__icon switch__icon--on icon icon--16'>
                    <CheckIcon height={16} width={16} />
                  </svg>
                  <svg className='switch__icon switch__icon--off icon icon--16'>
                    <DeleteIcon height={16} width={16} />
                  </svg>
                </label>
              </div>
              <span className='u-ml-1 u-size-base16'>{label}</span>
            </label>
          ) : (
            <div
              style={{
                marginLeft: nakladyType === 'pocetZamestnancu' ? '12px' : '0px',
              }}
            >
              {label}
            </div>
          )}
        </label>
        <div className='input xsmall-3 input--small'>
          <input
            type='tel'
            value={getSliderValue()}
            onChange={handleChangeSlider}
            onKeyPress={validateNumericKeyWithWhites}
            onWheel={(e) => e.currentTarget.blur()}
          />
          {inputSuffix && <span className='input__suffix'>Kč</span>}
        </div>
      </div>
      <div className='range__slider'>
        <span className='range__min'>{min}</span>
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          style={{
            backgroundSize: `${
              (Math.max(getSliderValue() - min, min) / max) * 100
            }%`,
          }}
          value={getSliderValue()}
          onChange={handleChangeSlider}
        />
        <span className='range__max'>{max}</span>
      </div>
    </div>
  )
}

const MesicniNaklady: FunctionComponent = () => {
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div className='highlight u-mb-4 u-pt-5 u-pb-2' style={{ padding: '16px' }}>
      <div className='highlight__content'>
        {/*  */}
        {/* Počet zaměstnanců */}
        {/*  */}
        <RangeSlider
          label={lg(lang, NUMBER_OF_EMPLOYEES)}
          min={1}
          max={80}
          step={1}
          nakladyType={'pocetZamestnancu'}
        />
        <RangeSlider
          label={lg(lang, NUMBER_OF_WORKED_DAYS)}
          min={1}
          max={31}
          step={1}
          nakladyType={'odpracovanychDnuVMesici'}
        />
        {/*  */}
        {/* Denní příspěvek na stravu */}
        {/*  */}
        <RangeSlider
          label={lg(lang, DAILY_FOOD_DOTATION)}
          min={0}
          max={200}
          step={1}
          inputSuffix={'Kč'}
          nakladyType={'denniPrispevekNaStravu'}
          checkbox={true}
        />
        {/*  */}
        {/* Volnočasový kredit na měsíc */}
        {/*  */}
        <RangeSlider
          label={lg(lang, MONTHLY_LEASURE_CREDIT)}
          min={0}
          max={5000}
          step={10}
          inputSuffix={'Kč'}
          nakladyType={'volnocasovyKreditNaMesic'}
          checkbox={true}
        />
        {volnocasovyKreditNaMesic < MIN_VOLNOCASOVY_KREDIT_NA_MESIC && (
          <span
            className='input__error'
            style={{ marginTop: '0px', fontSize: '15px' }}
          >
            {'Minimální hodnota volnočasového kreditu na měsíc je ' +
              MIN_VOLNOCASOVY_KREDIT_NA_MESIC +
              ' Kč'}
          </span>
        )}
        <div className='grid-x u-px-4 u-mb-1'>
          <div className='cell shrink'>
            <QuestionCircleOutlineIcon />
          </div>
          <div className='cell auto u-pl-1' style={{ marginBottom: '6px' }}>
            {lg(lang, VALUES_CAN_BE_CHANGED)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MesicniNaklady
