import FileIcon from '@styles/icon/fileIcon'
import React, { FunctionComponent } from 'react'

interface PdfComponentProps {
  label: string
  href: string | undefined
  onClick?: () => void
}

const PdfComponent: FunctionComponent<PdfComponentProps> = ({
  label,
  href,
  onClick,
}) => {
  return (
    <p className='u-py-0'>
      <a
        href={href}
        // without
        rel='noopener noreferrer'
        target='_blank'
        onClick={onClick}
        style={{
          color: '#283897',
        }}
      >
        <svg className='icon icon--24'>
          <FileIcon />
        </svg>
        {label}
      </a>
    </p>
  )
}

export default PdfComponent
