import React from 'react'
import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'
import { ImportRow } from './dataImportSlice'
import { Address, ExcelFile } from 'app/type'
import { DIC_COLUMNS } from 'app/constants'

const initialState: {
  uploadedFiles: ExcelFile[]
  activeImportRow: number
  activeImportColumn: number
  uploadedFileName: string
  jmenoColumn: number
  prijmeniColumn: number
  cisloZamestnanceColumn: number
  volnocasovyKreditColumn: number
  stravenkovyKreditColumn: number
  nazevSpolecnostiColumn: number
  uliceCisloPopisneColumn: number
  mestoColumn: number
  pscColumn: number
  kontaktniOsobaColumn: number
  telefonColumn: number
  importedRows: ImportRow[]
  changesAcceptance: string[]
  badIndexToRealIndex: number[]
  appliedAddresses: Address[]
} = {
  uploadedFiles: [] as ExcelFile[],
  activeImportRow: 0,
  activeImportColumn: 0,
  uploadedFileName: '',
  jmenoColumn: -1,
  prijmeniColumn: -1,
  cisloZamestnanceColumn: -1,
  volnocasovyKreditColumn: -1,
  stravenkovyKreditColumn: -1,
  nazevSpolecnostiColumn: -1,
  uliceCisloPopisneColumn: -1,
  mestoColumn: -1,
  pscColumn: -1,
  kontaktniOsobaColumn: -1,
  telefonColumn: -1,
  importedRows: [],
  changesAcceptance: [],
  badIndexToRealIndex: [],
  appliedAddresses: [],
}

const importSettingsSlice = createSlice({
  name: 'importSettings',
  initialState,
  reducers: {
    reducerUploadedFiles: (state, action) => {
      state.uploadedFiles = action.payload
    },
    reducerAppliedAddresses: (state, action) => {
      state.appliedAddresses = action.payload
    },
    initUploadSettingsKeepColumns(state) {
      state.activeImportRow = -1
      state.activeImportColumn = -1
      state.importedRows = []
      state.changesAcceptance = []
      state.badIndexToRealIndex = []
      state.appliedAddresses = []
    },
    resetColumns(state) {
      state.jmenoColumn = -1
      state.prijmeniColumn = -1
      state.cisloZamestnanceColumn = -1
      state.volnocasovyKreditColumn = -1
      state.stravenkovyKreditColumn = -1
      state.nazevSpolecnostiColumn = -1
      state.uliceCisloPopisneColumn = -1
      state.mestoColumn = -1
      state.pscColumn = -1
      state.kontaktniOsobaColumn = -1
      state.telefonColumn = -1
    },
    reducerBadIndexToRealIndex(state, action) {
      state.badIndexToRealIndex = action.payload
    },
    reducerChangesAcceptance(state, action) {
      state.changesAcceptance = action.payload
    },
    reducerActiveImportRow: (state, action) => {
      state.activeImportRow = action.payload
    },
    reducerActiveImportColumn: (state, action) => {
      state.activeImportColumn = action.payload
    },
    reducerUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload
    },
    reducerSetImportedRows: (state, action) => {
      state.importedRows = action.payload
    },
    reducerAssignColumn: (state, action) => {
      const { index, column } = action.payload
      console.log('reducerAssignColumn: ' + index + ' ' + column)
      if (state.jmenoColumn === index) {
        state.jmenoColumn = -1
      } else if (state.prijmeniColumn === index) {
        state.prijmeniColumn = -1
      } else if (state.cisloZamestnanceColumn === index) {
        state.cisloZamestnanceColumn = -1
      } else if (state.volnocasovyKreditColumn === index) {
        state.volnocasovyKreditColumn = -1
      } else if (state.stravenkovyKreditColumn === index) {
        state.stravenkovyKreditColumn = -1
      } else if (state.nazevSpolecnostiColumn === index) {
        state.nazevSpolecnostiColumn = -1
      } else if (state.kontaktniOsobaColumn === index) {
        state.kontaktniOsobaColumn = -1
      } else if (state.uliceCisloPopisneColumn === index) {
        state.uliceCisloPopisneColumn = -1
      } else if (state.mestoColumn === index) {
        state.mestoColumn = -1
      } else if (state.pscColumn === index) {
        state.pscColumn = -1
      } else if (state.telefonColumn === index) {
        state.telefonColumn = -1
      } else {
        // No column was previously assigned to index: index
      }
      switch (column) {
        case DIC_COLUMNS[0]:
          state.jmenoColumn = index
          break
        case DIC_COLUMNS[1]:
          state.prijmeniColumn = index
          break
        case DIC_COLUMNS[2]:
          state.cisloZamestnanceColumn = index
          break
        case DIC_COLUMNS[3]:
          state.volnocasovyKreditColumn = index
          break
        case DIC_COLUMNS[4]:
          state.stravenkovyKreditColumn = index
          break
        case DIC_COLUMNS[5]:
          state.nazevSpolecnostiColumn = index
          break
        case DIC_COLUMNS[6]:
          state.uliceCisloPopisneColumn = index
          break
        case DIC_COLUMNS[7]:
          state.mestoColumn = index
          break
        case DIC_COLUMNS[8]:
          state.pscColumn = index
          break
        case DIC_COLUMNS[9]:
          state.kontaktniOsobaColumn = index
          break
        case DIC_COLUMNS[10]:
          state.telefonColumn = index
      }
    },
  },
})

export const {
  reducerUploadedFiles,
  reducerAppliedAddresses,
  initUploadSettingsKeepColumns,
  resetColumns,
  reducerBadIndexToRealIndex,
  reducerChangesAcceptance,
  reducerActiveImportRow,
  reducerActiveImportColumn,
  reducerUploadedFileName,
  reducerSetImportedRows,
  reducerAssignColumn,
} = importSettingsSlice.actions

export const selectUploadedFiles = (state: RootState) =>
  state.importSettings.uploadedFiles
export const selectImportSettings = (state: RootState) => state.importSettings
export const activeImportRow = (state: RootState) =>
  state.importSettings.activeImportRow
export const activeImportColumn = (state: RootState) =>
  state.importSettings.activeImportColumn
export const uploadedFileName = (state: RootState) =>
  state.importSettings.uploadedFileName
export const selectImportedRows = (state: RootState) =>
  state.importSettings.importedRows

export default importSettingsSlice.reducer
