import React, { FunctionComponent } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { formatDateTwoDigitYear } from 'util/DateOps'
import { selectHistoricalOrders, tableActions } from './tableSlice'
import { nanoid } from '@reduxjs/toolkit'

export interface LeisureHistoryPopoverInsidesProps {
  id: string
  closePopover: () => void
  innerRef: React.MutableRefObject<HTMLDivElement>
}

const LeisureHistoryPopoverInsides: FunctionComponent<
  LeisureHistoryPopoverInsidesProps
> = ({ id, closePopover, innerRef }) => {
  const dispatch = useAppDispatch()
  const historicalOrders =
    useAppSelector(selectHistoricalOrders(id)).slice(0, 4) || []

  return (
    <div ref={innerRef} className='modal__trigger'>
      {historicalOrders.map((order) => (
        <div key={nanoid(5)} className='modal__trigger grid-x u-mb-1'>
          <div
            onClick={() => {
              dispatch(
                tableActions.setLeisureCredit({
                  id: id,
                  amount: order.leisure,
                })
              )
              closePopover()
            }}
            className='modal__trigger cell shrink u-pr-1'
          >
            <p className='modal__trigger u-size-base14'>{order.leisure} Kč</p>
          </div>
          <div className='modal__trigger cell auto u-text-right'>
            <p className='modal__trigger u-size-base14'>
              {formatDateTwoDigitYear(order.date)}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LeisureHistoryPopoverInsides
