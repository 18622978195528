import React from 'react'
import Kfc from '@styles/image/kfc.png'
import Lekarna from '@styles/image/lekarna.png'
import Lidl from '@styles/image/lidl.png'
import Rohlik from '@styles/image/rohlik.png'
import Slevomat from '@styles/image/slevomat.png'

const Logos = () => {
  return (
    <>
      <img src={Lidl} width='auto' height='53' alt='' className='u-mr-2' />
      <img src={Lekarna} width='auto' height='23' alt='' className='u-mx-2' />
      <img src={Rohlik} width='auto' height='75' alt='' className='u-mx-2' />
      <img src={Slevomat} width='auto' height='75' alt='' className='u-mx-2' />
      <img src={Kfc} width='auto' height='53' alt='' className='u-ml-2' />
    </>
  )
}

export default Logos
