import React, { Component } from 'react'
import Color from 'app/colors'
import { ERROR_OCCURRED } from 'app/constDictionary'
import { lg } from 'app/facade'
import { sendAndLog } from 'app/req'
import { beLogFrontendError } from 'App'
import StackTrace from 'stacktrace-js'

export type CustomErrorInfo = {
  message: string
  componentStack: string
  filename: string
  lineno: number
  colno: number
  error: string
}

export async function logFrontendErrorToBackend(errorInfo: CustomErrorInfo) {
  sendAndLog(beLogFrontendError, errorInfo)
}

interface Props {
  children: React.ReactNode
}

function parseErrorStack(stack) {
  const regex = /(?:at\s+|@)?(.*):(\d+):(\d+)/
  const lines = stack.split('\n')
  const filteredLines = lines.filter((line) => regex.test(line))

  if (filteredLines.length > 0) {
    // Find the first stack trace line that doesn't contain the error boundary itself
    const matchLine = filteredLines.find(
      (line) => !line.includes('CustomErrorBoundary')
    )

    if (matchLine) {
      const [, filename, lineno, colno] = matchLine.match(regex)
      return { filename, lineno, colno }
    }
  }

  return { filename: '', lineno: '', colno: '' }
}

class CustomErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
    errorInfo: {
      message: '',
      componentStack: '',
      lineno: 0,
      colno: 0,
      error: '',
    } as CustomErrorInfo,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  async componentDidCatch(error, errorInfo) {
    try {
      const stackFrames = await StackTrace.fromError(error)
      const relevantFrame = stackFrames.find(
        (frame) => !frame.fileName!.includes('CustomErrorBoundary')
      )

      const filename = relevantFrame!.fileName
      const lineno = relevantFrame!.lineNumber
      const colno = relevantFrame!.columnNumber

      this.setState({
        errorInfo: {
          message: error.message,
          componentStack: errorInfo.componentStack,
          filename,
          lineno,
          colno: colno || 0,
          error: error.stack,
        },
      })
    } catch (stackTraceError) {
      console.error('Error parsing stack trace:', stackTraceError)

      this.setState({
        errorInfo: {
          message: error.message,
          componentStack: errorInfo.componentStack,
          filename: '',
          lineno: '',
          colno: '',
          error: error.stack,
        },
      })
    }
  }

  //   async componentDidCatchResolveSourceMap(error, errorInfo) {
  //     const { filename, lineno, colno } = parseErrorStack(error.stack)

  //     const originalPosition = await resolveSourceMapPosition(
  //       filename,
  //       lineno,
  //       colno
  //     )

  //     if (originalPosition && originalPosition.source) {
  //       const updatedFilename = originalPosition.source
  //       const updatedLineno = originalPosition.line
  //       const updatedColno = originalPosition.column

  //       this.setState({
  //         errorInfo: {
  //           message: error.message,
  //           filename: updatedFilename,
  //           lineno: updatedLineno,
  //           colno: updatedColno,
  //           error: error.stack,
  //         },
  //       })
  //     } else {
  //       this.setState({
  //         errorInfo: {
  //           message: error.message,
  //           filename,
  //           lineno,
  //           colno,
  //           error: error.stack,
  //         },
  //       })
  //     }
  //   }

  //   componentDidCatchOld(error, errorInfo) {
  //     const { filename, lineno, colno } = parseErrorStack(error.stack)

  //     console.log('error', error)
  //     console.log('errorInfo', errorInfo)

  //     this.setState({
  //       errorInfo: {
  //         message: error.message,
  //         componentStack: errorInfo.componentStack,
  //         lineno: lineno,
  //         colno: colno,
  //         error: error.stack,
  //       } as CustomErrorInfo,
  //     })
  //   }

  render() {
    const lang = 'cz'

    if (this.state.hasError) {
      const errorInfo = this.state.errorInfo

      return (
        <div role='alert' className='error u-ml-9'>
          <h2 className='alert--error u-mb-5'>{lg(lang, ERROR_OCCURRED)}</h2>
          <pre className='u-ml-2 u-mb-4' style={{ color: Color.REDDARK }}>
            <br /> message: {errorInfo.message}
            <br /> componentStack: {errorInfo.componentStack}
            <br /> filename: {errorInfo.filename}
            <br /> lineno: {errorInfo.lineno}
            <br /> colno: {errorInfo.colno}
            <br /> error: {errorInfo.error}
          </pre>
          <button
            className='btn btn--primary btn--tiny u-ml-2'
            onClick={() => {
              this.setState({ hasError: false, errorInfo: null })
            }}
          >
            Zkusit znovu
          </button>
          <button
            className='btn btn--secondary btn--tiny u-ml-2'
            onClick={() => logFrontendErrorToBackend(errorInfo)}
          >
            Odeslat chybu na podporu
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

export default CustomErrorBoundary
