import React, { FunctionComponent, useEffect, useState } from 'react'
import EmployeeIcon from '@styles/icon/employeeIcon'
import DashboardIcon from '@styles/icon/dashboardIcon'
import WalletIcon from '@styles/icon/walletIcon'
import WheelIcon from '@styles/icon/wheelIcon'
import { useLocation } from 'react-router-dom'
import icons from '@styles/dist/system/icons.svg'
import {
  urlFaq,
  urlOrdersOverview,
  urlSelfcareTable,
  urlNastaveni,
  urlInternalWeb,
} from 'App'
import { Z_INDEX_SIDE_MENU } from 'app/tuning'
import { verne } from 'app/julesverne'
import { isInternal } from 'util/authOps'
import { useAppSelector } from 'app/hooks'
import { InternalCompany } from 'app/type'
import { selectInternalCompany } from 'components/settings/governanceSlice'
import Color from 'app/colors'
import { selectLanguage } from 'components/settings/userSettingsSlice'

// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

// // Each logical "route" has two components, one for
// // the sidebar and one for the main area. We want to
// // render both of them in different places when the
// // path matches the current URL.

// // We are going to use this route config in 2
// // spots: once for the sidebar and once in the main
// // content section. All routes are in the same
// // order they would appear in a <Switch>.
// const routes = [
//   {
//     path: '/selfcare-table',
//     exact: true,
//     sidebar: () => <span>Přehled</span>,
//     main: () => <h2>Home</h2>,
//   },
//   {
//     path: '/orders-overview',
//     sidebar: () => <span>Objednávky</span>,
//     main: () => <h2>Bubblegum</h2>,
//   },
//   {
//     path: '/settings',
//     sidebar: () => <span>Nastavení</span>,
//     main: () => <h2>Shoelaces</h2>,
//   },
// ]
interface SideMenuProps {
  isActivated?: boolean
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ isActivated = true }) => {
  verne('SideMenu#44')
  const location = useLocation()
  const pathname = location.pathname.slice('/'.length)
  const internalCompany: InternalCompany = useAppSelector(selectInternalCompany)
  const isIntButNoCompanySelected = isInternal() && internalCompany === null
  // console.log('isInternalButNoCompanySelected', isInternalButNoCompanySelected)
  // console.log('internalCompany', internalCompany)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const disabledColor = Color.BLUEDARK_C1Var80

  return (
    <div className='side-menu' style={{ zIndex: Z_INDEX_SIDE_MENU }}>
      <div className='side-menu__inner'>
        {isInternal() && (
          <a
            href={urlInternalWeb}
            className={
              'side-menu__link' +
              (pathname.includes(urlInternalWeb.substring(1))
                ? ' active'
                : '') +
              (isActivated && !isIntButNoCompanySelected
                ? ''
                : ' disabled-href')
            }
          >
            <svg className='icon icon--24' style={{ marginLeft: '8px' }}>
              <EmployeeIcon />
            </svg>
            <span>Seznam klientů</span>
          </a>
        )}
        <span className={isIntButNoCompanySelected ? 'not-allowed' : ''}>
          <a
            href={urlSelfcareTable}
            className={
              'side-menu__link' +
              (pathname.includes(urlSelfcareTable.substring(1))
                ? ' active'
                : '') +
              (!isIntButNoCompanySelected ? '' : ' disabled-href')
            }
          >
            <svg className='icon icon--24' style={{ marginLeft: '8px' }}>
              <DashboardIcon
                fill={
                  isIntButNoCompanySelected ? disabledColor : 'currentColor'
                }
              />
            </svg>
            <span
              className={isIntButNoCompanySelected ? 'color-not-allowed' : ''}
            >
              Nová Objednávka
            </span>
          </a>
        </span>
        <span className={isIntButNoCompanySelected ? 'not-allowed' : ''}>
          <a
            href={urlOrdersOverview}
            className={
              'side-menu__link' +
              (pathname.includes(urlOrdersOverview.substring(1))
                ? ' active'
                : '') +
              (!isIntButNoCompanySelected ? '' : ' disabled-href')
            }
          >
            <svg className='icon icon--24' style={{ marginLeft: '8px' }}>
              <WalletIcon
                fill={
                  isIntButNoCompanySelected ? disabledColor : 'currentColor'
                }
              />
            </svg>
            <span
              className={isIntButNoCompanySelected ? 'color-not-allowed' : ''}
            >
              Přehled Objednávek
            </span>
          </a>
        </span>
        <span className={isIntButNoCompanySelected ? 'not-allowed' : ''}>
          <a
            href={urlNastaveni}
            className={
              'side-menu__link' +
              (pathname.includes(urlNastaveni.substring(1)) ? ' active' : '') +
              (isActivated && !isIntButNoCompanySelected
                ? ''
                : ' disabled-href')
            }
          >
            <svg className='icon icon--24' style={{ marginLeft: '8px' }}>
              <WheelIcon
                fill={
                  isIntButNoCompanySelected ? disabledColor : 'currentColor'
                }
              />
            </svg>
            <span
              className={isIntButNoCompanySelected ? 'color-not-allowed' : ''}
            >
              {isInternal() ? 'Správa' : 'Nastavení'}
            </span>
          </a>
        </span>
        <span className={isIntButNoCompanySelected ? 'not-allowed' : ''}>
          <a
            href={urlFaq}
            className={
              'side-menu__link side-menu__link--faq' +
              (pathname.includes(urlFaq.substring(1)) ? ' active' : '')
              // + (isActivated && !isInternalButNoCompanySelected
              //   ? ''
              //   : ' disabled-href')
            }
          >
            <svg className='icon icon--24' style={{ marginLeft: '8px' }}>
              <use href={icons + '#sprite-question-circle-outline'}></use>
            </svg>{' '}
            <span>FAQ</span>
          </a>
        </span>
      </div>
    </div>
  )
}
export default SideMenu
