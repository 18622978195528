import React, { FC, useEffect } from 'react'
import { useAppSelector } from 'app/hooks'
import SideMenu from 'components/modules/menu/SideMenu'
import { useDispatch } from 'react-redux'
import { faqActions } from './faqSlice'
import Markdown from 'react-markdown'
import { defaultHeaders } from 'app/back'
import { beFaq } from 'App'

// const input = `# This is a header\n\nAnd this is a paragraph
// * Bod 1
// * Bod 2
//   * Bod 2.1
// * Bod 3\n* Bod 4\n  * Bod 4.1\n  * Bod 4.2
// ![Birds](https://image.shutterstock.com/image-photo/picture-beautiful-view-birds-260nw-1836263689.jpg)
// [UI_designs](https://gitlab.com/FIS-VSE/4IT115/2021ZS/pa1100/kala13/top-down-shooter/-/wikis/N%C3%A1vrhy-UI)
// `
// ![Class_Diagram0](uploads/f37d177162cf46c21315d6d66b8c9264/Class_Diagram0.png)

type Props = {
  title: string
  children: string
  index: number
  isExpanded: boolean
  setExpanded: (index: number) => void
}

const AccordionItem: FC<Props> = ({
  title,
  children,
  index,
  isExpanded,
  setExpanded,
}) => {
  return (
    <div className={`accordion__item ${isExpanded ? 'active' : ''}`}>
      <div
        className='accordion__item-title'
        onClick={() => setExpanded(isExpanded ? -1 : index)}
      >
        <h4 className='accordion-item-title'>{title}</h4>
      </div>
      <div
        className='accordion__item-content'
        style={isExpanded ? { height: '100%' } : {}}
      >
        {isExpanded && (
          <div className='accordion__item-inner'>
            {/* <Markdown children={input} /> */}
            <Markdown children={children} />
          </div>
        )}
      </div>
    </div>
  )
}

const Faq: FC = () => {
  const dispatch = useDispatch()

  const faqs = useAppSelector((state) => state.faq)
  const [expanded, setExpanded] = React.useState(-1)

  function clearFaqs() {
    dispatch(faqActions.clearFaqs())
  }

  async function fetchFaqs() {
    console.log('fetching faqs')
    let faqs: any[] = []
    await fetch(beFaq, defaultHeaders())
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        faqs = [...data]
        return data
      })
      .catch((error) => console.log(error))

    faqs.forEach((element: any) => {
      dispatch(
        faqActions.addFaq({
          title: element.otazka,
          content: element.odpoved,
        })
      )
    })

    // async function fetchVsechno() {
    //   console.log()
    //   let faqs: any[] = []
    //   await fetch(beFaq + noSpoSync ? '?no-sync' : '', defaultOptions)
    //     .then((response) => {
    //       console.log('response: ', response)
    //       return response.json()
    //     })
    //     .then((data) => {
    //       console.log('data: ', data)
    //       faqs = [...data]
    //       return data
    //     })
    //     .catch((error) => console.log(error))

    //   faqs.forEach((element: any) => {
    //     dispatch(
    //       faqActions.addFaq({
    //         title: element.otazka,
    //         content: element.odpoved,
    //       })
    //     )
    //   })
  }

  useEffect(() => {
    clearFaqs()
    fetchFaqs()
  }, [])

  return (
    <div className='faq'>
      <section className='u-pt-medium-9'>
        <div className='container u-my-9'>
          <div className='grid-x align-center'>
            <div className='cell medium-8'>
              <h2 className='heading heading--2 u-text-center u-mb-4'>
                Často kladené otázky
              </h2>
              {/* <button onClick={fetchFaqs}>fetch FAQs</button> */}
              {/* <button onClick={clearFaqs}>clear FAQs</button> */}
              <div className='accordion'>
                {faqs.map((faq, i) => (
                  <AccordionItem
                    key={i}
                    title={faq.title}
                    children={faq.content}
                    index={i}
                    isExpanded={expanded === i}
                    setExpanded={setExpanded}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideMenu />
    </div>
  )
}

export default Faq
