import {
  reducerCenik,
  reducerDicFirmy,
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerOpravneneOsoby,
  reducerPsc,
  reducerUliceCisloPopisne,
} from 'components/onboarding/features/firemniUdajeSlice'
import { govActions } from 'components/settings/governanceSlice'
import {
  reducerCerstveDokoncilMigraci,
  reducerCerstveProselOnboardingem,
  reducerHasError,
} from 'components/settings/userSettingsSlice'
import { getCurrentUser } from 'util/authOps'
import {
  splitUliceCisloPopisne,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from 'util/ValidFormatOps'
import { dictionary } from './dictionary'
import { parseJwtInternal } from './cryptoOps'
import { sinking } from './julesverne'
import { mutateZamestnanecBackend } from './req'
import { store } from './store'
import { Address, Cenik, Role, User, ZamestnanecBackendEditace } from './type'
import { reducerPocetZamestnancu } from 'components/onboarding/features/mesicniNakladySlice'
import {
  reducerPracovniEmail,
  reducerTelefon,
  reducerPredvolba,
} from 'components/onboarding/features/objednavatelSlice'
import { TableRow } from 'components/table/tableSlice'
import { SingletonFactory } from 'components/workers/WorkerSingletonFactory'

export const terminateWorker = () => {
  console.log('Facade, terminating worker service')
  const instance = SingletonFactory.getInstance() as Worker
  instance.terminate()
}

export const getBeginningOfTimeFromRedux = (): Date => {
  const { beginningOfTime } = store.getState().gov
  console.log('getBeginningOfTimeFromRedux: ', beginningOfTime)
  return beginningOfTime
  // return new Date(0)
}

export const getCenikFromRedux = (): Cenik => {
  const { cenik } = store.getState().firemniUdaje
  //   console.log('getCenikFromRedux', cenik)
  return cenik
}

export const setCenikToRedux = (cenik: Cenik) => {
  //   console.log('setCenikToRedux', cenik)
  store.dispatch(reducerCenik(cenik))
}

export const getUnparsedTokenFromRedux = () => {
  const tokenFromRedux = store.getState().gov.precious
  return tokenFromRedux
}

export const setUnparsedTokenToRedux = (token: string) => {
  store.dispatch(govActions.reducerPrecious(token))
  sinking() && console.log(store.getState().gov.precious)
}

export const getLogoutUrlFromRedux = () => {
  const logoutUrl = store.getState().gov.logoutUrl
  return logoutUrl
}

export const setLogoutUrlToRedux = (token: string) => {
  store.dispatch(govActions.reducerLogoutUrl(token))
  sinking() && console.log(store.getState().gov.logoutUrl)
}

export const getCurrentUserUnsafe = (): User => {
  const { users, login } = store.getState().gov
  return getCurrentUser(users, login)
}

// export const getLoginFromRedux = (): Login => {
//   const loginFromRedux = store.getState().gov.login
//   if (loginFromRedux && loginFromRedux.email) return loginFromRedux
//   else return { email: '', jmeno: '', prijmeni: '' }
// }

// export const getUserFromRedux = (): User => {
//   const { users } = store.getState().gov
//   const login = getLoginFromRedux() || {
//     email: '',
//     jmeno: '',
//     prijmeni: '',
//     role: '',
//   }
//   const user = isInternal()
//     ? ({
//         email: login.email,
//         jmeno: login.jmeno,
//         prijmeni: login.prijmeni,
//         role: login.role,
//       } as User)
//     : (users.find((user) => user.email === login.email) as User) || {
//         jmeno: '',
//         prijmeni: '',
//         email: '',
//         role: '',
//       }

//   // console.log('getUserFromRedux', user)
//   return user
// }

export const lg = (language: string, label: string): string => {
  return dictionary[label][language]
}

// currently only returns true when the user is internal, but has much larger potential, to return a string with the given role.
export function getUserAuthorization(): boolean {
  const precious = getUnparsedTokenFromRedux()
  if (!precious) return false
  try {
    const user = parseJwtInternal(precious)
    return user.role === Role.Internal
  } catch (e) {
    console.error('getUserAuthorization failed', e)
    return false
  }
}

export function getPocetZamestnancu(): number {
  const { pocetZamestnancu } = store.getState().mesicniNaklady
  return pocetZamestnancu
}

export function changeZamestnanecAddress(employee: TableRow, address: Address) {
  if (!employee || !address) {
    console.error(
      'Called changeZamestnanecAddress with undefined values employee or address',
      {
        employee,
        address,
      }
    )
    return
  }
  const { ulice, cisloPopisne } = splitUliceCisloPopisneZachovatCisloOrientacni(
    address.uliceCisloPopisne
  )
  const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
    id: +employee.id,
    jmeno: employee.name,
    prijmeni: employee.surname,
    adresa: {
      ulice: ulice,
      cisloPopisne: cisloPopisne,
      psc: address.psc.toString(),
      oddeleni: address.oddeleni[0],
    },
    zaplaceneMesice: employee.paidMonths,
    deaktivovaneMesice: employee.deactivatedMonths,
  }
  mutateZamestnanecBackend(zamestnanecBackendEditace)
}

export function changeZamestnanecBackend(
  id: string | number,
  jmeno: string,
  prijmeni: string,
  uliceCisloPopisne: string,
  psc: string | number,
  oddeleni: string,
  zaplaceneMesice: number,
  deaktivovaneMesice: number
): void {
  const { ulice, cisloPopisne } =
    splitUliceCisloPopisneZachovatCisloOrientacni(uliceCisloPopisne)
  const zamestnanecBackendEditace: ZamestnanecBackendEditace = {
    id: +id,
    jmeno: jmeno,
    prijmeni: prijmeni,
    adresa: {
      ulice: ulice,
      cisloPopisne: cisloPopisne,
      psc: psc.toString().replace(/\s/g, ''),
      oddeleni: oddeleni,
    },
    zaplaceneMesice: zaplaceneMesice,
    deaktivovaneMesice: deaktivovaneMesice,
  }
  mutateZamestnanecBackend(zamestnanecBackendEditace)
}

export function clearCache() {
  console.log('Clearing cache')
  store.dispatch(reducerNazevSpolecnosti(''))
  store.dispatch(reducerUliceCisloPopisne(''))
  store.dispatch(reducerMesto(''))
  store.dispatch(reducerPsc(''))
  store.dispatch(reducerPracovniEmail(''))
  store.dispatch(reducerTelefon(undefined))
  store.dispatch(reducerPredvolba(420))
  store.dispatch(reducerIcFirmy(''))
  store.dispatch(reducerDicFirmy(''))
  store.dispatch(reducerPocetZamestnancu(undefined))
  store.dispatch(reducerOpravneneOsoby([]))
}

export function clearFlags() {
  console.log('Clearing flags')
  // triggers loading of data from backend
  store.dispatch(govActions.reducerHaveCalledAfterLogin(false))
  store.dispatch(reducerCerstveProselOnboardingem(false))
  store.dispatch(reducerCerstveDokoncilMigraci(false))
  store.dispatch(reducerHasError(false))
}
