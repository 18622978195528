import React, { useEffect, useState } from 'react'
import Man from '@styles/image/man.svg'
import DirectionIcon from '@styles/icon/directionIcon'
import ConfirmSection from './ConfirmSection'
import Stepper from './Stepper'
import icons from '@styles/dist/system/icons.svg'
import Smlouvy from './Smlouvy'
import MesicniNaklady from 'components/onboarding/mesicniNaklady/MesicniNaklady'
import VaseMesicniNaklady from 'components/onboarding/mesicniNaklady/VaseMesicniNaklady'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  reducerDenniPrispevekNaStravu,
  reducerOdpracovanychDnuVMesici,
  reducerPocetZamestnancu,
  reducerVolnocasovyKreditNaMesic,
  selectDenniPrispevekNaStravuOnboarding,
  selectEnabledDenniPrispevekNaStravu,
  selectEnabledVolnocasovyKreditNaMesic,
  selectOdpracovanychDnuVMesici,
  selectPocetZamestnancu,
  selectVolnocasovyKreditNaMesic,
} from 'components/onboarding/features/mesicniNakladySlice'
import {
  reducerPracovniEmail,
  reducerPredvolba,
  reducerTelefon,
  selectPracovniEmail,
  selectPredvolba,
  selectTelefon,
} from 'components/onboarding/features/objednavatelSlice'
import { urlBackend, urlRecon2, urlMultiPassCard, urlOnboarding2 } from 'App'
import { createMagicLink } from 'components/magiclink/MagicLinkTools'
import {
  reducerCenik,
  reducerDicFirmy,
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerOpravneneOsoby,
  reducerPsc,
  reducerUliceCisloPopisne,
  selectCenik,
  selectCisloKontraktu,
  selectCompanyCode,
  selectDicFirmy,
  selectIcoFirmy,
  selectMesto,
  selectNazevSpolecnosti,
  selectPsc,
  selectUliceCisloPopisne,
} from './features/firemniUdajeSlice'
import {
  DEFAULT_ODPRACOVANYCH_DNU_V_MESICI,
  DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU,
  MIN_VOLNOCASOVY_KREDIT_NA_MESIC,
  RECON_EMAIL_COM,
} from 'app/constants'
import { procentualniPoplatek } from 'util/ValidFormatOps'
import { NavHashLink } from 'react-router-hash-link'
import { numberWithSpacesMax2 } from 'app/util'
import { Cenik } from 'app/type'
import { lg } from 'app/facade'
import {
  BEFORE_DELIVERY,
  CALCULATION,
  CARDS_DELIVERY,
  CARDS_ISSUANCE,
  COMMON_PRICE,
  CONTINUE_NEXT_STEP,
  CREDIT_ORDER_FEE,
  DELIVERY_TO_THE_COMPANY_ADDRESS,
  FEE_FOR_ACTIVE_USER_ACCOUNT,
  GO_TO_TERMS_AND_CONDITIONS,
  INDEFINITE,
  LEISURE_CREDIT,
  LENGTH_OF_CONTRACT_PERIOD,
  MATURITY,
  MEAL_VOUCHER_CREDIT,
  MIN_CREDITS_ORDER_PER_MONTH,
  MONTHLY_USER_ACCOUNT_MANAGEMENT,
  MONTHS_TWO,
  NONE,
  NOTICE_PERIOD,
  OB1_HEADER,
  OB1_SUBHEADER_1,
  OB1_SUBHEADER_2,
  ONE_TIME_FEES,
  OUR_OFFER,
  PCS,
  PRICE_WITHOUT_VAT,
  TERMS_AND_CONDITIONS,
  TOTAL_PRICE,
  TO_THE_COMPANY_ADDRESS,
  YOUR_PRICE,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { isRecon } from 'components/magiclink/ReconLink'

const OnboardingOne: React.FC = () => {
  const dispatch = useAppDispatch()

  const cenik = useAppSelector(selectCenik) as Cenik
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const odpracovanychDnuMesicne = useAppSelector(selectOdpracovanychDnuVMesici)
  const denniPrispevekNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )

  const lang = useAppSelector(selectLanguage) || 'cz'

  const submitIcon = <DirectionIcon />
  const submitText = lg(lang, CONTINUE_NEXT_STEP)
  const nextRoute = isRecon() ? urlRecon2 : urlOnboarding2

  const ContinueButton = () => (
    <div className='cell medium-3 u-pl-2'>
      <ConfirmSection
        submitIcon={submitIcon}
        submitText={submitText}
        nextRoute={isValid() ? nextRoute : ''}
        onSubmit={() => {
          isValid()
            ? function (): void {}
            : () => {
                if (volnocasovyKreditNaMesic < MIN_VOLNOCASOVY_KREDIT_NA_MESIC)
                  dispatch(
                    reducerVolnocasovyKreditNaMesic(
                      MIN_VOLNOCASOVY_KREDIT_NA_MESIC
                    )
                  )
              }
          uliceCisloPopisne
            ? callBackendAsync()
            : callBackendSyncWhenFromMagicLink()
        }}
        nextLocationNumber={2}
        formNotValid={!isValid()}
      />
    </div>
  )

  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1030px)').matches
  )

  useEffect(() => {
    window
      .matchMedia('(min-width: 1000px)')
      .addEventListener('change', (e) => setMatches(e.matches))

    // dataLayer.push({
    //   event: 'formSubmission',
    //   lead_type: 'xxx',
    //   step: '1',
    //   pocet_zamestancnu: 'yyy',
    // })
  }, [])

  // for Magic Link only 1/2
  const telefon = useAppSelector(selectTelefon)
  const predvolba = useAppSelector(selectPredvolba)
  const icFirmy = useAppSelector(selectIcoFirmy)
  const dicFirmy = useAppSelector(selectDicFirmy)
  const enabledDenniPrispevekNaStravu = useAppSelector(
    selectEnabledDenniPrispevekNaStravu
  )
  const enabledVolnocasovyKreditNaMesic = useAppSelector(
    selectEnabledVolnocasovyKreditNaMesic
  )

  // for Magic Link only 2/2
  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne)
  const mesto = useAppSelector(selectMesto)
  const psc = useAppSelector(selectPsc)

  // for Magic Link only 3/3 - two additional fields that come from SPO as part of Recontracting process
  const cisloKontraktu = useAppSelector(selectCisloKontraktu)
  const companyCode = useAppSelector(selectCompanyCode)

  function callBackendSyncWhenFromMagicLink() {
    console.log(
      'The user came from magiclink to Kalkulace, data from findme must be loaded. Calling backend in a synchronous way, including a find me call.'
    )
    callBackendFindmeSync()
    callBackendCalculateAsync()
  }

  async function callBackendAsync() {
    console.log('callBackendAsync()')
    callBackendCalculateAsync()
  }

  function callBackendFindmeSync() {
    const _icFirmy = icFirmy

    const requestBody = JSON.stringify({
      ico: _icFirmy,
      DEBUG_KEY: 'wS5xyTmmJJU9qzJQezmG',
    })

    console.log(
      'Sending POST request to ' + urlBackend + '/findme, requestBody: ',
      requestBody
    )

    fetch(urlBackend + '/findme', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        data.dicFirmy
          ? dispatch(reducerDicFirmy(data.company.vat_id)) // added 12.4. - not sure if backend (Pepa) sends it yet.
          : dispatch(reducerDicFirmy(''))
        dispatch(reducerNazevSpolecnosti(data.company.company_name))
        dispatch(
          reducerUliceCisloPopisne(
            data.company.street_address + ', ' + data.company.house_number
          )
        )
        dispatch(reducerMesto(data.company.address_locality))
        dispatch(reducerPsc(data.company.address_postal_code))
      })
      .catch((error) => console.log(error))
  }

  async function callBackendCalculateAsync() {
    // dealing with undefined values –> if undefined, set to 0
    let pocetZamestnancuNumber = parseInt((pocetZamestnancu || 0).toString())
    let dailyGastroAllowanceNumber = parseInt(
      (denniPrispevekNaStravu || 0).toString()
    )
    let monthlyLeisureCreditNumber = parseInt(
      (volnocasovyKreditNaMesic || 0).toString()
    )

    const magicLink = createMagicLink({
      location: urlOnboarding2,
      vars: {
        pracovniEmail: pracovniEmail,
        telefon: telefon,
        predvolba: predvolba,
        pocetZamestnancu: pocetZamestnancu,
        icFirmy: icFirmy,
        dicFirmy: dicFirmy,
        odpracovanychDnuMesicne: odpracovanychDnuMesicne,
        denniPrispevekNaStravu: denniPrispevekNaStravu,
        volnocasovyKreditNaMesic: volnocasovyKreditNaMesic,
        enabledDenniPrispevekNaStravu: enabledDenniPrispevekNaStravu,
        enabledVolnocasovyKreditNaMesic: enabledVolnocasovyKreditNaMesic,
        nazevSpolecnosti: nazevSpolecnosti,
        uliceCisloPopisne: uliceCisloPopisne,
        mesto: mesto,
        psc: psc,
        cisloKontraktu: cisloKontraktu,
        companyCode: companyCode,
      },
    })
    console.log({ magicLink })

    const requestBody = JSON.stringify({
      email: pracovniEmail || RECON_EMAIL_COM,
      employees: pocetZamestnancuNumber,
      workDays: odpracovanychDnuMesicne,
      dailyGastroAllowance: dailyGastroAllowanceNumber,
      monthlyLeisureCredit: monthlyLeisureCreditNumber,
      magic_link: magicLink,
      contract_number: cisloKontraktu,
      company_code: companyCode,
    })

    console.log('Request Body, ', requestBody)

    await fetch(urlBackend + '/onboarding/calc', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        return data
      })
      .catch((error) => console.log(error))
  }

  function isValid() {
    return (
      volnocasovyKreditNaMesic >= MIN_VOLNOCASOVY_KREDIT_NA_MESIC &&
      // check for pocetZamestnancu malfunctions and doesn't let the user proceed without him entering something
      // but only if he begins from site onboarding1 (testing scenario only)
      pocetZamestnancu &&
      pocetZamestnancu > 0 &&
      odpracovanychDnuMesicne > 0 &&
      denniPrispevekNaStravu > -1
    )
  }

  return (
    <div>
      <div className='u-my-6'>
        <div className='container'>
          <Stepper />
        </div>
        {/* <div style={{ marginTop: '-40px', marginBottom: '20px' }}>
        </div> */}
        <img
          width='200'
          src={Man}
          className='u-mb-3 u-mt-0'
          style={{
            position: 'absolute',
            top: '150px',
            right: '30px',
            float: 'right',
          }}
        ></img>
        <div className='u-pt-3 custom-shape-divider-bottom-1655459597'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
          >
            <path
              d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
              className='shape-fill'
            ></path>
          </svg>
        </div>
        {/* <div className='spacer layer1' style={{ marginTop: '-40px' }}></div> */}
        <div
          className='container u-mt-9 u-pt-2 u-pb-2'
          style={{ background: '#2A295B', maxWidth: '100vw' }}
        >
          <h2 className='u-text-center u-mb-3' style={{ color: '#fff' }}>
            {lg(lang, OB1_HEADER)}
          </h2>
          <p className='u-text-center u-mb-6' style={{ color: '#fff' }}>
            {lg(lang, OB1_SUBHEADER_1)}
            <br />
            {lg(lang, OB1_SUBHEADER_2)}
          </p>
          <div
            className='container container--wide u-pt-7 u-pb-1'
            style={{ background: 'white', borderRadius: 24 }}
          >
            <div
              className='u-mb-3 u-ml-4 u-mr-2 u-text-center'
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <h3>{lg(lang, CALCULATION)}</h3>
              {/* <h3>-&gt;</h3> */}
              <h3>
                <svg className='icon icon--32 u-ml-3'>
                  <use href={icons + '#sprite-arrow-right'}></use>
                </svg>
              </h3>
              <h3>{lg(lang, OUR_OFFER)}</h3>
            </div>

            <div className='grid-x u-justify-center u-mb-9'>
              <div className='cell large-6 u-pr-large-3'>
                <MesicniNaklady />
              </div>
              <div className='cell large-6 u-pl-large-3'>
                <VaseMesicniNaklady />
                <div className='highlight u-mb-6 u-pb-4'>
                  <div
                    className='highlight__title grid-x u-mb-2'
                    style={{ borderBottom: '1px solid #C2C2E4' }}
                  >
                    <span>{lg(lang, ONE_TIME_FEES)}</span>
                    <span className='u-desktop-only'>
                      {lg(lang, YOUR_PRICE)}
                    </span>
                  </div>
                  <div className='highlight__content'>
                    <p className='grid-x'>
                      <span className='cell auto u-pt-0'>
                        {lg(lang, CARDS_ISSUANCE)}
                      </span>
                      {/* <span className='cell shrink u-text-right u-mr-4'>
                      <s>{pocetZamestnancu ? pocetZamestnancu * 20 : 800} Kč</s>
                    </span> */}
                      <span className='cell shrink u-text-right'>
                        <b>
                          {cenik.vydaniPrvniKarty === 0
                            ? 'zdarma'
                            : numberWithSpacesMax2(
                                cenik.vydaniPrvniKarty * (pocetZamestnancu || 1)
                              ) + ' Kč'}
                        </b>
                      </span>
                    </p>
                    <div className='u-mb-2'>
                      <small className='cell auto u-color-c1-40'>
                        <span className='small-text'>
                          Karta Multibenefit ({pocetZamestnancu}
                          {lg(lang, PCS)})
                        </span>
                      </small>
                    </div>
                    <p className='grid-x u-mb-0'>
                      <span className='cell auto'>
                        {lg(lang, CARDS_DELIVERY)}
                      </span>
                      {/* <span className='cell shrink u-text-right u-mr-4'>
                      <s>170 Kč</s>
                    </span> */}
                      <span className='cell shrink u-text-right'>
                        <b>
                          {cenik.doruceni === 0 ? 'zdarma' : cenik.doruceni} Kč
                        </b>
                      </span>
                    </p>
                    <div className='u-mb-2'>
                      <small className='cell auto u-color-c1-40'>
                        <span className='small-text'>
                          {`${lg(lang, CARDS_DELIVERY)} ${lg(
                            lang,
                            TO_THE_COMPANY_ADDRESS
                          )}`}
                        </span>
                      </small>
                    </div>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        borderTop: '1px solid #C2C2E4',
                      }}
                      className='grid-x u-mt-0'
                    >
                      <span className='cell auto u-pt-1'>
                        {lg(lang, TOTAL_PRICE)}
                      </span>
                      <span className='u-text-right cell cell--boxed u-pl-0 u-pr-0 u-pb-0 u-pt-1'>
                        <b>
                          {numberWithSpacesMax2(
                            cenik.vydaniPrvniKarty * (pocetZamestnancu || 1) +
                              cenik.doruceni
                          )}{' '}
                          Kč
                        </b>
                      </span>
                    </p>
                    <small className='cell auto u-color-c1-40 u-pb-1'>
                      <span className='small-text'>
                        {lg(lang, PRICE_WITHOUT_VAT)}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id='smluvni-podminky'
            style={{ marginTop: '-80px', paddingBottom: '60px' }}
          >
            {/* style={{ display: 'flex', justifyContent: 'space-around' }} */}
            <div className='grid-x'>
              <div className='cell medium-3'></div>
              <div className='cell medium-3 u-pl-2 u-pr-2'>
                <NavHashLink
                  smooth
                  // to='#smluvni-podminky'
                  to='/multipasscard/kalkulace#smluvni-podminky'
                  className='btn btn--secondary btn--outlined'
                  // onClick={handleSubmit}
                >
                  <span>{lg(lang, GO_TO_TERMS_AND_CONDITIONS)}</span>
                </NavHashLink>
              </div>
              {/* <div className='cell medium-1'></div> */}
              <ContinueButton />
              <div className='cell medium-3'></div>
            </div>
          </div>
          <div
            className='container container--wide u-pt-5 u-pb-6'
            style={{ background: 'white', borderRadius: 24 }}
          >
            <h2 className='u-text-center u-mb-3'>
              {lg(lang, TERMS_AND_CONDITIONS)}
            </h2>
            <div className='highlight__content'>
              <div className='grid-x'>
                <div className='cell large-6 u-pr-medium-4 u-mb-large-0'>
                  <div className='highlight u-mb-3 u-py-3'>
                    <p className='grid-x align-justify color-sodexo-blue'>
                      <span className='cell medium-auto'>
                        {lg(lang, LENGTH_OF_CONTRACT_PERIOD)}
                      </span>
                      <strong className='cell medium-shrink'>
                        {lg(lang, INDEFINITE)}
                      </strong>
                    </p>
                    <p className='grid-x align-justify color-sodexo-blue'>
                      <span className='cell medium-auto'>
                        {lg(lang, NOTICE_PERIOD)}
                      </span>
                      <strong className='cell medium-shrink'>
                        3 {lg(lang, MONTHS_TWO)}
                      </strong>
                    </p>
                    <p className='grid-x align-justify color-sodexo-blue'>
                      <span className='cell medium-auto'>
                        {lg(lang, MIN_CREDITS_ORDER_PER_MONTH)}
                      </span>
                      <strong className='cell medium-shrink color-sodexo-blue'>
                        {lg(lang, NONE)}
                      </strong>
                    </p>
                    <p className='grid-x align-justify u-mb-1 color-sodexo-blue'>
                      <span className='cell medium-auto'>
                        {lg(lang, MATURITY)}
                      </span>
                      <strong className='cell medium-shrink'>
                        {lg(lang, BEFORE_DELIVERY)}
                      </strong>
                    </p>
                  </div>
                  <div className='highlight u-py-3'>
                    <Smlouvy onboardingOne /> {/* label={'Náhledy smluv'} */}
                  </div>
                </div>
                <div className='cell large-6'>
                  <div
                    className={`${
                      !matches ? 'u-mt-3' : 'u-mt-0'
                    } highlight u-pt-5 u-pb-6`}
                    style={{ maxWidth: '840px' }}
                  >
                    <div
                      style={{ borderBottom: '1px solid #C2C2E4' }}
                      className='highlight__title grid-x color-sodexo-blue u-pb-2'
                    >
                      <span>Karta Multibenefit</span>
                      <small className='u-mr-4 xsmall-2 u-desktop-only'>
                        {lg(lang, COMMON_PRICE)}
                      </small>
                      <small className='xsmall-2 u-desktop-only'>
                        {lg(lang, YOUR_PRICE)}
                      </small>
                    </div>
                    <div className='highlight__content'>
                      <p className='grid-x color-sodexo-blue u-pt-1'>
                        <span className='cell auto'>
                          {lg(lang, CARDS_ISSUANCE)}
                          <br />
                          <small className='small-text'>
                            {/* Multi Pass CARD ({pocetZamestnancu}ks) */}
                            Karta Multibenefit (1{lg(lang, PCS)})
                          </small>
                        </span>
                        <span className='cell xsmall-2 u-text-right u-mr-4 u-desktop-only color-sodexo-grey'>
                          <>
                            80{' '}
                            {/* {numberWithSpacesX(
                              pocetZamestnancu ? pocetZamestnancu * 80 : 600
                            )}{' '} */}
                            Kč
                          </>
                        </span>
                        <span className='cell shrink small-2 u-text-right'>
                          {numberWithSpacesMax2(cenik.vydaniPrvniKarty)} Kč
                          {/* {cenik.vydaniPrvniKarty === 0
                            ? 'zdarma'
                            : numberWithSpacesMax2(
                                cenik.vydaniPrvniKarty * (pocetZamestnancu || 1)
                              ) + ' Kč'} */}
                        </span>
                      </p>
                      <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                        <span className='cell auto'>
                          {lg(lang, CARDS_DELIVERY)}
                          <br />
                          <small className='small-text'>
                            {lg(lang, DELIVERY_TO_THE_COMPANY_ADDRESS)}
                          </small>
                        </span>
                        <span className='cell xsmall-2 u-text-right u-mr-4 u-desktop-only color-sodexo-grey'>
                          <>170 Kč</>
                        </span>
                        <span className='cell shrink small-2 u-text-right'>
                          {cenik.doruceni} Kč
                        </span>
                      </p>
                      <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                        <span className='cell auto'>
                          {lg(lang, MEAL_VOUCHER_CREDIT)}
                          <br />
                          <small className='small-text'>
                            {lg(lang, CREDIT_ORDER_FEE)}
                          </small>
                        </span>
                        <span className='cell xsmall-2 u-text-right u-mr-4 u-desktop-only color-sodexo-grey'>
                          <>4 %</>
                        </span>
                        <span className='cell shrink small-2 u-text-right'>
                          {procentualniPoplatek(cenik.stravenky)}
                        </span>
                      </p>
                      <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                        <span className='cell auto'>
                          {lg(lang, LEISURE_CREDIT)}
                          <br />
                          <small className='small-text'>
                            {lg(lang, CREDIT_ORDER_FEE)}
                          </small>
                        </span>
                        <span className='cell xsmall-2 u-text-right u-mr-4 u-desktop-only color-sodexo-grey'>
                          <>5 %</>
                        </span>
                        <span className='cell shrink small-2 u-text-right'>
                          {procentualniPoplatek(cenik.volnocas)}
                        </span>
                      </p>
                      <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1 u-pb-2 u-mb-05'>
                        <span className='cell auto'>
                          {lg(lang, MONTHLY_USER_ACCOUNT_MANAGEMENT)}
                          <br />
                          <small className='small-text'>
                            {lg(lang, FEE_FOR_ACTIVE_USER_ACCOUNT)}
                          </small>
                        </span>
                        <span className='cell xsmall-2 u-text-right u-mr-4 u-desktop-only color-sodexo-grey'>
                          <>5 Kč</>
                        </span>
                        <span className='cell shrink small-2 u-text-right'>
                          {cenik.spravaJednohoUctu} Kč
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='u-pt-2 u-pr-2'>
              <ContinueButton />
            </div>
          </div>
        </div>
        <div className='custom-shape-divider-top-1655459350'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
          >
            <path
              d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
              className='shape-fill'
            ></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default OnboardingOne
