import OnboardingThree from 'components/onboarding/OnboardingThree'
import OnboardingFour from 'components/onboarding/onboardingfour/OnboardingFour'
import OnboardingSuccess from 'components/onboarding/OnboardingSuccess'
import OnboardingTwo from 'components/onboarding/OnboardingTwo'
import OnboardingOne from 'components/onboarding/OnboardingOne'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ScrollToTop from 'util/ScrollToTop'
import OfferAndLead from 'components/onboarding/OfferAndLead'
import OfferAndLeadExit from 'components/onboarding/OfferAndLeadExit'
import { useAppSelector } from 'app/hooks'
import { selectFarthestVisitedLocation } from 'components/onboarding/features/stepperSlice'
import TableContainer from 'components/table/TableContainer'
import Settings from 'components/settings/Settings'
import OrdersOverview from 'components/table/OrdersOverview'
import Faq from 'components/faq/Faq'

import DataImportOne from 'components/dataimportcentre/DataImportOne'
import DataImportTwo from 'components/dataimportcentre/DataImportTwo'
import DataImportThree from 'components/dataimportcentre/DataImportThree'
import DataImportFour from 'components/dataimportcentre/DataImportFour'
import DataImportFive from 'components/dataimportcentre/DataImportFive'
import DataImportSix from 'components/dataimportcentre/DataImportSix'
import DataImportSummary from 'components/dataimportcentre/DataImportSummary'
import TopNavBar from 'components/modules/menu/TopNavBar'
// import PartnersMap from 'components/modules/mapofpartners/PartnersMap'
import MagicLink from 'components/magiclink/MagicLink'
import Soap from 'util/Soap'
import JwtAuth from 'components/magiclink/JwtAuth'
import { getPrecious, loggedIn } from 'app/back'
import { logout } from 'app/req'
import { sinking } from 'app/julesverne'
import InternalWeb from 'components/table/InternalWeb'
import Register from 'components/internal/Register'
import Login from 'components/internal/Login'
import ReconLink from 'components/magiclink/ReconLink'
import { Suspense } from 'react'
import {
  selectHasError,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import { isJwtExpired } from 'app/cryptoOps'
import { isEmpty } from 'app/util'

export const isReconEnabled = () => {
  return true
}

const parentAppUrl =
  window.env.URL_PARENT_APP ??
  process.env.REACT_APP_URL_PARENT_APP ??
  'http://localhost:3002'

export const homepage = process.env.PUBLIC_URL

export const urlBackend =
  window.env.URL_API ?? process.env.REACT_APP_URL_API ?? 'http://localhost:3001'

export const urlLogoutNeprihlaseny = `${parentAppUrl}/?languageCode=cs'`

export const uriStaticMedia =
  window.env.URL_STATIC_MEDIA ??
  process.env.REACT_APP_URL_STATIC_MEDIA ??
  'http://localhost:3001/static/media'

export const urlOchranaOsobnichUdaju =
  window.env.URL_PRIVACY_POLICY ??
  process.env.REACT_APP_URL_PRIVACY_POLICY ??
  'http://localhost:3003/dokumenty-sodexo/ochrana-osobnich-udaju.html'

export const urlKatalogProvozoven =
  window.env.URL_PARTNERS_CATALOGUE ??
  process.env.REACT_APP_URL_PARTNERS_CATALOGUE ??
  'http://localhost:3004'

export const APP_VERSION =
  window.env.VERSION ?? process.env.REACT_APP_VERSION ?? '0.0.0'

export const beFaq = urlBackend + '/faq'
export const beAfterLogin = urlBackend + '/after-login'
export const beObjednavky = urlBackend + '/objednavky'
export const beMakeAdresa = urlBackend + '/dorucovaci-adresy' // POST, PUT, DELETE
export const beMakeOddeleni = beMakeAdresa + '/oddeleni' // POST, PUT, DELETE
export const beMakeZamestnanec = urlBackend + '/zamestnanci' // POST, PUT
export const beMakeFakturacniUdaje = urlBackend + '/settings/fakturacni-udaje' // POST, PUT
export const beMakeKontaktniOsoba = urlBackend + '/settings/kontaktni-osoby' // POST, PUT, DELETE
export const beMakeSpolecnost = urlBackend + '/settings/spolecnosti' // PUT
export const beMakeSkupina = urlBackend + '/zamestnanci/skupiny' // POST, PUT, DELETE
export const beMutatePocatekCasu = `${urlBackend}/settings/spolecnosti` // PATCH
export const beContractWatermark = `${urlBackend}/onboarding/contract` // POST
export const beIndividualniCenikWatermark = `${urlBackend}/onboarding/pricelist` // POST
export const beContract = urlBackend + '/onboarding/contract' // POST
export const beIndividualniCenik = urlBackend + '/onboarding/pricelist' // POST
export const beSignedContract = urlBackend + '/settings/smlouva' //GET
export const beSignedIndividualniCenik = urlBackend + '/settings/cenik' //GET
export const beUploadExcel = urlBackend + '/data-import-center/upload' // POST
export const beDownloadExcel = urlBackend + '/data-import-center/uploaded-files' // GET + /{id}
export const beLogFrontendError =
  urlBackend + '/debug/logFrontendError?DEBUG_KEY=wS5xyTmmJJU9qzJQezmG' // POST
export const beLogoutInternal = urlBackend + '/user/logout' // POST

export const beSodexoUcetLoginRedirect = `${parentAppUrl}/RedirectLogin/Login?redirectUrlKey=RedirectRegisterOrd&companyId=`

export const beInterniSpolecnosti =
  urlBackend + '/interni/spolecnosti?API_KEY=aeRLOaLAvJytX1NPUq4YjgsPL'
export const beInterniLogin =
  urlBackend + '/internal/login?API_KEY=aeRLOaLAvJytX1NPUq4YjgsPL'
export const beInterniSprava =
  urlBackend + '/interni/sprava?API_KEY=aeRLOaLAvJytX1NPUq4YjgsPL'

export const beOnboarding4Redirect = urlBackend + '/user/sc-redirect'

export const urlMultiPassCard = '/multipasscard'
export const urlOfferAndLead = urlMultiPassCard + '/formular'
export const urlOfferAndLeadExit = urlMultiPassCard + '/podekovani'
export const urlOnboarding1 = urlMultiPassCard + '/kalkulace'
export const urlOnboarding2 = urlMultiPassCard + '/firemni-udaje'
export const urlOnboarding3 = urlMultiPassCard + '/smlouva'
export const urlOnboarding4 = urlMultiPassCard + '/registrace'
export const urlOnboardingSuccess = urlMultiPassCard + '/dokonceni-registrace'

export const urlRecontracting = '/recontracting'
export const urlReconOfferAndLeadExit = urlRecontracting + '/podekovani'
export const urlRecon1 = urlRecontracting + '/kalkulace'
export const urlRecon2 = urlRecontracting + '/firemni-udaje'
export const urlRecon3 = urlRecontracting + '/smlouva'
export const urlRecon4 = urlRecontracting + '/registrace'
export const urlReconSuccess = urlRecontracting + '/dokonceni-registrace'

export const urlInternal = '/internal'
export const urlInternalLogin = urlInternal + '/prihlaseni'
export const urlInternalRegister = urlInternal + '/registrace'
export const urlInternalWeb = urlInternal + '/web'

export const urlSelfcareTable = '/objednavka'
export const urlOrdersOverview = '/prehled-objednavek'
export const urlNastaveni = '/nastaveni'
export const urlFaq = '/dotazy'

export const urlMagicLink = '/magiclink' // Hash History https://stackoverflow.com/a/36623117/3832336 actually doesn't work. It breaks the Navigates and also loading of the data. Interesting...
export const urlReconLink = '/recontracting/magiclink'

export const urlDataImportOne = '/data-import-1'
export const urlDataImportTwo = '/data-import-2'
export const urlDataImportThree = '/data-import-3'
export const urlDataImportFour = '/data-import-4'
export const urlDataImportFive = '/data-import-5'
export const urlDataImportSix = '/data-import-6'
export const urlDataImportSummary = '/data-import-summary'

export const urlPartnersMap = '/mapa-partneru'
export const urlNotFound = '/404'
export const urlNenalezeno = '/nenalezeno'

export const urlJwtAuth = '/jwt-auth'
export const urlSoap = '/soap'

function App() {
  console.log('App() - app version: ' + APP_VERSION)

  const farthestVisitedLocation = useAppSelector(selectFarthestVisitedLocation)
  // contains stale value, always recheck with calling getTokenFromLocalStorage()
  const jwt = getPrecious()
  console.log('App, jwt', jwt)
  const isLoggedIn = loggedIn()

  console.log('urlBackend', urlBackend)

  // sinking() && console.log('get precious ', precious || 'empty')
  sinking() && console.log('farthest', farthestVisitedLocation)

  function isUnlockedLocationOnboarding(urlNumber: number) {
    // return urlNumber > 4 ? false : true
    return farthestVisitedLocation >= urlNumber || isLoggedIn
  }

  function navigateIfNotLoggedIn() {
    // try useHistory.push when upgraded to React Router 6
    // https://stackoverflow.com/a/42121109/3832336

    console.log('NavigateIfNotLoggedIn, loggedIn = ' + isLoggedIn)
    sinking() &&
      console.log(
        'NavigateIfNotLoggedIn, tokenFromLocalStorage = ' + getPrecious()
      )
    console.log(
      `NavigateIfNotLoggedIn, jwt is empty = ${isEmpty(
        getPrecious()
      )}, expired = ${isJwtExpired(getPrecious())}`
    )
    // this check lags a bit, and it is a big deal
    if (getPrecious()) {
      setTimeout(() => {
        navigateIfNotLoggedIn()
      }, 1000)
    } else if (isJwtExpired(getPrecious())) {
      // setNavigate(true)
      logout()
    }
  }

  return <div>{renderApp()}</div>

  function renderApp() {
    return (
      <div className='App'>
        <header className='App-header'>
          <BrowserRouter>
            <ScrollToTop>
              <TopNavBar />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <>
                    <Route path={urlSoap} element={<Soap />} />

                    {/* Extra-application Links */}
                    <Route path={urlMagicLink} element={<MagicLink />} />
                    <Route path={urlReconLink} element={<ReconLink />} />
                    <Route path={urlJwtAuth} element={<JwtAuth />} />
                    {/* <Route path={urlPartnersMap} element={PartnersMap} /> */}

                    {/* Inside-application Links */}
                    {/* automatic Navigate to sodexo one if not logged in. - turned off for Tomas */}
                    {!isLoggedIn &&
                      // commented out this check since the homepage got moved to /objednavka instead of /multipasscard/formular
                      // this check serves as a Navigate stopper in case someone just types in the url of the server and expects the basic Navigate to onboarding
                      // window.location.pathname !== '/' &&

                      // not logged in user is allowed to go through onboarding and magiclink
                      !window.location.pathname.includes(urlMultiPassCard) &&
                      !window.location.pathname.includes(urlRecontracting) &&
                      !window.location.pathname.includes(urlMagicLink) &&
                      !window.location.pathname.includes(urlInternal) &&
                      // Navigate to sodexo one / muj-ucet
                      setTimeout(() => navigateIfNotLoggedIn(), 0)}
                    {!isLoggedIn && (
                      <Route path={urlInternalWeb} element={<Login />} />
                    )}
                    {/* Miscellaneous */}
                    <Route path={`${urlNastaveni}/*`} element={<Settings />} />
                    <Route path={urlFaq} element={<Faq />} />

                    {/* Internal */}
                    <Route path={urlInternalLogin} element={<Login />} />
                    <Route path={urlInternalRegister} element={<Register />} />
                    <Route path={urlInternalWeb} element={<InternalWeb />} />
                    {/* Selfcare */}
                    <Route
                      path={`${urlOrdersOverview}/*`}
                      element={<OrdersOverview />}
                    />
                    <Route
                      path={urlSelfcareTable}
                      element={<TableContainer />}
                    />

                    {/* Data Import Center */}
                    <Route
                      path={urlDataImportOne}
                      element={<DataImportOne />}
                    />
                    <Route
                      path={urlDataImportTwo}
                      element={<DataImportTwo />}
                    />
                    <Route
                      path={urlDataImportThree}
                      element={<DataImportThree />}
                    />
                    <Route
                      path={urlDataImportFour}
                      element={<DataImportFour />}
                    />
                    <Route
                      path={urlDataImportFive}
                      element={<DataImportFive />}
                    />
                    <Route
                      path={urlDataImportSix}
                      element={<DataImportSix />}
                    />
                    <Route
                      path={urlDataImportSummary}
                      element={<DataImportSummary />}
                    />

                    <Route
                      path='/'
                      element={<Navigate to={urlSelfcareTable} />}
                    />

                    {/* ################################## */}
                    {/* ######## Onboarding Below ######## */}
                    {/* ################################## */}
                    <Route path={urlMultiPassCard} element={<OfferAndLead />} />
                    {/* <Route path={urlOfferAndLead} element={<OfferAndLead />} />
            <Route
              path={urlOfferAndLeadExit}
              element={<OfferAndLeadExit />}
            /> */}
                    {/* If process has been finished, user goes to selfcare, unless he enters a URL for starting the onboarding process anew. */}
                    {isUnlockedLocationOnboarding(6) ? (
                      <>
                        <Route
                          path={urlOfferAndLead}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<TableContainer />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<TableContainer />}
                        />
                      </>
                    ) : isUnlockedLocationOnboarding(5) ? (
                      <>
                        {/* If process has been a success, user cannot return back. */}
                        <Route
                          path={urlOfferAndLead}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OnboardingSuccess />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OnboardingSuccess />}
                        />
                      </>
                    ) : isUnlockedLocationOnboarding(4) ? (
                      <>
                        {/* User can go back and forth between steps 1-4, but not to OfferAndLead */}
                        <Route
                          path={urlOfferAndLead}
                          element={<OnboardingFour />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OnboardingThree />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OnboardingFour />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OnboardingFour />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OnboardingFour />}
                        />
                      </>
                    ) : isUnlockedLocationOnboarding(3) ? (
                      <>
                        <Route
                          path={urlOfferAndLead}
                          element={<OnboardingThree />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OnboardingThree />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OnboardingThree />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OnboardingThree />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OnboardingThree />}
                        />
                      </>
                    ) : isUnlockedLocationOnboarding(2) ? (
                      <>
                        <Route
                          path={urlOfferAndLead}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OnboardingTwo />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OnboardingTwo />}
                        />
                      </>
                    ) : isUnlockedLocationOnboarding(1) ? (
                      <>
                        <Route
                          path={urlOfferAndLead}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OnboardingOne />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OnboardingOne />}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          path={urlOfferAndLead}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboarding1}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboarding2}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboarding3}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboarding4}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOnboardingSuccess}
                          element={<OfferAndLead />}
                        />
                        <Route
                          path={urlOfferAndLeadExit}
                          element={<OfferAndLead />}
                        />
                      </>
                    )}

                    {/* ################################## */}
                    {/* ######## Recontracting Below ######## */}
                    {/* ################################## */}
                    {/* If process has been finished, user cannot return back. */}
                    {isReconEnabled() && isUnlockedLocationOnboarding(4) && (
                      <>
                        <Route
                          path={urlRecon1}
                          element={<Navigate to={urlReconSuccess} />}
                        />
                        <Route
                          path={urlRecon2}
                          element={<Navigate to={urlReconSuccess} />}
                        />
                        <Route
                          path={urlRecon3}
                          element={<Navigate to={urlReconSuccess} />}
                        />
                      </>
                    )}
                    {isReconEnabled() && isUnlockedLocationOnboarding(5) && (
                      <Route
                        path={urlRecon4}
                        element={<Navigate to={urlReconSuccess} />}
                      />
                    )}
                    {isReconEnabled() && isUnlockedLocationOnboarding(6) && (
                      <>
                        <Route
                          path={urlReconSuccess}
                          element={<Navigate to={urlSelfcareTable} />}
                        />
                      </>
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlRecontracting}
                        element={<Navigate to={urlRecon1} />}
                      />
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlReconOfferAndLeadExit}
                        element={<OfferAndLeadExit />}
                      />
                    )}
                    {isReconEnabled() && (
                      <Route path={urlRecon1} element={<OnboardingOne />} />
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlRecon2}
                        element={
                          isUnlockedLocationOnboarding(2) ? (
                            <OnboardingTwo />
                          ) : (
                            <Navigate to={urlRecon1} />
                          )
                        }
                      />
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlRecon3}
                        element={
                          isUnlockedLocationOnboarding(3) ? (
                            <OnboardingThree />
                          ) : isUnlockedLocationOnboarding(2) ? (
                            <Navigate to={urlRecon2} />
                          ) : (
                            <OnboardingOne />
                          )
                        }
                      />
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlRecon4}
                        element={
                          isUnlockedLocationOnboarding(4) ? (
                            <OnboardingFour />
                          ) : isUnlockedLocationOnboarding(3) ? (
                            <Navigate to={urlRecon3} />
                          ) : isUnlockedLocationOnboarding(2) ? (
                            <Navigate to={urlRecon2} />
                          ) : (
                            <Navigate to={urlRecon1} />
                          )
                        }
                      />
                    )}
                    {isReconEnabled() && (
                      <Route
                        path={urlReconSuccess}
                        element={
                          isUnlockedLocationOnboarding(5) ? (
                            <OnboardingSuccess />
                          ) : isUnlockedLocationOnboarding(4) ? (
                            <Navigate to={urlRecon4} />
                          ) : isUnlockedLocationOnboarding(3) ? (
                            <Navigate to={urlRecon3} />
                          ) : isUnlockedLocationOnboarding(2) ? (
                            <Navigate to={urlRecon2} />
                          ) : (
                            <Navigate to={urlRecon1} />
                          )
                        }
                      />
                    )}

                    {/* should work but doesn't */}
                    {/* <Route path={'*'} element={Error404} /> */}
                    {/* <Navigate to={urlNotFound} /> */}
                  </>
                </Routes>
              </Suspense>
            </ScrollToTop>
          </BrowserRouter>
          {/*
    <button onClick={() => setCurrentWindow('table')}>Show Table</button>
    {rende element(currentWindow)} */}
          {/* <button onClick={() => setShowPartnersMap(!showPartnersMap)}>
    Show PartnersMap
  </button>
  {showPartnersMap && <PartnersMap />} */}
          {/* <Counter /> */}
        </header>
      </div>
    )
  }
}

export default App
function selectLang(state: any): unknown {
  throw new Error('Function not implemented.')
}
