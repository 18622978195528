import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class telephoneIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'telephone-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height, style } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
        style={style}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.0002 15.5856C21.7812 16.3667 21.7812 17.633 21.0002 18.4141L19.0183 20.3959C17.947 21.4672 16.3623 21.8413 14.925 21.3622L13.6964 20.9527C7.72431 18.962 3.038 14.2757 1.0473 8.30358L0.637787 7.07503C0.158672 5.63769 0.53276 4.05302 1.60409 2.98169L3.58594 0.999848C4.36699 0.218801 5.63331 0.218798 6.41436 0.999848L9.04002 3.62551C9.78922 4.37471 9.82414 5.5782 9.11963 6.36957L8.13773 7.47254C9.20351 10.4685 11.5897 12.8089 14.609 13.8154L14.7301 13.8557L15.5859 12.9998C16.367 12.2188 17.6333 12.2188 18.4144 12.9998L21.0002 15.5856ZM19.5859 16.9998L17.0001 14.4141L16.1013 15.3129C15.592 15.8222 14.8386 16.0001 14.1553 15.7723L13.9766 15.7127C10.3448 14.5022 7.48073 11.6745 6.22377 8.05859C6.0009 7.41747 6.14249 6.70594 6.5938 6.19897L7.62581 5.03972L8.37272 5.70465L7.62581 5.03972L5.00015 2.41406L3.0183 4.39591C2.48264 4.93157 2.29559 5.72391 2.53515 6.44258L2.94467 7.67113C4.7363 13.046 8.95398 17.2637 14.3289 19.0553L15.5574 19.4648C16.2761 19.7044 17.0684 19.5174 17.6041 18.9817L19.5859 16.9998Z'
          fill='#2E3897'
          style={style}
        ></path>
      </svg>
    )
  }
}

export default telephoneIcon
