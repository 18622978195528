import React, { useRef } from 'react'
import { useOnClickOutsideAndEscape } from 'app/hooks'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import Color from '../../../app/colors'

interface IExtraImportInfoModal {
  showExtraImportInfoModal: boolean
  setShowExtraImportInfoModal: any
}

export const ExtraImportInfoModal = ({
  showExtraImportInfoModal,
  setShowExtraImportInfoModal,
}: IExtraImportInfoModal) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowExtraImportInfoModal(false)
    }
  }

  useOnClickOutsideAndEscape(
    modalRef,
    () => setShowExtraImportInfoModal(false),
    showExtraImportInfoModal,
    setShowExtraImportInfoModal
  )

  return (
    <>
      {showExtraImportInfoModal ? (
        <div className='modal_background' onClick={closeModal} ref={modalRef}>
          <div className='extra-import-modal_wrapper'>
            <div className='small-modal_content'>
              <div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>Osobní číslo</h4>
                  <p>
                    Osobní číslo není povinné pole. Pokud jej nemáte,
                    automaticky se vygeneruje. V dalších krocích již nebude
                    možné číslo měnit, protože slouží pro identifikaci
                    zaměstnance.
                  </p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>Data navíc</h4>
                  <p>
                    Pokud váš soubor obsahuje navíc i data, která nejsou povinná
                    {'(např. datum nástupu, datum narození apod.)'}, tyto údaje
                    můžete v souboru ponechat, avšak nebudou mít vliv na
                    objednávku.
                  </p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>Více listů</h4>
                  <p>
                    V případě, že váš soubor obsahuje více listů, po nahrání
                    souboru vyberte list pro danou objednávku.
                  </p>
                </div>
              </div>
            </div>
            <DeleteThinIcon
              className='small-modal_close'
              aria-label='Close modal'
              onClick={() => setShowExtraImportInfoModal((prev: any) => !prev)}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
