import CryptoES from 'crypto-es'
import { newDate } from 'util/DateOps'

// Takes the input (such as username), pads it to 33 chars, and removes the char
// at the index equal to the charcode of the first char % 33
// output: 32-char string, deterministically computed from the input
export function createSalt32Char(input: string): string {
  // extend input to 33 characters by replicating it
  const paddedInput = input.padEnd(33, input)
  // remove character at the position equal to the charcode of the first char % 33
  const index = input.charCodeAt(0) % 33
  const out = paddedInput.substring(0, index) + paddedInput.substring(index + 1)
  return out
}

export function hash(input: string, salt: string): string {
  const hash = CryptoES.SHA256(input + salt)
  return hash.toString()
}

export function encrypt(str: string, key: string) {
  const encrypted = CryptoES.AES.encrypt(str, key)
  return encrypted
}

export function decrypt(str: string, key: string): string {
  const decrypted = CryptoES.AES.decrypt(str, key)
  return decrypted.toString(CryptoES.enc.Utf8)
}

export function decryptWithVector(
  str: string,
  key: string,
  iv: string
): string {
  console.log('decryptWithVector', str, key, iv)

  const decryptedWordArray = CryptoES.AES.decrypt(
    str,
    CryptoES.enc.Utf8.parse(key),
    {
      iv: CryptoES.enc.Utf8.parse(iv),
      mode: CryptoES.mode.CBC,
    }
  )
  const decryptedString = decryptedWordArray.toString(CryptoES.enc.Utf8)
  return decryptedString
}

export function encryptWithVector(
  str: string,
  key: string,
  iv?: string
): string {
  console.log('encryptWithVector', str, key, iv)

  let encrypted
  if (iv) {
    encrypted = CryptoES.AES.encrypt(str, key, {
      iv: CryptoES.enc.Utf8.parse(iv),
    })
    console.log('encrypted with iv', encrypted)
  } else {
    encrypted = CryptoES.AES.encrypt(str, key)
    console.log('encrypted without iv', encrypted)
  }
  return encrypted.toString()
}

export function convertEpochSecondsToISO(epoch: number): string {
  return new Date(epoch * 1000).toISOString()
}

export function isJwtExpired(jwt: string): boolean {
  if (!jwt) {
    return true
  }
  const payload = JSON.parse(getDecodedJwtPayloadAsString(jwt)) // can be JwtInternalPayload or JwtUserPayload
  const expSeconds = payload.exp
  const nowSecondsString = newDate().getTime() / 1000
  const nowSecondsRoundedNumber = Math.round(nowSecondsString)
  const result = nowSecondsRoundedNumber > expSeconds
  console.log('isJwtExpired = ', result, jwt)
  return result
}

export type JwtInternalPayload = {
  sub: [
    userId: string, // "8891e54ca49243adb483a9241f0ac6f3"
    name: string, // "Tomáš"
    surname: string // "Čepičák"
  ]
  role: string // "internal"
  exp: string // 1679316877 = epoch seconds
  iss: string // "pluxee.cz"
  iat: string // 1679309677
}

export type JwtUserPayload = {
  sub: string // 'SPO00000000001199986'
  session: string // 'qLPJ4Lpe_h9BBJKUFycGH'
  originalIssueTime: number // 1679064536
  iss: string // 'SDX WSO2 Tokenizator'
  ciamId: string // 'a170c4883cd04b1e8963690e6185b0d3'
  exp: number // 1679066336 = epoch seconds
  iat: number // 1679064536
  jti: string // '4b263fe3-19c2-4b43-96cd-b63636cda219'
}

export function parseJwtUser(token: string): JwtUserPayload {
  // console.log('Logging input argument to parseJwtUser function', token)
  const jsonStringPayload = getDecodedJwtPayloadAsString(token)

  return JSON.parse(jsonStringPayload) as JwtUserPayload
}

export function parseJwtInternal(token: string): JwtInternalPayload {
  // console.log('Logging input argument to parseJwtInternal function', token)
  const jsonStringPayload = getDecodedJwtPayloadAsString(token)

  return JSON.parse(jsonStringPayload) as JwtInternalPayload
}

function getDecodedJwtPayloadAsString(token: string): string {
  token = token + '' // hack
  var base64UrlPayload = token.split('.')[1] // 'header.payload.signature'
  var base64Payload = base64UrlPayload.replace(/-/g, '+').replace(/_/g, '/') // Base64Url format is converted to a standard Base64 format
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64Payload) // payload is decoded from Base64 to a string using the window.atob() function. This returns a string with characters that may not be safe to use in a URL
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2) // unsafe characters are converted to URL-safe characters using the encodeURIComponent() function. This is done by mapping each character to its respective character code in hexadecimal format, then adding a '%' before each hexadecimal value. The result is joined back into a single string.
      })
      .join('')
  )
  return jsonPayload
}
