import React, { useEffect, useState } from 'react'
import ClockIcon from '@styles/icon/clockIcon'
import MailIcon from '@styles/icon/mailIcon'
import TelephoneIcon from '@styles/icon/telephoneIcon'
import { Link } from 'react-router-dom'
import { SODEXO_INFO_KLIENT_EMAIL } from 'app/constants'
import { lg } from 'app/facade'
import { HM_FOOTER, MON_FRI } from 'app/constDictionary'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface Props {
  firstLevelClassNames: string
}

const HelpFooter = ({ firstLevelClassNames }: Props) => {
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div className='modal__footer'>
      <div className={firstLevelClassNames}>
        <p className='help-modal_contacts-p'>{lg(lang, HM_FOOTER)}</p>
        <div className='help-modal_contacts'>
          <div>
            <TelephoneIcon width={18} height={18} />
            <a
              style={{ marginTop: '-14px' }}
              href='tel:+420233113435'
              className='help-modal_contacts-element'
            >
              +420 233 113 435
            </a>
          </div>
          <div>
            <MailIcon width={32} height={32} />
            <Link
              to='#'
              className='help-modal_contacts-element'
              style={{ marginLeft: '-8px', marginTop: '-14px' }}
              onClick={(e) => {
                window.location.href = 'mailto:' + SODEXO_INFO_KLIENT_EMAIL
                e.preventDefault()
              }}
            >
              {SODEXO_INFO_KLIENT_EMAIL}
            </Link>
          </div>
          <div>
            <ClockIcon width={32} height={32} />
            <p
              className='help-modal_contacts-element'
              style={{ marginLeft: '-10px' }}
            >
              {lg(lang, MON_FRI)}&nbsp;8-17
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpFooter
