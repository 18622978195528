import FileIcon from '@styles/icon/fileIcon'
import React, { FunctionComponent } from 'react'

interface NoHrefPdfComponentProps {
  label: string
  onClick?: () => void
}

const PdfComponent: FunctionComponent<NoHrefPdfComponentProps> = ({
  label,
  onClick,
}) => {
  return (
    <p className='u-py-0'>
      <svg
        className='icon icon--24'
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <FileIcon />
      </svg>
      <button
        className='button-as-anchor u-ml-1'
        onClick={onClick}
        style={{
          color: '#283897',
        }}
      >
        {label}
      </button>
    </p>
  )
}

export default PdfComponent
