import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class questionCircleOutlineIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'question-circle-outline-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28 16c0 6.627-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4s12 5.373 12 12zM16 30c7.732 0 14-6.268 14-14S23.732 2 16 2 2 8.268 2 16s6.268 14 14 14zm.843-10.26h-2.48v-1.36l2.34-2.76c.96-1.14 1.4-2.02 1.4-2.92 0-1.22-1-1.58-2.34-1.58h-2.72V9h3.44c2.78 0 4.1 1.14 4.1 3.44 0 1.02-.5 2.18-1.78 3.7l-1.96 2.32v1.28zm0 4.26h-2.48v-2.5h2.48V24z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default questionCircleOutlineIcon
