import React, { useRef } from 'react'
import { useOnClickOutsideAndEscape } from 'app/hooks'
import InfoCircleFillIcon from '@styles/icon/InfoCircleFillIcon'

interface IErrorModal {
  showErrorModal: boolean
  setShowErrorModal: any
  text: string
}

export const ErrorModal = ({
  showErrorModal,
  setShowErrorModal,
  text,
}: IErrorModal) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowErrorModal(false)
    }
  }

  useOnClickOutsideAndEscape(
    modalRef,
    () => setShowErrorModal(false),
    showErrorModal,
    setShowErrorModal
  )

  return (
    <>
      {showErrorModal && (
        <div
          className='modal_background u-mt-2'
          onClick={closeModal}
          ref={modalRef}
        >
          <div className='error-modal_wrapper'>
            <div className='error-modal_content'>
              <InfoCircleFillIcon width={17} height={17} />
              <p>{text}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
