import { ConsignmentState, OrderState } from 'app/type'
import { MultiPassCardState } from './CardOps'

export function getColorCardStatus(status: string): string {
  switch (status) {
    case MultiPassCardState.VKošíku:
      return 'u-color-blue-light'
    case MultiPassCardState.NáhradníKartaVKošíku:
      return 'u-color-aquamarine'
    case MultiPassCardState.VeVýrobě:
      return 'u-color-violet'
    case MultiPassCardState.Aktivní:
      return 'u-color-firgreen-medium'
    case MultiPassCardState.Blokovaná:
      return 'u-color-sodexo-red'
    case MultiPassCardState.Expirovaná:
      return 'u-color-new-orange'
    default:
      return ''
  }
}

export function selectCardTagClass(cardStatus: string) {
  switch (cardStatus) {
    case MultiPassCardState.VKošíku:
      return 'tag--blue-light'
    case MultiPassCardState.NáhradníKartaVKošíku:
      return 'tag--aquamarine'
    case MultiPassCardState.Expirovaná:
      return 'tag--red'
    case MultiPassCardState.Blokovaná:
      return 'tag--red'
    case MultiPassCardState.Aktivní:
      return 'tag--aquamarine'
    default:
      return 'tag--blue'
  }
}
export function colorize(state: ConsignmentState | OrderState): string {
  switch (state) {
    case ConsignmentState.ŽádnéKarty:
      return ''
    case ConsignmentState.ČekáNaZpracování:
      return 'u-color-blue-light'
    case ConsignmentState.VeVýrobě:
      return 'u-color-violet'
    case ConsignmentState.ČekáNaPřevzetí:
      return 'u-color-new-orange'
    case ConsignmentState.Převzatá:
      return 'u-color-firgreen-medium'
    case OrderState.Stornovaná:
      return ''
    case OrderState.ČekáNaZaplacení:
    case OrderState.RozpracovanýDobropis:
      return 'u-color-new-orange'
    case OrderState.Přijatá:
      return 'u-color-blue-light'
    case OrderState.Zaplacená:
    case OrderState.Vrubopis:
    case OrderState.Dobropis:
      return 'u-color-violet'
    case OrderState.Doručená:
      return 'u-color-firgreen-medium'
    case OrderState.Blokovaná:
      return 'u-color-sodexo-red'
    default:
      return ''
  }
}
