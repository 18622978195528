import React, { useState, useEffect } from 'react'
import DirectionIcon from '@styles/icon/directionIcon'
import FiremniUdaje from './FiremniUdaje'
import Stepper from './Stepper'
import { urlRecon3, urlMultiPassCard, urlOnboarding3 } from 'App'
import { lg } from 'app/facade'
import {
  CONTINUE_NEXT_STEP,
  FIRM_INFORMATION,
  OB2_SUBHEADER,
} from 'app/constDictionary'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { isRecon } from 'components/magiclink/ReconLink'

const OnboardingTwo: React.FC = () => {
  const submitIcon = <DirectionIcon />
  const nextRoute = isRecon() ? urlRecon3 : urlOnboarding3

  const lang = useAppSelector(selectLanguage) || 'cz'
  const submitText = lg(lang, CONTINUE_NEXT_STEP)

  return (
    <div className='container u-my-6'>
      <Stepper />
      <h2 className='heading heading--2 u-text-center u-mb-1'>
        {lg(lang, FIRM_INFORMATION)}
      </h2>
      <p className='u-mb-5 u-text-center'>{lg(lang, OB2_SUBHEADER)}</p>
      <div className='grid-x u-justify-center'>
        <div className='cell medium-7 u-mb-8'>
          <FiremniUdaje
            submitIcon={submitIcon}
            submitText={submitText}
            nextRoute={nextRoute}
            onSubmit={function (): void {}}
          />
        </div>
      </div>
    </div>
  )
}

export default OnboardingTwo
