import { FunctionComponent, useState } from 'react'
import EyeOpenFilledIcon from '@styles/icon/eyeOpenFilledIcon'
import LockIcon from '@styles/icon/lockIcon'
import PenIcon from '@styles/icon/penIcon'
import { Formik, Form, Field } from 'formik'
import { useAppDispatch } from 'app/hooks'
import {
  validateEmail,
  validatePassword,
  validatePasswordMatch,
} from 'util/ValidFormatOps'
import Tip from 'components/settings/Tip'
import {
  createSalt32Char,
  decryptWithVector,
  encryptWithVector,
  hash,
  parseJwtUser,
} from 'app/cryptoOps'
import { getPrecious } from 'app/back'

const Register: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShown2, setPasswordShown2] = useState(false)

  const [encodedString, setEncodedString] = useState('')
  const [inputMessage, setInputMessage] = useState('')
  const [inputKey, setInputKey] = useState('')
  const [inputVector, setInputVector] = useState('')

  const [decodedString, setDecodedString] = useState('')
  const [inputMessageDecode, setInputMessageDecode] = useState('')
  const [inputKeyDecode, setInputKeyDecode] = useState('')
  const [inputVectorDecode, setInputVectorDecode] = useState('')

  function createUserBackend(values: {
    email: string
    password: string
    repeatPassword: string
  }) {
    // create salt and hash password
    const salt = createSalt32Char(values.email)
    // hash the password
    const hashedPassword = hash(values.password, salt)
    // callbackend create user
  }

  const [computedHash, setComputedHash] = useState('')
  const [parsedJwt, setParsedJwt] = useState('')

  function getDecodedObject(decryptedString: string) {
    const vars: any = {}
    decryptedString.split('&').forEach((pair: string) => {
      const [key, value] = pair.split('=')
      vars[key] = decodeURIComponent(value)
    })
    return vars
  }

  return (
    <div className='container u-mx-0'>
      <h1 className='u-size-l28 u-mb-3 u-ml-5'>
        Registrace nového interního zaměstnance
      </h1>
      <Formik
        initialValues={{
          email: '',
          password: '',
          repeatPassword: '',
        }}
        onSubmit={(values, { resetForm }) => {
          createUserBackend(values)
          resetForm()
        }}
      >
        {({ errors, touched, values, submitForm }) => (
          <div className='highlight u-py-3 u-px-4 u-mb-3'>
            <div className='highlight__content'>
              <Form>
                <h3 className='u-size-l20 u-mb-4'>
                  Zvolte si přihlašovací údaje
                </h3>
                <div className='input u-mb-4'>
                  <label className='input__label'>Přihlašovací e-mail</label>
                  <div className='input__wrapper'>
                    <Field
                      placeholder='Např.: novak@pluxee.cz'
                      type='text'
                      name='email'
                      validate={validateEmail}
                    />
                  </div>
                  {errors.email && touched.email && (
                    <span className='input__error'>{errors.email}</span>
                  )}
                </div>
                <div className='input u-mb-3 input--with-icon input--password'>
                  <label className='input__label'>Heslo</label>
                  <div className='input__wrapper'>
                    <Field
                      placeholder='Heslo'
                      type={passwordShown ? 'text' : 'password'}
                      name='password'
                      validate={(value: string) => validatePassword(value)}
                    />
                    <svg
                      onMouseDown={() => setPasswordShown(true)}
                      onMouseUp={() => setPasswordShown(false)}
                      className='input__eye icon icon--24'
                    >
                      <EyeOpenFilledIcon width={24} height={24} />
                    </svg>
                  </div>
                  <div className='input__icon'>
                    <svg className='icon icon--24'>
                      <LockIcon width={24} height={24} />
                    </svg>
                  </div>
                  {errors.password && touched.password && (
                    <span className='input__error'>{errors.password}</span>
                  )}
                </div>
                <div className='input u-mb-3 input--with-icon input--password'>
                  <label className='input__label'>Zopakujte heslo</label>
                  <div className='input__wrapper'>
                    <Field
                      placeholder='Heslo'
                      type={passwordShown2 ? 'text' : 'password'}
                      name='repeatPassword'
                      validate={(value: string) =>
                        validatePasswordMatch(value, values.password)
                      }
                    />
                    <svg
                      onClick={() => setPasswordShown2(!passwordShown2)}
                      className='input__eye icon icon--24'
                    >
                      <EyeOpenFilledIcon width={24} height={24} />
                    </svg>
                  </div>
                  <div className='input__icon'>
                    <svg className='icon icon--24'>
                      <LockIcon width={24} height={24} />
                    </svg>
                  </div>
                  {errors.repeatPassword && touched.repeatPassword ? (
                    <span className='input__error'>
                      {errors.repeatPassword}
                    </span>
                  ) : (
                    <span className='input__help'>
                      Minimální délka hesla je 8 libovolných znaků
                    </span>
                  )}
                  <Tip
                    id={'prihlasovaci_udaje_tip'}
                    content={
                      'Přihlašovací údaje slouží pro vstup do systému, proto při jejich vytváření postupujte obezřetně.'
                    }
                  />
                </div>
                <a onClick={submitForm} className='btn btn--primary btn--small'>
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Zaregistrovat se
                </a>
                <a
                  onClick={() =>
                    setComputedHash(
                      hash(values.password, createSalt32Char(values.email))
                    )
                  }
                  className='btn btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Print password hash
                </a>
                <p>Hash: {computedHash}</p>
                <a
                  onClick={() =>
                    setParsedJwt(JSON.stringify(parseJwtUser(getPrecious())))
                  }
                  className='btn btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Decode and parse JWT
                </a>
                <p>Parsed JWT: {parsedJwt}</p>
                <label>Input Message</label>
                <input
                  key='input-message'
                  onChange={(e) => setInputMessage(e.target.value)}
                ></input>
                <label>Input Key</label>
                <input
                  key='input-key'
                  onChange={(e) => setInputKey(e.target.value)}
                ></input>
                <label>Input Vector</label>
                <input
                  key='input-vector'
                  onChange={(e) => setInputVector(e.target.value)}
                ></input>
                <a
                  onClick={() => {
                    setEncodedString(
                      encryptWithVector(inputMessage, inputKey, inputVector)
                    )
                  }}
                  className='btn btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Encode with AES
                </a>
                <p>Encoded string: {encodedString}</p>
                <label>Cyphered message</label>
                <input
                  key='input-cypher'
                  onChange={(e) => setInputMessageDecode(e.target.value)}
                ></input>
                <label>Input Key</label>
                <input
                  key='input-key-decode'
                  onChange={(e) => setInputKeyDecode(e.target.value)}
                ></input>
                <label>Input Vector</label>
                <input
                  key='input-vector-decode'
                  onChange={(e) => setInputVectorDecode(e.target.value)}
                ></input>
                <a
                  onClick={() =>
                    setDecodedString(
                      decryptWithVector(
                        inputMessageDecode,
                        inputKeyDecode,
                        inputVectorDecode
                      )
                    )
                  }
                  className='btn btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <PenIcon width={24} height={24} />
                  </svg>
                  Decode with AES
                </a>
                <p>Decoded string: {decodedString}</p>
                <p>
                  Decoded object:{' '}
                  {JSON.stringify(getDecodedObject(decodedString))}
                </p>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default Register
