import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class TrashIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'trash-icon',
    width: 16,
    height: 16,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13 4h7v2h-7V4zm-2 2V4a2 2 0 012-2h7a2 2 0 012 2v2h6a2 2 0 012 2v4a2 2 0 01-2 2v14a2 2 0 01-2 2H7a2 2 0 01-2-2V14a2 2 0 01-2-2V8a2 2 0 012-2h6zm11 2H5v4h23V8h-6zM7 28V14h19v14H7zm6-12a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1zm8 1a1 1 0 10-2 0v6a1 1 0 102 0v-6z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default TrashIcon
