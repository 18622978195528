import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class CloseCircleIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    width: 24,
    height: 24,
    fill: 'currentColor',
  }

  render() {
    const { className, width, height, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm5.013-22.658l-5.007 5.01-5.019-5.01a1.164 1.164 0 10-1.646 1.647L14.361 16l-5.008 5.011A1.164 1.164 0 1011 22.658l5.007-5.01 5.007 5.01a1.164 1.164 0 101.646-1.647L17.65 16l5.008-5.011a1.164 1.164 0 10-1.646-1.647z'
          fill={fill}
        />
      </svg>
    )
  }
}

export default CloseCircleIcon
