import {
  urlOnboardingSuccess,
  urlSelfcareTable,
  urlOrdersOverview,
  urlNastaveni,
  urlFaq,
  urlDataImportOne,
  urlDataImportTwo,
  urlDataImportThree,
  urlDataImportFour,
  urlDataImportFive,
  urlDataImportSix,
  urlDataImportSummary,
} from 'App'
import { getUserAuthorization } from 'app/facade'
import { Login, User } from 'app/type'

export function isInternal(): boolean {
  return getUserAuthorization() === true
}

export function getCurrentUser(users: User[], login: Login): User {
  // get the user from the login if we are logged as internal
  if (login && login.role === 'internal') {
    return {
      email: login.email,
      jmeno: login.jmeno!,
      prijmeni: login.prijmeni!,
      role: login.role,
    }
  }
  // this should not be here, it's just some safety.
  let currentUser =
    users[0] || ({ email: 'temp@temp.cz', jmeno: '', prijmeni: '' } as User)
  // get the user from the list of users, by comparing the email of login and of user
  if (login && login.email) {
    for (const user of users) {
      if (user.hasOwnProperty('email') && user.email === login.email) {
        currentUser = user
        break
      }
    }
  }
  return currentUser
}

export function comesFromOurApplication(documentReferrer: string): boolean {
  console.log('comesFromOurApplication', documentReferrer)
  return (
    documentReferrer !== '' &&
    (documentReferrer.includes(urlOnboardingSuccess) ||
      documentReferrer.includes(urlSelfcareTable) ||
      documentReferrer.includes(urlOrdersOverview) ||
      documentReferrer.includes(urlNastaveni) ||
      documentReferrer.includes(urlFaq) ||
      documentReferrer.includes(urlDataImportOne) ||
      documentReferrer.includes(urlDataImportTwo) ||
      documentReferrer.includes(urlDataImportThree) ||
      documentReferrer.includes(urlDataImportFour) ||
      documentReferrer.includes(urlDataImportFive) ||
      documentReferrer.includes(urlDataImportSix) ||
      documentReferrer.includes(urlDataImportSummary))
  )
}
