import FileIcon from '@styles/icon/fileIcon'
import GeoPinOutlineIcon from '@styles/icon/geoPinOutlineIcon'
import PlusIcon from '@styles/icon/plusIcon'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { numberWithSpacesX } from 'app/util'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { govActions, selectAddresses } from '../settings/governanceSlice'
import {
  reducerNewBornEmployeeDepartment,
  reducerNewBornEmployeeUliceCisloPopisne,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import {
  equalsBezRozdiluVCisluOrientacnim,
  formatPsc,
} from 'util/ValidFormatOps'
import { createOddeleniBackend } from 'app/req'
import { lg } from 'app/facade'
import {
  ADD,
  ADDRESS_FOR_CARDS_DELIVERY,
  ASSIGN_DEPARTMENT,
  DEPARTMENT,
  DEPARTMENT_NAME,
  NEW_DELIVERY_ADDRESS,
  REQUIRED_FIELD,
} from 'app/constDictionary'
import { Address } from 'app/type'
import EmployeeAddressesInline from './EmployeeAddressesInLine'
import { nanoid } from '@reduxjs/toolkit'

interface Props {
  hasValidated?: boolean
}

const EmployeeAddAddresses: FunctionComponent<Props> = ({
  hasValidated = false,
}) => {
  const dispatch = useAppDispatch()

  const [show, setShow] = useState('none')

  const addresses = useAppSelector(selectAddresses) as Address[]
  const selectedAddress = useAppSelector(
    (state) => state.userSettings.babyUliceCisloPopisne
  )
  const selectedDepartment = useAppSelector(
    (state) => state.userSettings.babyDepartment
  )
  const lang = useAppSelector(selectLanguage) || 'cz'



  useEffect(() => {
    function checkForMatchingAddress() {
      if (!addresses) return false
      return addresses?.some(
          address =>
              equalsBezRozdiluVCisluOrientacnim(
                  address?.uliceCisloPopisne,
                  selectedAddress
              )
      )
    }
    if (!selectedAddress || !selectedDepartment || !checkForMatchingAddress()) {
      dispatch(
          reducerNewBornEmployeeUliceCisloPopisne(
              addresses[0]?.uliceCisloPopisne
          )
      )
      dispatch(
          reducerNewBornEmployeeDepartment(addresses[0]?.oddeleni[0])
      )
    }
  },  [dispatch, selectedAddress, selectedDepartment, addresses])

  const [nazevOddeleni, setNazevOddeleni] = React.useState('')

  return (
    <div className='highlight u-mb-3 u-py-3 u-px-2'>
      <div className='highlight__content'>
        <div className='grid-x align-bottom u-mb-3'>
          <div className='cell auto'>
            <h4>{lg(lang, ADDRESS_FOR_CARDS_DELIVERY)}</h4>
          </div>
        </div>
        {hasValidated &&
          (!addresses || addresses.length === 0 || !selectedAddress) && (
            <span className='input__error u-mb-2'>
              {lg(lang, REQUIRED_FIELD)}
            </span>
          )}
        {addresses.map((address, i) => (
          <div key={nanoid(5)}>
            <label className='radio u-mb-3 radio--align-top'>
              <input
                type='radio'
                // In normal HTML, we would give each radio button a name attribute set to the same value, to tell the browser that they belong to a group of related radio buttons.
                // name='address-group' For a controlled input we must turn this off.
                checked={equalsBezRozdiluVCisluOrientacnim(
                  address.uliceCisloPopisne,
                  selectedAddress
                )}
                onChange={(e) => {
                  // https://stackoverflow.com/questions/49092857/react-16-radio-button-onchange-not-working
                  if (e.currentTarget.checked) {
                    // console.log(
                    //   'address.uliceCisloPopisne, address.oddeleni',
                    //   address.uliceCisloPopisne,
                    //   address.oddeleni
                    // )
                    dispatch(
                      reducerNewBornEmployeeUliceCisloPopisne(
                        address.uliceCisloPopisne
                      )
                    )
                    dispatch(
                      reducerNewBornEmployeeDepartment(address.oddeleni[0])
                    )
                  }
                }}
                value={address.uliceCisloPopisne}
              />
              <span className='radio__control'></span>
              <span className='radio__label'>
                <p className='u-size-base14'>{address.nazevSpolecnosti}</p>
                <p className='u-size-base14 u-mt-1'>{address.kontaktniOsoba}</p>
                <p className='u-size-base14 u-mt-1'>
                  {address.uliceCisloPopisne}, {address.mesto},{' '}
                  {formatPsc(address.psc)}
                </p>
                <p className='u-size-base14 u-mt-1 u-mb-3'>
                  tel.: +{address.predvolba}{' '}
                  {/* 21.11. Resolving Recon issues, where some addresses do not have a 'telefon' field
                   -> Martin Čepek: "telefon není podle ěm by neměl bejt biggie a dal bych mocK" */}
                  {numberWithSpacesX(address.telefon || 777777777)}
                </p>

                {equalsBezRozdiluVCisluOrientacnim(
                  address.uliceCisloPopisne,
                  selectedAddress
                ) && (
                  <div>
                    <h4 className='u-mb-2'>{lg(lang, DEPARTMENT)}</h4>
                    <div className='grid-x u-mb-2'>
                      {address.oddeleni.map((oddeleni, j) => (
                        <div key={nanoid()} className='cell u-mr-1 shrink'>
                          <label className='radio'>
                            <input
                              type='radio'
                              // name='department-group' // uncomment and have fun dying
                              checked={oddeleni === selectedDepartment}
                              onChange={(e) => {
                                // https://stackoverflow.com/questions/49092857/react-16-radio-button-onchange-not-working
                                if (e.currentTarget.checked) {
                                  dispatch(
                                    reducerNewBornEmployeeDepartment(oddeleni)
                                  )
                                }
                              }}
                              value={oddeleni}
                            />
                            <span className='radio__control'></span>
                            <span className='radio__label'>{oddeleni}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                    {!address.oddeleni.includes('') && (
                      <button
                        onClick={() => {
                          show === 'priradit_oddeleni#' + i
                            ? setShow('none')
                            : setShow('priradit_oddeleni#' + i)
                        }}
                        className='button-as-anchor u-size-s u-mb-3'
                      >
                        <svg className='icon icon--16'>
                          <FileIcon width={16} height={16} />
                        </svg>
                        <strong className='u-ml-1'>
                          {lg(lang, ASSIGN_DEPARTMENT)}
                        </strong>
                      </button>
                    )}
                  </div>
                )}
              </span>
            </label>
            {show === 'priradit_oddeleni#' + i && (
              <div>
                <div className='input u-mb-2 input--required'>
                  <label className='input__label'>
                    {lg(lang, DEPARTMENT_NAME)}
                  </label>
                  <div className='input__wrapper'>
                    <input
                      onChange={(e) => setNazevOddeleni(e.target.value)}
                      placeholder='Např.: Marketing'
                      type='text'
                      value={nazevOddeleni}
                    />
                  </div>
                </div>
                <a
                  onClick={() => {
                    if (nazevOddeleni && nazevOddeleni.length > 0) {
                      dispatch(
                        govActions.addOddeleni({
                          uliceCisloPopisne: address.uliceCisloPopisne,
                          oddeleni: nazevOddeleni,
                        })
                      )
                      createOddeleniBackend({
                        address: address,
                        oddeleni: nazevOddeleni,
                      })
                      dispatch(reducerNewBornEmployeeDepartment(nazevOddeleni))
                    }
                    setShow('none')
                    setNazevOddeleni('')
                  }}
                  className='btn u-pl-2 u-size-base14 btn--secondary btn--small u-mb-2'
                >
                  <svg className='icon icon--24'>
                    <PlusIcon width={24} height={24} />
                  </svg>
                  {lg(lang, ADD)}
                </a>
              </div>
            )}
          </div>
        ))}
        <a
          onClick={() => {
            show === 'pridat_adresu'
              ? setShow('none')
              : setShow('pridat_adresu')
          }}
          className='btn u-pl-2 u-mb-4 u-size-base14 btn--primary btn--outlined btn--small'
        >
          <svg className='icon icon--24'>
            <GeoPinOutlineIcon width={24} height={24} />
          </svg>
          {lg(lang, NEW_DELIVERY_ADDRESS)}
        </a>
        {show === 'pridat_adresu' && (
          <EmployeeAddressesInline
            setShow={setShow}
            afterCreateAdresaBackend={(
              uliceCisloPopisneNaNoveAdrese,
              oddeleniNaNoveAdrese
            ) => {
              // set new address to the employee on backend? - no need this is a new employee adding and he will be sent to backend only when order is sent and mainly, the employee has not been yet even confirmed by button "Přidat zaměstnance" and thus does not exist even on frontend. Only as a fiction for remembering the input, called "newborn", I guess.
              dispatch(
                reducerNewBornEmployeeUliceCisloPopisne(
                  uliceCisloPopisneNaNoveAdrese
                )
              )
              dispatch(
                reducerNewBornEmployeeDepartment(
                  oddeleniNaNoveAdrese || 'Žádné'
                )
              )
            }}
          />
        )}
      </div>
    </div>
  )
}

export default EmployeeAddAddresses
