import React, { useState } from 'react'

import icons from '@styles/dist/system/icons.svg'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { reducerCloseTip, selectClosedTips } from './userSettingsSlice'

interface Props {
  id: string
  content: string
  scrollId?: string
}

const Tip: React.FC<Props> = ({ id, content, scrollId }) => {
  const dispatch = useAppDispatch()
  const closedTips: string[] = useAppSelector(selectClosedTips)
  const [hoverTip, setHoverTip] = useState(false)

  return (
    <div className='tip u-mb-3' id={scrollId}>
      {(!closedTips || !closedTips.includes(id)) && (
        <div
          className='tip__icon'
          style={{ cursor: 'pointer' }}
          onMouseEnter={() => setHoverTip(true)}
          onMouseLeave={() => setHoverTip(false)}
          onClick={() => {
            dispatch(reducerCloseTip(id))
          }}
        >
          <svg className='icon'>
            <use
              href={
                icons +
                (hoverTip
                  ? '#sprite-close-circle-filled'
                  : '#sprite-info-circle-fill')
              }
            ></use>
          </svg>{' '}
          <p className='u-size-l20'>Tip</p>
        </div>
      )}{' '}
      {(!closedTips || !closedTips.includes(id)) && (
        <div className='tip__text'>
          <p>{content}</p>
        </div>
      )}
    </div>
  )
}

export default Tip
