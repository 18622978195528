import React from 'react'
import {
  urlOfferAndLeadExit,
  urlOnboarding1,
  urlOnboarding2,
  urlOnboarding3,
} from 'App'
import { useAppDispatch } from 'app/hooks'
import { Link } from 'react-router-dom'
import { reducerCurrentLocation } from './features/stepperSlice'
import { getPocetZamestnancu } from 'app/facade'
import { callInstinkt } from 'util/3rdPartyOps'

interface ConfirmSectionProps {
  submitIcon?: JSX.Element
  submitText: string
  nextRoute?: string
  nextLocationNumber?: number
  onSubmit?: () => void
  alignClassName?: string
  disabled?: boolean
  secondary?: boolean
  formNotValid?: boolean
  customClassName?: string
}

const ConfirmSection: React.FC<ConfirmSectionProps> = ({
  submitIcon,
  submitText,
  nextRoute,
  nextLocationNumber,
  onSubmit,
  alignClassName,
  disabled,
  secondary,
  formNotValid, customClassName,
}) => {
  const dispatch = useAppDispatch()

  return (
    <div className={alignClassName ? alignClassName : 'u-text-center'}>
      {/* Why is this ternary operator here? nextRoute is mandatory, so it seems the second branch never gets executed... */}
      {/* The ternary branch of Link vs Button gets executed. It lives there when the fields are not entered yet. How this is achieved, I don't know from this code. Must be from enclosing component by controlling when to pass nextRoute and when not to. */}
      {!disabled ? (
        formNotValid ? (
          <button
            className={`btn not-allowed ${
              secondary ? 'btn--secondary' : 'btn--primary'
            } ${customClassName}`}
            onClick={() => {
              onSubmit && onSubmit()
            }}
          >
            {submitIcon && <svg className='icon icon--24'>{submitIcon}</svg>}
            {submitText}
          </button>
        ) : nextRoute ? (
          <Link
            to={nextRoute}
            className='btn btn--primary'
            onClick={() => {
              onSubmit && onSubmit()
              nextLocationNumber &&
                dispatch(reducerCurrentLocation(nextLocationNumber))
              callInstinctAgency(nextRoute)
            }}
          >
            {submitIcon && <svg className='icon icon--24'>{submitIcon}</svg>}
            {submitText}
          </Link>
        ) : (
          <button
            className={`btn ${secondary ? 'btn--secondary' : 'btn--primary'} ${customClassName}`}
            onClick={() => {
              onSubmit && onSubmit()
              nextLocationNumber &&
                dispatch(reducerCurrentLocation(nextLocationNumber))
              // nextRoute && callInstinctAgency(nextRoute)
            }}
          >
            {/* {console.log('onSubmit, second branch of ternary operator')} */}
            {submitIcon && <svg className='icon icon--24'>{submitIcon}</svg>}
            {submitText}
          </button>
        )
      ) : (
        <button
          className={`btn btn--disabled not-allowed ${
            secondary ? 'btn--secondary' : 'btn--primary'
          } ${customClassName}`}
        >
          {submitIcon && <svg className='icon icon--24'>{submitIcon}</svg>}
          {submitText}
        </button>
      )}
    </div>
  )
}

export default ConfirmSection

function callInstinctAgency(nextRoute: string) {
  console.log('callInstinctAgency', nextRoute)
  if (nextRoute === urlOnboarding1) {
    console.log('callInstinctAgency, nextRoute === urlOnboarding1')
    // @ts-ignore
    dataLayer.push({
      event: 'formSubmission',
      lead_type: 'full',
      step: '1',
      pocet_zamestnancu: getPocetZamestnancu(),
    })
    return
  }
  if (nextRoute === urlOfferAndLeadExit) {
    console.log('callInstinctAgency, nextRoute === urlOfferAndLeadExit')
    // @ts-ignore
    dataLayer.push({
      event: 'formSubmission',
      lead_type: 'lite',
      step: '1',
      pocet_zamestnancu: getPocetZamestnancu(),
    })
    return
  }
  if (nextRoute === urlOnboarding2) {
    callInstinkt(2)
  }
  if (nextRoute === urlOnboarding3) {
    callInstinkt(3)
  }
}
