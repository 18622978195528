import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class DashboardIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'dashboard-icon',
    width: 24,
    height: 24,
    fill: 'currentColor',
  }

  render() {
    const { className, width, height, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5 12V6h6v6H5zM3 5a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 01-1 1H4a1 1 0 01-1-1V5zm14 7V6h10v6H17zm-2-7a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H16a1 1 0 01-1-1V5zm6 14v6h6v-6h-6zm-1-2a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1v-8a1 1 0 00-1-1h-8zM5 25v-6h10v6H5zm-2-7a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1H4a1 1 0 01-1-1v-8z'
          fill={fill}
        ></path>
      </svg>
    )
  }
}

export default DashboardIcon
