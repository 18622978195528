import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MAX_POCET_OPRAVNENYCH_OSOB } from 'app/tuning'
import { RootState } from 'app/store'
import { Cenik } from 'app/type'

export const CENIK_CZK_DEFAULT: Cenik = {
  volnocas: 0.03,
  stravenky: 0.02,
  spravaJednohoUctu: 5,
  vydaniPrvniKarty: 80,
  vydaniNahradniKarty: 20,
  doruceni: 100,
  minStravenkyFee: 0,
  minVolnocasFee: 0,
}

export interface FiremniUdajeState {
  dicFirmy?: string
  icoFirmy?: string
  cenik: Cenik // právě aktivní ceník
  nazevSpolecnosti?: string
  uliceCisloPopisne?: string
  mesto?: string
  psc?: string
  opravneneOsoby: string[]
  cisloKontraktu?: string
  companyCode?: string
  companyId?: string // serves only for joining a company to another existing company during the onboarding process
  previousState: FiremniUdajePreviousState
  allowSodexoConnect: boolean
}

export interface FiremniUdajePreviousState {
  previousIcoFirmy: string
  previousNazevSpolecnosti: string
  previousUliceCisloPopisne: string
  previousMesto: string
  previousPsc: string
  previousOpravneneOsoby: string[]
}

let initialState: FiremniUdajeState = {
  opravneneOsoby: [''],
  previousState: {
    previousIcoFirmy: '',
    previousNazevSpolecnosti: '',
    previousUliceCisloPopisne: '',
    previousMesto: '',
    previousPsc: '',
    previousOpravneneOsoby: [''],
  },
  cenik: CENIK_CZK_DEFAULT,
  allowSodexoConnect: false,
}

export const firemniUdajeSlice = createSlice({
  name: 'firemniUdaje',
  initialState,
  reducers: {
    reducerDicFirmy: (state, action: PayloadAction<string>) => {
      state.dicFirmy = action.payload
    },
    reducerIcFirmy: (state, action: PayloadAction<string>) => {
      state.icoFirmy = action.payload
    },
    reducerCenik: (state, action: PayloadAction<Cenik>) => {
      state.cenik = action.payload
    },
    reducerDefaultCenik: (state) => {
      state.cenik = CENIK_CZK_DEFAULT
    },
    reducerNazevSpolecnosti: (state, action: PayloadAction<string>) => {
      state.nazevSpolecnosti = action.payload
    },
    reducerUliceCisloPopisne: (state, action: PayloadAction<string>) => {
      state.uliceCisloPopisne = action.payload
    },
    reducerMesto: (state, action: PayloadAction<string>) => {
      state.mesto = action.payload
    },
    reducerPsc: (state, action: PayloadAction<string>) => {
      state.psc = action.payload
    },
    reducerOpravneneOsoby: (state, action: PayloadAction<string[]>) => {
      state.opravneneOsoby = action.payload
    },
    reducerOpravnenaOsoba: (
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) => {
      const { index, value } = action.payload
      // console.log('reducerOpravnenaOsoba', index, value)
      // const prevOsoba: string | undefined = state.opravneneOsoby[index]
      // console.log('prevOsoba', prevOsoba)
      state.opravneneOsoby[index] = value
      // console.log('state.opravneneOsoby[index]', state.opravneneOsoby[index])
    },
    reducerCisloKontraktu: (state, action: PayloadAction<string>) => {
      state.cisloKontraktu = action.payload
    },
    reducerCompanyCode: (state, action: PayloadAction<string>) => {
      state.companyCode = action.payload
    },
    reducerAdUserCode: (state, action: PayloadAction<string>) => {
      state.companyCode = action.payload
    },
    reducerCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload
    },
    reducerPridatPrazdnouOpravnenouOsobu: (state) => {
      if (state.opravneneOsoby.length < MAX_POCET_OPRAVNENYCH_OSOB) {
        state.opravneneOsoby.push('')
      }
    },
    reducerOdebratPosledniOpravnenouOsobu: (state) => {
      if (state.opravneneOsoby.length > 1)
        state.opravneneOsoby = state.opravneneOsoby.slice(
          0,
          state.opravneneOsoby.length - 1
        )
    },
    reducerRemoveEmptyOpravneneOsoby: (state) => {
      console.log('remove empty opravnene osoby - before', state.opravneneOsoby)
      state.opravneneOsoby = state.opravneneOsoby.filter((o) => o !== '')
      console.log('remove empty opravnene osoby - after', state.opravneneOsoby)
    },
    reducerRemoveAllOpravneneOsoby: (state) => {
      state.opravneneOsoby = ['']
    },
    reducerCopyActualStateToThePreviousState: (state) => {
      console.log(
        'save actual state to previous state',
        state.uliceCisloPopisne
        // state.previousState.previousUliceCisloPopisne || ''
      )
      state.previousState = {
        previousIcoFirmy: state.icoFirmy || '',
        previousNazevSpolecnosti: state.nazevSpolecnosti || '',
        previousUliceCisloPopisne: state.uliceCisloPopisne || '',
        previousMesto: state.mesto || '',
        previousPsc: state.psc || '',
        previousOpravneneOsoby: state.opravneneOsoby || [],
      }
    },
    reducerRestorePreviousFiremniUdaje: (state) => {
      console.log(
        'restoring the previous state, state.uliceCisloPopisne, previousState.uliceCisloPopisne',
        state.uliceCisloPopisne,
        state.previousState.previousUliceCisloPopisne || ''
      )
      state.icoFirmy = state.previousState.previousIcoFirmy
      state.nazevSpolecnosti = state.previousState.previousNazevSpolecnosti
      state.uliceCisloPopisne = state.previousState.previousUliceCisloPopisne
      state.mesto = state.previousState.previousMesto
      state.psc = state.previousState.previousPsc
      state.opravneneOsoby = state.previousState.previousOpravneneOsoby
    },
    reducerAllowSodexoConnect: (state, action: PayloadAction<boolean>) => {
      state.allowSodexoConnect = action.payload
    },
  },
})

export const {
  reducerDicFirmy,
  reducerIcFirmy,
  reducerCenik,
  reducerDefaultCenik,
  reducerNazevSpolecnosti,
  reducerUliceCisloPopisne,
  reducerMesto,
  reducerPsc,
  reducerOpravnenaOsoba,
  reducerOpravneneOsoby,
  reducerCisloKontraktu,
  reducerCompanyCode,
  reducerPridatPrazdnouOpravnenouOsobu,
  reducerOdebratPosledniOpravnenouOsobu,
  reducerRemoveEmptyOpravneneOsoby,
  reducerRemoveAllOpravneneOsoby,
  reducerCopyActualStateToThePreviousState,
  reducerRestorePreviousFiremniUdaje,
  reducerCompanyId,
  reducerAdUserCode,
  reducerAllowSodexoConnect,
} = firemniUdajeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIcoFirmy = (state: RootState) => state.firemniUdaje.icoFirmy

export const selectDicFirmy = (state: RootState) => state.firemniUdaje.dicFirmy

export const selectCenik = (state: RootState) => {
  return state.firemniUdaje.cenik ? state.firemniUdaje.cenik : CENIK_CZK_DEFAULT
}

export const selectNazevSpolecnosti = (state: RootState) =>
  state.firemniUdaje.nazevSpolecnosti
export const selectUliceCisloPopisne = (state: RootState) =>
  state.firemniUdaje.uliceCisloPopisne
export const selectMesto = (state: RootState) => state.firemniUdaje.mesto
export const selectPsc = (state: RootState) => state.firemniUdaje.psc
export const selectOpravneneOsoby = (state: RootState) =>
  state.firemniUdaje.opravneneOsoby

export const selectPreviousState = (state: RootState) => {
  return {
    previousIcoFirmy: state.firemniUdaje.previousState.previousIcoFirmy,
    previousNazevSpolecnosti:
      state.firemniUdaje.previousState.previousNazevSpolecnosti,
    previousUliceCisloPopisne:
      state.firemniUdaje.previousState.previousUliceCisloPopisne,
    previousMesto: state.firemniUdaje.previousState.previousMesto,
    previousPsc: state.firemniUdaje.previousState.previousPsc,
    previousOpravneneOsoby:
      state.firemniUdaje.previousState.previousOpravneneOsoby,
  }
}

export const selectActualState = (state: RootState) => {
  return {
    icoFirmy: state.firemniUdaje.icoFirmy,
    nazevSpolecnosti: state.firemniUdaje.nazevSpolecnosti,
    uliceCisloPopisne: state.firemniUdaje.uliceCisloPopisne,
    mesto: state.firemniUdaje.mesto,
    psc: state.firemniUdaje.psc,
    opravneneOsoby: state.firemniUdaje.opravneneOsoby,
  }
}

export const selectCisloKontraktu = (state: RootState) =>
  state.firemniUdaje.cisloKontraktu
export const selectCompanyCode = (state: RootState) =>
  state.firemniUdaje.companyCode
export const selectCompanyId = (state: RootState) =>
  state.firemniUdaje.companyId

export const selectAllowSodexoConnect = (state: RootState) =>
  state.firemniUdaje.allowSodexoConnect

export default firemniUdajeSlice.reducer
