import React, { useState } from 'react'

const Soap: React.FC = () => {
  const [soapResponse, setSoapResponse] = useState('')
  function soap() {
    var xmlhttp = new XMLHttpRequest()
    xmlhttp.open(
      'GET',
      'http://wwwinfo.mfcr.cz/cgi-bin/ares/darv_bas.cgi',
      true
      // bas / std
      // ?ico=27074358
      // true
    )

    // build SOAP request
    var sr =
      '<?xml version="1.0" encoding="utf-8"?>' +
      '<soapenv:Envelope ' +
      'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
      'xmlns:api="http://127.0.0.1/Integrics/Enswitch/API" ' +
      'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
      'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">' +
      '<soapenv:Body>' +
      '<api:some_api_call soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
      '<ico xsi:type="xsd:string">26202891</ico>' +
      '</api:some_api_call>' +
      '</soapenv:Body>' +
      '</soapenv:Envelope>'

    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4) {
        if (xmlhttp.status == 200) {
          alert(xmlhttp.responseText)
          setSoapResponse(xmlhttp.responseText)
          // alert('done. use firebug/console to see network response');
        }
      }
    }
    // Send the POST request
    xmlhttp.setRequestHeader('Content-Type', 'text/xml')
    xmlhttp.send(sr)
    // send request
    // ...
  }
  return (
    <div>
      <h1>Soap</h1>
      <button onClick={soap}>Soap</button>
      <div>{soapResponse}</div>
    </div>
  )
}

export default Soap
