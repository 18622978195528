import { FunctionComponent, useEffect, useState } from 'react'
import { Form, Field } from 'formik'
import icons from '@styles/dist/system/icons.svg'
import { validateNumericKeyWithWhites } from 'app/util'
import {
  validateNazevSpolecnosti,
  validateKontaktniOsoba,
  validateUliceCisloPopisne,
  validateMesto,
  validatePsc,
  validateTelefon,
} from 'util/ValidFormatOps'
import { lg } from 'app/facade'
import { FIRM_NAME } from 'app/constDictionary'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface Props {
  errors: any
  touched: any
  values: any
}

const SettingsAddressChangeForm: FunctionComponent<Props> = ({
  errors,
  touched,
  values,
}) => {
  const [countryCodeSelectionShown, setCountryCodeSelectionShown] =
    useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <Form id='settings-address-change-form'>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>{lg(lang, FIRM_NAME)}</label>
        <Field
          validate={validateNazevSpolecnosti}
          type='text'
          placeholder='Např. Společnost, s.r.o.'
          name='nazevSpolecnosti'
        />
        {errors.nazevSpolecnosti && touched.nazevSpolecnosti && (
          <span className='input__error'>{errors.nazevSpolecnosti}</span>
        )}
      </div>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>Kontaktní osoba</label>
        <Field
          validate={validateKontaktniOsoba}
          type='text'
          placeholder='Např. Karel Havlíček'
          name='kontaktniOsoba'
        />
        {errors.kontaktniOsoba && touched.kontaktniOsoba && (
          <span className='input__error'>{errors.kontaktniOsoba}</span>
        )}
      </div>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>Ulice, číslo popisné</label>
        <Field
          validate={validateUliceCisloPopisne}
          type='text'
          placeholder='Např. Hlavní náměstí 1'
          name='uliceCisloPopisne'
        />
        {errors.uliceCisloPopisne && touched.uliceCisloPopisne && (
          <span className='input__error'>{errors.uliceCisloPopisne}</span>
        )}
      </div>
      <div className='grid-x u-mb-4'>
        <div className='cell small-7 u-pr-small-2'>
          <div className='input input--required'>
            <label className='input__label'>Město</label>
            <Field
              validate={validateMesto}
              type='text'
              placeholder='Např. Praha 1'
              name='mesto'
            />
            {errors.mesto && touched.mesto && (
              <span className='input__error'>{errors.mesto}</span>
            )}
          </div>
        </div>
        <div className='cell small-5'>
          <div className='input input--required'>
            <label className='input__label'>PSČ</label>
            <Field
              validate={validatePsc}
              type='text'
              placeholder='Např. 110 00'
              name='psc'
            />
            {errors.psc && touched.psc && (
              <span className='input__error'>{errors.psc}</span>
            )}
          </div>
        </div>
      </div>
      <div className='grid-x'>
        <div className='cell medium-5 u-pr-medium-2'>
          <div
            onClick={() =>
              setCountryCodeSelectionShown(!countryCodeSelectionShown)
            }
            id='country-code'
            className={
              'cphone-code cphone-code--required' +
              ' ' +
              (countryCodeSelectionShown ? 'cphone-code--active' : '')
            }
          >
            <label htmlFor='country-code' className='country-code__label'>
              Země
            </label>{' '}
            <div className='cphone-code__option cphone-code__option--selected'>
              <svg className='icon icon--24'>
                <use
                  href={
                    icons +
                    (values.predvolba === 420
                      ? '#sprite-flag-cz'
                      : '#sprite-flag-sk')
                  }
                />
              </svg>{' '}
              <span>
                <svg className='icon icon--16 u-mx-1'>
                  <use
                    href={
                      icons +
                      (countryCodeSelectionShown
                        ? '#sprite-arrow-thin-up'
                        : '#sprite-arrow-thin-down')
                    }
                  />
                </svg>
              </span>{' '}
              <span>+{values.predvolba}</span>
            </div>{' '}
            {countryCodeSelectionShown && (
              <div
                onClick={() => {
                  setCountryCodeSelectionShown(false)
                  values.predvolba = values.predvolba === 420 ? 421 : 420
                }}
                className='cphone-code__options'
              >
                <div className='cphone-code__option'>
                  <svg className='icon icon--24 u-mr-1'>
                    <use
                      href={
                        icons +
                        (values.predvolba === 420
                          ? '#sprite-flag-sk'
                          : '#sprite-flag-cz')
                      }
                    />
                  </svg>{' '}
                  <span>+{values.predvolba === 420 ? 421 : 420}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='cell medium-7'>
          <div className='input input--required'>
            <label className='input__label'>Telefon</label>
            <Field
              validate={validateTelefon}
              placeholder='Např.: 723 723 723'
              type='tel'
              name='telefon'
              onKeyPress={validateNumericKeyWithWhites}
            />
          </div>
        </div>
      </div>
      <div
        className='u-mb-4 u-mt-1'
        style={{
          height: '36px',
        }} /* because help message is 2x taller than error  */
      >
        <div className='grid-x'>
          <div className='cell medium-5 u-pr-medium-2'></div>
          <div className='cell medium-7'>
            {errors.telefon && touched.telefon ? (
              <span className='input__error'>{errors.telefon}</span>
            ) : (
              <span className='input__help' style={{ marginLeft: '-16px' }}>
                Na telefonním čísle budete informováni o doručení smlouvy a
                karet pro zaměstnance
              </span>
            )}
          </div>
        </div>
      </div>
    </Form>
  )
}

export default SettingsAddressChangeForm
