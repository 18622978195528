import { equalsNormalize } from 'util/ValidFormatOps'

// #############################################################################
// ###################### COMPONENT ############################################
// #############################################################################
export interface IconProps {
  width?: number
  height?: number
  className?: string
  iconColor?: string
  defaultRegion?: string
  fill?: string
  onClick?: () => void
  style?: React.CSSProperties
  direction?: 'up' | 'right' | 'down' | 'left'
}

export interface TSMenu {
  code: string
  items?: TSMenu[]
  name?: string // only end leaves have this
  url?: string // only end leaves have this
}

// #############################################################################
// ####################### FRONTEND #############################################
// #############################################################################
export type FakturacniUdaje = {
  nazevSpolecnosti: string
  uliceCisloPopisne: string
  mesto: string
  psc: number
  opravneneOsoby: string[]
}

export type Order = {
  // part 1 - related to Overview Row
  orderId: number
  owner: string
  date: Date
  price: number
  note: string
  contactEmail: string
  orderState: OrderState
  deliveryState: ConsignmentState
  // part 2 - related to Order Detail
  zasilky: Zasilka[]
  vydaniPrvniKartyPrice: number
  vydaniNahradniKartyPrice: number
  leisureCredits: number
  gastroCredits: number
  spravaUctu: number
  feeLeisure: number
  feeGastro: number
  uskutecnenyPoplatekGastro: number
  uskutecnenyPoplatekLeisure: number
  uskutecnenyPoplatekDoruceni: number
  uskutecnenyPoplatekSpravaUctu: number
}

// Manage zasilka as a static final constant for when promotional or individual prices become active, it must NOT affect previous shipments
export type Zasilka = {
  number: number // just an inner numbering #1, #2, #3
  dorucovaciAdresa: Address
  pocetNovychKaret: number
  pocetNahradnichKaret: number
  stavZasilky: ConsignmentState
}

export enum OrderState {
  Přijatá = 'Přijatá',
  ČekáNaZaplacení = 'Čeká na zaplacení',
  Zaplacená = 'Zaplacená', // původně Odeslaná, na SPO to je 'SENT'
  Doručená = 'Doručená',
  Stornovaná = 'Stornovaná',
  Blokovaná = 'Blokovaná',
  Dobropis = 'Dobropis',
  Vrubopis = 'Vrubopis',
  RozpracovanýDobropis = 'Rozpracovaný Dobropis',
  Neznámý = 'Neznámý',
}

// Přijatá -> Čeká na zpracování
// Čeká na zaplacení -> Čeká na zpracování
// Zaplacená -> Ve výrobě
// (po 3 dnech): Zaplacená -> Čeká na převzetí
// (všechny zásilky převzaty): Zaplacená -> Převzatá
// Doručená -> Převzatá
// Stornovaná -> Čeká na převzetí / Převzatá
// Blokovaná -> Čeká na zpracování
// Dobropis -> Čeká na převzetí / Převzatá
// Vrubopis -> Čeká na převzetí / Převzatá
export enum ConsignmentState {
  ČekáNaZpracování = 'Čeká na zpracování',
  VeVýrobě = 'Ve výrobě',
  ČekáNaPřevzetí = 'Čeká na převzetí', // po 3 dnech
  Převzatá = 'Převzatá',
  ŽádnéKarty = 'Žádné karty',
  Neznámý = 'Neznámý',
}

export type Address = {
  uuid?: string
  nazevSpolecnosti: string
  kontaktniOsoba: string
  uliceCisloPopisne: string
  mesto: string
  psc: number
  predvolba: number
  telefon: number
  oddeleni: string[]
}

export type BrokenAddress = {
  uuid: string
  street: string
  nazevSpolecnosti: string
  kontaktniOsoba: string
  cisloPopisne: string
  mesto: string
  psc: string
  predvolba: number
  telefon: number
  oddeleni: string[]
}

export type User = {
  email: string
  jmeno: string
  prijmeni: string
  role: string
  predvolba?: number // let it optional for backward compatibility
  telefon?: number | undefined // let it optional for backward compatibility + can be undefined
}

export type Login = {
  email: string
  jmeno?: string // currently only for internal users
  prijmeni?: string // currently only for internal users
  role?: Role // currently only for internal users
}

export enum Role {
  Internal = 'internal',
  User = 'user',
  Admin = 'admin',
}

// #############################################################################
// ####################### UNIVERSAL ###########################################
// #############################################################################
export type InternalCompany = {
  ico: string
  nazev: string
  cisloSmlouvy: string
}

export type ExcelFile = {
  uuid: string
  date: Date
  // name: string
  // do not store data in redux, it is too big -> memory leak
  // data: string
}

export type Cenik = {
  volnocas: number
  stravenky: number
  spravaJednohoUctu: number
  vydaniPrvniKarty: number
  vydaniNahradniKarty: number
  doruceni: number
  minStravenkyFee: number
  minVolnocasFee: number
}

export enum Role {
  Vytvořená = 'Vytvořená',
  Aktivní = 'Aktivní',
  Hlavní = 'Hlavní',
}

export type Spolecnost = {
  ico: string
  nazev: string
  aktivni: boolean
  pocatekCasu: string // (DD.MM.YYYY)
  cerstveZmigrovana: boolean
  kontrakt: string // "C4900000074"
}

export function equalsBackend(a: string, b: string) {
  // return a === b || a === b.toLowerCase() || a === b.toUpperCase() || a === b.toUpperCase().replace(/\s/g, '')
  return equalsNormalize(a, b)
}
// #############################################################################
// ####################### BACKEND #############################################
// #############################################################################
export type AdresaBackendKlic = {
  ulice: string
  cisloPopisne: string
  psc: string
  oddeleni: string // is optional on backend, but must be present on frontend
}

export type ZamestnanecBackendEditace = {
  id: number
  jmeno: string
  prijmeni: string
  adresa: AdresaBackendKlic
  zaplaceneMesice: number
  deaktivovaneMesice: number
}

export type FakturacniUdajeBackend = {
  nazevSpolecnosti: string
  ulice: string
  cisloPopisne: string
  mesto: string
  psc: string
  opravneneOsoby: string[]
}

export type CenaKsDphTotal = {
  cena: number
  ks: number
  dph: number
  total: number
}

export type NovaObjednavkaBackend = {
  noviZamestnanci: {
    jmeno: string
    prijmeni: string
    cislo: number
    adresa: {
      psc: string
      ulice: string
      cisloPopisne: string
      oddeleni: string
    }
    zaplaceneMesice: number
    deaktivovaneMesice: number
  }[]
  zamestnanciBezObjednavky: {
    cislo: number
    zaplaceneMesice: number
    deaktivovaneMesice: number
  }[]
  objednavka: {
    polozky: {
      volnocasovyKredit: CenaKsDphTotal
      stravenkovyKredit: CenaKsDphTotal
      poplatekVolnocasovyKredit: CenaKsDphTotal
      poplatekStravenkovyKredit: CenaKsDphTotal
      vydaniPrvniMPC: CenaKsDphTotal
      vydaniNahradniMPC: CenaKsDphTotal
      doruceniMPC: CenaKsDphTotal
      spravaJednohoUctu: CenaKsDphTotal
      // nastaveniUctuNovehoUzivatele: CenaKsTotal
      // personalizaceMPC: CenaKsTotal
    }
    zamestnanci: {
      cislo: number
      volnocasovyKredit: number | null
      stravenkovyKredit: number | null
      karta: boolean
      zaplaceneMesice: number
      deaktivovaneMesice: number
    }[]
    cena: number
    kontaktniEmail: string
    poznamka: string
  }
}

export type ObjednavkaBackend = {
  cislo: string
  datum: string
  zadavatel: string
  email: string
  cena: {
    spolu: number
  }
  poznamka: string | null
  status: string
  zasilky: ZasilkaBackend[]
  stravenkovyKredit: number | null
  volnocasovyKredit: number | null
  poplatekStravenkovyKredit: number | null
  poplatekVolnocasovyKredit: number | null
  doruceniMPC: number | null
  vydaniPrvniMPC: number | null
  vydaniNahradniMPC: number | null
  spravaJednohoUctu: number | null
}

export type ZasilkaBackend = {
  cislo: number
  adresa: AdresaBackendUUID
  dorucena: boolean
  pocetPrvnichKaret: number
  pocetNahradnichKaret: number
}

export interface AdresaBackendUUID extends AdresaBackend {
  uuid: string
}

export type AdresaBackend = {
  nazevSpolecnosti: string
  ulice: string
  cisloPopisne: string
  mesto: string
  psc: string
  kontaktniOsoba: string
  telefon: string
  oddeleni?: string[]
}

export type KontaktniOsobaBackend = {
  jmeno: string
  prijmeni: string
  email: string
  status: string
  predvolba: string
  telefon: string
}

export type KontaktniOsobaBackendRequest = {
  email: string
  name: string
  surname: string
  countryCode?: number | undefined
  phoneNumber?: number | undefined
}

export type ZamestnanecBackend = {
  cislo: number
  jmeno: string
  prijmeni: string
  adresa: AdresaBackend
  oddeleni: string
  aktivni: boolean
  skupina: string | null
  objednavky: ZamestnancovaHistorickaObjednavkaBackend[]
  karty: KartaBackend[]
  zaplaceneMesice: number
  deaktivovaneMesice: number
  datumDeaktivace: string | null
}

export type ZamestnancovaHistorickaObjednavkaBackend = {
  cisloObjednavky: string
  datumObjednavky: string
  cisloKaretniZasilky: number | null
  stravenkovyKredit: number | null
  volnocasovyKredit: number | null
}

export type KartaBackend = {
  cisloKarty: string | null // null if not assigned on SPO yet
  typ: string
  cisloObjednavky: string
  cisloZasilky: number
  status:
    | 4
    | 5
    | 7
    | 1
    | 3
    | 8
    | 2
    | 6
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 99
    | -2
  datumObjednavky: string
  datumExpirace: string | null // null if not assigned on SPO yet
}
