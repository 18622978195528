import {
  monthsElapsedBeginningInclusive,
  ONE,
} from 'components/selfcare/selfcareHooks'

export function bitMaskOfTheCurrentMonth(beginningOfTime: Date): number {
  return ONE >> (monthsElapsedBeginningInclusive(beginningOfTime) - 1)
}

export function padWithZerosFromTheLeftTo32Bits(number: number): string {
  if (number == 0) {
    return '0'.repeat(32)
  } else {
    return number.toString(2).padStart(32, '0')
  }
}
