import React, { FunctionComponent, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import Logo from '@styles/image/data-import-3-logo.svg'
import SideMenu from 'components/modules/menu/SideMenu'
import { urlDataImportFour, urlDataImportTwo } from 'App'
import { useAppDispatch, useAppSelector, useBodyClass } from 'app/hooks'
import { Navigate } from 'react-router-dom'
import { ExcelRow } from './dataImportSlice'
import {
  activeImportRow,
  activeImportColumn,
  uploadedFileName,
  reducerActiveImportColumn,
} from './importSettingsSlice'

const DataImportThree: FunctionComponent = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()
  const redux = useAppSelector((state) => state.dataImport)
  const activeRow = useAppSelector(activeImportRow)
  const activeColumn = useAppSelector(activeImportColumn)
  const fileName = useAppSelector(uploadedFileName)

  const [selectedColumn, setSelectedColumn] = useState(activeColumn)

  const [mouseOver, setMouseOver] = React.useState(-1)
  function mouseLeave(j: number) {
    if (mouseOver === j) {
      setMouseOver(-1)
    }
  }
  const [redirect, setRedirect] = React.useState(false)
  function onColumnClick(j: number) {
    dispatch(reducerActiveImportColumn(j))
    setRedirect(true)
    setSelectedColumn(j)
  }

  return (
    <div>
      {redirect && <Navigate to={urlDataImportFour} />}
      <div className='table__import'>
        <div className='grid-x u-mb-4'>
          <div>
            <img src={Logo} width='auto' className='u-mr-3' />
          </div>
          <div>
            <h1 className='u-size-l28 u-mb-2'>Výběr sloupce</h1>
            <p className='u-size-l20'>
              Označte první sloupec, na kterém začínají údaje o zaměstnancích.
            </p>
          </div>
        </div>
        <div className='table__overflow'>
          <div className='box box--small-radius box--no-padding box--transparent u-mb-9'>
            <div className='box__content'>
              <div
                role='table'
                aria-label='Řádek s názvy sloupců'
                className='table table--compact'
              >
                <div role='row' className='table__row'>
                  <div className='table__cell table__cell--small u-bg-white'>
                    &nbsp;
                  </div>
                  {
                    // @ts-ignore
                    redux[0].map((_, i) => (
                      <div
                        key={i}
                        role='cell'
                        className='table__cell table__cell--fixed u-text-ellipsis u-text-center'
                      >
                        {/* write letter A + i */}
                        {String.fromCharCode(65 + i)}
                      </div>
                    ))
                  }
                </div>
                {redux.map((row: ExcelRow, i) => (
                  <div
                    key={i}
                    role='row'
                    className='table__row table__row--clickable'
                  >
                    <div
                      className={
                        'table__cell table__cell--small u-bg-white ' +
                        (i <= activeRow ? 'u-faded-50' : 'u-bold')
                      }
                    >
                      <span>{i + 1}</span>
                    </div>
                    {/* @ts-ignore */}
                    {row.map((cell, j) => (
                      <div
                        key={j}
                        onMouseOver={() => setMouseOver(j)}
                        onMouseLeave={() => mouseLeave(j)}
                        onClick={() => onColumnClick(j)}
                        className={
                          'table__cell table__cell--fixed u-text-ellipsis ' +
                          (i <= activeRow
                            ? 'u-faded-50'
                            : selectedColumn === j
                            ? 'u-bg-aquamarine-50 u-bold'
                            : mouseOver === j
                            ? 'u-bg-aquamarine-20'
                            : '')
                        }
                      >
                        <span>{cell}</span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='u-fixed-bottom u-mb-4 u-px-3'>
        <div className='grid-x align-justify u-ml-5 u-mr-3'>
          <div className='grid-x align-middle'>
            <svg className='icon u-mr-2'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>
            <i>{fileName}</i>
          </div>
          <div className='grid-x align-middle'>
            <a href={urlDataImportTwo} className='u-mr-3'>
              Zpět
            </a>
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}

export default DataImportThree
