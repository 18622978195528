import { FunctionComponent, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import SideMenu from 'components/modules/menu/SideMenu'
import { urlDataImportSix, urlSelfcareTable } from 'App'
import { useBodyClass, useAppDispatch, useAppSelector } from 'app/hooks'
import { dataImportActions, ImportRow } from './dataImportSlice'
import {
  initUploadSettingsKeepColumns,
  uploadedFileName,
} from './importSettingsSlice'
import { Navigate } from 'react-router'
import {
  selectTable,
  tableActions,
  TableRow,
} from 'components/table/tableSlice'
import {
  numbersWithSpacesX,
  numberWithSpaces,
  numberWithSpacesX,
} from 'app/util'
import {
  govActions,
  selectAddresses,
} from 'components/settings/governanceSlice'
import {
  addCountryCodeIfMissingVisualOnly,
  containsDuplicates,
  equalsBezRozdiluVCisluOrientacnim,
  isValidJmeno,
  isValidKontaktniOsoba,
  isValidKredit,
  isValidMesto,
  isValidNazevSpolecnosti,
  isValidPrijmeni,
  isValidPsc,
  isValidTelefon,
  isValidUliceCisloPopisne,
  normalizeUCP,
  parsePsc,
  removeDuplicates,
  splitPredvolbaTelefon,
} from 'util/ValidFormatOps'
import {
  finalJmeno,
  finalPrijmeni,
  finalUliceCisloPopisne,
  completeUliceCisloPopisne,
  completeMesto,
  completeKontaktniOsoba,
  completeNazevSpolecnosti,
  completePsc,
  completeTelefon,
} from 'util/DataImportFormatter'
import { reducerZadaniStravKredituCastkaKreditu } from 'components/settings/userSettingsSlice'
import { selectCenik } from 'components/onboarding/features/firemniUdajeSlice'
import { createAdresaBackend } from 'app/req'
import { changeZamestnanecBackend } from 'app/facade'
import { MultiPassCardState } from 'util/CardOps'

const DataImportSummary: FunctionComponent = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()
  const tableIds = useAppSelector(selectTable).flatMap((table) => table.id)
  const employees = useAppSelector(selectTable) as TableRow[]
  const importRows = useAppSelector(
    (state) => state.importSettings.importedRows
  ) as ImportRow[]
  const fileName = useAppSelector(uploadedFileName)
  const [redirect, setRedirect] = useState(false)
  const importSettings = useAppSelector((state) => state.importSettings)
  const changesAcceptance = importSettings.changesAcceptance
  const db = useAppSelector((state) => state.table)
  const addresses = useAppSelector(selectAddresses)
  const indexes = importSettings.badIndexToRealIndex

  const totalVolnocasovy = importRows.reduce((acc, curr) => {
    return acc + Number(curr.volnocasovyKredit || 0)
  }, 0)
  const totalStravenkovy = importRows.reduce((acc, curr) => {
    return acc + Number(curr.stravenkovyKredit || 0)
  }, 0)

  const appliedAddresses = useAppSelector(
    (state) => state.importSettings.appliedAddresses
  )

  // this can happen when somebody imports employees from a file, then does not persist the employees to backend by submitting the order, and imports again
  const existujeNaFrontenduAleNeNaBackendu = (
    moznaJizExistujici: TableRow | undefined,
    cisloZamestnance: string
  ) => {
    const existujeNaFrontenduAleNeNaBackendu =
      moznaJizExistujici?.cardStatus === MultiPassCardState.VKošíku
    // todo remove the log after prod testing of ORD-1901 and ORD-1902
    console.log(
      'DISummary, onSubmit -> cisloZamestnance, mozna jiz existujici zamestnanec, existuje na frontendu ale ne na backendu,',
      cisloZamestnance,
      moznaJizExistujici,
      existujeNaFrontenduAleNeNaBackendu
    )
    return existujeNaFrontenduAleNeNaBackendu
  }

  function onSubmit() {
    const rows = importRows.map((e, i) => {
      const mergedUliceCisloPopisne = normalizeUCP(
        completeUliceCisloPopisne(
          e,
          i,
          changesAcceptance,
          db,
          indexes,
          appliedAddresses
        )
      )
      const newOddeleni =
        addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            mergedUliceCisloPopisne
          )
        )?.oddeleni[0] || 'Žádné'
      const existujici = employees.find((z) => z.id === e.cisloZamestnance)

      if (
        existujici &&
        !existujeNaFrontenduAleNeNaBackendu(existujici, e.cisloZamestnance)
      ) {
        const employeeAddressOnBackend = addresses.find((a) =>
          equalsBezRozdiluVCisluOrientacnim(
            a.uliceCisloPopisne,
            existujici.uliceCisloPopisne
          )
        )
        changeZamestnanecBackend(
          e.cisloZamestnance,
          e.jmeno,
          e.prijmeni,
          employeeAddressOnBackend.uliceCisloPopisne, // is the same as existujici.uliceCisloPopisne
          employeeAddressOnBackend.psc, // is the same as existujici.psc but existujici (TableRow) does not have psc on its datatype
          equalsBezRozdiluVCisluOrientacnim(
            e.uliceCisloPopisne,
            existujici.uliceCisloPopisne
          )
            ? existujici.oddeleni
            : newOddeleni,
          existujici.paidMonths,
          existujici.deactivatedMonths
        )
      }
      return {
        ...e, // -> we try to reuse the row
        jmeno: finalJmeno(changesAcceptance, i, e, db, indexes),
        prijmeni: finalPrijmeni(changesAcceptance, i, e, db, indexes),
        uliceCisloPopisne: mergedUliceCisloPopisne,
        volnocasovyKredit: Number(e.volnocasovyKredit || 0),
        stravenkovyKredit: Number(e.stravenkovyKredit || 0),
        oddeleni: newOddeleni,
      }
    })
    console.log('Submitting these rows:', rows)
    dispatch(tableActions.importRowsToTheTable(rows))

    setRedirect(true)
    // do not reset dataimportsettings - user will usually import excels in the same format and will welcome this
    // but do free memory of the uploaded XLS File and related settings (however, it's not needed - useEffect on DIC-1 does it too).
    dispatch(dataImportActions.resetInitialState()) // delete redux state / uploaded file
    dispatch(initUploadSettingsKeepColumns()) // delete transformed data

    // Create NEW addresses
    // crazy O(n2) algo, but we have just a few addresses
    importRows.forEach((importRow: ImportRow, i: number) => {
      let exists = false
      addresses.forEach((existing) => {
        if (
          finalUliceCisloPopisne(
            changesAcceptance,
            i,
            importRow,
            db,
            indexes
          ) === existing.uliceCisloPopisne
        )
          exists = true
      })
      // do not create new address from excel if it already exists or if it was broken in excel
      if (!exists && !appliedAddresses[i]) {
        const { predvolba, telefon } = splitPredvolbaTelefon(importRow.telefon)
        const newAddress = {
          nazevSpolecnosti: importRow.nazevSpolecnosti,
          kontaktniOsoba: importRow.kontaktniOsoba,
          uliceCisloPopisne: importRow.uliceCisloPopisne,
          mesto: importRow.mesto,
          psc: parsePsc(importRow.psc),
          predvolba: predvolba || 420,
          telefon: telefon,
          oddeleni: ['Žádné'],
        }
        dispatch(govActions.addAddress(newAddress))
        createAdresaBackend(newAddress).then((res) => {
          console.log('Created new address after data import:', res)
          // if (res.status === 200) {
          console.log(
            'Mutating zamestnanec backend to have the newly assigned adress from data import'
          )
          const existujici = employees.find(
            (z) => z.id === importRow.cisloZamestnance
          )
          if (
            !existujeNaFrontenduAleNeNaBackendu(
              existujici,
              importRow.cisloZamestnance
            )
          ) {
            changeZamestnanecBackend(
              importRow.cisloZamestnance,
              importRow.jmeno,
              importRow.prijmeni,
              importRow.uliceCisloPopisne,
              importRow.psc,
              'Žádné',
              0,
              0
            )
          }
        })
      }
    })
    // switch to displaying Částka kreditu on Table Container
    dispatch(reducerZadaniStravKredituCastkaKreditu(true))
  }

  const containsDupliciteIds = containsDuplicates(
    // check for length > 0 to prevent duplicates of empty ids
    importRows.flatMap((r) => r.cisloZamestnance).filter((n) => n.length > 0)
  )

  const [invalidCells, setInvalidCells] = useState<Array<number>>([])

  function addInvalidCell(i: number): string {
    i = i + 1 // this is to account for null index
    // this is to prevent perma reload of page and to prevent duplicates
    // sometimes I saw duplicates even after this check, probably race condition
    if (!invalidCells.includes(i)) {
      setInvalidCells((prev) => [...prev, i])
    }
    return ''
  }

  const rowsWithIllFormattedCells = removeDuplicates(invalidCells).sort(
    (a, b) => +a - +b
  ) // most probably we should be able to remove the removeDuplicates, but better safe than sorry (race condition in includes check)

  function rowsWithNewIds(): number {
    let count = 0
    importRows
      .flatMap((r) => r.cisloZamestnance)
      .forEach((i) => (count += tableIds.includes(i) ? 0 : 1))
    return count
  }

  const cenik = useAppSelector(selectCenik)

  return (
    <div>
      {redirect && <Navigate to={urlSelfcareTable} />}
      <div className='table__import table__import--summary'>
        <div className='u-mb-3'>
          <h1 className='u-size-l28 u-mb-2'>Shrnutí importu</h1>
          <div className='boxes u-mb-2'>
            <div className='boxes__box'>
              <div className='boxes__inner'>
                <p className='u-size-s u-mb-1'>
                  Nové karty ({rowsWithNewIds()}ks)
                </p>
                <p className='u-size-l20 u-mb-1'>
                  <strong>
                    {rowsWithNewIds() * cenik.vydaniPrvniKarty} Kč
                  </strong>
                </p>
              </div>
            </div>
            <div className='boxes__box'>
              <div className='boxes__inner'>
                <p className='u-size-s u-mb-1'>Volnočasový kredit</p>
                <p className='u-size-l20 u-mb-1'>
                  <strong>{numberWithSpacesX(totalVolnocasovy)} Kč</strong>
                </p>
              </div>
            </div>
            <div className='boxes__box'>
              <div className='boxes__inner'>
                <p className='u-size-s u-mb-1'>Stravenkový kredit</p>
                <p className='u-size-l20 u-mb-1'>
                  <strong>{numberWithSpacesX(totalStravenkovy)} Kč</strong>
                </p>
              </div>
            </div>
            {invalidCells[0] ? (
              <div
                className='boxes'
                style={{
                  maxWidth: `${
                    rowsWithIllFormattedCells.length < 8
                      ? '240px'
                      : rowsWithIllFormattedCells.length < 16
                      ? '280px'
                      : rowsWithIllFormattedCells.length < 24
                      ? '360px'
                      : '420px'
                  }`,
                }}
              >
                <div className='boxes__inner'>
                  <p className='u-size-s u-mb-1'>
                    Řádky s buňkami obsahujícími údaje ve špatném formátu
                  </p>
                  <p className='u-size-l20 u-mb-1'>
                    <strong>
                      {numbersWithSpacesX(rowsWithIllFormattedCells)}
                    </strong>
                  </p>
                  {containsDupliciteIds ? (
                    <p className='u-size-s input__error'>
                      Duplicitní čísla zaměstnanců.
                    </p>
                  ) : (
                    <p className='u-size-s input__error'>
                      Nelze pokračovat v importu.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='table__overflow'>
          <div className='box box--small-radius box--no-padding box--transparent u-mb-9'>
            <div className='box__content'>
              <div
                role='table'
                aria-label='Spárování sloupců'
                className='table table--compact'
              >
                <div role='row' className='table__row'>
                  <div className='table__cell table__cell--small u-bg-white'></div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    <>Jméno</>
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Příjmení
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Číslo zaměstnance
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Volnočasový kredit
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Stravenkový kredit
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Název společnosti
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Ulice, č. popisné
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Město
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    PSČ
                  </div>
                  <div className='table__cell table__cell--fixed u-text-ellipsis'>
                    Kontaktní osoba
                  </div>
                  <div
                    style={{ borderTopRightRadius: '14px' }}
                    className='table__cell table__cell--fixed u-text-ellipsis'
                  >
                    Telefon
                  </div>
                </div>
                {importRows.map((row: ImportRow, i) => (
                  <div role='row' className='table__row' key={i}>
                    <div
                      className={`table__cell table__cell--small ${
                        // ORDTEX-57 Odstranenie farby
                        invalidCells.find((e) => e === i + 1)
                          ? 'u-bg-fuel-orange'
                          : // : appliedAddresses[i]
                            // ? 'u-bg-aquamarine-50'
                            'u-bg-white'
                      }`}
                    >
                      {i + 1}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        !isValidJmeno(
                          finalJmeno(changesAcceptance, i, row, db, indexes)
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {finalJmeno(changesAcceptance, i, row, db, indexes)}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        !isValidPrijmeni(
                          finalPrijmeni(changesAcceptance, i, row, db, indexes)
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {finalPrijmeni(changesAcceptance, i, row, db, indexes)}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        importRows
                          .flatMap((r) => r.cisloZamestnance)
                          .filter(
                            (id) => id.length > 0 && id === row.cisloZamestnance
                          ).length > 1 && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {!row.cisloZamestnance ? (
                        <i>- bude přiděleno -</i>
                      ) : (
                        row.cisloZamestnance
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        !isValidKredit(row.volnocasovyKredit) &&
                        'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {numberWithSpaces(row.volnocasovyKredit || '0')} Kč
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        !isValidKredit(row.stravenkovyKredit) &&
                        'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {numberWithSpaces(row.stravenkovyKredit || '0')} Kč
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidNazevSpolecnosti(
                          completeNazevSpolecnosti(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {completeNazevSpolecnosti(
                        row,
                        i,
                        changesAcceptance,
                        db,
                        indexes,
                        appliedAddresses,
                        addresses
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidUliceCisloPopisne(
                          completeUliceCisloPopisne(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {completeUliceCisloPopisne(
                        row,
                        i,
                        changesAcceptance,
                        db,
                        indexes,
                        appliedAddresses,
                        addresses
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidMesto(
                          completeMesto(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {completeMesto(
                        row,
                        i,
                        changesAcceptance,
                        db,
                        indexes,
                        appliedAddresses,
                        addresses
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidPsc(
                          completePsc(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {completePsc(
                        row,
                        i,
                        changesAcceptance,
                        db,
                        indexes,
                        appliedAddresses,
                        addresses
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidKontaktniOsoba(
                          completeKontaktniOsoba(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {completeKontaktniOsoba(
                        row,
                        i,
                        changesAcceptance,
                        db,
                        indexes,
                        appliedAddresses,
                        addresses
                      )}
                    </div>
                    <div
                      className={`table__cell table__cell--fixed u-text-ellipsis ${
                        // appliedAddresses[i]
                        //   ? 'u-bg-aquamarine-50'
                        //   :
                        !isValidTelefon(
                          completeTelefon(
                            row,
                            i,
                            changesAcceptance,
                            db,
                            indexes,
                            appliedAddresses,
                            addresses
                          )
                        ) && 'u-bg-fuel-orange' + addInvalidCell(i)
                      }`}
                    >
                      {addCountryCodeIfMissingVisualOnly(
                        completeTelefon(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          appliedAddresses,
                          addresses
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='u-fixed-bottom u-mb-4 u-px-3'>
        <div className='grid-x align-justify align-bottom u-ml-5 u-mr-3'>
          <div className='grid-x align-middle'>
            <svg className='icon u-mr-2'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>
            <i>{fileName}</i>
          </div>
          <div className='grid-x align-bottom'>
            <a href={urlDataImportSix} className='u-mr-2 u-mb-1'>
              Zpět
            </a>
            <button
              onClick={onSubmit}
              className={
                'btn u-size-base14 btn--primary btn--small' +
                (invalidCells[0] > -1 ? ' btn--disabled' : '')
              }
              disabled={invalidCells[0] > -1}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-check'}></use>
              </svg>
              Dokončit
            </button>
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}
export default DataImportSummary
