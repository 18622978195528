import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'
import { InternalCompany } from 'app/type'

const initialState: InternalCompany[] = []

const internalSlice = createSlice({
  name: 'internal',
  initialState,
  reducers: {
    replaceCompanies(state, action) {
      const companies = action.payload
      console.log(
        `addCompaniesToInternal: ${companies.length} companies, %O`,
        companies
      )
      state.splice(0, state.length, ...companies)
    },
    addCompany(state, action) {
      const newRow = action.payload
      state.push(newRow)
      console.log('addCompany called', newRow.orderId)
    },
    removeAllRowsFromTheTable(state) {
      state.splice(0, state.length)
    },
  },
})

export const internalActions = internalSlice.actions

export const selectInternalCompanies = (state: RootState) => state.internal

export default internalSlice.reducer
