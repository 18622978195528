import FileIcon from '@styles/icon/fileIcon'
import PenIcon from '@styles/icon/penIcon'
import PlusIcon from '@styles/icon/plusIcon'
import { UDAJ_JE_POVINNY } from 'app/constants'
import { modals, useAppDispatch, useAppSelector } from 'app/hooks'
import { numberWithSpacesX } from 'app/util'
import { FunctionComponent, Key, useEffect, useState } from 'react'
import {
  govActions,
  selectAddresses,
  selectBrokenAddresses,
} from './governanceSlice'
import CloseCircleFilledIcon from '@styles/icon/closeCircleFilledIcon'
import TrashIcon from '@styles/icon/trashIcon'
import {
  PridatAdresuModal,
  SmazatAdresuModal,
  UpravitAdresuModal,
} from 'components/modules/modal/Modals'
import { Modal } from 'components/modules/modal/Modal'
import Tip from './Tip'
import { selectTable, tableActions } from 'components/table/tableSlice'
import {
  equalsBezRozdiluVCisluOrientacnim,
  formatPsc,
} from 'util/ValidFormatOps'
import Color from 'app/colors'
import {
  createOddeleniBackend,
  mutateOddeleniBackend,
  purgeOddeleniBackend,
  sendAndLog,
} from 'app/req'
import { Address } from 'app/type'
import { lg } from 'app/facade'
import {
  ADD_ANOTHER_ADDRESS,
  DELIVERY_ADDRESS_HELP,
  DEPARTMENT_NAME,
} from 'app/constDictionary'
import { selectLanguage } from './userSettingsSlice'
import { beMakeSpolecnost, urlSelfcareTable } from 'App'
import { Navigate, useLocation } from 'react-router-dom'
import { isMigrating } from 'util/migrationOps'
import { nanoid } from '@reduxjs/toolkit'

interface Props {
  hasValidated?: boolean
}

const AddressesSettings: FunctionComponent<Props> = ({
  hasValidated = false,
}) => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(selectLanguage) || 'cz'

  useEffect(() => {
    window.addEventListener('beforeunload', removeEmptyFields)
    return () => {
      window.removeEventListener('beforeunload', removeEmptyFields)
    }
  })

  function removeEmptyFields() {
    // let's not do this, it can break references to oddeleni of employees and also remove last oddeleni
    // dispatch(govActions.removeEmptyOddeleni())
  }

  const { isShown: isShownSmazatAdresu, toggle: toggleSmazatAdresu } =
    modals[0]()
  const { isShown: isShownUpravitAdresu, toggle: toggleUpravitAdresu } =
    modals[1]()
  const { isShown: isShownPridatAdresu, toggle: togglePridatAdresu } =
    modals[2]()

  const [callerUliceCisloPopisne, setCallerUliceCisloPopisne] = useState('')
  const [callerPsc, setCallerPsc] = useState('')

  const brokenAddresses = useAppSelector(selectBrokenAddresses)

  const addresses = useAppSelector(selectAddresses)
  const employees = useAppSelector(selectTable)

  function uponArrivalFromRedirect(): boolean {
    return window.location.href.indexOf('~') > -1
  }

  // if (uponArrivalFromRedirect()) {
  //   window.location.href = urlNastaveni + MIGRACE_DAT
  // }

  // const [redirect, setRedirect] = useState(false)

  const location = useLocation()

  // We are having problem in https://czsodexo.atlassian.net/browse/ORD-1895, where this condition does not get met and the body does not get executed, including the PATCH request for setting the cerstveZmigrovana to false.
  // The solution is: even if the user does not finish the whole migration process, we can still set the cerstveZmigrovana to false so that the user does not get redirected to the migration page again.
  // Reasoning: if he leaves an ill-formatted address behind, he will be called to 'korekce-dat' anyway, so he will be redirected to the migration page again.
  // Action: put the PATCH request in the modal UpravitAdresu onSubmit, so that it gets executed on the completion of the first address's "migration".
  if (isMigrating() && brokenAddresses.length === 0) {
    // dispatch(govActions.clearBrokenAddresses())
    // setRedirect(true)
    sendAndLog(beMakeSpolecnost, { cerstveZmigrovana: false }, 'PATCH')
    return <Navigate to={urlSelfcareTable} />
  }

  if (!isShownUpravitAdresu && brokenAddresses.length > 0) {
    const callerUliceCisloPopisne =
      brokenAddresses[0]?.street + ', ' + brokenAddresses[0]?.cisloPopisne
    console.log(
      `AddressesSettings: setting callerUliceCisloPopisne ${callerUliceCisloPopisne}, brokenAddresses are: `,
      brokenAddresses
    )
    if (callerUliceCisloPopisne) {
      setTimeout(() => {
        setCallerUliceCisloPopisne(callerUliceCisloPopisne)
        setCallerPsc(brokenAddresses[0]?.psc)
        toggleUpravitAdresu()
      }, 300)
    }
  }

  // const spawnEditAddressModal = (address: Address) => {
  //   setCallerUliceCisloPopisne(address.uliceCisloPopisne)
  //   toggleUpravitAdresu()
  // }
  // if (!isShownUpravitAdresu) {
  //   for (let i = 0; i < brokenAddresses.length; i++) {
  //     if (!isValidAddress(brokenAddresses[i])) {
  //       console.log()
  //       // spawnEditAddressModal(brokenAddresses[i])
  //       // setCallerUliceCisloPopisne(brokenAddresses[i].uliceCisloPopisne)
  //       // toggleUpravitAdresu()
  //       break
  //     }
  //   }
  // }

  // if (spawnEditAddressModal) {
  //   setCallerUliceCisloPopisne(spawnEditAddressModal.uliceCisloPopisne)
  //   toggleUpravitAdresu()
  // }

  function vymazatOddeleni(address: Address, oddeleni: string) {
    const uliceCisloPopisne = address.uliceCisloPopisne
    let oddeleniHasEmployees = false
    for (let i = 0; i < employees.length; i++) {
      if (
        equalsBezRozdiluVCisluOrientacnim(
          employees[i].uliceCisloPopisne,
          uliceCisloPopisne
        )
      ) {
        if (employees[i].oddeleni === oddeleni) {
          oddeleniHasEmployees = true
        }
      }
    }

    if (oddeleniHasEmployees) {
      alert('Nelze vymazat neprázdné oddělení.')
    } else if (address.oddeleni.length === 1) {
      alert('Nelze vymazat poslední oddělení.')
    } else {
      dispatch(
        govActions.removeOddeleni({
          uliceCisloPopisne: uliceCisloPopisne,
          oddeleni: oddeleni,
        })
      )
      purgeOddeleniBackend(address, oddeleni)
    }
  }

  async function renameOddeleni(
    i: number,
    j: number,
    address: Address,
    s: string
  ) {
    console.log(
      `renaming address[${i}].oddeleni[${j}] to ${s}` + `\naddresses: `,
      addresses
    )

    if (address.oddeleni.includes(s)) {
      alert('Oddělení již existuje.')
    } else {
      dispatch(
        govActions.renameOddeleni({
          uliceCisloPopisne: address.uliceCisloPopisne,
          indexOddeleni: j, // careful! it's j, not i.
          newOddeleni: s,
        })
      )
      // get an array of employees with the same address and oddeleni and rename their oddeleni to the new one
      const employeesWithSameAddressAndOddeleni = employees.filter(
        (employee: { uliceCisloPopisne: string; oddeleni: string }) =>
          equalsBezRozdiluVCisluOrientacnim(
            employee.uliceCisloPopisne,
            address.uliceCisloPopisne
          ) && employee.oddeleni === address.oddeleni[j]
      )
      employeesWithSameAddressAndOddeleni.forEach((employee: { id: any }) => {
        dispatch(
          tableActions.setOddeleni({
            id: employee.id,
            oddeleni: s,
          })
        )
      })
      // timeout = timeout + 300
      // setTimeout(
      //   () =>
      //     {return mutateOddeleniBackend(address, address.oddeleni[j], e.target.value),
      //   timeout}
      // )
      return mutateOddeleniBackend(address, address.oddeleni[j], s)
    }
    // .catch((err) => {
    //   console.log('error', err)
    // })
  }

  function recursiveRenameOddeleni(
    i: number,
    j: number,
    address: Address,
    s: string
  ) {
    renameOddeleni(i, j, address, s).then((res) => {
      // console.log('response', res)
      if (res.zprava === 'Adresa nenalezena') {
        console.log('address not found, running recursion')
        // setTimeout(() => recursiveRenameOddeleni(i, j, address, s), 1000)
        recursiveRenameOddeleni(i, j, address, s)
      }
    })
    // .catch((err) => {
    //   console.log('error', err)
    // })
  }

  return (
    <div>
      {/* {redirect && <Redirect to={urlSelfcareTable} />} */}
      <Modal
        isShown={isShownSmazatAdresu}
        hide={toggleSmazatAdresu}
        headerText='Zavřít'
        subheaderText='Smazat adresu?'
        modalContent={
          <SmazatAdresuModal
            onSubmit={toggleSmazatAdresu}
            onCancel={toggleSmazatAdresu}
            uliceCisloPopisne={callerUliceCisloPopisne}
          />
        }
      />
      <Modal
        isShown={isShownUpravitAdresu}
        hide={toggleUpravitAdresu}
        headerText='Zrušit'
        subheaderText='Upravit adresu'
        modalContent={
          <UpravitAdresuModal
            onSubmit={() => {
              if (isMigrating())
                sendAndLog(
                  beMakeSpolecnost,
                  { cerstveZmigrovana: false },
                  'PATCH'
                )
              toggleUpravitAdresu()
              console.log(
                'UpravitAdresuModal - onSubmit: addresses, brokenAddresses',
                addresses,
                brokenAddresses
              )
            }}
            uliceCisloPopisne={callerUliceCisloPopisne}
            psc={callerPsc}
          />
        }
      />
      <Modal
        isShown={isShownPridatAdresu}
        hide={togglePridatAdresu}
        headerText='Zrušit'
        subheaderText='Přidat novou adresu'
        modalContent={<PridatAdresuModal onSubmit={togglePridatAdresu} />}
      />

      <div className='highlight__content'>
        {hasValidated && (!addresses || addresses.length === 0) && (
          <span className='input__error'>{UDAJ_JE_POVINNY}</span>
        )}
        {addresses.map(
          (
            address: {
              uliceCisloPopisne: any
              nazevSpolecnosti: any
              kontaktniOsoba: any
              mesto: any
              psc: any
              predvolba: any
              telefon: any
              oddeleni: any
            },
            i: string | number
          ) => (
            <div key={address.uliceCisloPopisne}>
              <p className='u-mb-3'>
                {address.nazevSpolecnosti}
                <br />
                {address.kontaktniOsoba}
                <br />
                {address.uliceCisloPopisne}
                <br />
                {address.mesto}, {formatPsc(address.psc)}
                <br />
                tel.: +{address.predvolba}{' '}
                {numberWithSpacesX(address.telefon || 0)}
              </p>
              {/* Upravit, přiřadit, smazat adresu */}
              <div className='u-mb-3'>
                <button
                  onClick={() => {
                    toggleUpravitAdresu()
                    setCallerUliceCisloPopisne(address.uliceCisloPopisne)
                    setCallerPsc(address.psc)
                  }}
                  className='button-as-anchor u-mr-2 u-size-s'
                >
                  <svg className='icon icon--16'>
                    <PenIcon width={16} height={16} />
                  </svg>
                  <strong>Upravit</strong>
                </button>
                {!address.oddeleni.includes('') && (
                  <button
                    onClick={() => {
                      dispatch(
                        // Here we do not reaally add a new oddeleni, we add a blank field providing the opportunity to write.
                        govActions.addOddeleni({
                          uliceCisloPopisne: address.uliceCisloPopisne,
                          oddeleni: '', // wtf is this? - empty oddělení for the user to be able to put name into it.
                        })
                      )
                      createOddeleniBackend({
                        address: address,
                        oddeleni: '', // todo this must be failing, no?
                      })
                    }}
                    className='button-as-anchor u-mr-2 u-size-s'
                  >
                    <svg className='icon icon--16'>
                      <FileIcon width={16} height={16} />
                    </svg>
                    <strong>Přiřadit oddělení</strong>
                  </button>
                )}
                <button
                  onClick={() => {
                    if (addresses.length > 1) {
                      toggleSmazatAdresu()
                      setCallerUliceCisloPopisne(address.uliceCisloPopisne)
                      setCallerPsc(address.psc)
                    } else if (employees.length > 0) {
                      alert(
                        'Nejméně jedna adresa musí být zadána u každého zaměstnance. Přidejte novou adresu před smazáním této.'
                      )
                    } else {
                      dispatch(
                        govActions.removeAddressByUliceCisloPopisne(
                          address.uliceCisloPopisne
                        )
                      )
                    }
                  }}
                  className='button-as-anchor u-size-s u-color-pluxee-red'
                >
                  <svg className='icon icon--16'>
                    <TrashIcon width={16} height={16} />
                  </svg>
                  <strong>Smazat adresu</strong>
                </button>
              </div>
              {address.oddeleni.map((department: string, j: Key) => (
                // do not put key = department here, it causes re-rendering of whole component
                <div key={nanoid(5)}>
                  <div className='input u-mb-4'>
                    <label className='input__label'>
                      {lg(lang, DEPARTMENT_NAME)}
                    </label>
                    <div className='input__wrapper'>
                      <input
                        type='text'
                        value={addresses[i].oddeleni[j]}
                        onChange={(e) => {
                          recursiveRenameOddeleni(
                            +i,
                            +j,
                            address,
                            e.currentTarget.value
                          )
                        }}
                      />
                      <svg
                        onClick={() => vymazatOddeleni(address, department)}
                        className='input__remove icon icon--24'
                      >
                        <CloseCircleFilledIcon fill={Color.RED} />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
        <Tip
          id={'sprava-adres-tip'}
          content={lg(lang, DELIVERY_ADDRESS_HELP)}
        />
        <div>
          <button
            onClick={togglePridatAdresu}
            className='btn btn--primary btn--small'
          >
            <svg className='icon icon--24'>
              <PlusIcon width={24} height={24} />
            </svg>
            {lg(lang, ADD_ANOTHER_ADDRESS)}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddressesSettings
