import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface ObjednavatelState {
  pracovniEmail?: string
  kontaktniEmail?: string
  heslo?: string
  telefon?: number
  predvolba: number
  poznamkaNaFakture?: string
}

const initialState: ObjednavatelState = {
  predvolba: 420,
}

export const objednavatelSlice = createSlice({
  name: 'objednavatel',
  initialState,
  reducers: {
    reducerPracovniEmail: (state, action: PayloadAction<string>) => {
      state.pracovniEmail = action.payload
    },
    reducerKontaktniEmail: (state, action: PayloadAction<string>) => {
      state.kontaktniEmail = action.payload
    },
    reducerHeslo: (state, action: PayloadAction<string>) => {
      state.heslo = action.payload
    },
    reducerTelefon: (state, action: PayloadAction<number | undefined>) => {
      state.telefon = action.payload
    },
    reducerPredvolba: (state, action: PayloadAction<number>) => {
      state.predvolba = action.payload
    },
    reducerPoznamkaNaFakture: (state, action: PayloadAction<string>) => {
      state.poznamkaNaFakture = action.payload
    },
  },
})

export const {
  reducerPracovniEmail,
  reducerKontaktniEmail,
  reducerHeslo,
  reducerTelefon,
  reducerPredvolba,
  reducerPoznamkaNaFakture,
} = objednavatelSlice.actions

// The function below is called a selector and allows us to select a value from the state.
export const selectPracovniEmail = (state: RootState) =>
  state.objednavatel.pracovniEmail
export const selectKontaktniEmail = (state: RootState) =>
  state.objednavatel.kontaktniEmail
export const selectHeslo = (state: RootState) => state.objednavatel.heslo
export const selectTelefon = (state: RootState) => state.objednavatel.telefon
export const selectPredvolba = (state: RootState) =>
  state.objednavatel.predvolba
export const selectPoznamkaNaFakture = (state: RootState) =>
  state.objednavatel.poznamkaNaFakture

export default objednavatelSlice.reducer
