export enum Color {
  // Sodexo UI Library
  // https://www.figma.com/file/bbZd3Ky1Nn9osbKBimEtSaH3/Sodexo-UI-Library?node-id=1%3A2

  // 00.1 Color Palette main
  // ######### PRIMARY #########
  BLUEDARK = '#2A295C',
  // C1 Dark Purple Blue
  // Contrast ratio with white: 13.37:1
  // Large text: AAA
  // Small text: AAA
  // Use for:
  // This color is used as a base color for all sodexo’s design system on elements that do not carry specific informations like strokes, some outlines, etc..
  // Also used for texts, icons and default elements on a white background or surface. It can also be used as a surface color when the writing is in white.

  BLUESODEXO = '#283897',
  // C2-1 Sodexo Blue
  // Contrast ratio with white: 9.99:1
  // Large text: AAA
  // Small text: AAA
  // Use for:
  // The original Sodexo Blue, it is used on the logotype and as the secondary color.
  // It can be used for actions, surfaces (used carefully), short specific texts and icons.

  WHITE = '#FFFFFF',
  // C3 - White
  // Contrast ratio with white: 1.00:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Surfaces and backgrounds or on primary, secondary, warnings, errors, and success.

  RED_PRIMARY = '#E32B24',
  // C4-1 Primary Red
  // Contrast ratio with white: 4.53:1
  // Large text: AAA
  // Small text: AA
  // Use for:
  // This is Sodexo's secondary color, the red must be used carefully to accent or put emphasis on an action/button.
  // It is the primary color for Call To Actions (CTAs) such as buttons.

  // ######### SECONDARY #########
  VIOLET = '#8282DC',
  // C5-1 Violet
  // Contrast ratio with white: 3.4:1
  // Large text: AA
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  AQUAMARINE = '#3CDCC8',
  // C6 Aquamarine
  // Contrast ratio with white: 1.71:1
  // Large text: Fail
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  LIME = '#D2DC28',
  // C7 Lime
  // Contrast ratio with white: 1.49:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  SALMON = '#FF9673',
  // C8 Salmon
  // Contrast ratio with white: 2.12:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  ORANGENEW = '#EF661A',
  // C8 New Orange
  // Contrast ratio with white: 3:18:1
  // Large text: AA
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  FUSHIA = '#CC1480',
  // C9 Fushia
  // Contrast ratio with white: 5.27:1
  // Large text: AAA
  // Small text: AA
  // Use for:
  // Used as a surface or a background to create contrast.
  // Can be used sometimes to accent an element (icon, text-link, et cetera).

  VIOLETLIGHT = '#E1E2F8',
  // C5-3 Light Violet
  // Contrast ratio with white: 1.27:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  // ######### NEUTRAL #########
  GREYOLD = '#EAE9EF',
  // C10 Old Grey
  // Contrast ratio with white: 1.20:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  GREYLIGHT = '#F9FAFF',
  // C11 Light Grey
  // Contrast ratio with white: 1.04:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  GREYMEDIUMC12 = '#EFF1F6',
  // C12 Medium Grey
  // Contrast ratio with white: 1.13:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  GREYMEDIUMC13 = '#F8F7F9',
  // C13 Medium Grey
  // Contrast ratio with white: 1.06:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or a background to create contrast.

  // ######### C1 Variations #########
  BLUEDARK_C1Var80 = '#55547D',
  // C1-80 Dark Purple Blue
  // Contrast ratio with white: 7.11:1
  // Large text: AAA
  // Small text: AAA
  // Use for:
  // Used for content text, icons and other elements.
  // Never used for emphasis or highlighting.

  BLUEDARK_C1Var60 = '#7F7F9D',
  // C1-60 Dark Purple Blue
  // Contrast ratio with white: 1.04:1
  // Large text: AA
  // Small text: FAIL
  // Use for:
  // Displaying lowkey elements or subtitles.

  BLUEDARK_C1Var40 = '#AAA9BE',
  // C1-40 Dark Purple Blue
  // Contrast ratio with white: 2.30:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or background.

  BLUEDARK_C1Var20 = '#D4D4DE',
  // C1-20 Dark Purple Blue
  // Contrast ratio with white: 1.47:1
  // Large text: FAIL
  // Small text: FAIL
  // Use for:
  // Used as a surface or background.

  // 00.2 Color Palette variants
  BLUEHIGHKEY = '#3862F8',
  // C2-2 Blue Highkey
  // Contrast ratio with white: 4.92:1
  // Large text: AAA
  // Small text: AA
  // Use for:
  // Lighting effects but use sparingly as it has a strong luminance
  // and it tends to be jarring among other low-key elements.

  BLUE = '#293B94',
  BLUELIGHT = '#8CCDEC',
  BLUE_DANYIL = '#E3E6F2',
  GREENDARK = '#006245',
  GREENLIGHTEST = '#E4FFF7',

  RED = '#EA444E',
  REDDARK = '#B30000',
  REDLIGHT = '#FFACB0',
  REDLIGHTEST = '#FFEEEE',

  VIOLETDARK = '#55547A',

  GREY = '#9D9CB4',
  GREY_LIGHT = '#DFDFE7',
  GREYLIGHTER = '#EAE9EE',

  ORANGEDARK = '#812F00',
  ORANGELIGHT = '#FFEDD1',

  TABLE_HEADER_TEXT = '#CACEE5',
}

export default Color
