import React from 'react'
import { ExcelFile } from 'app/type'
import { formatDate, formatDateAndTime } from 'util/DateOps'
import icons from '@styles/dist/system/icons.svg'
import { verne } from 'app/julesverne'
import { loadAndOpenFile } from 'app/req'
import { beDownloadExcel } from 'App'

interface Props {
  uploadedFiles: ExcelFile[]
}

const UploadedFilesHistory: React.FC<Props> = ({ uploadedFiles }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <div>
      <p className='u-size-l20 u-mb-2'>Poslední nahrané soubory</p>
      <p className='u-mb-2'>Soubory ke stažení ve formátu XLS</p>
      {verne('Uploaded Files', uploadedFiles)}
      <div className='file-upload__history'>
        {uploadedFiles.map((file, index) => (
          <div key={index}>
            <svg className='icon icon--24'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>{' '}
            <button
              key={index}
              className='button-as-anchor u-mb-2'
              onClick={() => {
                loadAndOpenFile(beDownloadExcel + `/${file.uuid}`, setIsLoading)
              }}
            >
              {`${formatDateAndTime(file.date)}`}
            </button>
            {isLoading && (
              <span
                style={{ fontSize: '20px' }}
                className='u-ml-2 loader'
              ></span>
            )}
            <br />
          </div>
        ))}
      </div>
    </div>
  )
}

export default UploadedFilesHistory
