import React from 'react'
import { HeaderGroup, useAsyncDebounce } from 'react-table'
import icons from '@styles/dist/system/icons.svg'
import { useAppSelector, useComponentVisible } from 'app/hooks'
import { selectDeaktivovaniActive } from 'components/settings/userSettingsSlice'
import { lg } from 'app/facade'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import {
  CARD_STATUS,
  ORDER_STATUS,
  RECORD,
  RECORDS,
  TO_FILTER,
} from 'app/constDictionary'
import { Order, InternalCompany } from 'app/type'
import { TableRow } from './tableSlice'

export function renderHeaderAndSortIndicators(
  column:
    | HeaderGroup<TableRow>
    | HeaderGroup<Order>
    | HeaderGroup<InternalCompany>
): JSX.Element {
  return column.Header !== undefined ? (
    <b>
      {column.render('Header')}
      <span>
        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
      </span>
    </b>
  ) : (
    <></>
  )
}

// Define the UI for global filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isDeaktivovaniActive,
  isDominantElement = false,
}): JSX.Element {
  const count = preGlobalFilteredRows.filter((row) =>
    isDeaktivovaniActive
      ? row.original.deactivatedFrom
      : !row.original.deactivatedFrom
  ).length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 100)

  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <span>
      :
      <input
        value={value || ''}
        type='search'
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${count} ${
          count === 1 ? lg(lang, RECORD) : lg(lang, RECORDS)
        }`}
        className='input-child table_input'
        style={isDominantElement ? { width: '200px' } : {}}
      />
    </span>
  )
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}): JSX.Element {
  const count = preFilteredRows.length
  const lang = useAppSelector(selectLanguage) || 'cz'
  return (
    <input
      className='table_input'
      value={filterValue || ''}
      type='search'
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`${lg(lang, TO_FILTER)}...`}
    />
  )
}
// This is a custom filter UI for selecting a unique option from a list
export function SelectColumnFilterOld({
  column: { filterValue, setFilter, preFilteredRows, id },
}): JSX.Element {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows]) as string[]

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value=''>Vše</option>
      {options.map((option: string) => (
        <option
          // key={nanoid(5)}
          value={option}
        >
          {option}
        </option>
      ))}
    </select>
  )
}

interface SelectColumnFilterProps {
  column: {
    filterValue: string
    setFilter: (value: string) => void
    preFilteredRows: any[]
    id: string
  }
}
// This is a custom filter UI for selecting a unique option from a list
export const SelectColumnFilter: React.ReactElement<SelectColumnFilterProps> =
  // @ts-ignore
  ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const { ref, isComponentVisible } = useComponentVisible(false, () => {
      setMultiSelectVisible(false)
    })
    const isDeaktivovaniActive = useAppSelector(selectDeaktivovaniActive)
    preFilteredRows = preFilteredRows.filter((row) =>
      isDeaktivovaniActive
        ? row.original.deactivatedFrom
        : !row.original.deactivatedFrom
    )
    const [multiSelectVisible, setMultiSelectVisible] =
      React.useState(isComponentVisible)
    // Calculate the options for filtering using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach((row) => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows]) as string[]

    const lang = useAppSelector(selectLanguage) || 'cz'
    // Render a multi-select box
    return (
      <div
        // @ts-ignore
        ref={ref}
        onClick={() => setMultiSelectVisible(!multiSelectVisible)}
        className={
          'modal__trigger select select--wide select--checkboxes' +
          (multiSelectVisible ? ' select--active' : '')
        }
      >
        <div className='modal__trigger select__option select__option--selected'>
          <span className='modal__trigger'>
            {filterValue
              ? filterValue
              : id === 'orderState'
              ? lg(lang, ORDER_STATUS)
              : id === 'deliveryState'
              ? lg(lang, CARD_STATUS)
              : lg(lang, ORDER_STATUS)}
          </span>
          <svg className='modal__trigger icon icon--24'>
            <use
              className='modal__trigger'
              href={
                icons +
                (multiSelectVisible
                  ? '#sprite-sorting-up'
                  : '#sprite-sorting-down')
              }
            ></use>
          </svg>
        </div>
        <div className='modal__trigger select__options'>
          {options.map((option: string) => (
            <div
              className='modal__trigger select__option'
              // MULTIVALUE FILTERS NOT SUPPORTED https://github.com/tannerlinsley/react-table/discussions/2247
              onClick={() => {
                filterValue
                  ? filterValue.includes(option)
                    ? setFilter(undefined)
                    : setFilter(option)
                  : setFilter(undefined)
              }}
            >
              <label className='modal__trigger checkbox u-size-s'>
                {option}
                <input
                  value={option}
                  type='checkbox'
                  checked={filterValue && filterValue.includes(option)}
                />
                <span className='modal__trigger checkbox__box'></span>
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

// This is a custom UI for our 'between' or number range filter.
// It uses two number boxes and filters rows to ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type='tel'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
        onKeyDown={(e) =>
          (e.key === 'e' || e.key === '-' || e.key === '+') &&
          e.preventDefault()
        }
        onWheel={(e) => e.currentTarget.blur()}
      />
      až
      <input
        value={filterValue[1] || ''}
        type='tel'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
        onKeyDown={(e) =>
          (e.key === 'e' || e.key === '-' || e.key === '+') &&
          e.preventDefault()
        }
        onWheel={(e) => e.currentTarget.blur()}
      />
    </div>
  )
}

// This is a custom filter UI that uses a slider to set the filter value
// between a column's min and max values
export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}): JSX.Element {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type='range'
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}
