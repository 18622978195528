import React, { useRef, useState } from 'react'

import { TSMenu } from '../../../../app/type';
import { useOnClickOutside } from '../../../../app/hooks';
import ChevronIcon from '@styles/icon/chevronIcon';

import './Style.css'


type TMultiLevelMenu = {
    name: string
    items: TSMenu[]
}

const MultiLevelMenu: React.FC<TMultiLevelMenu> = ({name, items}) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const itemRef = useRef<HTMLDivElement>(null)

    const showDropdownHandler = () => {
        setShowDropdown(!showDropdown)
    }

    useOnClickOutside(itemRef, () => setShowDropdown(false))

    return (
        <div ref={itemRef}>
            <button type="button" className="topbar__nav-link button-as-anchor multi-level-button" onClick={showDropdownHandler}>
                {name}
                <ChevronIcon className={`chevron-icon ${showDropdown ? 'chevron-icon-up' : ''}`} height={15}/>
            </button>
            {showDropdown && (
                <div className="multi-level-menu">
                    {items.map((item, index) => (
                        <a
                            className="menu__item"
                            href={item.url}
                            key={index}>
                            {item.name}
                        </a>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MultiLevelMenu;
