import {
  KRATKE_HESLO,
  NEPLATNA_ADRESA,
  NEPLATNA_KONTAKTNI_OSOBA,
  NEPLATNE_MESTO,
  NEPLATNE_PSC,
  NEPLATNE_TELEFONNI_CISLO,
  NEPLATNY_EMAIL,
  NEPLATNY_NAZEV_SPOLECNOSTI,
  UDAJ_JE_POVINNY,
} from 'app/constants'
import { MIN_POCET_ZNAKU_HESLA } from 'app/tuning'
import { Address } from 'app/type'
import {
  emailRegex,
  numberWithSpaces,
  numberWithSpacesX,
  pscRegex,
  telefonniCisloRegex,
} from 'app/util'

// #############################################################################
// ####### VALIDATION FUNCTIONS WITH BOOLEAN OUTPUT - CALLED BY DIC ############
// #############################################################################
export function isValidAddress(address: Address): boolean {
  return (
    isValidKontaktniOsoba(address.kontaktniOsoba) &&
    isValidNazevSpolecnosti(address.nazevSpolecnosti) &&
    isValidUliceCisloPopisne(address.uliceCisloPopisne) &&
    isValidMesto(address.mesto) &&
    isValidPsc(address.psc) &&
    isValidTelefon(address.telefon.toString()) &&
    isValidPredvolba(address.predvolba)
    // && isValidOddeleni(address.oddeleni) - we do not allow the user to edit oddeleni in Upravit modal, so why validate it and ask him to fix something which we don't display to his eyes anyways?
  )
}

export function isValidJmeno(jmeno: string): boolean {
  // bez číslic a delší než 1 znak
  return jmeno !== '' && jmeno.length > 1 && !jmeno.match(/\d/)
}
export function isValidPrijmeni(prijmeni: string): boolean {
  // bez číslic a delší než 1 znak
  return prijmeni !== '' && prijmeni.length > 1 && !prijmeni.match(/\d/)
}
export function isValidCisloZamestnance(cisloZamestnance: string): boolean {
  return cisloZamestnance !== '' && cisloZamestnance.length > 0
}
export function isValidNazevSpolecnosti(nazevSpolecnosti: string): boolean {
  return nazevSpolecnosti !== '' && nazevSpolecnosti.length > 1
}
export function isValidUliceCisloPopisne(uliceCisloPopisne: string): boolean {
  let { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne)
  // regex check that cisloPopisne does not contain a digit
  return (
    ulice.length > 1 && cisloPopisne.length > 0 && !!cisloPopisne.match(/\d/)
  )
}
export function isValidMesto(mesto: string): boolean {
  // Aš
  return mesto !== '' && mesto.length > 1
}
export function isValidPsc(psc: string | number): boolean {
  // odstranit vedlejší znaky a naformátovat. Výsledný počet číslic musí být právě 5.
  // trochu zbytečný ten první řádek anebo pscRegex, ale to je jen pro kontrolu
  return (
    psc.toString().replace(/\D/g, '').length === 5 &&
    pscRegex.test(psc.toString())
  )
}
export function isValidKontaktniOsoba(kontaktniOsoba: string): boolean {
  return (
    kontaktniOsoba !== '' &&
    kontaktniOsoba.length > 1 &&
    !kontaktniOsoba.match(/\d/)
  )

  // validate that kontaktniOsoba contains a name and surname - only letters and one space in between
  // const nameAndSurnameRegex =
  // /^[A-ZÁÄČĎÉĚÍĹĽŇÓŔŠŤÚŮÝŽŘ][a-záäčďéěíĺľňóŕšťúůýžř]+ [A-ZÁÄČĎÉĚÍĹĽŇÓŔŠŤÚŮÝŽŘ][a-záäčďéěíĺľňóŕšťúůýžř]+$/
  // return nameAndSurnameRegex.test(kontaktniOsoba)
}

export function isValidTelefon(telefon: string): boolean {
  const fullRegex = new RegExp(
    /^([+]|([0]{2}))?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?([0-9]{3}[-\s.])?[0-9]{3}$/
  ) // full-version with country code
  // podporovat s předvolbou i bez, pomlčky a mezery, zformátovat, defaultně dopltnit na +420
  return telefon !== '' && telefon.length > 1 && fullRegex.test(telefon)
}
export function isValidTelefonX(telefon: number): boolean {
  return telefon < 999999999 && telefon > 100000000
}
export function isValidPredvolba(predvolba: number): boolean {
  return predvolba < 999 && predvolba > 10
}
export function isValidOddeleni(oddeleni: string[]): boolean {
  return oddeleni.length > 0 && oddeleni[0].length > 0
}
export function isValidKredit(kredit: string): boolean {
  return kredit !== '' && +(kredit || '0') > -1
}

// #############################################################################
// ####### VALIDATION FUNCTIONS WITH STRING OUTPUT - CALLED BY FORMIK ##########
// #############################################################################
export function validateEmail(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!emailRegex.test(value)) {
    error = NEPLATNY_EMAIL
  }
  return error
}
export function validatePassword(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (value.length < MIN_POCET_ZNAKU_HESLA) {
    error = KRATKE_HESLO
  }
  return error
}
export function validatePasswordMatch(value: string, oldValue: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (value !== oldValue) {
    error = 'Zadaná hesla se neshodují'
  }
  return error
}
export function validateNewPassword(value: string, oldValue: string): string {
  let error = ''
  if (!value) {
    return ''
  } else if (value.length < MIN_POCET_ZNAKU_HESLA) {
    error = KRATKE_HESLO
  }
  // disable this check because we want to allow the user to only change his email and not password = let him put in the same password
  else if (value === oldValue) {
    error = 'Zvolte si prosím jiné než stávající heslo'
  }
  return error
}
export function validateUliceCisloPopisne(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!isValidUliceCisloPopisne(value)) {
    error = NEPLATNA_ADRESA
  }
  return error
}
export function validateMesto(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!isValidMesto(value)) {
    error = NEPLATNE_MESTO
  }
  return error
}
export function validatePsc(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!isValidPsc(value)) {
    error = NEPLATNE_PSC
  }
  return error
}
export function validateNazevSpolecnosti(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!isValidNazevSpolecnosti(value)) {
    error = NEPLATNY_NAZEV_SPOLECNOSTI
  }
  return error
}
export function validateKontaktniOsoba(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!isValidKontaktniOsoba(value)) {
    error = NEPLATNA_KONTAKTNI_OSOBA
  }
  return error
}
export function validateTelefon(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  } else if (!telefonniCisloRegex.test(value)) {
    error = NEPLATNE_TELEFONNI_CISLO
  }
  return error
}
export function validateNepovinnyTelefon(value: string): string {
  let error = ''
  if (value && !telefonniCisloRegex.test(value)) {
    error = NEPLATNE_TELEFONNI_CISLO
  }
  return error
}
export function validateJmeno(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  }
  return error
}
export function validatePrijmeni(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  }
  return error
}

export function validateUsername(value: string): string {
  let error = ''
  if (!value) {
    error = UDAJ_JE_POVINNY
  }
  return error
}

// #############################################################################
// ###################### FORMATTING FUNCTIONS #################################
// #############################################################################
// export function formatUliceCisloPopisne(uliceCisloPopisne: string) {
//   // if (!isValidUliceCisloPopisne(uliceCisloPopisne)) {
//   // let's not interfere with the user's input
//   // return uliceCisloPopisne
//   // } else {
//   // let commaIndex = uliceCisloPopisne.indexOf(',')
//   // // remove all commas
//   // let formattedUliceCisloPopisne = uliceCisloPopisne.replace(/,/g, '')
//   // // add one comma back
//   // formattedUliceCisloPopisne =
//   //   formattedUliceCisloPopisne.substring(0, commaIndex) +
//   //   ',' +
//   //   formattedUliceCisloPopisne.substring(commaIndex)
//   // // remove double whitespaces
//   let formattedUliceCisloPopisne = uliceCisloPopisne
//     .replace(/\s+/g, ' ')
//     .replace(/,+/g, ',')
//   let commaIndex = formattedUliceCisloPopisne.indexOf(',')
//   if (uliceCisloPopisne.charAt(commaIndex + 1) !== ' ') {
//     formattedUliceCisloPopisne =
//       formattedUliceCisloPopisne.substring(0, commaIndex) +
//       ' ' +
//       formattedUliceCisloPopisne.substring(commaIndex)
//   }
//   if (uliceCisloPopisne.charAt(commaIndex - 1) === ' ') {
//     formattedUliceCisloPopisne =
//       formattedUliceCisloPopisne.substring(0, commaIndex - 1) +
//       formattedUliceCisloPopisne.substring(commaIndex)
//   }

//   return formattedUliceCisloPopisne
//   //   return uliceCisloPopisne
//   // } else {
//   //   return (
//   //     uliceCisloPopisne.split(',')[0] + ' ' + uliceCisloPopisne.split(',')[1]
//   //   )
//   // }
//   // }
// }
export function formatPsc(psc: string | number): string {
  if (!psc) return ''
  let withoutSpaces = psc.toString().replace(/\s/g, '')
  return withoutSpaces.slice(0, 3) + ' ' + withoutSpaces.slice(3, 5)
}
export function formatTelefon(x: any): string {
  if (x === undefined) {
    return ''
  }
  if (typeof x === 'string') {
    // console.log('string')
    return numberWithSpaces(x)
  } else {
    // console.log('number')
    return numberWithSpacesX(x)
  }
}
export function addCountryCodeIfMissingVisualOnly(predvolbaTelefon: string) {
  // 11 because we are in the visual mode, aka we always already have the spaces between the numbers
  if (predvolbaTelefon.length === 11) {
    return '+420 ' + predvolbaTelefon
  } else {
    // means it has the country code, aka the length is 14 or 15 or 16
    return predvolbaTelefon
  }
}

export function stringToNumber(string: string): number {
  return parseInt(string.replace(/[^0-9]/g, ''))
}

export function removeDuplicates(arr: any[]) {
  return arr.filter(
    (obj, index, self) =>
      index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj))
  )
}

export function containsDuplicates(arr: any[]): boolean {
  return arr.length !== removeDuplicates(arr).length
}

/* We work with strings here because it's easier to display an empty string than a zero. */
export function splitPredvolbaTelefon(predvolbaTelefon: string): {
  predvolba: number
  telefon: number
} {
  if (predvolbaTelefon === undefined) {
    return { predvolba: 0, telefon: 0 }
  }

  // remove all non-digit chars
  const stripped = predvolbaTelefon.replace(/\D/g, '')
  if (stripped.length === 9) {
    return { predvolba: 0, telefon: +stripped }
  } else if (stripped.length === 12) {
    return {
      predvolba: +stripped.slice(0, 3),
      telefon: +stripped.slice(3, stripped.length),
    }
  } else {
    return { predvolba: 0, telefon: 0 }
  }
}

export function splitUliceCisloPopisneZachovatCisloOrientacni(
  uliceCisloPopisne: string | undefined
): {
  ulice: string
  cisloPopisne: string
} {
  if (uliceCisloPopisne === undefined) {
    return { ulice: '', cisloPopisne: '' }
  }
  // we split from the end, where the number can be a number, a number with a letter or two numbers separated by a slash
  let lastSpace = uliceCisloPopisne.lastIndexOf(' ')

  // if space is the last character (a typo by HR), remove it
  if (uliceCisloPopisne.length - 1 === lastSpace) {
    uliceCisloPopisne = uliceCisloPopisne.slice(0, lastSpace)
    lastSpace = uliceCisloPopisne.lastIndexOf(' ')
  }

  let cisloPopisne = uliceCisloPopisne.substring(lastSpace + 1)
  let ulice = uliceCisloPopisne.substring(0, lastSpace)

  // if cisloPopisne doesn't contain a number, concatenate it to ulice and clear it
  if (cisloPopisne.replace(/\D/g, '') === '') {
    ulice = ulice + ' ' + cisloPopisne
    cisloPopisne = ''
  }

  function normalizeUlice(ulice: string): string {
    if (
      ulice.charAt(ulice.length - 1) === ' ' ||
      ulice.charAt(ulice.length - 1) === ','
    ) {
      ulice = ulice.slice(0, ulice.length - 1)
      ulice = normalizeUlice(ulice)
    }
    return ulice
  }

  ulice = normalizeUlice(ulice)
  return {
    ulice: ulice,
    cisloPopisne: cisloPopisne,
  }
}

export function splitUliceCisloPopisne(uliceCisloPopisne: string | undefined): {
  ulice: string
  cisloPopisne: string
} {
  let { ulice, cisloPopisne } =
    splitUliceCisloPopisneZachovatCisloOrientacni(uliceCisloPopisne)

  return {
    ulice: ulice,
    cisloPopisne: cisloPopisne,
  }
}

export function addCommaInUliceCisloPopisne(uliceCisloPopisne: string): string {
  // add a comma between ulice and cislo popisne if uliceCisloPopisne does not contain a comma
  if (uliceCisloPopisne && !uliceCisloPopisne.includes(',')) {
    let { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne)
    return ulice + ', ' + cisloPopisne
  } else {
    return uliceCisloPopisne
  }
}

export function parsePsc(psc: string): number {
  return parseInt(psc.replace(/\s/g, ''))
}

export function parseUliceCisloPopisne(
  ulice: string,
  cisloPopisne: string
): string {
  // replace whitespaces with a single space + remove all commas
  ulice = ulice.replace(/\s+/g, ' ').replace(/,+/g, '')
  // .replace(/\s+$/, '')
  // .replace(/^\s+/, '')
  // .replace(/.*\s+/, '')
  if (!cisloPopisne) {
    return ulice
  } else {
    return ulice + ', ' + cisloPopisne
  }
}

export function parseUliceCisloPopisneWithoutReplace(
  ulice: string,
  cisloPopisne: string
): string {
  if (!cisloPopisne) {
    return ulice
  } else {
    return ulice + ', ' + cisloPopisne
  }
}

export function formatUliceCisloPopisne(uliceCisloPopisne: string): string {
  let { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne)
  // replace whitespaces with a single space + remove all commas
  ulice = ulice.replace(/\s+/g, ' ').replace(/,+/g, '')
  // .replace(/\s+$/, '')
  // .replace(/^\s+/, '')
  // .replace(/.*\s+/, '')
  if (!cisloPopisne) {
    return ulice
  } else {
    return ulice + ', ' + cisloPopisne
  }
}

export function procentualniPoplatek(fee: number): string {
  if (fee === 0) {
    return 'zdarma'
  }
  // if fee is integer, return it as a string, to avoid errors further down in the code when splitting by ('.')
  if (fee % 1 === 0) {
    // 5.5 % 2 = 1.5 https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder
    return fee.toString() + ' %'
  }

  // get second and third fractional digit
  const fractionalPart = fee.toString().split('.')[1]
  const firstFractionalDigit = fractionalPart[0]
  const secondFractionalDigit = fractionalPart[1] || '0'
  const thirdFractionalDigit = fractionalPart[2]
  const fourthFractionalDigit = fractionalPart[3]
  return `${
    +firstFractionalDigit > 0 ? firstFractionalDigit : ''
  } ${secondFractionalDigit}${
    thirdFractionalDigit ? ',' + thirdFractionalDigit : ''
  }${fourthFractionalDigit ? fourthFractionalDigit : ''} %`
}

// NORMALIZATION
export function bezDiakritiky(text: string | undefined): string {
  return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
}

export function equalsBezDiakritiky(
  text1: string,
  text2: string | undefined
): boolean {
  return bezDiakritiky(text1) === bezDiakritiky(text2)
}

export function bezDiakritikyInterpunkceSpecZnaku(
  text: string | undefined
): string {
  return text
    ? text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
    : ''
}

export function equalsBezDiakritikyInterpunkceSpecZnaku(
  text1: string,
  text2: string | undefined
): boolean {
  return (
    bezDiakritikyInterpunkceSpecZnaku(text1) ===
    bezDiakritikyInterpunkceSpecZnaku(text2)
  )
}

export function normalize(text: string | undefined): string {
  return text ? bezDiakritikyInterpunkceSpecZnaku(text).toLowerCase() : ''
}

// Normalizované equals = bez diakritiky, interpunkce a spec znaků v lowercase
export function equalsNormalize(
  text1: string,
  text2: string | undefined
): boolean {
  return normalize(text1) === normalize(text2)
}

export function findMatchingAddress(
  uliceCisloPopisne: string,
  addresses: Address[]
): Address | undefined {
  for (const address of addresses) {
    if (address.uliceCisloPopisne == uliceCisloPopisne) {
      return address
    }
  }

  // what if we auto add ',' between ulice and cislo popisne when saving from /after-login?
  const uliceCisloPopisne_ = addCommaInUliceCisloPopisne(uliceCisloPopisne)
  for (const address of addresses) {
    if (address.uliceCisloPopisne == uliceCisloPopisne_) {
      return address
    }
  }

  const normalizedUliceCisloPopisne = normalize(uliceCisloPopisne)
  for (const address of addresses) {
    if (
      equalsNormalize(address.uliceCisloPopisne, normalizedUliceCisloPopisne)
    ) {
      return address
    }
  }

  for (const address of addresses) {
    if (
      equalsBezRozdiluVCisluOrientacnim(
        address.uliceCisloPopisne,
        uliceCisloPopisne
      )
    ) {
      return address
    }
  }

  return undefined
}

export function equalsBezRozdiluVCisluOrientacnim(
  ulice1: string,
  ulice2: string | undefined
): boolean {
  if (!ulice2) {
    return false
  }
  let { ulice, cisloPopisne } = splitUliceCisloPopisne(ulice1)
  let { ulice: ulice2_, cisloPopisne: cisloPopisne2_ } =
    splitUliceCisloPopisne(ulice2)
  if (equalsNormalize(ulice, ulice2)) {
    return true
  } else {
    if (equalsNormalize(ulice, ulice2_)) {
      if (equalsNormalize(cisloPopisne, cisloPopisne2_)) {
        return true
      } else {
        // compare the numbers after /
        return (
          removeCisloOrientacni(cisloPopisne) ===
          removeCisloOrientacni(cisloPopisne2_)
        )
      }
    } else {
      return false
    }
  }
}

export function removeCisloOrientacni(cisloPopisne: string): string {
  return cisloPopisne.replace(/.*\//, '')
}

export function normalizeUCP(uliceCisloPopisne: string): string {
  let { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne)
  return ulice + ', ' + normalize(removeCisloOrientacni(cisloPopisne))
}

// A variant of Luhn-algorithm
export function validateCzechIcoControlNumber(ico: string): boolean {
  if (ico.length !== 8) {
    return false
  }
  const weights = [8, 7, 6, 5, 4, 3, 2]
  let sum = 0
  for (let i = 0; i < 7; i++) {
    sum += parseInt(ico.charAt(i)) * weights[i]
  }
  const remainder = sum % 11
  const controlNumber = (11 - remainder) % 10
  // console.log('sum, remainder, control number', sum, remainder, controlNumber)
  return controlNumber === parseInt(ico.charAt(7))
}
