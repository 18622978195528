import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'

export interface StepperState {
  farthestVisitedLocation: number
  userExists: boolean
}

let initialState: StepperState = {
  farthestVisitedLocation: 0, // 0 = offer and lead
  userExists: false,
}

export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    reducerUserExists(state, action: PayloadAction<boolean>) {
      state.userExists = action.payload
    },
    reducerCurrentLocation: (state, action: PayloadAction<number>) => {
      if (state.farthestVisitedLocation < action.payload)
        state.farthestVisitedLocation = action.payload
    },
    resetVisitedLocation: (state) => {
      state.farthestVisitedLocation = 0
    },
  },
})

export const {
  reducerUserExists,
  reducerCurrentLocation,
  resetVisitedLocation,
} = stepperSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFarthestVisitedLocation = (state: RootState) =>
  state.stepper.farthestVisitedLocation
export const selectUserExists = (state: RootState) => state.stepper.userExists

export default stepperSlice.reducer
