import React from 'react'
import { RootState } from 'app/store'
import { createSlice, current } from '@reduxjs/toolkit'

// transient - i.e., do not share with other users -> do not send to backend

export type DenniKreditZamestnance = {
  id: string
  kredit: number
}

const initialState: {
  zadaniStravKredituCastkaKreditu: boolean
  deaktivovaniActive: boolean
  denniKredit: number
  denniKredityZamestnancu: DenniKreditZamestnance[]
  empDetailRollover: boolean
  closedTips: string[]
  hiddenGroups: string[]
  babyDepartment: string
  babyUliceCisloPopisne: string
  activeImportRow: number
  activeImportColumn: number
  uploadedFileName: string
  cerstveProselOnboardingem: boolean
  cerstveDokoncilMigraci: boolean
  language: string
  hasError: boolean
  errorMessage: string
} = {
  zadaniStravKredituCastkaKreditu: true, // start with displaying Částka kreditu
  deaktivovaniActive: false,
  denniKredit: 0,
  denniKredityZamestnancu: [] as DenniKreditZamestnance[],
  empDetailRollover: false,
  closedTips: [],
  hiddenGroups: [],
  babyDepartment: '',
  babyUliceCisloPopisne: '',
  activeImportRow: 0,
  activeImportColumn: 0,
  uploadedFileName: '',
  cerstveProselOnboardingem: false,
  cerstveDokoncilMigraci: false,
  language: 'cz',
  hasError: false,
  errorMessage: '',
}

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    reducerCerstveProselOnboardingem: (state, action) => {
      state.cerstveProselOnboardingem = action.payload
    },
    reducerCerstveDokoncilMigraci: (state, action) => {
      state.cerstveDokoncilMigraci = action.payload
    },
    reducerZadaniStravKredituCastkaKreditu: (state, action) => {
      state.zadaniStravKredituCastkaKreditu = action.payload
    },
    reducerDeaktivovaniActive(state, action) {
      state.deaktivovaniActive = action.payload
    },
    reducerDenniKredit: (state, action) => {
      // const newState = Object.assign({}, action.payload)
      // console.log('reducerDenniKredit newState', newState)
      state.denniKredit = action.payload
    },
    addDenniKreditZamestnance(state, action) {
      state.denniKredityZamestnancu.push(action.payload)
    },
    eraseDenniKredityZamestnancu(state) {
      state.denniKredityZamestnancu = []
    },
    reducerRemoveDenniKreditZamestnance: (state, action) => {
      const { id } = action.payload
      const newState = state.denniKredityZamestnancu.filter(
        (denniKreditZamestnance) => denniKreditZamestnance.id !== id
      )
      state.denniKredityZamestnancu = newState
    },
    reducerAddDenniKreditZamestnance: (state, action) => {
      // console.log('reducerAddDenniKreditZamestnance', action.payload)
      state.denniKredityZamestnancu = [
        ...state.denniKredityZamestnancu,
        action.payload,
      ]
      // console.log(current(state))
    },
    reducerEmpDetailRollover: (state, action) => {
      state.empDetailRollover = action.payload
    },
    reducerCloseTip: (state, action) => {
      if (!state.closedTips) {
        state.closedTips = []
      }
      state.closedTips.push(action.payload)
    },
    reducerNewBornEmployeeDepartment: (state, action) => {
      state.babyDepartment = action.payload
    },
    reducerNewBornEmployeeUliceCisloPopisne: (state, action) => {
      state.babyUliceCisloPopisne = action.payload
    },
    reducerSetLanguage: (state, action) => {
      state.language = action.payload
    },
    reducerHasError: (state, action) => {
      state.hasError = action.payload
    },
    reducerErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
  },
})

export const {
  reducerCerstveProselOnboardingem,
  reducerCerstveDokoncilMigraci,
  reducerZadaniStravKredituCastkaKreditu,
  reducerDeaktivovaniActive,
  reducerDenniKredit,
  reducerAddDenniKreditZamestnance,
  reducerRemoveDenniKreditZamestnance,
  eraseDenniKredityZamestnancu,
  reducerEmpDetailRollover,
  reducerCloseTip,
  reducerNewBornEmployeeDepartment,
  reducerNewBornEmployeeUliceCisloPopisne,
  reducerSetLanguage,
  reducerHasError,
  reducerErrorMessage,
} = userSettingsSlice.actions

export const userSettingsActions = userSettingsSlice.actions

export const selectUserSettings = (state: RootState) => state.userSettings
export const selectCerstveProselOnboardingem = (state: RootState) =>
  state.userSettings.cerstveProselOnboardingem
export const selectCerstveDokoncilMigraci = (state: RootState) =>
  state.userSettings.cerstveDokoncilMigraci
export const selectDeaktivovaniActive = (state: RootState) =>
  state.userSettings.deaktivovaniActive
export const selectDenniKredit = (state: RootState) =>
  state.userSettings.denniKredit
export const selectDenniKredityZamestnancu = (state: RootState) =>
  state.userSettings.denniKredityZamestnancu
export const selectZadaniStravKredituCastkaKreditu = (state: RootState) =>
  state.userSettings.zadaniStravKredituCastkaKreditu
export const selectEmpDetailRollover = (state: RootState) =>
  state.userSettings.empDetailRollover
export const selectClosedTips = (state: RootState) =>
  state.userSettings.closedTips
export const selectLanguage = (state: RootState) => state.userSettings.language
export const selectHasError = (state: RootState) =>
  state.userSettings.hasError || false
export const selectErrorMessage = (state: RootState) =>
  state.userSettings.errorMessage

export default userSettingsSlice.reducer
