import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import SideMenu from 'components/modules/menu/SideMenu'
import { urlDataImportFour, urlDataImportSix } from 'App'
import { useBodyClass, useAppDispatch, useAppSelector } from 'app/hooks'
import {
  activeImportRow,
  uploadedFileName,
  reducerSetImportedRows,
  reducerChangesAcceptance,
  reducerBadIndexToRealIndex,
} from './importSettingsSlice'
import { ExcelRow, ImportRow } from './dataImportSlice'
import { Navigate } from 'react-router'
import { numberWithSpaces } from 'app/util'
import Tooltip from 'components/modules/tooltip/Tooltip'
import {
  equalsBezRozdiluVCisluOrientacnim,
  equalsNormalize,
  formatPsc,
} from 'util/ValidFormatOps'
import { GHOST_INDEX } from 'app/constants'
import CheckCircleIcon from '@styles/icon/checkCircleIcon'
import CloseCircleFilledIcon from '@styles/icon/closeCircleFilledIcon'
import HappyFace from '@styles/image/device-woman2.png'
import { Color } from 'app/colors'

const DataImportFive: FC = () => {
  useBodyClass('u-bg-light-grey')

  const dispatch = useAppDispatch()
  const redux = useAppSelector((state) => state.dataImport)
  const importSettings = useAppSelector((state) => state.importSettings)
  const changesAcceptance = importSettings.changesAcceptance
  const activeRow = useAppSelector(activeImportRow)
  const fileName = useAppSelector(uploadedFileName)
  const [hover, setHover] = useState(-1)
  const [operatingTable, setOperatingTable] = useState<ImportRow[]>([]) // the stripped table of the uploaded file which we operate upon here
  const employees = useAppSelector((state) => state.table)
  const [changes, setChanges] = useState<Array<String>>([])
  const [redirect, setRedirect] = useState(false)
  const [filtering, setFiltering] = useState('all')

  const acceptedRows = changesAcceptance.filter(
    (acceptance) => acceptance === 'accepted'
  )
  const rejectedRows = changesAcceptance.filter(
    (acceptance) => acceptance === 'rejected'
  )

  const isBadOrNoIdRow = useCallback(
    (row: ImportRow): boolean => {
      if (
        row.cisloZamestnance &&
        employees.findIndex((e) => e.id === row.cisloZamestnance) === -1
      ) {
        return false // = valid new row, what can we do... ?
      }
      return (
        !row.cisloZamestnance ||
        !equalsNormalize(
          row.jmeno,
          employees.find((e) => e.id === row.cisloZamestnance)?.name
        ) ||
        !equalsNormalize(
          row.prijmeni,
          employees.find((e) => e.id === row.cisloZamestnance)?.surname
        ) ||
        !equalsBezRozdiluVCisluOrientacnim(
          row.uliceCisloPopisne,
          employees.find((e) => e.id === row.cisloZamestnance)
            ?.uliceCisloPopisne
        )
      )
    },
    [employees]
  )

  const badTable = operatingTable.filter((row) => isBadOrNoIdRow(row))
  const noIdRows = badTable.filter((row) => !row.cisloZamestnance)
  const pocetSpatnychRadku = badTable.length - noIdRows.length
  const pocetNevyresenychRadku =
    pocetSpatnychRadku - acceptedRows.length - rejectedRows.length

  const badIndexToRealIndex: number[] = useMemo(
    () =>
      operatingTable.map((row, index) => {
        if (isBadOrNoIdRow(row)) {
          // we actually want to return the index of the bad row only, not of the row with no id
          return row.cisloZamestnance ? index : GHOST_INDEX
        }
        return GHOST_INDEX
      }),
    [badTable, employees]
  )
  // [])

  const nameChangeRows = badTable
    .filter(
      (row) =>
        !equalsNormalize(
          row.jmeno,
          employees.find((e) => e.id === row.cisloZamestnance)?.name
        ) ||
        !equalsNormalize(
          row.prijmeni,
          employees.find((e) => e.id === row.cisloZamestnance)?.surname
        )
    )
    .filter((row) => row.cisloZamestnance)
  const addressChangeRows = badTable
    .filter(
      (row) =>
        !equalsBezRozdiluVCisluOrientacnim(
          row.uliceCisloPopisne,
          employees.find((e) => e.id === row.cisloZamestnance)
            ?.uliceCisloPopisne
        )
    )
    .filter((row) => row.cisloZamestnance)

  console.log('badTable', badTable)

  function isRowInCorrectFilter(row: ImportRow, filter: string): boolean {
    if (filter === 'all') return true
    if (filter === 'no-id') return !row.cisloZamestnance
    if (filter === 'name-change') return nameChangeRows.includes(row)
    if (filter === 'address-change') return addressChangeRows.includes(row)
    return false
  }

  useEffect(() => {
    // Perf: shouldn't we do this in transition from DI4 to DI5? Because now it happens on every reload.
    // or at least set the state dependency.
    // funny enough, if we add [importSettings] as the dependency, it stops working.
    // but works with [redux]
    // it will work with importSettings.changesAcceptance
    // transform from ExcelRow to ImportRow (redux to useState)
    // ofc it didn't work with [importSettings] - it reseted every time.
    // with [] empty dependency it resets only on page reload. Which might be good.
    // but better will be if it doesn't reset. Only on file reload, not page reload.
    let newTable: ImportRow[] = []
    redux
      .slice(activeRow + 1 /* +1 for user's headers which we omit now */)
      .forEach((row: ExcelRow) => {
        let oldRow = row as unknown as string[]
        let newRow: ImportRow = {
          jmeno: oldRow[importSettings.jmenoColumn],
          prijmeni: oldRow[importSettings.prijmeniColumn],
          cisloZamestnance: oldRow[importSettings.cisloZamestnanceColumn],
          nazevSpolecnosti: oldRow[importSettings.nazevSpolecnostiColumn],
          uliceCisloPopisne: oldRow[importSettings.uliceCisloPopisneColumn],
          mesto: oldRow[importSettings.mestoColumn],
          psc: oldRow[importSettings.pscColumn],
          kontaktniOsoba: oldRow[importSettings.kontaktniOsobaColumn],
          telefon: oldRow[importSettings.telefonColumn],
          volnocasovyKredit: oldRow[importSettings.volnocasovyKreditColumn],
          stravenkovyKredit: oldRow[importSettings.stravenkovyKreditColumn],
        }
        newTable.push(newRow)
      })
    setOperatingTable(newTable)
    // persist for DataImportSummary
    dispatch(reducerSetImportedRows(newTable))

    // what we want to do on useEffect (page enter/leave):
    // 1. check if the state is changed - some rows rejected or accepted
    // 2. if yes, then we persist the state
    // 3. if no, then we load the persisted state and apply it to useState.
    // 4. the persisted state must be cleared either upon successful completion or load of new file.
    let isStateAltered = false
    for (let i = 0; i < pocetSpatnychRadku; i++) {
      if (changes[i] !== 'not-checked') {
        isStateAltered = true
        break
      }
    }
    if (isStateAltered) {
      dispatch(reducerChangesAcceptance(changes))
    } else {
      // load persisted state
      let persistedState = importSettings.changesAcceptance
      // set it to useState if exists or create new state
      if (persistedState) {
        setChanges(persistedState)
      } else {
        let changesLocal: String[] = []
        newTable.forEach((_) => {
          changesLocal.push('not-checked')
        })
        setChanges(changesLocal)
      }
    }

    // for (let i = 0; i < table.length; i++) {
    //   if (isBadRow(table[i])) {
    //     badIndexToRealIndex.push(i)
    //   }
    // }
    dispatch(reducerBadIndexToRealIndex(badIndexToRealIndex))
  }, [
    changes,
    activeRow,
    // badIndexToRealIndex, // this triggers on every reload
    isBadOrNoIdRow,
    pocetSpatnychRadku,
    redux,
    // operatingTable, // this triggers on every reload
    importSettings.changesAcceptance,
    importSettings.cisloZamestnanceColumn,
    importSettings.jmenoColumn,
    importSettings.prijmeniColumn,
    importSettings.volnocasovyKreditColumn,
    importSettings.stravenkovyKreditColumn,
    importSettings.nazevSpolecnostiColumn,
    importSettings.kontaktniOsobaColumn,
    importSettings.telefonColumn,
    importSettings.uliceCisloPopisneColumn,
    importSettings.mestoColumn,
    importSettings.pscColumn,
    dispatch,
  ])

  function cellClass(index: number, row: ImportRow, column: string): string {
    let opacitySuffix: string = ''
    const existing = employees.find((e) => e.id === row.cisloZamestnance)
    switch (column) {
      case 'jmeno':
        opacitySuffix = !equalsNormalize(row.jmeno, existing?.name)
          ? '-50'
          : '-20'
        break
      case 'prijmeni':
        opacitySuffix = !equalsNormalize(row.prijmeni, existing?.surname)
          ? '-50'
          : '-20'
        break
      case 'uliceCisloPopisne':
        opacitySuffix = !equalsBezRozdiluVCisluOrientacnim(
          row.uliceCisloPopisne,
          existing?.uliceCisloPopisne
        )
          ? '-50'
          : '-20'
        break
      // default = cisloZamestnance
      default:
        opacitySuffix = '-20'
    }
    // if the row doesn't exist in our db yet, the opacity should be standard, except for the id field - opacity 50
    !existing && (opacitySuffix = column === 'cisloZamestnance' ? '-50' : '-20')

    return (
      'table__cell table__cell--fixed u-text-ellipsis ' +
      (!existing && column === 'cisloZamestnance'
        ? 'u-bg-aquamarine'
        : (changes[index] === 'rejected'
            ? 'u-bg-table-red'
            : changes[index] === 'accepted'
            ? 'u-bg-aquamarine'
            : 'u-bg-new-yellow') + opacitySuffix)
    )
  }

  function btnClass(filter: string): string {
    let suffix = ''
    switch (filter) {
      case 'all':
        suffix = filtering === 'all' ? '' : 'btn--outlined'
        break
      case 'no-id':
        suffix = filtering === 'no-id' ? '' : 'btn--outlined'
        break
      case 'name-change':
        suffix = filtering === 'name-change' ? '' : 'btn--outlined'
        break
      case 'address-change':
        suffix = filtering === 'address-change' ? '' : 'btn--outlined'
        break
      default:
        suffix = 'btn--outlined'
        break
    }
    return 'btn u-mr-1 u-mr-large-3 u-mb-1 btn--secondary btn--tiny ' + suffix
  }

  function rejectedRowsWithNoId() {
    let count = 0
    for (let i = 0; i < badTable.length; i++) {
      if (
        (badTable[i].cisloZamestnance === undefined ||
          badTable[i].cisloZamestnance === '') &&
        changesAcceptance[i] === 'rejected'
      ) {
        count++
      }
    }
    return count
  }

  const tooltipContent = (row: ImportRow) => (
    <p className='u-size-s'>
      {`Společnost ${row.nazevSpolecnosti}, město ${row.mesto}, psč ${formatPsc(
        row.psc
      )},`}{' '}
      <br />
      {`kontaktní osoba ${row.kontaktniOsoba}, telefon ${numberWithSpaces(
        row.telefon
      )}.`}
    </p>
  )

  return (
    <div>
      {redirect && <Navigate to={urlDataImportSix} />}
      <div className='table__import'>
        <div className='grid-x align-justify align-bottom u-mb-2'>
          <div className='u-mb-1'>
            <h1 className='u-size-l28 u-mb-2'>Revize dat</h1>
            <p className='u-size-l20'>
              Následující údaje v importním souboru se liší od původních v
              aplikaci.
              <br />
              <CheckCircleIcon
                width={20}
                height={20}
                fill={Color.AQUAMARINE}
              />{' '}
              Přijmutím řádku nahradíte zvýrazněné údaje v aplikaci.{' '}
              <CloseCircleFilledIcon
                width={20}
                height={20}
                fill={Color.RED_PRIMARY}
              />{' '}
              Odmítnutím zůstane původní údaj v aplikaci zachován.
            </p>
          </div>
          <div className='u-mb-1'>
            <div className='alert alert--info alert--visible'>
              <div className='alert__inner'>
                <div className='grid-x align-middle'>
                  <span className='alert__text'>
                    Použito: {acceptedRows.length}, Zahozeno:{' '}
                    {rejectedRows.length}, Zbývá vyřešit:{' '}
                    {pocetNevyresenychRadku}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* HORNI MENU BUTTONS */}
        <div className='grid-x align-left u-mb-2'>
          <div className='u-mr-6'>
            <button
              onClick={() => setFiltering('all')}
              className={btnClass('all')}
            >
              <b>Vše ({pocetSpatnychRadku + noIdRows.length})</b>
            </button>
            <button
              onClick={() => setFiltering('name-change')}
              className={btnClass('name-change')}
            >
              <b>Změna jména ({nameChangeRows.length})</b>
            </button>
            <button
              onClick={() => setFiltering('no-id')}
              className={btnClass('no-id')}
            >
              <b>Záznamy bez ID ({noIdRows.length})</b>
            </button>
            <div className='btn__info'>
              {/* otazníček nad Změna adresy */}
              {/* <div
                onMouseOver={() => setHover(-2)}
                onMouseLeave={() => setHover(-1)}
                className='btn__icon'
              >
                <svg className='icon icon--16'>
                  <use href={icons + '#sprite-question-circle-outline'}></use>
                </svg>
                {hover === -2 && (
                  <div className='btn__hover'>
                    <p className='u-size-s'>
                      Přijmutím změn v adrese vytvoříte nebo změníte doručovací
                      adresu vázanou na zaměstnance.
                    </p>
                  </div>
                )}
              </div> */}
              <button
                onClick={() => setFiltering('address-change')}
                className={btnClass('address-change')}
              >
                <div
                  onMouseOver={() => setHover(-2)}
                  onMouseLeave={() => setHover(-1)}
                  className='btn__icon'
                ></div>
                <b>Změna adresy ({addressChangeRows.length})</b>
              </button>
            </div>
          </div>
          {badTable.length > 0 && (
            <div>
              {/* RIGHT BUTTONS - Použít/zahodit vše */}
              <button
                onClick={() => {
                  let newChanges = changes.slice(0)
                  badTable.forEach((row, i) => {
                    if (row.cisloZamestnance) {
                      if (isRowInCorrectFilter(row, filtering)) {
                        newChanges[i] = 'accepted'
                      }
                    } else {
                      // of course, the forEach cycle increments this for us...
                      // we keep this commentary and else branch here to prevent future hard-to-detect bugs
                      // caused by simplifying this code into a .filter().forEach()
                      // i++ (no-id element is still a part of the bad table)
                    }
                  })
                  setChanges(newChanges)
                }}
                className='btn u-mb-1 btn--aquamarine btn--tiny'
              >
                Použít vše{' '}
              </button>
              <button
                onClick={() => {
                  let newChanges = changes.slice(0)
                  badTable.forEach((row, i) => {
                    if (row.cisloZamestnance) {
                      if (isRowInCorrectFilter(row, filtering)) {
                        newChanges[i] = 'rejected'
                      }
                    } else {
                      // ibid. - see above (Použít vše button) comment for explanation
                      // i++ (no-id element is still a part of the bad table)
                    }
                  })
                  setChanges(newChanges)
                }}
                className='btn u-mb-1 u-ml-1 btn--primary btn--tiny'
              >
                Zahodit vše
              </button>
            </div>
          )}
        </div>
        {/* TABLE */}
        {badTable.length > 0 ? (
          <div className='table__overflow u-pl-6'>
            <div className='box box--small-radius box--no-padding box--transparent u-mb-9'>
              <div className='box__content u-overflow-visible'>
                <div
                  role='table'
                  aria-label='Spárování sloupců'
                  className='table table--compact'
                >
                  {/* HEADER */}
                  <div role='row' className='table__row'>
                    <div
                      className='table__cell table__cell--small u-bg-white'
                      style={{
                        borderTopLeftRadius: '14px',
                        borderBottomLeftRadius: `${
                          badTable.length > 0 ? '0px' : '14px'
                        }`,
                      }}
                    ></div>
                    <div className='table__cell table__cell--fixed u-text-ellipsis'>
                      Jméno
                    </div>
                    <div className='table__cell table__cell--fixed u-text-ellipsis'>
                      Příjmení
                    </div>
                    <div className='table__cell table__cell--fixed u-text-ellipsis'>
                      Číslo zaměstnance (ID)
                    </div>
                    <div
                      style={{
                        borderTopRightRadius: '14px',
                        borderBottomRightRadius: `${
                          badTable.length > 0 ? '0px' : '14px'
                        }`,
                      }}
                      className='table__cell table__cell--fixed u-text-ellipsis'
                    >
                      Ulice, č. popisné
                    </div>
                  </div>
                  {/* BODY */}
                  {badTable.map(
                    (row, index) =>
                      isRowInCorrectFilter(row, filtering) && (
                        <div role='row' className='table__row' key={index}>
                          {/* LEFT SIDE BUTTONS */}
                          {!noIdRows.includes(row) && (
                            <div className='table__buttons'>
                              <svg
                                onClick={() => {
                                  let newChanges = changes.slice(0)
                                  newChanges[index] =
                                    newChanges[index] === 'accepted'
                                      ? 'not-checked'
                                      : 'accepted'
                                  setChanges(newChanges)
                                }}
                                className='icon icon--16'
                              >
                                <use
                                  href={icons + '#sprite-check-circle'}
                                ></use>
                              </svg>
                              <svg
                                onClick={() => {
                                  let newChanges = changes.slice(0)
                                  newChanges[index] =
                                    newChanges[index] === 'rejected'
                                      ? 'not-checked'
                                      : 'rejected'
                                  setChanges(newChanges)
                                }}
                                className='icon icon--16'
                                style={{ color: 'rgb(227, 43, 36)' }}
                              >
                                <use
                                  href={icons + '#sprite-close-circle-filled'}
                                ></use>
                              </svg>
                            </div>
                          )}
                          <div
                            style={{
                              borderBottomLeftRadius:
                                index ===
                                pocetSpatnychRadku + noIdRows.length - 1
                                  ? '14px'
                                  : '0px',
                            }}
                            className='table__cell table__cell--small u-bg-white'
                          >
                            {index + 1}
                          </div>
                          <div className={cellClass(index, row, 'jmeno')}>
                            {row.jmeno}
                          </div>
                          <div className={cellClass(index, row, 'prijmeni')}>
                            {row.prijmeni}
                          </div>
                          <div
                            className={cellClass(
                              index,
                              row,
                              'cisloZamestnance'
                            )}
                          >
                            {!row.cisloZamestnance ? (
                              <i>- bude přiděleno -</i>
                            ) : (
                              row.cisloZamestnance
                            )}
                          </div>
                          <div
                            style={{
                              borderBottomRightRadius:
                                index ===
                                pocetSpatnychRadku + noIdRows.length - 1
                                  ? '14px'
                                  : '0px',
                            }}
                            className={cellClass(
                              index,
                              row,
                              'uliceCisloPopisne'
                            )}
                          >
                            {row.uliceCisloPopisne && (
                              <div
                                onMouseOver={() => setHover(index)}
                                onMouseLeave={() => setHover(-1)}
                                className='btn__icon'
                                style={{
                                  top: '0.6rem',
                                  right: '2rem',
                                }}
                              >
                                <svg className='icon icon--16'>
                                  <use
                                    href={
                                      icons + '#sprite-question-circle-outline'
                                    }
                                  ></use>
                                </svg>
                                {hover === index && (
                                  <Tooltip children={tooltipContent(row)} />
                                )}
                              </div>
                            )}
                            <>{row.uliceCisloPopisne}</>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className='u-text-center u-mt-5 u-mr-8'
            style={{
              backgroundColor: Color.GREYLIGHT,
            }}
          >
            <p className='u-size-l20 u-mb-5'>
              Všechna data jsou validní.
              <br />
              Pokračujte dále.
            </p>{' '}
            <img
              src={HappyFace}
              // srcSet={`${HappyFace} 1x, ${HappyFace2x} 2x`}
              width='376'
              height='auto'
              alt='Nebyl nalezen žádný záznam'
            />
          </div>
        )}
      </div>
      <div className='u-fixed-bottom u-mb-3 u-px-3'>
        <div className='grid-x align-justify u-ml-5 u-mr-3'>
          <div className='grid-x align-middle u-mb-1'>
            <svg className='icon u-mr-2'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>
            <i>{fileName}</i>
          </div>
          <div className='grid-x align-middle u-mb-1'>
            <a href={urlDataImportFour} className='u-mr-3'>
              Zpět
            </a>
            <button
              onClick={() => {
                setRedirect(true)
              }}
              className={`btn u-px-2 btn--primary btn--small ${
                pocetNevyresenychRadku + rejectedRowsWithNoId() > 0 &&
                'btn--disabled'
              }`}
              disabled={pocetNevyresenychRadku + rejectedRowsWithNoId() > 0}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-check'}></use>
              </svg>
              Pokračovat
            </button>
          </div>
        </div>
      </div>
      <div className='modal'>
        <div className='modal__body'>
          <div className='modal__header'>
            <div className='modal__close'>
              <span>Zavřít</span>
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-delete'}></use>
              </svg>
            </div>
          </div>
          <div className='modal__subheader'></div>
          <div className='modal__content'>
            <div>
              <div className='highlight u-mb-4 u-py-3 highlight--narrow'>
                <div className='highlight__content'>
                  <h3 className='h3 u-mb-5'>Adresa pro doručení karet</h3>
                  <div className='input u-mb-4 input--required'>
                    <label className='input__label'>Název společnosti</label>
                    <div className='input__wrapper'>
                      <input placeholder='Např.: Pluxee.cz' type='text' />
                    </div>
                  </div>
                  <div className='input u-mb-4 input--required'>
                    <label className='input__label'>Kontaktní osoba</label>
                    <div className='input__wrapper'>
                      <input placeholder='Např.: Karel Havlíček' type='text' />
                    </div>
                  </div>
                  <div className='input u-mb-4 input--required'>
                    <label className='input__label'>Ulice, číslo popisné</label>
                    <div className='input__wrapper'>
                      <input placeholder='Např.: Pražská 1000' type='text' />
                    </div>
                  </div>
                  <div className='grid-x u-mb-4'>
                    <div className='cell small-7 u-pr-small-2'>
                      <div className='input input--required'>
                        <label className='input__label'>Město</label>
                        <div className='input__wrapper'>
                          <input placeholder='Např.: Praha 10' type='text' />
                        </div>
                      </div>
                    </div>
                    <div className='cell small-5'>
                      <div className='input input--required'>
                        <label className='input__label'>PSČ</label>
                        <div className='input__wrapper'>
                          <input placeholder='Např.: 101 00' type='text' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='input u-mb-4 input--required'>
                    <label className='input__label'>Telefon</label>
                    <div className='input__wrapper'>
                      <input placeholder='Např.: 777 888 999' type='text' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='u-text-center'>
                <a className='btn u-mb-2 btn--primary btn--small btn--wide'>
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-check'}></use>
                  </svg>
                  Zadat adresu
                </a>
                <a className='btn btn--primary btn--outlined btn--small btn--wide'>
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-delete-thin'}></use>
                  </svg>
                  Zrušit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}
export default DataImportFive
