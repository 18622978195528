import React from 'react'
// import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom/client'
import './index.css'
import './redesign.css'
import '@styles/dist/system/system.css'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import * as serviceWorker from './serviceWorker'
import { render } from 'react-dom'
import CustomErrorBoundary from 'components/modules/errorhandler/CustomErrorBoundary'

let persistor = persistStore(store)
// const container = document.getElementById('root') as HTMLElement
// const root = createRoot(container)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode> // StrictMode: in strict mode while in development, React calls your reducer twice. This double invocation is React helping you uncover impurities in your reducer. The reducer must be pure: given the same data, it should return the same results. This is a great (again, development-only) feature and you should be on the lookout for impurities. Once you build your app, there’s no more want for double-calling.
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CustomErrorBoundary>
        <App />
      </CustomErrorBoundary>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
