import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class EmployeeIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'employee-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.5 13c2.468 0 4.5-2.024 4.5-4.559V6.56C21 4.024 18.968 2 16.5 2S12 4.024 12 6.559V8.44c0 2.536 2.032 4.56 4.5 4.56zm0 2c3.59 0 6.5-2.936 6.5-6.559V6.56C23 2.936 20.09 0 16.5 0S10 2.936 10 6.559V8.44c0 3.624 2.91 6.56 6.5 6.56zm12.425 12.078C28.367 22.361 24.785 19 20.818 19h-.976l-.97 1.772 2.033 4.33a1 1 0 01-.028.905l-2.137 3.9a27.684 27.684 0 008.622-2.158l1.563-.671zm-11.809 4.915c3.744-.08 7.48-.882 11.034-2.406L31 28.364C31 22.09 26.442 17 20.818 17h-8.636C6.558 17 2 22.087 2 28.364l2.766 1.195a29.515 29.515 0 0012.154 2.438 1.006 1.006 0 00.196-.004zm-1.819-2.02l-2.174-3.966a1 1 0 01-.028-.906l2.034-4.33L14.158 19h-1.976c-3.968 0-7.552 3.36-8.107 8.081l1.484.642a27.601 27.601 0 009.738 2.25zM16.438 19L17 20.025 17.562 19h-1.124zm-1.317 6.491L17 21.492l1.879 4L17 28.918l-1.879-3.428z'
          fill='currentColor'
        />
      </svg>
    )
  }
}

export default EmployeeIcon
