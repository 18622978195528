import React, { useEffect, useState } from 'react'
import TrashIcon from '@styles/icon/trashIcon'
import { useAppDispatch, useAppSelector, useModal } from 'app/hooks'
import { getColorCardStatus } from 'util/colorOps'
import { Modal } from 'components/modules/modal/Modal'
import { MultiPassCard, MultiPassCardState } from 'util/CardOps'
import { formatDate, formatDateAndTime } from 'util/DateOps'
import { selectRowById, tableActions } from 'components/table/tableSlice'
import ShoppingCartIcon from '@styles/icon/shoppingCartIcon'
import { urlOrdersOverview } from 'App'
import { verne } from 'app/julesverne'
import { lg } from 'app/facade'
import {
  CARD_DETAILS,
  CARD_IN_CART,
  NEW_F,
  SUBSTITUTE,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface Props {
  employeeId: string
}

/* A component representing and displaying all cards on a given employee detail side panel. */
const DetailsOfCards: React.FC<Props> = ({ employeeId }) => {
  const employee = useAppSelector((state) =>
    state.table.filter((e) => e.id === employeeId)
  )[0]
  // this check prevents error which was triggered after employee was deleted
  const cards = employee ? employee.cards : []
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div>
      <div className='highlight u-mb-3 u-py-3 u-px-2'>
        <div className='highlight__content'>
          <p className='u-mb-2'>
            <b>{lg(lang, CARD_DETAILS)}</b> (Karta Multibenefit)
          </p>
          {console.log('Cards %O', cards)}
          {cards.map((card, i) => (
            <CardDetail
              key={card.id || i}
              id={employeeId}
              card={card}
              cardIndex={i}
            />
          ))}
          {employee && employee.cardStatus === MultiPassCardState.VKošíku && (
            <p
              className={`u-mt-3 u-mb-1 ${getColorCardStatus(
                MultiPassCardState.VKošíku
              )}`}
            >
              <b>
                {employee.objednatNahradniKartu
                  ? lg(lang, SUBSTITUTE)
                  : lg(lang, NEW_F)}
                &nbsp;{lg(lang, CARD_IN_CART)}
              </b>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

interface CardProps {
  id: string
  card: MultiPassCard
  cardIndex: number
}

const CardDetail: React.FC<CardProps> = ({
  id,
  card,
  cardIndex,
}: CardProps) => {
  const { isShown: isShownResetPin, toggle: toggleResetPin } = useModal()

  const colorCardStatus = getColorCardStatus(card.status.toString())

  const [currentlyResetting, setCurrentlyResetting] = React.useState({
    cardId: '',
    volnocasOrStravovani: '',
  })

  function resetPin(currentlyResetting: {
    cardId: string
    volnocasOrStravovani: string
  }) {
    // callBackendResetPin(
    //   currentlyResetting.cardId,
    //   currentlyResetting.volnocasOrStravovani
    // )
  }

  const dispatch = useAppDispatch()

  const { isShown: isShownBlokaceKarty, toggle: toggleBlokaceKarty } =
    useModal()

  const { isShown: isShownBylaZablokovana, toggle: toggleBylaZablokovana } =
    useModal()

  function blokovatKartu() {
    dispatch(tableActions.blokovatKartu({ id: id, cardIndex: cardIndex }))
    callBackendBlokovatKartu()
  }

  function callBackendBlokovatKartu() {
    return
  }

  // duplicated in EmployeeDetail.tsx -> we need it here because when the card gets blocked we offer immediate order of a new replacement card
  function objednatNahradniKartu(employeeId: string) {
    dispatch(tableActions.objednatNahradniKartu({ id: employeeId }))
    dispatch(
      tableActions.setCardStatus({
        id: employeeId,
        cardStatus: MultiPassCardState.VKošíku,
      })
    )
    callBackendObjednatNahradniKartu(employeeId)
  }

  function callBackendObjednatNahradniKartu(employeeId: string) {
    return
  }

  const employee = useAppSelector(selectRowById(id))

  const { isShown: isShownObjednatNovou, toggle: toggleObjednatNovou } =
    useModal()

  return (
    <>
      <div className='u-py-2'>
        <p className='grid-x u-size-base14'>
          <span className='cell auto'>{card.type === 'GPC' ? 'Stravování' : 'Volný čas'}</span>
          <span className='cell shrink u-text-right u-ml-1'>
            {card.cardNumber ? (
                <b>{card.cardNumber}</b>
            ) : (
                <i>karta se připravuje</i>
            )}
          </span>
        </p>
        {card.status === MultiPassCardState.VeVýrobě && (
          <p className='grid-x u-size-base14 u-mt-2'>
            <span className='cell auto'>Karta byla objednána</span>
            <span className='cell shrink u-text-right u-ml-1'>
              <b>{formatDateAndTime(card.datumObjednani)}</b>
              <br />
              {'  '}
              <a
                href={`${urlOrdersOverview}/#${card.orderId}`}
                className='u-size-s'
              >
                {/* if the orderId is not resolved yet (was a bug in old model), and object is passed in, the following error will be thrown */}
                {/* Uncaught Error: Objects are not valid as a React child (found: object with keys {}). If you meant to render a collection of children, use an array instead. */}
                {/* obj. č. {card.orderId} */}

                {verne(card.orderId)}
                {/* this one works even with the error, and displays "obj. č. [object Object]" */}
                {`obj. č. ${card.orderId}`}
              </a>
            </span>
          </p>
        )}
        <p className='grid-x u-size-base14 u-mt-2'>
          <span className='cell auto'>Stav karty</span>
          <span className='cell shrink u-text-right'>
            <b className={colorCardStatus}>{card.status}</b>
            <br />
            {(card.status === MultiPassCardState.VeVýrobě ||
              card.status === MultiPassCardState.Aktivní) && (
              <span className='u-size-s'>
                expirace {formatDate(card.datumExpirace)}, tj. za více než rok
              </span>
            )}
          </span>
        </p>
        <div className='grid-x u-mt-1 u-ml-0 u-mb-0 u-pb-0'>
          {/* Todo re-enable Objednat náhradní kartu.
          24.5.2022 Objednat náhradní kartu feature hidden by request from Petra - because backend doesn't support it yet. */}
          {/* <div className='cell medium-4'>
            {cardIndex === employee!.cards.length - 1 &&
              employee!.cardStatus !== MultiPassCardState.VKošíku && (
                <div className='cell shrink u-mt-1'>
                  <a
                    onClick={() => {
                      toggleObjednatNovou()
                    }}
                    className='btn u-size-base14 btn--primary btn--small'
                  >
                    <svg
                      className='icon icon--24'
                      style={{ marginLeft: '-4px' }}
                    >
                      <ShoppingCartIcon width={24} height={24} />
                    </svg>
                    Objednat
                  </a>
                </div>
              )}
          </div> */}
          <div className='cell medium-1'></div>
          {card.status !== MultiPassCardState.Blokovaná &&
            card.status !== MultiPassCardState.Expirovaná &&
            card.status !== MultiPassCardState.VeVýrobě && (
              <div
                // todo re-enable, invisible because SPO doesn't support it yet ORDTEX-173
                style={{ display: 'none' }}
                className='cell medium-7 u-ml-0 u-pr-0 u-pl-1'
              >
                <a
                  onClick={() => {
                    toggleBlokaceKarty()
                  }}
                  className='btn u-size-base14 u-px-2 btn--primary btn--outlined btn--small'
                >
                  <TrashIcon />
                  Zablokovat kartu
                </a>
              </div>
            )}
        </div>
      </div>
      {/* Blokace karty - Modal */}
      <Modal
        isShown={isShownBlokaceKarty}
        hide={toggleBlokaceKarty}
        headerText='Zavřít'
        subheaderText='Blokace karty'
        modalContent={
          <div className='modal__content'>
            <p className='u-size-base14 u-mt-2'>
              Uživatel nebude moc nadále manipulovat a čerpat prostředky ze
              svého účtu prostřednictvím karty.
            </p>
            <p className='u-size-base14 u-mt-3'>
              Opravdu chcete zablokovat kartu?
            </p>
            <div className='grid-x u-mt-3'>
              <a
                onClick={() => {
                  toggleBylaZablokovana()
                  blokovatKartu()
                  toggleBlokaceKarty() // = objednat náhradní kartu
                }}
                className='btn u-pl-2 u-size-base14 u-mr-1 btn--primary btn--small'
              >
                <TrashIcon />
                Zablokovat
              </a>
              <a
                onClick={() => {
                  toggleBlokaceKarty()
                }}
                className='btn u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
              >
                <TrashIcon />
                Zrušit
              </a>
            </div>
          </div>
        }
      />
      {/* Objednat novou (náhradní) kartu - Modal */}
      <Modal
        isShown={isShownObjednatNovou}
        hide={toggleObjednatNovou}
        headerText='Zavřít'
        subheaderText='Objednat novou (náhradní) kartu'
        modalContent={
          <div>
            <p className='u-size-base14 u-mt-2'>
              Uživatel bude moci nadále používat aktuální kartu, manipulovat a
              čerpat prostředky ze svého účtu. Jakmile aktivuje novou (náhradní)
              kartu, ta původní přestane být aktivní.
            </p>
            <p className='u-size-base14 u-mt-3'>
              Opravdu chcete objednat náhradní kartu?
            </p>
            <div className='grid-x u-mt-3 u-justify-center'>
              <a
                onClick={() => {
                  objednatNahradniKartu(id)
                  toggleObjednatNovou()
                }}
                className='btn u-pl-2 u-mt-2 u-size-base14 btn--primary btn--small'
              >
                <svg className='icon icon--24'>
                  <ShoppingCartIcon />
                </svg>
                Objednat náhradní kartu
              </a>
            </div>
          </div>
        }
      />
      {/* Karta byla zablokována - Modal */}
      <Modal
        isShown={isShownBylaZablokovana}
        hide={toggleBylaZablokovana}
        headerText='Zavřít'
        subheaderText='Karta byla zablokována'
        modalContent={
          <div className='modal__content'>
            <div>
              <div className='grid-x u-mt-3 u-justify-center'>
                <a
                  onClick={() => {
                    objednatNahradniKartu(id)
                    toggleBylaZablokovana()
                  }}
                  className='btn u-pl-2 u-size-base14 u-mr-1 btn--primary btn--small'
                >
                  Objednat náhradní kartu
                </a>
                <a
                  onClick={toggleBylaZablokovana}
                  className='btn u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
                >
                  <TrashIcon />
                  Zrušit
                </a>
              </div>
            </div>
          </div>
        }
      />
      {/* Reset PINu - Modal */}
      <Modal
        isShown={isShownResetPin}
        hide={toggleResetPin}
        headerText='Zavřít'
        subheaderText='Reset PINu'
        modalContent={
          <div className='modal__content'>
            <div>
              <p className='u-size-base14 u-mt-2'>
                Uživatel si musí na terminálu nastavit nový PIN, aby mohl pomocí
                karty platit.
              </p>
              <p className='u-size-base14 u-mt-3'>
                Opravdu chcete resetovat PIN na kartě?
              </p>
              <div className='grid-x u-size-base14 u-mt-2 u-justify-center'>
                <a
                  onClick={() => {
                    resetPin(currentlyResetting) // tady vyměnit ID karty za ID konkrétního pinu (Stravování, Volný čas)
                    toggleResetPin()
                  }}
                  className='btn u-pl-2 u-mt-3 u-size-base14 btn--primary btn--small'
                >
                  <TrashIcon />
                  Resetovat PIN
                </a>
              </div>
            </div>
          </div>
        }
      />
    </>
  )
}

export default DetailsOfCards
