import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class InfoCircleFillIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'info-circle-fill-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zM18.186 9.688H15.21V7h2.976v2.688zm0 15.312H15.21V14.368h-2.088v-2.496h5.064V25z'
          fill='#8282DC'
        ></path>
      </svg>
    )
  }
}

export default InfoCircleFillIcon
