import { FunctionComponent } from 'react'
import { useAppSelector } from 'app/hooks'
import { formatDate } from 'util/DateOps'
import { selectHistoricalOrders } from './tableSlice'
import { numberWithSpacesX } from 'app/util'
import { selectOrderById } from './overviewSlice'
import { urlOrdersOverview } from 'App'
import { verne } from 'app/julesverne'

export interface PosledniObjednavkyProps {
  id: string
}
export interface PosledniObjednavkyInsidesProps {
  orderId: number
}

const PosledniObjednavkyInsides: FunctionComponent<
  PosledniObjednavkyInsidesProps
> = ({ orderId }) => {
  const order = useAppSelector(selectOrderById(orderId))
  return (
    <span className='u-color-new-yellow'>
      <b>{order?.orderState}</b>
    </span>
  )
}

const PosledniObjednavky: FunctionComponent<PosledniObjednavkyProps> = ({
  id,
}) => {
  const historicalOrders = useAppSelector(selectHistoricalOrders(id)) || []
  const existsLeisureCredit = historicalOrders.some(
    (order) => order.leisure > 0
  )
  const existsGastroCredit = historicalOrders.some((order) => order.gastro > 0)
  return (
    <div>
      {historicalOrders.length > 0 && (
        <div className='highlight u-mb-3 u-py-3 u-px-2'>
          <div className='highlight__content'>
            <p className='u-mb-3'>
              <b>Poslední objednávky</b>
            </p>
            <div>
              {existsLeisureCredit && (
                <div className='grid-x u-size-base14 u-mt-2'>
                  <span className='cell auto'>Volnočasový kredit</span>
                  <div className='cell shrink u-text-right u-ml-1'>
                    {historicalOrders
                      .filter((order) => order.leisure > 0)
                      .map((order) => (
                        <div key={order.id} className='u-mb-2'>
                          <p>
                            <b>{numberWithSpacesX(order.leisure)} Kč</b>
                            <br />{' '}
                            <span className='u-size-s'>
                              {formatDate(order.date)}
                            </span>
                            {'  '}
                            <a
                              href={`${urlOrdersOverview}/#${order.id}`}
                              className='u-size-s'
                            >
                              {/* if the orderId is not resolved yet (was a bug in old model), and object is passed in, the following error will be thrown */}
                              {/* Uncaught Error: Objects are not valid as a React child (found: object with keys {}). If you meant to render a collection of children, use an array instead. */}
                              {/* obj. č. {card.orderId} */}

                              {verne(order.id)}
                              {/* this one works even with the error, and displays "obj. č. [object Object]" */}
                              {`obj. č. ${order.id}`}
                            </a>
                            <br />
                            <span className='u-color-new-yellow'>
                              <b>
                                <PosledniObjednavkyInsides orderId={order.id} />
                              </b>
                            </span>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {existsGastroCredit && (
                <div className='grid-x u-size-base14 u-mt-2'>
                  <span className='cell auto'>Stravenkový kredit</span>
                  <div className='cell shrink u-text-right u-ml-1'>
                    {historicalOrders
                      .filter((order) => order.gastro > 0)
                      .map((order) => (
                        <div key={order.id}>
                          <p>
                            <b>{numberWithSpacesX(order.gastro)} Kč</b>
                            <br />{' '}
                            <span className='u-size-s'>
                              {formatDate(order.date)}
                            </span>
                            {'  '}
                            <a href={urlOrdersOverview} className='u-size-s'>
                              {/* if the orderId is not resolved yet (was a bug in old model), and object is passed in, the following error will be thrown */}
                              {/* Uncaught Error: Objects are not valid as a React child (found: object with keys {}). If you meant to render a collection of children, use an array instead. */}
                              {/* obj. č. {card.orderId} */}

                              {verne(order.id)}
                              {/* this one works even with the error, and displays "obj. č. [object Object]" */}
                              {`obj. č. ${order.id}`}
                            </a>
                            <br />
                            <PosledniObjednavkyInsides orderId={order.id} />
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PosledniObjednavky
