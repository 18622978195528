import React, { useState } from 'react'
import { beOnboarding4Redirect, beSodexoUcetLoginRedirect } from 'App'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { selectPracovniEmail } from '../features/objednavatelSlice'
import { RECON_EMAIL_COM, SOUHLAS_SE_ZASILANIM_INFORMACI } from 'app/constants'
import { useAppSelector } from 'app/hooks'
import { selectCompanyId } from '../features/firemniUdajeSlice'
import { sendAndLog } from 'app/req'
import QuestionCircleOutlineIcon from '@styles/icon/questionCircleOutlineIcon'
import ConfirmSection from '../ConfirmSection'
import SodexoUcet from '@styles/image/sodexo-ucet.png'
import SodexoConnect from '@styles/image/sodexo-connect.png'
import Color from 'app/colors'
import QuestionCircleFillIcon from "../../../@styles/icon/questionCircleFillIcon";

interface Props {
  onSubmit: () => void
}

const OnboardingFourPrihlasitSeDanyil: React.FC<Props> = ({ onSubmit }) => {
  const lang = useAppSelector(selectLanguage) || 'cz'

  const submitText = 'Přihlásit se'
  const submitTextSc = 'Přihlásit se pomocí Pluxee Connect'

  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const companyId = useAppSelector(selectCompanyId)

  async function callBackendSc() {
    console.log('Submit - Onb4 - callingbackend - přihlásit se Pluxee connect')

    const requestBody = {
      email: pracovniEmail || RECON_EMAIL_COM,
      marketingConsent: SOUHLAS_SE_ZASILANIM_INFORMACI,
      companyId: companyId,
      languageCode: lang === 'cz' || lang === 'cs' ? 'cs' : 'en',
      uc: 'login-sodexo-connect',
    }

    await sendAndLog(beOnboarding4Redirect, requestBody).then((data) => {
      const unescapedUrl = decodeURI(data.redirectUrl)
      console.log('data, unescaped url', data, unescapedUrl)
      window.location.href = unescapedUrl
    })
  }

  async function callBackendSu() {
    console.log('Submit - Onb4 - callingbackend - přihlásit se Pluxee ucet')

    const requestBody = {
      email: pracovniEmail || RECON_EMAIL_COM,
      marketingConsent: SOUHLAS_SE_ZASILANIM_INFORMACI,
      companyId: companyId,
      languageCode: lang === 'cz' || lang === 'cs' ? 'cs' : 'en',
      uc: 'login-sodexo-ucet',
    }

    await sendAndLog(beOnboarding4Redirect, requestBody).then((data) => {
      const unescapedUrl = decodeURI(data.redirectUrl)
      console.log('data, unescaped url', data, unescapedUrl)

      window.location.href = beSodexoUcetLoginRedirect + companyId
    })
  }

  const [mouseOver, setMouseOver] = useState('')

  return (
    <div
      className='grid-x u-justify-center highlight highlight--wide'
      style={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: `${Color.BLUE_DANYIL}`,
      }}
    >
      <div className='grid-y'>
        <div
          className='input input--label'
          onMouseOver={() => setMouseOver('non-sc')}
          onMouseLeave={() => setMouseOver('')}
        >
          <h4 className='heading heading--4 u-text-center u-mb-4 flex-dir-row align-center'>
            <span className='u-mr-1'>Nevyužívám Pluxee Connect</span>
            <QuestionCircleFillIcon width={20} height={20} fill="#8282DC" />
          </h4>
          <img
            src={SodexoUcet}
            width='auto'
            height='150'
            alt=''
            className='u-mx-2'
          />
          {mouseOver === 'non-sc' && (
            <div
              className='input__hover-new'
              style={{
                width: 300,
                marginLeft: -22.5,
              }}
            >
              <p className='u-size-s'>
                Pro přihlášení do vašeho Pluxee účtu stále využíváte původní
                přihlašovací údaje a zatím nevyužíváte Pluxee Connect.
              </p>
            </div>
          )}
        </div>
        <div className='u-text-center u-mt-2'>
          <ConfirmSection
            submitText={submitText}
            onSubmit={() => {
              callBackendSu()
            }}
            customClassName="u-px-8 btn--dark-red"
          />
        </div>
      </div>

      <div className='grid-y u-ml-5'>
        <div
          className='input input--label'
          onMouseOver={() => setMouseOver('sc')}
          onMouseLeave={() => setMouseOver('')}
        >
          <h4 className='heading heading--4 u-text-center u-mb-4 flex-dir-row align-center'>
            <span className='u-mr-1'>Využívám Pluxee Connect</span>
            <QuestionCircleFillIcon width={20} height={20} fill="#8282DC" />
          </h4>
          <img
            src={SodexoConnect}
            width='auto'
            height='150'
            alt=''
            className='u-mx-2'
          />
          {mouseOver === 'sc' && (
            <div
              className='input__hover-new'
              style={{
                width: 350,
                marginLeft: -57.5,
              }}
            >
              <p className='u-size-s'>
                Už jste se úspěšně registrovali do Pluxee Connect a již
                využíváte vylepšené zabezpečení, které vám umožňuje přihlašovat
                se z jediného místa a platit rychle a snadno pomocí Apple Pay.
              </p>
            </div>
          )}
        </div>
        <div className='u-text-center u-mt-2'>
          <ConfirmSection
            submitText={submitTextSc}
            onSubmit={() => {
              callBackendSc()
            }}
            secondary
          />
        </div>
      </div>
    </div>
  )
}

export default OnboardingFourPrihlasitSeDanyil
