import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class CloseIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'close-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.01 13.946l9.41-9.415c.627-.628 1.53-.74 2.158-.112.627.628.51 1.525-.118 2.154l-9.41 9.415 9.423 9.427c.627.628.734 1.536.107 2.164-.628.628-1.534.521-2.162-.107l-9.423-9.428-9.415 9.42c-.628.629-1.525.746-2.152.118-.627-.628-.537-1.51.09-2.138l9.436-9.442-9.326-9.34c-.627-.627-.737-1.53-.11-2.158.627-.628 1.535-.522 2.162.106l9.33 9.336z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default CloseIcon
