import { FunctionComponent, useEffect, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import SideMenu from 'components/modules/menu/SideMenu'
import { urlDataImportFive, urlDataImportSummary } from 'App'
import {
  useBodyClass,
  useAppDispatch,
  useAppSelector,
  useModal,
} from 'app/hooks'
import {
  activeImportRow,
  uploadedFileName,
  reducerSetImportedRows,
  reducerAppliedAddresses,
} from './importSettingsSlice'
import { ExcelRow, ImportRow } from './dataImportSlice'
import { Navigate } from 'react-router'
import { addressWithPhone } from 'app/util'
import {
  completeKontaktniOsoba,
  completeMesto,
  completeNazevSpolecnosti,
  completePsc,
  completeTelefon,
  completeUliceCisloPopisne,
  finalJmeno,
  finalKontaktniOsoba,
  finalMesto,
  finalNazevSpolecnosti,
  finalPrijmeni,
  finalPsc,
  finalTelefon,
  finalUliceCisloPopisne,
} from 'util/DataImportFormatter'
import { selectAddresses } from 'components/settings/governanceSlice'
import { Address } from 'app/type'
import {
  isValidNazevSpolecnosti,
  isValidUliceCisloPopisne,
  isValidMesto,
  isValidPsc,
  isValidKontaktniOsoba,
  isValidTelefon,
} from 'util/ValidFormatOps'
import { Modal } from 'components/modules/modal/Modal'
import { PridatAdresuModal } from 'components/modules/modal/Modals'
import PlusIcon from '@styles/icon/plusIcon'
import CheckCircleIcon from '@styles/icon/checkCircleIcon'
import CloseCircleIcon from '@styles/icon/closeCircleIcon'
import { DIC_COLUMNS } from 'app/constants'
import HappyFace from '@styles/image/device-woman2.png'
import { Color } from 'app/colors'

const DataImportSix: FunctionComponent = () => {
  useBodyClass('u-bg-light-grey')
  const dispatch = useAppDispatch()
  const redux = useAppSelector((state) => state.dataImport) // the original import
  const importSettings = useAppSelector((state) => state.importSettings)
  const changesAcceptance = importSettings.changesAcceptance
  const activeRow = useAppSelector(activeImportRow)
  const fileName = useAppSelector(uploadedFileName)
  const [operatingTable, setOperatingTable] = useState<ImportRow[]>([]) // the stripped table of the uploaded file which we operate upon here
  const [redirect, setRedirect] = useState(false)
  const importedRowsWithBadAddress = useAppSelector(
    (state) => state.importSettings.importedRows
  ) // we do not filter here, we want the user to see the contrast with the good addresses and also to see some positivity and better progress illusion.

  const db = useAppSelector((state) => state.table)
  const addresses = useAppSelector(selectAddresses)
  const indexes = importSettings.badIndexToRealIndex

  // MODAL
  const { isShown: isShownPridatAdresu, toggle: togglePridatAdresu } =
    useModal()

  // POPOVER
  const [popover, setPopover] = useState('')
  const [popoverAdresySelect, setPopoverAdresySelect] = useState(false)

  // ADDRESSES
  const adresses = useAppSelector(selectAddresses)
  const [selectedAddress, setSelectedAddress] = useState<Address>(
    addresses[0] || {}
  )
  const [useStateAppliedAddresses, setUseStateAppliedAddresses] = useState<
    Array<Address | undefined>
  >([])

  const badTable = operatingTable.filter((row, i) => isBadRow(row, i))
  const pocetSpatnychRadku = badTable.length

  useEffect(() => {
    let newTable: ImportRow[] = []
    redux
      .slice(activeRow + 1 /* +1 for user's headers which we omit now */)
      .forEach((row: ExcelRow) => {
        // @ts-ignore
        let oldRow = row as string[]
        let newRow: ImportRow = {
          jmeno: oldRow[importSettings.jmenoColumn],
          prijmeni: oldRow[importSettings.prijmeniColumn],
          cisloZamestnance: oldRow[importSettings.cisloZamestnanceColumn],
          nazevSpolecnosti: oldRow[importSettings.nazevSpolecnostiColumn],
          uliceCisloPopisne: oldRow[importSettings.uliceCisloPopisneColumn],
          mesto: oldRow[importSettings.mestoColumn],
          psc: oldRow[importSettings.pscColumn],
          kontaktniOsoba: oldRow[importSettings.kontaktniOsobaColumn],
          telefon: oldRow[importSettings.telefonColumn],
          volnocasovyKredit: oldRow[importSettings.volnocasovyKreditColumn],
          stravenkovyKredit: oldRow[importSettings.stravenkovyKreditColumn],
        }
        newTable.push(newRow)
      })
    setOperatingTable(newTable)
    // persist for DataImportSummary
    dispatch(reducerSetImportedRows(newTable))

    // what we want to do on useEffect (page enter/leave):
    // 1. check if the state is changed - some rows rejected or accepted
    // 2. if yes, then we persist the state
    // 3. if no, then we load the persisted state and apply it to useState.
    // 4. the persisted state must be cleared either upon successful completion or load of new file.
    let isStateAltered = false
    for (let i = 0; i < pocetSpatnychRadku; i++) {
      if (useStateAppliedAddresses[i] !== undefined) {
        isStateAltered = true
        break
      }
    }
    if (isStateAltered) {
      // when leaving the page, we persist the state
      dispatch(reducerAppliedAddresses(useStateAppliedAddresses))
    } else {
      // load persisted state
      let persistedState = importSettings.appliedAddresses
      // if persisted state exists, set it to useState (we returned from another page) or create new state
      if (persistedState) {
        setUseStateAppliedAddresses(persistedState)
      } else {
        let changesLocal: (Address | undefined)[] = []
        newTable.forEach((_) => {
          changesLocal.push(undefined)
        })
        setUseStateAppliedAddresses(changesLocal)
      }
    }
  }, []) // here we removed appliedAddresses dependency

  function unsolvedRows() {
    let solvedCount = 0
    for (let i = 0; i < useStateAppliedAddresses.length; i++) {
      if (useStateAppliedAddresses[i]) {
        solvedCount++
      }
    }
    return badTable.length - solvedCount
  }

  function solvedRows() {
    let solvedCount = 0
    for (let i = 0; i < useStateAppliedAddresses.length; i++) {
      if (useStateAppliedAddresses[i]) {
        solvedCount++
      }
    }
    return solvedCount
  }

  const [invalidCellCounter, setInvalidCellCounter] = useState<Array<number>>(
    []
  )

  function incrementCounter(i: number): string {
    i = i + 1
    if (!invalidCellCounter.includes(i)) {
      setInvalidCellCounter((prev) => [...prev, i])
    }
    return ''
  }

  function applyAddress(i: number, appliedAddress: Address | undefined) {
    let newTable = [...useStateAppliedAddresses]
    newTable[i] = appliedAddress
    if (i === -8) {
      for (let j = 0; j < operatingTable.length; j++) {
        const row = operatingTable[j]
        if (isBadRow(row, j)) {
          newTable[j] = appliedAddress
        }
      }
    }
    setUseStateAppliedAddresses(newTable)
    dispatch(reducerAppliedAddresses(newTable))
    // WOULD NOT WORK, because we are using useState, not useReducer
    // useState is ASYNC!
    // dispatch(reducerAppliedAddresses(appliedAddresses))
  }

  function isBadRow(row: ImportRow, i: number): boolean {
    if (
      isValidNazevSpolecnosti(
        finalNazevSpolecnosti(changesAcceptance, i, row, addresses, db, indexes)
      ) &&
      isValidUliceCisloPopisne(
        finalUliceCisloPopisne(changesAcceptance, i, row, db, indexes)
      ) &&
      isValidMesto(
        finalMesto(changesAcceptance, i, row, addresses, db, indexes)
      ) &&
      isValidPsc(finalPsc(changesAcceptance, i, row, addresses, db, indexes)) &&
      isValidKontaktniOsoba(
        finalKontaktniOsoba(changesAcceptance, i, row, addresses, db, indexes)
      ) &&
      isValidTelefon(
        finalTelefon(changesAcceptance, i, row, addresses, db, indexes)
      )
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div>
      <Modal
        isShown={isShownPridatAdresu}
        hide={togglePridatAdresu}
        headerText='Zrušit'
        subheaderText='Přidat novou adresu'
        modalContent={
          <PridatAdresuModal
            onSubmit={() => {
              togglePridatAdresu()
              // // choose the last added address
              // let lastAddress = addresses[addresses.length - 1]
              // // set the last address as the selected address
              // setSelectedAddress(lastAddress)
              // console.log({ lastAddress })
            }}
            setSelectedAddress={setSelectedAddress}
          />
        }
      />
      {redirect && <Navigate to={urlDataImportSummary} />}
      <div className='table__import'>
        <div className='grid-x align-justify align-bottom'>
          <div className='u-mb-1'>
            <h1 className='u-size-l28 u-mb-2'>Chybný formát adresy</h1>
            <p className='u-size-l20'>
              Vyberte adresu ze seznamu a přiřaďte ji zaměstnanci kliknutím na
              symbol <CheckCircleIcon width={20} height={20} fill={'#3CDCC8'} />
              {/* Byly detekovány řádky, jejichž adresa nesplňuje naše nároky na
              formátování. <br /> Vytvořte, prosím, pro tyto řádky adresu pomocí
              našeho formuláře nebo vyberte již existující ze seznamu. */}
            </p>
          </div>
          <div className='u-mb-1'>
            <div className='alert alert--info alert--visible'>
              <div className='alert__inner'>
                <div className='grid-x align-middle'>
                  <span className='alert__text'>
                    Vyřešeno: {solvedRows()}, Zbývá vyřešit:{' '}
                    {badTable.length - solvedRows()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* u-ml-6 */}
        <div className='grid-x u-mb-1'>
          <div
            className='modal__trigger highlight u-bg-lightest-blue u-px-2 u-py-1 u-mb-1'
            style={{
              position: 'relative',
              zIndex: 1,
              width: '70%',
              minWidth: 630,
            }}
          >
            <div
              className='🧮'
              // style={{ position: 'absolute' }}
            >
              <div
                className='modal__trigger highlight__content'
                // style={{ width: clamp(300, 800, window.innerWidth) }}
                style={{ width: '100%' }}
              >
                {/* u-mb-1 */}
                <p className='modal__trigger u-size-base14'>
                  Zvolte adresu k aplikování a následně ji aplikujte na každém
                  řádku
                </p>
                <div
                  onClick={() => {
                    if (addresses?.length > 0)
                      setPopoverAdresySelect(!popoverAdresySelect)
                  }}
                  className={
                    'modal__trigger select select--wide select--big' +
                    (popoverAdresySelect
                      ? ' select--active'
                      : addresses?.length > 0
                      ? ''
                      : ' select--disabled')
                  }
                >
                  <div className='modal__trigger select__option select__option--selected'>
                    <span className='modal__trigger'>
                      {
                        // no address exists yet
                        !addresses || addresses.length === 0
                          ? 'Vytvořte novou adresu'
                          : selectedAddress.nazevSpolecnosti
                          ? // addresses exist and length > 0 and one was select on useState init
                            selectedAddress.nazevSpolecnosti +
                            ', ' +
                            selectedAddress.uliceCisloPopisne
                          : // addresses exist but the first has just been created and was not put in useState init yet
                            addresses[0].nazevSpolecnosti +
                            ', ' +
                            addresses[0].uliceCisloPopisne
                      }
                    </span>{' '}
                    <svg className='modal__trigger icon icon--24'>
                      <use
                        className='modal__trigger'
                        href={
                          icons +
                          (popoverAdresySelect
                            ? '#sprite-sorting-up'
                            : '#sprite-sorting-down')
                        }
                      ></use>
                    </svg>
                  </div>{' '}
                  <div className='modal__trigger select__options'>
                    {adresses &&
                      adresses.map((adresa, i) => (
                        <div
                          key={i}
                          className='modal__trigger select__option'
                          onClick={() => setSelectedAddress(adresa)}
                        >
                          {addressWithPhone(adresa)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className='modal__trigger highlight__content u-pr-3'>
                <button
                  onClick={togglePridatAdresu}
                  className='btn btn--secondary btn--small'
                  // style={{ position: 'absolute', right: 16, top: 45, height: 37 }}
                  // not as good when resizing window.
                  style={{
                    position: 'relative',
                    right: '40%',
                    top: '66%',
                    transform: 'translate(50%, -50%)',
                    minWidth: 250,
                    // height: '38%',
                  }}
                >
                  <svg className='icon icon--24'>
                    <PlusIcon width={24} height={24} />
                  </svg>
                  Vytvořit novou adresu
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* TABLE */}
        {badTable.length > 0 ? (
          <div className='table__overflow u-pl-6'>
            <div className='box box--small-radius box--no-padding box--transparent u-mb-9'>
              <div className='box__content u-overflow-visible'>
                <div
                  role='table'
                  aria-label='Spárování sloupců'
                  className='table table--compact'
                >
                  {/* HEADER */}
                  <div role='row' className='table__row'>
                    <>
                      {badTable.length > 0 && (
                        <div className='table__buttons'>
                          <svg
                            onClick={() => {
                              applyAddress(-8, selectedAddress)
                            }}
                            className='icon icon--16'
                          >
                            <CheckCircleIcon
                              width={16}
                              height={16}
                              fill={
                                unsolvedRows() !== 0 ? '#3CDCC8' : '#3CDCC8'
                              }
                            />
                          </svg>
                          <svg
                            onClick={() => {
                              applyAddress(-8, undefined)
                            }}
                            className='icon icon--16'
                          >
                            <CloseCircleIcon
                              width={16}
                              height={16}
                              fill={solvedRows() === 0 ? '#aaa9be' : '#ea444e'}
                            />
                          </svg>
                        </div>
                      )}
                      <div
                        className='table__cell table__cell--small u-bg-white'
                        style={{ borderTopLeftRadius: '14px' }}
                      ></div>
                      {DIC_COLUMNS.filter(
                        (c) => !c.includes('kredit') && !c.includes('Telefon')
                      ).map((sloupec, i) => (
                        <div
                          key={i}
                          className='table__cell table__cell--medium u-text-ellipsis'
                        >
                          {sloupec}
                        </div>
                      ))}
                      <div
                        style={{ borderTopRightRadius: '14px' }}
                        className='table__cell table__cell--medium u-text-ellipsis'
                      >
                        Telefon
                      </div>{' '}
                    </>
                  </div>
                  {/* BODY */}
                  {importedRowsWithBadAddress.map((row: ImportRow, i) => (
                    <div role='row' className='table__row' key={i}>
                      {/* ADD-REMOVE BUTTONS */}
                      {isBadRow(row, i) && (
                        <div className='table__buttons'>
                          <svg
                            onClick={() => {
                              if (!useStateAppliedAddresses[i])
                                applyAddress(i, selectedAddress)
                            }}
                            className='cursor-parent icon icon--16'
                            // style={{
                            //   cursor: `${
                            //     appliedAddresses[i] ? 'default' : 'pointer'
                            //   }`,
                            // }}
                          >
                            <CheckCircleIcon
                              width={16}
                              height={16}
                              fill={
                                !useStateAppliedAddresses[i]
                                  ? '#3CDCC8'
                                  : '#3CDCC8'
                              }
                              className={
                                useStateAppliedAddresses[i]
                                  ? 'cursor-child'
                                  : ''
                              }
                            />
                          </svg>
                          {/* {appliedAddresses[i] && ( */}
                          <svg
                            onClick={() => {
                              if (useStateAppliedAddresses[i])
                                applyAddress(i, undefined)
                            }}
                            className='cursor-parent icon icon--16'
                            // style={{
                            //   cursor: `${
                            //     !appliedAddresses[i] ? 'default' : 'pointer'
                            //   }`,
                            // }}
                          >
                            <CloseCircleIcon
                              width={16}
                              height={16}
                              fill={
                                !useStateAppliedAddresses[i]
                                  ? '#aaa9be'
                                  : '#ea444e'
                              }
                              className={
                                !useStateAppliedAddresses[i]
                                  ? 'cursor-child'
                                  : ''
                              }
                            />
                          </svg>
                          {/* )} */}
                        </div>
                      )}
                      <div
                        style={{
                          borderBottomLeftRadius:
                            i === importedRowsWithBadAddress.length - 1
                              ? '14px'
                              : '0px',
                        }}
                        className={`table__cell table__cell--small ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : invalidCellCounter.find((e) => e === i + 1)
                            ? 'u-bg-fuel-orange-80 🎯'
                            : 'u-bg-white'
                        }`}
                      >
                        {i + 1}
                      </div>
                      <div className='table__cell table__cell--medium u-text-ellipsis'>
                        {finalJmeno(changesAcceptance, i, row, db, indexes)}
                      </div>
                      <div className='table__cell table__cell--medium u-text-ellipsis'>
                        {finalPrijmeni(changesAcceptance, i, row, db, indexes)}
                      </div>
                      <div className='table__cell table__cell--medium u-text-ellipsis'>
                        {!row.cisloZamestnance ? (
                          <i>- bude přiděleno -</i>
                        ) : (
                          row.cisloZamestnance
                        )}
                      </div>
                      <div
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidNazevSpolecnosti(
                                finalNazevSpolecnosti(
                                  changesAcceptance,
                                  i,
                                  row,
                                  addresses,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                      >
                        {completeNazevSpolecnosti(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                      <div
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidUliceCisloPopisne(
                                finalUliceCisloPopisne(
                                  changesAcceptance,
                                  i,
                                  row,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                      >
                        {completeUliceCisloPopisne(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                      <div
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidMesto(
                                finalMesto(
                                  changesAcceptance,
                                  i,
                                  row,
                                  addresses,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                        // style={{ order: 2 }}
                      >
                        {completeMesto(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                      <div
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidPsc(
                                finalPsc(
                                  changesAcceptance,
                                  i,
                                  row,
                                  addresses,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                      >
                        {completePsc(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                      <div
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidKontaktniOsoba(
                                finalKontaktniOsoba(
                                  changesAcceptance,
                                  i,
                                  row,
                                  addresses,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                      >
                        {completeKontaktniOsoba(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                      <div
                        style={{
                          borderBottomRightRadius:
                            i === importedRowsWithBadAddress.length - 1
                              ? '14px'
                              : '0px',
                        }}
                        className={`table__cell table__cell--medium u-text-ellipsis ${
                          useStateAppliedAddresses[i]
                            ? 'u-bg-aquamarine-50 🏅'
                            : !isValidTelefon(
                                finalTelefon(
                                  changesAcceptance,
                                  i,
                                  row,
                                  addresses,
                                  db,
                                  indexes
                                )
                              ) &&
                              'u-bg-fuel-orange-80 🎯' + incrementCounter(i)
                        }`}
                      >
                        {completeTelefon(
                          row,
                          i,
                          changesAcceptance,
                          db,
                          indexes,
                          useStateAppliedAddresses,
                          addresses
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className='u-text-center u-mt-5 u-mr-8'
            style={{
              backgroundColor: Color.GREYLIGHT,
            }}
          >
            <p className='u-size-l20 u-mb-5'>
              Všechny adresy jsou ve správném formátu.
              <br />
              Pokračujte dále.
            </p>{' '}
            <img
              src={HappyFace}
              // srcSet={`${HappyFace} 1x, ${HappyFace2x} 2x`}
              width='376'
              height='auto'
              alt='Nebyl nalezen žádný záznam'
            />
          </div>
        )}
      </div>
      <div className='u-fixed-bottom u-mb-3 u-px-3'>
        <div className='grid-x align-justify u-ml-5 u-mr-3'>
          <div className='grid-x align-middle u-mb-1'>
            <svg className='icon u-mr-2'>
              <use href={icons + '#sprite-excel'}></use>
            </svg>
            <i>{fileName}</i>
          </div>
          <div className='grid-x align-middle u-mb-1'>
            <a href={urlDataImportFive} className='u-mr-3'>
              Zpět
            </a>
            <button
              onClick={() => {
                setRedirect(true)
              }}
              className={`btn u-px-2 btn--primary btn--small ${
                unsolvedRows() > 0 && 'btn--disabled'
              }`}
              disabled={unsolvedRows() > 0}
            >
              <svg className='icon icon--24'>
                <use href={icons + '#sprite-check'}></use>
              </svg>
              Pokračovat
            </button>
          </div>
        </div>
      </div>
      <SideMenu />
    </div>
  )
}
export default DataImportSix
