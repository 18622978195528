import { selectLogin } from 'components/settings/governanceSlice'
import { isJwtExpired } from './cryptoOps'
import {
  getLogoutUrlFromRedux,
  getUnparsedTokenFromRedux,
  setLogoutUrlToRedux,
  setUnparsedTokenToRedux,
} from './facade'
import { useAppSelector } from './hooks'
import { sinking } from './julesverne'

export const loggedIn = (): boolean => {
  // const login = useAppSelector(selectLogin)
  const jwt = getUnparsedTokenFromRedux()
  console.log('loggedInFunction', jwt)
  // return getPrecious() !== '' && login // ORD-2138 -> redux has delay -> login.email is not set yet in case of internal users or something like that -> use only getPrecious() instead
  return jwt !== '' && !isJwtExpired(jwt) // ORD-2138 -> login is sometimes not set in case of end user, JwtAuth does not do it, unlike Login (Internal). It should be done either in Onboarding4 or TableContainer, but there are some conditions. Thus, it is not guaranteed field.
  // there used to be this 6 months ago: const login = getLoginFromRedux()
  // return getPrecious() !== '' && login && login.email !== '' // ORD-2097 -> redux has delay -> login.email is not set yet in case of internal users
}

export const getPrecious = () => {
  // const tokenFromLocalStorage = localStorage.getItem('token') // LET'S MAKE AN EXPERIMENT -> use Redux storage only
  const tokenFromLocalStorage = getUnparsedTokenFromRedux()
  // console.log('getPrecious', tokenFromLocalStorage)
  return tokenFromLocalStorage
}

export const setPrecious = (token: string) => {
  sinking() && console.log('setPrecious', token)
  // console.log('setTokenToLocalStorage(redux):', token)
  // localStorage.setItem('token', token) // LET'S MAKE AN EXPERIMENT -> use Redux storage only
  setUnparsedTokenToRedux(token)
}

export const getLogoutUrl = (): string => {
  const logoutUrl = getLogoutUrlFromRedux()
  console.log('getLogoutUrl', logoutUrl)
  return logoutUrl
}

export const setLogoutUrl = (logoutUrl: string) => {
  sinking() && console.log('setLogoutUrl', logoutUrl)
  setLogoutUrlToRedux(logoutUrl)
}

export const removePrecious = () => {
  console.log('remove precious')
  setPrecious('')
  // localStorage.removeItem('token') // LET'S MAKE AN EXPERIMENT -> use Redux storage only
}

export function defaultHeaders(extraheader?: TupleString) {
  let token = getPrecious()
  const headers =
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    {
      Authorization: `Bearer ${token}`,
      // the following ones must be set on the response from the server:
      // 'X-Frame-Options': 'sameorigin', // only the pages served from the same origin will be allowed to embed it in frames or iframe. Practically speaking pages from your domain (with the same port) could embed your website.
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  if (extraheader) {
    headers[extraheader[0]] = extraheader[1]
  }
  return {
    headers: headers,
  }
}

export function defaultOptionsDelete() {
  return {
    ...defaultHeaders(),
    method: 'DELETE',
  }
}

export type TupleString = [string, string]

export function defaultOptionsGet(extraHeader?: TupleString) {
  return {
    ...defaultHeaders(extraHeader),
    method: 'GET',
  }
}

export function defaultOptionsMake(requestBody: string, method?: string) {
  return {
    ...defaultHeaders(),
    method: method || 'POST',
    body: requestBody,
  }
}
