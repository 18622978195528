import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class questionCircleFillIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'question-circle-fill-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height, fill, onClick } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
        cursor='pointer'
        onClick={onClick}
      >
        <path
          className='modal__trigger'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z'
          fill={fill}
        />
        <path
          className='modal__trigger'
          d='M13.936 19.888h2.976v-1.536l2.352-2.784c1.536-1.824 2.136-3.216 2.136-4.44C21.4 8.368 19.816 7 16.48 7h-4.128v2.544h3.264c1.608 0 2.808.432 2.808 1.896 0 1.08-.528 2.136-1.68 3.504l-2.808 3.312v1.632zm0 5.112h2.976v-3h-2.976v3z'
          fill='#fff'
        />
      </svg>
    )
  }
}

export default questionCircleFillIcon
