import React, { FunctionComponent } from 'react'
import icons from '@styles/dist/system/icons.svg'
import { useAppSelector, useModal } from 'app/hooks'
import {
  overviewActions,
  selectOrderById,
} from 'components/table/overviewSlice'
import { formatDateAndTime } from 'util/DateOps'
import {
  addressWithPhone,
  generateInfoMessage,
  minimumXIfNotZero,
  numberWithSpaces,
  numberWithSpacesX,
} from 'app/util'
import { Modal } from 'components/modules/modal/Modal'
import { useDispatch } from 'react-redux'
import { kohoCehoKusuSwitch } from 'util/LangOps'
import { fractionalToPercents } from 'app/numberOps'
import { getPrecious } from 'app/back'
import { ErrorModal } from 'components/modules/errorhandler/ErrorModal'
import { orderIdToCisloObjednavkyBackend } from 'util/transformer'
import { colorize } from 'util/colorOps'
import { ConsignmentState, Order, OrderState } from 'app/type'
import { sinking } from 'app/julesverne'
import { beObjednavky } from 'App'
import { mutateAktivovatZasilkuBackend } from 'app/req'
import { getCenikFromRedux } from 'app/facade'

export interface OrderDetailProps {
  orderId: number
}

const OrderDetail: FunctionComponent<OrderDetailProps> = ({ orderId }) => {
  const dispatch = useDispatch()
  const order = useAppSelector(selectOrderById(orderId)) as Order
  const { isShown: isShownAktivovatZasilku, toggle: toggleAktivovatZasilku } =
    useModal()
  const [currentZasilkaNumber, setCurrentZasilkaNumber] = React.useState(-1)

  const [showErrorModal, setShowErrorModal] = React.useState(false)
  const [isFaktura, setIsFaktura] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  function aktivujZasilku(zasilkaNumber: number) {
    dispatch(overviewActions.activateZasilka({ orderId, zasilkaNumber }))
    toggleAktivovatZasilku()
    setCurrentZasilkaNumber(-1)
    const orderIdBackend = orderIdToCisloObjednavkyBackend(orderId)
    mutateAktivovatZasilkuBackend(orderIdBackend, zasilkaNumber)
    // todo call backend endpoint once backend is ready
  }

  function castkaCelkemZaKarty(): number {
    if (!order) return 0
    return order.zasilky.reduce((acc, zasilka) => {
      return (
        acc +
        zasilka.pocetNovychKaret * order.vydaniPrvniKartyPrice +
        zasilka.pocetNahradnichKaret * order.vydaniNahradniKartyPrice +
        order.uskutecnenyPoplatekDoruceni / order.zasilky.length
      )
    }, 0)
  }

  // managing errors in order to avoid showing them in the UI
  function manageErrors(response: Response) {
    if (!response.ok) {
      setIsLoading(false)
      setShowErrorModal(true)
      throw new Error(response.statusText)
    }
    return response
  }

  function getDataFromBackend(dataType: string): any {
    console.log(orderId)
    setIsLoading(true)
    const zasilkaBackendNumber = orderIdToCisloObjednavkyBackend(orderId)
    console.log('zasilkaBackendNumber', zasilkaBackendNumber)
    let token = getPrecious()
    if (dataType === 'faktura') {
      setIsFaktura(true)
    } else {
      setIsFaktura(false)
    }

    fetch(`${beObjednavky}/${zasilkaBackendNumber}/soubory/${dataType}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(manageErrors)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const fileURL = URL.createObjectURL(myBlob)
        window.open(fileURL) as Window
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.log(error)
      })
  }

  const getDataType = () => {
    if (isFaktura) {
      return 'Faktura'
    } else {
      return 'Proforma'
    }
  }

  return (
    // this is a panel
    <div key={orderId} style={{ width: '480px' }}>
      <div className='grid-x u-mb-2'>
        <div className='cell medium-auto'>
          <h3 className='u-size-l20 u-mb-2'>Obj. č. {orderId}</h3>
        </div>
        <div className='cell medium-auto u-text-medium-right'>
          {order?.orderState === OrderState.ČekáNaZaplacení && (
            <p className='u-size-base14'>
              Objednávka obsahuje záznamy, které vyžadují vaši pozornost
            </p>
          )}
          {/* <button onClick={() => getDataFromBackend('faktura')}>
            Zobrazit fakturu
          </button> */}
        </div>
      </div>
      <div className='highlight u-py-2 u-px-2'>
        <div className='highlight__content'>
          <p className='grid-x'>
            <small className='cell auto'>
              <strong>Datum a čas vytvoření</strong>
            </small>
            <small className='cell shrink u-text-right u-ml-1'>
              <strong>{order ? formatDateAndTime(order.date) : '-'}</strong>
            </small>
          </p>
          <p className='grid-x'>
            <small className='cell auto'>
              <strong>Číslo objednávky</strong>
            </small>
            <small className='cell shrink u-text-right u-ml-1'>
              <strong>{order?.orderId}</strong>
            </small>
          </p>
          <p className='grid-x'>
            <small className='cell auto'>
              <strong>Poznámka na faktuře</strong>
            </small>
            <small className='cell shrink u-text-right u-ml-1'>
              <strong>{order?.note}</strong>
            </small>
          </p>
          <p className='grid-x'>
            <small className='cell auto'>
              <strong>Stav objednávky</strong>
            </small>
            <small className='cell shrink u-text-right u-ml-1'>
              <strong>{order?.orderState}</strong>
            </small>
          </p>
        </div>
      </div>
      <div className='highlight u-mb-3 u-py-3 u-px-2 highlight--dark'>
        <div className='highlight__content'>
          <p className='grid-x align-bottom'>
            <span className='cell auto'>
              <strong>Částka celkem</strong>
            </span>
            <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
              <strong>
                {numberWithSpaces((order ? order.price : 0).toFixed(2))} Kč
              </strong>
            </small>
          </p>
          {/* na FE není DPH nikde, jen ve fakturách */}
          {/* V tom případě je ale jediná cesta ukázat celkovou cenu ve dvou variantách - bez DPH a s DPH. Protože cena s DPH typického zákazníka (firmu) nezajímá. Jeho nákladem je cena bez DPH. */}
          {/* Obecně, je-li firma plátcem DPH, odečítá zaplacené DPH od DPH, kterou přijala od svých zákazníků a státu odvádí jen rozdíl. Takže z pohledu nákladů ji DPH nezajímá (z pohledu cash-flow samozřejmě ano). Firmy (plátci DPH) mezi sebou obchodují za ceny bez DPH. V tomto případě (alespoň podle faktury, kterou jsem viděl) kredity nepodléhají DPH, takže klient platí DPH jen z poplatků. za dobití a případně dalších služeb. */}
          <small className='cell auto u-pb-0'>
            <span className='small-text u-color-c1-40'>(bez DPH)</span>
          </small>
        </div>
      </div>
      <div className='grid-x grid-margin-x u-mb-4'>
        <div className='cell'>
          <a
            className='btn u-size-s u-mr-1 u-px-2 btn--violet btn--tiny'
            onClick={() => getDataFromBackend('proforma')}
          >
            <svg className='icon icon--16'>
              <use href={icons + '#sprite-download'}></use>
            </svg>
            Pro forma faktura
          </a>
          <a
            className='btn u-size-s u-px-2 btn--violet btn--tiny'
            onClick={() => getDataFromBackend('faktura')}
          >
            <svg className='icon icon--16'>
              <use href={icons + '#sprite-download'}></use>
            </svg>
            Faktura
          </a>
          {isLoading && (
            <span style={{ fontSize: '20px' }} className='u-ml-2 loader'></span>
          )}
          <ErrorModal
            showErrorModal={showErrorModal}
            setShowErrorModal={setShowErrorModal}
            text={generateInfoMessage(getDataType(), order?.orderState)}
          />
        </div>
      </div>
      {order && order.zasilky.length > 0 && (
        <div>
          <h3 className='u-size-l20 u-mb-2'>Objednávka karet</h3>
          <div className='highlight u-mb-2 u-py-3 u-px-2'>
            <div className='highlight__content'>
              {order?.zasilky.map((zasilka) => (
                <div className='u-mb-2'>
                  <p>
                    <strong>
                      Zásilka #{order.orderId}/{zasilka.number} -
                    </strong>
                    {order?.orderState === OrderState.Stornovaná ? (
                        <span className='u-ml-1'>
                            <strong>Stornovaná</strong>
                        </span>
                    ) : (
                        <span className={`u-ml-1 ${colorize(zasilka.stavZasilky)}`}>
                          <strong>{zasilka.stavZasilky}</strong>
                        </span>
                    )}
                  </p>
                  <p className='u-size-base14 u-color-c1-60'>
                    {addressWithPhone(zasilka.dorucovaciAdresa)}
                  </p>
                  <p className='grid-x'>
                    <small className='cell auto'>
                      Vydání karet (
                      {`${
                        zasilka.pocetNovychKaret > 0
                          ? order.vydaniPrvniKartyPrice +
                            ' Kč * ' +
                            zasilka.pocetNovychKaret +
                            ' ' +
                            kohoCehoKusuSwitch(zasilka.pocetNovychKaret)
                          : ''
                      }`}
                      {zasilka.pocetNovychKaret > 0 &&
                      zasilka.pocetNahradnichKaret > 0
                        ? ' + '
                        : ''}
                      {`${
                        zasilka.pocetNahradnichKaret > 0
                          ? order.vydaniNahradniKartyPrice +
                            ' Kč * ' +
                            zasilka.pocetNahradnichKaret +
                            ' ' +
                            kohoCehoKusuSwitch(zasilka.pocetNahradnichKaret)
                          : ''
                      }`}
                      )
                    </small>
                    <small className='cell shrink u-text-right u-ml-1'>
                      {order.vydaniPrvniKartyPrice * zasilka.pocetNovychKaret +
                        order.vydaniNahradniKartyPrice *
                          zasilka.pocetNahradnichKaret}{' '}
                      Kč
                    </small>
                  </p>
                  <p className='grid-x'>
                    <small className='cell auto'>
                      Doručení karet na firemní adresu
                    </small>
                    <small className='cell shrink u-text-right u-ml-1'>
                      {order.uskutecnenyPoplatekDoruceni / order.zasilky.length}{' '}
                      Kč
                    </small>
                  </p>
                  <p className='grid-x'>
                    <small className='cell auto'>
                      <b>Jednorázové náklady na vydání a doručení karet</b>
                    </small>
                    <small className='cell shrink u-text-right u-ml-1'>
                      <strong>
                        {order.vydaniPrvniKartyPrice *
                          zasilka.pocetNovychKaret +
                          order.vydaniNahradniKartyPrice *
                            zasilka.pocetNahradnichKaret +
                          order.uskutecnenyPoplatekDoruceni /
                            order.zasilky.length}{' '}
                        Kč
                      </strong>
                    </small>
                  </p>
                  {zasilka.stavZasilky === ConsignmentState.ČekáNaPřevzetí &&
                      order?.orderState !== OrderState.Stornovaná && (
                        <div>
                          <div className='u-text-center u-mt-4 u-mb-4'>
                            <a
                              onClick={() => {
                                toggleAktivovatZasilku()
                                setCurrentZasilkaNumber(zasilka.number)
                              }}
                              className='btn u-size-s u-px-2 btn--primary btn--tiny'
                            >
                              <svg className='icon icon--16'>
                                <use href={icons + '#sprite-sdx-card'}></use>
                              </svg>
                              Převzít zásilku
                            </a>
                          </div>
                          <div className='grid-x u-mb-4'>
                            <div className='cell shrink'>
                              <svg className='icon icon--24'>
                                <use
                                  href={icons + '#sprite-info-circle-fill'}
                                ></use>
                              </svg>
                            </div>
                            <div className='cell auto u-pl-1 u-size-base14'>
                              Karty budou převedeny do stavu pro první použití na
                              platebním terminálu, kde si každý uživatel aktivuje
                              svoji kartu.
                            </div>
                          </div>
                        </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div
            className='highlight u-mb-5 u-py-3 u-px-2'
            style={{ backgroundColor: 'rgb(194, 194, 228)' }}
          >
            <div className='highlight__content'>
              <p className='grid-x align-bottom'>
                <span className='cell auto'>
                  <strong>Částka za karty celkem</strong>
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>{numberWithSpacesX(castkaCelkemZaKarty())} Kč</strong>
                </small>
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {console.log(order)} */}
      {order && (order.leisureCredits > 0 || order.gastroCredits > 0) && (
        <div>
          <h3 className='u-size-l20 u-mb-2'>Objednávka kreditů</h3>
          <div className='highlight u-mb-0 u-pt-3 u-px-2 u-pb-1'>
            <div className='highlight__content'>
              {/* <p className='u-mb-2'>
                <strong>Objednávka kreditů</strong>
              </p> */}
              <p className='grid-x u-mb-2'>
                <span className='cell auto u-size-base14'>
                  Volnočasový kredit
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>
                    {numberWithSpaces(
                      (
                        order.leisureCredits +
                        (order.uskutecnenyPoplatekLeisure ||
                          minimumXIfNotZero(
                            getCenikFromRedux().minVolnocasFee,
                            order.feeLeisure,
                            order.leisureCredits
                          ))
                      ).toFixed(2)
                    )}{' '}
                    Kč
                  </strong>
                  <br />
                  <span className='u-color-c1-40 u-size-s'>
                    vč. poplatku {fractionalToPercents(order?.feeLeisure)} % (
                    {order.uskutecnenyPoplatekLeisure ||
                      minimumXIfNotZero(
                        getCenikFromRedux().minVolnocasFee,
                        order.feeLeisure,
                        order.leisureCredits
                      )}{' '}
                    Kč)
                  </span>
                </small>
              </p>
              <p className='grid-x u-mb-4'>
                <span className='cell auto u-size-base14'>
                  Stravenkový kredit
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>
                    {numberWithSpaces(
                      (
                        order.gastroCredits +
                        (order.uskutecnenyPoplatekGastro ||
                          minimumXIfNotZero(
                            getCenikFromRedux().minStravenkyFee,
                            order.feeGastro,
                            order.gastroCredits
                          ))
                      ).toFixed(2)
                    )}{' '}
                    Kč
                  </strong>
                  <br />
                  {/* {console.log(order)} */}
                  {/* {console.log(+order.feeGastro * +order.gastroCredits)} */}
                  <span className='u-color-c1-40 u-size-s'>
                    vč. poplatku {fractionalToPercents(order?.feeGastro)} % (
                    {order.uskutecnenyPoplatekGastro ||
                      minimumXIfNotZero(
                        getCenikFromRedux().minStravenkyFee,
                        order.feeGastro,
                        order.gastroCredits
                      )}{' '}
                    Kč)
                  </span>
                </small>
              </p>
              {order?.uskutecnenyPoplatekSpravaUctu > 0 && (
                <div className='grid-x u-pb-3'>
                  <p className='cell auto'>
                    <strong>Správa účtů</strong>
                  </p>
                  <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                    <strong>
                      {numberWithSpacesX(
                        order?.uskutecnenyPoplatekSpravaUctu || 0
                      )}{' '}
                      Kč
                    </strong>
                    <br />
                    <span className='u-color-c1-40 u-size-s'>
                      Účtováno za každého zaměstnance a kalendářní měsíc
                    </span>
                  </small>
                </div>
              )}
            </div>
          </div>
          <div
            className='highlight u-py-3 u-px-2'
            style={{ backgroundColor: 'rgb(194, 194, 228)' }}
          >
            <div className='highlight__content'>
              <p className='grid-x align-bottom'>
                <span className='cell auto'>
                  <strong>Částka za kredity celkem</strong>
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  <strong>
                    {numberWithSpaces(
                      (
                        order.uskutecnenyPoplatekSpravaUctu +
                        order.leisureCredits +
                        (order.uskutecnenyPoplatekLeisure ||
                          minimumXIfNotZero(
                            getCenikFromRedux().minVolnocasFee,
                            order.feeLeisure,
                            order.leisureCredits
                          )) +
                        order.gastroCredits +
                        (order.uskutecnenyPoplatekGastro ||
                          minimumXIfNotZero(
                            getCenikFromRedux().minStravenkyFee,
                            order.feeGastro,
                            order.gastroCredits
                          ))
                      ).toFixed(2)
                    )}{' '}
                    Kč
                  </strong>
                </small>
              </p>
              <small className='cell auto u-pb-0'>
                <span className='small-text u-color-c1-80'>(bez DPH)</span>
              </small>
            </div>
          </div>
        </div>
      )}
      {/* MODAL - aktivovat zásilku */}
      <Modal
        isShown={isShownAktivovatZasilku}
        hide={toggleAktivovatZasilku}
        headerText='Zavřít'
        subheaderText='Převzít zásilku'
        modalContent={
          <div className='modal__content'>
            <div>
              <p className='u-size-base14'>
                <b>Opravdu chcete převzít zásilku?</b>
              </p>
              <p className='u-size-base14'>
                {order &&
                  order.zasilky.find(
                    (zasilka) => zasilka.number === currentZasilkaNumber
                  ) &&
                  addressWithPhone(
                    order.zasilky.find(
                      (zasilka) => zasilka.number === currentZasilkaNumber
                    )!.dorucovaciAdresa
                  )}
              </p>
              <div className='grid-x u-mt-3'>
                <a
                  onClick={() => {
                    toggleAktivovatZasilku()
                    aktivujZasilku(currentZasilkaNumber)
                  }}
                  className='btn u-pl-2 u-size-base14 u-mr-1 btn--primary btn--small'
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-check'}></use>
                  </svg>
                  Převzít
                </a>
                <a
                  onClick={toggleAktivovatZasilku}
                  className='btn u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
                >
                  <svg className='icon icon--16'>
                    <use href={icons + '#sprite-delete-thin'}></use>
                  </svg>
                  Zrušit
                </a>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default OrderDetail
