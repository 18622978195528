import React from 'react'
import { useAppSelector } from 'app/hooks'
import { numberWithSpacesMax2 } from 'app/util'
import { selectCenik } from 'components/onboarding/features/firemniUdajeSlice'
import { procentualniPoplatek } from 'util/ValidFormatOps'
import Color from 'app/colors'
import { Cenik } from 'app/type'

const SettingsSluzby = () => {
  const cenik = useAppSelector(selectCenik) as Cenik

  return (
    <div className='settings-sluzby'>
      <div
        className='container container--wide u-mx-0'
        style={{
          // background: '#2A295B',
          maxWidth: '100vw',
          borderRadius: 24,
        }}
      >
        <div className='grid-x'>
          <div className='cell large-8 u-pr-large-3 u-mb-6 u-mb-large-0'>
            {/* Přihlašovací údaje - not used for now, will be re-enabled when we switch to the new login system */}
            {/* <PrihlasovaciUdaje /> */}
            {/* Fakturační údaje */}
            <div
              className='highlight u-py-3 u-px-4 u-mb-3'
              style={{ width: 1000 }}
            >
              <div className='highlight__content'>
                <h2 className='u-size-l20 u-mb-4'>
                  Příloha č. 3 - Ceník individuální
                </h2>
                {/* <p>
                  Pojmy začínající velkými písmenem, které zde nejsou přímo
                  definovány, mají stejný význam jako ve VOP. Klient je zavázán
                  k úhradě následujících cen a poplatků (odměn) ve prospěch
                  společnosti Sodexo za následující Produkty a služby poskytnuté
                  společností Sodexo na základě uzavřené Smlouvy:
                </p> */}
                <h3 className='u-size-l20 u-my-4 u-ml-1'>
                  MultiPassCard (MPC)
                </h3>
                <div className='grid-x'>
                  <div className='cell'>
                    <div
                      className={'u-mt-1 u-pt-0 u-pb-5'}
                      style={{ maxWidth: '840px' }}
                    >
                      <div
                        style={{
                          border: `2px solid ${Color.BLUESODEXO}`,
                          marginBottom: '0px',
                        }}
                        className='highlight__title grid-x color-sodexo-blue u-py-2 u-px-2'
                      >
                        <span>Druh služby</span>
                        <small className='u-mr-4 xsmall-2'></small>
                        <small className='xsmall-3'>
                          Poplatek (zvýhodněný)
                        </small>
                      </div>
                      <div className='border-full-no-top u-px-2 u-pt-1 u-pb-2'>
                        <p className='grid-x color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-right u-mr-4 color-sodexo-grey'></span>
                          <span className='cell auto'>
                            Kód služby: 844
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Správa uživatelských účtů
                              (měsíční poplatek za aktivní účty Gastro a Flexi)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {numberWithSpacesMax2(cenik.spravaJednohoUctu)} Kč
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-center u-mr-4'>
                            <>
                              Poplatek za služby GastroPassCard a FlexiPassCard
                            </>
                          </span>
                          <span className='cell auto'>
                            Kód služby: 26C-831
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Objednání elektronického Gastro Pass kreditu
                              (% z objednávaného kreditu/Stravného)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {procentualniPoplatek(cenik.stravenky)}
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-right u-mr-4 color-sodexo-grey'>
                            <>{/* 5 % */}</>
                          </span>
                          <span className='cell auto'>
                            Kód služby: 27C-731
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Objednání elektronického Flexi Pass kreditu
                              (% z objednávaného kreditu)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {procentualniPoplatek(cenik.volnocas)}
                          </span>
                        </p>
                      </div>
                      <div className='border-full-no-top u-px-2 u-pt-1 u-pb-2'>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-center u-mr-4 u-pt-6'>
                            <>Vydání karty</>
                          </span>
                          <span className='cell auto'>
                            Kód služby: 401
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Vydání nové karty
                              (jednorázový poplatek za 1 kartu)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {cenik.vydaniPrvniKarty} Kč
                          </span>
                        </p>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-center u-mr-4'>
                            {/* <>Vydání karty</> */}
                          </span>
                          <span className='cell auto'>
                            Kód služby: 402
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Vydání náhradní karty
                              (jednorázový poplatek za 1 kartu)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {cenik.vydaniNahradniKarty} Kč
                          </span>
                        </p>
                      </div>
                      <div className='border-full-no-top u-px-2 u-pt-1 u-pb-2'>
                        <p className='grid-x u-mt-2 color-sodexo-blue u-pt-1'>
                          <span className='cell xsmall-4 u-text-center u-mr-4 u-pt-2'>
                            <>Doručení karty</>
                          </span>
                          <span className='cell auto'>
                            Kód služby: 421
                            <br />
                            <small className='small-text display-linebreak'>
                              {`Doručení karty na firemní adresu
                              (jednorázový poplatek za 1 místo doručení)`}
                            </small>
                          </span>
                          <span className='cell shrink small-2 u-text-right u-mt-2'>
                            {cenik.doruceni} Kč
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsSluzby
