import React, { useEffect, useRef, useState } from 'react'
import { ImportExampleModal } from './ImportExampleModal'
import { ExtraImportInfoModal } from './ExtraImportInfoModal'
import { useAppSelector, useOnClickOutsideAndEscape } from 'app/hooks'
import QuestionCircleFillIcon from '@styles/icon/questionCircleFillIcon'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import DeleteIcon from '@styles/icon/deleteIcon'
import { AddEmployeeModal } from './AddEmployeeModal'
import HelpFooter from './HelpFooter'
import { lg } from 'app/facade'
import {
  ADD_TO_CART,
  BY_IMPORTING_DATA,
  CITY,
  CLOSE,
  CONTACT_PERSON,
  CREATING_A_NEW_ORDER,
  FIRM_NAME,
  HM_IMPORT_STEP_1,
  HM_IMPORT_STEP_1_BOLD,
  HM_IMPORT_STEP_2,
  HM_IMPORT_STEP_2_BOLD,
  HM_IMPORT_STEP_3,
  SAVE,
  HM_STEP_1,
  HM_STEP_1_BOLD,
  HM_STEP_2,
  HM_STEP_3,
  HM_STEP_4,
  HM_STEP_5,
  HM_STEP_6,
  HM_STEP_6_BOLD,
  MANUALLY,
  NAME,
  NAME_SURNAME_FIRST_CELL,
  PHONE_NUMBER_OF_THE_CONTACT_PERSON,
  STREET_HOUSE_NUMBER,
  SURNAME,
  ZIP,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface IHelpModal {
  showHelpModal: boolean
  setShowHelpModal: any
  defaultPageDataImport?: boolean
}

export const HelpModal = ({
  showHelpModal,
  setShowHelpModal,
  defaultPageDataImport = false,
}: IHelpModal) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [tabImport, setTabImport] = useState(defaultPageDataImport)
  const [showAddEmployee, setShowAddEmployee] = useState(false)
  const [showImportExample, setShowImportExample] = useState(false)
  const [showExtraImportInfo, setShowExtraImportInfo] = useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowHelpModal(false)
    }
  }

  useOnClickOutsideAndEscape(
    modalRef,
    () => setShowHelpModal(false),
    showHelpModal,
    setShowHelpModal
  )

  const openAddEmployee = () => {
    setShowAddEmployee((prev) => !prev)
  }

  const openImportExample = () => {
    setShowImportExample((prev) => !prev)
  }

  const openExtraImportInfo = () => {
    setShowExtraImportInfo((prev) => !prev)
  }

  return (
    <>
      {showHelpModal ? (
        <div className='modal_background' onClick={closeModal} ref={modalRef}>
          <div className='help-modal_wrapper'>
            <div className='u-px-4 help-modal_content'>
              <div className='help-modal--header'>
                <h1>{lg(lang, CREATING_A_NEW_ORDER)}</h1>
                <div className='tabs u-mb-4 switcher'>
                  <div className='tabs__links'>
                    <div>
                      <a
                        onClick={() => setTabImport(false)}
                        className={
                          'button-as-anchor tabs__link' +
                          (!tabImport ? ' tabs__link--active' : '')
                        }
                      >
                        {lg(lang, MANUALLY)}
                      </a>
                    </div>
                    <div>
                      <button
                        onClick={() => setTabImport(true)}
                        className={
                          'button-as-anchor tabs__link' +
                          (tabImport ? ' tabs__link--active' : '')
                        }
                      >
                        {lg(lang, BY_IMPORTING_DATA)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {!tabImport ? (
                <>
                  <div className='u-mt-9 u-pt-9'>
                    <ol className='custom-counter u-mt-5 u-mx-8'>
                      <li>
                        {lg(lang, HM_STEP_1)}{' '}
                        <strong>{lg(lang, HM_STEP_1_BOLD)}</strong>
                        .&nbsp;
                        <QuestionCircleFillIcon
                          fill='#3CDCC8'
                          width={20}
                          height={20}
                          onClick={openAddEmployee}
                          className='modal__trigger'
                        />
                      </li>
                      <AddEmployeeModal
                        showAddEmployeeModal={showAddEmployee}
                        setShowAddEmployeeModal={setShowAddEmployee}
                      />
                      <li>
                        {lg(lang, HM_STEP_2)}{' '}
                        <strong>{lg(lang, ADD_TO_CART)}</strong>.
                      </li>
                      <li>{lg(lang, HM_STEP_3)}</li>
                      <li>{lg(lang, HM_STEP_4)}</li>
                      <li>{lg(lang, HM_STEP_5)}</li>
                      <li>
                        {lg(lang, HM_STEP_6)}{' '}
                        <strong>{lg(lang, HM_STEP_6_BOLD)}</strong>.
                      </li>
                    </ol>
                    <button
                      className='btn btn--primary btn--large help-modal_btn u-mt-5 u-mb-2'
                      onClick={() => setShowHelpModal(false)}
                    >
                      <DeleteIcon />
                      {lg(lang, CLOSE)}
                    </button>
                    <HelpFooter firstLevelClassNames='help-modal_text' />
                  </div>
                </>
              ) : (
                <>
                  <div className='u-mt-9 u-pt-5'>
                    <ol className='custom-counter u-mx-8 u-mt-2'>
                      <li>
                        {lg(lang, HM_IMPORT_STEP_1)}&nbsp;
                        <strong>{lg(lang, HM_IMPORT_STEP_1_BOLD)}</strong>
                        . &nbsp;
                        <QuestionCircleFillIcon
                          fill='#3CDCC8'
                          width={20}
                          height={20}
                          onClick={openImportExample}
                          className='modal__trigger'
                        />
                      </li>
                      <ImportExampleModal
                        showImportExampleModal={showImportExample}
                        setShowImportExampleModal={setShowImportExample}
                      />
                      <li>
                        {lg(lang, HM_IMPORT_STEP_2)}&nbsp;
                        <strong>{lg(lang, HM_IMPORT_STEP_2_BOLD)}</strong>
                        &nbsp;&nbsp;
                        <QuestionCircleFillIcon
                          fill='#3CDCC8'
                          width={20}
                          height={20}
                          onClick={openExtraImportInfo}
                          className='modal__trigger'
                        />
                        <div className='help-modal--2__columns u-pt-1'>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, NAME)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, SURNAME)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, FIRM_NAME)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, STREET_HOUSE_NUMBER)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, CITY)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, ZIP)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, CONTACT_PERSON)}{' '}
                            {lg(lang, NAME_SURNAME_FIRST_CELL)}
                          </div>
                          <div className='help-modal__list-items u-pb-1'>
                            {lg(lang, PHONE_NUMBER_OF_THE_CONTACT_PERSON)}
                          </div>
                          {/* Jméno; Příjmení; Název společnosti; Ulice a číslo popisné; Město; PSČ;
                                                    Kontaktní osoba {'(jméno a příjmení v 1 buňce)'}; Telefonní číslo kontaktní osoby; ? */}
                        </div>
                      </li>
                      <ExtraImportInfoModal
                        showExtraImportInfoModal={showExtraImportInfo}
                        setShowExtraImportInfoModal={setShowExtraImportInfo}
                      />
                      <li>
                        {lg(lang, HM_IMPORT_STEP_3)}
                        <strong>{lg(lang, SAVE)}</strong>.
                      </li>
                    </ol>

                    <button
                      className='btn btn--primary btn--large help-modal_btn u-mt-5 u-mb-2'
                      onClick={() => setShowHelpModal(false)}
                    >
                      <DeleteIcon />
                      {lg(lang, CLOSE)}
                    </button>
                    <HelpFooter firstLevelClassNames='help-modal_text-2 u-mt-9' />
                  </div>
                </>
              )}
            </div>
            <DeleteThinIcon
              className='help-modal_close'
              aria-label='Close modal'
              onClick={() => setShowHelpModal((prev: any) => !prev)}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
