import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector, useOnClickOutsideAndEscape } from 'app/hooks'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import Color from 'app/colors'
import { lg } from 'app/facade'
import {
  ADDRESS_FOR_CARDS_DELIVERY,
  EMPLOYEE_ID,
  HM_AE_STEP_1,
  HM_AE_STEP_2,
  HM_AE_STEP_3_1,
  HM_AE_STEP_3_2,
  NAME_AND_SURNAME,
  NEW_DELIVERY_ADDRESS,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface IAddEmployeeModal {
  showAddEmployeeModal: boolean
  setShowAddEmployeeModal: any
}

export const AddEmployeeModal = ({
  showAddEmployeeModal,
  setShowAddEmployeeModal,
}: IAddEmployeeModal) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const lang = useAppSelector(selectLanguage) || 'cz'
  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowAddEmployeeModal(false)
    }
  }

  useOnClickOutsideAndEscape(
    modalRef,
    () => setShowAddEmployeeModal(false),
    showAddEmployeeModal,
    setShowAddEmployeeModal
  )

  return (
    <>
      {showAddEmployeeModal ? (
        <div className='modal_background' onClick={closeModal} ref={modalRef}>
          <div className='add-employee-modal_wrapper'>
            <div className='small-modal_content'>
              <div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>
                    {lg(lang, NAME_AND_SURNAME)}
                  </h4>
                  <p>{lg(lang, HM_AE_STEP_1)}</p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>
                    {lg(lang, EMPLOYEE_ID)}
                  </h4>
                  <p>{lg(lang, HM_AE_STEP_2)}</p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>
                    {lg(lang, ADDRESS_FOR_CARDS_DELIVERY)}
                  </h4>
                  <p>
                    {lg(lang, HM_AE_STEP_3_1)}&nbsp;
                    <strong>{lg(lang, NEW_DELIVERY_ADDRESS)}.</strong>{' '}
                    {lg(lang, HM_AE_STEP_3_2)}
                  </p>
                </div>
              </div>
            </div>
            <DeleteThinIcon
              className='small-modal_close'
              aria-label='Close modal'
              onClick={() => setShowAddEmployeeModal((prev: any) => !prev)}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
