import React, { FunctionComponent } from 'react'
import { selectKontaktniEmail } from 'components/onboarding/features/objednavatelSlice'
import Mail from '@styles/image/order-recap-mail.svg'
import { numberWithSpaces } from 'app/util'
import { selectLastOrderPrice } from 'components/table/overviewSlice'
import { lg } from 'app/facade'
import {
  AMOUNT_TO_BE_PAID,
  CARDS_DELIVERY_INFO,
  CREDIT_ASSIGNMENT_INFO,
  NO_VAT,
  ORDER_SUMMARY,
  THE_PAYMENT_DETAILS_WERE_SENT,
  TO_EMAIL,
} from 'app/constDictionary'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'

const OrderEmailRecap: FunctionComponent = () => {
  const kontaktniEmail = useAppSelector(selectKontaktniEmail)
  const totalPriceWithFeesAndDelivery = useAppSelector(selectLastOrderPrice)
  const lang = useAppSelector(selectLanguage) || 'cz'
  return (
    <div>
      <div className='u-bg-light-grey u-py-5 u-px-3'>
        <h3 className='u-size-l20 u-mb-2'>{lg(lang, ORDER_SUMMARY)}</h3>
        <p className='u-size-base14 u-mb-3'>
          {lg(lang, TO_EMAIL)}{' '}
          <a href={'mailto:' + kontaktniEmail}>{kontaktniEmail}</a>{' '}
          {lg(lang, THE_PAYMENT_DETAILS_WERE_SENT)}
        </p>
        <p>
          <strong>
            {lg(lang, AMOUNT_TO_BE_PAID)}{' '}
            {numberWithSpaces(totalPriceWithFeesAndDelivery.toFixed(2))} Kč
          </strong>
        </p>
        <small className='cell auto u-pb-0'>
          <span className='small-text u-color-c1-60'>{lg(lang, NO_VAT)}</span>
        </small>
      </div>
      <div className='u-pt-3 u-px-3 u-pb-4'>
        <p className='u-size-s u-mb-4'>
          {lg(lang, CARDS_DELIVERY_INFO)}
          <br />
          {lg(lang, CREDIT_ASSIGNMENT_INFO)}
        </p>
        {/* <div className='u-text-center'>
          <a className='btn btn--primary'>
            <svg className='icon icon--24'>
              <DeleteThinIcon width={24} height={24} />
            </svg>
            Zavřít
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default OrderEmailRecap
