import React from 'react'

interface TooltipProps {
  children: React.ReactElement
}

const Tooltip: React.FC<TooltipProps> = ({ children }) => {
  return (
    <div
      style={{
        // there is some bug with the position of the tooltip
        // so we have to use absolute positioning
        // https://stackoverflow.com/questions/39146047/display-tooltip-when-container-overflow-is-hidden
        position: 'absolute',
        zIndex: 1000, // not needed
        // width: '42rem',
        // height: '2.5rem', // not needed
        // maxHeight: '2.5rem',
        // minWidth: '15rem',
        width: '23rem', // too wide for small screens
        // width: '100%', // too wide for small screens
        padding: '0.5rem',
        paddingLeft: '1.25rem',
        paddingRight: '0.75rem',
        // borderRadius: '1.5rem',
        // top: '0rem',
        // right: '-1.5rem',
      }}
      className='btn__hover'
    >
      {children}
    </div>
  )
}

export default Tooltip
