import { FunctionComponent, useState, useEffect } from 'react'
import { useAppSelector } from 'app/hooks'
import {
  selectPocetZamestnancu,
  selectDenniPrispevekNaStravuOnboarding,
  selectVolnocasovyKreditNaMesic,
  selectOdpracovanychDnuVMesici,
  selectEnabledDenniPrispevekNaStravu,
  selectEnabledVolnocasovyKreditNaMesic,
} from 'components/onboarding/features/mesicniNakladySlice'
import { numberWithSpacesMax2 } from 'app/util'
import { getSpravaUctuPriceFormatted } from 'app/numberOps'
import { selectCenik } from '../features/firemniUdajeSlice'
import { lg } from 'app/facade'
import {
  ACCOUNT_MANAGEMENT_FEE,
  CREDIT_ORDER_FEE,
  LEISURE_CREDIT,
  MEAL_VOUCHER_CREDIT,
  MONTHLY_FEES,
  ORDER_FEE,
  YOUR_PRICE,
  PRICE_WITHOUT_VAT,
  TOTAL_PRICE,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

const VaseMesicniNaklady: FunctionComponent = () => {
  const enabledDenniPrispevekNaStravu = useAppSelector(
    selectEnabledDenniPrispevekNaStravu
  )
  const enabledVolnocasovyKreditNaMesic = useAppSelector(
    selectEnabledVolnocasovyKreditNaMesic
  )
  let pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const denniPrispevekNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )
  const odpracovanychDnuMesicne = useAppSelector(selectOdpracovanychDnuVMesici)

  function stravenkovyKreditProZamestnance() {
    return (
      (pocetZamestnancu === undefined ? 0 : pocetZamestnancu) *
      denniPrispevekNaStravu *
      odpracovanychDnuMesicne
    )
  }

  function volnocasovyKreditProZamestnance() {
    return (
      (pocetZamestnancu === undefined ? 0 : pocetZamestnancu) *
      volnocasovyKreditNaMesic
    )
  }

  const cenik = useAppSelector(selectCenik)
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div
      className='highlight u-mb-3 u-pb-4 highlight--highlight-column'
      // style={{ padding: '32px 16px 24px 16px' }}
    >
      <div
        className='highlight__title'
        style={{ borderBottom: '1px solid #C2C2E4' }}
      >
        <span>{lg(lang, MONTHLY_FEES)}</span>
        <span className='u-desktop-only'>{lg(lang, YOUR_PRICE)}</span>
      </div>
      <div className='highlight__content u-mb-0'>
        {enabledDenniPrispevekNaStravu && (
          <div>
            <p className='grid-x'>
              <span className='cell auto u-pt-2'>
                {lg(lang, MEAL_VOUCHER_CREDIT)}
              </span>
              <span className='u-text-right cell cell--boxed u-pl-2 u-pr-1 u-pb-0 u-pt-2'>
                <b>
                  {numberWithSpacesMax2(stravenkovyKreditProZamestnance())} Kč
                </b>
              </span>
            </p>
            <p className='grid-x align-middle u-mt-0'>
              <small className='cell auto u-color-c1-40'>
                <span className='small-text'>{lg(lang, CREDIT_ORDER_FEE)}</span>
              </small>
              <small className='u-text-right cell cell--boxed u-ml-2 u-pl-2 u-pr-1'>
                <span>
                  {numberWithSpacesMax2(
                    stravenkovyKreditProZamestnance() * cenik.stravenky
                  )}{' '}
                  Kč
                </span>
              </small>
            </p>
          </div>
        )}
        {enabledVolnocasovyKreditNaMesic && (
          <div>
            <p className='grid-x u-mt-0'>
              <span className='cell auto u-pt-2'>
                {lg(lang, LEISURE_CREDIT)}
              </span>
              <span
                className={
                  // enabledDenniPrispevekNaStravu
                  // ?
                  'u-text-right cell cell--boxed u-pl-2 u-pr-1 u-pb-0 u-pt-2'
                  // : 'cell cell--boxed cell--radius-top  u-bg-light-blue u-color-dark-purple-blue u-px-2 u-pb-1 u-pt-2'
                }
              >
                <b>
                  {numberWithSpacesMax2(volnocasovyKreditProZamestnance())} Kč
                </b>
              </span>
            </p>
            <p className='grid-x align-middle u-mt-0'>
              <small className='cell auto u-color-c1-40 u-pb-0'>
                <span className='small-text'>{lg(lang, ORDER_FEE)}</span>
              </small>
              <small className='u-text-right cell cell--boxed u-pl-2 u-pr-1 u-pb-0'>
                <span>
                  {numberWithSpacesMax2(
                    volnocasovyKreditProZamestnance() * cenik.volnocas
                  )}{' '}
                  Kč
                </span>
              </small>
            </p>
          </div>
        )}
        <p className='grid-x u-mt-0 u-pb-1 u-pt-2'>
          <span className='cell auto'>{lg(lang, ACCOUNT_MANAGEMENT_FEE)}</span>
          <span className='u-text-right cell cell--boxed u-pl-2 u-pr-1 u-pb-0'>
            <b>
              {getSpravaUctuPriceFormatted(
                pocetZamestnancu === undefined ? 0 : pocetZamestnancu,
                cenik.spravaJednohoUctu
              )}{' '}
              Kč
            </b>
          </span>
        </p>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            borderTop: '1px solid #C2C2E4',
          }}
          className='grid-x u-mt-0'
        >
          <span className='cell auto u-pt-1'>{lg(lang, TOTAL_PRICE)}</span>
          <span className='u-text-right cell cell--boxed u-pl-0 u-pr-0 u-pb-0 u-pt-1'>
            <b>
              {numberWithSpacesMax2(
                stravenkovyKreditProZamestnance() *
                  (enabledDenniPrispevekNaStravu ? 1 + cenik.stravenky : 0) +
                  volnocasovyKreditProZamestnance() *
                    (enabledVolnocasovyKreditNaMesic ? 1 + cenik.volnocas : 0) +
                  (pocetZamestnancu === undefined ? 0 : pocetZamestnancu) *
                    cenik.spravaJednohoUctu
              )}{' '}
              Kč
            </b>
          </span>
        </p>
        <small className='cell auto u-color-c1-40 u-pb-1'>
          <span className='small-text'>{lg(lang, PRICE_WITHOUT_VAT)}</span>
        </small>
      </div>
    </div>
  )
}

export default VaseMesicniNaklady
