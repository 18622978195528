import React from 'react'
import { RootState } from 'app/store'
import { createSlice } from '@reduxjs/toolkit'
import { verne } from 'app/julesverne'

export interface ImportRow {
  jmeno: string
  prijmeni: string
  cisloZamestnance: string
  nazevSpolecnosti: string
  uliceCisloPopisne: string
  mesto: string
  psc: string
  kontaktniOsoba: string
  telefon: string
  volnocasovyKredit: string
  stravenkovyKredit: string
}

export interface ExcelRow {
  content: string[]
}

const initialState: ExcelRow[] = []

const dataImportSlice = createSlice({
  name: 'dataImport',
  initialState,
  reducers: {
    addRow: (state, action) => {
      state.push(action.payload)
    },
    arrayOfArraysFromExcel: (state, action) => {
      verne('arrayOfArraysFromExcel', action.payload)
      state.splice(0, state.length, ...action.payload)
    },
    resetInitialState(state) {
      state.splice(0, state.length)
      state.push(...initialState)
    },
  },
})

export const dataImportActions = dataImportSlice.actions

export const selectDataImport = (state: RootState) => state.dataImport

export default dataImportSlice.reducer
