import { useEffect, useState } from 'react'
import PenIcon from '@styles/icon/penIcon'
import { NEPLATNY_EMAIL } from 'app/constants'
import { useAppDispatch, useAppSelector, useModal } from 'app/hooks'
import { emailRegex } from 'app/util'
import { Modal } from 'components/modules/modal/Modal'
import OIIIModal from 'components/modules/modal/Modals'
import {
  selectNazevSpolecnosti,
  selectUliceCisloPopisne,
  selectMesto,
  selectPsc,
  selectIcoFirmy,
  reducerRestorePreviousFiremniUdaje,
} from 'components/onboarding/features/firemniUdajeSlice'
import {
  reducerPoznamkaNaFakture,
  reducerKontaktniEmail,
  selectKontaktniEmail,
  selectPracovniEmail,
} from 'components/onboarding/features/objednavatelSlice'
import { OpravneneOsobyList } from 'components/onboarding/OnboardingThree'
import {
  selectPocetNovychKaretKObjednani,
  selectTotalVolnocasove,
  selectTotalStravenkovy,
  selectPocetNahradnichKaretKObjednani,
} from 'components/table/tableSlice'
import { getFormattedDatumDoruceniKaret } from 'util/DateOps'
import { formatPsc } from 'util/ValidFormatOps'
import { selectLogin } from 'components/settings/governanceSlice'
import { lg } from 'app/facade'
import {
  BILLING_INFORMATION,
  CANCEL,
  CARDS_DELIVERY_DATE,
  CARDS_DELIVERY_INFO,
  CONTACT_FOR_INVOICE,
  CREDIT_ASSIGNMENT,
  CREDIT_ASSIGNMENT_INFO,
  EDIT,
  FOR_EXAMPLE,
  INVOICE_EMAIL_MISSING,
  NOTE_ON_INVOICE,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

const FakturacniUdajeOrderSummary = () => {
  const dispatch = useAppDispatch()

  const pocetNovychKaretKObjednani = useAppSelector(
    selectPocetNovychKaretKObjednani
  )
  const pocetNahradnichKaretKObjednani = useAppSelector(
    selectPocetNahradnichKaretKObjednani
  )
  const loginEmail = useAppSelector(selectLogin)?.email
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const kontaktniEmail = useAppSelector(selectKontaktniEmail)
  const totalVolnocasove = useAppSelector(selectTotalVolnocasove)
  const totalStravenkovy = useAppSelector(selectTotalStravenkovy)

  const [pristine, setPristine] = useState(true)
  const { isShown: isShownFakturacniUdaje, toggle: toggleFakturacniUdaje } =
    useModal()
  const onConfirm = () => toggleFakturacniUdaje()
  const onCancel = () => {
    dispatch(reducerRestorePreviousFiremniUdaje())
    toggleFakturacniUdaje()
  }
  const lang = useAppSelector(selectLanguage) || 'cz'

  function getKontaktniEmailProZaslaniFaktury(): string {
    return kontaktniEmail || loginEmail || pracovniEmail
  }

  return (
    <div>
      <div
        className='💪 u-mb-2 u-px-2'
        style={{ justifyContent: 'space-between' }}
      >
        <h3 className='u-size-l20 u-ml-0'>{lg(lang, BILLING_INFORMATION)}</h3>
        <button
          onClick={toggleFakturacniUdaje}
          className='button-as-anchor u-size-base14 u-mr-1'
        >
          <svg className='icon icon--16' style={{ marginRight: '8.75px' }}>
            <PenIcon width={16} height={16} />
          </svg>
          {lg(lang, EDIT)}
        </button>
      </div>
      <div
        className='highlight u-ml-0 u-py-2 u-px-2'
        style={{ width: '25rem' }}
      >
        <div className='highlight__content'>
          <div className='grid-x'>
            <div className='cell auto u-pt-05'>
              <strong>{useAppSelector(selectNazevSpolecnosti)}</strong>
              <p className='u-size-base14 u-mt-2'>{OpravneneOsobyList()}</p>
              <p className='u-size-base14 u-mt-2'>
                {useAppSelector(selectUliceCisloPopisne)},{' '}
                {useAppSelector(selectMesto)},{' '}
                {formatPsc(useAppSelector(selectPsc) || '')}
              </p>
              <p className='u-size-base14 u-mt-2 u-mb-5'>
                IČ {useAppSelector(selectIcoFirmy)}
              </p>
              <div className='input u-mb-3'>
                <label className='input__label'>
                  {lg(lang, NOTE_ON_INVOICE)}
                </label>
                <div className='input__wrapper'>
                  <input
                    onChange={(e) =>
                      dispatch(reducerPoznamkaNaFakture(e.currentTarget.value))
                    }
                    placeholder={`${lg(
                      lang,
                      FOR_EXAMPLE
                    )} Marketingové oddělení`}
                    type='text'
                  />
                </div>
              </div>
              {/* Kontaktní e-mail */}
              {/* {console.log(kontaktniEmail, pracovniEmail, loginEmail)} */}
              <div className='input u-mt-2 u-mb-05 input--required'>
                <label className='input__label'>
                  {lg(lang, CONTACT_FOR_INVOICE)}
                </label>
                <div className='input__wrapper'>
                  <input
                    value={getKontaktniEmailProZaslaniFaktury()} // kontaktni is the first priority, pracovni comes from onboarding, login comes from login (obviously). The latter two should not exist at the same time.
                    onChange={(e) => {
                      setPristine(false)
                      dispatch(reducerKontaktniEmail(e.target.value))
                    }}
                    placeholder={`${lg(lang, FOR_EXAMPLE)} novak@pluxee.cz`}
                    type='email'
                  />
                  {/* the condition !pristine && was here, but we must let the user know and it actually gets sent without the email! */}
                  {!pristine && (!kontaktniEmail || kontaktniEmail === '') && (
                    <div className='input__error'>
                      <span className='input__error-text'>
                        {lg(lang, INVOICE_EMAIL_MISSING)}
                      </span>
                    </div>
                  )}
                  {kontaktniEmail &&
                    kontaktniEmail !== '' && // otherwise the above error is displayed
                    !emailRegex.test(kontaktniEmail) && (
                      <div className='input__error'>
                        <span className='input__error-text'>
                          {NEPLATNY_EMAIL}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Datum doručení karet a připsání kreditu */}
      <div className='u-mt-4 u-ml-0' style={{ width: '23rem' }}>
        {pocetNovychKaretKObjednani + pocetNahradnichKaretKObjednani > 0 && (
          <div className='u-mb-3 u-mt-4 u-px-2'>
            <p>
              <strong>{lg(lang, CARDS_DELIVERY_DATE)}</strong>
            </p>
            <p className='u-size-base14 u-mt-1'>
              {getFormattedDatumDoruceniKaret(lang)}
            </p>
            <p className='u-size-s u-mt-1'>{lg(lang, CARDS_DELIVERY_INFO)}</p>
          </div>
        )}
        {totalVolnocasove + totalStravenkovy > 0 && (
          <div className='u-mb-3 u-px-2'>
            <p>
              <strong>{lg(lang, CREDIT_ASSIGNMENT)}</strong>
            </p>
            <p className='u-size-base14 u-mt-1'>
              {lg(lang, CREDIT_ASSIGNMENT_INFO)}
            </p>
          </div>
        )}
        {/* remnant - moved to Table Container (even though it belongs here more) */}
        <div className='u-text-center'>
          <div className='btn'>
            <svg className='icon icon--24'>{/* <DirectionIcon /> */}</svg>
            {/* Odeslat objednávku */}
          </div>
        </div>
      </div>
      <Modal
        isShown={isShownFakturacniUdaje}
        hide={onCancel}
        headerText={lg(lang, CANCEL)}
        subheaderText={lg(lang, BILLING_INFORMATION)}
        modalContent={<OIIIModal onSubmit={onConfirm} />}
      />
    </div>
  )
}

export default FakturacniUdajeOrderSummary
