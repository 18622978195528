import React, { Key, useEffect, useState } from 'react'
import Tip from './Tip'
import icons from '@styles/dist/system/icons.svg'
import PenIcon from '@styles/icon/penIcon'
import PlusIcon from '@styles/icon/plusIcon'
import TrashIcon from '@styles/icon/trashIcon'
import { Modal } from 'components/modules/modal/Modal'
import { modals, useAppDispatch, useAppSelector } from 'app/hooks'
import {
  CreateNewUserModal,
  EditUserModal,
  RemoveUserModal,
} from 'components/modules/modal/Modals'
import { govActions } from './governanceSlice'
import { mutateHlavniKontaktniOsobaBackend } from 'app/req'
import { Role, User } from 'app/type'
import { isInternal } from 'util/authOps'
import { lg } from 'app/facade'
import {
  CANCEL,
  CONTACT_PERSON,
  DELETE_CONTACT_PERSON,
  EDIT,
} from 'app/constDictionary'
import { selectLanguage } from './userSettingsSlice'
import { nanoid } from '@reduxjs/toolkit'

const SettingsKontaktniOsoby: React.FC = () => {
  const dispatch = useAppDispatch()

  const users = useAppSelector((state) => state.gov.users) as User[]
  const loginEmail = useAppSelector((state) => state.gov.login?.email) || ''

  const lang = useAppSelector(selectLanguage) || 'cz'

  console.log('users', users)
  console.log('loginEmail', loginEmail)

  // users = oprávněné osoby. Keep here, it's nice for testing purposes (Aktivní state is not reachable yet).
  // console.log('users', users)
  // useEffect(() => {
  //   const newUsers = [
  //     {
  //       jmeno: 'Tomáš',
  //       prijmeni: 'Vavříček',
  //       email: 'karel@sodexo.cz',
  //       role: 'Hlavní',
  //     },
  //     {
  //       jmeno: 'František',
  //       prijmeni: 'Černohorský',
  //       email: 'frantisek@sodexo.cz',
  //       role: 'Aktivní',
  //     },
  //     {
  //       jmeno: 'Jitka',
  //       prijmeni: 'Křížová',
  //       email: 'jitka@sodexo.cz',
  //       role: 'Vytvořená',
  //     },
  //   ]
  //   dispatch(governanceActions.setUsers(newUsers))
  // }, [])

  const hlavniKontaktniOsobaEmail =
    useAppSelector(
      (state) =>
        state.gov.users.filter((user) => user.role === Role.Hlavní)[0]?.email
    ) || ''

  // remove in production
  dispatch(govActions.addUserField())

  const { isShown: isShownUpravitOsobu, toggle: toggleUpravitOsobu } =
    modals[0]()
  const { isShown: isShownPridatOsobu, toggle: togglePridatOsobu } = modals[1]()
  const { isShown: isShownOdstranitOsobu, toggle: toggleOdstranitOsobu } =
    modals[2]()

  const [
    popoverNastavitHlavniKontaktniOsobu,
    setpopoverNastavitHlavniKontaktniOsobu,
  ] = useState(-1)

  // these default values are never used, it's just placeholders
  const [callerUser, setCallerUser] = useState<User>({
    jmeno: '',
    prijmeni: '',
    email: '',
    role: '',
    telefon: undefined,
    predvolba: undefined,
  })

  function togglePopoverNastavitHlavniKontaktniOsobu(index: number) {
    if (popoverNastavitHlavniKontaktniOsobu === index) {
      setpopoverNastavitHlavniKontaktniOsobu(-1) /* hide */
    } else {
      setpopoverNastavitHlavniKontaktniOsobu(index) /* show */
    }
  }
  function nastavitHlavniKontaktniOsobu(email: string): void {
    dispatch(govActions.setHlavniKontaktniOsoba(email))
    // first strip the admin role from myself? -> backend does it for us.
    mutateHlavniKontaktniOsobaBackend(email)
  }

  return (
    <div>
      {users.map((user, index) => (
        <div key={nanoid()}>
          <div className='input u-mb-2'>
            <label
              onClick={() => nastavitHlavniKontaktniOsobu(loginEmail)}
              className='input__label'
            >
              {lg(lang, CONTACT_PERSON)}
            </label>
            <div className='input__wrapper'>
              <input
                disabled={true}
                type='text'
                value={`${user.jmeno} ${user.prijmeni} (${user.email})`}
              />
              <span
                className={
                  'input__suffix ' +
                  (user.role === Role.Hlavní
                    ? 'u-color-red'
                    : user.role === Role.Aktivní
                    ? 'u-color-aquamarine'
                    : 'u-color-new-yellow')
                }
              >
                {user.role}
              </span>
            </div>
          </div>
          {(isInternal() ||
            loginEmail === hlavniKontaktniOsobaEmail ||
            user.email === loginEmail) && (
            <div className='grid-x align-justify u-mb-4'>
              <div>
                <button
                  onClick={() => {
                    toggleUpravitOsobu()
                    setCallerUser(user)
                  }}
                  className='button-as-anchor u-mr-2 u-size-s'
                >
                  <svg className='icon icon--16'>
                    <PenIcon width={16} height={16} />
                  </svg>
                  <strong>{lg(lang, EDIT)}</strong>
                </button>
                {user.email !== hlavniKontaktniOsobaEmail &&
                  user.email !== loginEmail && (
                    <button
                      onClick={() => {
                        toggleOdstranitOsobu()
                        setCallerUser(user)
                      }}
                      className='button-as-anchor u-size-s u-color-sodexo-red'
                    >
                      <svg className='icon icon--16'>
                        <TrashIcon width={16} height={16} />
                      </svg>
                      <strong>{lg(lang, DELETE_CONTACT_PERSON)}</strong>
                    </button>
                  )}
              </div>
              {(isInternal() && user?.role === Role.Aktivní) ||
                  (user.email !== hlavniKontaktniOsobaEmail &&
                  user.role === Role.Aktivní &&
                  loginEmail === hlavniKontaktniOsobaEmail) && (
                <div className='popover__wrapper'>
                  <button
                    onClick={() =>
                      togglePopoverNastavitHlavniKontaktniOsobu(index)
                    }
                    className='button-as-anchor u-size-s'
                  >
                    <svg className='icon icon--16'>
                      <use href={icons + '#sprite-employee'}></use>
                    </svg>{' '}
                    <strong>Nastavit jako hlavní kontaktní osobu</strong>
                  </button>{' '}
                  <div
                    className={
                      'popover popover--small' +
                      (popoverNastavitHlavniKontaktniOsobu === index
                        ? ' popover--open'
                        : '')
                    }
                  >
                    <div className='popover__body'>
                      <p className='u-color-dark-purple-blue u-size-base14 u-mb-1'>
                        <b>Opravdu chcete nastavit hlavní kontaktní osobu?</b>
                      </p>{' '}
                      <div>
                        <a
                          onClick={() => {
                            nastavitHlavniKontaktniOsobu(user.email)
                            togglePopoverNastavitHlavniKontaktniOsobu(index)
                          }}
                          className='btn u-size-s u-mb-1 btn--primary btn--tiny btn--wide'
                        >
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-check'}></use>
                          </svg>
                          Nastavit
                        </a>
                      </div>{' '}
                      <div>
                        <a
                          onClick={() =>
                            togglePopoverNastavitHlavniKontaktniOsobu(index)
                          }
                          className='btn u-size-s btn--primary btn--outlined btn--tiny btn--wide'
                        >
                          <svg className='icon icon--16'>
                            <use href={icons + '#sprite-delete-thin'}></use>
                          </svg>
                          Zrušit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <p className='u-mb-3'>
        Kontaktní osoby mohou vytvářet objednávky a spravovat seznam
        zaměstnanců.
      </p>
      <Tip
        id={'kontaktni-osoby-tip'}
        content={
          "Pouze hlavní kontaktní osoba může spravovat ostatní kontaktní osoby. Toto právo může být postoupeno pomocí funkce 'Nastavit jako hlavní kontaktní osobu.'"
        }
      />
      {(loginEmail === hlavniKontaktniOsobaEmail || isInternal()) && (
        <div>
          <a
            onClick={togglePridatOsobu}
            className='btn btn--primary btn--small'
          >
            <svg className='icon icon--24'>
              <PlusIcon width={24} height={24} />
            </svg>
            Přidat další kontaktní osobu
          </a>
        </div>
      )}
      <Modal
        isShown={isShownPridatOsobu}
        hide={togglePridatOsobu}
        headerText={lg(lang, CANCEL)}
        subheaderText='Přidat novou kontaktní osobu'
        modalContent={<CreateNewUserModal onSubmit={togglePridatOsobu} />}
      />
      <Modal
        isShown={isShownUpravitOsobu}
        hide={toggleUpravitOsobu}
        headerText={lg(lang, CANCEL)}
        subheaderText='Upravit kontaktní osobu'
        modalContent={
          <EditUserModal
            onSubmit={toggleUpravitOsobu}
            callerUser={callerUser}
          />
        }
      />
      <Modal
        isShown={isShownOdstranitOsobu}
        hide={toggleOdstranitOsobu}
        headerText={lg(lang, CANCEL)}
        subheaderText={lg(lang, DELETE_CONTACT_PERSON)}
        modalContent={
          <RemoveUserModal
            onSubmit={toggleOdstranitOsobu}
            onCancel={toggleOdstranitOsobu}
            callerUser={callerUser}
            loggedInUserEmail={loginEmail}
          />
        }
      />
    </div>
  )
}

export default SettingsKontaktniOsoby
