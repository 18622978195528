export const D_CSS = 'color: #daa520; background: black; font-weight: bold;'
export const D_SINKING = true

export function exists(x: any): boolean {
  return x !== undefined && x !== null
}

export function sinking(): boolean {
  return D_SINKING
}

export function verne(...args: any[]): boolean {
  if (D_SINKING) {
    console.log(
      `%c${new Date().toLocaleString()} -${args.flatMap(
        (a) => ` ${exists(a) ? JSON.stringify(a) : 'null'}`
      )}`,
      D_CSS
    )
    // debugger -> doesn't work in production
  }
  return true
}

export function vernerr(...args: any[]) {
  console.log(
    `%c${new Date().toLocaleString()} -${args.flatMap((a) =>
      ' ' + exists(a) ? JSON.stringify(a) : 'null'
    )}`,
    D_CSS
  )
  // debugger -> doesn't work in production
}

export const timeDoingSomething = (f: () => void) => {
  console.time('doSomething()')
  //do something, and measure its performance
  f()
  console.timeEnd()
}

export const profileDoingSomething = (f: () => void) => {
  console.profile('doSomething()')
  //do something, and measure its performance
  f()
  console.profileEnd()
}

// console.log(`%c${args.join(' ')}`, D_CSS)
