import React, { FunctionComponent } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  tableActions,
  selectRowById,
  selectTable,
} from 'components/table/tableSlice'
import { NEPLATNE_JMENO, NEPLATNE_PRIJMENI } from 'app/constants'
import { selectAddresses } from '../settings/governanceSlice'
import AddressesUserDetailAdd from './EmployeeAddAddresses'
import {
  reducerNewBornEmployeeUliceCisloPopisne,
  reducerNewBornEmployeeDepartment,
  selectDenniKredit,
  reducerAddDenniKreditZamestnance,
  reducerRemoveDenniKreditZamestnance,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import ShoppingCartIcon from '@styles/icon/shoppingCartIcon'
import { MultiPassCardState } from 'util/CardOps'
import { validateNumericKeyStrict } from 'app/util'
// import SodexoMPC from '@styles/image/sodexo-mpc.png'
import PluxeeCard from '@styles/image/pluxee-card.png'
import { getPersonId } from 'util/TableOps'
import { getCurrentUserUnsafe, lg } from 'app/facade'
import {
  ADD_NEW_EMPLOYEE,
  ADD_TO_CART,
  EMPLOYEE_ADD_DETAIL_1,
  EMPLOYEE_ADD_DETAIL_2,
  EMPLOYEE_ID,
  EMPLOYEE_ID_HELP,
  FOR_EXAMPLE,
  NAME,
  SURNAME,
} from 'app/constDictionary'
import { isValidJmeno, isValidPrijmeni } from 'util/ValidFormatOps'
import { User } from 'app/type'

export interface EmployeeAddProps {
  onSubmit: () => void
}

const EmployeeAdd: FunctionComponent<EmployeeAddProps> = ({ onSubmit }) => {
  const dispatch = useAppDispatch()

  const denniKredit = useAppSelector(selectDenniKredit)
  const addresses = useAppSelector(selectAddresses)

  const [shouldOrderCard, setShouldOrderCard] = React.useState(true)
  const currentUser = getCurrentUserUnsafe() as User
  function isBabyEmployeeTheCurrentUser() {
    return currentUser.jmeno === name && currentUser.prijmeni === surname
  }

  // SECTION FOR ADDING NEW EMPLOYEE
  const [hasValidated, setHasValidated] = React.useState(false)
  const [id, setId] = React.useState('')
  const [name, setName] = React.useState('')
  const [surname, setSurname] = React.useState('')
  const lang = useAppSelector(selectLanguage) || 'cz'

  const zamestnancovaAdresa = useAppSelector(
    (state) => state.userSettings.babyUliceCisloPopisne
  )
  const zamestnancovoOddeleni = useAppSelector(
    (state) => state.userSettings.babyDepartment
  )

  const selectEmpById = useAppSelector(selectRowById(id))
  const employees = useAppSelector(selectTable)

  async function addDenniKreditZamestnance(finalId: string) {
    dispatch(reducerRemoveDenniKreditZamestnance({ id: finalId }))
    dispatch(
      reducerAddDenniKreditZamestnance({ id: finalId, kredit: denniKredit })
    )
    console.log('DENNIKREDIT ZAMESTNANCE ADDED,' + id)
  }

  async function addEmpRowToTheTableHandler(): Promise<Boolean> {
    if (isValidEmployee()) {
      if (selectEmpById) {
        alert('Zaměstnanec s tímto ID již existuje')
        return false
      }

      const finalId = id || getPersonId(employees)

      console.log('addEmpRowToTheTableHandler', {
        finalId,
        name,
        surname,
        zamestnancovaAdresa,
        zamestnancovoOddeleni,
      })

      await addDenniKreditZamestnance(finalId)

      const isLoggedInUser = shouldOrderCard && isBabyEmployeeTheCurrentUser()
      console.log('isLoggedInUser', isLoggedInUser)
      dispatch(
        tableActions.addRowToTheTable({
          id: finalId, // can be empty string
          name: name.trim(),
          surname: surname.trim(),
          cardStatus: MultiPassCardState.VKošíku,
          oddeleni: zamestnancovoOddeleni,
          uliceCisloPopisne: zamestnancovaAdresa,
          group: 'Nezařazení',
          isLoggedInUser: isLoggedInUser, // also check if is still the current user, just in case the name gets changed after the radio button selection, and the radio button remains in true position...
        })
      )

      dispatch(
        reducerNewBornEmployeeUliceCisloPopisne(addresses[0].uliceCisloPopisne)
      )
      dispatch(reducerNewBornEmployeeDepartment(addresses[0].oddeleni[0]))

      clearForm()
      return true
    }
    return false
  }

  function isValidEmployee() {
    setHasValidated(true)
    if (
      !isValidJmeno(name) ||
      !isValidPrijmeni(surname) ||
      addresses.length === 0 ||
      !zamestnancovaAdresa ||
      !zamestnancovoOddeleni
    ) {
      return false
    } else {
      return true
    }
  }

  function clearForm() {
    setId('')
    setName('')
    setSurname('')
    setHasValidated(false)
  }

  return (
    <div>
      <h1 className='u-size-l20 u-mb-3'>{lg(lang, ADD_NEW_EMPLOYEE)}</h1>
      <div className='highlight u-mb-3 u-py-3 u-px-2'>
        <div className='highlight__content'>
          <div className='input u-mb-4 input--required'>
            <label className='input__label'>{lg(lang, NAME)}</label>
            <div className='input__wrapper'>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={`${lg(lang, FOR_EXAMPLE)} Karel`}
                type='text'
              />
              {hasValidated && !isValidJmeno(name) && (
                <span className='input__error'>{NEPLATNE_JMENO}</span> // todo: translate
              )}
            </div>
          </div>
          <div className='input u-mb-4 input--required'>
            <label className='input__label'>{lg(lang, SURNAME)}</label>
            <div className='input__wrapper'>
              <input
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                placeholder={`${lg(lang, FOR_EXAMPLE)} Novák`}
                type='text'
              />
              {hasValidated && !isValidPrijmeni(surname) && (
                <span className='input__error'>{NEPLATNE_PRIJMENI}</span> // todo: translate
              )}
            </div>
          </div>
          <div className='input u-mb-3'>
            <label className='input__label'>{lg(lang, EMPLOYEE_ID)}</label>
            <div className='input__wrapper'>
              <input
                type='text'
                value={id}
                onKeyPress={(e: any) => validateNumericKeyStrict(e)}
                onChange={(e) => setId(e.target.value.replace(/\D/g, ''))}
                placeholder={`${lg(lang, FOR_EXAMPLE)} 123321`}
              />
            </div>
            <span className='input__help'>{lg(lang, EMPLOYEE_ID_HELP)}</span>
          </div>
          {isBabyEmployeeTheCurrentUser() && (
            <div className='u-mb-1'>
              <label className='input__label u-mr-2 radio'>
                Tato karta je pro mě
                <label className='radio u-ml-2'>
                  <input
                    type='radio'
                    name='should-order-card' // uncomment and have fun dying
                    checked={shouldOrderCard}
                    onChange={() => setShouldOrderCard(true)}
                  />
                  <span className='radio__control'></span>
                  <span className='radio__label'>Ano</span>
                </label>
                <label className='radio u-ml-2'>
                  <input
                    type='radio'
                    name='should-order-card' // uncomment and have fun dying
                    checked={!shouldOrderCard}
                    onChange={() => setShouldOrderCard(false)}
                  />
                  <span className='radio__control'></span>
                  <span className='radio__label'>Ne</span>
                </label>
              </label>
              {/* {console.log('shouldOrderCard', shouldOrderCard)} */}
            </div>
          )}
        </div>
      </div>
      {/* ADRESY */}
      <AddressesUserDetailAdd hasValidated={hasValidated} />
      <div className='highlight u-mb-3 u-py-3 u-px-2'>
        <div className='highlight__content'>
          <img
            src={PluxeeCard}
            // srcSet={NoResult2x + ' 2x'}
            width='303'
            height='auto'
            alt='Nebyl nalezen žádný záznam'
          />
          <p className='u-size-base14 u-mt-2'>
            {lg(lang, EMPLOYEE_ADD_DETAIL_1)}
          </p>
          <p className='u-size-base14'>{lg(lang, EMPLOYEE_ADD_DETAIL_2)}</p>
        </div>
      </div>
      <a
        onClick={() => {
          addEmpRowToTheTableHandler().then((res) => {
            if (res === true) onSubmit()
          })
        }}
        className={
          'btn u-pl-2 u-size-base14 btn--primary btn--small'
          // + (!shouldOrderCard ? ' btn--disabled' : '')
        }
      >
        <svg className='icon icon--24'>
          <ShoppingCartIcon width={24} height={24} />
        </svg>
        {lg(lang, ADD_TO_CART)}
      </a>
    </div>
  )
}

export default EmployeeAdd
