import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectPocetNahradnichKaretKObjednani,
  selectPocetNovychKaretKObjednani,
  selectTable,
  selectTotalStravenkovy,
  selectTotalVolnocasove,
  selectZamestnanciSDorucovanimNahradniKarty,
  selectZamestnanciSDorucovanimNoveKarty,
  tableActions,
  TableRow,
} from 'components/table/tableSlice'
import { govActions, selectAddresses } from '../settings/governanceSlice'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import {
  equalsBezRozdiluVCisluOrientacnim,
  findMatchingAddress,
} from 'util/ValidFormatOps'
import { selectCenik } from 'components/onboarding/features/firemniUdajeSlice'
import { EMPTY_PSC } from 'app/constants'
import { Address, Cenik } from 'app/type'
import { newDate, getSecondDayOfCurrentMonth } from 'util/DateOps'
import { getCalculatedFee } from 'app/numberOps';

export interface DeliveryAddressAndCardsCount {
  key: Address
  pocetNovychKaret: number
  pocetNahradnichKaret: number
}

// returns an array of addresses and the number of cards for each address
export function useDorucovaciAdresyMap(): DeliveryAddressAndCardsCount[] {
  const addresses = useAppSelector(selectAddresses)
  const zamestnanciNovaKarta = useAppSelector(
    selectZamestnanciSDorucovanimNoveKarty
  )
  const zamestnanciNahradniKarta = useAppSelector(
    selectZamestnanciSDorucovanimNahradniKarty
  )

  let adressesMap = new Map<Address, [number, number]>()
  for (const employee of zamestnanciNovaKarta) {
    const matchingAddress = findMatchingAddress(
      employee.uliceCisloPopisne,
      addresses
    ) as Address
    const adresa: Address = {
      nazevSpolecnosti: matchingAddress ? matchingAddress.nazevSpolecnosti : '',
      kontaktniOsoba: matchingAddress ? matchingAddress.kontaktniOsoba : '',
      uliceCisloPopisne: matchingAddress
        ? matchingAddress.uliceCisloPopisne
        : '',
      mesto: matchingAddress ? matchingAddress.mesto : '',
      psc: matchingAddress ? matchingAddress.psc : EMPTY_PSC,
      telefon: matchingAddress ? matchingAddress.telefon : 0,
      oddeleni: matchingAddress ? matchingAddress.oddeleni : [],
      predvolba: matchingAddress ? matchingAddress.predvolba : 420,
    }
    if (adressesMap.has(adresa)) {
      const pocet = adressesMap.get(adresa) || [0, 0]
      adressesMap.set(adresa, [pocet[0] + 1, pocet[1]])
    } else {
      adressesMap.set(adresa, [1, 0])
    }
  }
  for (const employee of zamestnanciNahradniKarta) {
    const uliceCisloPopisne = employee.uliceCisloPopisne
    const matchingAddress = addresses.find((address) => {
      return equalsBezRozdiluVCisluOrientacnim(
        address.uliceCisloPopisne,
        uliceCisloPopisne
      )
    })
    // why do we have the fallbacks here?
    const adresa: Address = {
      nazevSpolecnosti: matchingAddress ? matchingAddress.nazevSpolecnosti : '',
      kontaktniOsoba: matchingAddress ? matchingAddress.kontaktniOsoba : '',
      uliceCisloPopisne: matchingAddress
        ? matchingAddress.uliceCisloPopisne
        : '',
      mesto: matchingAddress ? matchingAddress.mesto : '',
      psc: matchingAddress ? matchingAddress.psc : EMPTY_PSC,
      telefon: matchingAddress ? matchingAddress.telefon : 0,
      oddeleni: matchingAddress ? matchingAddress.oddeleni : [],
      predvolba: matchingAddress ? matchingAddress.predvolba : 420,
    }
    if (adressesMap.has(adresa)) {
      const pocet = adressesMap.get(adresa) || [0, 0]
      adressesMap.set(adresa, [pocet[0], pocet[1] + 1])
    } else {
      adressesMap.set(adresa, [0, 1])
    }
  }

  // sort alphabetically by uliceCisloPopisne
  let keys = Array.from(adressesMap.keys()).sort((a, b) => {
    return a.uliceCisloPopisne.localeCompare(b.uliceCisloPopisne)
  })
  let values = Array.from(adressesMap.values())
  let keyvalues: DeliveryAddressAndCardsCount[] = []

  // create an array of addresses and the number of cards for each address
  for (let i = 0; i < keys.length; i++) {
    let exists = false
    let countNovychKaret = values[i][0]
    let countNahradnichKaret = values[i][1]
    for (let j = 0; j < keyvalues.length; j++) {
      if (
        equalsBezRozdiluVCisluOrientacnim(
          keyvalues[j].key.uliceCisloPopisne,
          keys[i].uliceCisloPopisne
        )
      ) {
        exists = true
        keyvalues[j].pocetNovychKaret += countNovychKaret
        keyvalues[j].pocetNahradnichKaret += countNahradnichKaret
        break
      }
    }
    if (!exists) {
      if (keys[i].uliceCisloPopisne !== '') {
        keyvalues.push({
          key: keys[i],
          pocetNovychKaret: values[i][0],
          pocetNahradnichKaret: values[i][1],
        })
      }
    }
  }
  return keyvalues
}

export const useTotalPriceWithFeesAndDelivery = (): number => {
  const monthsToPay = useTotalMonthsToPay(1) // change request from business to only bill this and the last month (Petra Putyrova, 22.02.2022)
  const cenik = useAppSelector(selectCenik)
  const pocetNovychKaretKObjednani = useAppSelector(
    selectPocetNovychKaretKObjednani
  )
  const pocetNahradnichKaretKObjednani = useAppSelector(
    selectPocetNahradnichKaretKObjednani
  )
  const totalVolnocasove = useAppSelector(selectTotalVolnocasove)
  const totalStravenkovy = useAppSelector(selectTotalStravenkovy)

  // overkill - only for adding delivery fee for each address (26.4.2022)
  const consignments = useDorucovaciAdresyMap()

  // console.log('monthsToPay', monthsToPay)

  // console.log('cenik', cenik)
  // console.log('pocetNovychKaretKObjednani', pocetNovychKaretKObjednani)
  // console.log('pocetNahradnichKaretKObjednani', pocetNahradnichKaretKObjednani)
  // console.log('totalVolnocasove', totalVolnocasove)
  // console.log('totalStravenkovy', totalStravenkovy)
  // console.log('consignments', consignments)

  const totalPrice = // round to 2 fractional digits
    getTotalPrice(
      totalStravenkovy,
      cenik,
      totalVolnocasove,
      monthsToPay,
      pocetNovychKaretKObjednani,
      pocetNahradnichKaretKObjednani,
      consignments
    )
  // console.log('total price', totalPrice)
  // console.log(
  //   'sprava uctu atd',
  //   monthsToPay * cenik.spravaJednohoUctu +
  //     cenik.vydaniPrvniKarty * pocetNovychKaretKObjednani +
  //     cenik.vydaniNahradniKarty * pocetNahradnichKaretKObjednani +
  //     cenik.doruceni * consignments.length
  // )
  // console.log(
  //   'stravenky a volnocas s poplatkem',
  //   totalStravenkovy +
  //     minimumXIfNotZero(totalStravenkovy * cenik.stravenky) +
  //     totalVolnocasove +
  //     minimumXIfNotZero(totalVolnocasove * cenik.volnocas)
  // )
  // console.log(
  //   totalStravenkovy + minimumXIfNotZero(totalStravenkovy * cenik.stravenky)
  // )
  // console.log(
  //   totalVolnocasove + minimumXIfNotZero(totalVolnocasove * cenik.volnocas)
  // )
  return totalPrice
}

// two data structures:
// 1. paid months = represented by 32-bit integer
// 2. deactivated months = represented by 32-bit integer
// for each employee these months are filled in
// each month is represented by a bit
// if the bit is set, the employee has paid for that month
// if the bit is not set, the employee has not paid for that month
// use bit shift operations
// example:
// paid months = 0b11010101010101010101010100000000
// deactivated months = 0b001010...
// we pay for each non-paid month for every currently active employee? or for all employees?
// for all -> let the customer pay for the months he has not paid for both currently active and non-active employees
export const useTotalMonthsToPay = (historyDepth: number): number => {
  const employees = useAppSelector(selectTable)

  let beginningOfTime = new Date(
    useAppSelector((state) => selectorBeginningOfTime(state))
  )

  if (!beginningOfTime) useRestartBeginningOfTime()

  const monthsElapsed = monthsElapsedBeginningInclusive(beginningOfTime)
  if (monthsElapsed == 0) return 0

  const firstDayOfLastMonth = getFirstDayOfLastMonth()

  let employeeMonthsToPay = 0
  employees.forEach((employee) => {
    employeeMonthsToPay += computeEmployeeMonthsToPay(
      employee,
      monthsElapsed,
      historyDepth,
      firstDayOfLastMonth
    )
  })

  return employeeMonthsToPay
}

export function getFirstDayOfLastMonth(now?: Date): Date {
  if (!now) now = new Date()
  // Transformations in local timezone - could cause problems (see the comment below)
  // firstDayOfLastMonth.setMonth(firstDayOfLastMonth.getMonth() - 1)
  // firstDayOfLastMonth.setDate(1)
  // firstDayOfLastMonth.setHours(0, 0, 0, 0)

  // We need to use setUTC___ methods to avoid timezone problems. In other case, we would get wrong date.
  // For example, now is 2022-07-04T00:00:00.000Z, and we need to get the first day of last month, which is 2022-06-01T00:00:00.000Z
  // But what we'll get wit usual set___ methods is 2022-05-31T22:00:00.000Z, which is wrong. The cause of it is that our timezone is CET = UTC+2 (+1 in winter).

  // Usual setMonth(), setDate(), setHours() methods change the date in the local timezone (CET in our case). BUT! all the operations in JavaScript are performed in UTC.
  // So, basically, every time we change the date or compare the dates, it is all saving in UTC.
  // The transformation local timezone -> UTC is done under the hood by the JavaScript itself.
  // This problem will appear ALWAYS if we setHours() to 0, 0, 0, 0. Because the date is in UTC, and the timezone is CET, the date is in UTC+2 (+1 in winter).
  // This problem could also appear if we use newDate() when it's CET 00:00 - 02:00 (01:00 in winter). The saved Date will be the day before.
  // The difference between CET and UTC is -2 hours in summer and -1 hour in winter.
  // So, just to not get the wrong date, we need to use setUTC___ methods.

  now.setUTCMonth(now.getUTCMonth() - 1)
  now.setUTCDate(1)
  now.setUTCHours(0, 0, 0, 0)
  return now
}

export function computeEmployeeMonthsToPay(
  employee: TableRow,
  monthsElapsed: number,
  historyDepth: number,
  firstDayOfLastMonth: Date
): number {
  let employeeMonthsToPay = 0
  if (employee.flag === 'new') {
    console.debug(
      `employee ${employee.id} is new, not paying for the current month`
    )
    return 0
  }
  const paidMonths = employee.paidMonths
  const deactivatedMonths = employee.deactivatedMonths

  console.debug(
    `employee ${employee.id}, paidMonths ${paidMonths.toString(
      2
    )}, deactivatedMonths ${deactivatedMonths.toString(2)}`
  )
  for (let i = monthsElapsed - historyDepth; i < monthsElapsed; i++) {
    // this is a very good debug line, commenting out on 2022-12-8 to reduce the console output for Tomáš Čepička.
    // console.log(`i = ${i}, monthsElapsed = ${monthsElapsed}`)
    const monthBit = ONE >> i
    if ((paidMonths & monthBit) === 0) {
      // the common case - the employee has not paid for the month and was not deactivated
      if ((deactivatedMonths & monthBit) === 0) {
        employeeMonthsToPay++
        console.debug(
          `employee ${employee.id} had been activated for the whole month ${i}`
        )
        console.debug(`increasing monthsToPay to ${employeeMonthsToPay}`)
      } else {
        const deactivatedFrom = new Date(employee.deactivatedFrom!)
        if (deactivatedFrom > firstDayOfLastMonth) {
          employeeMonthsToPay++
          console.debug(
            `employee ${employee.id} had been deactivated only for a part of the month ${i}`
          )
          console.debug(`increasing monthsToPay to ${employeeMonthsToPay}`)
        } else {
          console.debug(
            `employee ${employee.id} had been deactivated for the whole month ${i}`
          )
        }
      }
    }
  }
  return employeeMonthsToPay
}

// export const useEmployeeMonthsToPayOriginal = (historyDepth: number): number => {
//   const employees = useAppSelector(selectTable)

//   let beginningOfTime = new Date(
//     useAppSelector((state) => selectorBeginningOfTime(state))
//   )

//   const dispatch = useAppDispatch()
//   if (!beginningOfTime) {
//     dispatch(tableActions.clearPaidMonths())
//     dispatch(tableActions.clearDeactivatedMonths())
//     // set beginning of time to the second day of the current month
//     const secondDayOfCurrentMonth = new Date()
//     secondDayOfCurrentMonth.setDate(1)
//     secondDayOfCurrentMonth.setHours(0, 0, 0, 0)
//     beginningOfTime = secondDayOfCurrentMonth
//     console.log(`setting beginning of time to ${secondDayOfCurrentMonth}`)
//     dispatch(govActions.reducerBeginningOfTime(secondDayOfCurrentMonth))
//   }
//   // for testing
//   // beginningOfTime.setMonth(beginningOfTime.getMonth() - 4)

//   const monthsElapsed = monthsElapsedX(beginningOfTime)
//   let employeeMonthsToPay = 0
//   for (const employee of employees) {
//     const paidMonths = employee.paidMonths
//     const deactivatedMonths = employee.deactivatedMonths
//     // since we pay for a month even if only a single day in it was active, pay for the full length of the month upfront
//     for (let i = 0; i <= monthsElapsed; i++) {
//       const monthBit = ONE >> i
//       // change request from business to only bill this and the last month (Petra Putyrova, 22.02.2022)
//       if (i === monthsElapsed || i > monthsElapsed - historyDepth) {
//         if (!(monthBit & (paidMonths | deactivatedMonths))) {
//           employeeMonthsToPay++
//         }
//       }
//     }
//     // paying for employee which is deactivated but not since the beginning of this month
//     const now = new Date()
//     if (
//       (ONE >> monthsElapsed) & deactivatedMonths &&
//       employee.deactivatedFrom &&
//       new Date(employee.deactivatedFrom).getMonth() === now.getMonth() &&
//       ((ONE >> monthsElapsed) & paidMonths) === 0
//     ) {
//       employeeMonthsToPay++
//     }
//   }
//   // return monthsToPay
//   return 0
// }

// The second part of the selector is the "combiner" and it will only re-run when the value selected in the first part changes. So we get a consistent object reference.
export const selectorBeginningOfTime = createSelector(
  (state: RootState) => state.gov.beginningOfTime,
  (rawValue) => rawValue
)

// * Full calendar months since the beginning of the application. Includes the first month as a full one even though it were a partial one (business req).
export const monthsElapsedBeginningInclusive = (
  from: Date,
  until?: Date
): number => {
  // never forget to wrap the date in a new object, otherwise it only gives us a string - prevention of modification of dates in redux or reactjs, I guess
  from = new Date(from)
  until = until ? new Date(until) : newDate()
  const year = until.getFullYear()
  const month = until.getMonth()
  // const month = until.getMonth() + 1 // for testing

  const monthsElapsed =
    month - from.getMonth() + (year - from.getFullYear()) * 12

  // console.log(
  //   `monthsElapsed, from: ${from} until: ${until}, monthsElapsed: ${monthsElapsed}`
  // )
  return monthsElapsed
}

export const useRestartBeginningOfTime = () => {
  const dispatch = useAppDispatch()

  dispatch(tableActions.clearPaidMonths())
  dispatch(tableActions.clearDeactivatedMonths())
  // set beginning of time to the second day of the current month (to account for timezones)
  const secondDayOfCurrentMonth = getSecondDayOfCurrentMonth()
  console.log(`renewing the beginning of time to ${secondDayOfCurrentMonth}`)
  dispatch(govActions.reducerBeginningOfTime(secondDayOfCurrentMonth))
}

// export const ZEROS = 0b0000000000000000_0000000000000000_0000000000000000_0000000000000000_0000000000000000_0000000000000000_0000000000000000_0000000000000000
export const ZEROS = 0b00000000_00000000_00000000_00000000_00000000_00000000_00000000_00000000
export const ONES = 0b11111111_11111111_11111111_11111111_11111111_11111111_11111111_11111111
export const ONE_64_BIT = 0b10000000_00000000_00000000_00000000_00000000_00000000_00000000_00000000
export const ONE_48_BIT = 0b00000000_00000000_10000000_00000000_00000000_00000000_00000000_00000000
export const ONE = 0b01000000_00000000_00000000_00000000 // should not this be moved one bit to the left? nobody knows now, but it must stay this way, because we cannot migrate the current employees in production (let's not force backend).

export const getTotalPrice = (
  totalStravenkovy: number,
  cenik: Cenik,
  totalVolnocasove: number,
  monthsToPay: number,
  pocetNovychKaretKObjednani: number,
  pocetNahradnichKaretKObjednani: number,
  consignments: DeliveryAddressAndCardsCount[]
): number => {
  return (
    Math.round(
      100 *
        (totalStravenkovy +
          getCalculatedFee(
              cenik.minStravenkyFee,
              totalStravenkovy,
              cenik.stravenky
          ) +
          totalVolnocasove +
          getCalculatedFee(
              cenik.minVolnocasFee,
              totalVolnocasove,
              cenik.volnocas
          ) +
          monthsToPay * cenik.spravaJednohoUctu +
          cenik.vydaniPrvniKarty * pocetNovychKaretKObjednani +
          cenik.vydaniNahradniKarty * pocetNahradnichKaretKObjednani +
          cenik.doruceni * consignments.length) // - added delivery fee for each address (26.4.2022)
    ) / 100
  )
}
