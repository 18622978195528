import QuestionCircleFillIcon from '@styles/icon/questionCircleFillIcon'
import Color from 'app/colors'
import { DO_YOU_NEED_HELP } from 'app/constDictionary'
import { lg } from 'app/facade'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import React, { useState } from 'react'
import { HelpModal } from './HelpModal'

interface HelpProps {
  dataImport?: boolean
}

const Help: React.FC<HelpProps> = ({ dataImport = false }) => {
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div>
      <HelpModal
        showHelpModal={showHelpModal}
        setShowHelpModal={setShowHelpModal}
        defaultPageDataImport={dataImport}
      />
      <button
        className='button-as-anchor u-ml-9 u-mr-1'
        onClick={() => setShowHelpModal(!showHelpModal)}
      >
        <p
          className=''
          style={{
            color: Color.BLUESODEXO,
          }}
        >
          {lg(lang, DO_YOU_NEED_HELP)}
        </p>
      </button>
      <QuestionCircleFillIcon
        fill={Color.BLUESODEXO}
        width={24}
        height={24}
        className='modal__trigger'
        onClick={() => setShowHelpModal(!showHelpModal)}
      />
    </div>
  )
}

export default Help
