import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class CheckIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'check-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M27.015 8.395c.61.56.65 1.51.09 2.12l-12.857 14a1.5 1.5 0 01-2.205.005L4.9 16.82a1.5 1.5 0 112.2-2.04l6.037 6.508L24.895 8.485a1.5 1.5 0 012.12-.09z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default CheckIcon
