import React, { FunctionComponent } from 'react'
import LogoSalesRepresentative from '@styles/icon/logoSalesRepresentative.svg'

const OfferAndLeadExit: FunctionComponent = () => {
  return (
    <div className='u-bg-dark-purple-blue u-py-9'>
      <div className='container container--wide'>
        <div className='grid-x align-center'>
          <div className='cell large-6 u-text-center u-pl-large-2'>
            <div className='highlight'>
              <div className='highlight__content'>
                <h3>
                  V nejbližší době vás bude kontaktovat náš&nbsp;obchodní
                  zástupce.
                </h3>
                <img src={LogoSalesRepresentative} alt='' className='u-my-4' />
                <p>Děkujeme za zájem o náš produkt</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfferAndLeadExit
