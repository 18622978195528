import React, { useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import GeoPinOutlineIcon from '@styles/icon/geoPinOutlineIcon'
import {
  NEPLATNE_PSC,
  NEPLATNA_ADRESA,
  NEPLATNA_KONTAKTNI_OSOBA,
} from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  telefonniCisloRegex,
  pscRegex,
  validateNumericKeyWithWhites,
} from 'app/util'
import {
  govActions,
  selectAddresses,
} from 'components/settings/governanceSlice'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import {
  equalsBezRozdiluVCisluOrientacnim,
  formatTelefon,
  isValidKontaktniOsoba,
  isValidMesto,
  isValidNazevSpolecnosti,
  isValidPsc,
  isValidTelefon,
  isValidUliceCisloPopisne,
  splitUliceCisloPopisne,
} from 'util/ValidFormatOps'
import { createAdresaBackend } from 'app/req'
import DeleteIcon from '@styles/icon/deleteIcon'
import { Address } from 'app/type'
import { lg } from 'app/facade'
import {
  CANCEL,
  CITY,
  CONTACT_PERSON,
  COUNTRY,
  DEPARTMENT_NAME,
  FIRM_NAME,
  FOR_EXAMPLE,
  NOT_REQUIRED,
  PHONE,
  PHONE_INVALID,
  REQUIRED_FIELD,
  SAVE_ADDRESS,
  SEND_INFO_TO_YOUR_PHONE,
  STREET_HOUSE_NUMBER,
  ZIP,
} from 'app/constDictionary'
import { verne } from 'app/julesverne'
import { TableRow } from 'components/table/tableSlice'

interface Props {
  setShow: (show: string) => void
  afterCreateAdresaBackend: (
    uliceCisloPopisneNaNoveAdrese: string,
    oddeleniNaNoveAdrese: string,
    pscNaNoveAdrese?: string,
    employee?: TableRow
  ) => void
}

const EmployeeAddressesInline: React.FunctionComponent<Props> = ({
  setShow,
  afterCreateAdresaBackend,
}) => {
  const dispatch = useAppDispatch()

  const addresses = useAppSelector(selectAddresses)
  const [predvolbaSelectionShown, setPredvolbaSelectionShown] = useState(false)
  const [predvolba, setPredvolba] = useState(420)

  // SECTION FOR ADDING NEW ADDRESS
  const [hasValidatedAddress, setHasValidatedAddress] = React.useState(false)
  const [nazevSpolecnosti, setNazevSpolecnosti] = React.useState('')
  const [kontaktniOsoba, setKontaktniOsoba] = React.useState('')
  const [uliceCisloPopisne, setUliceCisloPopisne] = React.useState('')
  const [mesto, setMesto] = React.useState('')
  const [psc, setPsc] = React.useState('')
  const [telefon, setTelefon] = React.useState('')

  const [oddeleniNaNoveAdrese, setOddeleniNaNoveAdrese] = React.useState('')

  const lang = useAppSelector(selectLanguage) || 'cz'

  function addAddress(): boolean {
    verne('addAddress()', uliceCisloPopisne)
    if (validAddress()) {
      const { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne)
      const formattedUliceCisloPopisne = ulice + ', ' + cisloPopisne
      const newAddress: Address = {
        nazevSpolecnosti: nazevSpolecnosti,
        kontaktniOsoba: kontaktniOsoba,
        uliceCisloPopisne: formattedUliceCisloPopisne,
        mesto: mesto,
        psc: +psc.replace(/\s/g, ''),
        telefon: +telefon.replace(/\s/g, ''),
        oddeleni: [oddeleniNaNoveAdrese || 'Žádné'],
        predvolba: predvolba || 420,
      }

      dispatch(govActions.addAddress(newAddress))
      createAdresaBackend(newAddress).then(() => {
        afterCreateAdresaBackend(
          formattedUliceCisloPopisne,
          oddeleniNaNoveAdrese
        )
      })
      clearAddressForm()
      return true
    }
    return false
  }

  function validAddress() {
    setHasValidatedAddress(true)
    if (
      addresses.filter((a: Address) =>
        // safe check
        equalsBezRozdiluVCisluOrientacnim(
          a.uliceCisloPopisne,
          uliceCisloPopisne
        )
      ).length > 0
    ) {
      alert('Adresa již existuje, ulice a číslo popisné musí být unikátní.')
      return false
    } else if (
      !isValidNazevSpolecnosti(nazevSpolecnosti) ||
      !isValidKontaktniOsoba(kontaktniOsoba) ||
      !isValidUliceCisloPopisne(uliceCisloPopisne) ||
      !isValidMesto(mesto) ||
      !isValidPsc(psc) ||
      !isValidTelefon(telefon)
    ) {
      return false
    } else {
      return true
    }
  }

  function clearAddressForm() {
    setNazevSpolecnosti('')
    setKontaktniOsoba('')
    setUliceCisloPopisne('')
    setMesto('')
    setPsc('')
    setTelefon('')
    // setActiveDepartment(oddeleniNaAdrese) // ORDER SENSITIVE
    setOddeleniNaNoveAdrese('') // ORDER SENSITIVE
    // dispatch(reducerNewBornEmployeeUliceCisloPopisne('')) // DO NOT UNCOMMENT, we are not adding employee yet, just the address... and we want to select it
    // dispatch(reducerNewBornEmployeeDepartment(''))
    setHasValidatedAddress(false)
  }

  return (
    <div>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>{lg(lang, FIRM_NAME)}</label>
        <div className='input__wrapper'>
          <input
            value={nazevSpolecnosti}
            onChange={(e) => setNazevSpolecnosti(e.target.value)}
            placeholder={`${lg(lang, FOR_EXAMPLE)} Sodexo s.r.o`}
            type='text'
          />
          {hasValidatedAddress &&
            (!nazevSpolecnosti || nazevSpolecnosti.length === 0) && (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            )}
        </div>
      </div>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>{lg(lang, STREET_HOUSE_NUMBER)}</label>
        <div className='input__wrapper'>
          <input
            value={uliceCisloPopisne}
            onChange={(e) => setUliceCisloPopisne(e.target.value)}
            placeholder={`${lg(lang, FOR_EXAMPLE)} Moskevská 33`}
            type='text'
          />
          {hasValidatedAddress &&
            (!uliceCisloPopisne || uliceCisloPopisne.length === 0 ? (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            ) : (
              !isValidUliceCisloPopisne(uliceCisloPopisne) && (
                <span className='input__error'>{NEPLATNA_ADRESA}</span>
              )
            ))}
        </div>
      </div>
      <div className='grid-x u-mb-4'>
        <div className='cell small-7 u-pr-small-2'>
          <div className='input input--required'>
            <label className='input__label'>{lg(lang, CITY)}</label>
            <div className='input__wrapper'>
              <input
                value={mesto}
                onChange={(e) => setMesto(e.target.value)}
                placeholder={`${lg(lang, FOR_EXAMPLE)} Praha 10`}
                type='text'
              />
              {hasValidatedAddress && (!mesto || mesto.length === 0) && (
                <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
              )}
            </div>
          </div>
        </div>
        <div className='cell small-5'>
          <div className='input input--required'>
            <label className='input__label'>{lg(lang, ZIP)}</label>
            <div className='input__wrapper'>
              <input
                value={psc}
                onChange={(e) => setPsc(e.target.value)}
                placeholder='101 00'
                type='text'
              />
              {hasValidatedAddress &&
                (psc ? (
                  !pscRegex.test(psc) && (
                    <span className='input__error'>{NEPLATNE_PSC}</span>
                  )
                ) : (
                  <span className='input__error'>
                    {lg(lang, REQUIRED_FIELD)}
                  </span>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='input u-mb-4 input--required'>
        <label className='input__label'>{lg(lang, CONTACT_PERSON)}</label>
        <div className='input__wrapper'>
          <input
            value={kontaktniOsoba}
            onChange={(e) => setKontaktniOsoba(e.target.value)}
            placeholder={`${lg(lang, FOR_EXAMPLE)} Karel Havlíček`}
            type='text'
          />
          {hasValidatedAddress &&
            (!kontaktniOsoba || kontaktniOsoba.length === 0 ? (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            ) : (
              !isValidKontaktniOsoba(kontaktniOsoba) && (
                <span className='input__error'>{NEPLATNA_KONTAKTNI_OSOBA}</span>
              )
            ))}
        </div>
      </div>
      <div className='grid-x'>
        <div className='cell medium-5 u-pr-medium-2'>
          <div
            onClick={() => setPredvolbaSelectionShown(!predvolbaSelectionShown)}
            id='country-code'
            className={
              'cphone-code cphone-code--required' +
              ' ' +
              (predvolbaSelectionShown ? 'cphone-code--active' : '')
            }
          >
            <label htmlFor='country-code' className='country-code__label'>
              {lg(lang, COUNTRY)}
            </label>{' '}
            <div className='cphone-code__option cphone-code__option--selected'>
              <svg className='icon icon--24'>
                <use
                  href={
                    icons +
                    (predvolba === 420 ? '#sprite-flag-cz' : '#sprite-flag-sk')
                  }
                />
              </svg>{' '}
              <span>
                <svg className='icon icon--16 u-mx-1'>
                  <use
                    href={
                      icons +
                      (predvolbaSelectionShown
                        ? '#sprite-arrow-thin-up'
                        : '#sprite-arrow-thin-down')
                    }
                  />
                </svg>
              </span>{' '}
              <span>+{predvolba}</span>
            </div>{' '}
            {predvolbaSelectionShown && (
              <div
                onClick={() => {
                  setPredvolbaSelectionShown(false)
                  setPredvolba(predvolba === 420 ? 421 : 420)
                }}
                className='cphone-code__options'
              >
                <div className='cphone-code__option'>
                  <svg className='icon icon--24 u-mr-1'>
                    <use
                      href={
                        icons +
                        (predvolba === 420
                          ? '#sprite-flag-sk'
                          : '#sprite-flag-cz')
                      }
                    />
                  </svg>{' '}
                  <span>+{predvolba === 420 ? 421 : 420}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='cell medium-7'>
          <div className='input input--required'>
            <label className='input__label'>{lg(lang, PHONE)}</label>{' '}
            <div className='input__wrapper'>
              <input
                placeholder={`${lg(lang, FOR_EXAMPLE)} 723 723 723`}
                type='tel'
                value={formatTelefon(telefon)}
                onKeyPress={validateNumericKeyWithWhites}
                onChange={(e) =>
                  setTelefon(e.target.value.replace(/[^0-9]/g, ''))
                }
              />{' '}
              {hasValidatedAddress &&
                (telefon ? (
                  !telefonniCisloRegex.test(telefon) && (
                    <span className='input__error'>
                      {lg(lang, PHONE_INVALID)}
                    </span>
                  )
                ) : (
                  <span className='input__error'>
                    {lg(lang, REQUIRED_FIELD)}
                  </span>
                ))}
            </div>{' '}
          </div>
        </div>
        <span className='input__help u-mb-4'>
          {lg(lang, SEND_INFO_TO_YOUR_PHONE)}
        </span>
      </div>

      <div className='input u-mb-4'>
        <label className='input__label'>
          {lg(lang, DEPARTMENT_NAME)} ({lg(lang, NOT_REQUIRED)})
        </label>
        <div className='input__wrapper'>
          <input
            value={oddeleniNaNoveAdrese}
            onChange={(e) => setOddeleniNaNoveAdrese(e.target.value)}
            placeholder={`${lg(lang, FOR_EXAMPLE)} HR`}
            type='text'
          />
        </div>
      </div>
      <div>
        <a
          onClick={() => {
            if (addAddress()) {
              setShow('none')
              clearAddressForm()
            }
          }}
          className='btn u-ml-1 u-pl-2 u-size-base14 btn--primary btn--small'
        >
          <svg className='icon icon--24'>
            <GeoPinOutlineIcon width={24} height={24} />
          </svg>
          {lg(lang, SAVE_ADDRESS)}
        </a>
        <a
          onClick={() => {
            setShow('none')
            clearAddressForm()
          }}
          className='btn u-ml-2 u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
        >
          <svg className='icon icon--24'>
            <DeleteIcon width={24} height={24} />
          </svg>
          {lg(lang, CANCEL)}
        </a>
      </div>
    </div>
  )
}

export default EmployeeAddressesInline
