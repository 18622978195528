import React, { useEffect, useState } from 'react'
import DirectionIcon from '@styles/icon/directionIcon'
import LogoOdeslano from '@styles/icon/logoOdeslano.svg'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  reducerUserExists,
  reducerCurrentLocation,
  selectUserExists,
} from './features/stepperSlice'
import { urlOnboardingSuccess, urlSelfcareTable } from 'App'
import {
  selectKontaktniEmail,
  selectPredvolba,
  selectTelefon,
} from './features/objednavatelSlice'
import Color from 'app/colors'
import { createAdresaBackend, createSkupina } from 'app/req'
import { govActions } from 'components/settings/governanceSlice'
import {
  selectNazevSpolecnosti,
  selectUliceCisloPopisne,
  selectMesto,
  selectPsc,
  selectOpravneneOsoby,
} from './features/firemniUdajeSlice'
import {
  reducerCerstveProselOnboardingem,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import { getPrecious } from 'app/back'
import { Address } from 'app/type'
import { lg } from 'app/facade'
import {
  OB_SUCCESS_BUTTON,
  OB_SUCCESS_HEADER,
  OB_SUCCESS_SUBHEADER,
  OB_SUCCESS_TEXT,
} from 'app/constDictionary'

const OnboardingSuccess: React.FC = () => {
  const dispatch = useAppDispatch()

  const nextLocationNumber = 6 // selfcare-table
  // const pracovniEmail = useAppSelector(selectPracovniEmail)
  const kontaktniEmail = useAppSelector(selectKontaktniEmail)

  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti) as string
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne) as string
  const mesto = useAppSelector(selectMesto) as string
  const psc = useAppSelector(selectPsc) as string
  const kontaktniOsoba = useAppSelector(selectOpravneneOsoby)[0] as string
  const predvolba = useAppSelector(selectPredvolba) as number
  const telefon = useAppSelector(selectTelefon) as number

  const userExists = useAppSelector(selectUserExists)
  const lang = useAppSelector(selectLanguage) || 'cz'

  useEffect(() => {
    if (userExists) {
      dispatch(reducerUserExists(false))
      // No, do not succumb to their ideas. User can continue. Why wouldn't he?
      // setTimeout(() => (window.location.href = urlLoginSodexoOneUat), 10000)
    }
  }, [userExists])

  function createNullDeliveryAddress() {
    const firemniUdajeAddress: Address = {
      nazevSpolecnosti: nazevSpolecnosti,
      uliceCisloPopisne: uliceCisloPopisne,
      mesto: mesto,
      psc: +psc.replace(/\s/g, ''),
      kontaktniOsoba: kontaktniOsoba,
      telefon: telefon,
      predvolba: predvolba,
      oddeleni: ['Žádné'],
    }
    dispatch(govActions.addAddress(firemniUdajeAddress))
    createAdresaBackend(firemniUdajeAddress)
  }

  function createNullGroup() {
    const skupina = 'Nezařazení'
    dispatch(govActions.addGroup(skupina))
    createSkupina(skupina)
  }

  return (
    <div className='container u-my-6'>
      <div className='highlight'>
        <div className='highlight__content'>
          <div className='u-text-center'>
            <div className='grid-x u-justify-center'>
              <div className='cell small-10 medium-6'>
                <h2
                  className='heading u-size-l28'
                  style={{ width: '600px', marginLeft: '-100px' }}
                >
                  {lg(lang, OB_SUCCESS_HEADER)}
                </h2>
              </div>
            </div>
            <p
              style={{
                margin: '16px 16px 16px 16px',
                color: Color.VIOLET,
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              {lg(lang, OB_SUCCESS_SUBHEADER)}
            </p>
            <p>
              {lg(lang, OB_SUCCESS_TEXT)}
              <b>{kontaktniEmail}</b>
            </p>
            <img src={LogoOdeslano} alt='' className='u-mt-4' />
          </div>
        </div>
        <div className='u-my-4 u-text-center'>
          <Link
            to={getPrecious() ? urlSelfcareTable : urlOnboardingSuccess}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              if (getPrecious()) {
                // dispatch(resetVisitedLocation()) // this would lead to automatic redirect to offer-lead on entering of our base url into the browser search bar
                dispatch(reducerCurrentLocation(nextLocationNumber)) // prevent the user from getting loaded into /dokonceni-registrace when he types in the URL of the server.
                createNullDeliveryAddress()
                createNullGroup()
                dispatch(reducerCerstveProselOnboardingem(true))
              } else {
                alert(
                  'Počkejte, než server zpracuje Vaše data z předchozího kroku onboarding procesu.'
                )
              }
            }}
          >
            <button className='btn btn--primary'>
              <svg className='icon icon--24'>
                <DirectionIcon />
              </svg>
              {lg(lang, OB_SUCCESS_BUTTON)}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OnboardingSuccess
