import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class DirectionIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'direction-icon',
    width: 24,
    height: 24,
  }

  render() {
    const { className, width, height } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.586 1.332a2 2 0 012.828 0l13.253 13.253a2 2 0 010 2.828L17.414 30.666a2 2 0 01-2.828 0L1.333 17.413a2 2 0 010-2.828L14.586 1.332zm14.667 14.667L16 2.746 2.747 16 16 29.252 29.253 16zM11 17.666a4 4 0 014-4h3.919l-2.293-2.293a1 1 0 011.414-1.414l4.707 4.707-4.707 4.707a1 1 0 01-1.414-1.414l2.293-2.293H15a2 2 0 00-2 2v1.666a1 1 0 11-2 0v-1.666z'
          fill='currentColor'
        ></path>
      </svg>
    )
  }
}

export default DirectionIcon
