import React, { useRef } from 'react'
import icons from '@styles/dist/system/icons.svg'
import { useAppDispatch, useAppSelector, useOnClickOutside } from 'app/hooks'
import { reducerAssignColumn } from './importSettingsSlice'
import { DIC_COLUMNS } from 'app/constants'

interface Props {
  index: number
  popover: number
  setPopover: (index: number) => void
  onSelect: (index: number, column: string) => void
  selectedColumns: Array<{ index: number; column: string }>
}
const ImportHeader: React.FC<Props> = ({
  index,
  popover,
  setPopover,
  onSelect,
  selectedColumns,
}) => {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setPopover(-1))

  const dispatch = useAppDispatch()
  const redux = useAppSelector((state) => state.importSettings)

  function handleColumnSelect(columnNewName: string) {
    onSelect(index, columnNewName)
    dispatch(
      reducerAssignColumn({
        index: index,
        column: columnNewName,
      })
    )
  }

  return (
    <div
      // @ts-ignore
      ref={ref}
      className={
        'modal__trigger table-select' +
        (index === 0 ? ' table-select--first' : '') +
        (popover === index ? ' table-select--active' : '')
      }
      style={{ zIndex: 11 }}
    >
      <div
        onClick={() => setPopover(index === popover ? -1 : index)}
        className='modal__trigger table-select__option table-select__option--selected table__cell table__cell--fixed table__cell--valign u-text-ellipsis'
      >
        {/* Label of the column (always visible). */}
        <span className='modal__trigger'>
          {redux.jmenoColumn === index
            ? DIC_COLUMNS[0]
            : redux.prijmeniColumn === index
            ? DIC_COLUMNS[1]
            : redux.cisloZamestnanceColumn === index
            ? DIC_COLUMNS[2]
            : redux.volnocasovyKreditColumn === index
            ? DIC_COLUMNS[3]
            : redux.stravenkovyKreditColumn === index
            ? DIC_COLUMNS[4]
            : redux.nazevSpolecnostiColumn === index
            ? DIC_COLUMNS[5]
            : redux.uliceCisloPopisneColumn === index
            ? DIC_COLUMNS[6]
            : redux.mestoColumn === index
            ? DIC_COLUMNS[7]
            : redux.pscColumn === index
            ? DIC_COLUMNS[8]
            : redux.kontaktniOsobaColumn === index
            ? DIC_COLUMNS[9]
            : redux.telefonColumn === index
            ? DIC_COLUMNS[10]
            : 'Vyberte hodnotu'}
        </span>
        <svg className='icon icon--24 u-ml-1'>
          <use
            href={
              icons +
              (index === popover
                ? '#sprite-sorting-up'
                : '#sprite-sorting-down')
            }
          ></use>
        </svg>
      </div>
      {/* Dropdown menu - render only if it's popover is active */}
      {popover === index && (
        /* WHETHER TO LIST THE ITEMS OR MAKE THEM DISAPPEAR AFTER THEY HAD BEEN SELECTED. ALLOW RESELECTION IF ALL COLUMNS HAD BEEN CHOSEN. */
        <div className='modal__trigger table-select__options'>
          {DIC_COLUMNS.map(
            (column) =>
              (selectedColumns.filter((item) => item.column === column)
                .length === 0 ||
                selectedColumns.length === DIC_COLUMNS.length) && (
                <div
                  key={column}
                  onClick={() => handleColumnSelect(column)}
                  className='modal__trigger table-select__option'
                >
                  {column}
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}

export default ImportHeader
