import {
  urlOnboarding1,
  urlOnboarding2,
  urlOnboarding3,
  urlOnboarding4,
} from 'App'
import { useAppDispatch } from 'app/hooks'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { unpackEncryptedVarsFromURL } from './MagicLinkTools'
import {
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerPsc,
  reducerUliceCisloPopisne,
  reducerOpravneneOsoby,
  reducerDicFirmy,
  reducerCisloKontraktu,
  reducerCompanyCode,
} from 'components/onboarding/features/firemniUdajeSlice'
import {
  reducerPracovniEmail,
  reducerPredvolba,
  reducerTelefon,
} from 'components/onboarding/features/objednavatelSlice'
import {
  reducerPocetZamestnancu,
  reducerDenniPrispevekNaStravu,
  reducerVolnocasovyKreditNaMesic,
  reducerOdpracovanychDnuVMesici,
  reducerEnabledDenniPrispevekNaStravu,
  reducerEnabledVolnocasovyKreditNaMesic,
} from 'components/onboarding/features/mesicniNakladySlice'
import { reducerCurrentLocation } from 'components/onboarding/features/stepperSlice'

const MagicLink: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const search = location.search
  // remove &utm_medium=email... from search. This is added by Hubspot
  const searchWithoutUtmMedium = search.split('&utm_medium=email')[0]
  // replace %2F with / and %3A with : and %3D with = and %26 with & and %3F with ? and %2C with , and %23 with # and %25 with % and %20 with + (yes, Hubspot misinterprets + as %20)
  const searchWithoutUtmMediumAndEncoded = searchWithoutUtmMedium
    .replace(/%2F/g, '/')
    .replace(/%3A/g, ':')
    .replace(/%3D/g, '=')
    .replace(/%26/g, '&')
    .replace(/%3F/g, '?')
    .replace(/%2C/g, ',')
    .replace(/%23/g, '#')
    .replace(/%25/g, '%')
    .replace(/%20/g, '+')
  const vars = unpackEncryptedVarsFromURL(searchWithoutUtmMediumAndEncoded)
  console.log('unpacked vars', vars)
  const onboardingLocation = vars.location

  unlockLocation(vars.location)
  dispatchValues(vars)

  function unlockLocation(location: string) {
    let locationNumber = 0
    switch (location) {
      case urlOnboarding1:
        locationNumber = 1
        break
      case urlOnboarding2:
        locationNumber = 2
        break
      case urlOnboarding3:
        locationNumber = 3
        break
      case urlOnboarding4:
        locationNumber = 4
        break
      default:
        locationNumber = 0
    }
    dispatch(reducerCurrentLocation(locationNumber))
  }

  function dispatchValues(vars: { [key: string]: string }): void {
    if (vars.pracovniEmail) {
      dispatch(reducerPracovniEmail(vars.pracovniEmail))
    }
    if (vars.telefon) {
      dispatch(reducerTelefon(+vars.telefon))
    }
    if (vars.predvolba) {
      dispatch(reducerPredvolba(+vars.predvolba))
    }
    if (vars.icFirmy) {
      dispatch(reducerIcFirmy(vars.icFirmy))
    }
    if (vars.dicFirmy) {
      dispatch(reducerDicFirmy(vars.dicFirmy))
    }
    if (vars.pocetZamestnancu) {
      dispatch(reducerPocetZamestnancu(+vars.pocetZamestnancu))
    }
    if (vars.denniPrispevekNaStravu) {
      dispatch(reducerDenniPrispevekNaStravu(+vars.denniPrispevekNaStravu))
    }
    if (vars.volnocasovyKreditNaMesic) {
      dispatch(reducerVolnocasovyKreditNaMesic(+vars.volnocasovyKreditNaMesic))
    }
    if (vars.odpracovanychDnuMesicne) {
      dispatch(reducerOdpracovanychDnuVMesici(+vars.odpracovanychDnuMesicne))
    }
    if (vars.enabledDenniPrispevekNaStravu) {
      dispatch(
        reducerEnabledDenniPrispevekNaStravu(
          vars.enabledDenniPrispevekNaStravu === 'true'
        )
      )
    }
    if (vars.enabledVolnocasovyKreditNaMesic) {
      dispatch(
        reducerEnabledVolnocasovyKreditNaMesic(
          vars.enabledVolnocasovyKreditNaMesic === 'true'
        )
      )
    }
    if (vars.nazevSpolecnosti) {
      dispatch(reducerNazevSpolecnosti(vars.nazevSpolecnosti))
    }
    if (vars.uliceCisloPopisne) {
      dispatch(reducerUliceCisloPopisne(vars.uliceCisloPopisne))
    }
    if (vars.psc) {
      dispatch(reducerPsc(vars.psc))
    }
    if (vars.mesto) {
      dispatch(reducerMesto(vars.mesto))
    }
    if (vars.opravneneOsoby) {
      let opravneneOsoby: string[] = []
      // do not use split(',') because it will split on ',' in the middle of a name (when someone has titles)
      vars.opravneneOsoby.split(':').forEach((osoba) => {
        opravneneOsoby.push(osoba)
      })

      dispatch(reducerOpravneneOsoby(opravneneOsoby))
    }
    if (vars.cisloKontraktu) {
      dispatch(reducerCisloKontraktu(vars.cisloKontraktu))
    }
    if (vars.companyCode) {
      dispatch(reducerCompanyCode(vars.companyCode))
    }
  }

  return (
    <div className='magic-link'>
      <Navigate to={onboardingLocation} />
      {/* <h1>Magic Link</h1>
      <p>You will be redirected to your onboarding page in 5 seconds (not).</p>
      <p style={{ whiteSpace: 'pre' }}>
        {`URL params: = ${Object.keys(vars).map(
          (key: string) => `${key} = ${vars[key]}`
        )}
        
        http://localhost:3000/magiclink?location=/multipasscard/kalkulace&pracovniEmail=vasek@sodexo.cz&telefon=725725725&pocetZamestnancu=33&icFirmy=26202891&predvolba=+421
        http://localhost:3000/magiclink?location=/multipasscard/firemni-udaje&pracovniEmail=petra@sodexo.cz&telefon=725725711&predvolba=421&pocetZamestnancu=25&icFirmy=26202891&odpracovanychDnuMesicne=20&denniPrispevekNaStravu=115&volnocasovyKreditNaMesic=1430&enabledDenniPrispevekNaStravu=false&enabledVolnocasovyKreditNaMesic=true
        http://localhost:3000/magiclink?location=/multipasscard/smlouva&pracovniEmail=karel@sodexo.cz&telefon=725725725&predvolba=420&pocetZamestnancu=33&icFirmy=26202891&odpracovanychDnuMesicne=20&denniPrispevekNaStravu=131&volnocasovyKreditNaMesic=2950&enabledDenniPrispevekNaStravu=true&enabledVolnocasovyKreditNaMesic=true&nazevSpolecnosti=TVI-BIZ%20s.r.o.&uliceCisloPopisne=N%C3%A1zovsk%C3%A1%203254&mesto=Praha&psc=10000&opravneneOsoby=Andrej%20Babi%C5%A1:Tom%C3%A1%C5%A1%20Vav%C5%99%C3%AD%C4%8Dek
        http://localhost:3000/magiclink?location=/multipasscard/smlouva&pracovniEmail=karel@sodexo.cz&telefon=725725725&predvolba=420&pocetZamestnancu=33&icFirmy=26202891&odpracovanychDnuMesicne=20&denniPrispevekNaStravu=131&volnocasovyKreditNaMesic=2950&enabledDenniPrispevekNaStravu=true&enabledVolnocasovyKreditNaMesic=true&nazevSpolecnosti=TVI-BBIZ%20s.r.o.&uliceCisloPopisne=N%C3%A1zovsk%C3%A1%206&mesto=Praha&psc=10000&opravneneOsoby=Andrej:Tom%C3%A1%C5%A1%20Vav%C5%99%C3%AD%C4%8Dek
        http://localhost:3000/magiclink?1744fee75477a89c7c195e7a88f3c61cc08d18ac861b403abd8bac078f7943ee4ae77f999e5d96f6a4e237aaa1e6c3b43e8e4e2c3091d1bbd88b3bfc9b31b3fb19402c3d251a1578d6a51af1a0a44106aab8b60a3de93270db7af488eef3824ba1099ebea0fb8b6ceebcc4078395ff08b374c1e1ffdc614ecd199009b9afa3391f067355b0721645c3079768d8719ccb15e50bb4bb593ad9d24ea2e991d93ef35276759ed6b2a2ab97e7aedc0881d4ea39b2a107db14a141e35e5d8748fb4389ce8e06ae4e971e4eb47c60cfdc8b47cff2f1a80e0246f2fea56d71066797f89355fcd6f1691dcec6131a158307bcc8d96135e47025ae02e2c0664737069c98a5b3a753088dec017ef8deff93f0f00dceb4e91462f866a5be14182d45f92b232debbb9e9c4776b487f80fe4447b2b30a80e4f3e07a6199b99edf3b466b9f59a9927b34ec6092861fa1d33d71f3782a674cd43e13dc47949a8c4e6b83855505d92521f6884f62d357e12e4558a2a8fea7515728bbddfd3baac441ce4689d0eac282954b46e30a92f6f61b002b2b314abde40137ef60c05264b5f8d2ad1fde45c1ac975aaea10f2aa01749b7cdcadaf380e7215f2a0cbaa668e3564b593acc3d05f3afc8ef3df16131323608ccf1d30b4a49e2aff52a0ac6a0ae83299c462792938185cdb52b6dcb129f19734b10fd2b694
        http://localhost:3000/magiclink?1744fee75477a89c7c195e7a88f3c61c8785220951381477d819beb9b39c3d357b63d3b7827ebb0616a28dc40e76a34086d295323bcac5f03279f6c578f10ab4e45e1bded0a9df6ca11e643e2244d426678669addf4e22207061f37d2f2117dc21db2eed6cc8598d8d057b3a083dee32c01d8f3cc87f7d5aa2012a2f26b554c73e65094118ab5cd3692d11e38b282f58f299f311f15732cf72d10fcd4209791e84d67ac77949b4e81970410c2f01471f79c4d8098bc1280524e382f7cc2054e3dc6c8fe732a68e26a2fcb5bda51439c426ab2b958f19ee9cc6be4e075727dda346c9a4271b931fda6e46b34db7fcb1dd0da263dcfaa5163a64be27b8af1ead5718e9dc270479b5698244b99285d93da4d89d5866a35633fd0b813bf872263e77892605559c6c8ba8c27753e7a8a5121ce72a46a8c8ba43b4cfd44e9222a083f2dd46c309dc2b9b79468025b0361826df19bb4320b0626a7a8a38973ee79eccbdc89427ceacaffb05e38f314341ad181b7bd233432654d33b25b3ac92901cebe4a56fd5fb7a3f7bfc7e3b01c7b265c1afc5932e532f478817fedc1e4aa6637c4717fb8285046797cb7723950c578003ec43ac9b5eacd9c1af0ef50d8f22751c047a8a1f019284472ef50ce1f03509718061c7af2aa3f4ca5a95fe107fd70c361ab0bd6902e772daf1827263d09815b09faaabbe4d44f37abd459b35b08ae422ea
        `}
      </p>
      {/* ${createMagicLink(vars)} ${createMagicLink(unpackVarsFromURL(location.search).email)} */}
      {/* <button onClick={onClick}>Go to Your onboarding page</button> */}
    </div>
  )
}

export default MagicLink
