import React, { useState, useEffect } from 'react'
import {
  NEPLATNA_ULICE_CISLO_POPISNE,
  NEPLATNE_PSC,
  RECON_EMAIL_COM,
  UDAJ_JE_POVINNY,
} from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { pscRegex } from 'app/util'
import {
  selectPracovniEmail,
  selectPredvolba,
  selectTelefon,
} from 'components/onboarding/features/objednavatelSlice'
import ConfirmSection from 'components/onboarding/ConfirmSection'
import {
  selectIcoFirmy,
  selectNazevSpolecnosti,
  selectUliceCisloPopisne,
  selectMesto,
  selectOpravneneOsoby,
  selectPsc,
  reducerIcFirmy,
  reducerNazevSpolecnosti,
  reducerUliceCisloPopisne,
  reducerMesto,
  reducerPsc,
  reducerRemoveEmptyOpravneneOsoby,
  selectDicFirmy,
  selectCisloKontraktu,
  selectCompanyCode,
} from './features/firemniUdajeSlice'
import { urlBackend, urlOnboarding3 } from 'App'
import { createMagicLink } from 'components/magiclink/MagicLinkTools'
import {
  selectEnabledDenniPrispevekNaStravu,
  selectEnabledVolnocasovyKreditNaMesic,
  selectPocetZamestnancu,
  selectOdpracovanychDnuVMesici,
  selectDenniPrispevekNaStravuOnboarding,
  selectVolnocasovyKreditNaMesic,
} from './features/mesicniNakladySlice'
import {
  isValidKontaktniOsoba,
  isValidMesto,
  isValidNazevSpolecnosti,
  isValidPsc,
  isValidUliceCisloPopisne,
  splitUliceCisloPopisne,
} from 'util/ValidFormatOps'
import OpravneneOsoby from 'components/modules/OpravneneOsoby'
import { lg } from 'app/facade'
import {
  CITY,
  FIRM_ID,
  FIRM_NAME,
  FOR_EXAMPLE,
  INVALID_STREET_HOUSE_NUMBER,
  INVALID_ZIP,
  REQUIRED_FIELD,
  STREET_HOUSE_NUMBER,
  ZIP,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'

interface FiremniUdajeProps {
  submitIcon: JSX.Element
  submitText: string
  nextRoute: string
  onSubmit: () => void
}

const FiremniUdaje: React.FC<FiremniUdajeProps> = ({
  submitIcon,
  submitText,
  nextRoute,
  onSubmit,
}) => {
  const dispatch = useAppDispatch()

  const lang = useAppSelector(selectLanguage) || 'cz'
  const [hasValidated, setHasValidated] = useState(false)
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const icFirmy = useAppSelector(selectIcoFirmy)
  const dicFirmy = useAppSelector(selectDicFirmy)
  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne)
  const mesto = useAppSelector(selectMesto)
  const psc = useAppSelector(selectPsc)
  const opravneneOsoby = useAppSelector(selectOpravneneOsoby)

  // for MagicLink only
  const telefon = useAppSelector(selectTelefon)
  const predvolba = useAppSelector(selectPredvolba)
  // const icFirmy = useAppSelector(selectIcoFirmy)
  const enabledDenniPrispevekNaStravu = useAppSelector(
    selectEnabledDenniPrispevekNaStravu
  )
  const enabledVolnocasovyKreditNaMesic = useAppSelector(
    selectEnabledVolnocasovyKreditNaMesic
  )
  // const pracovniEmail = useAppSelector(selectPracovniEmail)
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const odpracovanychDnuMesicne = useAppSelector(selectOdpracovanychDnuVMesici)
  const denniPrispevekNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )

  // for Magic Link only 2/2 - already defined above

  // for Magic Link only 3/3 - two additional fields that come from SPO as part of Recontracting process
  const cisloKontraktu = useAppSelector(selectCisloKontraktu)
  const companyCode = useAppSelector(selectCompanyCode)

  function formatovaneOpravneneOsobyProMagicLink(opravneneOsoby: string[]) {
    return opravneneOsoby.join(':')
  }

  async function callBackend() {
    console.log('submit - firemni udaje - callbackend company')

    const magicLink = createMagicLink({
      location: urlOnboarding3,
      vars: {
        pracovniEmail: pracovniEmail,
        telefon: telefon,
        predvolba: predvolba,
        pocetZamestnancu: pocetZamestnancu,
        icFirmy: icFirmy,
        dicFirmy: dicFirmy,
        odpracovanychDnuMesicne: odpracovanychDnuMesicne,
        denniPrispevekNaStravu: denniPrispevekNaStravu,
        volnocasovyKreditNaMesic: volnocasovyKreditNaMesic,
        enabledDenniPrispevekNaStravu: enabledDenniPrispevekNaStravu,
        enabledVolnocasovyKreditNaMesic: enabledVolnocasovyKreditNaMesic,
        nazevSpolecnosti: nazevSpolecnosti,
        uliceCisloPopisne: uliceCisloPopisne,
        mesto: mesto,
        psc: psc,
        opravneneOsoby: formatovaneOpravneneOsobyProMagicLink(opravneneOsoby),
        cisloKontraktu: cisloKontraktu,
        companyCode: companyCode,
      },
    })
    console.log({ magicLink })

    const { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne!)

    dispatch(reducerRemoveEmptyOpravneneOsoby())
    const requestBody = JSON.stringify({
      email: pracovniEmail || RECON_EMAIL_COM,
      company_name: nazevSpolecnosti,
      ico: icFirmy,
      street_address: ulice,
      street_number: cisloPopisne,
      address_locality: mesto,
      address_postal_code: psc.replace(/\s/g, ''), // remove whitespace for backend ORDTEX-193
      persons: opravneneOsoby,
      magic_link: magicLink,
      contract_number: cisloKontraktu,
      company_code: companyCode,
    })

    console.log('Request Body, ', requestBody)

    await fetch(urlBackend + '/onboarding/company', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        return data
      })
      .catch((error) => console.log(error))
  }

  const handleInput = (e: any) => {
    let value = e.target.value
    let id = e.target.id
    value = value === undefined ? (typeof value === 'number' ? 0 : '') : value
    switch (id) {
      case 'icFirmy':
        dispatch(reducerIcFirmy(value))
        break
      case 'nazevSpolecnosti':
        dispatch(reducerNazevSpolecnosti(value))
        break
      case 'uliceCisloPopisne':
        dispatch(reducerUliceCisloPopisne(value))
        break
      case 'mesto':
        dispatch(reducerMesto(value))
        break
      case 'psc':
        dispatch(reducerPsc(value))
        break
      default:
        console.log(
          'WARNING: handling input from a field with unrecognized id',
          id,
          value
        )
    }
  }

  function isValid() {
    return (
      isValidNazevSpolecnosti(nazevSpolecnosti) &&
      isValidUliceCisloPopisne(uliceCisloPopisne) &&
      isValidMesto(mesto) &&
      isValidPsc(psc) &&
      opravneneOsoby[0] &&
      isValidKontaktniOsoba(opravneneOsoby[0])
    )
  }

  const wereChanged = [false, false, false, false, false]

  return (
    <div>
      <div className='highlight u-mb-4 u-py-3 highlight--narrow'>
        <div className='highlight__content'>
          <div className='input u-mb-4'>
            <label>{lg(lang, FIRM_ID)}</label>
            <input
              id='icFirmy'
              type='text'
              placeholder='Např.: 1231231'
              value={icFirmy}
              onChange={handleInput}
              readOnly
              style={{
                backgroundColor: 'rgba(0,0,0,0.03)',
                opacity: 0.8,
              }}
            />
          </div>
          <div className='input u-mb-4 input--required'>
            <label>{lg(lang, FIRM_NAME)}</label>
            <input
              id='nazevSpolecnosti'
              type='text'
              placeholder={`${lg(lang, FOR_EXAMPLE)} Pluxee s.r.o`}
              value={nazevSpolecnosti}
              onChange={handleInput}
            />
            {hasValidated && !nazevSpolecnosti && (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            )}
          </div>
          <div className='input u-mb-4 input--required'>
            <label>{lg(lang, STREET_HOUSE_NUMBER)}</label>
            <input
              id='uliceCisloPopisne'
              type='text'
              placeholder='Např.: Moskevská 33'
              value={uliceCisloPopisne}
              onChange={handleInput}
            />
            {hasValidated && !uliceCisloPopisne && (
              <span className='input__error'>{lg(lang, REQUIRED_FIELD)}</span>
            )}
            {hasValidated &&
              uliceCisloPopisne &&
              !isValidUliceCisloPopisne(uliceCisloPopisne) && (
                <span className='input__error'>
                  {lg(lang, INVALID_STREET_HOUSE_NUMBER)}
                </span>
              )}
          </div>
          <div className='grid-x'>
            <div className='cell small-7 u-pr-small-2'>
              <div className='input input--required'>
                <label>{lg(lang, CITY)}</label>
                <input
                  id='mesto'
                  type='text'
                  placeholder='Např.: Praha 10'
                  value={mesto}
                  onChange={handleInput}
                />
                {hasValidated && !mesto && (
                  <span className='input__error'>
                    {lg(lang, REQUIRED_FIELD)}
                  </span>
                )}
              </div>
            </div>
            <div className='cell small-5'>
              <div className='input input--required'>
                <label>{lg(lang, ZIP)}</label>
                <input
                  id='psc'
                  type='text'
                  placeholder='Např.: 101 00'
                  value={psc}
                  onChange={handleInput}
                />
                {hasValidated &&
                  (psc ? (
                    !pscRegex.test(psc) && (
                      <span className='input__error'>
                        {lg(lang, INVALID_ZIP)}
                      </span>
                    )
                  ) : (
                    <span className='input__error'>
                      {lg(lang, REQUIRED_FIELD)}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='highlight u-mb-4 u-py-3 highlight--narrow'>
        <div className='highlight__content'>
          <OpravneneOsoby
            opravneneOsoby={opravneneOsoby}
            hasValidated={hasValidated}
            wereChanged={wereChanged}
          />
        </div>
      </div>
      <div className='u-my-6 u-text-center u-mb-4'>
        {
          <ConfirmSection
            submitIcon={submitIcon}
            submitText={submitText}
            nextRoute={isValid() ? nextRoute : ''}
            onSubmit={
              isValid()
                ? () => {
                    // doesn't get called when going from step 2 to step 3 - i dont know why,
                    // but it works when submitting on step 3 during Edit
                    console.log('submit - firemni udaje')
                    dispatch(reducerRemoveEmptyOpravneneOsoby())
                    callBackend() // keep it here, this one gets called when editing on step 3
                    onSubmit()
                  }
                : () => {
                    callBackend()
                    setHasValidated(true)
                  }
            }
            nextLocationNumber={isValid() ? 3 : undefined}
          />
        }
      </div>
    </div>
  )
}

export default FiremniUdaje
