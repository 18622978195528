import React, { useEffect, useState } from 'react'
import FileIcon from '@styles/icon/fileIcon'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { tableActions } from 'components/table/tableSlice'
import { Address } from 'app/type'
import { lg } from 'app/facade'
import { ASSIGN_DEPARTMENT, DEPARTMENT } from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { nanoid } from '@reduxjs/toolkit'

interface Props {
  employeeId: string
  selectedAddress: Address
  selectedDepartment: string
  onPriraditOddeleni: () => void
  onChange: (
    oddeleni: string,
    prestehovanaUliceCisloPopisne: string | undefined
  ) => void
}

const OddeleniRadioSelector: React.FC<Props> = ({
  employeeId,
  selectedAddress,
  selectedDepartment,
  onPriraditOddeleni,
  onChange,
}) => {
  const dispatch = useAppDispatch()
  const lang = useAppSelector(selectLanguage) || 'cz'

  return (
    <div>
      <h4 className='u-mb-2'>{lg(lang, DEPARTMENT)}</h4>
      <div className='grid-x u-mb-2'>
        {selectedAddress.oddeleni.map((iteratedDepartment) => (
          <div className='cell u-mr-1 shrink' key={nanoid(5)}>
            <label className='radio'>
              <input
                type='radio'
                id={iteratedDepartment}
                value={iteratedDepartment}
                // name='department-group' // THIS WAS THE CAUSE
                checked={Boolean(selectedDepartment === iteratedDepartment)}
                onChange={(e) => {
                  // https://stackoverflow.com/questions/49092857/react-16-radio-button-onchange-not-working
                  if (e.currentTarget.checked) {
                    // console.log(
                    //   'OddeleniRadioSelector: checked department (before) - address.uliceCisloPopisne, iteratedDepartment, selectedDepartment, checkedUseState, currentTargetValue',
                    //   selectedAddress.uliceCisloPopisne,
                    //   iteratedDepartment,
                    //   selectedDepartment,
                    //   checkedUseState,
                    //   e.currentTarget.value
                    // )
                    // setCheckedUseState(e.currentTarget.value)
                    dispatch(
                      tableActions.setOddeleni({
                        id: employeeId,
                        oddeleni: e.currentTarget.value,
                      })
                    )
                    onChange(
                      e.currentTarget.value,
                      selectedAddress.uliceCisloPopisne
                    )
                    // console.log(
                    //   'OddeleniRadioSelector: checked department (after) - address.uliceCisloPopisne, iteratedDepartment, selectedDepartment, checkedUseState, currentTargetValue',
                    //   selectedAddress.uliceCisloPopisne,
                    //   iteratedDepartment,
                    //   selectedDepartment,
                    //   checkedUseState,
                    //   e.currentTarget.value
                    // )
                    // forceUpdate() // no need
                  } else {
                    // it actually seems this never gets called.
                    console.log(
                      'OddeleniRadioSelector: unchecked department - address.uliceCisloPopisne, address.oddeleni',
                      selectedAddress.uliceCisloPopisne,
                      iteratedDepartment
                    )
                  }
                }}
              />

              <span className='radio__control'></span>
              <span className='radio__label'>{iteratedDepartment}</span>
            </label>
          </div>
        ))}
      </div>
      {!selectedAddress.oddeleni.includes('') && (
        <button
          onClick={() => onPriraditOddeleni()}
          className='button-as-anchor u-size-s u-mb-3'
        >
          <svg className='icon icon--16'>
            <FileIcon width={16} height={16} />
          </svg>
          <strong className='u-ml-1'>{lg(lang, ASSIGN_DEPARTMENT)}</strong>
        </button>
      )}
    </div>
  )
}

export default OddeleniRadioSelector
