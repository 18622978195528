import { RootState } from 'app/store'
import { createSlice, current } from '@reduxjs/toolkit'
import { ConsignmentState, Order } from 'app/type'

const initialState: Order[] = []

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    removeAllDuplicateOrders: (state) => {
      // remove duplicates
      const newState = state.filter(
        (order, index, self) =>
          index === self.findIndex((t) => t.orderId === order.orderId)
      )
      return newState
    },
    reducerReplaceCisloObjednavky: (state, action) => {
      const { callerId, newId } = action.payload
      console.log('reducerReplaceCisloObjednavky called', callerId, newId)
      const index = state.findIndex((row) => row.orderId === callerId)
      const order = state[index]
      // console.log(
      //   'order found: reducerReplaceCisloObjednavky called',
      //   callerId,
      //   newId
      // )
      // console.log('after', current(state)[index])

      const newOrder: Order = {
        orderId: newId,
        owner: order.owner,
        date: order.date,
        price: order.price,
        note: order.note,
        contactEmail: order.contactEmail,
        orderState: order.orderState,
        deliveryState: order.deliveryState,
        zasilky: order.zasilky,
        vydaniPrvniKartyPrice: order.vydaniPrvniKartyPrice,
        vydaniNahradniKartyPrice: order.vydaniNahradniKartyPrice,
        leisureCredits: order.leisureCredits,
        gastroCredits: order.gastroCredits,
        spravaUctu: order.spravaUctu,
        feeLeisure: order.feeLeisure,
        feeGastro: order.feeGastro,
        uskutecnenyPoplatekDoruceni: order.uskutecnenyPoplatekDoruceni,
        uskutecnenyPoplatekSpravaUctu: order.uskutecnenyPoplatekSpravaUctu,
        uskutecnenyPoplatekLeisure: order.uskutecnenyPoplatekLeisure,
        uskutecnenyPoplatekGastro: order.uskutecnenyPoplatekGastro,
      }

      state.splice(index, 1)
      state.push(newOrder)

      // this code beneath actually causes some trouble with deleting the actually added order row
      // maybe multiple orders with the same temporary id are present in the table (stuck state)
      // const indexToBeSure = state.findIndex((row) => row.orderId === callerId)
      // state.splice(indexToBeSure, 1)
    },
    addOrders(state, action) {
      const { orders } = action.payload
      console.log(`addOrdersToTheOverview: ${orders.length} orders, %O`, orders)
      state.push(...orders)
    },
    addOrder(state, action) {
      const newRow = action.payload
      // THE BELOW CODE IS COMMENTED AND REPLACED BY A ONE-LINER, BECAUSE:
      // 1. New version of javascript evaluates data type 'undefined' equality differently (two undefineds are equal, whereas before they were not)
      // 2. The code was not proper for production, because we were using default values as a fallback.
      // THE ONE-LINER EXPECTS THAT GARBAGE COLLECTION IS GUARANTEED OUTSIDE THIS FUNCTION.
      state.push(newRow)
      console.log('addOrder called', newRow.orderId)
      // const existingRow = state.find((row) => row.orderId === newRow.id)
      // if (!existingRow) {
      //   state.push({
      //     orderId: newRow.id,
      //     owner: newRow.owner || '',
      //     date: newRow.date || new Date(),
      //     price: newRow.price || 0,
      //     note: newRow.note || '',
      //     contactEmail: newRow.contactEmail || '',
      //     orderState: newRow.orderState || '',
      //     deliveryState: newRow.deliveryState || '',
      //     zasilky: newRow.zasilky || [],
      //     vydaniPrvniKartyPrice: newRow.vydaniPrvniKartyPrice || 80,
      //     vydaniNahradniKartyPrice: newRow.vydaniNahradniKartyPrice || 20,
      //     doruceniKaretPrice: newRow.doruceniKaretPrice || 100,
      //     leisureCredits: newRow.leisureCredits || 0,
      //     gastroCredits: newRow.gastroCredits || 0,
      //     spravaUctu: newRow.spravaUctu || 0,
      //     feeLeisure: newRow.feeLeisure || 2,
      //     feeGastro: newRow.feeGastro || 3,
      //   })
      // } else {
      //   console.log('Tento záznam již existuje', newRow.id, newRow, existingRow)
      //   state.push(newRow)
      // }
    },
    removeRowFromTheTable(state, action) {
      const { id } = action.payload
      const index = state.findIndex((row) => row.orderId === id)
      if (index > -1) {
        state.splice(index, 1)
      }
    },
    removeAllRowsFromTheTable(state) {
      state.splice(0, state.length)
    },
    updateRowInTheTable(state, action) {
      const { id, row } = action.payload
      const existingRow = state.find((row) => row.orderId === id)
      if (existingRow) {
        Object.assign(existingRow, row)
      }
    },
    activateZasilka(state, action) {
      const { orderId, zasilkaNumber } = action.payload
      const order = state.find((order) => order.orderId === orderId)
      if (order) {
        const zasilka = order.zasilky.find(
          (zasilka) => zasilka.number === zasilkaNumber
        )
        if (zasilka) {
          zasilka.stavZasilky = ConsignmentState.Převzatá
          order.deliveryState = ConsignmentState.Převzatá
        }
      }
    },
  },
})

export const overviewActions = overviewSlice.actions

export const selectOverview = (state: RootState) => state.overview
export const selectIsOverviewEmpty = (state: RootState) =>
  state.overview.length === 0
export const selectOrderById = (orderId: number) => (state: RootState) =>
  state.overview.find((row) => row.orderId === orderId)
export const selectLastOrderId = (state: RootState) =>
  state.overview.length > 0
    ? state.overview[state.overview.length - 1].orderId
    : ''

export const selectLastOrderPrice = (state: RootState) =>
  state.overview.length > 0
    ? state.overview[state.overview.length - 1].price
    : 0

export default overviewSlice.reducer
