import { Address, OrderState } from 'app/type'
import { formatPsc } from 'util/ValidFormatOps'
import { DOCUMENT_IN_PREPARATION } from './constants'

export const timeout = (ms: number): Promise<never> => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error(`Request timed out after ${ms} ms`))
    }, ms)
  })
}

export function minimumXIfNotZero(
  x: number,
  cenikFee: number,
  amount: number
): number {
  if (cenikFee === 0) {
    console.log(
      'minimumXIfNotZero, detected cenikFee = 0, a special case where minimum poplatek is 0',
      cenikFee,
      amount
    )
    return 0
  }
  if (x === 0) {
    console.log(
      'minimumXIfNotZero, detected a special case where minimum poplatek is 0 for the customer'
    )
  }
  if (x === undefined) {
    // console.log(
    //   'minimumXIfNotZero, detected a customer using old model of Cenik with no minimum of poplatek defined, using CENIK_CZE_DEFAULT (100) as minimum'
    // )
    x = 100
  }
  const value = cenikFee * amount
  const result = amount > 0 ? Math.max(value, x) : 0
  return result
}

// #############################################################################
// ########################## REGEX FUNCTIONS ##################################
// #############################################################################
export const emailRegex = RegExp(
  // Internet top level domain .travelersinsurance = 18 chars - https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains
  // /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@#$~^&*{}%§"]+\.)+[^<>()[\].,;:$\s@#$~^&*{}%§"]{2,18})$/i
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9\-_.]+\.)+[^<>()[\].,;:$\s@#$~^&*{}%§"]{2,18})$/i
  // domain name regex from https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
  // ^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$
)
export const telefonniCisloRegex = RegExp(
  /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$/
)
export const icoRegex = RegExp(/^[0-9]{8}$/)
export const pscRegex = RegExp(/^[0-9]{3}[\s.]?[0-9]{2}$/)

// #############################################################################
// ###################### FORMATTING FUNCTIONS #################################
// #############################################################################
export function lastLetterToNumber(string: string): number {
  return string.charCodeAt(string.length - 1) - '0'.charCodeAt(0)
}
// used for formatting telefon as well as prices
export function numberWithSpaces(x: string): string {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
  // return x.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;').replace('.', ',')
}
export function numberWithoutSpacesX(x: number): string {
  return x.toFixed(0).replace('.', ',')
}
export function numberWithSpacesX(x: number): string {
  return numberWithSpaces(x.toString())
}
export function numberWithSpacesMax2(x: number): string {
  // return a number with max two decimal places
  if (x.toString().includes('.')) {
    return numberWithSpaces(x.toFixed(2))
  } else {
    return numberWithSpaces(x.toFixed(0))
  }
}
export function numbersWithSpacesX(x: number[]): string {
  return (
    '#' +
    x.map(String).reduce((acc, curr) => {
      return acc + ', #' + curr
    })
  )
}
export function addressWithPhone(address: Address): string {
  return `${address.nazevSpolecnosti}, ${address.uliceCisloPopisne}, 
  ${address.mesto}, ${formatPsc(address.psc)}, ${address.kontaktniOsoba}, 
  tel.: ${address.predvolba} ${numberWithSpacesX(address.telefon)}`
}
export function validateNumericKeyWithWhites(event: any) {
  var theEvent = event || window.event

  let key
  // Handle paste
  if (theEvent.type === 'paste') {
    key = event.clipboardData.getData('text/plain')
    // key = event.clipboardData.getData('text')
  } else {
    // Handle key press
    var keyCode = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(keyCode)
  }
  // let's prevent negative values (27.4.2022)
  // var regex = /[0-9]|-|\s/
  var regex = /[0-9]|\s/
  if (!regex.test(key)) {
    theEvent.returnValue = false
    if (theEvent.preventDefault) theEvent.preventDefault()
  }
}

export function validateNumericKeyStrict(event: any) {
  var theEvent = event || window.event

  let key
  // Handle paste
  if (theEvent.type === 'paste') {
    key = event.clipboardData.getData('text/plain')
    // key = event.clipboardData.getData('text')
  } else {
    // Handle key press
    var keyCode = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(keyCode)
  }
  var regex = /[0-9]/
  if (!regex.test(key)) {
    theEvent.returnValue = false
    if (theEvent.preventDefault) theEvent.preventDefault()
  }
}

export function generateInfoMessage(
  document: string,
  documentStatus?: string | undefined
) {
  if (document === 'faktura' && documentStatus === OrderState.ČekáNaZaplacení) {
    return 'Faktura bude dostupná až po zaplacení.'
  } else {
    return `${document} ${DOCUMENT_IN_PREPARATION}`
  }
}

export function isEmpty(string: string): boolean {
  return string === '' || string === undefined || string === null
}
