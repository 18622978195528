import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class WheelIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'wheel-icon',
    width: 24,
    height: 24,
    fill: 'currentColor',
  }

  render() {
    const { className, width, height, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.182 2a1 1 0 00-1 1v1.46c-1.85.6-3.508 1.62-4.858 2.954l-1.328-.67a1 1 0 00-1.34.438L2.11 12.163a1 1 0 00.443 1.35l1.317.657a12.04 12.04 0 00.458 5.601l-1.193.866a1 1 0 00-.215 1.405l3.334 4.498a1 1 0 001.39.214l1.194-.866a12.212 12.212 0 003.344 1.673v1.459a1 1 0 001 1h5.636a1 1 0 001-1v-1.46c1.85-.6 3.508-1.62 4.858-2.954l1.328.67a1 1 0 001.34-.438l2.546-4.981a1 1 0 00-.443-1.35l-1.317-.657a12.04 12.04 0 00-.458-5.6l1.192-.867a1 1 0 00.216-1.405L25.746 5.48a1 1 0 00-1.39-.214l-1.195.866a12.21 12.21 0 00-3.343-1.673V3a1 1 0 00-1-1h-5.636zm1 3.212V4h3.636v1.212a1 1 0 00.755.97 10.228 10.228 0 013.945 1.972 1 1 0 001.217.032l.993-.72 2.144 2.892-.986.717a1 1 0 00-.34 1.185 9.951 9.951 0 01.726 3.75c0 .75-.085 1.478-.24 2.183a1 1 0 00.53 1.11l1.088.543-1.633 3.196-1.104-.556a1 1 0 00-1.196.228 10.309 10.309 0 01-5.147 3.126 1 1 0 00-.752.968v1.212h-3.636v-1.212a1 1 0 00-.755-.97 10.227 10.227 0 01-3.945-1.972 1 1 0 00-1.217-.032l-.993.72-2.144-2.892.987-.717a1 1 0 00.338-1.185 9.95 9.95 0 01-.725-3.75c0-.75.086-1.478.24-2.183a1 1 0 00-.53-1.11l-1.088-.543 1.633-3.196 1.104.556a1 1 0 001.196-.228 10.309 10.309 0 015.147-3.125 1 1 0 00.752-.97zm.899 7.958c1.66-.556 3.427.297 3.976 1.849a1 1 0 001.886-.668c-.933-2.633-3.857-3.962-6.498-3.077-2.664.893-4.1 3.744-3.154 6.381.711 1.988 2.562 3.233 4.548 3.344a1 1 0 10.111-1.997c-1.244-.07-2.356-.846-2.776-2.021v-.001c-.556-1.548.27-3.261 1.907-3.81z'
          fill={fill}
        ></path>
      </svg>
    )
  }
}

export default WheelIcon
