import { useAppDispatch } from 'app/hooks'
import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { unpackEncryptedVarsFromURL } from './MagicLinkTools'
import {
  reducerIcFirmy,
  reducerMesto,
  reducerNazevSpolecnosti,
  reducerPsc,
  reducerUliceCisloPopisne,
  reducerOpravneneOsoby,
  reducerDicFirmy,
  reducerCenik,
  reducerCisloKontraktu,
  reducerCompanyCode,
  CENIK_CZK_DEFAULT,
} from 'components/onboarding/features/firemniUdajeSlice'
import {
  reducerKontaktniEmail,
  reducerPracovniEmail,
  reducerPredvolba,
  reducerTelefon,
} from 'components/onboarding/features/objednavatelSlice'
import { reducerCurrentLocation } from 'components/onboarding/features/stepperSlice'
import { validateEmail } from 'util/ValidFormatOps'
import { urlBackend, urlRecon1, urlRecontracting } from 'App'
import { getAndLog } from 'app/req'
import { reducerPocetZamestnancu } from 'components/onboarding/features/mesicniNakladySlice'

export function isRecon(): boolean {
  return window.location.href.includes(urlRecontracting)
}

const ReconLink: React.FC = () => {
  const secret = 'jXn2r5u7x!A%D*G-KaPdSgVkYp3s6v9y'
  const vector = 'jXn2r5u7x!A%D*G-'

  const dispatch = useAppDispatch()
  const location = useLocation()
  const search = location.search

  const vars = unpackEncryptedVarsFromURL(search, secret, vector)
  console.log('unpacked vars', vars)
  // const onboardingLocation = vars.location
  const onboardingLocation = urlRecon1

  // const [varsDisplay, setVarsDisplay] = React.useState('')
  // setVarsDisplay()

  // unlockLocation(vars.location)

  async function loadValuesFromBackendThenDispatch() {
    console.log('loadValuesFromBackendThenDispatch')

    const response = getAndLog(urlBackend + `/recon/pricelist`, [
      ['ico', vars.icFirmy],
      ['RECON_KEY', '27610e0f-6652-4d91-8f93-13cdcbb75ea1'],
    ])
    response
      .then((data) => {
        dispatch(
          reducerCenik({
            volnocas: data.poplatek_z_volnocasoveho_kreditu,
            stravenky: data.poplatek_ze_stravovaciho_kreditu,
            spravaJednohoUctu: data.mesicni_sprava_uzivatelskeho_uctu,
            vydaniPrvniKarty: data.cena_vydani_karty,
            vydaniNahradniKarty: data.poplatek_za_objednani_nahradni_karty,
            doruceni: data.cena_doruceni,
            minStravenkyFee:
              data.min_gastro_fee || CENIK_CZK_DEFAULT.minStravenkyFee,
            minVolnocasFee:
              data.min_leisure_fee || CENIK_CZK_DEFAULT.minVolnocasFee,
          })
        )
      })
      .catch((error) => console.log(error))

    dispatchReconLinkVars(vars)
  }

  // getAndLog(urlBackend + '/recon', [{ico: vars.ico}, {recon-key='xvMYjQIZco7V10bRz0Vi'}])

  // function unlockLocation(location: string) {
  //   let locationNumber = 0
  //   switch (location) {
  //     case urlOnboarding1:
  //       locationNumber = 1
  //       break
  //     case urlOnboarding2:
  //       locationNumber = 2
  //       break
  //     case urlOnboarding3:
  //       locationNumber = 3
  //       break
  //     case urlOnboarding4:
  //       locationNumber = 4
  //       break
  //     default:
  //       locationNumber = 0
  //   }
  dispatch(reducerCurrentLocation(0))
  // }

  function dispatchReconLinkVars(vars: { [key: string]: string }): void {
    if (vars.pracovniEmail) {
      if (validateEmail(vars.pracovniEmail)) {
        dispatch(reducerPracovniEmail(vars.pracovniEmail))
      } else {
        console.log(
          'invalid pracovní email from recon link',
          vars.pracovniEmail
        )
      }
    }
    // SPO sends email instead of pracovniEmail
    if (vars.email) {
      if (validateEmail(vars.email)) {
        dispatch(reducerPracovniEmail(vars.email))
      } else {
        console.log('invalid pracovní email from recon link', vars.email)
      }
    }
    if (vars.kontaktniEmail) {
      if (validateEmail(vars.kontaktniEmail)) {
        dispatch(reducerKontaktniEmail(vars.kontaktniEmail))
        if (!vars.pracovniEmail && !vars.email) {
          dispatch(reducerPracovniEmail(vars.kontaktniEmail))
        }
      } else {
        console.log(
          'invalid kontaktní email from recon link',
          vars.kontaktniEmail
        )
      }
    }
    if (vars.telefon) {
      const telefonBezMezer = vars.telefon.replace(/\s/g, '')
      if (telefonBezMezer.startsWith('+')) {
        console.log(
          'telefon starts with +, capturing predvolba, removing +, capturing telefon, input telefon',
          telefonBezMezer
        )
        dispatch(reducerPredvolba(+telefonBezMezer.slice(1, 4)))
        dispatch(reducerTelefon(+telefonBezMezer.slice(4)))
      } else if (telefonBezMezer.startsWith('00')) {
        console.log(
          'telefon starts with 00, capturing predvolba, removing 00, capturing telefon, input telefon:',
          telefonBezMezer
        )
        dispatch(reducerPredvolba(+telefonBezMezer.slice(2, 5)))
        dispatch(reducerTelefon(+telefonBezMezer.slice(5)))
      } else if (telefonBezMezer.length !== 9) {
        console.log(
          'incomplete phone number, capturing 888888888 instead input telefon:',
          telefonBezMezer
        )
        dispatch(reducerTelefon(888888888))
      } else {
        dispatch(reducerTelefon(+telefonBezMezer))
      }
    } else {
      console.log('missing phone number, capturing 999999999 instead')
      dispatch(reducerTelefon(999999999))
    }
    if (vars.predvolba) {
      const predvolbaBezMezer = +vars.predvolba
        .toString()
        .replace(/\s/g, '')
        .replace('+', '')
        .replace('00', '')
      if (predvolbaBezMezer.toString().length === 3) {
        console.log(
          'predvolba is 3 digits, capturing predvolba, input predvolba:',
          predvolbaBezMezer
        )
        dispatch(reducerPredvolba(predvolbaBezMezer))
      } else {
        console.log(
          'invalid predvolba, capturing 420 instead, input predvolba:',
          vars.predvolba
        )
        dispatch(reducerPredvolba(420))
      }
    } else {
      console.log('predvolba is missing, capturing 420 instead')
      dispatch(reducerPredvolba(420))
    }
    if (vars.icFirmy) {
      dispatch(reducerIcFirmy(vars.icFirmy))
    }
    if (vars.dicFirmy) {
      dispatch(reducerDicFirmy(vars.dicFirmy))
    }

    // if (vars.denniPrispevekNaStravu) {
    //   dispatch(reducerDenniPrispevekNaStravu(+vars.denniPrispevekNaStravu))
    // }
    // if (vars.volnocasovyKreditNaMesic) {
    //   dispatch(reducerVolnocasovyKreditNaMesic(+vars.volnocasovyKreditNaMesic))
    // }
    // if (vars.odpracovanychDnuMesicne) {
    //   dispatch(reducerOdpracovanychDnuVMesici(+vars.odpracovanychDnuMesicne))
    // }
    // if (vars.enabledDenniPrispevekNaStravu) {
    //   dispatch(
    //     reducerEnabledDenniPrispevekNaStravu(
    //       vars.enabledDenniPrispevekNaStravu === 'true'
    //     )
    //   )
    // }
    // if (vars.enabledVolnocasovyKreditNaMesic) {
    //   dispatch(
    //     reducerEnabledVolnocasovyKreditNaMesic(
    //       vars.enabledVolnocasovyKreditNaMesic === 'true'
    //     )
    //   )
    // }
    if (vars.nazevSpolecnosti) {
      dispatch(reducerNazevSpolecnosti(vars.nazevSpolecnosti))
    }
    if (vars.uliceCisloPopisne) {
      dispatch(reducerUliceCisloPopisne(vars.uliceCisloPopisne))
    }
    if (vars.psc) {
      dispatch(reducerPsc(vars.psc))
    }
    if (vars.mesto) {
      dispatch(reducerMesto(vars.mesto))
    }
    // if (vars.opravneneOsoby) {
    //   let opravneneOsoby: string[] = []
    //   // do not use split(',') because it will split on ',' in the middle of a name (when someone has titles)
    //   vars.opravneneOsoby.split(':').forEach((osoba) => {
    //     opravneneOsoby.push(osoba)
    //   })
    //   dispatch(reducerOpravneneOsoby(opravneneOsoby))
    // }
    if (vars.hlavniKontaktniOsoba) {
      let opravneneOsoby: string[] = []
      opravneneOsoby.push(vars.hlavniKontaktniOsoba)
      dispatch(reducerOpravneneOsoby(opravneneOsoby))
    } else {
      dispatch(reducerOpravneneOsoby(['']))
    }
    // 2 additional params
    if (vars.cisloKontraktu) {
      dispatch(reducerCisloKontraktu(vars.cisloKontraktu))
    }
    if (vars.companyCode) {
      dispatch(reducerCompanyCode(vars.companyCode))
    }
    if (vars.pocetZamestnancu) {
      // ORD-1906 - even if the value of vars.pocetZamestnancu from SPO is present but is not a number or is less than 1 (zero or negative), we still want to set it to 1
      const pocetZamestnancu = Math.max(+vars.pocetZamestnancu || 1)
      dispatch(reducerPocetZamestnancu(pocetZamestnancu))
    } else {
      dispatch(reducerPocetZamestnancu(1)) // ORD-1906
    }
  }

  const [redirect, setRedirect] = useState(false)
  // !redirect && loadValuesFromBackendThenDispatch().then(() => setRedirect(true))
  !redirect &&
    loadValuesFromBackendThenDispatch()
      .then(() => setRedirect(true))
      .catch((error) => console.log(error))

  return (
    <div className='magic-link'>
      {/* {JSON.stringify(vars)} */}
      {redirect && <Navigate to={onboardingLocation} />}
    </div>
  )
}

export default ReconLink
