import React, { useRef } from 'react'
import { useOnClickOutsideAndEscape } from 'app/hooks'
import DeleteThinIcon from '@styles/icon/deleteThinIcon'
import Color from 'app/colors'

interface IImportExampleModal {
  showImportExampleModal: boolean
  setShowImportExampleModal: any
}

export const ImportExampleModal = ({
  showImportExampleModal,
  setShowImportExampleModal,
}: IImportExampleModal) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowImportExampleModal(false)
    }
  }

  useOnClickOutsideAndEscape(
    modalRef,
    () => setShowImportExampleModal(false),
    showImportExampleModal,
    setShowImportExampleModal
  )

  return (
    <>
      {showImportExampleModal ? (
        <div className='modal_background' onClick={closeModal} ref={modalRef}>
          <div className='import-example-modal_wrapper'>
            <div className='small-modal_content'>
              <div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>Jméno a Příjmení</h4>
                  <p>
                    Vyplňte jméno a příjmení zaměstnance, které bude uvedeno na
                    kartě.
                  </p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>Číslo zaměstnance</h4>
                  <p>
                    Vyplňte identifikační číslo zaměstnance. Pokud jej nemáte,
                    automaticky se vygeneruje. V dalších krocích již nebude
                    možné číslo měnit, protože slouží pro identifikaci
                    zaměstnance.
                  </p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>
                    Adresa pro doručení karet
                  </h4>
                  <p>
                    Zadejte název společnosti, adresu, jméno, příjmení a tel.
                    číslo kontaktní osoby pro převzetí zásilky karet.
                  </p>
                </div>
                <div className='small-modal_main-content'>
                  <h4 style={{ color: Color.BLUEDARK }}>
                    Stravenkový/volnočasový kredit
                  </h4>
                  <p>
                    Můžete rovnou zadat i hodnotu stravenkového/volnočasového
                    kreditu. Stravenkový kredit zadávejte jako výslednou částku
                    (denní hodnota stravenky x počet odpracovaných dní). Pokud
                    nebude hodnota vyplněna, objednáme zaměstnanci jen kartu.
                  </p>
                </div>
              </div>
            </div>
            <DeleteThinIcon
              className='small-modal_close'
              aria-label='Close modal'
              onClick={() => setShowImportExampleModal((prev: any) => !prev)}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
