import { FunctionComponent, useEffect, useState } from 'react'
import icons from '@styles/dist/system/icons.svg'
import ShoppingCartIcon from '@styles/icon/shoppingCartIcon'
import {
  selectCheckBoxObjednatNahradniKartu,
  selectRowById,
  tableActions,
  TableRow,
} from 'components/table/tableSlice'
import { validateNumericKeyWithWhites } from 'app/util'
import { useAppDispatch, useAppSelector, useModal } from 'app/hooks'
import { Modal } from 'components/modules/modal/Modal'
import PosledniObjednavky from 'components/table/PosledniObjednavky'
import { DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU } from 'app/constants'
import TrashIcon from '@styles/icon/trashIcon'
import {
  reducerEmpDetailRollover,
  selectLanguage,
} from 'components/settings/userSettingsSlice'
import DetailsOfCards from './DetailsOfCards'
import EraseIcon from '@styles/icon/eraseIcon'
import { isDeactivatable, isDeletable } from 'util/TableOps'
import CloseIcon from '@styles/icon/closeIcon'
import { mutateAktivovanyBackend, mutateZamestnanecBackend } from 'app/req'
import { ZamestnanecBackendEditace } from 'app/type'
import { selectAddressByUliceCisloPopisne } from 'components/settings/governanceSlice'
import { addressToAdresaBackendKlic } from 'util/transformer'
import EmployeeDetailAddresses from './EmployeeDetailAddresses'
import { MultiPassCardState } from 'util/CardOps'
import { Address } from 'app/type'
import {
  EMPLOYEE_DETAIL_NOTICE_DISAPPEAR_TIMEOUT,
  TYPING_DEBOUNCE,
} from 'app/tuning'
import { lg } from 'app/facade'
import {
  ACCOUNTS_AND_CARDS,
  ADD_ORDER_TO_CART,
  CANCEL,
  EMPLOYEE_DATA,
  EMPLOYEE_ID,
  FOR_EXAMPLE,
  ID_CAN_NO_LONGER_BE_CHANGED,
  LEISURE_CREDIT,
  MEAL_VOUCHER_CREDIT,
  NAME,
  SURNAME,
} from 'app/constDictionary'

interface EmployeeDetailProps {
  id: string
  volnocasovyKreditZTabulky: number
  stravenkovyKreditZTabulky: number
  pristiVolnocasovy: boolean
  pristiStravenkovy: boolean
  onSubmit: () => void
}

const EmployeeDetail: FunctionComponent<EmployeeDetailProps> = ({
  id,
  volnocasovyKreditZTabulky,
  stravenkovyKreditZTabulky,
  pristiVolnocasovy,
  pristiStravenkovy,
  onSubmit,
}) => {
  const dispatch = useAppDispatch()

  // let intervalNumber = 0

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // intervalNumber++
  //     console.log('calling interval')
  //   }, 500)
  //   return () => {
  //     console.log('calling return')
  //     clearInterval(interval)
  //   }
  // })

  let row = useAppSelector(selectRowById(id)) as TableRow
  let jmeno = row?.name
  let prijmeni = row?.surname
  const [previousJmenoLetters, setPreviousJmenoLetters] = useState('')
  const [previousPrijmeniLetters, setPreviousPrijmeniLetters] = useState('')
  const [tabUdajeOZamestnanci, setTabUdajeOZamestnanci] = useState(false)
  const address: Address = useAppSelector(
    selectAddressByUliceCisloPopisne(row?.uliceCisloPopisne || '')
  ) as Address // POTENTIAL SOURCE OF BUGS

  const {
    isShown: isShownDeaktivovatZamestnance,
    toggle: toggleDeaktivovatZamestnance,
  } = useModal()
  const {
    isShown: isShownOdstranitZamestnance,
    toggle: toggleOdstranitZamestnance,
  } = useModal()

  // duplicated in DetailsOfCards.tsx
  function objednatNahradniKartu(employeeId: string) {
    dispatch(tableActions.objednatNahradniKartu({ id: employeeId }))
    dispatch(
      tableActions.setCardStatus({
        id: employeeId,
        cardStatus: MultiPassCardState.NáhradníKartaVKošíku,
      })
    )
    callBackendObjednatNahradniKartu(employeeId)
  }

  function callBackendObjednatNahradniKartu(employeeId: string) {
    // we don't do anything here, because the replacement can be voided until the order and its related data are persisted to backend
    return
  }

  function removeEmployee() {
    dispatch(tableActions.removeRowFromTheTable({ id }))
    dispatch(reducerEmpDetailRollover(false))
  }

  function deactivateEmployee() {
    dispatch(tableActions.deactivateById({ id }))
    mutateAktivovanyBackend(id, false)
    dispatch(reducerEmpDetailRollover(false))
  }

  // TAB - ÚČTY A KARTY
  const [volnocasovyKredit, setVolnocasovyKredit] = useState(
    volnocasovyKreditZTabulky
  )
  const [stravenkovyKredit, setStravenkovyKredit] = useState(
    stravenkovyKreditZTabulky
  )
  const [pristineVolnocas, setPristineVolnocas] = useState(pristiVolnocasovy)
  const [pristineStrav, setPristineStrav] = useState(pristiStravenkovy)

  // name & surname states (for change approval mark)
  const [jmenoChanged, setJmenoChanged] = useState(false)
  const [prijmeniChanged, setPrijmeniChanged] = useState(false)

  const [jmenoTyping, setJmenoTyping] = useState(false)
  const [prijmeniTyping, setPrijmeniTyping] = useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  useEffect(() => {
    if (jmenoTyping) {
      const timer = setTimeout(() => {
        setJmenoTyping(false)
        if (jmeno === '') {
          changeJmeno(previousJmenoLetters)
          jmeno = previousJmenoLetters
          row = { ...row, name: previousJmenoLetters }
          // be careful, if this console.log() is commented out, the app will optimize and not go into this if statement
          console.log('Restoring previous name')
          // console.log(jmeno, previousJmenoLetters, row?.name)
          // @ts-ignore
          document.getElementById('input-jmeno').value = row?.name
        }
      }, TYPING_DEBOUNCE)
      return () => clearTimeout(timer)
    }
    if (prijmeniTyping) {
      const timer = setTimeout(() => {
        setPrijmeniTyping(false)
        if (prijmeni === '') {
          changePrijmeni(previousPrijmeniLetters)
          prijmeni = previousPrijmeniLetters
          row = { ...row, surname: previousPrijmeniLetters }
          // be careful, if this console.log() is commented out, the app will optimize and not go into this if statement
          console.log('Restoring previous surname')
          // console.log(prijmeni, previousPrijmeniLetters, row?.surname)
          // @ts-ignore
          document.getElementById('input-prijmeni').value = row?.surname
        }
      }, TYPING_DEBOUNCE)
      return () => clearTimeout(timer)
    }
  }, [jmenoTyping, prijmeniTyping])

  useEffect(() => {
    if (jmenoChanged) {
      const timer = setTimeout(() => {
        setJmenoChanged(false)
      }, EMPLOYEE_DETAIL_NOTICE_DISAPPEAR_TIMEOUT)
      return () => clearTimeout(timer)
    }
    if (prijmeniChanged) {
      const timer = setTimeout(() => {
        setPrijmeniChanged(false)
      }, EMPLOYEE_DETAIL_NOTICE_DISAPPEAR_TIMEOUT)
      return () => clearTimeout(timer)
    }
  }, [jmenoChanged, prijmeniChanged])

  // Force reset of form when changing employee
  useEffect(() => {
    pristiVolnocasovy ? setPristineVolnocas(true) : setPristineVolnocas(false)
    pristiStravenkovy ? setPristineStrav(true) : setPristineStrav(false)
  }, [id])

  function changeJmeno(value: string) {
    dispatch(
      tableActions.setName({
        id: id,
        name: value,
      })
    )
    const zamestnanecBackend: ZamestnanecBackendEditace = {
      id: +id,
      jmeno: value,
      prijmeni: prijmeni,
      adresa: addressToAdresaBackendKlic(address, row.oddeleni),
      deaktivovaneMesice: row.deactivatedMonths,
      zaplaceneMesice: row.paidMonths,
    }
    setJmenoTyping(true)
    setJmenoChanged(true)
    mutateZamestnanecBackend(zamestnanecBackend)
  }

  function changePrijmeni(value: string) {
    dispatch(
      tableActions.setSurname({
        id: id,
        surname: value,
      })
    )
    const zamestnanecBackend: ZamestnanecBackendEditace = {
      id: +id,
      jmeno: jmeno,
      prijmeni: value,
      adresa: addressToAdresaBackendKlic(address, row.oddeleni),
      deaktivovaneMesice: row.deactivatedMonths,
      zaplaceneMesice: row.paidMonths,
    }
    setPrijmeniChanged(true)
    setPrijmeniTyping(true)
    mutateZamestnanecBackend(zamestnanecBackend)
  }

  // OBJEDNANI NAHRADNI KARTY
  const checkBoxObjednatNahradniKartu = useAppSelector(
    selectCheckBoxObjednatNahradniKartu(id)
  )

  function canOrderReplacementCard(): boolean {
    return (
      row &&
      !(
        row.cardStatus === MultiPassCardState.VKošíku ||
        row.cardStatus === MultiPassCardState.VeVýrobě ||
        row.cardStatus === MultiPassCardState.Vyrobená
      )
    )
  }

  return (
    <div key={id}>
      <span className='💪' style={{ justifyContent: 'space-between' }}>
        <div>
          <h1 className='u-size-l20 u-mb-3'>
            {jmeno} {prijmeni} - #{id}
          </h1>
        </div>
        {/* {isDeletable(row) ? (
          <div>
            <button
              className='button-as-anchor'
              onClick={toggleOdstranitZamestnance}
            >
              <EraseIcon width={16} height={16} fill={Color.BLUE} />
              <span className='u-ml-1'>odstranit</span>
            </button>
          </div>
        ) : (
          <div>
            <button
              className='button-as-anchor'
              onClick={toggleDeaktivovatZamestnance}
            >
              <TrashIcon width={16} height={16} />
              <span className='u-ml-1'>deaktivovat</span>
            </button>
          </div>
        )} */}
      </span>

      <div className='tabs u-mb-4'>
        <div className='tabs__links'>
          <div>
            <button
              onClick={() => setTabUdajeOZamestnanci(false)}
              className={
                'button-as-anchor tabs__link' +
                (!tabUdajeOZamestnanci ? ' tabs__link--active' : '')
              }
            >
              {lg(lang, ACCOUNTS_AND_CARDS)}
            </button>
          </div>
          <div>
            <button
              onClick={() => setTabUdajeOZamestnanci(true)}
              className={
                'button-as-anchor tabs__link' +
                (tabUdajeOZamestnanci ? ' tabs__link--active' : '')
              }
            >
              {lg(lang, EMPLOYEE_DATA)}
            </button>
          </div>
        </div>
      </div>
      {!tabUdajeOZamestnanci && (
        <div>
          <div className='highlight u-mb-3 u-py-3 u-px-2'>
            <div className='highlight__content'>
              <div className='input u-mb-4'>
                <label className='input__label'>
                  {lg(lang, LEISURE_CREDIT)}
                </label>
                <div className='input__wrapper'>
                  <input
                    // id='input-volnocasovy-kredit'
                    // ref={inputVolnocasovyKredit}
                    // value={volnocasovyKredit}
                    defaultValue={volnocasovyKreditZTabulky}
                    onChange={(e) => {
                      setPristineVolnocas(false)
                      setVolnocasovyKredit(+e.target.value)
                    }}
                    placeholder={`${lg(lang, FOR_EXAMPLE)} 500 Kč`}
                    type='tel'
                    onKeyPress={validateNumericKeyWithWhites}
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </div>
              </div>
              <div className='input'>
                <label className='input__label'>
                  {lg(lang, MEAL_VOUCHER_CREDIT)}
                </label>
                <div className='input__wrapper'>
                  <input
                    defaultValue={stravenkovyKreditZTabulky}
                    onChange={(e) => {
                      setPristineStrav(false)
                      setStravenkovyKredit(+e.target.value)
                    }}
                    placeholder={
                      lg(lang, FOR_EXAMPLE) +
                      DOPORUCENY_DENNI_PRISPEVEK_NA_STRAVU +
                      ' Kč'
                    }
                    type='tel'
                    onKeyPress={validateNumericKeyWithWhites}
                    onWheel={(e) => e.currentTarget.blur()}
                  />
                </div>
              </div>
            </div>
            <label
              className={`checkbox u-mb-0 u-mt-4 ${
                !canOrderReplacementCard() ? 'disabled-href' : ''
              }`}
              style={
                canOrderReplacementCard()
                  ? {}
                  : {
                      opacity: 0.8,
                      color: 'rgba(0,0,0,0.5)',
                    }
              }
            >
              {/* {lg(lang, RECIEVING_EMAILS_CONSENT)} */}
              Objednat náhradní kartu
              <input
                type='checkbox'
                checked={checkBoxObjednatNahradniKartu}
                onChange={() => {
                  dispatch(
                    tableActions.checkBoxObjednatNahradniKartu({
                      id: id,
                      checkBoxObjednatNahradniKartu:
                        !checkBoxObjednatNahradniKartu,
                    })
                  )
                }}
              />
              <span className='checkbox__box'></span>
            </label>
            <a
              onClick={() => {
                dispatch(
                  tableActions.setLeisureCredit({
                    id: id,
                    amount: +(pristineVolnocas
                      ? +volnocasovyKreditZTabulky
                      : +volnocasovyKredit),
                  })
                )
                dispatch(
                  tableActions.setGastroCredit({
                    id: id,
                    amount: +(pristineStrav
                      ? +stravenkovyKreditZTabulky
                      : +stravenkovyKredit),
                  })
                )
                onSubmit()
                // OBJEDNANI NAHRADNI KARTY
                if (checkBoxObjednatNahradniKartu) {
                  objednatNahradniKartu(id)
                }
              }}
              className='btn u-pl-2 u-mt-3 u-mb-0 u-size-base14 btn--primary btn--small'
            >
              <svg className='icon icon--24'>
                <ShoppingCartIcon width={24} height={24} />
              </svg>
              {lg(lang, ADD_ORDER_TO_CART)}
            </a>
          </div>
          {/* <div className='highlight u-mb-3 u-py-3 u-px-2 highlight--dark'>
            <div className='highlight__content'>
              <p className='grid-x align-bottom'>
                <span className='cell auto'>
                  <strong>{lg(lang, IN_TOTAL)}</strong>
                </span>
                <small className='cell shrink u-text-right u-ml-1 u-size-base14'>
                  {numberWithSpaces(
                    (
                      +(pristineVolnocas
                        ? +volnocasovyKreditZTabulky
                        : +volnocasovyKredit) +
                      +(pristineStrav
                        ? +stravenkovyKreditZTabulky
                        : +stravenkovyKredit)
                    ).toString()
                  )}{' '}
                  Kč
                </small>
              </p>
            </div>
          </div> */}
        </div>
      )}
      {!tabUdajeOZamestnanci && (
        <div>
          {id && <DetailsOfCards employeeId={id} />}
          <PosledniObjednavky id={id} />
        </div>
      )}
      {tabUdajeOZamestnanci && (
        <div className='highlight u-mb-3 u-py-3 u-px-2'>
          <div className='highlight__content'>
            <div className='input u-mb-4'>
              <label className='input__label'>{lg(lang, NAME)}</label>
              <div className='input__wrapper'>
                <input
                  id='input-jmeno'
                  defaultValue={jmeno}
                  placeholder='Např.: Karel'
                  type='text'
                  onChange={(e) => {
                    setPreviousJmenoLetters(jmeno)
                    changeJmeno(e.target.value)
                  }}
                  className={
                    jmenoChanged && !jmenoTyping ? 'changed-input bg-95' : ''
                  }
                />
              </div>
            </div>
            <div className='input u-mb-4'>
              <label className='input__label'>{lg(lang, SURNAME)}</label>
              <div className='input__wrapper'>
                <input
                  id='input-prijmeni'
                  defaultValue={prijmeni}
                  placeholder='Např.: Novák'
                  type='text'
                  onChange={(e) => {
                    setPreviousPrijmeniLetters(prijmeni)
                    changePrijmeni(e.target.value)
                  }}
                  className={
                    prijmeniChanged && !prijmeniTyping
                      ? 'changed-input bg-95'
                      : ''
                  }
                />
              </div>
            </div>
            <div className='input'>
              <label className='input__label'>{lg(lang, EMPLOYEE_ID)}</label>
              <div className='input__wrapper'>
                <input
                  value={id}
                  disabled={true}
                  type='text'
                  readOnly
                  style={{ backgroundColor: 'rgba(0,0,0,0.03)', opacity: 0.8 }}
                />
              </div>
              <span className='input__help'>
                {lg(lang, ID_CAN_NO_LONGER_BE_CHANGED)}
              </span>
            </div>
          </div>
        </div>
      )}
      {tabUdajeOZamestnanci && <EmployeeDetailAddresses id={id} />}
      {tabUdajeOZamestnanci && (
        <>
          {isDeactivatable(row) ? (
            <div className='u-mb-4'>
              <button
                onClick={() => {
                  toggleDeaktivovatZamestnance()
                }}
                className='button-as-anchor u-size-s u-color-red u-mb-2'
              >
                <svg className='icon icon--16'>
                  <TrashIcon width={16} height={16} />
                </svg>
                <strong> Deaktivovat zaměstnance</strong>
              </button>
              <p className='u-size-base14'>
                Zaměstnanec bude deaktivován první den následujícího měsíce.
                <br />
                Zaměstnanec bude přesunut v tabulce uživatelů mezi deaktivované.
              </p>
            </div>
          ) : (
            isDeletable(row) && (
              <div className='u-mb-4'>
                <button
                  onClick={() => {
                    toggleOdstranitZamestnance()
                  }}
                  className='button-as-anchor u-size-s u-color-red u-mb-2'
                >
                  <svg className='icon icon--16'>
                    <EraseIcon width={16} height={16} />
                  </svg>
                  <strong className='u-ml-1'>Smazat uživatele</strong>
                </button>
                <p className='u-size-base14'>Uživatel bude smazán z tabulky.</p>
              </div>
            )
          )}
        </>
      )}
      {/* MODALS */}
      {/* Modal - deaktivovat zaměstnance */}
      <Modal
        isShown={isShownDeaktivovatZamestnance}
        hide={toggleDeaktivovatZamestnance}
        headerText='Zavřít'
        subheaderText='Deaktivovat zaměstnance'
        modalContent={
          <div className='modal__content'>
            <div className='grid-x u-mt-3'>
              <p className='u-size-base14 u-mt-2'>
                Uživatel bude deaktivován a nebude možné mu objednávat kredity.
              </p>
              <p className='u-size-base14 u-mt-3'>
                Opravdu chcete deaktivovat zaměstnance{' '}
                <b>
                  {jmeno} {prijmeni}
                </b>
                ?
              </p>
              <div className='grid-x u-mt-3'>
                <a
                  onClick={() => {
                    deactivateEmployee()
                    toggleDeaktivovatZamestnance()
                    onSubmit()
                  }}
                  className='btn u-pl-2 u-size-base14 u-mr-1 btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <use href={icons + '#sprite-trash'} />
                  </svg>
                  Deaktivovat
                </a>
                <a
                  onClick={() => {
                    toggleDeaktivovatZamestnance()
                  }}
                  className='btn u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
                >
                  <svg className='icon icon--24'>
                    <CloseIcon />
                  </svg>
                  {lg(lang, CANCEL)}
                </a>
              </div>
            </div>
          </div>
        }
      />
      {/* Modal - odstranit zaměstnance */}
      <Modal
        isShown={isShownOdstranitZamestnance}
        hide={toggleOdstranitZamestnance}
        headerText='Zavřít'
        subheaderText='Odstranit zaměstnance'
        modalContent={
          <div className='modal__content'>
            <div className='grid-x u-mt-3'>
              <p className='u-size-base14 u-mt-2'>
                Uživatel bude odstraněn z tabulky zaměstnanců.
              </p>
              <p className='u-size-base14 u-mt-3'>
                Opravdu chcete odstranit zaměstnance{' '}
                <b>
                  {jmeno} {prijmeni}
                </b>
                ?
              </p>
              <div className='grid-x u-mt-3'>
                <a
                  onClick={() => {
                    removeEmployee()
                    toggleOdstranitZamestnance()
                    onSubmit()
                  }}
                  className='btn u-pl-2 u-size-base14 u-mr-1 btn--primary btn--small'
                >
                  <svg className='icon icon--24'>
                    <use href={icons + '#sprite-trash'} />
                  </svg>
                  Odstranit
                </a>
                <a
                  onClick={() => {
                    toggleOdstranitZamestnance()
                  }}
                  className='btn u-pl-2 u-size-base14 btn--primary btn--outlined btn--small'
                >
                  <svg className='icon icon--24'>
                    <CloseIcon />
                  </svg>
                  {lg(lang, CANCEL)}
                </a>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default EmployeeDetail
