import React, { FunctionComponent, useEffect, useState } from 'react'
import DirectionIcon from '@styles/icon/directionIcon'
import PenIcon from '@styles/icon/penIcon'
import { Modal } from '../modules/modal/Modal'
import Stepper from './Stepper'
import Smlouvy from './Smlouvy'
import { modals, useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectIcoFirmy,
  selectNazevSpolecnosti,
  selectMesto,
  selectPsc,
  selectOpravneneOsoby,
  selectUliceCisloPopisne,
  reducerCopyActualStateToThePreviousState,
  reducerRestorePreviousFiremniUdaje,
  reducerRemoveEmptyOpravneneOsoby,
  selectDicFirmy,
  selectCompanyCode,
  selectCisloKontraktu,
  reducerCompanyId,
  reducerAllowSodexoConnect,
} from 'components/onboarding/features/firemniUdajeSlice'
import { reducerCurrentLocation } from './features/stepperSlice'
import OIIIModal from 'components/modules/modal/Modals'
import { urlBackend, urlRecon4, urlMultiPassCard, urlOnboarding4 } from 'App'
import { createMagicLink } from 'components/magiclink/MagicLinkTools'
import {
  selectEnabledDenniPrispevekNaStravu,
  selectEnabledVolnocasovyKreditNaMesic,
  selectPocetZamestnancu,
  selectOdpracovanychDnuVMesici,
  selectDenniPrispevekNaStravuOnboarding,
  selectVolnocasovyKreditNaMesic,
} from './features/mesicniNakladySlice'
import {
  selectPracovniEmail,
  selectTelefon,
  selectPredvolba,
} from './features/objednavatelSlice'
import { splitUliceCisloPopisne } from 'util/ValidFormatOps'
import Color from 'app/colors'
import { callInstinkt } from 'util/3rdPartyOps'
import { lg } from 'app/facade'
import {
  AFFIDAVIT,
  AFFIDAVIT_1,
  AFFIDAVIT_CONTENT,
  CLOSE,
  DRAFT_CONTRACT,
  EDIT,
  FIRM_INFORMATION,
  OB3_BUTTON,
  OB3_SUBHEADER,
  ONLY_A_STATUTORY_BODY_CAN_CONFIRM_THE_CONTRACT,
  SEE,
  SIGNED_BY_SENDING,
  SIGNING_CONTRACT,
  STATUTORY_BODY_TEXT,
  YOUR_DATA,
} from 'app/constDictionary'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { RECON_EMAIL_COM } from 'app/constants'
import { useNavigate } from 'react-router-dom'
import { nanoid } from '@reduxjs/toolkit'
import { createBackendTelefon } from 'util/transformer'

export const OpravneneOsobyList = () => {
  const opravneneOsoby = useAppSelector(selectOpravneneOsoby)
  const posledniOpravnenaOsoba = opravneneOsoby[opravneneOsoby.length - 1]
  return (
    // Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.
    <span>
      {opravneneOsoby
        .filter((o) => o !== '')
        .slice(0, opravneneOsoby.length - 1)
        .map((opravnenaOsoba: string) => (
          <span key={nanoid(5)}>{opravnenaOsoba}, </span>
        ))}
      {posledniOpravnenaOsoba && <span>{posledniOpravnenaOsoba}</span>}
    </span>
  )
}

const OnboardingThree: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const lang = useAppSelector(selectLanguage) || 'cz'

  const [isPoverenaOsoba, setIsPoverenaOsoba] = useState(false)
  const [hasValidationFailed, setHasValidationFailed] = useState(false)
  const { isShown, toggle } = modals[0]()
  const { isShown: isShown2, toggle: toggle2 } = modals[1]()
  const icFirmy = useAppSelector(selectIcoFirmy)
  const dicFirmy = useAppSelector(selectDicFirmy)
  const nazevSpolecnosti = useAppSelector(selectNazevSpolecnosti)
  const uliceCisloPopisne = useAppSelector(selectUliceCisloPopisne)
  const mesto = useAppSelector(selectMesto)
  const psc = useAppSelector(selectPsc)

  const [bigWindow, setBigWindow] = useState(
    window.matchMedia('(min-width: 980px)').matches
  )

  useEffect(() => {
    console.log(`dicFirmy, ${dicFirmy}`)
    window
      .matchMedia('(min-width: 980px)')
      .addEventListener('change', (e) => setBigWindow(e.matches))
  }, [])

  const submitText = lg(lang, OB3_BUTTON)

  const onConfirm = () => toggle()
  const onCancel = () => {
    dispatch(reducerRestorePreviousFiremniUdaje())
    toggle()
  }
  const onCancel2 = () => toggle2()

  const osobaPoverenaChecked = (e: any) => {
    setHasValidationFailed(false)
    setIsPoverenaOsoba(e.target.checked)
  }

  const ValidationFailed: FunctionComponent = () => {
    return (
      <button
        onClick={() => setHasValidationFailed(true)}
        className='btn btn--primary'
      >
        <svg className='icon icon--24'>
          <DirectionIcon />
        </svg>
        {submitText}
      </button>
    )
  }
  const ValidationSucceeded: FunctionComponent = () => {
    return (
      <button onClick={callBackendAndProceed} className='btn btn--primary'>
        <svg className='icon icon--24'>
          <DirectionIcon />
        </svg>
        {submitText}
      </button>
    )
  }

  // for MagicLink only
  const pracovniEmail = useAppSelector(selectPracovniEmail)
  const opravneneOsoby = useAppSelector(selectOpravneneOsoby)
  const telefon = useAppSelector(selectTelefon)
  const predvolba = useAppSelector(selectPredvolba)
  const enabledDenniPrispevekNaStravu = useAppSelector(
    selectEnabledDenniPrispevekNaStravu
  )
  const enabledVolnocasovyKreditNaMesic = useAppSelector(
    selectEnabledVolnocasovyKreditNaMesic
  )
  const pocetZamestnancu = useAppSelector(selectPocetZamestnancu)
  const odpracovanychDnuMesicne = useAppSelector(selectOdpracovanychDnuVMesici)
  const denniPrispevekNaStravu = useAppSelector(
    selectDenniPrispevekNaStravuOnboarding
  )
  const volnocasovyKreditNaMesic = useAppSelector(
    selectVolnocasovyKreditNaMesic
  )

  // for Magic Link only 3/3 - two additional fields that come from SPO as part of Recontracting process
  const cisloKontraktu = useAppSelector(selectCisloKontraktu)
  const companyCode = useAppSelector(selectCompanyCode)

  function formatovaneOpravneneOsobyProMagicLink(opravneneOsoby: string[]) {
    return opravneneOsoby.join(':')
  }

  async function callBackendAndProceed() {
    console.log('submit - firemni udaje - callbackend company')

    callInstinkt(4)

    let dailyGastroAllowanceNumber = parseInt(
      (denniPrispevekNaStravu || 0).toString()
    )

    const magicLink = createMagicLink({
      location: urlOnboarding4,
      vars: {
        pracovniEmail: pracovniEmail,
        telefon: telefon,
        predvolba: predvolba,
        pocetZamestnancu: pocetZamestnancu,
        icFirmy: icFirmy,
        dicFirmy: dicFirmy,
        odpracovanychDnuMesicne: odpracovanychDnuMesicne,
        denniPrispevekNaStravu: denniPrispevekNaStravu,
        volnocasovyKreditNaMesic: volnocasovyKreditNaMesic,
        enabledDenniPrispevekNaStravu: enabledDenniPrispevekNaStravu,
        enabledVolnocasovyKreditNaMesic: enabledVolnocasovyKreditNaMesic,
        nazevSpolecnosti: nazevSpolecnosti,
        uliceCisloPopisne: uliceCisloPopisne,
        mesto: mesto,
        psc: psc,
        isPoverenaOsoba: isPoverenaOsoba,
        opravneneOsoby: formatovaneOpravneneOsobyProMagicLink(opravneneOsoby),
        cisloKontraktu: cisloKontraktu,
        companyCode: companyCode,
      },
    })
    console.log({ magicLink })

    const { ulice, cisloPopisne } = splitUliceCisloPopisne(uliceCisloPopisne!)

    dispatch(reducerRemoveEmptyOpravneneOsoby())
    const requestBody = JSON.stringify({
      email: pracovniEmail || RECON_EMAIL_COM,
      company_name: nazevSpolecnosti,
      ico: icFirmy,
      street_address: ulice,
      street_number: cisloPopisne,
      address_locality: mesto,
      address_postal_code: psc.replace(/\s/g, ''),
      persons: opravneneOsoby,
      magic_link: magicLink,
      employees: +(pocetZamestnancu as number),
      dailyGastroAllowance: dailyGastroAllowanceNumber, // requested by Pepa - nemůže založit firmu dřív, neměl by kam to propsat, když to dostane v kroku 1.
      dic: dicFirmy || '', // requested by Pepa 12.4.22
      contract_number: cisloKontraktu || '', // requested by Pepa 13.9.22 // changed from contract_code to contract_number 22.11.22
      company_code: companyCode, // requested by Pepa 13.9.22
      phone: createBackendTelefon(predvolba, telefon),
    })

    console.log('Request Body, ', requestBody)

    setIsLoading(true)

    await fetch(urlBackend + '/onboarding/sign-contract', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then((response) => {
        if (response.status === 201) {
          console.log(
            'response.status === 201, company has signed the contract. Redirecting to the next step (4).'
          )
          setIsLoading(false)
          dispatch(reducerCurrentLocation(4))
          navigate(
            window.location.href.includes(urlMultiPassCard)
              ? urlOnboarding4
              : urlRecon4
          )
        } else {
          console.log(
            'response.status !== 201 (created), company has not signed the contract. Not continuing to the next step (4).'
          )
        }
        console.log('response: ', response)
        return response.json()
      })
      .then((data) => {
        console.log('data: ', data)
        dispatch(reducerCompanyId(data.companyId))
        dispatch(reducerAllowSodexoConnect(data.allowSodexoConnect))

        // není zapotřebí -> po konzultaci s Pepou 7.3.2023
        // dispatch(reducerCisloKontraktu(data.contractId))

        // prepared for task - [ ] https://czsodexo.atlassian.net/browse/ORD-1985
        // dispatch(reducerAdUserCode(data.adUserCode)) // not used anywhere, only in line below
        // navigate('https://sodexo-ucet.cz/')
        return data
      })
      .catch((error) => console.log(error))
  }

  return (
    <div>
      <Modal
        isShown={isShown}
        hide={onCancel}
        headerText={lg(lang, CLOSE)}
        subheaderText={lg(lang, FIRM_INFORMATION)}
        modalContent={<OIIIModal onSubmit={onConfirm} />}
      />
      <Modal
        isShown={isShown2}
        hide={onCancel2}
        headerText={lg(lang, CLOSE)}
        subheaderText={lg(lang, AFFIDAVIT)}
        modalContent={
          <div style={{ textAlign: 'justify' }}>
            {lg(lang, AFFIDAVIT_CONTENT)}
          </div>
        }
      />
      <div className='container u-my-6'>
        <Stepper />
        <h2 className='heading heading--2 u-text-center u-mb-2'>
          {lg(lang, SIGNING_CONTRACT)}
        </h2>
        <p className='u-mb-8 u-text-center'>{lg(lang, OB3_SUBHEADER)}</p>

        <div className='💪'>
          <div
            className='highlight u-pr-1 u-pt-0 highlight--transparent'
            style={{
              maxWidth: '30rem',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div
              className='highlight__content'
              style={{ marginLeft: bigWindow ? '-40px' : '0px' }}
            >
              <h3 className='u-pl-3 u-pb-2'>{lg(lang, YOUR_DATA)}</h3>
              <div
                className='highlight u-pt-4'
                style={{
                  width: bigWindow ? 400 : 462,
                }}
              >
                <div className='highlight__content u-pt-05 u-pb-0'>
                  <div className='grid-x u-pb-3'>
                    <div className='cell medium-8 u-pr-medium-2'></div>
                    <div className='cell medium-2'>
                      <button
                        className='button-as-anchor'
                        onClick={() => {
                          dispatch(reducerCopyActualStateToThePreviousState())
                          toggle()
                        }}
                        style={{ color: Color.VIOLET }}
                      >
                        <svg className='icon icon--24'>
                          <PenIcon width={24} height={24} />
                        </svg>
                        {lg(lang, EDIT)}
                      </button>
                    </div>
                  </div>
                  <p className='u-pb-05'>
                    <b>{nazevSpolecnosti}</b>
                  </p>
                  <p>{<OpravneneOsobyList />}</p>
                  <p>{uliceCisloPopisne}</p>
                  <p>
                    {mesto}, {psc}
                  </p>
                  <p>IČ {icFirmy}</p>
                </div>
              </div>
            </div>
            {!bigWindow && (
              <span className='u-ml-3'>
                <h3 className='u-pl-3 u-pb-2'>{lg(lang, DRAFT_CONTRACT)}</h3>
                <div className='highlight u-py-4' style={{ width: 462 }}>
                  <Smlouvy />
                </div>
              </span>
            )}
          </div>
          {/* RIGHT COLUMN */}
          {bigWindow && (
            <span className='u-ml-3'>
              <h3 className='u-pl-3 u-pb-2'>{lg(lang, DRAFT_CONTRACT)}</h3>
              <div className='highlight u-pt-2 u-pb-3' style={{ width: 462 }}>
                <Smlouvy />
              </div>
            </span>
          )}
        </div>
        <div
          className='u-py-4 u-px-1'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            // https://czsodexo.atlassian.net/browse/ORDTEX-35
            border: '0px solid #E1E2F8',
            boxSizing: 'border-box',
            borderRadius: '8px',
          }}
        >
          <label className='checkbox u-mb-1'>
            {lg(lang, STATUTORY_BODY_TEXT)} ({lg(lang, SEE)}{' '}
            <input
              type='checkbox'
              defaultChecked={isPoverenaOsoba}
              onChange={osobaPoverenaChecked}
            />
            <span className='checkbox__box'></span>
            {hasValidationFailed && !isPoverenaOsoba && (
              <span className='input__error' style={{ display: 'block' }}>
                {lg(lang, ONLY_A_STATUTORY_BODY_CAN_CONFIRM_THE_CONTRACT)}
              </span>
            )}
            <button onClick={toggle2} className='button-as-anchor'>
              {lg(lang, AFFIDAVIT_1)}
            </button>
            ).
          </label>
          <p className='u-mt-2 u-text-center'>{lg(lang, SIGNED_BY_SENDING)}</p>
        </div>
        <div className='u-my-6 u-text-center'>
          {isPoverenaOsoba ? (
            <>
              <ValidationSucceeded />
              {isLoading && (
                <span
                  style={{ fontSize: '20px' }}
                  className='u-ml-2 loader'
                ></span>
              )}
            </>
          ) : (
            <ValidationFailed />
          )}
        </div>
      </div>
    </div>
  )
}

export default OnboardingThree
