import React from 'react'
import Stepper from '../Stepper'
import { useAppSelector } from 'app/hooks'
import { selectLanguage } from 'components/settings/userSettingsSlice'
import { lg } from 'app/facade'
import {
  LOGIN_CREDENTIALS as REGISTRATION_OR_LOGIN,
  OB4_SUBHEADER_1,
} from 'app/constDictionary'
import OnboardingFourNovyZakaznikSodexoConnect from './OnboardingFourNovyZakaznikSodexoConnect'
import OnboardingFourStepOneRegistrovanyZakaznik from './OnboardingFourStepOneRegistrovanyZakaznik'
import OnboardingFourRegistrovanyZakaznikSodexoConnect from './OnboardingFourRegistrovanyZakaznikSodexoConnect'
import { selectAllowSodexoConnect } from '../features/firemniUdajeSlice'
import { callInstinkt } from 'util/3rdPartyOps'
import OnboardingFourRegistrovanyZakaznikSodexoUcet from './OnboardingFourRegistrovanyZakaznikSodexoUcet'
import OnboardingFourRegistrovanyZakaznikSodexoClassic from './OnboardingFourRegistrovanyZakaznikClassicTs'
import OnboardingFourNovyZakaznikClassicTs from './OnboardingFourNovyZakaznikClassicTs'
import OnboardingFourRegistrovatSeDanyil from './OnboardingFourRegistrovatSeDanyil'
import OnboardingFourPrihlasitSeDanyil from './OnboardingFourPrihlasitSeDanyil'
import Color from 'app/colors'
import DirectionIcon from "../../../@styles/icon/directionIcon";

const OnboardingFour: React.FC = () => {
  const lang = useAppSelector(selectLanguage) || 'cz'

  // const allowSodexoConnect =
  //   (useAppSelector(selectAllowSodexoConnect) as boolean) || false
  // const [marketingConsentStepOne, setMarketingConsentStepOne] =
  //   React.useState(false)

  const [tab, setTab] = React.useState('registrovat-se')

  function onSubmit() {
    callInstinkt(5)
    // dispatch(reducerCurrentLocation(nextLocationNumber)) // prevent the user from getting loaded into /dokonceni-registrace when he types in the URL of the server.
  }

  return (
    <div className='container u-my-6'>
      <Stepper />
      <h2 className='heading heading--2 u-text-center u-mb-5'>Registrace</h2>

      <div className='grid-x u-justify-center u-text-center'>
        <div
          className='grid-y large-6 u-pl-large-3 u-py-2'
          style={{
              cursor: 'pointer',
              borderTopRightRadius: 24,
              borderTopLeftRadius: 24,
              background: `${
                  tab === 'prihlasit-se' ? 'white' : `${Color.BLUE_DANYIL}`
              }`,
              opacity: `${tab === 'prihlasit-se' ? '0.5' : '1'}`,
          }}
          onClick={() => setTab('registrovat-se')}
        >
          <h3 className='u-mb-05'>Registrovat se</h3>
          <small>
            Chci si vytvořit <b>nové</b> registrační údaje do Portálu Pluxee
          </small>
        </div>
        <div
          className='grid-y large-6 u-pl-large-3 u-py-2'
          style={{
              cursor: 'pointer',
              borderTopRightRadius: 24,
              borderTopLeftRadius: 24,
              background: `${
                tab === 'registrovat-se' ? 'white' : `${Color.BLUE_DANYIL}`
              }`,
              opacity: `${tab === 'registrovat-se' ? '0.5' : '1'}`,
          }}
          onClick={() => setTab('prihlasit-se')}
        >
          <h3 className='u-mb-05'>Přihlásit se</h3>
          <small>
            Chci použít <b>stávající</b> registrační údaje do Portálu Pluxee
          </small>
        </div>
      </div>
      <div
        style={{
          boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.2)',
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
        }}
      >
        {tab === 'registrovat-se' ? (
          <OnboardingFourRegistrovatSeDanyil onSubmit={onSubmit} />
        ) : (
          <OnboardingFourPrihlasitSeDanyil onSubmit={onSubmit} />
        )}
      </div>
    </div>
  )
}

export default OnboardingFour
