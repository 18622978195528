import React from 'react'
import { urlSelfcareTable } from 'App'
import { Navigate, useLocation } from 'react-router-dom'
import { unpackURIEncodedVarsFromURL } from './MagicLinkTools'
import { setPrecious } from 'app/back'
import { useAppDispatch } from 'app/hooks'
import { govActions } from 'components/settings/governanceSlice'
import { verne } from 'app/julesverne'
import { clearCache, clearFlags } from 'app/facade'
import { reducerSetLanguage } from 'components/settings/userSettingsSlice'

const JwtAuth: React.FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const vars = unpackURIEncodedVarsFromURL(location.search)

  console.log('JwtAuth visited.')
  dispatchValues(vars)

  function dispatchValues(vars: { [key: string]: string }): void {
    if (vars.token) {
      // dispatch(eraseDenniKredityZamestnancu())
      verne('vars.token', vars.token)
      setPrecious(vars.token)

      if (vars.token.endsWith('ZI-BO4')) {
        dispatch(govActions.reducerIsCustomUser(true))
      }

      vars.lang
        ? dispatch(reducerSetLanguage(vars.lang))
        : dispatch(reducerSetLanguage('cz'))
      clearFlags()
      clearCache()
    }
  }

  return (
    <div className='jwt-auth'>
      <Navigate to={urlSelfcareTable} />
    </div>
  )
}

export default JwtAuth
