import {
  beMakeAdresa,
  beMakeKontaktniOsoba,
  beMakeOddeleni,
  beMakeSkupina,
  beMakeZamestnanec,
  beObjednavky,
  beMutatePocatekCasu,
  beMakeSpolecnost,
  beUploadExcel,
  urlBackend,
} from 'App'
import { cisloObjednavkyBackendToOrderId } from 'util/transformer'
import {
  splitUliceCisloPopisne,
  splitUliceCisloPopisneZachovatCisloOrientacni,
} from 'util/ValidFormatOps'
import {
  defaultHeaders,
  defaultOptionsDelete,
  defaultOptionsGet,
  defaultOptionsMake,
  getLogoutUrl,
  getPrecious,
  removePrecious,
  TupleString,
} from './back'
import { INTERNAL_ID } from './constants'
import {
  Address,
  AdresaBackend,
  KontaktniOsobaBackendRequest,
  NovaObjednavkaBackend,
  ZamestnanecBackendEditace,
} from './type'

const logResponseAndThrowErrors = (response: Response) => {
  console.log('response: ', response)
  if (!response.ok) {
    throw Error(response.status.toString())
  } else {
    return response.json()
  }
}

const logDataAndReturn = (data: any) => {
  console.log('data: ', data)
  return data
}

export function getAndLog(
  url: string,
  params?: TupleString[],
  extraHeader?: TupleString
) {
  const searchParams = new URLSearchParams()
  params && params.forEach((param) => searchParams.append(param[0], param[1]))
  url = `${url}?${searchParams.toString()}`
  console.log(`GET ${url} ${extraHeader ? `extraHeader: ${extraHeader}` : ''}`)

  return fetch(url, defaultOptionsGet(extraHeader))
    .then(logResponseAndThrowErrors)
    .then(logDataAndReturn)
}

export async function sendAndLog(
  url: string,
  requestObject: any,
  method: string = 'POST'
): Promise<any> {
  console.log(`${method} ${url} with requestObject: %O`, requestObject)
  const requestBody = JSON.stringify(requestObject)

  return fetch(url, defaultOptionsMake(requestBody, method))
    .then(logResponseAndThrowErrors)
    .then(logDataAndReturn)
}

export async function purgeAndLog(url: string): Promise<any> {
  console.log(`DELETE ${url}`)
  return fetch(url, defaultOptionsDelete())
    .then(logResponseAndThrowErrors)
    .then(logDataAndReturn)
}

export async function loadAndOpenFile(
  url: string,
  setLoading: (loading: boolean) => void,
  method: string = 'GET',
  requestObject?: any,
  doubleCheck: boolean = false,
  setNoFileFound?: (noFileFound: boolean) => void
) {
  setLoading(true)

  if (doubleCheck) {
    const response = await fetch(url, defaultOptionsGet())
    if (response.status !== 200) {
      setLoading(false)
      setNoFileFound!(true)
      return
    }
  }
  loadFile(url, method, requestObject)
    .then(function (myBlob) {
      const fileURL = URL.createObjectURL(myBlob)
      window.open(fileURL) as Window
    })
    .then(() => setLoading(false))
    .catch((error) => console.log(error))
}

export async function loadFile(
  url: string,
  method: string = 'GET',
  requestObject?: any
): Promise<Blob> {
  const requestBody = requestObject ? JSON.stringify(requestObject) : ''
  console.log(`${method} ${url} ${requestBody && `with body: ${requestBody}`}`)

  return (
    fetch(
      url,
      method === 'GET'
        ? defaultOptionsGet()
        : defaultOptionsMake(
            requestObject ? JSON.stringify(requestObject) : requestBody,
            method
          )
    )
      .then((response) => {
        console.log('response: ', response)
        return response.blob()
      })
      // .then((data) => {
      //   console.log('data: ', data)
      //   return data
      // })
      .catch((error) => {
        console.log(error)
        return error
      })
  )
}

export async function loadResource(url: string): Promise<Blob> {
  console.log(`GET ${url}`)

  return fetch(url)
    .then((response) => {
      console.log('response: ', response)
      return response.blob()
    })
    .catch((error) => {
      console.log(error)
      return error
    })
}

export function placeNewJwtInUrl(url: string) {
  if (!url) return url
  const [firstPart, secondPart] = url.split('&jwt')
  if (secondPart) {
    return firstPart + '&jwt=' + getPrecious()
  } else {
    return firstPart
  }
}

export async function logout(companyId?: string) {
  // logout from server, then redirect to login page
  // purgeAndLog(urlBackend + '/jwt/delete').then(() => {
  removePrecious()
  // without the delay we get errors (SodexoOne redirects them again into our app with an already invalid Token) --> apparently not anymore
  // setTimeout(
  // () => (
  const logoutUrl = companyId
    ? // strip everything after .cz from the url
      getLogoutUrl().replace(/(.*\.cz).*/, '$1') +
      `/RedirectLogin/Login?redirectUrlKey=RedirectRegisterOrd&companyId=${companyId}`
    : placeNewJwtInUrl(getLogoutUrl())

  console.log('logging out, to logoutUrl: ', logoutUrl)
  window.location.href = logoutUrl

  // ),
  // 500
  // ) // faster logout for user = better UX.
  // })
}

export async function uploadExcel(nazev: string, data: Blob): Promise<any> {
  // upload excel file to server

  // 1st approach - use sendAndLog - problem is that it has different headers (Accept: 'application/json', 'Content-Type': 'application/json',) than we need (inferred from data's type: 'application/vnd.ms-excel').
  // return sendAndLog(beUploadExcel, data, 'POST')

  /* 2nd approach - suggested by Patrik, used outdated methods (XHR from year 1999/2000, originally developed for MS Outlook).
   Funnily enough, this is what GitHub Copilot suggests as a comment: 
   "This was a workaround for the fact that the backend does not support multipart/form-data" */
  /* const formData = new FormData()
  formData.append('soubor', data, nazev) // append(name: string, value: string | Blob, fileName?: string): void;
  console.log(`POST ${beUploadExcel} with formData: %O`, formData)
  const requestBody = JSON.stringify(formData)
  console.log(
    `POST ${beUploadExcel} with body: ${requestBody} and formData ${formData}`
  ) */

  // 3rd approach: decorate Blob with JS File interface (the original blob does not get copied).
  const file: File = new File([data], nazev, {
    type: 'application/vnd.ms-excel',
  })

  console.log(`POST ${beUploadExcel} with file: %O`, file)

  // Another approach would be to change this to a POST request which has the blob as body and the filename as parameter

  return fetch(beUploadExcel, {
    method: 'POST',
    headers: {
      Authorization: defaultHeaders().headers.Authorization,
      Accept: 'application/json',
      // https://stackoverflow.com/questions/35761248/which-separator-should-be-used-in-the-content-type-header-for-a-multipart-data-r
      // hopefully we won't have to delete the Content-Type https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/vnd.ms-excel', // The first is for Excel2007 and above .xlsx files, the latter for BIFF .xls files.
    },
    body: file,
    // body: formData,
    // body: requestBody, // URLSearchParams | ReadableStream<any> | Blob | ArrayBufferView | ArrayBuffer | FormData
    // body: dataArray,
  })
    .then((response) => {
      console.log('response: ', response)
      return response.json()
    })
    .then((data) => {
      console.log('data: ', data)
      return data
    })
    .catch((error) => console.error(error))
}

export async function createSkupina(skupina: string): Promise<any> {
  return sendAndLog(beMakeSkupina, { skupina: skupina }, 'POST')
}
export async function mutateSkupina(
  skupina: string,
  callerSkupina: string
): Promise<any> {
  return sendAndLog(
    beMakeSkupina + `/${callerSkupina}`,
    { nazev: skupina },
    'PUT'
  )
}
export async function purgeSkupina(skupina: string): Promise<any> {
  return purgeAndLog(beMakeSkupina + `/${skupina}`)
}
export async function assignSkupina(
  zamestnanci: number[],
  skupina: string
): Promise<any> {
  const requestBody = {
    zamestnanci: zamestnanci.map((zamestnanec) => ({
      zamestnanec: zamestnanec,
      skupina: skupina,
    })),
  }
  return sendAndLog(beMakeSkupina, requestBody, 'PUT')
}

export async function switchSpolecnost(ico: string) {
  const requestBody = { ico: ico }

  return sendAndLog(beMakeSpolecnost, requestBody, 'PUT')
}

export async function mutatePocatekCasu(pocatekCasu: string) {
  const newToken = await sendAndLog(
    beMutatePocatekCasu,
    { pocatekCasu: pocatekCasu },
    'PATCH'
  )
  return newToken
}

export async function mutateAktivovanyBackend(id: string, aktivni: boolean) {
  /* Backend manages setting deactivatedFrom to new Date() or null (confirmed in Teams channel with Patrik on 25.4.) */

  // A potom endpoint pro de/aktivaci: PUT {{URL}}/zamestnanci/12345728/aktivace
  console.log('mutateAaktivovanyBackend', id, aktivni)
  const requestBody = {
    aktivni: aktivni,
  }
  const url = `${beMakeZamestnanec}/${id}/aktivace`
  return await sendAndLog(url, requestBody, 'PUT')
}

export async function mutateZamestnanecBackend(
  zamestnanecBackend: ZamestnanecBackendEditace
): Promise<any> {
  if (!zamestnanecBackend.adresa.oddeleni) {
    zamestnanecBackend.adresa.oddeleni = 'Žádné' // bugfix: when there is no oddeleni or empty string, the field gets removed from the request
  }
  console.log('mutateZamestnanecBackend', zamestnanecBackend)
  const { id, ...zamestnanecBackendWithoutId } = zamestnanecBackend
  const url = `${beMakeZamestnanec}/${id}`
  return await sendAndLog(url, zamestnanecBackendWithoutId, 'PUT')
}

export async function createAdresaBackend(address: Address) {
  const { ulice, cisloPopisne } = splitUliceCisloPopisne(
    address.uliceCisloPopisne
  )

  const adresaBackend: AdresaBackend = {
    nazevSpolecnosti: address.nazevSpolecnosti,
    kontaktniOsoba: address.kontaktniOsoba,
    ulice: ulice,
    cisloPopisne: cisloPopisne,
    mesto: address.mesto,
    psc: address.psc.toString(),
    telefon: '+' + address.predvolba.toString() + address.telefon.toString(),
    oddeleni: address.oddeleni || ['Žádné'],
  }

  return sendAndLog(beMakeAdresa, adresaBackend, 'POST')
}

export async function sendObjednavkaToBackendAndPrettifyId(
  order: NovaObjednavkaBackend
): Promise<number> {
  const requestBody = JSON.stringify(order)

  console.log(
    `POST ${beObjednavky}`,
    requestBody,
    defaultOptionsMake(requestBody).headers.Authorization
  )

  let orderIdFromBackend: number = 0

  // it seems like this await miraculously pauses the program?? or it just returns a promise...
  await fetch(beObjednavky, defaultOptionsMake(requestBody))
    .then(logResponseAndThrowErrors)
    .then((data) => {
      console.log('data: ', data)
      let zpravaFromBackend: string = data.message
      // get the string from the message, which starts directly after the word 'Objednávka ' and ends before ' byla odeslána'
      const orderIdFromBackendString = zpravaFromBackend
        .split('Objednávka ')[1]
        .split(' byla odeslána')[0]
        .trim()

      orderIdFromBackend = cisloObjednavkyBackendToOrderId(
        orderIdFromBackendString
      )
      console.log('orderIdFromBackend: ', orderIdFromBackend)
      return data
    })

  // we actually have to return a promise... maybe this will work - it does
  return orderIdFromBackend
}

export async function createOddeleniBackend(payload: {
  address: Address
  oddeleni: string
}) {
  const { ulice, cisloPopisne } = splitUliceCisloPopisne(
    payload.address.uliceCisloPopisne
  )
  const oddeleniForBackend = payload.oddeleni || INTERNAL_ID // backend doesn't support blank string

  const obaleneOddeleni = payload.address.uuid
    ? {
        oddeleni: oddeleniForBackend,
        uuid: payload.address.uuid,
      }
    : {
        oddeleni: oddeleniForBackend,
        psc: payload.address.psc,
        ulice: ulice,
        cisloPopisne: cisloPopisne,
      }

  sendAndLog(beMakeOddeleni, obaleneOddeleni, 'POST')
}

export async function createKontaktniOsobaBackend(
  payload: KontaktniOsobaBackendRequest
) {
  const url = `${beMakeKontaktniOsoba}`
  return sendAndLog(url, payload, 'POST')
}
export async function mutateKontaktniOsobaBackend(
  payload: KontaktniOsobaBackendRequest
) {
  const url = `${beMakeKontaktniOsoba}/${payload.email}`
  return sendAndLog(url, payload, 'PUT')
}

export async function mutateAktivovatZasilkuBackend(
  orderIdBackend: string,
  zasilkaNumber: number
) {
  // PUT {{URL}}/objednavky/40E100000015/zasilky/1/aktivace
  const url = `${urlBackend}/objednavky/${orderIdBackend}/zasilky/${zasilkaNumber}/aktivace`
  sendAndLog(url, {}, 'PUT')
}

export async function mutateHlavniKontaktniOsobaBackend(email: string) {
  const url = `${beMakeKontaktniOsoba}/admin`
  sendAndLog(url, { email: email }, 'PUT')
}

export async function mutateAdresaBackend(payload: {
  callerAddress: { ulice: string; cisloPopisne: string; psc: string }
  updatedAddress: Address
  callerUuid?: string
}) {
  const { ulice, cisloPopisne } = splitUliceCisloPopisneZachovatCisloOrientacni(
    payload.updatedAddress.uliceCisloPopisne
  )

  const AdresaBackend: AdresaBackend = {
    nazevSpolecnosti: payload.updatedAddress.nazevSpolecnosti,
    kontaktniOsoba: payload.updatedAddress.kontaktniOsoba,
    ulice: ulice,
    cisloPopisne: cisloPopisne,
    mesto: payload.updatedAddress.mesto,
    psc: payload.updatedAddress.psc.toString(),
    telefon:
      payload.updatedAddress.predvolba.toString() +
      payload.updatedAddress.telefon.toString(),
  }
  const obalenaAdresa = {
    adresa: AdresaBackend,
  }

  const url = payload.callerUuid
    ? `${beMakeAdresa}?uuid=${payload.callerUuid}`
    : `${beMakeAdresa}?psc=${payload.callerAddress.psc}&ulice=${payload.callerAddress.ulice}&cisloPopisne=${payload.callerAddress.cisloPopisne}`
  return sendAndLog(url, obalenaAdresa, 'PUT')
}

export async function mutateOddeleniBackend(
  address: Address,
  callerOddeleni: string,
  newOddeleni: string
) {
  console.log('mutateOddeleniBackend, payload:', {
    address,
    callerOddeleni,
    newOddeleni,
  })

  const { ulice, cisloPopisne } = splitUliceCisloPopisne(
    address.uliceCisloPopisne
  )

  const oddeleniForBackend = newOddeleni || INTERNAL_ID // backend doesn't support blank string

  const url = `${beMakeOddeleni}?psc=${
    address.psc
  }&ulice=${ulice}&cisloPopisne=${cisloPopisne}&oddeleni=${
    callerOddeleni === '' ? INTERNAL_ID : encodeURIComponent(callerOddeleni)
  }`
  return sendAndLog(url, { nazev: oddeleniForBackend }, 'PUT')
}

export async function purgeKontaktniOsobaBackend(email: string) {
  console.log('purgeKontaktniOsobaBackend, email:', email)

  const url = `${beMakeKontaktniOsoba}/${email}`
  return purgeAndLog(url)
}

export async function purgeOddeleniBackend(
  address: Address,
  callerOddeleni: string
) {
  const { ulice, cisloPopisne } = splitUliceCisloPopisne(
    address.uliceCisloPopisne
  )

  const url = `${beMakeOddeleni}?psc=${
    address.psc
  }&ulice=${ulice}&cisloPopisne=${cisloPopisne}&oddeleni=${
    callerOddeleni === '' ? INTERNAL_ID : encodeURIComponent(callerOddeleni)
  }`

  purgeAndLog(url)
}

export async function purgeAdresaBackend(address: Address) {
  console.log('purgeAdresaBackend, payload:', address)

  const { ulice, cisloPopisne } = splitUliceCisloPopisneZachovatCisloOrientacni(
    address.uliceCisloPopisne
  )

  const url = `${beMakeAdresa}?psc=${address.psc}&ulice=${ulice}&cisloPopisne=${cisloPopisne}`
  purgeAndLog(url)
}
