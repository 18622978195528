import { numberWithSpaces, numberWithSpacesX } from './util'

export function getCalculatedFee(
    minimalFee: number,
    totalCredit: number,
    feePercentage: number
): number {
    if (totalCredit === 0) {
        return 0
    }
    const fee = feePercentage * totalCredit
    if (fee < minimalFee) {
        return minimalFee
    }
    return fee
}

export function getFormattedFee(
    minimalFee: number,
    totalCredit: number,
    feePercentage: number
): string {
    return numberWithSpaces(getCalculatedFee(minimalFee, totalCredit, feePercentage).toFixed(2))
}

export function getSpravaUctuPriceFormatted(
  monthsToPay: number,
  spravaJednohoUctu: number
): string {
  return numberWithSpacesX(monthsToPay * spravaJednohoUctu)
}

export function fractionalToPercents(fractional: number): number {
  return fractional * 100
}

export function roundToTwo(number: number): number {
  return Math.round((number + Number.EPSILON) * 100) / 100 // https://stackoverflow.com/a/11832950/104370
}

// export function roundNumber(num: number, scale: number): number {
//   if (!('' + num).includes('e')) {
//     return +(Math.round(+(num + 'e+' + scale)) + 'e-' + scale)
//   } else {
//     var arr = ('' + num).split('e')
//     var sig = ''
//     if (+arr[1] + scale > 0) {
//       sig = '+'
//     }
//     return +(
//       Math.round(+(+arr[0] + 'e' + sig + (+arr[1] + scale))) +
//       'e-' +
//       scale
//     )
//   }
// }

// export function roundToTwo(num) {
//   return +(Math.round(+(num + 'e+2')) + 'e-2') // https://stackoverflow.com/a/18358056
// }
