import Color from 'app/colors'
import React, { PureComponent } from 'react'

import { IconProps } from '../../app/type'

class EraseIcon extends PureComponent<IconProps> {
  static defaultProps: IconProps = {
    className: 'erase-icon',
    width: 32,
    height: 32,
  }

  render() {
    const { className, width, height, style, fill } = this.props
    return (
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        className={className}
        fill='none'
        style={style}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.804 6.65614C18.1926 6.25466 18.8347 6.2494 19.2297 6.64446L26.4065 13.8212C26.7914 14.2061 26.7978 14.8282 26.4208 15.2209L25.0564 16.6421L16.4596 8.04537L17.804 6.65614ZM15.0686 9.48277L12.5252 12.1109L21.138 20.7238L23.671 18.0852L15.0686 9.48277ZM19.7527 22.1668L11.1342 13.5483L5.58609 19.2813C5.20646 19.6736 5.21158 20.2979 5.59758 20.6839L10.9135 25.9998H16.073L19.7527 22.1668ZM27.8636 16.6059L18.8455 25.9998H28.4993V27.9998H10.9135C10.383 27.9998 9.87433 27.789 9.49926 27.414L4.18337 22.0981C3.02535 20.9401 3.01 19.0673 4.14888 17.8905L16.3668 5.2653C17.5324 4.06086 19.4588 4.04507 20.6439 5.23024L27.8207 12.407C28.9754 13.5617 28.9945 15.4279 27.8636 16.6059Z'
          fill={fill ? fill : Color.RED_PRIMARY}
        />
      </svg>
    )
  }
}

export default EraseIcon
